.text-media {
  background: $color-black;
  color: $color-white;
  border-bottom: 2px solid $color-yellow;
}

.text-media__content {
  @media (min-width: $md) {
    width: 50%;
  }
}

.text-media__image {
  @media (min-width: $md) {
    width: 50%;
  }

  picture {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      inset: 1rem;
      border: 2px solid $color-yellow;
    }
  }
}
