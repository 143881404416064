@for $i from 1 through 30 {
  .animation-delay--#{$i} {
    animation-delay: $i * 100ms !important;
  }
}

.fade-in-bottom {
  transform: translate3d(0, 100%, 0);
  opacity: 0;

  &.ani-active {
    animation: fade-in-bottom 0.5s ease-in-out forwards;
  }
}

@keyframes fade-in-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
}
