html {
  font-size: 16px;
  @apply box-border;
}

body {
  // @apply bg-green;
  @apply font-body;
  @apply text-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-size: 1.125rem;
  font-size: 1rem;
  line-height: 1.4;

  *:focus {
    outline: none;
    background: none;
  }
}

* {
  // border: 1px solid red;
}

section,
.section {
  padding: clamp(30px, 13vw, 150px) 0;
  @apply relative;
}

.container {
  @apply px-4;
  @apply mx-auto;
  max-width: $xl;
}

.container-fluid {
  padding: 0 5vw;
  @apply w-full;
}

.row {
  @apply -mx-4;
  @apply flex;
  @apply flex-wrap;
}

.col {
  @apply p-4;
}

p {
  @apply mb-4;
}

img {
  @apply w-full;
}

table {
  width: 100%;
}
