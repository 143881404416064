.btn {
  padding: 1rem;
  background: $color-yellow;
  border: 2px solid $color-yellow;
  color: $color-black;
  display: inline-block;
  font-weight: $font-bold;

  &--secondary {
    background: transparent;
    color: $color-yellow;
  }

  &--black {
    border: 2px solid $color-black;
    background: black;
    color: $color-yellow;
  }

  &--arrow {
    color: $color-yellow;
    font-weight: $font-bold;
    background: none;
    padding: 0;
    border: none;
  }
}
