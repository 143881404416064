.hero {
  position: relative;
}

.hero__content {
  position: relative;
  z-index: 10;
  color: $color-white;
  padding: 20px 0 20px 30px;
  margin: 2rem 0;

  @media (min-width: $md) {
    margin: 0;
    width: 70%;
    padding: 60px 0 60px 120px;
  }

  @media (min-width: $xl) {
    width: 50%;
  }
}

.hero__description {
  margin-bottom: 1rem;
}

.hero__content-border {
  position: absolute;
  inset: 0;
  width: 50%;
  //   z-index: -1;
  border: 2px solid $color-yellow;
  border-right: none;
  pointer-events: none;

  @media (min-width: $md) {
  }
}

.hero__headline {
  font-weight: $font-light;

  strong {
    font-weight: $font-bold;
    color: $color-yellow;
  }
}

.hero__image {
  position: absolute;
  inset: 0;
}

.hero__picture {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero__image-overlay {
  position: absolute;
  inset: 0;
  background: rgb(36, 36, 36);
  background: linear-gradient(
    90deg,
    rgba(36, 36, 36, 1) 0%,
    rgba(36, 36, 36, 0.25) 100%
  );
  mix-blend-mode: multiply;
}

//intro

.intro {
  background: $color-black;
  position: relative;
  z-index: 2;
  padding: 2rem 0;

  @media (min-width: $md) {
    padding: 5rem 0;
  }
}

.intro__inner {
  @media (min-width: $md) {
    display: flex;
    align-items: flex-start;
  }
}

.intro__content {
  margin-bottom: 2rem;

  @media (min-width: $md) {
    margin-bottom: 0;
    width: calc(100% - 320px);
  }
}

.intro__text {
  color: $color-white;
  // max-width: 50%;
  margin-bottom: 2rem;

  @media (min-width: $md) {
    padding-right: 3rem;
  }
}

.intro__categories {
  background: $color-yellow;
  padding-bottom: 1rem;
  width: 100%;

  @media (min-width: $md) {
    width: 300px;
    flex: 0 1 300px;
    margin: -12rem 0 -12rem 0;
  }
}

.intro__categories-items {
  display: flex;
  flex-wrap: wrap;

  & .category-link {
    padding: 0.5rem !important;
  }

  & .category-link img {
    width: 70%;
    height: 70%;
    margin: 0 auto;
  }
}

.intro__categories-headline {
  padding: 1rem;
  // width: 100%;
}
