.machine-content {
  // background: $color-black;
}

.machine__content-inner {
  @media (min-width: $md) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.machine__form {
  @media (min-width: $md) {
    width: 50%;
    position: sticky;
    top: 150px;
    left: 0;
    padding: 1rem;
  }
}

.machine__additional {
  @media (min-width: $md) {
    // width: 50%;
  }
}

.machine__gallery {
  margin-bottom: 2rem;
}

.machine__gallery-thumbnails {
  display: flex;
  flex-wrap: wrap;
}

.machine__gallery-item {
  width: 100px;
  cursor: pointer;
  //   background: $color-yellow;
  aspect-ratio: 1 / 1;
  opacity: 0.4;
  padding: 0.5rem;

  @media (min-width: $xl) {
    width: 150px;
  }

  &--active {
    opacity: 1;
  }

  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.machine__gallery-image {
  display: none;
  aspect-ratio: 1 / 1;

  &--active {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
