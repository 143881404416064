@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("montserrat-v15-latin-200.385e75e6.woff2") format("woff2"), url("montserrat-v15-latin-200.2fe17fa3.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("montserrat-v15-latin-regular.a2250735.woff2") format("woff2"), url("montserrat-v15-latin-regular.7689b5bf.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("montserrat-v15-latin-700.8c9438c0.woff2") format("woff2"), url("montserrat-v15-latin-700.fa8a261e.woff") format("woff");
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat;
  font-size: 1rem;
  line-height: 1.4;
}

body :focus {
  background: none;
  outline: none;
}

section, .section {
  padding: clamp(30px, 13vw, 150px) 0;
  position: relative;
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container-fluid {
  width: 100%;
  padding: 0 5vw;
}

.row {
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
}

.col {
  padding: 1rem;
}

p {
  margin-bottom: 1rem;
}

img, table {
  width: 100%;
}

.nav__toggle {
  width: 50px;
  height: 20px;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 1rem;
  display: flex;
  position: relative;
}

@media (min-width: 480px) {
  .nav__toggle {
    width: 70px;
  }
}

@media (min-width: 769px) {
  .nav__toggle {
    width: 90px;
  }
}

.nav__toggle span {
  width: 100%;
  height: 2px;
  pointer-events: none;
  background: #fcc924;
  transition: all .3s ease-in-out;
  display: block;
}

.nav__toggle span:nth-child(2) {
  width: 40%;
  margin: 5px 0;
}

.nav__toggle span:nth-child(3) {
  width: 60%;
}

.nav__toggle.active span {
  width: 30px;
  position: absolute;
  top: 50%;
}

@media (min-width: 769px) {
  .nav__toggle.active span {
    width: 60px;
  }
}

.nav__toggle.active span:nth-child(2) {
  opacity: 0;
}

.nav__toggle.active span:first-child {
  transform: rotate(45deg);
}

.nav__toggle.active span:last-child {
  transform: rotate(-45deg);
}

.main-navigation {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 100%;
  opacity: 0;
  z-index: -1;
  background: #242424;
  align-items: center;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate3d(0, -200%, 0);
}

.nav__toggle.active + .main-navigation {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
}

.main-navigation__inner {
  height: calc(100vh - 68px);
  padding: 3rem 1rem;
  position: relative;
  overflow: auto;
}

@media (min-width: 768px) {
  .main-navigation__inner {
    height: calc(100vh - 83px);
    padding: 5rem 1rem;
  }
}

@media (min-width: 1024px) {
  .main-navigation__inner {
    align-items: center;
    display: flex;
  }
}

#primary-menu {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #primary-menu {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }
}

#primary-menu .menu-item {
  display: block;
}

#primary-menu .menu-item.current_page_item, #primary-menu .menu-item:hover:after {
  right: 0;
}

#primary-menu .menu-item a {
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: block;
}

@media (min-width: 768px) {
  #primary-menu .menu-item a {
    padding: 1rem 2rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

#primary-menu a.active + .sub-menu {
  padding: 3rem 1rem;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0;
  overflow: auto;
}

@media (min-width: 769px) {
  #primary-menu a.active + .sub-menu {
    padding: 5rem 1rem;
  }
}

@media (min-width: 1024px) {
  #primary-menu a.active + .sub-menu {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate3d(-50%, -50%, 0);
  }
}

#primary-menu .sub-menu {
  display: none;
}

#primary-menu .sub-menu a {
  font-size: 1rem;
  line-height: 1.5rem;
}

.nav-btns {
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 1024px) {
  .nav-btns {
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    display: flex;
  }
}

.nav-btns .btn {
  margin-bottom: .5rem;
  margin-right: .5rem;
  display: inline-block;
}

.nav_back {
  text-align: center;
  cursor: pointer;
  visibility: visible;
  padding: 1rem;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .nav_back {
    width: 100%;
    flex: 1 0 100%;
  }
}

.sub-nav-open .menu-item {
  visibility: hidden;
}

.sub-nav-open .active + .sub-menu > .menu-item {
  visibility: visible;
}

.headline {
  font-weight: 200;
}

.headline strong {
  color: #fcc924;
  font-weight: 700;
}

h1, .headline--h1 {
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-weight: 200;
  line-height: 1.2;
}

h2, .headline--h2 {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  font-weight: 200;
  line-height: 1.2;
}

h3, .headline--h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: clamp(1.1rem, 2vw, 1.8rem);
  font-weight: 700;
  line-height: 1.2;
}

.header {
  width: 100%;
  z-index: 90;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  left: 0;
}

.header.nav-open {
  background: #242424 !important;
}

.scroll .header {
  background: #24242480;
}

.header__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo {
  width: 90px;
}

@media (min-width: 380px) {
  .logo {
    width: 120px;
  }
}

@media (min-width: 480px) {
  .logo {
    width: 150px;
  }
}

@media (min-width: 769px) {
  .logo {
    width: 200px;
  }
}

.logo img {
  width: 100%;
}

.header__interact, .header__social {
  align-items: center;
  display: flex;
}

.header-social__item {
  margin-right: .5rem;
  display: inline-block;
}

@media (min-width: 480px) {
  .header-social__item {
    margin-right: 1rem;
  }
}

.header-social__item--tel {
  color: #fcc924;
  font-size: 1.2rem;
  display: none;
}

@media (min-width: 769px) {
  .header-social__item--tel {
    display: block;
  }
}

.header-social__item-icon {
  width: 1rem;
  height: 1rem;
  color: #fcc924;
}

.header-social__item-icon svg path, .header-social__item-icon svg g {
  fill: currentColor;
}

@media (min-width: 380px) {
  .header-social__item-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (min-width: 480px) {
  .header-social__item-icon {
    width: 1.75rem;
    height: 1.75rem;
  }
}

@media (min-width: 769px) {
  .header-social__item-icon {
    width: 2rem;
    height: 2rem;
  }
}

.hero {
  position: relative;
}

.hero__content {
  z-index: 10;
  color: #fff;
  margin: 2rem 0;
  padding: 20px 0 20px 30px;
  position: relative;
}

@media (min-width: 769px) {
  .hero__content {
    width: 70%;
    margin: 0;
    padding: 60px 0 60px 120px;
  }
}

@media (min-width: 1280px) {
  .hero__content {
    width: 50%;
  }
}

.hero__description {
  margin-bottom: 1rem;
}

.hero__content-border {
  width: 50%;
  pointer-events: none;
  border: 2px solid #fcc924;
  border-right: none;
  position: absolute;
  inset: 0;
}

.hero__headline {
  font-weight: 200;
}

.hero__headline strong {
  color: #fcc924;
  font-weight: 700;
}

.hero__image {
  position: absolute;
  inset: 0;
}

.hero__picture {
  width: 100%;
  height: 100%;
}

.hero__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__image-overlay {
  mix-blend-mode: multiply;
  background: linear-gradient(90deg, #242424 0%, #24242440 100%);
  position: absolute;
  inset: 0;
}

.intro {
  z-index: 2;
  background: #242424;
  padding: 2rem 0;
  position: relative;
}

@media (min-width: 769px) {
  .intro {
    padding: 5rem 0;
  }

  .intro__inner {
    align-items: flex-start;
    display: flex;
  }
}

.intro__content {
  margin-bottom: 2rem;
}

@media (min-width: 769px) {
  .intro__content {
    width: calc(100% - 320px);
    margin-bottom: 0;
  }
}

.intro__text {
  color: #fff;
  margin-bottom: 2rem;
}

@media (min-width: 769px) {
  .intro__text {
    padding-right: 3rem;
  }
}

.intro__categories {
  width: 100%;
  background: #fcc924;
  padding-bottom: 1rem;
}

@media (min-width: 769px) {
  .intro__categories {
    width: 300px;
    flex: 0 300px;
    margin: -12rem 0;
  }
}

.intro__categories-items {
  flex-wrap: wrap;
  display: flex;
}

.intro__categories-items .category-link {
  padding: .5rem !important;
}

.intro__categories-items .category-link img {
  width: 70%;
  height: 70%;
  margin: 0 auto;
}

.intro__categories-headline {
  padding: 1rem;
}

.accordion {
  margin-bottom: 1rem;
}

.accordion__header {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  cursor: pointer;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.accordion__header h4 {
  flex: auto;
  margin-bottom: 0;
}

.accordion__icon {
  width: 1.5rem;
}

.accordion__icon div {
  transform-origin: center;
  width: 1rem;
  height: 1rem;
  transition: all .3s ease-in-out;
  transform: rotate(90deg);
}

.accordion__icon div.accordion__icon--active {
  transform: rotate(-90deg);
}

.accordion__body {
  max-height: 0;
  transition: all .3s ease-in-out;
  overflow: hidden;
}

.accordion__body--open {
  max-height: 1000px;
  overflow: auto;
}

.accordion__body-inner {
  padding: 1rem;
}

.big-teaser {
  min-height: 50vh;
  background: #242424;
  position: relative;
}

.big-teaser__inner {
  align-items: center;
  padding: 0 1rem;
  display: flex;
}

.big-teaser__image {
  position: absolute;
  inset: 0;
}

.big-teaser__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .3;
}

.big-teaser__content {
  z-index: 1;
  width: 100%;
  max-width: 1024px;
  text-align: center;
  border: 2px solid #fcc924;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
}

.big-teaser__headline {
  color: #fff;
}

.big-teaser__headline strong {
  color: #fcc924;
  font-weight: 700;
}

.btn {
  color: #242424;
  background: #fcc924;
  border: 2px solid #fcc924;
  padding: 1rem;
  font-weight: 700;
  display: inline-block;
}

.btn--secondary {
  color: #fcc924;
  background: none;
}

.btn--black {
  color: #fcc924;
  background: #000;
  border: 2px solid #242424;
}

.btn--arrow {
  color: #fcc924;
  background: none;
  border: none;
  padding: 0;
  font-weight: 700;
}

.textarea {
  color: #fff;
  background: #242424;
  border-bottom: 2px solid #fcc924;
}

@media (min-width: 769px) {
  .textarea__inner {
    display: flex;
  }

  .textarea__headline {
    width: 50%;
  }
}

.textarea__content {
  font-weight: 200;
}

@media (min-width: 769px) {
  .textarea__content {
    width: 50%;
  }
}

.footer {
  color: #242424;
  background: #fcc924;
}

@media (min-width: 769px) {
  .footer__col {
    width: 33.3333%;
  }
}

.footer__info-content {
  margin-bottom: 1rem;
}

.footer-link {
  margin-bottom: .3rem;
  font-weight: 700;
  display: block;
}

input, textarea {
  width: 100%;
  background: none;
  border-bottom: 2px solid #242424;
  display: block;
  margin-bottom: 1rem !important;
  padding: .5rem 0 !important;
}

input[type="checkbox"] {
  width: 2rem;
  margin-right: .5rem;
  display: inline-block;
  margin-bottom: 0 !important;
}

.input-wrapper--checkbox {
  padding: 1rem 0;
}

form label {
  align-items: center;
  font-weight: 200;
  display: flex;
}

form label a {
  font-weight: 700;
}

.wpcf7-list-item {
  margin-left: 0 !important;
}

::-webkit-input-placeholder {
  font-weight: 200;
  color: #242424 !important;
}

:-ms-input-placeholder {
  font-weight: 200;
  color: #242424 !important;
}

::placeholder {
  font-weight: 200;
  color: #242424 !important;
}

.text-media {
  color: #fff;
  background: #242424;
  border-bottom: 2px solid #fcc924;
}

@media (min-width: 769px) {
  .text-media__content, .text-media__image {
    width: 50%;
  }
}

.text-media__image picture {
  position: relative;
}

.text-media__image picture:before {
  content: "";
  border: 2px solid #fcc924;
  position: absolute;
  inset: 1rem;
}

.category-header {
  color: #fff;
  background: #242424;
  padding: 9rem 0 7rem;
  position: relative;
}

.category__hero {
  position: absolute;
  inset: 0;
}

.category__hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .3;
}

.category-header__inner {
  z-index: 1;
  border-bottom: 2px solid #fcc924;
  border-right: 2px solid #fcc924;
  padding: 0 1rem 2rem 0;
  position: relative;
}

@media (min-width: 769px) {
  .category-header__inner {
    gap: 2rem;
    padding: 0 3rem 3rem 0;
    display: flex;
  }

  .category-header__inner--small {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .category-header__inner {
    padding: 0 5rem 5rem 0;
  }
}

.category-header__inner .btn {
  margin-top: 1rem;
}

@media (min-width: 769px) {
  .category-header__content {
    width: 50%;
    position: relative;
  }

  .category-header__content--small {
    width: 100%;
  }
}

.category-header__content-inner {
  width: 100%;
  position: sticky;
  top: 150px;
  left: 0;
}

@media (min-width: 769px) {
  .category-header__media {
    width: 50%;
  }
}

.category-header__back {
  cursor: pointer;
  color: #fcc924;
  margin-top: 1rem;
  font-size: .75rem;
  font-weight: 200;
}

.category-list {
  background: #fcc924;
}

.machines-overview {
  background: #7e8184;
}

.machine-overview__item {
  margin-bottom: 2rem;
}

@media (min-width: 769px) {
  .machine-overview__item {
    align-items: flex-start;
    margin-bottom: 4rem;
    display: flex;
  }

  .machine-overview__image {
    width: 50%;
    z-index: 0;
    position: relative;
  }
}

.machine-overview__content {
  color: #fff;
  background: #242424;
  padding: 1rem;
  font-weight: 200;
}

@media (min-width: 769px) {
  .machine-overview__content {
    width: 50%;
    margin-top: 3rem;
    margin-left: -3rem;
    padding: 3rem 3rem 3rem 7rem;
  }
}

.machine-overview__price {
  color: #fcc924;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 2rem;
  display: block;
}

.machine-overview__text {
  margin-bottom: 1rem;
}

.category-list__inner {
  align-items: center;
  display: flex;
  overflow: auto;
}

.categories-headline {
  text-transform: uppercase;
  margin-right: 1rem;
  font-size: clamp(.8rem, 1.5vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

.categories-items {
  align-items: center;
  display: flex;
}

.category-link {
  min-width: 70px;
  aspect-ratio: 1 / 1;
  padding: .2rem;
  display: block;
}

.category-link img {
  object-fit: contain;
  object-position: bottom;
  width: 100%;
  height: 100%;
}

.intro__categories-items .category-link {
  width: 50%;
}

@media (min-width: 769px) {
  .category-link {
    width: 160px;
    padding: 1rem;
  }
}

.category-link--active, .category-link:hover {
  background: #eab303;
}

.category-link__name {
  width: 100%;
  text-align: center;
  color: #242424;
  padding: .2rem 0;
  font-size: 1rem;
  display: block;
}

@media (min-width: 769px) {
  .machine__content-inner {
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .machine__form {
    width: 50%;
    padding: 1rem;
    position: sticky;
    top: 150px;
    left: 0;
  }
}

.machine__gallery {
  margin-bottom: 2rem;
}

.machine__gallery-thumbnails {
  flex-wrap: wrap;
  display: flex;
}

.machine__gallery-item {
  width: 100px;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  opacity: .4;
  padding: .5rem;
}

@media (min-width: 1280px) {
  .machine__gallery-item {
    width: 150px;
  }
}

.machine__gallery-item--active {
  opacity: 1;
}

.machine__gallery-item img {
  pointer-events: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.machine__gallery-image {
  aspect-ratio: 1 / 1;
  display: none;
}

.machine__gallery-image--active {
  display: block;
}

.machine__gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.error-404 {
  color: #fff;
  background: #242424;
  padding-top: 10rem;
}

.animation-delay--1 {
  animation-delay: .1s !important;
}

.animation-delay--2 {
  animation-delay: .2s !important;
}

.animation-delay--3 {
  animation-delay: .3s !important;
}

.animation-delay--4 {
  animation-delay: .4s !important;
}

.animation-delay--5 {
  animation-delay: .5s !important;
}

.animation-delay--6 {
  animation-delay: .6s !important;
}

.animation-delay--7 {
  animation-delay: .7s !important;
}

.animation-delay--8 {
  animation-delay: .8s !important;
}

.animation-delay--9 {
  animation-delay: .9s !important;
}

.animation-delay--10 {
  animation-delay: 1s !important;
}

.animation-delay--11 {
  animation-delay: 1.1s !important;
}

.animation-delay--12 {
  animation-delay: 1.2s !important;
}

.animation-delay--13 {
  animation-delay: 1.3s !important;
}

.animation-delay--14 {
  animation-delay: 1.4s !important;
}

.animation-delay--15 {
  animation-delay: 1.5s !important;
}

.animation-delay--16 {
  animation-delay: 1.6s !important;
}

.animation-delay--17 {
  animation-delay: 1.7s !important;
}

.animation-delay--18 {
  animation-delay: 1.8s !important;
}

.animation-delay--19 {
  animation-delay: 1.9s !important;
}

.animation-delay--20 {
  animation-delay: 2s !important;
}

.animation-delay--21 {
  animation-delay: 2.1s !important;
}

.animation-delay--22 {
  animation-delay: 2.2s !important;
}

.animation-delay--23 {
  animation-delay: 2.3s !important;
}

.animation-delay--24 {
  animation-delay: 2.4s !important;
}

.animation-delay--25 {
  animation-delay: 2.5s !important;
}

.animation-delay--26 {
  animation-delay: 2.6s !important;
}

.animation-delay--27 {
  animation-delay: 2.7s !important;
}

.animation-delay--28 {
  animation-delay: 2.8s !important;
}

.animation-delay--29 {
  animation-delay: 2.9s !important;
}

.animation-delay--30 {
  animation-delay: 3s !important;
}

.fade-in-bottom {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.fade-in-bottom.ani-active {
  animation: .5s ease-in-out forwards fade-in-bottom;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1560px) {
  .container {
    max-width: 1560px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.not-sr-only {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.focus-within\:sr-only:focus-within {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.focus-within\:not-sr-only:focus-within {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.focus\:sr-only:focus {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.focus\:not-sr-only:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-1 {
  inset: .25rem;
}

.inset-2 {
  inset: .5rem;
}

.inset-3 {
  inset: .75rem;
}

.inset-4 {
  inset: 1rem;
}

.inset-5 {
  inset: 1.25rem;
}

.inset-6 {
  inset: 1.5rem;
}

.inset-7 {
  inset: 1.75rem;
}

.inset-8 {
  inset: 2rem;
}

.inset-9 {
  inset: 2.25rem;
}

.inset-10 {
  inset: 2.5rem;
}

.inset-11 {
  inset: 2.75rem;
}

.inset-12 {
  inset: 3rem;
}

.inset-14 {
  inset: 3.5rem;
}

.inset-16 {
  inset: 4rem;
}

.inset-20 {
  inset: 5rem;
}

.inset-24 {
  inset: 6rem;
}

.inset-28 {
  inset: 7rem;
}

.inset-32 {
  inset: 8rem;
}

.inset-36 {
  inset: 9rem;
}

.inset-40 {
  inset: 10rem;
}

.inset-44 {
  inset: 11rem;
}

.inset-48 {
  inset: 12rem;
}

.inset-52 {
  inset: 13rem;
}

.inset-56 {
  inset: 14rem;
}

.inset-60 {
  inset: 15rem;
}

.inset-64 {
  inset: 16rem;
}

.inset-72 {
  inset: 18rem;
}

.inset-80 {
  inset: 20rem;
}

.inset-96 {
  inset: 24rem;
}

.inset-auto {
  inset: auto;
}

.inset-px {
  inset: 1px;
}

.inset-0\.5 {
  inset: .125rem;
}

.inset-1\.5 {
  inset: .375rem;
}

.inset-2\.5 {
  inset: .625rem;
}

.inset-3\.5 {
  inset: .875rem;
}

.-inset-0 {
  inset: 0;
}

.-inset-1 {
  inset: -.25rem;
}

.-inset-2 {
  inset: -.5rem;
}

.-inset-3 {
  inset: -.75rem;
}

.-inset-4 {
  inset: -1rem;
}

.-inset-5 {
  inset: -1.25rem;
}

.-inset-6 {
  inset: -1.5rem;
}

.-inset-7 {
  inset: -1.75rem;
}

.-inset-8 {
  inset: -2rem;
}

.-inset-9 {
  inset: -2.25rem;
}

.-inset-10 {
  inset: -2.5rem;
}

.-inset-11 {
  inset: -2.75rem;
}

.-inset-12 {
  inset: -3rem;
}

.-inset-14 {
  inset: -3.5rem;
}

.-inset-16 {
  inset: -4rem;
}

.-inset-20 {
  inset: -5rem;
}

.-inset-24 {
  inset: -6rem;
}

.-inset-28 {
  inset: -7rem;
}

.-inset-32 {
  inset: -8rem;
}

.-inset-36 {
  inset: -9rem;
}

.-inset-40 {
  inset: -10rem;
}

.-inset-44 {
  inset: -11rem;
}

.-inset-48 {
  inset: -12rem;
}

.-inset-52 {
  inset: -13rem;
}

.-inset-56 {
  inset: -14rem;
}

.-inset-60 {
  inset: -15rem;
}

.-inset-64 {
  inset: -16rem;
}

.-inset-72 {
  inset: -18rem;
}

.-inset-80 {
  inset: -20rem;
}

.-inset-96 {
  inset: -24rem;
}

.-inset-px {
  inset: -1px;
}

.-inset-0\.5 {
  inset: -.125rem;
}

.-inset-1\.5 {
  inset: -.375rem;
}

.-inset-2\.5 {
  inset: -.625rem;
}

.-inset-3\.5 {
  inset: -.875rem;
}

.inset-1\/2 {
  inset: 50%;
}

.inset-1\/3 {
  inset: 33.3333%;
}

.inset-2\/3 {
  inset: 66.6667%;
}

.inset-1\/4 {
  inset: 25%;
}

.inset-2\/4 {
  inset: 50%;
}

.inset-3\/4 {
  inset: 75%;
}

.inset-full {
  inset: 100%;
}

.-inset-1\/2 {
  inset: -50%;
}

.-inset-1\/3 {
  inset: -33.3333%;
}

.-inset-2\/3 {
  inset: -66.6667%;
}

.-inset-1\/4 {
  inset: -25%;
}

.-inset-2\/4 {
  inset: -50%;
}

.-inset-3\/4 {
  inset: -75%;
}

.-inset-full {
  inset: -100%;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-x-1 {
  left: .25rem;
  right: .25rem;
}

.inset-x-2 {
  left: .5rem;
  right: .5rem;
}

.inset-x-3 {
  left: .75rem;
  right: .75rem;
}

.inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.inset-x-5 {
  left: 1.25rem;
  right: 1.25rem;
}

.inset-x-6 {
  left: 1.5rem;
  right: 1.5rem;
}

.inset-x-7 {
  left: 1.75rem;
  right: 1.75rem;
}

.inset-x-8 {
  left: 2rem;
  right: 2rem;
}

.inset-x-9 {
  left: 2.25rem;
  right: 2.25rem;
}

.inset-x-10 {
  left: 2.5rem;
  right: 2.5rem;
}

.inset-x-11 {
  left: 2.75rem;
  right: 2.75rem;
}

.inset-x-12 {
  left: 3rem;
  right: 3rem;
}

.inset-x-14 {
  left: 3.5rem;
  right: 3.5rem;
}

.inset-x-16 {
  left: 4rem;
  right: 4rem;
}

.inset-x-20 {
  left: 5rem;
  right: 5rem;
}

.inset-x-24 {
  left: 6rem;
  right: 6rem;
}

.inset-x-28 {
  left: 7rem;
  right: 7rem;
}

.inset-x-32 {
  left: 8rem;
  right: 8rem;
}

.inset-x-36 {
  left: 9rem;
  right: 9rem;
}

.inset-x-40 {
  left: 10rem;
  right: 10rem;
}

.inset-x-44 {
  left: 11rem;
  right: 11rem;
}

.inset-x-48 {
  left: 12rem;
  right: 12rem;
}

.inset-x-52 {
  left: 13rem;
  right: 13rem;
}

.inset-x-56 {
  left: 14rem;
  right: 14rem;
}

.inset-x-60 {
  left: 15rem;
  right: 15rem;
}

.inset-x-64 {
  left: 16rem;
  right: 16rem;
}

.inset-x-72 {
  left: 18rem;
  right: 18rem;
}

.inset-x-80 {
  left: 20rem;
  right: 20rem;
}

.inset-x-96 {
  left: 24rem;
  right: 24rem;
}

.inset-x-auto {
  left: auto;
  right: auto;
}

.inset-x-px {
  left: 1px;
  right: 1px;
}

.inset-x-0\.5 {
  left: .125rem;
  right: .125rem;
}

.inset-x-1\.5 {
  left: .375rem;
  right: .375rem;
}

.inset-x-2\.5 {
  left: .625rem;
  right: .625rem;
}

.inset-x-3\.5 {
  left: .875rem;
  right: .875rem;
}

.-inset-x-0 {
  left: 0;
  right: 0;
}

.-inset-x-1 {
  left: -.25rem;
  right: -.25rem;
}

.-inset-x-2 {
  left: -.5rem;
  right: -.5rem;
}

.-inset-x-3 {
  left: -.75rem;
  right: -.75rem;
}

.-inset-x-4 {
  left: -1rem;
  right: -1rem;
}

.-inset-x-5 {
  left: -1.25rem;
  right: -1.25rem;
}

.-inset-x-6 {
  left: -1.5rem;
  right: -1.5rem;
}

.-inset-x-7 {
  left: -1.75rem;
  right: -1.75rem;
}

.-inset-x-8 {
  left: -2rem;
  right: -2rem;
}

.-inset-x-9 {
  left: -2.25rem;
  right: -2.25rem;
}

.-inset-x-10 {
  left: -2.5rem;
  right: -2.5rem;
}

.-inset-x-11 {
  left: -2.75rem;
  right: -2.75rem;
}

.-inset-x-12 {
  left: -3rem;
  right: -3rem;
}

.-inset-x-14 {
  left: -3.5rem;
  right: -3.5rem;
}

.-inset-x-16 {
  left: -4rem;
  right: -4rem;
}

.-inset-x-20 {
  left: -5rem;
  right: -5rem;
}

.-inset-x-24 {
  left: -6rem;
  right: -6rem;
}

.-inset-x-28 {
  left: -7rem;
  right: -7rem;
}

.-inset-x-32 {
  left: -8rem;
  right: -8rem;
}

.-inset-x-36 {
  left: -9rem;
  right: -9rem;
}

.-inset-x-40 {
  left: -10rem;
  right: -10rem;
}

.-inset-x-44 {
  left: -11rem;
  right: -11rem;
}

.-inset-x-48 {
  left: -12rem;
  right: -12rem;
}

.-inset-x-52 {
  left: -13rem;
  right: -13rem;
}

.-inset-x-56 {
  left: -14rem;
  right: -14rem;
}

.-inset-x-60 {
  left: -15rem;
  right: -15rem;
}

.-inset-x-64 {
  left: -16rem;
  right: -16rem;
}

.-inset-x-72 {
  left: -18rem;
  right: -18rem;
}

.-inset-x-80 {
  left: -20rem;
  right: -20rem;
}

.-inset-x-96 {
  left: -24rem;
  right: -24rem;
}

.-inset-x-px {
  left: -1px;
  right: -1px;
}

.-inset-x-0\.5 {
  left: -.125rem;
  right: -.125rem;
}

.-inset-x-1\.5 {
  left: -.375rem;
  right: -.375rem;
}

.-inset-x-2\.5 {
  left: -.625rem;
  right: -.625rem;
}

.-inset-x-3\.5 {
  left: -.875rem;
  right: -.875rem;
}

.inset-x-1\/2 {
  left: 50%;
  right: 50%;
}

.inset-x-1\/3 {
  left: 33.3333%;
  right: 33.3333%;
}

.inset-x-2\/3 {
  left: 66.6667%;
  right: 66.6667%;
}

.inset-x-1\/4 {
  left: 25%;
  right: 25%;
}

.inset-x-2\/4 {
  left: 50%;
  right: 50%;
}

.inset-x-3\/4 {
  left: 75%;
  right: 75%;
}

.inset-x-full {
  left: 100%;
  right: 100%;
}

.-inset-x-1\/2 {
  left: -50%;
  right: -50%;
}

.-inset-x-1\/3 {
  left: -33.3333%;
  right: -33.3333%;
}

.-inset-x-2\/3 {
  left: -66.6667%;
  right: -66.6667%;
}

.-inset-x-1\/4 {
  left: -25%;
  right: -25%;
}

.-inset-x-2\/4 {
  left: -50%;
  right: -50%;
}

.-inset-x-3\/4 {
  left: -75%;
  right: -75%;
}

.-inset-x-full {
  left: -100%;
  right: -100%;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.inset-y-0\.5 {
  top: .125rem;
  bottom: .125rem;
}

.inset-y-1\.5 {
  top: .375rem;
  bottom: .375rem;
}

.inset-y-2\.5 {
  top: .625rem;
  bottom: .625rem;
}

.inset-y-3\.5 {
  top: .875rem;
  bottom: .875rem;
}

.-inset-y-0 {
  top: 0;
  bottom: 0;
}

.-inset-y-1 {
  top: -.25rem;
  bottom: -.25rem;
}

.-inset-y-2 {
  top: -.5rem;
  bottom: -.5rem;
}

.-inset-y-3 {
  top: -.75rem;
  bottom: -.75rem;
}

.-inset-y-4 {
  top: -1rem;
  bottom: -1rem;
}

.-inset-y-5 {
  top: -1.25rem;
  bottom: -1.25rem;
}

.-inset-y-6 {
  top: -1.5rem;
  bottom: -1.5rem;
}

.-inset-y-7 {
  top: -1.75rem;
  bottom: -1.75rem;
}

.-inset-y-8 {
  top: -2rem;
  bottom: -2rem;
}

.-inset-y-9 {
  top: -2.25rem;
  bottom: -2.25rem;
}

.-inset-y-10 {
  top: -2.5rem;
  bottom: -2.5rem;
}

.-inset-y-11 {
  top: -2.75rem;
  bottom: -2.75rem;
}

.-inset-y-12 {
  top: -3rem;
  bottom: -3rem;
}

.-inset-y-14 {
  top: -3.5rem;
  bottom: -3.5rem;
}

.-inset-y-16 {
  top: -4rem;
  bottom: -4rem;
}

.-inset-y-20 {
  top: -5rem;
  bottom: -5rem;
}

.-inset-y-24 {
  top: -6rem;
  bottom: -6rem;
}

.-inset-y-28 {
  top: -7rem;
  bottom: -7rem;
}

.-inset-y-32 {
  top: -8rem;
  bottom: -8rem;
}

.-inset-y-36 {
  top: -9rem;
  bottom: -9rem;
}

.-inset-y-40 {
  top: -10rem;
  bottom: -10rem;
}

.-inset-y-44 {
  top: -11rem;
  bottom: -11rem;
}

.-inset-y-48 {
  top: -12rem;
  bottom: -12rem;
}

.-inset-y-52 {
  top: -13rem;
  bottom: -13rem;
}

.-inset-y-56 {
  top: -14rem;
  bottom: -14rem;
}

.-inset-y-60 {
  top: -15rem;
  bottom: -15rem;
}

.-inset-y-64 {
  top: -16rem;
  bottom: -16rem;
}

.-inset-y-72 {
  top: -18rem;
  bottom: -18rem;
}

.-inset-y-80 {
  top: -20rem;
  bottom: -20rem;
}

.-inset-y-96 {
  top: -24rem;
  bottom: -24rem;
}

.-inset-y-px {
  top: -1px;
  bottom: -1px;
}

.-inset-y-0\.5 {
  top: -.125rem;
  bottom: -.125rem;
}

.-inset-y-1\.5 {
  top: -.375rem;
  bottom: -.375rem;
}

.-inset-y-2\.5 {
  top: -.625rem;
  bottom: -.625rem;
}

.-inset-y-3\.5 {
  top: -.875rem;
  bottom: -.875rem;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.inset-y-1\/3 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-y-2\/3 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.inset-y-full {
  top: 100%;
  bottom: 100%;
}

.-inset-y-1\/2 {
  top: -50%;
  bottom: -50%;
}

.-inset-y-1\/3 {
  top: -33.3333%;
  bottom: -33.3333%;
}

.-inset-y-2\/3 {
  top: -66.6667%;
  bottom: -66.6667%;
}

.-inset-y-1\/4 {
  top: -25%;
  bottom: -25%;
}

.-inset-y-2\/4 {
  top: -50%;
  bottom: -50%;
}

.-inset-y-3\/4 {
  top: -75%;
  bottom: -75%;
}

.-inset-y-full {
  top: -100%;
  bottom: -100%;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.top-7 {
  top: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.top-9 {
  top: 2.25rem;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-20 {
  top: 5rem;
}

.top-24 {
  top: 6rem;
}

.top-28 {
  top: 7rem;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-40 {
  top: 10rem;
}

.top-44 {
  top: 11rem;
}

.top-48 {
  top: 12rem;
}

.top-52 {
  top: 13rem;
}

.top-56 {
  top: 14rem;
}

.top-60 {
  top: 15rem;
}

.top-64 {
  top: 16rem;
}

.top-72 {
  top: 18rem;
}

.top-80 {
  top: 20rem;
}

.top-96 {
  top: 24rem;
}

.top-auto {
  top: auto;
}

.top-px {
  top: 1px;
}

.top-0\.5 {
  top: .125rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-3\.5 {
  top: .875rem;
}

.-top-0 {
  top: 0;
}

.-top-1 {
  top: -.25rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-4 {
  top: -1rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-6 {
  top: -1.5rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-8 {
  top: -2rem;
}

.-top-9 {
  top: -2.25rem;
}

.-top-10 {
  top: -2.5rem;
}

.-top-11 {
  top: -2.75rem;
}

.-top-12 {
  top: -3rem;
}

.-top-14 {
  top: -3.5rem;
}

.-top-16 {
  top: -4rem;
}

.-top-20 {
  top: -5rem;
}

.-top-24 {
  top: -6rem;
}

.-top-28 {
  top: -7rem;
}

.-top-32 {
  top: -8rem;
}

.-top-36 {
  top: -9rem;
}

.-top-40 {
  top: -10rem;
}

.-top-44 {
  top: -11rem;
}

.-top-48 {
  top: -12rem;
}

.-top-52 {
  top: -13rem;
}

.-top-56 {
  top: -14rem;
}

.-top-60 {
  top: -15rem;
}

.-top-64 {
  top: -16rem;
}

.-top-72 {
  top: -18rem;
}

.-top-80 {
  top: -20rem;
}

.-top-96 {
  top: -24rem;
}

.-top-px {
  top: -1px;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-1\.5 {
  top: -.375rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.-top-3\.5 {
  top: -.875rem;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-2\/3 {
  top: 66.6667%;
}

.top-1\/4 {
  top: 25%;
}

.top-2\/4 {
  top: 50%;
}

.top-3\/4 {
  top: 75%;
}

.top-full {
  top: 100%;
}

.-top-1\/2 {
  top: -50%;
}

.-top-1\/3 {
  top: -33.3333%;
}

.-top-2\/3 {
  top: -66.6667%;
}

.-top-1\/4 {
  top: -25%;
}

.-top-2\/4 {
  top: -50%;
}

.-top-3\/4 {
  top: -75%;
}

.-top-full {
  top: -100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.right-6 {
  right: 1.5rem;
}

.right-7 {
  right: 1.75rem;
}

.right-8 {
  right: 2rem;
}

.right-9 {
  right: 2.25rem;
}

.right-10 {
  right: 2.5rem;
}

.right-11 {
  right: 2.75rem;
}

.right-12 {
  right: 3rem;
}

.right-14 {
  right: 3.5rem;
}

.right-16 {
  right: 4rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-28 {
  right: 7rem;
}

.right-32 {
  right: 8rem;
}

.right-36 {
  right: 9rem;
}

.right-40 {
  right: 10rem;
}

.right-44 {
  right: 11rem;
}

.right-48 {
  right: 12rem;
}

.right-52 {
  right: 13rem;
}

.right-56 {
  right: 14rem;
}

.right-60 {
  right: 15rem;
}

.right-64 {
  right: 16rem;
}

.right-72 {
  right: 18rem;
}

.right-80 {
  right: 20rem;
}

.right-96 {
  right: 24rem;
}

.right-auto {
  right: auto;
}

.right-px {
  right: 1px;
}

.right-0\.5 {
  right: .125rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-3\.5 {
  right: .875rem;
}

.-right-0 {
  right: 0;
}

.-right-1 {
  right: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-3 {
  right: -.75rem;
}

.-right-4 {
  right: -1rem;
}

.-right-5 {
  right: -1.25rem;
}

.-right-6 {
  right: -1.5rem;
}

.-right-7 {
  right: -1.75rem;
}

.-right-8 {
  right: -2rem;
}

.-right-9 {
  right: -2.25rem;
}

.-right-10 {
  right: -2.5rem;
}

.-right-11 {
  right: -2.75rem;
}

.-right-12 {
  right: -3rem;
}

.-right-14 {
  right: -3.5rem;
}

.-right-16 {
  right: -4rem;
}

.-right-20 {
  right: -5rem;
}

.-right-24 {
  right: -6rem;
}

.-right-28 {
  right: -7rem;
}

.-right-32 {
  right: -8rem;
}

.-right-36 {
  right: -9rem;
}

.-right-40 {
  right: -10rem;
}

.-right-44 {
  right: -11rem;
}

.-right-48 {
  right: -12rem;
}

.-right-52 {
  right: -13rem;
}

.-right-56 {
  right: -14rem;
}

.-right-60 {
  right: -15rem;
}

.-right-64 {
  right: -16rem;
}

.-right-72 {
  right: -18rem;
}

.-right-80 {
  right: -20rem;
}

.-right-96 {
  right: -24rem;
}

.-right-px {
  right: -1px;
}

.-right-0\.5 {
  right: -.125rem;
}

.-right-1\.5 {
  right: -.375rem;
}

.-right-2\.5 {
  right: -.625rem;
}

.-right-3\.5 {
  right: -.875rem;
}

.right-1\/2 {
  right: 50%;
}

.right-1\/3 {
  right: 33.3333%;
}

.right-2\/3 {
  right: 66.6667%;
}

.right-1\/4 {
  right: 25%;
}

.right-2\/4 {
  right: 50%;
}

.right-3\/4 {
  right: 75%;
}

.right-full {
  right: 100%;
}

.-right-1\/2 {
  right: -50%;
}

.-right-1\/3 {
  right: -33.3333%;
}

.-right-2\/3 {
  right: -66.6667%;
}

.-right-1\/4 {
  right: -25%;
}

.-right-2\/4 {
  right: -50%;
}

.-right-3\/4 {
  right: -75%;
}

.-right-full {
  right: -100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-36 {
  bottom: 9rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-44 {
  bottom: 11rem;
}

.bottom-48 {
  bottom: 12rem;
}

.bottom-52 {
  bottom: 13rem;
}

.bottom-56 {
  bottom: 14rem;
}

.bottom-60 {
  bottom: 15rem;
}

.bottom-64 {
  bottom: 16rem;
}

.bottom-72 {
  bottom: 18rem;
}

.bottom-80 {
  bottom: 20rem;
}

.bottom-96 {
  bottom: 24rem;
}

.bottom-auto {
  bottom: auto;
}

.bottom-px {
  bottom: 1px;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.-bottom-0 {
  bottom: 0;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-bottom-7 {
  bottom: -1.75rem;
}

.-bottom-8 {
  bottom: -2rem;
}

.-bottom-9 {
  bottom: -2.25rem;
}

.-bottom-10 {
  bottom: -2.5rem;
}

.-bottom-11 {
  bottom: -2.75rem;
}

.-bottom-12 {
  bottom: -3rem;
}

.-bottom-14 {
  bottom: -3.5rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.-bottom-20 {
  bottom: -5rem;
}

.-bottom-24 {
  bottom: -6rem;
}

.-bottom-28 {
  bottom: -7rem;
}

.-bottom-32 {
  bottom: -8rem;
}

.-bottom-36 {
  bottom: -9rem;
}

.-bottom-40 {
  bottom: -10rem;
}

.-bottom-44 {
  bottom: -11rem;
}

.-bottom-48 {
  bottom: -12rem;
}

.-bottom-52 {
  bottom: -13rem;
}

.-bottom-56 {
  bottom: -14rem;
}

.-bottom-60 {
  bottom: -15rem;
}

.-bottom-64 {
  bottom: -16rem;
}

.-bottom-72 {
  bottom: -18rem;
}

.-bottom-80 {
  bottom: -20rem;
}

.-bottom-96 {
  bottom: -24rem;
}

.-bottom-px {
  bottom: -1px;
}

.-bottom-0\.5 {
  bottom: -.125rem;
}

.-bottom-1\.5 {
  bottom: -.375rem;
}

.-bottom-2\.5 {
  bottom: -.625rem;
}

.-bottom-3\.5 {
  bottom: -.875rem;
}

.bottom-1\/2 {
  bottom: 50%;
}

.bottom-1\/3 {
  bottom: 33.3333%;
}

.bottom-2\/3 {
  bottom: 66.6667%;
}

.bottom-1\/4 {
  bottom: 25%;
}

.bottom-2\/4 {
  bottom: 50%;
}

.bottom-3\/4 {
  bottom: 75%;
}

.bottom-full {
  bottom: 100%;
}

.-bottom-1\/2 {
  bottom: -50%;
}

.-bottom-1\/3 {
  bottom: -33.3333%;
}

.-bottom-2\/3 {
  bottom: -66.6667%;
}

.-bottom-1\/4 {
  bottom: -25%;
}

.-bottom-2\/4 {
  bottom: -50%;
}

.-bottom-3\/4 {
  bottom: -75%;
}

.-bottom-full {
  bottom: -100%;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-2 {
  left: .5rem;
}

.left-3 {
  left: .75rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-6 {
  left: 1.5rem;
}

.left-7 {
  left: 1.75rem;
}

.left-8 {
  left: 2rem;
}

.left-9 {
  left: 2.25rem;
}

.left-10 {
  left: 2.5rem;
}

.left-11 {
  left: 2.75rem;
}

.left-12 {
  left: 3rem;
}

.left-14 {
  left: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.left-20 {
  left: 5rem;
}

.left-24 {
  left: 6rem;
}

.left-28 {
  left: 7rem;
}

.left-32 {
  left: 8rem;
}

.left-36 {
  left: 9rem;
}

.left-40 {
  left: 10rem;
}

.left-44 {
  left: 11rem;
}

.left-48 {
  left: 12rem;
}

.left-52 {
  left: 13rem;
}

.left-56 {
  left: 14rem;
}

.left-60 {
  left: 15rem;
}

.left-64 {
  left: 16rem;
}

.left-72 {
  left: 18rem;
}

.left-80 {
  left: 20rem;
}

.left-96 {
  left: 24rem;
}

.left-auto {
  left: auto;
}

.left-px {
  left: 1px;
}

.left-0\.5 {
  left: .125rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-3\.5 {
  left: .875rem;
}

.-left-0 {
  left: 0;
}

.-left-1 {
  left: -.25rem;
}

.-left-2 {
  left: -.5rem;
}

.-left-3 {
  left: -.75rem;
}

.-left-4 {
  left: -1rem;
}

.-left-5 {
  left: -1.25rem;
}

.-left-6 {
  left: -1.5rem;
}

.-left-7 {
  left: -1.75rem;
}

.-left-8 {
  left: -2rem;
}

.-left-9 {
  left: -2.25rem;
}

.-left-10 {
  left: -2.5rem;
}

.-left-11 {
  left: -2.75rem;
}

.-left-12 {
  left: -3rem;
}

.-left-14 {
  left: -3.5rem;
}

.-left-16 {
  left: -4rem;
}

.-left-20 {
  left: -5rem;
}

.-left-24 {
  left: -6rem;
}

.-left-28 {
  left: -7rem;
}

.-left-32 {
  left: -8rem;
}

.-left-36 {
  left: -9rem;
}

.-left-40 {
  left: -10rem;
}

.-left-44 {
  left: -11rem;
}

.-left-48 {
  left: -12rem;
}

.-left-52 {
  left: -13rem;
}

.-left-56 {
  left: -14rem;
}

.-left-60 {
  left: -15rem;
}

.-left-64 {
  left: -16rem;
}

.-left-72 {
  left: -18rem;
}

.-left-80 {
  left: -20rem;
}

.-left-96 {
  left: -24rem;
}

.-left-px {
  left: -1px;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-1\.5 {
  left: -.375rem;
}

.-left-2\.5 {
  left: -.625rem;
}

.-left-3\.5 {
  left: -.875rem;
}

.left-1\/2 {
  left: 50%;
}

.left-1\/3 {
  left: 33.3333%;
}

.left-2\/3 {
  left: 66.6667%;
}

.left-1\/4 {
  left: 25%;
}

.left-2\/4 {
  left: 50%;
}

.left-3\/4 {
  left: 75%;
}

.left-full {
  left: 100%;
}

.-left-1\/2 {
  left: -50%;
}

.-left-1\/3 {
  left: -33.3333%;
}

.-left-2\/3 {
  left: -66.6667%;
}

.-left-1\/4 {
  left: -25%;
}

.-left-2\/4 {
  left: -50%;
}

.-left-3\/4 {
  left: -75%;
}

.-left-full {
  left: -100%;
}

.isolate {
  isolation: isolate;
}

.isolation-auto {
  isolation: auto;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

.focus-within\:z-0:focus-within {
  z-index: 0;
}

.focus-within\:z-10:focus-within {
  z-index: 10;
}

.focus-within\:z-20:focus-within {
  z-index: 20;
}

.focus-within\:z-30:focus-within {
  z-index: 30;
}

.focus-within\:z-40:focus-within {
  z-index: 40;
}

.focus-within\:z-50:focus-within {
  z-index: 50;
}

.focus-within\:z-auto:focus-within {
  z-index: auto;
}

.focus\:z-0:focus {
  z-index: 0;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:z-20:focus {
  z-index: 20;
}

.focus\:z-30:focus {
  z-index: 30;
}

.focus\:z-40:focus {
  z-index: 40;
}

.focus\:z-50:focus {
  z-index: 50;
}

.focus\:z-auto:focus {
  z-index: auto;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-7 {
  margin: 1.75rem;
}

.m-8 {
  margin: 2rem;
}

.m-9 {
  margin: 2.25rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}

.m-12 {
  margin: 3rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-28 {
  margin: 7rem;
}

.m-32 {
  margin: 8rem;
}

.m-36 {
  margin: 9rem;
}

.m-40 {
  margin: 10rem;
}

.m-44 {
  margin: 11rem;
}

.m-48 {
  margin: 12rem;
}

.m-52 {
  margin: 13rem;
}

.m-56 {
  margin: 14rem;
}

.m-60 {
  margin: 15rem;
}

.m-64 {
  margin: 16rem;
}

.m-72 {
  margin: 18rem;
}

.m-80 {
  margin: 20rem;
}

.m-96 {
  margin: 24rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.m-0\.5 {
  margin: .125rem;
}

.m-1\.5 {
  margin: .375rem;
}

.m-2\.5 {
  margin: .625rem;
}

.m-3\.5 {
  margin: .875rem;
}

.-m-0 {
  margin: 0;
}

.-m-1 {
  margin: -.25rem;
}

.-m-2 {
  margin: -.5rem;
}

.-m-3 {
  margin: -.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-7 {
  margin: -1.75rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-9 {
  margin: -2.25rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-11 {
  margin: -2.75rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-14 {
  margin: -3.5rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-28 {
  margin: -7rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-36 {
  margin: -9rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-44 {
  margin: -11rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-52 {
  margin: -13rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-60 {
  margin: -15rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-72 {
  margin: -18rem;
}

.-m-80 {
  margin: -20rem;
}

.-m-96 {
  margin: -24rem;
}

.-m-px {
  margin: -1px;
}

.-m-0\.5 {
  margin: -.125rem;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.-m-3\.5 {
  margin: -.875rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-3\.5 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-mx-2\.5 {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.-mx-3\.5 {
  margin-left: -.875rem;
  margin-right: -.875rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.my-3\.5 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

.-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-my-0\.5 {
  margin-top: -.125rem;
  margin-bottom: -.125rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-my-2\.5 {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

.-my-3\.5 {
  margin-top: -.875rem;
  margin-bottom: -.875rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mt-72 {
  margin-top: 18rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mt-44 {
  margin-top: -11rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-52 {
  margin-top: -13rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mt-60 {
  margin-top: -15rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mt-72 {
  margin-top: -18rem;
}

.-mt-80 {
  margin-top: -20rem;
}

.-mt-96 {
  margin-top: -24rem;
}

.-mt-px {
  margin-top: -1px;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1\.5 {
  margin-top: -.375rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-3\.5 {
  margin-top: -.875rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mr-36 {
  margin-right: 9rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mr-44 {
  margin-right: 11rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mr-52 {
  margin-right: 13rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mr-60 {
  margin-right: 15rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mr-72 {
  margin-right: 18rem;
}

.mr-80 {
  margin-right: 20rem;
}

.mr-96 {
  margin-right: 24rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-px {
  margin-right: 1px;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mr-9 {
  margin-right: -2.25rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mr-11 {
  margin-right: -2.75rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mr-14 {
  margin-right: -3.5rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mr-28 {
  margin-right: -7rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mr-36 {
  margin-right: -9rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mr-44 {
  margin-right: -11rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mr-52 {
  margin-right: -13rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mr-60 {
  margin-right: -15rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mr-72 {
  margin-right: -18rem;
}

.-mr-80 {
  margin-right: -20rem;
}

.-mr-96 {
  margin-right: -24rem;
}

.-mr-px {
  margin-right: -1px;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mr-3\.5 {
  margin-right: -.875rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.mb-72 {
  margin-bottom: 18rem;
}

.mb-80 {
  margin-bottom: 20rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-px {
  margin-bottom: 1px;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.-mb-0 {
  margin-bottom: 0;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-7 {
  margin-bottom: -1.75rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-9 {
  margin-bottom: -2.25rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-11 {
  margin-bottom: -2.75rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-mb-28 {
  margin-bottom: -7rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-mb-36 {
  margin-bottom: -9rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-mb-44 {
  margin-bottom: -11rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-mb-52 {
  margin-bottom: -13rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-mb-60 {
  margin-bottom: -15rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-mb-72 {
  margin-bottom: -18rem;
}

.-mb-80 {
  margin-bottom: -20rem;
}

.-mb-96 {
  margin-bottom: -24rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-mb-1\.5 {
  margin-bottom: -.375rem;
}

.-mb-2\.5 {
  margin-bottom: -.625rem;
}

.-mb-3\.5 {
  margin-bottom: -.875rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-28 {
  margin-left: 7rem;
}

.ml-32 {
  margin-left: 8rem;
}

.ml-36 {
  margin-left: 9rem;
}

.ml-40 {
  margin-left: 10rem;
}

.ml-44 {
  margin-left: 11rem;
}

.ml-48 {
  margin-left: 12rem;
}

.ml-52 {
  margin-left: 13rem;
}

.ml-56 {
  margin-left: 14rem;
}

.ml-60 {
  margin-left: 15rem;
}

.ml-64 {
  margin-left: 16rem;
}

.ml-72 {
  margin-left: 18rem;
}

.ml-80 {
  margin-left: 20rem;
}

.ml-96 {
  margin-left: 24rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-11 {
  margin-left: -2.75rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-ml-14 {
  margin-left: -3.5rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-ml-28 {
  margin-left: -7rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-ml-36 {
  margin-left: -9rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-ml-44 {
  margin-left: -11rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-ml-52 {
  margin-left: -13rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-ml-60 {
  margin-left: -15rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-ml-72 {
  margin-left: -18rem;
}

.-ml-80 {
  margin-left: -20rem;
}

.-ml-96 {
  margin-left: -24rem;
}

.-ml-px {
  margin-left: -1px;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-ml-3\.5 {
  margin-left: -.875rem;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: .125rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-1 {
  max-height: .25rem;
}

.max-h-2 {
  max-height: .5rem;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-7 {
  max-height: 1.75rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-9 {
  max-height: 2.25rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-11 {
  max-height: 2.75rem;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-44 {
  max-height: 11rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-52 {
  max-height: 13rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-px {
  max-height: 1px;
}

.max-h-0\.5 {
  max-height: .125rem;
}

.max-h-1\.5 {
  max-height: .375rem;
}

.max-h-2\.5 {
  max-height: .625rem;
}

.max-h-3\.5 {
  max-height: .875rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-2 {
  width: .5rem;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: .125rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min {
  min-width: min-content;
}

.min-w-max {
  min-width: max-content;
}

.max-w-0 {
  max-width: 0;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-xxl {
  max-width: 1560px;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-left {
  transform-origin: 0;
}

.origin-top-left {
  transform-origin: 0 0;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.translate-x-0 {
  --tw-translate-x: 0px;
}

.translate-x-1 {
  --tw-translate-x: .25rem;
}

.translate-x-2 {
  --tw-translate-x: .5rem;
}

.translate-x-3 {
  --tw-translate-x: .75rem;
}

.translate-x-4 {
  --tw-translate-x: 1rem;
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
}

.translate-x-7 {
  --tw-translate-x: 1.75rem;
}

.translate-x-8 {
  --tw-translate-x: 2rem;
}

.translate-x-9 {
  --tw-translate-x: 2.25rem;
}

.translate-x-10 {
  --tw-translate-x: 2.5rem;
}

.translate-x-11 {
  --tw-translate-x: 2.75rem;
}

.translate-x-12 {
  --tw-translate-x: 3rem;
}

.translate-x-14 {
  --tw-translate-x: 3.5rem;
}

.translate-x-16 {
  --tw-translate-x: 4rem;
}

.translate-x-20 {
  --tw-translate-x: 5rem;
}

.translate-x-24 {
  --tw-translate-x: 6rem;
}

.translate-x-28 {
  --tw-translate-x: 7rem;
}

.translate-x-32 {
  --tw-translate-x: 8rem;
}

.translate-x-36 {
  --tw-translate-x: 9rem;
}

.translate-x-40 {
  --tw-translate-x: 10rem;
}

.translate-x-44 {
  --tw-translate-x: 11rem;
}

.translate-x-48 {
  --tw-translate-x: 12rem;
}

.translate-x-52 {
  --tw-translate-x: 13rem;
}

.translate-x-56 {
  --tw-translate-x: 14rem;
}

.translate-x-60 {
  --tw-translate-x: 15rem;
}

.translate-x-64 {
  --tw-translate-x: 16rem;
}

.translate-x-72 {
  --tw-translate-x: 18rem;
}

.translate-x-80 {
  --tw-translate-x: 20rem;
}

.translate-x-96 {
  --tw-translate-x: 24rem;
}

.translate-x-px {
  --tw-translate-x: 1px;
}

.translate-x-0\.5 {
  --tw-translate-x: .125rem;
}

.translate-x-1\.5 {
  --tw-translate-x: .375rem;
}

.translate-x-2\.5 {
  --tw-translate-x: .625rem;
}

.translate-x-3\.5 {
  --tw-translate-x: .875rem;
}

.-translate-x-0 {
  --tw-translate-x: 0px;
}

.-translate-x-1 {
  --tw-translate-x: -.25rem;
}

.-translate-x-2 {
  --tw-translate-x: -.5rem;
}

.-translate-x-3 {
  --tw-translate-x: -.75rem;
}

.-translate-x-4 {
  --tw-translate-x: -1rem;
}

.-translate-x-5 {
  --tw-translate-x: -1.25rem;
}

.-translate-x-6 {
  --tw-translate-x: -1.5rem;
}

.-translate-x-7 {
  --tw-translate-x: -1.75rem;
}

.-translate-x-8 {
  --tw-translate-x: -2rem;
}

.-translate-x-9 {
  --tw-translate-x: -2.25rem;
}

.-translate-x-10 {
  --tw-translate-x: -2.5rem;
}

.-translate-x-11 {
  --tw-translate-x: -2.75rem;
}

.-translate-x-12 {
  --tw-translate-x: -3rem;
}

.-translate-x-14 {
  --tw-translate-x: -3.5rem;
}

.-translate-x-16 {
  --tw-translate-x: -4rem;
}

.-translate-x-20 {
  --tw-translate-x: -5rem;
}

.-translate-x-24 {
  --tw-translate-x: -6rem;
}

.-translate-x-28 {
  --tw-translate-x: -7rem;
}

.-translate-x-32 {
  --tw-translate-x: -8rem;
}

.-translate-x-36 {
  --tw-translate-x: -9rem;
}

.-translate-x-40 {
  --tw-translate-x: -10rem;
}

.-translate-x-44 {
  --tw-translate-x: -11rem;
}

.-translate-x-48 {
  --tw-translate-x: -12rem;
}

.-translate-x-52 {
  --tw-translate-x: -13rem;
}

.-translate-x-56 {
  --tw-translate-x: -14rem;
}

.-translate-x-60 {
  --tw-translate-x: -15rem;
}

.-translate-x-64 {
  --tw-translate-x: -16rem;
}

.-translate-x-72 {
  --tw-translate-x: -18rem;
}

.-translate-x-80 {
  --tw-translate-x: -20rem;
}

.-translate-x-96 {
  --tw-translate-x: -24rem;
}

.-translate-x-px {
  --tw-translate-x: -1px;
}

.-translate-x-0\.5 {
  --tw-translate-x: -.125rem;
}

.-translate-x-1\.5 {
  --tw-translate-x: -.375rem;
}

.-translate-x-2\.5 {
  --tw-translate-x: -.625rem;
}

.-translate-x-3\.5 {
  --tw-translate-x: -.875rem;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
}

.translate-x-1\/3 {
  --tw-translate-x: 33.3333%;
}

.translate-x-2\/3 {
  --tw-translate-x: 66.6667%;
}

.translate-x-1\/4 {
  --tw-translate-x: 25%;
}

.translate-x-2\/4 {
  --tw-translate-x: 50%;
}

.translate-x-3\/4 {
  --tw-translate-x: 75%;
}

.translate-x-full {
  --tw-translate-x: 100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.-translate-x-1\/3 {
  --tw-translate-x: -33.3333%;
}

.-translate-x-2\/3 {
  --tw-translate-x: -66.6667%;
}

.-translate-x-1\/4 {
  --tw-translate-x: -25%;
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
}

.-translate-x-3\/4 {
  --tw-translate-x: -75%;
}

.-translate-x-full {
  --tw-translate-x: -100%;
}

.translate-y-0 {
  --tw-translate-y: 0px;
}

.translate-y-1 {
  --tw-translate-y: .25rem;
}

.translate-y-2 {
  --tw-translate-y: .5rem;
}

.translate-y-3 {
  --tw-translate-y: .75rem;
}

.translate-y-4 {
  --tw-translate-y: 1rem;
}

.translate-y-5 {
  --tw-translate-y: 1.25rem;
}

.translate-y-6 {
  --tw-translate-y: 1.5rem;
}

.translate-y-7 {
  --tw-translate-y: 1.75rem;
}

.translate-y-8 {
  --tw-translate-y: 2rem;
}

.translate-y-9 {
  --tw-translate-y: 2.25rem;
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
}

.translate-y-11 {
  --tw-translate-y: 2.75rem;
}

.translate-y-12 {
  --tw-translate-y: 3rem;
}

.translate-y-14 {
  --tw-translate-y: 3.5rem;
}

.translate-y-16 {
  --tw-translate-y: 4rem;
}

.translate-y-20 {
  --tw-translate-y: 5rem;
}

.translate-y-24 {
  --tw-translate-y: 6rem;
}

.translate-y-28 {
  --tw-translate-y: 7rem;
}

.translate-y-32 {
  --tw-translate-y: 8rem;
}

.translate-y-36 {
  --tw-translate-y: 9rem;
}

.translate-y-40 {
  --tw-translate-y: 10rem;
}

.translate-y-44 {
  --tw-translate-y: 11rem;
}

.translate-y-48 {
  --tw-translate-y: 12rem;
}

.translate-y-52 {
  --tw-translate-y: 13rem;
}

.translate-y-56 {
  --tw-translate-y: 14rem;
}

.translate-y-60 {
  --tw-translate-y: 15rem;
}

.translate-y-64 {
  --tw-translate-y: 16rem;
}

.translate-y-72 {
  --tw-translate-y: 18rem;
}

.translate-y-80 {
  --tw-translate-y: 20rem;
}

.translate-y-96 {
  --tw-translate-y: 24rem;
}

.translate-y-px {
  --tw-translate-y: 1px;
}

.translate-y-0\.5 {
  --tw-translate-y: .125rem;
}

.translate-y-1\.5 {
  --tw-translate-y: .375rem;
}

.translate-y-2\.5 {
  --tw-translate-y: .625rem;
}

.translate-y-3\.5 {
  --tw-translate-y: .875rem;
}

.-translate-y-0 {
  --tw-translate-y: 0px;
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
}

.-translate-y-2 {
  --tw-translate-y: -.5rem;
}

.-translate-y-3 {
  --tw-translate-y: -.75rem;
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
}

.-translate-y-5 {
  --tw-translate-y: -1.25rem;
}

.-translate-y-6 {
  --tw-translate-y: -1.5rem;
}

.-translate-y-7 {
  --tw-translate-y: -1.75rem;
}

.-translate-y-8 {
  --tw-translate-y: -2rem;
}

.-translate-y-9 {
  --tw-translate-y: -2.25rem;
}

.-translate-y-10 {
  --tw-translate-y: -2.5rem;
}

.-translate-y-11 {
  --tw-translate-y: -2.75rem;
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
}

.-translate-y-14 {
  --tw-translate-y: -3.5rem;
}

.-translate-y-16 {
  --tw-translate-y: -4rem;
}

.-translate-y-20 {
  --tw-translate-y: -5rem;
}

.-translate-y-24 {
  --tw-translate-y: -6rem;
}

.-translate-y-28 {
  --tw-translate-y: -7rem;
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
}

.-translate-y-36 {
  --tw-translate-y: -9rem;
}

.-translate-y-40 {
  --tw-translate-y: -10rem;
}

.-translate-y-44 {
  --tw-translate-y: -11rem;
}

.-translate-y-48 {
  --tw-translate-y: -12rem;
}

.-translate-y-52 {
  --tw-translate-y: -13rem;
}

.-translate-y-56 {
  --tw-translate-y: -14rem;
}

.-translate-y-60 {
  --tw-translate-y: -15rem;
}

.-translate-y-64 {
  --tw-translate-y: -16rem;
}

.-translate-y-72 {
  --tw-translate-y: -18rem;
}

.-translate-y-80 {
  --tw-translate-y: -20rem;
}

.-translate-y-96 {
  --tw-translate-y: -24rem;
}

.-translate-y-px {
  --tw-translate-y: -1px;
}

.-translate-y-0\.5 {
  --tw-translate-y: -.125rem;
}

.-translate-y-1\.5 {
  --tw-translate-y: -.375rem;
}

.-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
}

.-translate-y-3\.5 {
  --tw-translate-y: -.875rem;
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
}

.translate-y-1\/3 {
  --tw-translate-y: 33.3333%;
}

.translate-y-2\/3 {
  --tw-translate-y: 66.6667%;
}

.translate-y-1\/4 {
  --tw-translate-y: 25%;
}

.translate-y-2\/4 {
  --tw-translate-y: 50%;
}

.translate-y-3\/4 {
  --tw-translate-y: 75%;
}

.translate-y-full {
  --tw-translate-y: 100%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.-translate-y-1\/3 {
  --tw-translate-y: -33.3333%;
}

.-translate-y-2\/3 {
  --tw-translate-y: -66.6667%;
}

.-translate-y-1\/4 {
  --tw-translate-y: -25%;
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%;
}

.-translate-y-3\/4 {
  --tw-translate-y: -75%;
}

.-translate-y-full {
  --tw-translate-y: -100%;
}

.hover\:translate-x-0:hover {
  --tw-translate-x: 0px;
}

.hover\:translate-x-1:hover {
  --tw-translate-x: .25rem;
}

.hover\:translate-x-2:hover {
  --tw-translate-x: .5rem;
}

.hover\:translate-x-3:hover {
  --tw-translate-x: .75rem;
}

.hover\:translate-x-4:hover {
  --tw-translate-x: 1rem;
}

.hover\:translate-x-5:hover {
  --tw-translate-x: 1.25rem;
}

.hover\:translate-x-6:hover {
  --tw-translate-x: 1.5rem;
}

.hover\:translate-x-7:hover {
  --tw-translate-x: 1.75rem;
}

.hover\:translate-x-8:hover {
  --tw-translate-x: 2rem;
}

.hover\:translate-x-9:hover {
  --tw-translate-x: 2.25rem;
}

.hover\:translate-x-10:hover {
  --tw-translate-x: 2.5rem;
}

.hover\:translate-x-11:hover {
  --tw-translate-x: 2.75rem;
}

.hover\:translate-x-12:hover {
  --tw-translate-x: 3rem;
}

.hover\:translate-x-14:hover {
  --tw-translate-x: 3.5rem;
}

.hover\:translate-x-16:hover {
  --tw-translate-x: 4rem;
}

.hover\:translate-x-20:hover {
  --tw-translate-x: 5rem;
}

.hover\:translate-x-24:hover {
  --tw-translate-x: 6rem;
}

.hover\:translate-x-28:hover {
  --tw-translate-x: 7rem;
}

.hover\:translate-x-32:hover {
  --tw-translate-x: 8rem;
}

.hover\:translate-x-36:hover {
  --tw-translate-x: 9rem;
}

.hover\:translate-x-40:hover {
  --tw-translate-x: 10rem;
}

.hover\:translate-x-44:hover {
  --tw-translate-x: 11rem;
}

.hover\:translate-x-48:hover {
  --tw-translate-x: 12rem;
}

.hover\:translate-x-52:hover {
  --tw-translate-x: 13rem;
}

.hover\:translate-x-56:hover {
  --tw-translate-x: 14rem;
}

.hover\:translate-x-60:hover {
  --tw-translate-x: 15rem;
}

.hover\:translate-x-64:hover {
  --tw-translate-x: 16rem;
}

.hover\:translate-x-72:hover {
  --tw-translate-x: 18rem;
}

.hover\:translate-x-80:hover {
  --tw-translate-x: 20rem;
}

.hover\:translate-x-96:hover {
  --tw-translate-x: 24rem;
}

.hover\:translate-x-px:hover {
  --tw-translate-x: 1px;
}

.hover\:translate-x-0\.5:hover {
  --tw-translate-x: .125rem;
}

.hover\:translate-x-1\.5:hover {
  --tw-translate-x: .375rem;
}

.hover\:translate-x-2\.5:hover {
  --tw-translate-x: .625rem;
}

.hover\:translate-x-3\.5:hover {
  --tw-translate-x: .875rem;
}

.hover\:-translate-x-0:hover {
  --tw-translate-x: 0px;
}

.hover\:-translate-x-1:hover {
  --tw-translate-x: -.25rem;
}

.hover\:-translate-x-2:hover {
  --tw-translate-x: -.5rem;
}

.hover\:-translate-x-3:hover {
  --tw-translate-x: -.75rem;
}

.hover\:-translate-x-4:hover {
  --tw-translate-x: -1rem;
}

.hover\:-translate-x-5:hover {
  --tw-translate-x: -1.25rem;
}

.hover\:-translate-x-6:hover {
  --tw-translate-x: -1.5rem;
}

.hover\:-translate-x-7:hover {
  --tw-translate-x: -1.75rem;
}

.hover\:-translate-x-8:hover {
  --tw-translate-x: -2rem;
}

.hover\:-translate-x-9:hover {
  --tw-translate-x: -2.25rem;
}

.hover\:-translate-x-10:hover {
  --tw-translate-x: -2.5rem;
}

.hover\:-translate-x-11:hover {
  --tw-translate-x: -2.75rem;
}

.hover\:-translate-x-12:hover {
  --tw-translate-x: -3rem;
}

.hover\:-translate-x-14:hover {
  --tw-translate-x: -3.5rem;
}

.hover\:-translate-x-16:hover {
  --tw-translate-x: -4rem;
}

.hover\:-translate-x-20:hover {
  --tw-translate-x: -5rem;
}

.hover\:-translate-x-24:hover {
  --tw-translate-x: -6rem;
}

.hover\:-translate-x-28:hover {
  --tw-translate-x: -7rem;
}

.hover\:-translate-x-32:hover {
  --tw-translate-x: -8rem;
}

.hover\:-translate-x-36:hover {
  --tw-translate-x: -9rem;
}

.hover\:-translate-x-40:hover {
  --tw-translate-x: -10rem;
}

.hover\:-translate-x-44:hover {
  --tw-translate-x: -11rem;
}

.hover\:-translate-x-48:hover {
  --tw-translate-x: -12rem;
}

.hover\:-translate-x-52:hover {
  --tw-translate-x: -13rem;
}

.hover\:-translate-x-56:hover {
  --tw-translate-x: -14rem;
}

.hover\:-translate-x-60:hover {
  --tw-translate-x: -15rem;
}

.hover\:-translate-x-64:hover {
  --tw-translate-x: -16rem;
}

.hover\:-translate-x-72:hover {
  --tw-translate-x: -18rem;
}

.hover\:-translate-x-80:hover {
  --tw-translate-x: -20rem;
}

.hover\:-translate-x-96:hover {
  --tw-translate-x: -24rem;
}

.hover\:-translate-x-px:hover {
  --tw-translate-x: -1px;
}

.hover\:-translate-x-0\.5:hover {
  --tw-translate-x: -.125rem;
}

.hover\:-translate-x-1\.5:hover {
  --tw-translate-x: -.375rem;
}

.hover\:-translate-x-2\.5:hover {
  --tw-translate-x: -.625rem;
}

.hover\:-translate-x-3\.5:hover {
  --tw-translate-x: -.875rem;
}

.hover\:translate-x-1\/2:hover {
  --tw-translate-x: 50%;
}

.hover\:translate-x-1\/3:hover {
  --tw-translate-x: 33.3333%;
}

.hover\:translate-x-2\/3:hover {
  --tw-translate-x: 66.6667%;
}

.hover\:translate-x-1\/4:hover {
  --tw-translate-x: 25%;
}

.hover\:translate-x-2\/4:hover {
  --tw-translate-x: 50%;
}

.hover\:translate-x-3\/4:hover {
  --tw-translate-x: 75%;
}

.hover\:translate-x-full:hover {
  --tw-translate-x: 100%;
}

.hover\:-translate-x-1\/2:hover {
  --tw-translate-x: -50%;
}

.hover\:-translate-x-1\/3:hover {
  --tw-translate-x: -33.3333%;
}

.hover\:-translate-x-2\/3:hover {
  --tw-translate-x: -66.6667%;
}

.hover\:-translate-x-1\/4:hover {
  --tw-translate-x: -25%;
}

.hover\:-translate-x-2\/4:hover {
  --tw-translate-x: -50%;
}

.hover\:-translate-x-3\/4:hover {
  --tw-translate-x: -75%;
}

.hover\:-translate-x-full:hover {
  --tw-translate-x: -100%;
}

.hover\:translate-y-0:hover {
  --tw-translate-y: 0px;
}

.hover\:translate-y-1:hover {
  --tw-translate-y: .25rem;
}

.hover\:translate-y-2:hover {
  --tw-translate-y: .5rem;
}

.hover\:translate-y-3:hover {
  --tw-translate-y: .75rem;
}

.hover\:translate-y-4:hover {
  --tw-translate-y: 1rem;
}

.hover\:translate-y-5:hover {
  --tw-translate-y: 1.25rem;
}

.hover\:translate-y-6:hover {
  --tw-translate-y: 1.5rem;
}

.hover\:translate-y-7:hover {
  --tw-translate-y: 1.75rem;
}

.hover\:translate-y-8:hover {
  --tw-translate-y: 2rem;
}

.hover\:translate-y-9:hover {
  --tw-translate-y: 2.25rem;
}

.hover\:translate-y-10:hover {
  --tw-translate-y: 2.5rem;
}

.hover\:translate-y-11:hover {
  --tw-translate-y: 2.75rem;
}

.hover\:translate-y-12:hover {
  --tw-translate-y: 3rem;
}

.hover\:translate-y-14:hover {
  --tw-translate-y: 3.5rem;
}

.hover\:translate-y-16:hover {
  --tw-translate-y: 4rem;
}

.hover\:translate-y-20:hover {
  --tw-translate-y: 5rem;
}

.hover\:translate-y-24:hover {
  --tw-translate-y: 6rem;
}

.hover\:translate-y-28:hover {
  --tw-translate-y: 7rem;
}

.hover\:translate-y-32:hover {
  --tw-translate-y: 8rem;
}

.hover\:translate-y-36:hover {
  --tw-translate-y: 9rem;
}

.hover\:translate-y-40:hover {
  --tw-translate-y: 10rem;
}

.hover\:translate-y-44:hover {
  --tw-translate-y: 11rem;
}

.hover\:translate-y-48:hover {
  --tw-translate-y: 12rem;
}

.hover\:translate-y-52:hover {
  --tw-translate-y: 13rem;
}

.hover\:translate-y-56:hover {
  --tw-translate-y: 14rem;
}

.hover\:translate-y-60:hover {
  --tw-translate-y: 15rem;
}

.hover\:translate-y-64:hover {
  --tw-translate-y: 16rem;
}

.hover\:translate-y-72:hover {
  --tw-translate-y: 18rem;
}

.hover\:translate-y-80:hover {
  --tw-translate-y: 20rem;
}

.hover\:translate-y-96:hover {
  --tw-translate-y: 24rem;
}

.hover\:translate-y-px:hover {
  --tw-translate-y: 1px;
}

.hover\:translate-y-0\.5:hover {
  --tw-translate-y: .125rem;
}

.hover\:translate-y-1\.5:hover {
  --tw-translate-y: .375rem;
}

.hover\:translate-y-2\.5:hover {
  --tw-translate-y: .625rem;
}

.hover\:translate-y-3\.5:hover {
  --tw-translate-y: .875rem;
}

.hover\:-translate-y-0:hover {
  --tw-translate-y: 0px;
}

.hover\:-translate-y-1:hover {
  --tw-translate-y: -.25rem;
}

.hover\:-translate-y-2:hover {
  --tw-translate-y: -.5rem;
}

.hover\:-translate-y-3:hover {
  --tw-translate-y: -.75rem;
}

.hover\:-translate-y-4:hover {
  --tw-translate-y: -1rem;
}

.hover\:-translate-y-5:hover {
  --tw-translate-y: -1.25rem;
}

.hover\:-translate-y-6:hover {
  --tw-translate-y: -1.5rem;
}

.hover\:-translate-y-7:hover {
  --tw-translate-y: -1.75rem;
}

.hover\:-translate-y-8:hover {
  --tw-translate-y: -2rem;
}

.hover\:-translate-y-9:hover {
  --tw-translate-y: -2.25rem;
}

.hover\:-translate-y-10:hover {
  --tw-translate-y: -2.5rem;
}

.hover\:-translate-y-11:hover {
  --tw-translate-y: -2.75rem;
}

.hover\:-translate-y-12:hover {
  --tw-translate-y: -3rem;
}

.hover\:-translate-y-14:hover {
  --tw-translate-y: -3.5rem;
}

.hover\:-translate-y-16:hover {
  --tw-translate-y: -4rem;
}

.hover\:-translate-y-20:hover {
  --tw-translate-y: -5rem;
}

.hover\:-translate-y-24:hover {
  --tw-translate-y: -6rem;
}

.hover\:-translate-y-28:hover {
  --tw-translate-y: -7rem;
}

.hover\:-translate-y-32:hover {
  --tw-translate-y: -8rem;
}

.hover\:-translate-y-36:hover {
  --tw-translate-y: -9rem;
}

.hover\:-translate-y-40:hover {
  --tw-translate-y: -10rem;
}

.hover\:-translate-y-44:hover {
  --tw-translate-y: -11rem;
}

.hover\:-translate-y-48:hover {
  --tw-translate-y: -12rem;
}

.hover\:-translate-y-52:hover {
  --tw-translate-y: -13rem;
}

.hover\:-translate-y-56:hover {
  --tw-translate-y: -14rem;
}

.hover\:-translate-y-60:hover {
  --tw-translate-y: -15rem;
}

.hover\:-translate-y-64:hover {
  --tw-translate-y: -16rem;
}

.hover\:-translate-y-72:hover {
  --tw-translate-y: -18rem;
}

.hover\:-translate-y-80:hover {
  --tw-translate-y: -20rem;
}

.hover\:-translate-y-96:hover {
  --tw-translate-y: -24rem;
}

.hover\:-translate-y-px:hover {
  --tw-translate-y: -1px;
}

.hover\:-translate-y-0\.5:hover {
  --tw-translate-y: -.125rem;
}

.hover\:-translate-y-1\.5:hover {
  --tw-translate-y: -.375rem;
}

.hover\:-translate-y-2\.5:hover {
  --tw-translate-y: -.625rem;
}

.hover\:-translate-y-3\.5:hover {
  --tw-translate-y: -.875rem;
}

.hover\:translate-y-1\/2:hover {
  --tw-translate-y: 50%;
}

.hover\:translate-y-1\/3:hover {
  --tw-translate-y: 33.3333%;
}

.hover\:translate-y-2\/3:hover {
  --tw-translate-y: 66.6667%;
}

.hover\:translate-y-1\/4:hover {
  --tw-translate-y: 25%;
}

.hover\:translate-y-2\/4:hover {
  --tw-translate-y: 50%;
}

.hover\:translate-y-3\/4:hover {
  --tw-translate-y: 75%;
}

.hover\:translate-y-full:hover {
  --tw-translate-y: 100%;
}

.hover\:-translate-y-1\/2:hover {
  --tw-translate-y: -50%;
}

.hover\:-translate-y-1\/3:hover {
  --tw-translate-y: -33.3333%;
}

.hover\:-translate-y-2\/3:hover {
  --tw-translate-y: -66.6667%;
}

.hover\:-translate-y-1\/4:hover {
  --tw-translate-y: -25%;
}

.hover\:-translate-y-2\/4:hover {
  --tw-translate-y: -50%;
}

.hover\:-translate-y-3\/4:hover {
  --tw-translate-y: -75%;
}

.hover\:-translate-y-full:hover {
  --tw-translate-y: -100%;
}

.focus\:translate-x-0:focus {
  --tw-translate-x: 0px;
}

.focus\:translate-x-1:focus {
  --tw-translate-x: .25rem;
}

.focus\:translate-x-2:focus {
  --tw-translate-x: .5rem;
}

.focus\:translate-x-3:focus {
  --tw-translate-x: .75rem;
}

.focus\:translate-x-4:focus {
  --tw-translate-x: 1rem;
}

.focus\:translate-x-5:focus {
  --tw-translate-x: 1.25rem;
}

.focus\:translate-x-6:focus {
  --tw-translate-x: 1.5rem;
}

.focus\:translate-x-7:focus {
  --tw-translate-x: 1.75rem;
}

.focus\:translate-x-8:focus {
  --tw-translate-x: 2rem;
}

.focus\:translate-x-9:focus {
  --tw-translate-x: 2.25rem;
}

.focus\:translate-x-10:focus {
  --tw-translate-x: 2.5rem;
}

.focus\:translate-x-11:focus {
  --tw-translate-x: 2.75rem;
}

.focus\:translate-x-12:focus {
  --tw-translate-x: 3rem;
}

.focus\:translate-x-14:focus {
  --tw-translate-x: 3.5rem;
}

.focus\:translate-x-16:focus {
  --tw-translate-x: 4rem;
}

.focus\:translate-x-20:focus {
  --tw-translate-x: 5rem;
}

.focus\:translate-x-24:focus {
  --tw-translate-x: 6rem;
}

.focus\:translate-x-28:focus {
  --tw-translate-x: 7rem;
}

.focus\:translate-x-32:focus {
  --tw-translate-x: 8rem;
}

.focus\:translate-x-36:focus {
  --tw-translate-x: 9rem;
}

.focus\:translate-x-40:focus {
  --tw-translate-x: 10rem;
}

.focus\:translate-x-44:focus {
  --tw-translate-x: 11rem;
}

.focus\:translate-x-48:focus {
  --tw-translate-x: 12rem;
}

.focus\:translate-x-52:focus {
  --tw-translate-x: 13rem;
}

.focus\:translate-x-56:focus {
  --tw-translate-x: 14rem;
}

.focus\:translate-x-60:focus {
  --tw-translate-x: 15rem;
}

.focus\:translate-x-64:focus {
  --tw-translate-x: 16rem;
}

.focus\:translate-x-72:focus {
  --tw-translate-x: 18rem;
}

.focus\:translate-x-80:focus {
  --tw-translate-x: 20rem;
}

.focus\:translate-x-96:focus {
  --tw-translate-x: 24rem;
}

.focus\:translate-x-px:focus {
  --tw-translate-x: 1px;
}

.focus\:translate-x-0\.5:focus {
  --tw-translate-x: .125rem;
}

.focus\:translate-x-1\.5:focus {
  --tw-translate-x: .375rem;
}

.focus\:translate-x-2\.5:focus {
  --tw-translate-x: .625rem;
}

.focus\:translate-x-3\.5:focus {
  --tw-translate-x: .875rem;
}

.focus\:-translate-x-0:focus {
  --tw-translate-x: 0px;
}

.focus\:-translate-x-1:focus {
  --tw-translate-x: -.25rem;
}

.focus\:-translate-x-2:focus {
  --tw-translate-x: -.5rem;
}

.focus\:-translate-x-3:focus {
  --tw-translate-x: -.75rem;
}

.focus\:-translate-x-4:focus {
  --tw-translate-x: -1rem;
}

.focus\:-translate-x-5:focus {
  --tw-translate-x: -1.25rem;
}

.focus\:-translate-x-6:focus {
  --tw-translate-x: -1.5rem;
}

.focus\:-translate-x-7:focus {
  --tw-translate-x: -1.75rem;
}

.focus\:-translate-x-8:focus {
  --tw-translate-x: -2rem;
}

.focus\:-translate-x-9:focus {
  --tw-translate-x: -2.25rem;
}

.focus\:-translate-x-10:focus {
  --tw-translate-x: -2.5rem;
}

.focus\:-translate-x-11:focus {
  --tw-translate-x: -2.75rem;
}

.focus\:-translate-x-12:focus {
  --tw-translate-x: -3rem;
}

.focus\:-translate-x-14:focus {
  --tw-translate-x: -3.5rem;
}

.focus\:-translate-x-16:focus {
  --tw-translate-x: -4rem;
}

.focus\:-translate-x-20:focus {
  --tw-translate-x: -5rem;
}

.focus\:-translate-x-24:focus {
  --tw-translate-x: -6rem;
}

.focus\:-translate-x-28:focus {
  --tw-translate-x: -7rem;
}

.focus\:-translate-x-32:focus {
  --tw-translate-x: -8rem;
}

.focus\:-translate-x-36:focus {
  --tw-translate-x: -9rem;
}

.focus\:-translate-x-40:focus {
  --tw-translate-x: -10rem;
}

.focus\:-translate-x-44:focus {
  --tw-translate-x: -11rem;
}

.focus\:-translate-x-48:focus {
  --tw-translate-x: -12rem;
}

.focus\:-translate-x-52:focus {
  --tw-translate-x: -13rem;
}

.focus\:-translate-x-56:focus {
  --tw-translate-x: -14rem;
}

.focus\:-translate-x-60:focus {
  --tw-translate-x: -15rem;
}

.focus\:-translate-x-64:focus {
  --tw-translate-x: -16rem;
}

.focus\:-translate-x-72:focus {
  --tw-translate-x: -18rem;
}

.focus\:-translate-x-80:focus {
  --tw-translate-x: -20rem;
}

.focus\:-translate-x-96:focus {
  --tw-translate-x: -24rem;
}

.focus\:-translate-x-px:focus {
  --tw-translate-x: -1px;
}

.focus\:-translate-x-0\.5:focus {
  --tw-translate-x: -.125rem;
}

.focus\:-translate-x-1\.5:focus {
  --tw-translate-x: -.375rem;
}

.focus\:-translate-x-2\.5:focus {
  --tw-translate-x: -.625rem;
}

.focus\:-translate-x-3\.5:focus {
  --tw-translate-x: -.875rem;
}

.focus\:translate-x-1\/2:focus {
  --tw-translate-x: 50%;
}

.focus\:translate-x-1\/3:focus {
  --tw-translate-x: 33.3333%;
}

.focus\:translate-x-2\/3:focus {
  --tw-translate-x: 66.6667%;
}

.focus\:translate-x-1\/4:focus {
  --tw-translate-x: 25%;
}

.focus\:translate-x-2\/4:focus {
  --tw-translate-x: 50%;
}

.focus\:translate-x-3\/4:focus {
  --tw-translate-x: 75%;
}

.focus\:translate-x-full:focus {
  --tw-translate-x: 100%;
}

.focus\:-translate-x-1\/2:focus {
  --tw-translate-x: -50%;
}

.focus\:-translate-x-1\/3:focus {
  --tw-translate-x: -33.3333%;
}

.focus\:-translate-x-2\/3:focus {
  --tw-translate-x: -66.6667%;
}

.focus\:-translate-x-1\/4:focus {
  --tw-translate-x: -25%;
}

.focus\:-translate-x-2\/4:focus {
  --tw-translate-x: -50%;
}

.focus\:-translate-x-3\/4:focus {
  --tw-translate-x: -75%;
}

.focus\:-translate-x-full:focus {
  --tw-translate-x: -100%;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
}

.focus\:translate-y-1:focus {
  --tw-translate-y: .25rem;
}

.focus\:translate-y-2:focus {
  --tw-translate-y: .5rem;
}

.focus\:translate-y-3:focus {
  --tw-translate-y: .75rem;
}

.focus\:translate-y-4:focus {
  --tw-translate-y: 1rem;
}

.focus\:translate-y-5:focus {
  --tw-translate-y: 1.25rem;
}

.focus\:translate-y-6:focus {
  --tw-translate-y: 1.5rem;
}

.focus\:translate-y-7:focus {
  --tw-translate-y: 1.75rem;
}

.focus\:translate-y-8:focus {
  --tw-translate-y: 2rem;
}

.focus\:translate-y-9:focus {
  --tw-translate-y: 2.25rem;
}

.focus\:translate-y-10:focus {
  --tw-translate-y: 2.5rem;
}

.focus\:translate-y-11:focus {
  --tw-translate-y: 2.75rem;
}

.focus\:translate-y-12:focus {
  --tw-translate-y: 3rem;
}

.focus\:translate-y-14:focus {
  --tw-translate-y: 3.5rem;
}

.focus\:translate-y-16:focus {
  --tw-translate-y: 4rem;
}

.focus\:translate-y-20:focus {
  --tw-translate-y: 5rem;
}

.focus\:translate-y-24:focus {
  --tw-translate-y: 6rem;
}

.focus\:translate-y-28:focus {
  --tw-translate-y: 7rem;
}

.focus\:translate-y-32:focus {
  --tw-translate-y: 8rem;
}

.focus\:translate-y-36:focus {
  --tw-translate-y: 9rem;
}

.focus\:translate-y-40:focus {
  --tw-translate-y: 10rem;
}

.focus\:translate-y-44:focus {
  --tw-translate-y: 11rem;
}

.focus\:translate-y-48:focus {
  --tw-translate-y: 12rem;
}

.focus\:translate-y-52:focus {
  --tw-translate-y: 13rem;
}

.focus\:translate-y-56:focus {
  --tw-translate-y: 14rem;
}

.focus\:translate-y-60:focus {
  --tw-translate-y: 15rem;
}

.focus\:translate-y-64:focus {
  --tw-translate-y: 16rem;
}

.focus\:translate-y-72:focus {
  --tw-translate-y: 18rem;
}

.focus\:translate-y-80:focus {
  --tw-translate-y: 20rem;
}

.focus\:translate-y-96:focus {
  --tw-translate-y: 24rem;
}

.focus\:translate-y-px:focus {
  --tw-translate-y: 1px;
}

.focus\:translate-y-0\.5:focus {
  --tw-translate-y: .125rem;
}

.focus\:translate-y-1\.5:focus {
  --tw-translate-y: .375rem;
}

.focus\:translate-y-2\.5:focus {
  --tw-translate-y: .625rem;
}

.focus\:translate-y-3\.5:focus {
  --tw-translate-y: .875rem;
}

.focus\:-translate-y-0:focus {
  --tw-translate-y: 0px;
}

.focus\:-translate-y-1:focus {
  --tw-translate-y: -.25rem;
}

.focus\:-translate-y-2:focus {
  --tw-translate-y: -.5rem;
}

.focus\:-translate-y-3:focus {
  --tw-translate-y: -.75rem;
}

.focus\:-translate-y-4:focus {
  --tw-translate-y: -1rem;
}

.focus\:-translate-y-5:focus {
  --tw-translate-y: -1.25rem;
}

.focus\:-translate-y-6:focus {
  --tw-translate-y: -1.5rem;
}

.focus\:-translate-y-7:focus {
  --tw-translate-y: -1.75rem;
}

.focus\:-translate-y-8:focus {
  --tw-translate-y: -2rem;
}

.focus\:-translate-y-9:focus {
  --tw-translate-y: -2.25rem;
}

.focus\:-translate-y-10:focus {
  --tw-translate-y: -2.5rem;
}

.focus\:-translate-y-11:focus {
  --tw-translate-y: -2.75rem;
}

.focus\:-translate-y-12:focus {
  --tw-translate-y: -3rem;
}

.focus\:-translate-y-14:focus {
  --tw-translate-y: -3.5rem;
}

.focus\:-translate-y-16:focus {
  --tw-translate-y: -4rem;
}

.focus\:-translate-y-20:focus {
  --tw-translate-y: -5rem;
}

.focus\:-translate-y-24:focus {
  --tw-translate-y: -6rem;
}

.focus\:-translate-y-28:focus {
  --tw-translate-y: -7rem;
}

.focus\:-translate-y-32:focus {
  --tw-translate-y: -8rem;
}

.focus\:-translate-y-36:focus {
  --tw-translate-y: -9rem;
}

.focus\:-translate-y-40:focus {
  --tw-translate-y: -10rem;
}

.focus\:-translate-y-44:focus {
  --tw-translate-y: -11rem;
}

.focus\:-translate-y-48:focus {
  --tw-translate-y: -12rem;
}

.focus\:-translate-y-52:focus {
  --tw-translate-y: -13rem;
}

.focus\:-translate-y-56:focus {
  --tw-translate-y: -14rem;
}

.focus\:-translate-y-60:focus {
  --tw-translate-y: -15rem;
}

.focus\:-translate-y-64:focus {
  --tw-translate-y: -16rem;
}

.focus\:-translate-y-72:focus {
  --tw-translate-y: -18rem;
}

.focus\:-translate-y-80:focus {
  --tw-translate-y: -20rem;
}

.focus\:-translate-y-96:focus {
  --tw-translate-y: -24rem;
}

.focus\:-translate-y-px:focus {
  --tw-translate-y: -1px;
}

.focus\:-translate-y-0\.5:focus {
  --tw-translate-y: -.125rem;
}

.focus\:-translate-y-1\.5:focus {
  --tw-translate-y: -.375rem;
}

.focus\:-translate-y-2\.5:focus {
  --tw-translate-y: -.625rem;
}

.focus\:-translate-y-3\.5:focus {
  --tw-translate-y: -.875rem;
}

.focus\:translate-y-1\/2:focus {
  --tw-translate-y: 50%;
}

.focus\:translate-y-1\/3:focus {
  --tw-translate-y: 33.3333%;
}

.focus\:translate-y-2\/3:focus {
  --tw-translate-y: 66.6667%;
}

.focus\:translate-y-1\/4:focus {
  --tw-translate-y: 25%;
}

.focus\:translate-y-2\/4:focus {
  --tw-translate-y: 50%;
}

.focus\:translate-y-3\/4:focus {
  --tw-translate-y: 75%;
}

.focus\:translate-y-full:focus {
  --tw-translate-y: 100%;
}

.focus\:-translate-y-1\/2:focus {
  --tw-translate-y: -50%;
}

.focus\:-translate-y-1\/3:focus {
  --tw-translate-y: -33.3333%;
}

.focus\:-translate-y-2\/3:focus {
  --tw-translate-y: -66.6667%;
}

.focus\:-translate-y-1\/4:focus {
  --tw-translate-y: -25%;
}

.focus\:-translate-y-2\/4:focus {
  --tw-translate-y: -50%;
}

.focus\:-translate-y-3\/4:focus {
  --tw-translate-y: -75%;
}

.focus\:-translate-y-full:focus {
  --tw-translate-y: -100%;
}

.rotate-0 {
  --tw-rotate: 0deg;
}

.rotate-1 {
  --tw-rotate: 1deg;
}

.rotate-2 {
  --tw-rotate: 2deg;
}

.rotate-3 {
  --tw-rotate: 3deg;
}

.rotate-6 {
  --tw-rotate: 6deg;
}

.rotate-12 {
  --tw-rotate: 12deg;
}

.rotate-45 {
  --tw-rotate: 45deg;
}

.rotate-90 {
  --tw-rotate: 90deg;
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.-rotate-180 {
  --tw-rotate: -180deg;
}

.-rotate-90 {
  --tw-rotate: -90deg;
}

.-rotate-45 {
  --tw-rotate: -45deg;
}

.-rotate-12 {
  --tw-rotate: -12deg;
}

.-rotate-6 {
  --tw-rotate: -6deg;
}

.-rotate-3 {
  --tw-rotate: -3deg;
}

.-rotate-2 {
  --tw-rotate: -2deg;
}

.-rotate-1 {
  --tw-rotate: -1deg;
}

.hover\:rotate-0:hover {
  --tw-rotate: 0deg;
}

.hover\:rotate-1:hover {
  --tw-rotate: 1deg;
}

.hover\:rotate-2:hover {
  --tw-rotate: 2deg;
}

.hover\:rotate-3:hover {
  --tw-rotate: 3deg;
}

.hover\:rotate-6:hover {
  --tw-rotate: 6deg;
}

.hover\:rotate-12:hover {
  --tw-rotate: 12deg;
}

.hover\:rotate-45:hover {
  --tw-rotate: 45deg;
}

.hover\:rotate-90:hover {
  --tw-rotate: 90deg;
}

.hover\:rotate-180:hover {
  --tw-rotate: 180deg;
}

.hover\:-rotate-180:hover {
  --tw-rotate: -180deg;
}

.hover\:-rotate-90:hover {
  --tw-rotate: -90deg;
}

.hover\:-rotate-45:hover {
  --tw-rotate: -45deg;
}

.hover\:-rotate-12:hover {
  --tw-rotate: -12deg;
}

.hover\:-rotate-6:hover {
  --tw-rotate: -6deg;
}

.hover\:-rotate-3:hover {
  --tw-rotate: -3deg;
}

.hover\:-rotate-2:hover {
  --tw-rotate: -2deg;
}

.hover\:-rotate-1:hover {
  --tw-rotate: -1deg;
}

.focus\:rotate-0:focus {
  --tw-rotate: 0deg;
}

.focus\:rotate-1:focus {
  --tw-rotate: 1deg;
}

.focus\:rotate-2:focus {
  --tw-rotate: 2deg;
}

.focus\:rotate-3:focus {
  --tw-rotate: 3deg;
}

.focus\:rotate-6:focus {
  --tw-rotate: 6deg;
}

.focus\:rotate-12:focus {
  --tw-rotate: 12deg;
}

.focus\:rotate-45:focus {
  --tw-rotate: 45deg;
}

.focus\:rotate-90:focus {
  --tw-rotate: 90deg;
}

.focus\:rotate-180:focus {
  --tw-rotate: 180deg;
}

.focus\:-rotate-180:focus {
  --tw-rotate: -180deg;
}

.focus\:-rotate-90:focus {
  --tw-rotate: -90deg;
}

.focus\:-rotate-45:focus {
  --tw-rotate: -45deg;
}

.focus\:-rotate-12:focus {
  --tw-rotate: -12deg;
}

.focus\:-rotate-6:focus {
  --tw-rotate: -6deg;
}

.focus\:-rotate-3:focus {
  --tw-rotate: -3deg;
}

.focus\:-rotate-2:focus {
  --tw-rotate: -2deg;
}

.focus\:-rotate-1:focus {
  --tw-rotate: -1deg;
}

.skew-x-0 {
  --tw-skew-x: 0deg;
}

.skew-x-1 {
  --tw-skew-x: 1deg;
}

.skew-x-2 {
  --tw-skew-x: 2deg;
}

.skew-x-3 {
  --tw-skew-x: 3deg;
}

.skew-x-6 {
  --tw-skew-x: 6deg;
}

.skew-x-12 {
  --tw-skew-x: 12deg;
}

.-skew-x-12 {
  --tw-skew-x: -12deg;
}

.-skew-x-6 {
  --tw-skew-x: -6deg;
}

.-skew-x-3 {
  --tw-skew-x: -3deg;
}

.-skew-x-2 {
  --tw-skew-x: -2deg;
}

.-skew-x-1 {
  --tw-skew-x: -1deg;
}

.skew-y-0 {
  --tw-skew-y: 0deg;
}

.skew-y-1 {
  --tw-skew-y: 1deg;
}

.skew-y-2 {
  --tw-skew-y: 2deg;
}

.skew-y-3 {
  --tw-skew-y: 3deg;
}

.skew-y-6 {
  --tw-skew-y: 6deg;
}

.skew-y-12 {
  --tw-skew-y: 12deg;
}

.-skew-y-12 {
  --tw-skew-y: -12deg;
}

.-skew-y-6 {
  --tw-skew-y: -6deg;
}

.-skew-y-3 {
  --tw-skew-y: -3deg;
}

.-skew-y-2 {
  --tw-skew-y: -2deg;
}

.-skew-y-1 {
  --tw-skew-y: -1deg;
}

.hover\:skew-x-0:hover {
  --tw-skew-x: 0deg;
}

.hover\:skew-x-1:hover {
  --tw-skew-x: 1deg;
}

.hover\:skew-x-2:hover {
  --tw-skew-x: 2deg;
}

.hover\:skew-x-3:hover {
  --tw-skew-x: 3deg;
}

.hover\:skew-x-6:hover {
  --tw-skew-x: 6deg;
}

.hover\:skew-x-12:hover {
  --tw-skew-x: 12deg;
}

.hover\:-skew-x-12:hover {
  --tw-skew-x: -12deg;
}

.hover\:-skew-x-6:hover {
  --tw-skew-x: -6deg;
}

.hover\:-skew-x-3:hover {
  --tw-skew-x: -3deg;
}

.hover\:-skew-x-2:hover {
  --tw-skew-x: -2deg;
}

.hover\:-skew-x-1:hover {
  --tw-skew-x: -1deg;
}

.hover\:skew-y-0:hover {
  --tw-skew-y: 0deg;
}

.hover\:skew-y-1:hover {
  --tw-skew-y: 1deg;
}

.hover\:skew-y-2:hover {
  --tw-skew-y: 2deg;
}

.hover\:skew-y-3:hover {
  --tw-skew-y: 3deg;
}

.hover\:skew-y-6:hover {
  --tw-skew-y: 6deg;
}

.hover\:skew-y-12:hover {
  --tw-skew-y: 12deg;
}

.hover\:-skew-y-12:hover {
  --tw-skew-y: -12deg;
}

.hover\:-skew-y-6:hover {
  --tw-skew-y: -6deg;
}

.hover\:-skew-y-3:hover {
  --tw-skew-y: -3deg;
}

.hover\:-skew-y-2:hover {
  --tw-skew-y: -2deg;
}

.hover\:-skew-y-1:hover {
  --tw-skew-y: -1deg;
}

.focus\:skew-x-0:focus {
  --tw-skew-x: 0deg;
}

.focus\:skew-x-1:focus {
  --tw-skew-x: 1deg;
}

.focus\:skew-x-2:focus {
  --tw-skew-x: 2deg;
}

.focus\:skew-x-3:focus {
  --tw-skew-x: 3deg;
}

.focus\:skew-x-6:focus {
  --tw-skew-x: 6deg;
}

.focus\:skew-x-12:focus {
  --tw-skew-x: 12deg;
}

.focus\:-skew-x-12:focus {
  --tw-skew-x: -12deg;
}

.focus\:-skew-x-6:focus {
  --tw-skew-x: -6deg;
}

.focus\:-skew-x-3:focus {
  --tw-skew-x: -3deg;
}

.focus\:-skew-x-2:focus {
  --tw-skew-x: -2deg;
}

.focus\:-skew-x-1:focus {
  --tw-skew-x: -1deg;
}

.focus\:skew-y-0:focus {
  --tw-skew-y: 0deg;
}

.focus\:skew-y-1:focus {
  --tw-skew-y: 1deg;
}

.focus\:skew-y-2:focus {
  --tw-skew-y: 2deg;
}

.focus\:skew-y-3:focus {
  --tw-skew-y: 3deg;
}

.focus\:skew-y-6:focus {
  --tw-skew-y: 6deg;
}

.focus\:skew-y-12:focus {
  --tw-skew-y: 12deg;
}

.focus\:-skew-y-12:focus {
  --tw-skew-y: -12deg;
}

.focus\:-skew-y-6:focus {
  --tw-skew-y: -6deg;
}

.focus\:-skew-y-3:focus {
  --tw-skew-y: -3deg;
}

.focus\:-skew-y-2:focus {
  --tw-skew-y: -2deg;
}

.focus\:-skew-y-1:focus {
  --tw-skew-y: -1deg;
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.hover\:scale-0:hover {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.hover\:scale-50:hover {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.hover\:scale-75:hover {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.hover\:scale-90:hover {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.focus\:scale-0:focus {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.focus\:scale-50:focus {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.focus\:scale-75:focus {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.focus\:scale-90:focus {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.focus\:scale-95:focus {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.focus\:scale-100:focus {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.focus\:scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.focus\:scale-110:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.focus\:scale-125:focus {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.focus\:scale-150:focus {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.scale-x-0 {
  --tw-scale-x: 0;
}

.scale-x-50 {
  --tw-scale-x: .5;
}

.scale-x-75 {
  --tw-scale-x: .75;
}

.scale-x-90 {
  --tw-scale-x: .9;
}

.scale-x-95 {
  --tw-scale-x: .95;
}

.scale-x-100 {
  --tw-scale-x: 1;
}

.scale-x-105 {
  --tw-scale-x: 1.05;
}

.scale-x-110 {
  --tw-scale-x: 1.1;
}

.scale-x-125 {
  --tw-scale-x: 1.25;
}

.scale-x-150 {
  --tw-scale-x: 1.5;
}

.scale-y-0 {
  --tw-scale-y: 0;
}

.scale-y-50 {
  --tw-scale-y: .5;
}

.scale-y-75 {
  --tw-scale-y: .75;
}

.scale-y-90 {
  --tw-scale-y: .9;
}

.scale-y-95 {
  --tw-scale-y: .95;
}

.scale-y-100 {
  --tw-scale-y: 1;
}

.scale-y-105 {
  --tw-scale-y: 1.05;
}

.scale-y-110 {
  --tw-scale-y: 1.1;
}

.scale-y-125 {
  --tw-scale-y: 1.25;
}

.scale-y-150 {
  --tw-scale-y: 1.5;
}

.hover\:scale-x-0:hover {
  --tw-scale-x: 0;
}

.hover\:scale-x-50:hover {
  --tw-scale-x: .5;
}

.hover\:scale-x-75:hover {
  --tw-scale-x: .75;
}

.hover\:scale-x-90:hover {
  --tw-scale-x: .9;
}

.hover\:scale-x-95:hover {
  --tw-scale-x: .95;
}

.hover\:scale-x-100:hover {
  --tw-scale-x: 1;
}

.hover\:scale-x-105:hover {
  --tw-scale-x: 1.05;
}

.hover\:scale-x-110:hover {
  --tw-scale-x: 1.1;
}

.hover\:scale-x-125:hover {
  --tw-scale-x: 1.25;
}

.hover\:scale-x-150:hover {
  --tw-scale-x: 1.5;
}

.hover\:scale-y-0:hover {
  --tw-scale-y: 0;
}

.hover\:scale-y-50:hover {
  --tw-scale-y: .5;
}

.hover\:scale-y-75:hover {
  --tw-scale-y: .75;
}

.hover\:scale-y-90:hover {
  --tw-scale-y: .9;
}

.hover\:scale-y-95:hover {
  --tw-scale-y: .95;
}

.hover\:scale-y-100:hover {
  --tw-scale-y: 1;
}

.hover\:scale-y-105:hover {
  --tw-scale-y: 1.05;
}

.hover\:scale-y-110:hover {
  --tw-scale-y: 1.1;
}

.hover\:scale-y-125:hover {
  --tw-scale-y: 1.25;
}

.hover\:scale-y-150:hover {
  --tw-scale-y: 1.5;
}

.focus\:scale-x-0:focus {
  --tw-scale-x: 0;
}

.focus\:scale-x-50:focus {
  --tw-scale-x: .5;
}

.focus\:scale-x-75:focus {
  --tw-scale-x: .75;
}

.focus\:scale-x-90:focus {
  --tw-scale-x: .9;
}

.focus\:scale-x-95:focus {
  --tw-scale-x: .95;
}

.focus\:scale-x-100:focus {
  --tw-scale-x: 1;
}

.focus\:scale-x-105:focus {
  --tw-scale-x: 1.05;
}

.focus\:scale-x-110:focus {
  --tw-scale-x: 1.1;
}

.focus\:scale-x-125:focus {
  --tw-scale-x: 1.25;
}

.focus\:scale-x-150:focus {
  --tw-scale-x: 1.5;
}

.focus\:scale-y-0:focus {
  --tw-scale-y: 0;
}

.focus\:scale-y-50:focus {
  --tw-scale-y: .5;
}

.focus\:scale-y-75:focus {
  --tw-scale-y: .75;
}

.focus\:scale-y-90:focus {
  --tw-scale-y: .9;
}

.focus\:scale-y-95:focus {
  --tw-scale-y: .95;
}

.focus\:scale-y-100:focus {
  --tw-scale-y: 1;
}

.focus\:scale-y-105:focus {
  --tw-scale-y: 1.05;
}

.focus\:scale-y-110:focus {
  --tw-scale-y: 1.1;
}

.focus\:scale-y-125:focus {
  --tw-scale-y: 1.25;
}

.focus\:scale-y-150:focus {
  --tw-scale-y: 1.5;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-none {
  animation: none;
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.animate-bounce {
  animation: 1s infinite bounce;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.select-auto {
  -webkit-user-select: auto;
  user-select: auto;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.appearance-none {
  appearance: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-stretch {
  place-content: stretch;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-28 {
  gap: 7rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-36 {
  gap: 9rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-48 {
  gap: 12rem;
}

.gap-52 {
  gap: 13rem;
}

.gap-56 {
  gap: 14rem;
}

.gap-60 {
  gap: 15rem;
}

.gap-64 {
  gap: 16rem;
}

.gap-72 {
  gap: 18rem;
}

.gap-80 {
  gap: 20rem;
}

.gap-96 {
  gap: 24rem;
}

.gap-px {
  gap: 1px;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-9 {
  column-gap: 2.25rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-11 {
  column-gap: 2.75rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-28 {
  column-gap: 7rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-x-36 {
  column-gap: 9rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-x-44 {
  column-gap: 11rem;
}

.gap-x-48 {
  column-gap: 12rem;
}

.gap-x-52 {
  column-gap: 13rem;
}

.gap-x-56 {
  column-gap: 14rem;
}

.gap-x-60 {
  column-gap: 15rem;
}

.gap-x-64 {
  column-gap: 16rem;
}

.gap-x-72 {
  column-gap: 18rem;
}

.gap-x-80 {
  column-gap: 20rem;
}

.gap-x-96 {
  column-gap: 24rem;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-x-0\.5 {
  column-gap: .125rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-2\.5 {
  column-gap: .625rem;
}

.gap-x-3\.5 {
  column-gap: .875rem;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-11 {
  row-gap: 2.75rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-28 {
  row-gap: 7rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-y-36 {
  row-gap: 9rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.gap-y-44 {
  row-gap: 11rem;
}

.gap-y-48 {
  row-gap: 12rem;
}

.gap-y-52 {
  row-gap: 13rem;
}

.gap-y-56 {
  row-gap: 14rem;
}

.gap-y-60 {
  row-gap: 15rem;
}

.gap-y-64 {
  row-gap: 16rem;
}

.gap-y-72 {
  row-gap: 18rem;
}

.gap-y-80 {
  row-gap: 20rem;
}

.gap-y-96 {
  row-gap: 24rem;
}

.gap-y-px {
  row-gap: 1px;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-1\.5 {
  row-gap: .375rem;
}

.gap-y-2\.5 {
  row-gap: .625rem;
}

.gap-y-3\.5 {
  row-gap: .875rem;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.625rem * var(--tw-space-x-reverse));
  margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.875rem * var(--tw-space-x-reverse));
  margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}

.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}

.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}

.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}

.space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}

.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
}

.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
}

.-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}

.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}

.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}

.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}

.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}

.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}

.-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
}

.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
}

.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
}

.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}

.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}

.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000;
}

.divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}

.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
}

.divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
}

.divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
}

.divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
}

.divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
}

.divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
}

.divide-red > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
}

.divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
}

.divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
}

.divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
}

.divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
}

.divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
}

.divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
}

.divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
}

.divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
}

.divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
}

.divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
}

.divide-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
}

.divide-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
}

.divide-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
}

.divide-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
}

.divide-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
}

.divide-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
}

.divide-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
}

.divide-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
}

.divide-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
}

.divide-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
}

.divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
}

.divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
}

.divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
}

.divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
}

.divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
}

.divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
}

.divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
}

.divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
}

.divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
}

.divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
}

.divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
}

.divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
}

.divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
}

.divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
}

.divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
}

.divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
}

.divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
}

.divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
}

.divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
}

.divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
}

.divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
}

.divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
}

.divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
}

.divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
}

.divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
}

.divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
}

.divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
}

.divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
}

.divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
}

.divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
}

.divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
}

.divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
}

.divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
}

.divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
}

.divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
}

.divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
}

.divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
}

.divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
}

.divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
}

.divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
}

.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}

.divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .05;
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .1;
}

.divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .2;
}

.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .25;
}

.divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .3;
}

.divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .4;
}

.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .5;
}

.divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .6;
}

.divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .7;
}

.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .75;
}

.divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .8;
}

.divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .9;
}

.divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .95;
}

.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.overscroll-none {
  overscroll-behavior: none;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-clip {
  text-overflow: clip;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-sm {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-sm {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-xl {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tl-sm {
  border-top-left-radius: .125rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-tr-sm {
  border-top-right-radius: .125rem;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-br-sm {
  border-bottom-right-radius: .125rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-xl {
  border-bottom-right-radius: .75rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-bl-sm {
  border-bottom-left-radius: .125rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: .75rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-transparent {
  border-color: #0000;
}

.border-current {
  border-color: currentColor;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity));
}

.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-transparent {
  border-color: #0000;
}

.group:hover .group-hover\:border-current {
  border-color: currentColor;
}

.group:hover .group-hover\:border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-red {
  --tw-border-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.group:hover .group-hover\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.focus-within\:border-transparent:focus-within {
  border-color: #0000;
}

.focus-within\:border-current:focus-within {
  border-color: currentColor;
}

.focus-within\:border-black:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.focus-within\:border-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.focus-within\:border-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.focus-within\:border-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.focus-within\:border-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.focus-within\:border-gray-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.focus-within\:border-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.focus-within\:border-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.focus-within\:border-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.focus-within\:border-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.focus-within\:border-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.focus-within\:border-red:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity));
}

.focus-within\:border-yellow-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.focus-within\:border-yellow-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.focus-within\:border-yellow-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.focus-within\:border-yellow-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.focus-within\:border-yellow-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.focus-within\:border-yellow-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.focus-within\:border-yellow-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.focus-within\:border-yellow-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.focus-within\:border-yellow-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.focus-within\:border-yellow-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.focus-within\:border-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.focus-within\:border-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.focus-within\:border-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.focus-within\:border-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.focus-within\:border-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.focus-within\:border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.focus-within\:border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.focus-within\:border-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.focus-within\:border-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.focus-within\:border-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.focus-within\:border-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.focus-within\:border-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.focus-within\:border-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.focus-within\:border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.focus-within\:border-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.focus-within\:border-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.focus-within\:border-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.focus-within\:border-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.focus-within\:border-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.focus-within\:border-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.focus-within\:border-indigo-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.focus-within\:border-indigo-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.focus-within\:border-indigo-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.focus-within\:border-indigo-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.focus-within\:border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.focus-within\:border-indigo-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.focus-within\:border-indigo-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.focus-within\:border-indigo-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.focus-within\:border-indigo-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.focus-within\:border-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.focus-within\:border-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.focus-within\:border-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.focus-within\:border-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.focus-within\:border-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.focus-within\:border-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.focus-within\:border-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.focus-within\:border-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.focus-within\:border-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.focus-within\:border-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.focus-within\:border-pink-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.focus-within\:border-pink-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.focus-within\:border-pink-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.focus-within\:border-pink-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.focus-within\:border-pink-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.focus-within\:border-pink-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.focus-within\:border-pink-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.focus-within\:border-pink-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.focus-within\:border-pink-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.focus-within\:border-pink-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.hover\:border-transparent:hover {
  border-color: #0000;
}

.hover\:border-current:hover {
  border-color: currentColor;
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.hover\:border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.hover\:border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.hover\:border-red:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity));
}

.hover\:border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.hover\:border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.hover\:border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.hover\:border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.hover\:border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.hover\:border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.hover\:border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.hover\:border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.hover\:border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.hover\:border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.hover\:border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.hover\:border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.hover\:border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.hover\:border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.hover\:border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.hover\:border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.hover\:border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.hover\:border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.hover\:border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.hover\:border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.hover\:border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.hover\:border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.hover\:border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.hover\:border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.hover\:border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.hover\:border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.hover\:border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.hover\:border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.hover\:border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.hover\:border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.hover\:border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.hover\:border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.hover\:border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.hover\:border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.focus\:border-transparent:focus {
  border-color: #0000;
}

.focus\:border-current:focus {
  border-color: currentColor;
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.focus\:border-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}

.focus\:border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.focus\:border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.focus\:border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}

.focus\:border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}

.focus\:border-red:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity));
}

.focus\:border-yellow-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}

.focus\:border-yellow-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}

.focus\:border-yellow-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}

.focus\:border-yellow-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}

.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}

.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}

.focus\:border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}

.focus\:border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.focus\:border-yellow-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}

.focus\:border-yellow-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}

.focus\:border-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.focus\:border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.focus\:border-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.focus\:border-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.focus\:border-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.focus\:border-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.focus\:border-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.focus\:border-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.focus\:border-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.focus\:border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.focus\:border-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.focus\:border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.focus\:border-indigo-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.focus\:border-indigo-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.focus\:border-indigo-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.focus\:border-indigo-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.focus\:border-indigo-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.focus\:border-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.focus\:border-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.focus\:border-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.focus\:border-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.focus\:border-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.focus\:border-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.focus\:border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.focus\:border-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.focus\:border-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.focus\:border-pink-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.focus\:border-pink-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.focus\:border-pink-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.focus\:border-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.focus\:border-pink-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.focus\:border-pink-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.focus\:border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.focus\:border-pink-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.focus\:border-pink-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.border-opacity-0 {
  --tw-border-opacity: 0;
}

.border-opacity-5 {
  --tw-border-opacity: .05;
}

.border-opacity-10 {
  --tw-border-opacity: .1;
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.border-opacity-25 {
  --tw-border-opacity: .25;
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.border-opacity-40 {
  --tw-border-opacity: .4;
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.border-opacity-60 {
  --tw-border-opacity: .6;
}

.border-opacity-70 {
  --tw-border-opacity: .7;
}

.border-opacity-75 {
  --tw-border-opacity: .75;
}

.border-opacity-80 {
  --tw-border-opacity: .8;
}

.border-opacity-90 {
  --tw-border-opacity: .9;
}

.border-opacity-95 {
  --tw-border-opacity: .95;
}

.border-opacity-100 {
  --tw-border-opacity: 1;
}

.group:hover .group-hover\:border-opacity-0 {
  --tw-border-opacity: 0;
}

.group:hover .group-hover\:border-opacity-5 {
  --tw-border-opacity: .05;
}

.group:hover .group-hover\:border-opacity-10 {
  --tw-border-opacity: .1;
}

.group:hover .group-hover\:border-opacity-20 {
  --tw-border-opacity: .2;
}

.group:hover .group-hover\:border-opacity-25 {
  --tw-border-opacity: .25;
}

.group:hover .group-hover\:border-opacity-30 {
  --tw-border-opacity: .3;
}

.group:hover .group-hover\:border-opacity-40 {
  --tw-border-opacity: .4;
}

.group:hover .group-hover\:border-opacity-50 {
  --tw-border-opacity: .5;
}

.group:hover .group-hover\:border-opacity-60 {
  --tw-border-opacity: .6;
}

.group:hover .group-hover\:border-opacity-70 {
  --tw-border-opacity: .7;
}

.group:hover .group-hover\:border-opacity-75 {
  --tw-border-opacity: .75;
}

.group:hover .group-hover\:border-opacity-80 {
  --tw-border-opacity: .8;
}

.group:hover .group-hover\:border-opacity-90 {
  --tw-border-opacity: .9;
}

.group:hover .group-hover\:border-opacity-95 {
  --tw-border-opacity: .95;
}

.group:hover .group-hover\:border-opacity-100 {
  --tw-border-opacity: 1;
}

.focus-within\:border-opacity-0:focus-within {
  --tw-border-opacity: 0;
}

.focus-within\:border-opacity-5:focus-within {
  --tw-border-opacity: .05;
}

.focus-within\:border-opacity-10:focus-within {
  --tw-border-opacity: .1;
}

.focus-within\:border-opacity-20:focus-within {
  --tw-border-opacity: .2;
}

.focus-within\:border-opacity-25:focus-within {
  --tw-border-opacity: .25;
}

.focus-within\:border-opacity-30:focus-within {
  --tw-border-opacity: .3;
}

.focus-within\:border-opacity-40:focus-within {
  --tw-border-opacity: .4;
}

.focus-within\:border-opacity-50:focus-within {
  --tw-border-opacity: .5;
}

.focus-within\:border-opacity-60:focus-within {
  --tw-border-opacity: .6;
}

.focus-within\:border-opacity-70:focus-within {
  --tw-border-opacity: .7;
}

.focus-within\:border-opacity-75:focus-within {
  --tw-border-opacity: .75;
}

.focus-within\:border-opacity-80:focus-within {
  --tw-border-opacity: .8;
}

.focus-within\:border-opacity-90:focus-within {
  --tw-border-opacity: .9;
}

.focus-within\:border-opacity-95:focus-within {
  --tw-border-opacity: .95;
}

.focus-within\:border-opacity-100:focus-within {
  --tw-border-opacity: 1;
}

.hover\:border-opacity-0:hover {
  --tw-border-opacity: 0;
}

.hover\:border-opacity-5:hover {
  --tw-border-opacity: .05;
}

.hover\:border-opacity-10:hover {
  --tw-border-opacity: .1;
}

.hover\:border-opacity-20:hover {
  --tw-border-opacity: .2;
}

.hover\:border-opacity-25:hover {
  --tw-border-opacity: .25;
}

.hover\:border-opacity-30:hover {
  --tw-border-opacity: .3;
}

.hover\:border-opacity-40:hover {
  --tw-border-opacity: .4;
}

.hover\:border-opacity-50:hover {
  --tw-border-opacity: .5;
}

.hover\:border-opacity-60:hover {
  --tw-border-opacity: .6;
}

.hover\:border-opacity-70:hover {
  --tw-border-opacity: .7;
}

.hover\:border-opacity-75:hover {
  --tw-border-opacity: .75;
}

.hover\:border-opacity-80:hover {
  --tw-border-opacity: .8;
}

.hover\:border-opacity-90:hover {
  --tw-border-opacity: .9;
}

.hover\:border-opacity-95:hover {
  --tw-border-opacity: .95;
}

.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1;
}

.focus\:border-opacity-0:focus {
  --tw-border-opacity: 0;
}

.focus\:border-opacity-5:focus {
  --tw-border-opacity: .05;
}

.focus\:border-opacity-10:focus {
  --tw-border-opacity: .1;
}

.focus\:border-opacity-20:focus {
  --tw-border-opacity: .2;
}

.focus\:border-opacity-25:focus {
  --tw-border-opacity: .25;
}

.focus\:border-opacity-30:focus {
  --tw-border-opacity: .3;
}

.focus\:border-opacity-40:focus {
  --tw-border-opacity: .4;
}

.focus\:border-opacity-50:focus {
  --tw-border-opacity: .5;
}

.focus\:border-opacity-60:focus {
  --tw-border-opacity: .6;
}

.focus\:border-opacity-70:focus {
  --tw-border-opacity: .7;
}

.focus\:border-opacity-75:focus {
  --tw-border-opacity: .75;
}

.focus\:border-opacity-80:focus {
  --tw-border-opacity: .8;
}

.focus\:border-opacity-90:focus {
  --tw-border-opacity: .9;
}

.focus\:border-opacity-95:focus {
  --tw-border-opacity: .95;
}

.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1;
}

.bg-transparent {
  background-color: #0000;
}

.bg-current {
  background-color: currentColor;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-transparent {
  background-color: #0000;
}

.group:hover .group-hover\:bg-current {
  background-color: currentColor;
}

.group:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.focus-within\:bg-transparent:focus-within {
  background-color: #0000;
}

.focus-within\:bg-current:focus-within {
  background-color: currentColor;
}

.focus-within\:bg-black:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.focus-within\:bg-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.focus-within\:bg-gray-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.focus-within\:bg-red:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.focus-within\:bg-yellow-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.focus-within\:bg-green-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.focus-within\:bg-green-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.focus-within\:bg-green-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.focus-within\:bg-green-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.focus-within\:bg-green-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.focus-within\:bg-green-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.focus-within\:bg-green-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.focus-within\:bg-green-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.focus-within\:bg-green-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.focus-within\:bg-green-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.focus-within\:bg-blue-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.focus-within\:bg-indigo-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.focus-within\:bg-purple-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.focus-within\:bg-pink-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-current:hover {
  background-color: currentColor;
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.hover\:bg-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: #0000;
}

.focus\:bg-current:focus {
  background-color: currentColor;
}

.focus\:bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus\:bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.focus\:bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.focus\:bg-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.focus\:bg-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.focus\:bg-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.focus\:bg-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.focus\:bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}

.focus\:bg-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.focus\:bg-red:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

.focus\:bg-yellow-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}

.focus\:bg-yellow-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.focus\:bg-yellow-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.focus\:bg-yellow-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}

.focus\:bg-yellow-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}

.focus\:bg-yellow-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.focus\:bg-yellow-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.focus\:bg-yellow-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.focus\:bg-yellow-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}

.focus\:bg-yellow-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}

.focus\:bg-green-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.focus\:bg-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.focus\:bg-green-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.focus\:bg-green-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.focus\:bg-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.focus\:bg-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.focus\:bg-green-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.focus\:bg-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.focus\:bg-green-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.focus\:bg-green-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.focus\:bg-blue-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.focus\:bg-blue-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.focus\:bg-blue-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.focus\:bg-blue-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.focus\:bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.focus\:bg-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.focus\:bg-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.focus\:bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.focus\:bg-blue-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.focus\:bg-blue-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.focus\:bg-indigo-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.focus\:bg-indigo-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.focus\:bg-indigo-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.focus\:bg-indigo-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.focus\:bg-indigo-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.focus\:bg-indigo-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.focus\:bg-indigo-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.focus\:bg-indigo-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.focus\:bg-indigo-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.focus\:bg-indigo-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.focus\:bg-purple-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.focus\:bg-purple-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.focus\:bg-purple-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.focus\:bg-purple-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.focus\:bg-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.focus\:bg-purple-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.focus\:bg-purple-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.focus\:bg-purple-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.focus\:bg-purple-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.focus\:bg-purple-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.focus\:bg-pink-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.focus\:bg-pink-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.focus\:bg-pink-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.focus\:bg-pink-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.focus\:bg-pink-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.focus\:bg-pink-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.focus\:bg-pink-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.focus\:bg-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.focus\:bg-pink-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.focus\:bg-pink-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.group:hover .group-hover\:bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.group:hover .group-hover\:bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.group:hover .group-hover\:bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.group:hover .group-hover\:bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.group:hover .group-hover\:bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.group:hover .group-hover\:bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.group:hover .group-hover\:bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.group:hover .group-hover\:bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.group:hover .group-hover\:bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.group:hover .group-hover\:bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.group:hover .group-hover\:bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.group:hover .group-hover\:bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.group:hover .group-hover\:bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.group:hover .group-hover\:bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.group:hover .group-hover\:bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.focus-within\:bg-opacity-0:focus-within {
  --tw-bg-opacity: 0;
}

.focus-within\:bg-opacity-5:focus-within {
  --tw-bg-opacity: .05;
}

.focus-within\:bg-opacity-10:focus-within {
  --tw-bg-opacity: .1;
}

.focus-within\:bg-opacity-20:focus-within {
  --tw-bg-opacity: .2;
}

.focus-within\:bg-opacity-25:focus-within {
  --tw-bg-opacity: .25;
}

.focus-within\:bg-opacity-30:focus-within {
  --tw-bg-opacity: .3;
}

.focus-within\:bg-opacity-40:focus-within {
  --tw-bg-opacity: .4;
}

.focus-within\:bg-opacity-50:focus-within {
  --tw-bg-opacity: .5;
}

.focus-within\:bg-opacity-60:focus-within {
  --tw-bg-opacity: .6;
}

.focus-within\:bg-opacity-70:focus-within {
  --tw-bg-opacity: .7;
}

.focus-within\:bg-opacity-75:focus-within {
  --tw-bg-opacity: .75;
}

.focus-within\:bg-opacity-80:focus-within {
  --tw-bg-opacity: .8;
}

.focus-within\:bg-opacity-90:focus-within {
  --tw-bg-opacity: .9;
}

.focus-within\:bg-opacity-95:focus-within {
  --tw-bg-opacity: .95;
}

.focus-within\:bg-opacity-100:focus-within {
  --tw-bg-opacity: 1;
}

.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}

.hover\:bg-opacity-5:hover {
  --tw-bg-opacity: .05;
}

.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: .1;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: .25;
}

.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: .3;
}

.hover\:bg-opacity-40:hover {
  --tw-bg-opacity: .4;
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: .5;
}

.hover\:bg-opacity-60:hover {
  --tw-bg-opacity: .6;
}

.hover\:bg-opacity-70:hover {
  --tw-bg-opacity: .7;
}

.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: .75;
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: .8;
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.hover\:bg-opacity-95:hover {
  --tw-bg-opacity: .95;
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.focus\:bg-opacity-0:focus {
  --tw-bg-opacity: 0;
}

.focus\:bg-opacity-5:focus {
  --tw-bg-opacity: .05;
}

.focus\:bg-opacity-10:focus {
  --tw-bg-opacity: .1;
}

.focus\:bg-opacity-20:focus {
  --tw-bg-opacity: .2;
}

.focus\:bg-opacity-25:focus {
  --tw-bg-opacity: .25;
}

.focus\:bg-opacity-30:focus {
  --tw-bg-opacity: .3;
}

.focus\:bg-opacity-40:focus {
  --tw-bg-opacity: .4;
}

.focus\:bg-opacity-50:focus {
  --tw-bg-opacity: .5;
}

.focus\:bg-opacity-60:focus {
  --tw-bg-opacity: .6;
}

.focus\:bg-opacity-70:focus {
  --tw-bg-opacity: .7;
}

.focus\:bg-opacity-75:focus {
  --tw-bg-opacity: .75;
}

.focus\:bg-opacity-80:focus {
  --tw-bg-opacity: .8;
}

.focus\:bg-opacity-90:focus {
  --tw-bg-opacity: .9;
}

.focus\:bg-opacity-95:focus {
  --tw-bg-opacity: .95;
}

.focus\:bg-opacity-100:focus {
  --tw-bg-opacity: 1;
}

.bg-none {
  background-image: none;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
}

.from-current {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.from-black {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.from-gray-50 {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
}

.from-gray-100 {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
}

.from-gray-200 {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
}

.from-gray-300 {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
}

.from-gray-400 {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
}

.from-gray-500 {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
}

.from-gray-600 {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
}

.from-gray-700 {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
}

.from-gray-800 {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
}

.from-gray-900 {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
}

.from-red {
  --tw-gradient-from: red;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
}

.from-yellow-50 {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
}

.from-yellow-100 {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
}

.from-yellow-200 {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
}

.from-yellow-300 {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
}

.from-yellow-400 {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
}

.from-yellow-500 {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
}

.from-yellow-600 {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
}

.from-yellow-700 {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
}

.from-yellow-800 {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
}

.from-yellow-900 {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
}

.from-green-50 {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
}

.from-green-100 {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
}

.from-green-200 {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
}

.from-green-300 {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
}

.from-green-400 {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
}

.from-green-500 {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
}

.from-green-600 {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
}

.from-green-700 {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
}

.from-green-800 {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
}

.from-green-900 {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
}

.from-blue-50 {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
}

.from-blue-100 {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
}

.from-blue-200 {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
}

.from-blue-300 {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
}

.from-blue-400 {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
}

.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
}

.from-blue-600 {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
}

.from-blue-700 {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
}

.from-blue-800 {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
}

.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
}

.from-indigo-50 {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
}

.from-indigo-100 {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
}

.from-indigo-200 {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
}

.from-indigo-300 {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
}

.from-indigo-400 {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
}

.from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
}

.from-indigo-600 {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
}

.from-indigo-700 {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
}

.from-indigo-800 {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
}

.from-indigo-900 {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
}

.from-purple-50 {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
}

.from-purple-100 {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
}

.from-purple-200 {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
}

.from-purple-300 {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
}

.from-purple-400 {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
}

.from-purple-500 {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
}

.from-purple-600 {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
}

.from-purple-700 {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
}

.from-purple-800 {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
}

.from-purple-900 {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
}

.from-pink-50 {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
}

.from-pink-100 {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
}

.from-pink-200 {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
}

.from-pink-300 {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
}

.from-pink-400 {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
}

.from-pink-500 {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
}

.from-pink-600 {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
}

.from-pink-700 {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
}

.from-pink-800 {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
}

.from-pink-900 {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
}

.hover\:from-transparent:hover {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
}

.hover\:from-current:hover {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.hover\:from-black:hover {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
}

.hover\:from-white:hover {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.hover\:from-gray-50:hover {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
}

.hover\:from-gray-100:hover {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
}

.hover\:from-gray-200:hover {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
}

.hover\:from-gray-300:hover {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
}

.hover\:from-gray-400:hover {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
}

.hover\:from-gray-500:hover {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
}

.hover\:from-gray-600:hover {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
}

.hover\:from-gray-700:hover {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
}

.hover\:from-gray-800:hover {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
}

.hover\:from-gray-900:hover {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
}

.hover\:from-red:hover {
  --tw-gradient-from: red;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
}

.hover\:from-yellow-50:hover {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
}

.hover\:from-yellow-100:hover {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
}

.hover\:from-yellow-200:hover {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
}

.hover\:from-yellow-300:hover {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
}

.hover\:from-yellow-400:hover {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
}

.hover\:from-yellow-500:hover {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
}

.hover\:from-yellow-600:hover {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
}

.hover\:from-yellow-700:hover {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
}

.hover\:from-yellow-800:hover {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
}

.hover\:from-yellow-900:hover {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
}

.hover\:from-green-50:hover {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
}

.hover\:from-green-100:hover {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
}

.hover\:from-green-200:hover {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
}

.hover\:from-green-300:hover {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
}

.hover\:from-green-400:hover {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
}

.hover\:from-green-500:hover {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
}

.hover\:from-green-600:hover {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
}

.hover\:from-green-700:hover {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
}

.hover\:from-green-800:hover {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
}

.hover\:from-green-900:hover {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
}

.hover\:from-blue-50:hover {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
}

.hover\:from-blue-100:hover {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
}

.hover\:from-blue-200:hover {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
}

.hover\:from-blue-300:hover {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
}

.hover\:from-blue-400:hover {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
}

.hover\:from-blue-500:hover {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
}

.hover\:from-blue-600:hover {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
}

.hover\:from-blue-700:hover {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
}

.hover\:from-blue-800:hover {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
}

.hover\:from-blue-900:hover {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
}

.hover\:from-indigo-50:hover {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
}

.hover\:from-indigo-100:hover {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
}

.hover\:from-indigo-200:hover {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
}

.hover\:from-indigo-300:hover {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
}

.hover\:from-indigo-400:hover {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
}

.hover\:from-indigo-500:hover {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
}

.hover\:from-indigo-600:hover {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
}

.hover\:from-indigo-700:hover {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
}

.hover\:from-indigo-800:hover {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
}

.hover\:from-indigo-900:hover {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
}

.hover\:from-purple-50:hover {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
}

.hover\:from-purple-100:hover {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
}

.hover\:from-purple-200:hover {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
}

.hover\:from-purple-300:hover {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
}

.hover\:from-purple-400:hover {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
}

.hover\:from-purple-500:hover {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
}

.hover\:from-purple-600:hover {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
}

.hover\:from-purple-700:hover {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
}

.hover\:from-purple-800:hover {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
}

.hover\:from-purple-900:hover {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
}

.hover\:from-pink-50:hover {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
}

.hover\:from-pink-100:hover {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
}

.hover\:from-pink-200:hover {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
}

.hover\:from-pink-300:hover {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
}

.hover\:from-pink-400:hover {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
}

.hover\:from-pink-500:hover {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
}

.hover\:from-pink-600:hover {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
}

.hover\:from-pink-700:hover {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
}

.hover\:from-pink-800:hover {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
}

.hover\:from-pink-900:hover {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
}

.focus\:from-transparent:focus {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
}

.focus\:from-current:focus {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.focus\:from-black:focus {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
}

.focus\:from-white:focus {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
}

.focus\:from-gray-50:focus {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
}

.focus\:from-gray-100:focus {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
}

.focus\:from-gray-200:focus {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
}

.focus\:from-gray-300:focus {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
}

.focus\:from-gray-400:focus {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
}

.focus\:from-gray-500:focus {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
}

.focus\:from-gray-600:focus {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
}

.focus\:from-gray-700:focus {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
}

.focus\:from-gray-800:focus {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
}

.focus\:from-gray-900:focus {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
}

.focus\:from-red:focus {
  --tw-gradient-from: red;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
}

.focus\:from-yellow-50:focus {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
}

.focus\:from-yellow-100:focus {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
}

.focus\:from-yellow-200:focus {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
}

.focus\:from-yellow-300:focus {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
}

.focus\:from-yellow-400:focus {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
}

.focus\:from-yellow-500:focus {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
}

.focus\:from-yellow-600:focus {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
}

.focus\:from-yellow-700:focus {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
}

.focus\:from-yellow-800:focus {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
}

.focus\:from-yellow-900:focus {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
}

.focus\:from-green-50:focus {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
}

.focus\:from-green-100:focus {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
}

.focus\:from-green-200:focus {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
}

.focus\:from-green-300:focus {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
}

.focus\:from-green-400:focus {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
}

.focus\:from-green-500:focus {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
}

.focus\:from-green-600:focus {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
}

.focus\:from-green-700:focus {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
}

.focus\:from-green-800:focus {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
}

.focus\:from-green-900:focus {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
}

.focus\:from-blue-50:focus {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
}

.focus\:from-blue-100:focus {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
}

.focus\:from-blue-200:focus {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
}

.focus\:from-blue-300:focus {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
}

.focus\:from-blue-400:focus {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
}

.focus\:from-blue-500:focus {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
}

.focus\:from-blue-600:focus {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
}

.focus\:from-blue-700:focus {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
}

.focus\:from-blue-800:focus {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
}

.focus\:from-blue-900:focus {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
}

.focus\:from-indigo-50:focus {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
}

.focus\:from-indigo-100:focus {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
}

.focus\:from-indigo-200:focus {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
}

.focus\:from-indigo-300:focus {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
}

.focus\:from-indigo-400:focus {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
}

.focus\:from-indigo-500:focus {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
}

.focus\:from-indigo-600:focus {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
}

.focus\:from-indigo-700:focus {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
}

.focus\:from-indigo-800:focus {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
}

.focus\:from-indigo-900:focus {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
}

.focus\:from-purple-50:focus {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
}

.focus\:from-purple-100:focus {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
}

.focus\:from-purple-200:focus {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
}

.focus\:from-purple-300:focus {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
}

.focus\:from-purple-400:focus {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
}

.focus\:from-purple-500:focus {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
}

.focus\:from-purple-600:focus {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
}

.focus\:from-purple-700:focus {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
}

.focus\:from-purple-800:focus {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
}

.focus\:from-purple-900:focus {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
}

.focus\:from-pink-50:focus {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
}

.focus\:from-pink-100:focus {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
}

.focus\:from-pink-200:focus {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
}

.focus\:from-pink-300:focus {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
}

.focus\:from-pink-400:focus {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
}

.focus\:from-pink-500:focus {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
}

.focus\:from-pink-600:focus {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
}

.focus\:from-pink-700:focus {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
}

.focus\:from-pink-800:focus {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
}

.focus\:from-pink-900:focus {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
}

.via-transparent {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
}

.via-current {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
}

.via-black {
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
}

.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
}

.via-gray-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
}

.via-gray-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
}

.via-gray-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
}

.via-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
}

.via-gray-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
}

.via-gray-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
}

.via-gray-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
}

.via-gray-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
}

.via-gray-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
}

.via-gray-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
}

.via-red {
  --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
}

.via-yellow-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
}

.via-yellow-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
}

.via-yellow-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
}

.via-yellow-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
}

.via-yellow-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
}

.via-yellow-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
}

.via-yellow-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
}

.via-yellow-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
}

.via-yellow-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
}

.via-yellow-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
}

.via-green-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
}

.via-green-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
}

.via-green-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
}

.via-green-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
}

.via-green-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
}

.via-green-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
}

.via-green-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
}

.via-green-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
}

.via-green-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
}

.via-green-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
}

.via-blue-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
}

.via-blue-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
}

.via-blue-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
}

.via-blue-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
}

.via-blue-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
}

.via-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
}

.via-blue-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
}

.via-blue-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
}

.via-blue-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
}

.via-blue-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
}

.via-indigo-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
}

.via-indigo-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
}

.via-indigo-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
}

.via-indigo-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
}

.via-indigo-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
}

.via-indigo-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
}

.via-indigo-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
}

.via-indigo-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
}

.via-indigo-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
}

.via-indigo-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
}

.via-purple-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
}

.via-purple-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
}

.via-purple-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
}

.via-purple-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
}

.via-purple-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
}

.via-purple-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
}

.via-purple-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
}

.via-purple-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
}

.via-purple-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
}

.via-purple-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
}

.via-pink-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
}

.via-pink-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
}

.via-pink-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
}

.via-pink-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
}

.via-pink-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
}

.via-pink-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
}

.via-pink-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
}

.via-pink-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
}

.via-pink-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
}

.via-pink-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
}

.hover\:via-transparent:hover {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
}

.hover\:via-current:hover {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
}

.hover\:via-black:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
}

.hover\:via-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
}

.hover\:via-gray-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
}

.hover\:via-gray-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
}

.hover\:via-gray-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
}

.hover\:via-gray-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
}

.hover\:via-gray-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
}

.hover\:via-gray-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
}

.hover\:via-gray-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
}

.hover\:via-gray-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
}

.hover\:via-gray-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
}

.hover\:via-gray-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
}

.hover\:via-red:hover {
  --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
}

.hover\:via-yellow-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
}

.hover\:via-yellow-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
}

.hover\:via-yellow-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
}

.hover\:via-yellow-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
}

.hover\:via-yellow-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
}

.hover\:via-yellow-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
}

.hover\:via-yellow-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
}

.hover\:via-yellow-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
}

.hover\:via-yellow-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
}

.hover\:via-yellow-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
}

.hover\:via-green-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
}

.hover\:via-green-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
}

.hover\:via-green-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
}

.hover\:via-green-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
}

.hover\:via-green-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
}

.hover\:via-green-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
}

.hover\:via-green-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
}

.hover\:via-green-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
}

.hover\:via-green-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
}

.hover\:via-green-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
}

.hover\:via-blue-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
}

.hover\:via-blue-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
}

.hover\:via-blue-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
}

.hover\:via-blue-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
}

.hover\:via-blue-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
}

.hover\:via-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
}

.hover\:via-blue-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
}

.hover\:via-blue-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
}

.hover\:via-blue-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
}

.hover\:via-blue-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
}

.hover\:via-indigo-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
}

.hover\:via-indigo-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
}

.hover\:via-indigo-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
}

.hover\:via-indigo-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
}

.hover\:via-indigo-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
}

.hover\:via-indigo-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
}

.hover\:via-indigo-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
}

.hover\:via-indigo-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
}

.hover\:via-indigo-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
}

.hover\:via-indigo-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
}

.hover\:via-purple-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
}

.hover\:via-purple-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
}

.hover\:via-purple-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
}

.hover\:via-purple-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
}

.hover\:via-purple-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
}

.hover\:via-purple-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
}

.hover\:via-purple-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
}

.hover\:via-purple-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
}

.hover\:via-purple-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
}

.hover\:via-purple-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
}

.hover\:via-pink-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
}

.hover\:via-pink-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
}

.hover\:via-pink-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
}

.hover\:via-pink-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
}

.hover\:via-pink-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
}

.hover\:via-pink-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
}

.hover\:via-pink-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
}

.hover\:via-pink-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
}

.hover\:via-pink-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
}

.hover\:via-pink-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
}

.focus\:via-transparent:focus {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
}

.focus\:via-current:focus {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
}

.focus\:via-black:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
}

.focus\:via-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
}

.focus\:via-gray-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
}

.focus\:via-gray-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
}

.focus\:via-gray-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
}

.focus\:via-gray-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
}

.focus\:via-gray-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
}

.focus\:via-gray-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
}

.focus\:via-gray-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
}

.focus\:via-gray-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
}

.focus\:via-gray-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
}

.focus\:via-gray-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
}

.focus\:via-red:focus {
  --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
}

.focus\:via-yellow-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
}

.focus\:via-yellow-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
}

.focus\:via-yellow-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
}

.focus\:via-yellow-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
}

.focus\:via-yellow-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
}

.focus\:via-yellow-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
}

.focus\:via-yellow-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
}

.focus\:via-yellow-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
}

.focus\:via-yellow-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
}

.focus\:via-yellow-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
}

.focus\:via-green-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
}

.focus\:via-green-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
}

.focus\:via-green-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
}

.focus\:via-green-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
}

.focus\:via-green-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
}

.focus\:via-green-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
}

.focus\:via-green-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
}

.focus\:via-green-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
}

.focus\:via-green-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
}

.focus\:via-green-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
}

.focus\:via-blue-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
}

.focus\:via-blue-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
}

.focus\:via-blue-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
}

.focus\:via-blue-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
}

.focus\:via-blue-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
}

.focus\:via-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
}

.focus\:via-blue-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
}

.focus\:via-blue-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
}

.focus\:via-blue-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
}

.focus\:via-blue-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
}

.focus\:via-indigo-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
}

.focus\:via-indigo-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
}

.focus\:via-indigo-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
}

.focus\:via-indigo-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
}

.focus\:via-indigo-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
}

.focus\:via-indigo-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
}

.focus\:via-indigo-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
}

.focus\:via-indigo-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
}

.focus\:via-indigo-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
}

.focus\:via-indigo-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
}

.focus\:via-purple-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
}

.focus\:via-purple-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
}

.focus\:via-purple-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
}

.focus\:via-purple-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
}

.focus\:via-purple-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
}

.focus\:via-purple-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
}

.focus\:via-purple-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
}

.focus\:via-purple-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
}

.focus\:via-purple-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
}

.focus\:via-purple-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
}

.focus\:via-pink-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
}

.focus\:via-pink-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
}

.focus\:via-pink-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
}

.focus\:via-pink-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
}

.focus\:via-pink-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
}

.focus\:via-pink-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
}

.focus\:via-pink-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
}

.focus\:via-pink-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
}

.focus\:via-pink-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
}

.focus\:via-pink-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
}

.to-transparent {
  --tw-gradient-to: transparent;
}

.to-current {
  --tw-gradient-to: currentColor;
}

.to-black {
  --tw-gradient-to: #000;
}

.to-white {
  --tw-gradient-to: #fff;
}

.to-gray-50 {
  --tw-gradient-to: #f9fafb;
}

.to-gray-100 {
  --tw-gradient-to: #f3f4f6;
}

.to-gray-200 {
  --tw-gradient-to: #e5e7eb;
}

.to-gray-300 {
  --tw-gradient-to: #d1d5db;
}

.to-gray-400 {
  --tw-gradient-to: #9ca3af;
}

.to-gray-500 {
  --tw-gradient-to: #6b7280;
}

.to-gray-600 {
  --tw-gradient-to: #4b5563;
}

.to-gray-700 {
  --tw-gradient-to: #374151;
}

.to-gray-800 {
  --tw-gradient-to: #1f2937;
}

.to-gray-900 {
  --tw-gradient-to: #111827;
}

.to-red {
  --tw-gradient-to: red;
}

.to-yellow-50 {
  --tw-gradient-to: #fffbeb;
}

.to-yellow-100 {
  --tw-gradient-to: #fef3c7;
}

.to-yellow-200 {
  --tw-gradient-to: #fde68a;
}

.to-yellow-300 {
  --tw-gradient-to: #fcd34d;
}

.to-yellow-400 {
  --tw-gradient-to: #fbbf24;
}

.to-yellow-500 {
  --tw-gradient-to: #f59e0b;
}

.to-yellow-600 {
  --tw-gradient-to: #d97706;
}

.to-yellow-700 {
  --tw-gradient-to: #b45309;
}

.to-yellow-800 {
  --tw-gradient-to: #92400e;
}

.to-yellow-900 {
  --tw-gradient-to: #78350f;
}

.to-green-50 {
  --tw-gradient-to: #ecfdf5;
}

.to-green-100 {
  --tw-gradient-to: #d1fae5;
}

.to-green-200 {
  --tw-gradient-to: #a7f3d0;
}

.to-green-300 {
  --tw-gradient-to: #6ee7b7;
}

.to-green-400 {
  --tw-gradient-to: #34d399;
}

.to-green-500 {
  --tw-gradient-to: #10b981;
}

.to-green-600 {
  --tw-gradient-to: #059669;
}

.to-green-700 {
  --tw-gradient-to: #047857;
}

.to-green-800 {
  --tw-gradient-to: #065f46;
}

.to-green-900 {
  --tw-gradient-to: #064e3b;
}

.to-blue-50 {
  --tw-gradient-to: #eff6ff;
}

.to-blue-100 {
  --tw-gradient-to: #dbeafe;
}

.to-blue-200 {
  --tw-gradient-to: #bfdbfe;
}

.to-blue-300 {
  --tw-gradient-to: #93c5fd;
}

.to-blue-400 {
  --tw-gradient-to: #60a5fa;
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6;
}

.to-blue-600 {
  --tw-gradient-to: #2563eb;
}

.to-blue-700 {
  --tw-gradient-to: #1d4ed8;
}

.to-blue-800 {
  --tw-gradient-to: #1e40af;
}

.to-blue-900 {
  --tw-gradient-to: #1e3a8a;
}

.to-indigo-50 {
  --tw-gradient-to: #eef2ff;
}

.to-indigo-100 {
  --tw-gradient-to: #e0e7ff;
}

.to-indigo-200 {
  --tw-gradient-to: #c7d2fe;
}

.to-indigo-300 {
  --tw-gradient-to: #a5b4fc;
}

.to-indigo-400 {
  --tw-gradient-to: #818cf8;
}

.to-indigo-500 {
  --tw-gradient-to: #6366f1;
}

.to-indigo-600 {
  --tw-gradient-to: #4f46e5;
}

.to-indigo-700 {
  --tw-gradient-to: #4338ca;
}

.to-indigo-800 {
  --tw-gradient-to: #3730a3;
}

.to-indigo-900 {
  --tw-gradient-to: #312e81;
}

.to-purple-50 {
  --tw-gradient-to: #f5f3ff;
}

.to-purple-100 {
  --tw-gradient-to: #ede9fe;
}

.to-purple-200 {
  --tw-gradient-to: #ddd6fe;
}

.to-purple-300 {
  --tw-gradient-to: #c4b5fd;
}

.to-purple-400 {
  --tw-gradient-to: #a78bfa;
}

.to-purple-500 {
  --tw-gradient-to: #8b5cf6;
}

.to-purple-600 {
  --tw-gradient-to: #7c3aed;
}

.to-purple-700 {
  --tw-gradient-to: #6d28d9;
}

.to-purple-800 {
  --tw-gradient-to: #5b21b6;
}

.to-purple-900 {
  --tw-gradient-to: #4c1d95;
}

.to-pink-50 {
  --tw-gradient-to: #fdf2f8;
}

.to-pink-100 {
  --tw-gradient-to: #fce7f3;
}

.to-pink-200 {
  --tw-gradient-to: #fbcfe8;
}

.to-pink-300 {
  --tw-gradient-to: #f9a8d4;
}

.to-pink-400 {
  --tw-gradient-to: #f472b6;
}

.to-pink-500 {
  --tw-gradient-to: #ec4899;
}

.to-pink-600 {
  --tw-gradient-to: #db2777;
}

.to-pink-700 {
  --tw-gradient-to: #be185d;
}

.to-pink-800 {
  --tw-gradient-to: #9d174d;
}

.to-pink-900 {
  --tw-gradient-to: #831843;
}

.hover\:to-transparent:hover {
  --tw-gradient-to: transparent;
}

.hover\:to-current:hover {
  --tw-gradient-to: currentColor;
}

.hover\:to-black:hover {
  --tw-gradient-to: #000;
}

.hover\:to-white:hover {
  --tw-gradient-to: #fff;
}

.hover\:to-gray-50:hover {
  --tw-gradient-to: #f9fafb;
}

.hover\:to-gray-100:hover {
  --tw-gradient-to: #f3f4f6;
}

.hover\:to-gray-200:hover {
  --tw-gradient-to: #e5e7eb;
}

.hover\:to-gray-300:hover {
  --tw-gradient-to: #d1d5db;
}

.hover\:to-gray-400:hover {
  --tw-gradient-to: #9ca3af;
}

.hover\:to-gray-500:hover {
  --tw-gradient-to: #6b7280;
}

.hover\:to-gray-600:hover {
  --tw-gradient-to: #4b5563;
}

.hover\:to-gray-700:hover {
  --tw-gradient-to: #374151;
}

.hover\:to-gray-800:hover {
  --tw-gradient-to: #1f2937;
}

.hover\:to-gray-900:hover {
  --tw-gradient-to: #111827;
}

.hover\:to-red:hover {
  --tw-gradient-to: red;
}

.hover\:to-yellow-50:hover {
  --tw-gradient-to: #fffbeb;
}

.hover\:to-yellow-100:hover {
  --tw-gradient-to: #fef3c7;
}

.hover\:to-yellow-200:hover {
  --tw-gradient-to: #fde68a;
}

.hover\:to-yellow-300:hover {
  --tw-gradient-to: #fcd34d;
}

.hover\:to-yellow-400:hover {
  --tw-gradient-to: #fbbf24;
}

.hover\:to-yellow-500:hover {
  --tw-gradient-to: #f59e0b;
}

.hover\:to-yellow-600:hover {
  --tw-gradient-to: #d97706;
}

.hover\:to-yellow-700:hover {
  --tw-gradient-to: #b45309;
}

.hover\:to-yellow-800:hover {
  --tw-gradient-to: #92400e;
}

.hover\:to-yellow-900:hover {
  --tw-gradient-to: #78350f;
}

.hover\:to-green-50:hover {
  --tw-gradient-to: #ecfdf5;
}

.hover\:to-green-100:hover {
  --tw-gradient-to: #d1fae5;
}

.hover\:to-green-200:hover {
  --tw-gradient-to: #a7f3d0;
}

.hover\:to-green-300:hover {
  --tw-gradient-to: #6ee7b7;
}

.hover\:to-green-400:hover {
  --tw-gradient-to: #34d399;
}

.hover\:to-green-500:hover {
  --tw-gradient-to: #10b981;
}

.hover\:to-green-600:hover {
  --tw-gradient-to: #059669;
}

.hover\:to-green-700:hover {
  --tw-gradient-to: #047857;
}

.hover\:to-green-800:hover {
  --tw-gradient-to: #065f46;
}

.hover\:to-green-900:hover {
  --tw-gradient-to: #064e3b;
}

.hover\:to-blue-50:hover {
  --tw-gradient-to: #eff6ff;
}

.hover\:to-blue-100:hover {
  --tw-gradient-to: #dbeafe;
}

.hover\:to-blue-200:hover {
  --tw-gradient-to: #bfdbfe;
}

.hover\:to-blue-300:hover {
  --tw-gradient-to: #93c5fd;
}

.hover\:to-blue-400:hover {
  --tw-gradient-to: #60a5fa;
}

.hover\:to-blue-500:hover {
  --tw-gradient-to: #3b82f6;
}

.hover\:to-blue-600:hover {
  --tw-gradient-to: #2563eb;
}

.hover\:to-blue-700:hover {
  --tw-gradient-to: #1d4ed8;
}

.hover\:to-blue-800:hover {
  --tw-gradient-to: #1e40af;
}

.hover\:to-blue-900:hover {
  --tw-gradient-to: #1e3a8a;
}

.hover\:to-indigo-50:hover {
  --tw-gradient-to: #eef2ff;
}

.hover\:to-indigo-100:hover {
  --tw-gradient-to: #e0e7ff;
}

.hover\:to-indigo-200:hover {
  --tw-gradient-to: #c7d2fe;
}

.hover\:to-indigo-300:hover {
  --tw-gradient-to: #a5b4fc;
}

.hover\:to-indigo-400:hover {
  --tw-gradient-to: #818cf8;
}

.hover\:to-indigo-500:hover {
  --tw-gradient-to: #6366f1;
}

.hover\:to-indigo-600:hover {
  --tw-gradient-to: #4f46e5;
}

.hover\:to-indigo-700:hover {
  --tw-gradient-to: #4338ca;
}

.hover\:to-indigo-800:hover {
  --tw-gradient-to: #3730a3;
}

.hover\:to-indigo-900:hover {
  --tw-gradient-to: #312e81;
}

.hover\:to-purple-50:hover {
  --tw-gradient-to: #f5f3ff;
}

.hover\:to-purple-100:hover {
  --tw-gradient-to: #ede9fe;
}

.hover\:to-purple-200:hover {
  --tw-gradient-to: #ddd6fe;
}

.hover\:to-purple-300:hover {
  --tw-gradient-to: #c4b5fd;
}

.hover\:to-purple-400:hover {
  --tw-gradient-to: #a78bfa;
}

.hover\:to-purple-500:hover {
  --tw-gradient-to: #8b5cf6;
}

.hover\:to-purple-600:hover {
  --tw-gradient-to: #7c3aed;
}

.hover\:to-purple-700:hover {
  --tw-gradient-to: #6d28d9;
}

.hover\:to-purple-800:hover {
  --tw-gradient-to: #5b21b6;
}

.hover\:to-purple-900:hover {
  --tw-gradient-to: #4c1d95;
}

.hover\:to-pink-50:hover {
  --tw-gradient-to: #fdf2f8;
}

.hover\:to-pink-100:hover {
  --tw-gradient-to: #fce7f3;
}

.hover\:to-pink-200:hover {
  --tw-gradient-to: #fbcfe8;
}

.hover\:to-pink-300:hover {
  --tw-gradient-to: #f9a8d4;
}

.hover\:to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}

.hover\:to-pink-500:hover {
  --tw-gradient-to: #ec4899;
}

.hover\:to-pink-600:hover {
  --tw-gradient-to: #db2777;
}

.hover\:to-pink-700:hover {
  --tw-gradient-to: #be185d;
}

.hover\:to-pink-800:hover {
  --tw-gradient-to: #9d174d;
}

.hover\:to-pink-900:hover {
  --tw-gradient-to: #831843;
}

.focus\:to-transparent:focus {
  --tw-gradient-to: transparent;
}

.focus\:to-current:focus {
  --tw-gradient-to: currentColor;
}

.focus\:to-black:focus {
  --tw-gradient-to: #000;
}

.focus\:to-white:focus {
  --tw-gradient-to: #fff;
}

.focus\:to-gray-50:focus {
  --tw-gradient-to: #f9fafb;
}

.focus\:to-gray-100:focus {
  --tw-gradient-to: #f3f4f6;
}

.focus\:to-gray-200:focus {
  --tw-gradient-to: #e5e7eb;
}

.focus\:to-gray-300:focus {
  --tw-gradient-to: #d1d5db;
}

.focus\:to-gray-400:focus {
  --tw-gradient-to: #9ca3af;
}

.focus\:to-gray-500:focus {
  --tw-gradient-to: #6b7280;
}

.focus\:to-gray-600:focus {
  --tw-gradient-to: #4b5563;
}

.focus\:to-gray-700:focus {
  --tw-gradient-to: #374151;
}

.focus\:to-gray-800:focus {
  --tw-gradient-to: #1f2937;
}

.focus\:to-gray-900:focus {
  --tw-gradient-to: #111827;
}

.focus\:to-red:focus {
  --tw-gradient-to: red;
}

.focus\:to-yellow-50:focus {
  --tw-gradient-to: #fffbeb;
}

.focus\:to-yellow-100:focus {
  --tw-gradient-to: #fef3c7;
}

.focus\:to-yellow-200:focus {
  --tw-gradient-to: #fde68a;
}

.focus\:to-yellow-300:focus {
  --tw-gradient-to: #fcd34d;
}

.focus\:to-yellow-400:focus {
  --tw-gradient-to: #fbbf24;
}

.focus\:to-yellow-500:focus {
  --tw-gradient-to: #f59e0b;
}

.focus\:to-yellow-600:focus {
  --tw-gradient-to: #d97706;
}

.focus\:to-yellow-700:focus {
  --tw-gradient-to: #b45309;
}

.focus\:to-yellow-800:focus {
  --tw-gradient-to: #92400e;
}

.focus\:to-yellow-900:focus {
  --tw-gradient-to: #78350f;
}

.focus\:to-green-50:focus {
  --tw-gradient-to: #ecfdf5;
}

.focus\:to-green-100:focus {
  --tw-gradient-to: #d1fae5;
}

.focus\:to-green-200:focus {
  --tw-gradient-to: #a7f3d0;
}

.focus\:to-green-300:focus {
  --tw-gradient-to: #6ee7b7;
}

.focus\:to-green-400:focus {
  --tw-gradient-to: #34d399;
}

.focus\:to-green-500:focus {
  --tw-gradient-to: #10b981;
}

.focus\:to-green-600:focus {
  --tw-gradient-to: #059669;
}

.focus\:to-green-700:focus {
  --tw-gradient-to: #047857;
}

.focus\:to-green-800:focus {
  --tw-gradient-to: #065f46;
}

.focus\:to-green-900:focus {
  --tw-gradient-to: #064e3b;
}

.focus\:to-blue-50:focus {
  --tw-gradient-to: #eff6ff;
}

.focus\:to-blue-100:focus {
  --tw-gradient-to: #dbeafe;
}

.focus\:to-blue-200:focus {
  --tw-gradient-to: #bfdbfe;
}

.focus\:to-blue-300:focus {
  --tw-gradient-to: #93c5fd;
}

.focus\:to-blue-400:focus {
  --tw-gradient-to: #60a5fa;
}

.focus\:to-blue-500:focus {
  --tw-gradient-to: #3b82f6;
}

.focus\:to-blue-600:focus {
  --tw-gradient-to: #2563eb;
}

.focus\:to-blue-700:focus {
  --tw-gradient-to: #1d4ed8;
}

.focus\:to-blue-800:focus {
  --tw-gradient-to: #1e40af;
}

.focus\:to-blue-900:focus {
  --tw-gradient-to: #1e3a8a;
}

.focus\:to-indigo-50:focus {
  --tw-gradient-to: #eef2ff;
}

.focus\:to-indigo-100:focus {
  --tw-gradient-to: #e0e7ff;
}

.focus\:to-indigo-200:focus {
  --tw-gradient-to: #c7d2fe;
}

.focus\:to-indigo-300:focus {
  --tw-gradient-to: #a5b4fc;
}

.focus\:to-indigo-400:focus {
  --tw-gradient-to: #818cf8;
}

.focus\:to-indigo-500:focus {
  --tw-gradient-to: #6366f1;
}

.focus\:to-indigo-600:focus {
  --tw-gradient-to: #4f46e5;
}

.focus\:to-indigo-700:focus {
  --tw-gradient-to: #4338ca;
}

.focus\:to-indigo-800:focus {
  --tw-gradient-to: #3730a3;
}

.focus\:to-indigo-900:focus {
  --tw-gradient-to: #312e81;
}

.focus\:to-purple-50:focus {
  --tw-gradient-to: #f5f3ff;
}

.focus\:to-purple-100:focus {
  --tw-gradient-to: #ede9fe;
}

.focus\:to-purple-200:focus {
  --tw-gradient-to: #ddd6fe;
}

.focus\:to-purple-300:focus {
  --tw-gradient-to: #c4b5fd;
}

.focus\:to-purple-400:focus {
  --tw-gradient-to: #a78bfa;
}

.focus\:to-purple-500:focus {
  --tw-gradient-to: #8b5cf6;
}

.focus\:to-purple-600:focus {
  --tw-gradient-to: #7c3aed;
}

.focus\:to-purple-700:focus {
  --tw-gradient-to: #6d28d9;
}

.focus\:to-purple-800:focus {
  --tw-gradient-to: #5b21b6;
}

.focus\:to-purple-900:focus {
  --tw-gradient-to: #4c1d95;
}

.focus\:to-pink-50:focus {
  --tw-gradient-to: #fdf2f8;
}

.focus\:to-pink-100:focus {
  --tw-gradient-to: #fce7f3;
}

.focus\:to-pink-200:focus {
  --tw-gradient-to: #fbcfe8;
}

.focus\:to-pink-300:focus {
  --tw-gradient-to: #f9a8d4;
}

.focus\:to-pink-400:focus {
  --tw-gradient-to: #f472b6;
}

.focus\:to-pink-500:focus {
  --tw-gradient-to: #ec4899;
}

.focus\:to-pink-600:focus {
  --tw-gradient-to: #db2777;
}

.focus\:to-pink-700:focus {
  --tw-gradient-to: #be185d;
}

.focus\:to-pink-800:focus {
  --tw-gradient-to: #9d174d;
}

.focus\:to-pink-900:focus {
  --tw-gradient-to: #831843;
}

.decoration-slice {
  -webkit-box-decoration-break: slice;
  box-decoration-break: slice;
}

.decoration-clone {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: 0;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-right {
  background-position: 100%;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-origin-border {
  background-origin: border-box;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.bg-origin-content {
  background-origin: content-box;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-bottom {
  object-position: bottom;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right {
  object-position: right;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-72 {
  padding: 18rem;
}

.p-80 {
  padding: 20rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3\.5 {
  padding: .875rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-52 {
  padding-top: 13rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pt-80 {
  padding-top: 20rem;
}

.pt-96 {
  padding-top: 24rem;
}

.pt-px {
  padding-top: 1px;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pr-36 {
  padding-right: 9rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-44 {
  padding-right: 11rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pr-52 {
  padding-right: 13rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pr-60 {
  padding-right: 15rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pr-72 {
  padding-right: 18rem;
}

.pr-80 {
  padding-right: 20rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pr-px {
  padding-right: 1px;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-72 {
  padding-bottom: 18rem;
}

.pb-80 {
  padding-bottom: 20rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-28 {
  padding-left: 7rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-44 {
  padding-left: 11rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pl-52 {
  padding-left: 13rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pl-60 {
  padding-left: 15rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pl-72 {
  padding-left: 18rem;
}

.pl-80 {
  padding-left: 20rem;
}

.pl-96 {
  padding-left: 24rem;
}

.pl-px {
  padding-left: 1px;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.font-body {
  font-family: Montserrat;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --tw-ordinal: var(--tw-empty, );
  --tw-slashed-zero: var(--tw-empty, );
  --tw-numeric-figure: var(--tw-empty, );
  --tw-numeric-spacing: var(--tw-empty, );
  --tw-numeric-fraction: var(--tw-empty, );
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --tw-ordinal: ordinal;
}

.slashed-zero {
  --tw-slashed-zero: slashed-zero;
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}

.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
}

.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-transparent {
  color: #0000;
}

.text-current {
  color: currentColor;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-transparent {
  color: #0000;
}

.group:hover .group-hover\:text-current {
  color: currentColor;
}

.group:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-red {
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.focus-within\:text-transparent:focus-within {
  color: #0000;
}

.focus-within\:text-current:focus-within {
  color: currentColor;
}

.focus-within\:text-black:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.focus-within\:text-white:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus-within\:text-gray-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.focus-within\:text-gray-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.focus-within\:text-gray-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.focus-within\:text-gray-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.focus-within\:text-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.focus-within\:text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.focus-within\:text-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.focus-within\:text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.focus-within\:text-gray-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.focus-within\:text-gray-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.focus-within\:text-red:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

.focus-within\:text-yellow-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.focus-within\:text-yellow-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.focus-within\:text-yellow-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.focus-within\:text-yellow-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.focus-within\:text-yellow-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.focus-within\:text-yellow-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.focus-within\:text-yellow-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.focus-within\:text-yellow-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.focus-within\:text-yellow-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.focus-within\:text-yellow-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.focus-within\:text-green-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.focus-within\:text-green-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.focus-within\:text-green-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.focus-within\:text-green-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.focus-within\:text-green-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.focus-within\:text-green-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.focus-within\:text-green-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.focus-within\:text-green-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.focus-within\:text-green-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.focus-within\:text-green-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.focus-within\:text-blue-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.focus-within\:text-blue-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.focus-within\:text-blue-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.focus-within\:text-blue-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.focus-within\:text-blue-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.focus-within\:text-blue-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.focus-within\:text-blue-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.focus-within\:text-blue-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.focus-within\:text-blue-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.focus-within\:text-blue-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.focus-within\:text-indigo-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.focus-within\:text-indigo-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.focus-within\:text-indigo-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.focus-within\:text-indigo-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.focus-within\:text-indigo-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.focus-within\:text-indigo-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.focus-within\:text-indigo-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.focus-within\:text-indigo-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.focus-within\:text-indigo-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.focus-within\:text-indigo-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.focus-within\:text-purple-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.focus-within\:text-purple-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.focus-within\:text-purple-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.focus-within\:text-purple-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.focus-within\:text-purple-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.focus-within\:text-purple-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.focus-within\:text-purple-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.focus-within\:text-purple-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.focus-within\:text-purple-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.focus-within\:text-purple-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.focus-within\:text-pink-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.focus-within\:text-pink-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.focus-within\:text-pink-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.focus-within\:text-pink-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.focus-within\:text-pink-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.focus-within\:text-pink-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.focus-within\:text-pink-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.focus-within\:text-pink-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.focus-within\:text-pink-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.focus-within\:text-pink-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.hover\:text-transparent:hover {
  color: #0000;
}

.hover\:text-current:hover {
  color: currentColor;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:text-red:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.focus\:text-transparent:focus {
  color: #0000;
}

.focus\:text-current:focus {
  color: currentColor;
}

.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus\:text-gray-50:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.focus\:text-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.focus\:text-gray-200:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.focus\:text-gray-300:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.focus\:text-gray-400:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.focus\:text-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.focus\:text-gray-600:focus {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.focus\:text-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.focus\:text-red:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

.focus\:text-yellow-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.focus\:text-yellow-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.focus\:text-yellow-200:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.focus\:text-yellow-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.focus\:text-yellow-400:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.focus\:text-yellow-500:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.focus\:text-yellow-600:focus {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.focus\:text-yellow-700:focus {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.focus\:text-yellow-800:focus {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.focus\:text-yellow-900:focus {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.focus\:text-green-50:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.focus\:text-green-100:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.focus\:text-green-200:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.focus\:text-green-300:focus {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.focus\:text-green-400:focus {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.focus\:text-green-500:focus {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.focus\:text-green-600:focus {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.focus\:text-green-700:focus {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.focus\:text-green-800:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.focus\:text-green-900:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.focus\:text-blue-50:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.focus\:text-blue-100:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.focus\:text-blue-200:focus {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.focus\:text-blue-300:focus {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.focus\:text-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.focus\:text-blue-500:focus {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.focus\:text-blue-600:focus {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.focus\:text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.focus\:text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.focus\:text-blue-900:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.focus\:text-indigo-50:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.focus\:text-indigo-100:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.focus\:text-indigo-200:focus {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.focus\:text-indigo-300:focus {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.focus\:text-indigo-400:focus {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.focus\:text-indigo-500:focus {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.focus\:text-indigo-600:focus {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.focus\:text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.focus\:text-indigo-900:focus {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.focus\:text-purple-50:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.focus\:text-purple-100:focus {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.focus\:text-purple-200:focus {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.focus\:text-purple-300:focus {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.focus\:text-purple-400:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.focus\:text-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.focus\:text-purple-600:focus {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.focus\:text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.focus\:text-purple-800:focus {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.focus\:text-purple-900:focus {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.focus\:text-pink-50:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.focus\:text-pink-100:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.focus\:text-pink-200:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.focus\:text-pink-300:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.focus\:text-pink-400:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.focus\:text-pink-500:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.focus\:text-pink-600:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.focus\:text-pink-700:focus {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.focus\:text-pink-800:focus {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.focus\:text-pink-900:focus {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-5 {
  --tw-text-opacity: .05;
}

.text-opacity-10 {
  --tw-text-opacity: .1;
}

.text-opacity-20 {
  --tw-text-opacity: .2;
}

.text-opacity-25 {
  --tw-text-opacity: .25;
}

.text-opacity-30 {
  --tw-text-opacity: .3;
}

.text-opacity-40 {
  --tw-text-opacity: .4;
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.text-opacity-70 {
  --tw-text-opacity: .7;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.text-opacity-80 {
  --tw-text-opacity: .8;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.text-opacity-95 {
  --tw-text-opacity: .95;
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.group:hover .group-hover\:text-opacity-0 {
  --tw-text-opacity: 0;
}

.group:hover .group-hover\:text-opacity-5 {
  --tw-text-opacity: .05;
}

.group:hover .group-hover\:text-opacity-10 {
  --tw-text-opacity: .1;
}

.group:hover .group-hover\:text-opacity-20 {
  --tw-text-opacity: .2;
}

.group:hover .group-hover\:text-opacity-25 {
  --tw-text-opacity: .25;
}

.group:hover .group-hover\:text-opacity-30 {
  --tw-text-opacity: .3;
}

.group:hover .group-hover\:text-opacity-40 {
  --tw-text-opacity: .4;
}

.group:hover .group-hover\:text-opacity-50 {
  --tw-text-opacity: .5;
}

.group:hover .group-hover\:text-opacity-60 {
  --tw-text-opacity: .6;
}

.group:hover .group-hover\:text-opacity-70 {
  --tw-text-opacity: .7;
}

.group:hover .group-hover\:text-opacity-75 {
  --tw-text-opacity: .75;
}

.group:hover .group-hover\:text-opacity-80 {
  --tw-text-opacity: .8;
}

.group:hover .group-hover\:text-opacity-90 {
  --tw-text-opacity: .9;
}

.group:hover .group-hover\:text-opacity-95 {
  --tw-text-opacity: .95;
}

.group:hover .group-hover\:text-opacity-100 {
  --tw-text-opacity: 1;
}

.focus-within\:text-opacity-0:focus-within {
  --tw-text-opacity: 0;
}

.focus-within\:text-opacity-5:focus-within {
  --tw-text-opacity: .05;
}

.focus-within\:text-opacity-10:focus-within {
  --tw-text-opacity: .1;
}

.focus-within\:text-opacity-20:focus-within {
  --tw-text-opacity: .2;
}

.focus-within\:text-opacity-25:focus-within {
  --tw-text-opacity: .25;
}

.focus-within\:text-opacity-30:focus-within {
  --tw-text-opacity: .3;
}

.focus-within\:text-opacity-40:focus-within {
  --tw-text-opacity: .4;
}

.focus-within\:text-opacity-50:focus-within {
  --tw-text-opacity: .5;
}

.focus-within\:text-opacity-60:focus-within {
  --tw-text-opacity: .6;
}

.focus-within\:text-opacity-70:focus-within {
  --tw-text-opacity: .7;
}

.focus-within\:text-opacity-75:focus-within {
  --tw-text-opacity: .75;
}

.focus-within\:text-opacity-80:focus-within {
  --tw-text-opacity: .8;
}

.focus-within\:text-opacity-90:focus-within {
  --tw-text-opacity: .9;
}

.focus-within\:text-opacity-95:focus-within {
  --tw-text-opacity: .95;
}

.focus-within\:text-opacity-100:focus-within {
  --tw-text-opacity: 1;
}

.hover\:text-opacity-0:hover {
  --tw-text-opacity: 0;
}

.hover\:text-opacity-5:hover {
  --tw-text-opacity: .05;
}

.hover\:text-opacity-10:hover {
  --tw-text-opacity: .1;
}

.hover\:text-opacity-20:hover {
  --tw-text-opacity: .2;
}

.hover\:text-opacity-25:hover {
  --tw-text-opacity: .25;
}

.hover\:text-opacity-30:hover {
  --tw-text-opacity: .3;
}

.hover\:text-opacity-40:hover {
  --tw-text-opacity: .4;
}

.hover\:text-opacity-50:hover {
  --tw-text-opacity: .5;
}

.hover\:text-opacity-60:hover {
  --tw-text-opacity: .6;
}

.hover\:text-opacity-70:hover {
  --tw-text-opacity: .7;
}

.hover\:text-opacity-75:hover {
  --tw-text-opacity: .75;
}

.hover\:text-opacity-80:hover {
  --tw-text-opacity: .8;
}

.hover\:text-opacity-90:hover {
  --tw-text-opacity: .9;
}

.hover\:text-opacity-95:hover {
  --tw-text-opacity: .95;
}

.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1;
}

.focus\:text-opacity-0:focus {
  --tw-text-opacity: 0;
}

.focus\:text-opacity-5:focus {
  --tw-text-opacity: .05;
}

.focus\:text-opacity-10:focus {
  --tw-text-opacity: .1;
}

.focus\:text-opacity-20:focus {
  --tw-text-opacity: .2;
}

.focus\:text-opacity-25:focus {
  --tw-text-opacity: .25;
}

.focus\:text-opacity-30:focus {
  --tw-text-opacity: .3;
}

.focus\:text-opacity-40:focus {
  --tw-text-opacity: .4;
}

.focus\:text-opacity-50:focus {
  --tw-text-opacity: .5;
}

.focus\:text-opacity-60:focus {
  --tw-text-opacity: .6;
}

.focus\:text-opacity-70:focus {
  --tw-text-opacity: .7;
}

.focus\:text-opacity-75:focus {
  --tw-text-opacity: .75;
}

.focus\:text-opacity-80:focus {
  --tw-text-opacity: .8;
}

.focus\:text-opacity-90:focus {
  --tw-text-opacity: .9;
}

.focus\:text-opacity-95:focus {
  --tw-text-opacity: .95;
}

.focus\:text-opacity-100:focus {
  --tw-text-opacity: 1;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.group:hover .group-hover\:underline {
  text-decoration: underline;
}

.group:hover .group-hover\:line-through {
  text-decoration: line-through;
}

.group:hover .group-hover\:no-underline {
  text-decoration: none;
}

.focus-within\:underline:focus-within {
  text-decoration: underline;
}

.focus-within\:line-through:focus-within {
  text-decoration: line-through;
}

.focus-within\:no-underline:focus-within {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:line-through:hover {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.focus\:underline:focus {
  text-decoration: underline;
}

.focus\:line-through:focus {
  text-decoration: line-through;
}

.focus\:no-underline:focus {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.placeholder-transparent::placeholder {
  color: #0000;
}

.placeholder-current::placeholder {
  color: currentColor;
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.placeholder-red::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
}

.placeholder-yellow-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.placeholder-yellow-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.placeholder-yellow-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.placeholder-yellow-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.placeholder-yellow-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.placeholder-yellow-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.placeholder-yellow-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.placeholder-yellow-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.placeholder-yellow-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.placeholder-green-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.placeholder-green-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.placeholder-green-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.placeholder-green-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.placeholder-green-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.placeholder-green-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.placeholder-green-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.placeholder-green-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.placeholder-green-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.placeholder-blue-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.placeholder-blue-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.placeholder-blue-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.placeholder-blue-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.placeholder-blue-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.placeholder-blue-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.placeholder-blue-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.placeholder-blue-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.placeholder-blue-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.focus\:placeholder-transparent:focus::placeholder {
  color: #0000;
}

.focus\:placeholder-current:focus::placeholder {
  color: currentColor;
}

.focus\:placeholder-black:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}

.focus\:placeholder-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}

.focus\:placeholder-red:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}

.focus\:placeholder-blue-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}

.focus\:placeholder-indigo-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}

.focus\:placeholder-purple-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}

.focus\:placeholder-pink-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}

.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}

.placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: .05;
}

.placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: .1;
}

.placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: .2;
}

.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: .25;
}

.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: .3;
}

.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: .4;
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: .5;
}

.placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: .6;
}

.placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: .7;
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: .75;
}

.placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: .8;
}

.placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: .9;
}

.placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: .95;
}

.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --tw-placeholder-opacity: 0;
}

.focus\:placeholder-opacity-5:focus::placeholder {
  --tw-placeholder-opacity: .05;
}

.focus\:placeholder-opacity-10:focus::placeholder {
  --tw-placeholder-opacity: .1;
}

.focus\:placeholder-opacity-20:focus::placeholder {
  --tw-placeholder-opacity: .2;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --tw-placeholder-opacity: .25;
}

.focus\:placeholder-opacity-30:focus::placeholder {
  --tw-placeholder-opacity: .3;
}

.focus\:placeholder-opacity-40:focus::placeholder {
  --tw-placeholder-opacity: .4;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --tw-placeholder-opacity: .5;
}

.focus\:placeholder-opacity-60:focus::placeholder {
  --tw-placeholder-opacity: .6;
}

.focus\:placeholder-opacity-70:focus::placeholder {
  --tw-placeholder-opacity: .7;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --tw-placeholder-opacity: .75;
}

.focus\:placeholder-opacity-80:focus::placeholder {
  --tw-placeholder-opacity: .8;
}

.focus\:placeholder-opacity-90:focus::placeholder {
  --tw-placeholder-opacity: .9;
}

.focus\:placeholder-opacity-95:focus::placeholder {
  --tw-placeholder-opacity: .95;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.opacity-5 {
  opacity: .05;
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.opacity-95 {
  opacity: .95;
}

.opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.group:hover .group-hover\:opacity-5 {
  opacity: .05;
}

.group:hover .group-hover\:opacity-10 {
  opacity: .1;
}

.group:hover .group-hover\:opacity-20 {
  opacity: .2;
}

.group:hover .group-hover\:opacity-25 {
  opacity: .25;
}

.group:hover .group-hover\:opacity-30 {
  opacity: .3;
}

.group:hover .group-hover\:opacity-40 {
  opacity: .4;
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5;
}

.group:hover .group-hover\:opacity-60 {
  opacity: .6;
}

.group:hover .group-hover\:opacity-70 {
  opacity: .7;
}

.group:hover .group-hover\:opacity-75 {
  opacity: .75;
}

.group:hover .group-hover\:opacity-80 {
  opacity: .8;
}

.group:hover .group-hover\:opacity-90 {
  opacity: .9;
}

.group:hover .group-hover\:opacity-95 {
  opacity: .95;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.focus-within\:opacity-0:focus-within {
  opacity: 0;
}

.focus-within\:opacity-5:focus-within {
  opacity: .05;
}

.focus-within\:opacity-10:focus-within {
  opacity: .1;
}

.focus-within\:opacity-20:focus-within {
  opacity: .2;
}

.focus-within\:opacity-25:focus-within {
  opacity: .25;
}

.focus-within\:opacity-30:focus-within {
  opacity: .3;
}

.focus-within\:opacity-40:focus-within {
  opacity: .4;
}

.focus-within\:opacity-50:focus-within {
  opacity: .5;
}

.focus-within\:opacity-60:focus-within {
  opacity: .6;
}

.focus-within\:opacity-70:focus-within {
  opacity: .7;
}

.focus-within\:opacity-75:focus-within {
  opacity: .75;
}

.focus-within\:opacity-80:focus-within {
  opacity: .8;
}

.focus-within\:opacity-90:focus-within {
  opacity: .9;
}

.focus-within\:opacity-95:focus-within {
  opacity: .95;
}

.focus-within\:opacity-100:focus-within {
  opacity: 1;
}

.hover\:opacity-0:hover {
  opacity: 0;
}

.hover\:opacity-5:hover {
  opacity: .05;
}

.hover\:opacity-10:hover {
  opacity: .1;
}

.hover\:opacity-20:hover {
  opacity: .2;
}

.hover\:opacity-25:hover {
  opacity: .25;
}

.hover\:opacity-30:hover {
  opacity: .3;
}

.hover\:opacity-40:hover {
  opacity: .4;
}

.hover\:opacity-50:hover {
  opacity: .5;
}

.hover\:opacity-60:hover {
  opacity: .6;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.hover\:opacity-95:hover {
  opacity: .95;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:opacity-0:focus {
  opacity: 0;
}

.focus\:opacity-5:focus {
  opacity: .05;
}

.focus\:opacity-10:focus {
  opacity: .1;
}

.focus\:opacity-20:focus {
  opacity: .2;
}

.focus\:opacity-25:focus {
  opacity: .25;
}

.focus\:opacity-30:focus {
  opacity: .3;
}

.focus\:opacity-40:focus {
  opacity: .4;
}

.focus\:opacity-50:focus {
  opacity: .5;
}

.focus\:opacity-60:focus {
  opacity: .6;
}

.focus\:opacity-70:focus {
  opacity: .7;
}

.focus\:opacity-75:focus {
  opacity: .75;
}

.focus\:opacity-80:focus {
  opacity: .8;
}

.focus\:opacity-90:focus {
  opacity: .9;
}

.focus\:opacity-95:focus {
  opacity: .95;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.bg-blend-normal {
  background-blend-mode: normal;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.bg-blend-screen {
  background-blend-mode: screen;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.bg-blend-lighten {
  background-blend-mode: lighten;
}

.bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.bg-blend-color-burn {
  background-blend-mode: color-burn;
}

.bg-blend-hard-light {
  background-blend-mode: hard-light;
}

.bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.bg-blend-difference {
  background-blend-mode: difference;
}

.bg-blend-exclusion {
  background-blend-mode: exclusion;
}

.bg-blend-hue {
  background-blend-mode: hue;
}

.bg-blend-saturation {
  background-blend-mode: saturation;
}

.bg-blend-color {
  background-blend-mode: color;
}

.bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.mix-blend-normal {
  mix-blend-mode: normal;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.mix-blend-screen {
  mix-blend-mode: screen;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.mix-blend-lighten {
  mix-blend-mode: lighten;
}

.mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.mix-blend-color-burn {
  mix-blend-mode: color-burn;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.mix-blend-soft-light {
  mix-blend-mode: soft-light;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

.mix-blend-exclusion {
  mix-blend-mode: exclusion;
}

.mix-blend-hue {
  mix-blend-mode: hue;
}

.mix-blend-saturation {
  mix-blend-mode: saturation;
}

.mix-blend-color {
  mix-blend-mode: color;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-sm:focus-within {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow:focus-within {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-md:focus-within {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-lg:focus-within {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-xl:focus-within {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-2xl:focus-within {
  --tw-shadow: 0 25px 50px -12px #00000040;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-inner:focus-within {
  --tw-shadow: inset 0 2px 4px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:shadow-none:focus-within {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px #00000040;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow:focus {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-2xl:focus {
  --tw-shadow: 0 25px 50px -12px #00000040;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-inner:focus {
  --tw-shadow: inset 0 2px 4px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-white {
  outline-offset: 2px;
  outline: 2px dotted #fff;
}

.outline-black {
  outline-offset: 2px;
  outline: 2px dotted #000;
}

.focus-within\:outline-none:focus-within {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-within\:outline-white:focus-within {
  outline-offset: 2px;
  outline: 2px dotted #fff;
}

.focus-within\:outline-black:focus-within {
  outline-offset: 2px;
  outline: 2px dotted #000;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-white:focus {
  outline-offset: 2px;
  outline: 2px dotted #fff;
}

.focus\:outline-black:focus {
  outline-offset: 2px;
  outline: 2px dotted #000;
}

*, :before, :after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-8:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-8:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset, .focus-within\:ring-inset:focus-within, .focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.ring-transparent {
  --tw-ring-color: transparent;
}

.ring-current {
  --tw-ring-color: currentColor;
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}

.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}

.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}

.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}

.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}

.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}

.ring-red {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
}

.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}

.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}

.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}

.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}

.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}

.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}

.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}

.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}

.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}

.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}

.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}

.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}

.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}

.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}

.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}

.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}

.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}

.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}

.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}

.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}

.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}

.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}

.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}

.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}

.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}

.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}

.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}

.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}

.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}

.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}

.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}

.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}

.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}

.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}

.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}

.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}

.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}

.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}

.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}

.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}

.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}

.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}

.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}

.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}

.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}

.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}

.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}

.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}

.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}

.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}

.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}

.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}

.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}

.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}

.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}

.focus-within\:ring-transparent:focus-within {
  --tw-ring-color: transparent;
}

.focus-within\:ring-current:focus-within {
  --tw-ring-color: currentColor;
}

.focus-within\:ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.focus-within\:ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}

.focus-within\:ring-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}

.focus-within\:ring-red:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}

.focus-within\:ring-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}

.focus-within\:ring-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}

.focus-within\:ring-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}

.focus-within\:ring-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}

.focus-within\:ring-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}

.focus-within\:ring-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.focus-within\:ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}

.focus-within\:ring-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}

.focus-within\:ring-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}

.focus-within\:ring-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}

.focus-within\:ring-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}

.focus-within\:ring-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}

.focus-within\:ring-pink-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}

.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}

.focus\:ring-current:focus {
  --tw-ring-color: currentColor;
}

.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}

.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.focus\:ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}

.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}

.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}

.focus\:ring-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}

.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}

.focus\:ring-red:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
}

.focus\:ring-yellow-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}

.focus\:ring-yellow-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}

.focus\:ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}

.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}

.focus\:ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}

.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}

.focus\:ring-yellow-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}

.focus\:ring-yellow-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}

.focus\:ring-yellow-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}

.focus\:ring-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}

.focus\:ring-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}

.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}

.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}

.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}

.focus\:ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}

.focus\:ring-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}

.focus\:ring-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}

.focus\:ring-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}

.focus\:ring-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}

.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}

.focus\:ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}

.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}

.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}

.focus\:ring-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}

.focus\:ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}

.focus\:ring-indigo-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}

.focus\:ring-indigo-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}

.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}

.focus\:ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}

.focus\:ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.focus\:ring-indigo-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}

.focus\:ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}

.focus\:ring-indigo-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}

.focus\:ring-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}

.focus\:ring-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}

.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}

.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}

.focus\:ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}

.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}

.focus\:ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}

.focus\:ring-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}

.focus\:ring-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}

.focus\:ring-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}

.focus\:ring-pink-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}

.focus\:ring-pink-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}

.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}

.focus\:ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}

.focus\:ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}

.focus\:ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}

.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}

.focus\:ring-pink-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}

.focus\:ring-pink-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}

.focus\:ring-pink-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}

.ring-opacity-0 {
  --tw-ring-opacity: 0;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-opacity-10 {
  --tw-ring-opacity: .1;
}

.ring-opacity-20 {
  --tw-ring-opacity: .2;
}

.ring-opacity-25 {
  --tw-ring-opacity: .25;
}

.ring-opacity-30 {
  --tw-ring-opacity: .3;
}

.ring-opacity-40 {
  --tw-ring-opacity: .4;
}

.ring-opacity-50 {
  --tw-ring-opacity: .5;
}

.ring-opacity-60 {
  --tw-ring-opacity: .6;
}

.ring-opacity-70 {
  --tw-ring-opacity: .7;
}

.ring-opacity-75 {
  --tw-ring-opacity: .75;
}

.ring-opacity-80 {
  --tw-ring-opacity: .8;
}

.ring-opacity-90 {
  --tw-ring-opacity: .9;
}

.ring-opacity-95 {
  --tw-ring-opacity: .95;
}

.ring-opacity-100 {
  --tw-ring-opacity: 1;
}

.focus-within\:ring-opacity-0:focus-within {
  --tw-ring-opacity: 0;
}

.focus-within\:ring-opacity-5:focus-within {
  --tw-ring-opacity: .05;
}

.focus-within\:ring-opacity-10:focus-within {
  --tw-ring-opacity: .1;
}

.focus-within\:ring-opacity-20:focus-within {
  --tw-ring-opacity: .2;
}

.focus-within\:ring-opacity-25:focus-within {
  --tw-ring-opacity: .25;
}

.focus-within\:ring-opacity-30:focus-within {
  --tw-ring-opacity: .3;
}

.focus-within\:ring-opacity-40:focus-within {
  --tw-ring-opacity: .4;
}

.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: .5;
}

.focus-within\:ring-opacity-60:focus-within {
  --tw-ring-opacity: .6;
}

.focus-within\:ring-opacity-70:focus-within {
  --tw-ring-opacity: .7;
}

.focus-within\:ring-opacity-75:focus-within {
  --tw-ring-opacity: .75;
}

.focus-within\:ring-opacity-80:focus-within {
  --tw-ring-opacity: .8;
}

.focus-within\:ring-opacity-90:focus-within {
  --tw-ring-opacity: .9;
}

.focus-within\:ring-opacity-95:focus-within {
  --tw-ring-opacity: .95;
}

.focus-within\:ring-opacity-100:focus-within {
  --tw-ring-opacity: 1;
}

.focus\:ring-opacity-0:focus {
  --tw-ring-opacity: 0;
}

.focus\:ring-opacity-5:focus {
  --tw-ring-opacity: .05;
}

.focus\:ring-opacity-10:focus {
  --tw-ring-opacity: .1;
}

.focus\:ring-opacity-20:focus {
  --tw-ring-opacity: .2;
}

.focus\:ring-opacity-25:focus {
  --tw-ring-opacity: .25;
}

.focus\:ring-opacity-30:focus {
  --tw-ring-opacity: .3;
}

.focus\:ring-opacity-40:focus {
  --tw-ring-opacity: .4;
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5;
}

.focus\:ring-opacity-60:focus {
  --tw-ring-opacity: .6;
}

.focus\:ring-opacity-70:focus {
  --tw-ring-opacity: .7;
}

.focus\:ring-opacity-75:focus {
  --tw-ring-opacity: .75;
}

.focus\:ring-opacity-80:focus {
  --tw-ring-opacity: .8;
}

.focus\:ring-opacity-90:focus {
  --tw-ring-opacity: .9;
}

.focus\:ring-opacity-95:focus {
  --tw-ring-opacity: .95;
}

.focus\:ring-opacity-100:focus {
  --tw-ring-opacity: 1;
}

.ring-offset-0 {
  --tw-ring-offset-width: 0px;
}

.ring-offset-1 {
  --tw-ring-offset-width: 1px;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-4 {
  --tw-ring-offset-width: 4px;
}

.ring-offset-8 {
  --tw-ring-offset-width: 8px;
}

.focus-within\:ring-offset-0:focus-within {
  --tw-ring-offset-width: 0px;
}

.focus-within\:ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px;
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}

.focus-within\:ring-offset-4:focus-within {
  --tw-ring-offset-width: 4px;
}

.focus-within\:ring-offset-8:focus-within {
  --tw-ring-offset-width: 8px;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-4:focus {
  --tw-ring-offset-width: 4px;
}

.focus\:ring-offset-8:focus {
  --tw-ring-offset-width: 8px;
}

.ring-offset-transparent {
  --tw-ring-offset-color: transparent;
}

.ring-offset-current {
  --tw-ring-offset-color: currentColor;
}

.ring-offset-black {
  --tw-ring-offset-color: #000;
}

.ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.ring-offset-gray-50 {
  --tw-ring-offset-color: #f9fafb;
}

.ring-offset-gray-100 {
  --tw-ring-offset-color: #f3f4f6;
}

.ring-offset-gray-200 {
  --tw-ring-offset-color: #e5e7eb;
}

.ring-offset-gray-300 {
  --tw-ring-offset-color: #d1d5db;
}

.ring-offset-gray-400 {
  --tw-ring-offset-color: #9ca3af;
}

.ring-offset-gray-500 {
  --tw-ring-offset-color: #6b7280;
}

.ring-offset-gray-600 {
  --tw-ring-offset-color: #4b5563;
}

.ring-offset-gray-700 {
  --tw-ring-offset-color: #374151;
}

.ring-offset-gray-800 {
  --tw-ring-offset-color: #1f2937;
}

.ring-offset-gray-900 {
  --tw-ring-offset-color: #111827;
}

.ring-offset-red {
  --tw-ring-offset-color: red;
}

.ring-offset-yellow-50 {
  --tw-ring-offset-color: #fffbeb;
}

.ring-offset-yellow-100 {
  --tw-ring-offset-color: #fef3c7;
}

.ring-offset-yellow-200 {
  --tw-ring-offset-color: #fde68a;
}

.ring-offset-yellow-300 {
  --tw-ring-offset-color: #fcd34d;
}

.ring-offset-yellow-400 {
  --tw-ring-offset-color: #fbbf24;
}

.ring-offset-yellow-500 {
  --tw-ring-offset-color: #f59e0b;
}

.ring-offset-yellow-600 {
  --tw-ring-offset-color: #d97706;
}

.ring-offset-yellow-700 {
  --tw-ring-offset-color: #b45309;
}

.ring-offset-yellow-800 {
  --tw-ring-offset-color: #92400e;
}

.ring-offset-yellow-900 {
  --tw-ring-offset-color: #78350f;
}

.ring-offset-green-50 {
  --tw-ring-offset-color: #ecfdf5;
}

.ring-offset-green-100 {
  --tw-ring-offset-color: #d1fae5;
}

.ring-offset-green-200 {
  --tw-ring-offset-color: #a7f3d0;
}

.ring-offset-green-300 {
  --tw-ring-offset-color: #6ee7b7;
}

.ring-offset-green-400 {
  --tw-ring-offset-color: #34d399;
}

.ring-offset-green-500 {
  --tw-ring-offset-color: #10b981;
}

.ring-offset-green-600 {
  --tw-ring-offset-color: #059669;
}

.ring-offset-green-700 {
  --tw-ring-offset-color: #047857;
}

.ring-offset-green-800 {
  --tw-ring-offset-color: #065f46;
}

.ring-offset-green-900 {
  --tw-ring-offset-color: #064e3b;
}

.ring-offset-blue-50 {
  --tw-ring-offset-color: #eff6ff;
}

.ring-offset-blue-100 {
  --tw-ring-offset-color: #dbeafe;
}

.ring-offset-blue-200 {
  --tw-ring-offset-color: #bfdbfe;
}

.ring-offset-blue-300 {
  --tw-ring-offset-color: #93c5fd;
}

.ring-offset-blue-400 {
  --tw-ring-offset-color: #60a5fa;
}

.ring-offset-blue-500 {
  --tw-ring-offset-color: #3b82f6;
}

.ring-offset-blue-600 {
  --tw-ring-offset-color: #2563eb;
}

.ring-offset-blue-700 {
  --tw-ring-offset-color: #1d4ed8;
}

.ring-offset-blue-800 {
  --tw-ring-offset-color: #1e40af;
}

.ring-offset-blue-900 {
  --tw-ring-offset-color: #1e3a8a;
}

.ring-offset-indigo-50 {
  --tw-ring-offset-color: #eef2ff;
}

.ring-offset-indigo-100 {
  --tw-ring-offset-color: #e0e7ff;
}

.ring-offset-indigo-200 {
  --tw-ring-offset-color: #c7d2fe;
}

.ring-offset-indigo-300 {
  --tw-ring-offset-color: #a5b4fc;
}

.ring-offset-indigo-400 {
  --tw-ring-offset-color: #818cf8;
}

.ring-offset-indigo-500 {
  --tw-ring-offset-color: #6366f1;
}

.ring-offset-indigo-600 {
  --tw-ring-offset-color: #4f46e5;
}

.ring-offset-indigo-700 {
  --tw-ring-offset-color: #4338ca;
}

.ring-offset-indigo-800 {
  --tw-ring-offset-color: #3730a3;
}

.ring-offset-indigo-900 {
  --tw-ring-offset-color: #312e81;
}

.ring-offset-purple-50 {
  --tw-ring-offset-color: #f5f3ff;
}

.ring-offset-purple-100 {
  --tw-ring-offset-color: #ede9fe;
}

.ring-offset-purple-200 {
  --tw-ring-offset-color: #ddd6fe;
}

.ring-offset-purple-300 {
  --tw-ring-offset-color: #c4b5fd;
}

.ring-offset-purple-400 {
  --tw-ring-offset-color: #a78bfa;
}

.ring-offset-purple-500 {
  --tw-ring-offset-color: #8b5cf6;
}

.ring-offset-purple-600 {
  --tw-ring-offset-color: #7c3aed;
}

.ring-offset-purple-700 {
  --tw-ring-offset-color: #6d28d9;
}

.ring-offset-purple-800 {
  --tw-ring-offset-color: #5b21b6;
}

.ring-offset-purple-900 {
  --tw-ring-offset-color: #4c1d95;
}

.ring-offset-pink-50 {
  --tw-ring-offset-color: #fdf2f8;
}

.ring-offset-pink-100 {
  --tw-ring-offset-color: #fce7f3;
}

.ring-offset-pink-200 {
  --tw-ring-offset-color: #fbcfe8;
}

.ring-offset-pink-300 {
  --tw-ring-offset-color: #f9a8d4;
}

.ring-offset-pink-400 {
  --tw-ring-offset-color: #f472b6;
}

.ring-offset-pink-500 {
  --tw-ring-offset-color: #ec4899;
}

.ring-offset-pink-600 {
  --tw-ring-offset-color: #db2777;
}

.ring-offset-pink-700 {
  --tw-ring-offset-color: #be185d;
}

.ring-offset-pink-800 {
  --tw-ring-offset-color: #9d174d;
}

.ring-offset-pink-900 {
  --tw-ring-offset-color: #831843;
}

.focus-within\:ring-offset-transparent:focus-within {
  --tw-ring-offset-color: transparent;
}

.focus-within\:ring-offset-current:focus-within {
  --tw-ring-offset-color: currentColor;
}

.focus-within\:ring-offset-black:focus-within {
  --tw-ring-offset-color: #000;
}

.focus-within\:ring-offset-white:focus-within {
  --tw-ring-offset-color: #fff;
}

.focus-within\:ring-offset-gray-50:focus-within {
  --tw-ring-offset-color: #f9fafb;
}

.focus-within\:ring-offset-gray-100:focus-within {
  --tw-ring-offset-color: #f3f4f6;
}

.focus-within\:ring-offset-gray-200:focus-within {
  --tw-ring-offset-color: #e5e7eb;
}

.focus-within\:ring-offset-gray-300:focus-within {
  --tw-ring-offset-color: #d1d5db;
}

.focus-within\:ring-offset-gray-400:focus-within {
  --tw-ring-offset-color: #9ca3af;
}

.focus-within\:ring-offset-gray-500:focus-within {
  --tw-ring-offset-color: #6b7280;
}

.focus-within\:ring-offset-gray-600:focus-within {
  --tw-ring-offset-color: #4b5563;
}

.focus-within\:ring-offset-gray-700:focus-within {
  --tw-ring-offset-color: #374151;
}

.focus-within\:ring-offset-gray-800:focus-within {
  --tw-ring-offset-color: #1f2937;
}

.focus-within\:ring-offset-gray-900:focus-within {
  --tw-ring-offset-color: #111827;
}

.focus-within\:ring-offset-red:focus-within {
  --tw-ring-offset-color: red;
}

.focus-within\:ring-offset-yellow-50:focus-within {
  --tw-ring-offset-color: #fffbeb;
}

.focus-within\:ring-offset-yellow-100:focus-within {
  --tw-ring-offset-color: #fef3c7;
}

.focus-within\:ring-offset-yellow-200:focus-within {
  --tw-ring-offset-color: #fde68a;
}

.focus-within\:ring-offset-yellow-300:focus-within {
  --tw-ring-offset-color: #fcd34d;
}

.focus-within\:ring-offset-yellow-400:focus-within {
  --tw-ring-offset-color: #fbbf24;
}

.focus-within\:ring-offset-yellow-500:focus-within {
  --tw-ring-offset-color: #f59e0b;
}

.focus-within\:ring-offset-yellow-600:focus-within {
  --tw-ring-offset-color: #d97706;
}

.focus-within\:ring-offset-yellow-700:focus-within {
  --tw-ring-offset-color: #b45309;
}

.focus-within\:ring-offset-yellow-800:focus-within {
  --tw-ring-offset-color: #92400e;
}

.focus-within\:ring-offset-yellow-900:focus-within {
  --tw-ring-offset-color: #78350f;
}

.focus-within\:ring-offset-green-50:focus-within {
  --tw-ring-offset-color: #ecfdf5;
}

.focus-within\:ring-offset-green-100:focus-within {
  --tw-ring-offset-color: #d1fae5;
}

.focus-within\:ring-offset-green-200:focus-within {
  --tw-ring-offset-color: #a7f3d0;
}

.focus-within\:ring-offset-green-300:focus-within {
  --tw-ring-offset-color: #6ee7b7;
}

.focus-within\:ring-offset-green-400:focus-within {
  --tw-ring-offset-color: #34d399;
}

.focus-within\:ring-offset-green-500:focus-within {
  --tw-ring-offset-color: #10b981;
}

.focus-within\:ring-offset-green-600:focus-within {
  --tw-ring-offset-color: #059669;
}

.focus-within\:ring-offset-green-700:focus-within {
  --tw-ring-offset-color: #047857;
}

.focus-within\:ring-offset-green-800:focus-within {
  --tw-ring-offset-color: #065f46;
}

.focus-within\:ring-offset-green-900:focus-within {
  --tw-ring-offset-color: #064e3b;
}

.focus-within\:ring-offset-blue-50:focus-within {
  --tw-ring-offset-color: #eff6ff;
}

.focus-within\:ring-offset-blue-100:focus-within {
  --tw-ring-offset-color: #dbeafe;
}

.focus-within\:ring-offset-blue-200:focus-within {
  --tw-ring-offset-color: #bfdbfe;
}

.focus-within\:ring-offset-blue-300:focus-within {
  --tw-ring-offset-color: #93c5fd;
}

.focus-within\:ring-offset-blue-400:focus-within {
  --tw-ring-offset-color: #60a5fa;
}

.focus-within\:ring-offset-blue-500:focus-within {
  --tw-ring-offset-color: #3b82f6;
}

.focus-within\:ring-offset-blue-600:focus-within {
  --tw-ring-offset-color: #2563eb;
}

.focus-within\:ring-offset-blue-700:focus-within {
  --tw-ring-offset-color: #1d4ed8;
}

.focus-within\:ring-offset-blue-800:focus-within {
  --tw-ring-offset-color: #1e40af;
}

.focus-within\:ring-offset-blue-900:focus-within {
  --tw-ring-offset-color: #1e3a8a;
}

.focus-within\:ring-offset-indigo-50:focus-within {
  --tw-ring-offset-color: #eef2ff;
}

.focus-within\:ring-offset-indigo-100:focus-within {
  --tw-ring-offset-color: #e0e7ff;
}

.focus-within\:ring-offset-indigo-200:focus-within {
  --tw-ring-offset-color: #c7d2fe;
}

.focus-within\:ring-offset-indigo-300:focus-within {
  --tw-ring-offset-color: #a5b4fc;
}

.focus-within\:ring-offset-indigo-400:focus-within {
  --tw-ring-offset-color: #818cf8;
}

.focus-within\:ring-offset-indigo-500:focus-within {
  --tw-ring-offset-color: #6366f1;
}

.focus-within\:ring-offset-indigo-600:focus-within {
  --tw-ring-offset-color: #4f46e5;
}

.focus-within\:ring-offset-indigo-700:focus-within {
  --tw-ring-offset-color: #4338ca;
}

.focus-within\:ring-offset-indigo-800:focus-within {
  --tw-ring-offset-color: #3730a3;
}

.focus-within\:ring-offset-indigo-900:focus-within {
  --tw-ring-offset-color: #312e81;
}

.focus-within\:ring-offset-purple-50:focus-within {
  --tw-ring-offset-color: #f5f3ff;
}

.focus-within\:ring-offset-purple-100:focus-within {
  --tw-ring-offset-color: #ede9fe;
}

.focus-within\:ring-offset-purple-200:focus-within {
  --tw-ring-offset-color: #ddd6fe;
}

.focus-within\:ring-offset-purple-300:focus-within {
  --tw-ring-offset-color: #c4b5fd;
}

.focus-within\:ring-offset-purple-400:focus-within {
  --tw-ring-offset-color: #a78bfa;
}

.focus-within\:ring-offset-purple-500:focus-within {
  --tw-ring-offset-color: #8b5cf6;
}

.focus-within\:ring-offset-purple-600:focus-within {
  --tw-ring-offset-color: #7c3aed;
}

.focus-within\:ring-offset-purple-700:focus-within {
  --tw-ring-offset-color: #6d28d9;
}

.focus-within\:ring-offset-purple-800:focus-within {
  --tw-ring-offset-color: #5b21b6;
}

.focus-within\:ring-offset-purple-900:focus-within {
  --tw-ring-offset-color: #4c1d95;
}

.focus-within\:ring-offset-pink-50:focus-within {
  --tw-ring-offset-color: #fdf2f8;
}

.focus-within\:ring-offset-pink-100:focus-within {
  --tw-ring-offset-color: #fce7f3;
}

.focus-within\:ring-offset-pink-200:focus-within {
  --tw-ring-offset-color: #fbcfe8;
}

.focus-within\:ring-offset-pink-300:focus-within {
  --tw-ring-offset-color: #f9a8d4;
}

.focus-within\:ring-offset-pink-400:focus-within {
  --tw-ring-offset-color: #f472b6;
}

.focus-within\:ring-offset-pink-500:focus-within {
  --tw-ring-offset-color: #ec4899;
}

.focus-within\:ring-offset-pink-600:focus-within {
  --tw-ring-offset-color: #db2777;
}

.focus-within\:ring-offset-pink-700:focus-within {
  --tw-ring-offset-color: #be185d;
}

.focus-within\:ring-offset-pink-800:focus-within {
  --tw-ring-offset-color: #9d174d;
}

.focus-within\:ring-offset-pink-900:focus-within {
  --tw-ring-offset-color: #831843;
}

.focus\:ring-offset-transparent:focus {
  --tw-ring-offset-color: transparent;
}

.focus\:ring-offset-current:focus {
  --tw-ring-offset-color: currentColor;
}

.focus\:ring-offset-black:focus {
  --tw-ring-offset-color: #000;
}

.focus\:ring-offset-white:focus {
  --tw-ring-offset-color: #fff;
}

.focus\:ring-offset-gray-50:focus {
  --tw-ring-offset-color: #f9fafb;
}

.focus\:ring-offset-gray-100:focus {
  --tw-ring-offset-color: #f3f4f6;
}

.focus\:ring-offset-gray-200:focus {
  --tw-ring-offset-color: #e5e7eb;
}

.focus\:ring-offset-gray-300:focus {
  --tw-ring-offset-color: #d1d5db;
}

.focus\:ring-offset-gray-400:focus {
  --tw-ring-offset-color: #9ca3af;
}

.focus\:ring-offset-gray-500:focus {
  --tw-ring-offset-color: #6b7280;
}

.focus\:ring-offset-gray-600:focus {
  --tw-ring-offset-color: #4b5563;
}

.focus\:ring-offset-gray-700:focus {
  --tw-ring-offset-color: #374151;
}

.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-color: #1f2937;
}

.focus\:ring-offset-gray-900:focus {
  --tw-ring-offset-color: #111827;
}

.focus\:ring-offset-red:focus {
  --tw-ring-offset-color: red;
}

.focus\:ring-offset-yellow-50:focus {
  --tw-ring-offset-color: #fffbeb;
}

.focus\:ring-offset-yellow-100:focus {
  --tw-ring-offset-color: #fef3c7;
}

.focus\:ring-offset-yellow-200:focus {
  --tw-ring-offset-color: #fde68a;
}

.focus\:ring-offset-yellow-300:focus {
  --tw-ring-offset-color: #fcd34d;
}

.focus\:ring-offset-yellow-400:focus {
  --tw-ring-offset-color: #fbbf24;
}

.focus\:ring-offset-yellow-500:focus {
  --tw-ring-offset-color: #f59e0b;
}

.focus\:ring-offset-yellow-600:focus {
  --tw-ring-offset-color: #d97706;
}

.focus\:ring-offset-yellow-700:focus {
  --tw-ring-offset-color: #b45309;
}

.focus\:ring-offset-yellow-800:focus {
  --tw-ring-offset-color: #92400e;
}

.focus\:ring-offset-yellow-900:focus {
  --tw-ring-offset-color: #78350f;
}

.focus\:ring-offset-green-50:focus {
  --tw-ring-offset-color: #ecfdf5;
}

.focus\:ring-offset-green-100:focus {
  --tw-ring-offset-color: #d1fae5;
}

.focus\:ring-offset-green-200:focus {
  --tw-ring-offset-color: #a7f3d0;
}

.focus\:ring-offset-green-300:focus {
  --tw-ring-offset-color: #6ee7b7;
}

.focus\:ring-offset-green-400:focus {
  --tw-ring-offset-color: #34d399;
}

.focus\:ring-offset-green-500:focus {
  --tw-ring-offset-color: #10b981;
}

.focus\:ring-offset-green-600:focus {
  --tw-ring-offset-color: #059669;
}

.focus\:ring-offset-green-700:focus {
  --tw-ring-offset-color: #047857;
}

.focus\:ring-offset-green-800:focus {
  --tw-ring-offset-color: #065f46;
}

.focus\:ring-offset-green-900:focus {
  --tw-ring-offset-color: #064e3b;
}

.focus\:ring-offset-blue-50:focus {
  --tw-ring-offset-color: #eff6ff;
}

.focus\:ring-offset-blue-100:focus {
  --tw-ring-offset-color: #dbeafe;
}

.focus\:ring-offset-blue-200:focus {
  --tw-ring-offset-color: #bfdbfe;
}

.focus\:ring-offset-blue-300:focus {
  --tw-ring-offset-color: #93c5fd;
}

.focus\:ring-offset-blue-400:focus {
  --tw-ring-offset-color: #60a5fa;
}

.focus\:ring-offset-blue-500:focus {
  --tw-ring-offset-color: #3b82f6;
}

.focus\:ring-offset-blue-600:focus {
  --tw-ring-offset-color: #2563eb;
}

.focus\:ring-offset-blue-700:focus {
  --tw-ring-offset-color: #1d4ed8;
}

.focus\:ring-offset-blue-800:focus {
  --tw-ring-offset-color: #1e40af;
}

.focus\:ring-offset-blue-900:focus {
  --tw-ring-offset-color: #1e3a8a;
}

.focus\:ring-offset-indigo-50:focus {
  --tw-ring-offset-color: #eef2ff;
}

.focus\:ring-offset-indigo-100:focus {
  --tw-ring-offset-color: #e0e7ff;
}

.focus\:ring-offset-indigo-200:focus {
  --tw-ring-offset-color: #c7d2fe;
}

.focus\:ring-offset-indigo-300:focus {
  --tw-ring-offset-color: #a5b4fc;
}

.focus\:ring-offset-indigo-400:focus {
  --tw-ring-offset-color: #818cf8;
}

.focus\:ring-offset-indigo-500:focus {
  --tw-ring-offset-color: #6366f1;
}

.focus\:ring-offset-indigo-600:focus {
  --tw-ring-offset-color: #4f46e5;
}

.focus\:ring-offset-indigo-700:focus {
  --tw-ring-offset-color: #4338ca;
}

.focus\:ring-offset-indigo-800:focus {
  --tw-ring-offset-color: #3730a3;
}

.focus\:ring-offset-indigo-900:focus {
  --tw-ring-offset-color: #312e81;
}

.focus\:ring-offset-purple-50:focus {
  --tw-ring-offset-color: #f5f3ff;
}

.focus\:ring-offset-purple-100:focus {
  --tw-ring-offset-color: #ede9fe;
}

.focus\:ring-offset-purple-200:focus {
  --tw-ring-offset-color: #ddd6fe;
}

.focus\:ring-offset-purple-300:focus {
  --tw-ring-offset-color: #c4b5fd;
}

.focus\:ring-offset-purple-400:focus {
  --tw-ring-offset-color: #a78bfa;
}

.focus\:ring-offset-purple-500:focus {
  --tw-ring-offset-color: #8b5cf6;
}

.focus\:ring-offset-purple-600:focus {
  --tw-ring-offset-color: #7c3aed;
}

.focus\:ring-offset-purple-700:focus {
  --tw-ring-offset-color: #6d28d9;
}

.focus\:ring-offset-purple-800:focus {
  --tw-ring-offset-color: #5b21b6;
}

.focus\:ring-offset-purple-900:focus {
  --tw-ring-offset-color: #4c1d95;
}

.focus\:ring-offset-pink-50:focus {
  --tw-ring-offset-color: #fdf2f8;
}

.focus\:ring-offset-pink-100:focus {
  --tw-ring-offset-color: #fce7f3;
}

.focus\:ring-offset-pink-200:focus {
  --tw-ring-offset-color: #fbcfe8;
}

.focus\:ring-offset-pink-300:focus {
  --tw-ring-offset-color: #f9a8d4;
}

.focus\:ring-offset-pink-400:focus {
  --tw-ring-offset-color: #f472b6;
}

.focus\:ring-offset-pink-500:focus {
  --tw-ring-offset-color: #ec4899;
}

.focus\:ring-offset-pink-600:focus {
  --tw-ring-offset-color: #db2777;
}

.focus\:ring-offset-pink-700:focus {
  --tw-ring-offset-color: #be185d;
}

.focus\:ring-offset-pink-800:focus {
  --tw-ring-offset-color: #9d174d;
}

.focus\:ring-offset-pink-900:focus {
  --tw-ring-offset-color: #831843;
}

.filter {
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter-none {
  filter: none;
}

.blur-0, .blur-none {
  --tw-blur: blur(0);
}

.blur-sm {
  --tw-blur: blur(4px);
}

.blur {
  --tw-blur: blur(8px);
}

.blur-md {
  --tw-blur: blur(12px);
}

.blur-lg {
  --tw-blur: blur(16px);
}

.blur-xl {
  --tw-blur: blur(24px);
}

.blur-2xl {
  --tw-blur: blur(40px);
}

.blur-3xl {
  --tw-blur: blur(64px);
}

.brightness-0 {
  --tw-brightness: brightness(0);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
}

.brightness-90 {
  --tw-brightness: brightness(.9);
}

.brightness-95 {
  --tw-brightness: brightness(.95);
}

.brightness-100 {
  --tw-brightness: brightness(1);
}

.brightness-105 {
  --tw-brightness: brightness(1.05);
}

.brightness-110 {
  --tw-brightness: brightness(1.1);
}

.brightness-125 {
  --tw-brightness: brightness(1.25);
}

.brightness-150 {
  --tw-brightness: brightness(1.5);
}

.brightness-200 {
  --tw-brightness: brightness(2);
}

.contrast-0 {
  --tw-contrast: contrast(0);
}

.contrast-50 {
  --tw-contrast: contrast(.5);
}

.contrast-75 {
  --tw-contrast: contrast(.75);
}

.contrast-100 {
  --tw-contrast: contrast(1);
}

.contrast-125 {
  --tw-contrast: contrast(1.25);
}

.contrast-150 {
  --tw-contrast: contrast(1.5);
}

.contrast-200 {
  --tw-contrast: contrast(2);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
}

.drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
}

.grayscale-0 {
  --tw-grayscale: grayscale(0);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
}

.hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(0deg);
}

.hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(15deg);
}

.hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(30deg);
}

.hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(60deg);
}

.hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(90deg);
}

.hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(180deg);
}

.-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg);
}

.-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg);
}

.-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg);
}

.-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg);
}

.-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg);
}

.invert-0 {
  --tw-invert: invert(0);
}

.invert {
  --tw-invert: invert(100%);
}

.saturate-0 {
  --tw-saturate: saturate(0);
}

.saturate-50 {
  --tw-saturate: saturate(.5);
}

.saturate-100 {
  --tw-saturate: saturate(1);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
}

.saturate-200 {
  --tw-saturate: saturate(2);
}

.sepia-0 {
  --tw-sepia: sepia(0);
}

.sepia {
  --tw-sepia: sepia(100%);
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty, );
  --tw-backdrop-brightness: var(--tw-empty, );
  --tw-backdrop-contrast: var(--tw-empty, );
  --tw-backdrop-grayscale: var(--tw-empty, );
  --tw-backdrop-hue-rotate: var(--tw-empty, );
  --tw-backdrop-invert: var(--tw-empty, );
  --tw-backdrop-opacity: var(--tw-empty, );
  --tw-backdrop-saturate: var(--tw-empty, );
  --tw-backdrop-sepia: var(--tw-empty, );
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter-none {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.backdrop-blur-0, .backdrop-blur-none {
  --tw-backdrop-blur: blur(0);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
}

.backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px);
}

.backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0);
}

.backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5);
}

.backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(.75);
}

.backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(.9);
}

.backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(.95);
}

.backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1);
}

.backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05);
}

.backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1);
}

.backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25);
}

.backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5);
}

.backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2);
}

.backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0);
}

.backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(.5);
}

.backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(.75);
}

.backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1);
}

.backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25);
}

.backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5);
}

.backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2);
}

.backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0);
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
}

.backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
}

.backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
}

.backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
}

.backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
}

.backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
}

.backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
}

.-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
}

.-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
}

.-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
}

.-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
}

.-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
}

.backdrop-invert-0 {
  --tw-backdrop-invert: invert(0);
}

.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
}

.backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0);
}

.backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(.05);
}

.backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(.1);
}

.backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(.2);
}

.backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(.25);
}

.backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(.3);
}

.backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(.4);
}

.backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(.5);
}

.backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(.6);
}

.backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(.7);
}

.backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(.75);
}

.backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(.8);
}

.backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(.9);
}

.backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(.95);
}

.backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1);
}

.backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0);
}

.backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(.5);
}

.backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
}

.backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
}

.backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0);
}

.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
}

.transition-none {
  transition-property: none;
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-100 {
  transition-delay: .1s;
}

.delay-150 {
  transition-delay: .15s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-300 {
  transition-delay: .3s;
}

.delay-500 {
  transition-delay: .5s;
}

.delay-700 {
  transition-delay: .7s;
}

.delay-1000 {
  transition-delay: 1s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-1000 {
  transition-duration: 1s;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .sm\:container {
      max-width: 1560px;
    }
  }

  .sm\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:pointer-events-none {
    pointer-events: none;
  }

  .sm\:pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:visible {
    visibility: visible;
  }

  .sm\:invisible {
    visibility: hidden;
  }

  .sm\:static {
    position: static;
  }

  .sm\:fixed {
    position: fixed;
  }

  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:sticky {
    position: sticky;
  }

  .sm\:inset-0 {
    inset: 0;
  }

  .sm\:inset-1 {
    inset: .25rem;
  }

  .sm\:inset-2 {
    inset: .5rem;
  }

  .sm\:inset-3 {
    inset: .75rem;
  }

  .sm\:inset-4 {
    inset: 1rem;
  }

  .sm\:inset-5 {
    inset: 1.25rem;
  }

  .sm\:inset-6 {
    inset: 1.5rem;
  }

  .sm\:inset-7 {
    inset: 1.75rem;
  }

  .sm\:inset-8 {
    inset: 2rem;
  }

  .sm\:inset-9 {
    inset: 2.25rem;
  }

  .sm\:inset-10 {
    inset: 2.5rem;
  }

  .sm\:inset-11 {
    inset: 2.75rem;
  }

  .sm\:inset-12 {
    inset: 3rem;
  }

  .sm\:inset-14 {
    inset: 3.5rem;
  }

  .sm\:inset-16 {
    inset: 4rem;
  }

  .sm\:inset-20 {
    inset: 5rem;
  }

  .sm\:inset-24 {
    inset: 6rem;
  }

  .sm\:inset-28 {
    inset: 7rem;
  }

  .sm\:inset-32 {
    inset: 8rem;
  }

  .sm\:inset-36 {
    inset: 9rem;
  }

  .sm\:inset-40 {
    inset: 10rem;
  }

  .sm\:inset-44 {
    inset: 11rem;
  }

  .sm\:inset-48 {
    inset: 12rem;
  }

  .sm\:inset-52 {
    inset: 13rem;
  }

  .sm\:inset-56 {
    inset: 14rem;
  }

  .sm\:inset-60 {
    inset: 15rem;
  }

  .sm\:inset-64 {
    inset: 16rem;
  }

  .sm\:inset-72 {
    inset: 18rem;
  }

  .sm\:inset-80 {
    inset: 20rem;
  }

  .sm\:inset-96 {
    inset: 24rem;
  }

  .sm\:inset-auto {
    inset: auto;
  }

  .sm\:inset-px {
    inset: 1px;
  }

  .sm\:inset-0\.5 {
    inset: .125rem;
  }

  .sm\:inset-1\.5 {
    inset: .375rem;
  }

  .sm\:inset-2\.5 {
    inset: .625rem;
  }

  .sm\:inset-3\.5 {
    inset: .875rem;
  }

  .sm\:-inset-0 {
    inset: 0;
  }

  .sm\:-inset-1 {
    inset: -.25rem;
  }

  .sm\:-inset-2 {
    inset: -.5rem;
  }

  .sm\:-inset-3 {
    inset: -.75rem;
  }

  .sm\:-inset-4 {
    inset: -1rem;
  }

  .sm\:-inset-5 {
    inset: -1.25rem;
  }

  .sm\:-inset-6 {
    inset: -1.5rem;
  }

  .sm\:-inset-7 {
    inset: -1.75rem;
  }

  .sm\:-inset-8 {
    inset: -2rem;
  }

  .sm\:-inset-9 {
    inset: -2.25rem;
  }

  .sm\:-inset-10 {
    inset: -2.5rem;
  }

  .sm\:-inset-11 {
    inset: -2.75rem;
  }

  .sm\:-inset-12 {
    inset: -3rem;
  }

  .sm\:-inset-14 {
    inset: -3.5rem;
  }

  .sm\:-inset-16 {
    inset: -4rem;
  }

  .sm\:-inset-20 {
    inset: -5rem;
  }

  .sm\:-inset-24 {
    inset: -6rem;
  }

  .sm\:-inset-28 {
    inset: -7rem;
  }

  .sm\:-inset-32 {
    inset: -8rem;
  }

  .sm\:-inset-36 {
    inset: -9rem;
  }

  .sm\:-inset-40 {
    inset: -10rem;
  }

  .sm\:-inset-44 {
    inset: -11rem;
  }

  .sm\:-inset-48 {
    inset: -12rem;
  }

  .sm\:-inset-52 {
    inset: -13rem;
  }

  .sm\:-inset-56 {
    inset: -14rem;
  }

  .sm\:-inset-60 {
    inset: -15rem;
  }

  .sm\:-inset-64 {
    inset: -16rem;
  }

  .sm\:-inset-72 {
    inset: -18rem;
  }

  .sm\:-inset-80 {
    inset: -20rem;
  }

  .sm\:-inset-96 {
    inset: -24rem;
  }

  .sm\:-inset-px {
    inset: -1px;
  }

  .sm\:-inset-0\.5 {
    inset: -.125rem;
  }

  .sm\:-inset-1\.5 {
    inset: -.375rem;
  }

  .sm\:-inset-2\.5 {
    inset: -.625rem;
  }

  .sm\:-inset-3\.5 {
    inset: -.875rem;
  }

  .sm\:inset-1\/2 {
    inset: 50%;
  }

  .sm\:inset-1\/3 {
    inset: 33.3333%;
  }

  .sm\:inset-2\/3 {
    inset: 66.6667%;
  }

  .sm\:inset-1\/4 {
    inset: 25%;
  }

  .sm\:inset-2\/4 {
    inset: 50%;
  }

  .sm\:inset-3\/4 {
    inset: 75%;
  }

  .sm\:inset-full {
    inset: 100%;
  }

  .sm\:-inset-1\/2 {
    inset: -50%;
  }

  .sm\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .sm\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .sm\:-inset-1\/4 {
    inset: -25%;
  }

  .sm\:-inset-2\/4 {
    inset: -50%;
  }

  .sm\:-inset-3\/4 {
    inset: -75%;
  }

  .sm\:-inset-full {
    inset: -100%;
  }

  .sm\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .sm\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .sm\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .sm\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .sm\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .sm\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .sm\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .sm\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .sm\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .sm\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .sm\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .sm\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .sm\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .sm\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .sm\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .sm\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .sm\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .sm\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .sm\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .sm\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .sm\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .sm\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .sm\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .sm\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .sm\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .sm\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .sm\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .sm\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .sm\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .sm\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .sm\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .sm\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .sm\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .sm\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .sm\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .sm\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .sm\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .sm\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .sm\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .sm\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .sm\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .sm\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .sm\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .sm\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .sm\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .sm\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .sm\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .sm\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .sm\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .sm\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .sm\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .sm\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .sm\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .sm\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .sm\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .sm\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .sm\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .sm\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .sm\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .sm\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .sm\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .sm\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .sm\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .sm\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .sm\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .sm\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .sm\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .sm\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .sm\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .sm\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .sm\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .sm\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .sm\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .sm\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .sm\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .sm\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .sm\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .sm\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .sm\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .sm\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .sm\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .sm\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .sm\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .sm\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .sm\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .sm\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .sm\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .sm\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .sm\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .sm\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .sm\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .sm\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .sm\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .sm\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .sm\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .sm\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .sm\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .sm\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .sm\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .sm\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .sm\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .sm\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .sm\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .sm\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .sm\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .sm\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .sm\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .sm\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .sm\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .sm\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .sm\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .sm\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .sm\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .sm\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .sm\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .sm\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .sm\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .sm\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .sm\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .sm\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .sm\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .sm\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .sm\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .sm\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .sm\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .sm\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .sm\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .sm\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .sm\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .sm\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .sm\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .sm\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .sm\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .sm\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .sm\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .sm\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .sm\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .sm\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .sm\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .sm\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .sm\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .sm\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .sm\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .sm\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .sm\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .sm\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .sm\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .sm\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .sm\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .sm\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .sm\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .sm\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .sm\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .sm\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .sm\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .sm\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .sm\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .sm\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .sm\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:top-1 {
    top: .25rem;
  }

  .sm\:top-2 {
    top: .5rem;
  }

  .sm\:top-3 {
    top: .75rem;
  }

  .sm\:top-4 {
    top: 1rem;
  }

  .sm\:top-5 {
    top: 1.25rem;
  }

  .sm\:top-6 {
    top: 1.5rem;
  }

  .sm\:top-7 {
    top: 1.75rem;
  }

  .sm\:top-8 {
    top: 2rem;
  }

  .sm\:top-9 {
    top: 2.25rem;
  }

  .sm\:top-10 {
    top: 2.5rem;
  }

  .sm\:top-11 {
    top: 2.75rem;
  }

  .sm\:top-12 {
    top: 3rem;
  }

  .sm\:top-14 {
    top: 3.5rem;
  }

  .sm\:top-16 {
    top: 4rem;
  }

  .sm\:top-20 {
    top: 5rem;
  }

  .sm\:top-24 {
    top: 6rem;
  }

  .sm\:top-28 {
    top: 7rem;
  }

  .sm\:top-32 {
    top: 8rem;
  }

  .sm\:top-36 {
    top: 9rem;
  }

  .sm\:top-40 {
    top: 10rem;
  }

  .sm\:top-44 {
    top: 11rem;
  }

  .sm\:top-48 {
    top: 12rem;
  }

  .sm\:top-52 {
    top: 13rem;
  }

  .sm\:top-56 {
    top: 14rem;
  }

  .sm\:top-60 {
    top: 15rem;
  }

  .sm\:top-64 {
    top: 16rem;
  }

  .sm\:top-72 {
    top: 18rem;
  }

  .sm\:top-80 {
    top: 20rem;
  }

  .sm\:top-96 {
    top: 24rem;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:top-px {
    top: 1px;
  }

  .sm\:top-0\.5 {
    top: .125rem;
  }

  .sm\:top-1\.5 {
    top: .375rem;
  }

  .sm\:top-2\.5 {
    top: .625rem;
  }

  .sm\:top-3\.5 {
    top: .875rem;
  }

  .sm\:-top-0 {
    top: 0;
  }

  .sm\:-top-1 {
    top: -.25rem;
  }

  .sm\:-top-2 {
    top: -.5rem;
  }

  .sm\:-top-3 {
    top: -.75rem;
  }

  .sm\:-top-4 {
    top: -1rem;
  }

  .sm\:-top-5 {
    top: -1.25rem;
  }

  .sm\:-top-6 {
    top: -1.5rem;
  }

  .sm\:-top-7 {
    top: -1.75rem;
  }

  .sm\:-top-8 {
    top: -2rem;
  }

  .sm\:-top-9 {
    top: -2.25rem;
  }

  .sm\:-top-10 {
    top: -2.5rem;
  }

  .sm\:-top-11 {
    top: -2.75rem;
  }

  .sm\:-top-12 {
    top: -3rem;
  }

  .sm\:-top-14 {
    top: -3.5rem;
  }

  .sm\:-top-16 {
    top: -4rem;
  }

  .sm\:-top-20 {
    top: -5rem;
  }

  .sm\:-top-24 {
    top: -6rem;
  }

  .sm\:-top-28 {
    top: -7rem;
  }

  .sm\:-top-32 {
    top: -8rem;
  }

  .sm\:-top-36 {
    top: -9rem;
  }

  .sm\:-top-40 {
    top: -10rem;
  }

  .sm\:-top-44 {
    top: -11rem;
  }

  .sm\:-top-48 {
    top: -12rem;
  }

  .sm\:-top-52 {
    top: -13rem;
  }

  .sm\:-top-56 {
    top: -14rem;
  }

  .sm\:-top-60 {
    top: -15rem;
  }

  .sm\:-top-64 {
    top: -16rem;
  }

  .sm\:-top-72 {
    top: -18rem;
  }

  .sm\:-top-80 {
    top: -20rem;
  }

  .sm\:-top-96 {
    top: -24rem;
  }

  .sm\:-top-px {
    top: -1px;
  }

  .sm\:-top-0\.5 {
    top: -.125rem;
  }

  .sm\:-top-1\.5 {
    top: -.375rem;
  }

  .sm\:-top-2\.5 {
    top: -.625rem;
  }

  .sm\:-top-3\.5 {
    top: -.875rem;
  }

  .sm\:top-1\/2 {
    top: 50%;
  }

  .sm\:top-1\/3 {
    top: 33.3333%;
  }

  .sm\:top-2\/3 {
    top: 66.6667%;
  }

  .sm\:top-1\/4 {
    top: 25%;
  }

  .sm\:top-2\/4 {
    top: 50%;
  }

  .sm\:top-3\/4 {
    top: 75%;
  }

  .sm\:top-full {
    top: 100%;
  }

  .sm\:-top-1\/2 {
    top: -50%;
  }

  .sm\:-top-1\/3 {
    top: -33.3333%;
  }

  .sm\:-top-2\/3 {
    top: -66.6667%;
  }

  .sm\:-top-1\/4 {
    top: -25%;
  }

  .sm\:-top-2\/4 {
    top: -50%;
  }

  .sm\:-top-3\/4 {
    top: -75%;
  }

  .sm\:-top-full {
    top: -100%;
  }

  .sm\:right-0 {
    right: 0;
  }

  .sm\:right-1 {
    right: .25rem;
  }

  .sm\:right-2 {
    right: .5rem;
  }

  .sm\:right-3 {
    right: .75rem;
  }

  .sm\:right-4 {
    right: 1rem;
  }

  .sm\:right-5 {
    right: 1.25rem;
  }

  .sm\:right-6 {
    right: 1.5rem;
  }

  .sm\:right-7 {
    right: 1.75rem;
  }

  .sm\:right-8 {
    right: 2rem;
  }

  .sm\:right-9 {
    right: 2.25rem;
  }

  .sm\:right-10 {
    right: 2.5rem;
  }

  .sm\:right-11 {
    right: 2.75rem;
  }

  .sm\:right-12 {
    right: 3rem;
  }

  .sm\:right-14 {
    right: 3.5rem;
  }

  .sm\:right-16 {
    right: 4rem;
  }

  .sm\:right-20 {
    right: 5rem;
  }

  .sm\:right-24 {
    right: 6rem;
  }

  .sm\:right-28 {
    right: 7rem;
  }

  .sm\:right-32 {
    right: 8rem;
  }

  .sm\:right-36 {
    right: 9rem;
  }

  .sm\:right-40 {
    right: 10rem;
  }

  .sm\:right-44 {
    right: 11rem;
  }

  .sm\:right-48 {
    right: 12rem;
  }

  .sm\:right-52 {
    right: 13rem;
  }

  .sm\:right-56 {
    right: 14rem;
  }

  .sm\:right-60 {
    right: 15rem;
  }

  .sm\:right-64 {
    right: 16rem;
  }

  .sm\:right-72 {
    right: 18rem;
  }

  .sm\:right-80 {
    right: 20rem;
  }

  .sm\:right-96 {
    right: 24rem;
  }

  .sm\:right-auto {
    right: auto;
  }

  .sm\:right-px {
    right: 1px;
  }

  .sm\:right-0\.5 {
    right: .125rem;
  }

  .sm\:right-1\.5 {
    right: .375rem;
  }

  .sm\:right-2\.5 {
    right: .625rem;
  }

  .sm\:right-3\.5 {
    right: .875rem;
  }

  .sm\:-right-0 {
    right: 0;
  }

  .sm\:-right-1 {
    right: -.25rem;
  }

  .sm\:-right-2 {
    right: -.5rem;
  }

  .sm\:-right-3 {
    right: -.75rem;
  }

  .sm\:-right-4 {
    right: -1rem;
  }

  .sm\:-right-5 {
    right: -1.25rem;
  }

  .sm\:-right-6 {
    right: -1.5rem;
  }

  .sm\:-right-7 {
    right: -1.75rem;
  }

  .sm\:-right-8 {
    right: -2rem;
  }

  .sm\:-right-9 {
    right: -2.25rem;
  }

  .sm\:-right-10 {
    right: -2.5rem;
  }

  .sm\:-right-11 {
    right: -2.75rem;
  }

  .sm\:-right-12 {
    right: -3rem;
  }

  .sm\:-right-14 {
    right: -3.5rem;
  }

  .sm\:-right-16 {
    right: -4rem;
  }

  .sm\:-right-20 {
    right: -5rem;
  }

  .sm\:-right-24 {
    right: -6rem;
  }

  .sm\:-right-28 {
    right: -7rem;
  }

  .sm\:-right-32 {
    right: -8rem;
  }

  .sm\:-right-36 {
    right: -9rem;
  }

  .sm\:-right-40 {
    right: -10rem;
  }

  .sm\:-right-44 {
    right: -11rem;
  }

  .sm\:-right-48 {
    right: -12rem;
  }

  .sm\:-right-52 {
    right: -13rem;
  }

  .sm\:-right-56 {
    right: -14rem;
  }

  .sm\:-right-60 {
    right: -15rem;
  }

  .sm\:-right-64 {
    right: -16rem;
  }

  .sm\:-right-72 {
    right: -18rem;
  }

  .sm\:-right-80 {
    right: -20rem;
  }

  .sm\:-right-96 {
    right: -24rem;
  }

  .sm\:-right-px {
    right: -1px;
  }

  .sm\:-right-0\.5 {
    right: -.125rem;
  }

  .sm\:-right-1\.5 {
    right: -.375rem;
  }

  .sm\:-right-2\.5 {
    right: -.625rem;
  }

  .sm\:-right-3\.5 {
    right: -.875rem;
  }

  .sm\:right-1\/2 {
    right: 50%;
  }

  .sm\:right-1\/3 {
    right: 33.3333%;
  }

  .sm\:right-2\/3 {
    right: 66.6667%;
  }

  .sm\:right-1\/4 {
    right: 25%;
  }

  .sm\:right-2\/4 {
    right: 50%;
  }

  .sm\:right-3\/4 {
    right: 75%;
  }

  .sm\:right-full {
    right: 100%;
  }

  .sm\:-right-1\/2 {
    right: -50%;
  }

  .sm\:-right-1\/3 {
    right: -33.3333%;
  }

  .sm\:-right-2\/3 {
    right: -66.6667%;
  }

  .sm\:-right-1\/4 {
    right: -25%;
  }

  .sm\:-right-2\/4 {
    right: -50%;
  }

  .sm\:-right-3\/4 {
    right: -75%;
  }

  .sm\:-right-full {
    right: -100%;
  }

  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:bottom-1 {
    bottom: .25rem;
  }

  .sm\:bottom-2 {
    bottom: .5rem;
  }

  .sm\:bottom-3 {
    bottom: .75rem;
  }

  .sm\:bottom-4 {
    bottom: 1rem;
  }

  .sm\:bottom-5 {
    bottom: 1.25rem;
  }

  .sm\:bottom-6 {
    bottom: 1.5rem;
  }

  .sm\:bottom-7 {
    bottom: 1.75rem;
  }

  .sm\:bottom-8 {
    bottom: 2rem;
  }

  .sm\:bottom-9 {
    bottom: 2.25rem;
  }

  .sm\:bottom-10 {
    bottom: 2.5rem;
  }

  .sm\:bottom-11 {
    bottom: 2.75rem;
  }

  .sm\:bottom-12 {
    bottom: 3rem;
  }

  .sm\:bottom-14 {
    bottom: 3.5rem;
  }

  .sm\:bottom-16 {
    bottom: 4rem;
  }

  .sm\:bottom-20 {
    bottom: 5rem;
  }

  .sm\:bottom-24 {
    bottom: 6rem;
  }

  .sm\:bottom-28 {
    bottom: 7rem;
  }

  .sm\:bottom-32 {
    bottom: 8rem;
  }

  .sm\:bottom-36 {
    bottom: 9rem;
  }

  .sm\:bottom-40 {
    bottom: 10rem;
  }

  .sm\:bottom-44 {
    bottom: 11rem;
  }

  .sm\:bottom-48 {
    bottom: 12rem;
  }

  .sm\:bottom-52 {
    bottom: 13rem;
  }

  .sm\:bottom-56 {
    bottom: 14rem;
  }

  .sm\:bottom-60 {
    bottom: 15rem;
  }

  .sm\:bottom-64 {
    bottom: 16rem;
  }

  .sm\:bottom-72 {
    bottom: 18rem;
  }

  .sm\:bottom-80 {
    bottom: 20rem;
  }

  .sm\:bottom-96 {
    bottom: 24rem;
  }

  .sm\:bottom-auto {
    bottom: auto;
  }

  .sm\:bottom-px {
    bottom: 1px;
  }

  .sm\:bottom-0\.5 {
    bottom: .125rem;
  }

  .sm\:bottom-1\.5 {
    bottom: .375rem;
  }

  .sm\:bottom-2\.5 {
    bottom: .625rem;
  }

  .sm\:bottom-3\.5 {
    bottom: .875rem;
  }

  .sm\:-bottom-0 {
    bottom: 0;
  }

  .sm\:-bottom-1 {
    bottom: -.25rem;
  }

  .sm\:-bottom-2 {
    bottom: -.5rem;
  }

  .sm\:-bottom-3 {
    bottom: -.75rem;
  }

  .sm\:-bottom-4 {
    bottom: -1rem;
  }

  .sm\:-bottom-5 {
    bottom: -1.25rem;
  }

  .sm\:-bottom-6 {
    bottom: -1.5rem;
  }

  .sm\:-bottom-7 {
    bottom: -1.75rem;
  }

  .sm\:-bottom-8 {
    bottom: -2rem;
  }

  .sm\:-bottom-9 {
    bottom: -2.25rem;
  }

  .sm\:-bottom-10 {
    bottom: -2.5rem;
  }

  .sm\:-bottom-11 {
    bottom: -2.75rem;
  }

  .sm\:-bottom-12 {
    bottom: -3rem;
  }

  .sm\:-bottom-14 {
    bottom: -3.5rem;
  }

  .sm\:-bottom-16 {
    bottom: -4rem;
  }

  .sm\:-bottom-20 {
    bottom: -5rem;
  }

  .sm\:-bottom-24 {
    bottom: -6rem;
  }

  .sm\:-bottom-28 {
    bottom: -7rem;
  }

  .sm\:-bottom-32 {
    bottom: -8rem;
  }

  .sm\:-bottom-36 {
    bottom: -9rem;
  }

  .sm\:-bottom-40 {
    bottom: -10rem;
  }

  .sm\:-bottom-44 {
    bottom: -11rem;
  }

  .sm\:-bottom-48 {
    bottom: -12rem;
  }

  .sm\:-bottom-52 {
    bottom: -13rem;
  }

  .sm\:-bottom-56 {
    bottom: -14rem;
  }

  .sm\:-bottom-60 {
    bottom: -15rem;
  }

  .sm\:-bottom-64 {
    bottom: -16rem;
  }

  .sm\:-bottom-72 {
    bottom: -18rem;
  }

  .sm\:-bottom-80 {
    bottom: -20rem;
  }

  .sm\:-bottom-96 {
    bottom: -24rem;
  }

  .sm\:-bottom-px {
    bottom: -1px;
  }

  .sm\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .sm\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .sm\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .sm\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .sm\:bottom-1\/2 {
    bottom: 50%;
  }

  .sm\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .sm\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .sm\:bottom-1\/4 {
    bottom: 25%;
  }

  .sm\:bottom-2\/4 {
    bottom: 50%;
  }

  .sm\:bottom-3\/4 {
    bottom: 75%;
  }

  .sm\:bottom-full {
    bottom: 100%;
  }

  .sm\:-bottom-1\/2 {
    bottom: -50%;
  }

  .sm\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .sm\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .sm\:-bottom-1\/4 {
    bottom: -25%;
  }

  .sm\:-bottom-2\/4 {
    bottom: -50%;
  }

  .sm\:-bottom-3\/4 {
    bottom: -75%;
  }

  .sm\:-bottom-full {
    bottom: -100%;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:left-1 {
    left: .25rem;
  }

  .sm\:left-2 {
    left: .5rem;
  }

  .sm\:left-3 {
    left: .75rem;
  }

  .sm\:left-4 {
    left: 1rem;
  }

  .sm\:left-5 {
    left: 1.25rem;
  }

  .sm\:left-6 {
    left: 1.5rem;
  }

  .sm\:left-7 {
    left: 1.75rem;
  }

  .sm\:left-8 {
    left: 2rem;
  }

  .sm\:left-9 {
    left: 2.25rem;
  }

  .sm\:left-10 {
    left: 2.5rem;
  }

  .sm\:left-11 {
    left: 2.75rem;
  }

  .sm\:left-12 {
    left: 3rem;
  }

  .sm\:left-14 {
    left: 3.5rem;
  }

  .sm\:left-16 {
    left: 4rem;
  }

  .sm\:left-20 {
    left: 5rem;
  }

  .sm\:left-24 {
    left: 6rem;
  }

  .sm\:left-28 {
    left: 7rem;
  }

  .sm\:left-32 {
    left: 8rem;
  }

  .sm\:left-36 {
    left: 9rem;
  }

  .sm\:left-40 {
    left: 10rem;
  }

  .sm\:left-44 {
    left: 11rem;
  }

  .sm\:left-48 {
    left: 12rem;
  }

  .sm\:left-52 {
    left: 13rem;
  }

  .sm\:left-56 {
    left: 14rem;
  }

  .sm\:left-60 {
    left: 15rem;
  }

  .sm\:left-64 {
    left: 16rem;
  }

  .sm\:left-72 {
    left: 18rem;
  }

  .sm\:left-80 {
    left: 20rem;
  }

  .sm\:left-96 {
    left: 24rem;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:left-px {
    left: 1px;
  }

  .sm\:left-0\.5 {
    left: .125rem;
  }

  .sm\:left-1\.5 {
    left: .375rem;
  }

  .sm\:left-2\.5 {
    left: .625rem;
  }

  .sm\:left-3\.5 {
    left: .875rem;
  }

  .sm\:-left-0 {
    left: 0;
  }

  .sm\:-left-1 {
    left: -.25rem;
  }

  .sm\:-left-2 {
    left: -.5rem;
  }

  .sm\:-left-3 {
    left: -.75rem;
  }

  .sm\:-left-4 {
    left: -1rem;
  }

  .sm\:-left-5 {
    left: -1.25rem;
  }

  .sm\:-left-6 {
    left: -1.5rem;
  }

  .sm\:-left-7 {
    left: -1.75rem;
  }

  .sm\:-left-8 {
    left: -2rem;
  }

  .sm\:-left-9 {
    left: -2.25rem;
  }

  .sm\:-left-10 {
    left: -2.5rem;
  }

  .sm\:-left-11 {
    left: -2.75rem;
  }

  .sm\:-left-12 {
    left: -3rem;
  }

  .sm\:-left-14 {
    left: -3.5rem;
  }

  .sm\:-left-16 {
    left: -4rem;
  }

  .sm\:-left-20 {
    left: -5rem;
  }

  .sm\:-left-24 {
    left: -6rem;
  }

  .sm\:-left-28 {
    left: -7rem;
  }

  .sm\:-left-32 {
    left: -8rem;
  }

  .sm\:-left-36 {
    left: -9rem;
  }

  .sm\:-left-40 {
    left: -10rem;
  }

  .sm\:-left-44 {
    left: -11rem;
  }

  .sm\:-left-48 {
    left: -12rem;
  }

  .sm\:-left-52 {
    left: -13rem;
  }

  .sm\:-left-56 {
    left: -14rem;
  }

  .sm\:-left-60 {
    left: -15rem;
  }

  .sm\:-left-64 {
    left: -16rem;
  }

  .sm\:-left-72 {
    left: -18rem;
  }

  .sm\:-left-80 {
    left: -20rem;
  }

  .sm\:-left-96 {
    left: -24rem;
  }

  .sm\:-left-px {
    left: -1px;
  }

  .sm\:-left-0\.5 {
    left: -.125rem;
  }

  .sm\:-left-1\.5 {
    left: -.375rem;
  }

  .sm\:-left-2\.5 {
    left: -.625rem;
  }

  .sm\:-left-3\.5 {
    left: -.875rem;
  }

  .sm\:left-1\/2 {
    left: 50%;
  }

  .sm\:left-1\/3 {
    left: 33.3333%;
  }

  .sm\:left-2\/3 {
    left: 66.6667%;
  }

  .sm\:left-1\/4 {
    left: 25%;
  }

  .sm\:left-2\/4 {
    left: 50%;
  }

  .sm\:left-3\/4 {
    left: 75%;
  }

  .sm\:left-full {
    left: 100%;
  }

  .sm\:-left-1\/2 {
    left: -50%;
  }

  .sm\:-left-1\/3 {
    left: -33.3333%;
  }

  .sm\:-left-2\/3 {
    left: -66.6667%;
  }

  .sm\:-left-1\/4 {
    left: -25%;
  }

  .sm\:-left-2\/4 {
    left: -50%;
  }

  .sm\:-left-3\/4 {
    left: -75%;
  }

  .sm\:-left-full {
    left: -100%;
  }

  .sm\:isolate {
    isolation: isolate;
  }

  .sm\:isolation-auto {
    isolation: auto;
  }

  .sm\:z-0 {
    z-index: 0;
  }

  .sm\:z-10 {
    z-index: 10;
  }

  .sm\:z-20 {
    z-index: 20;
  }

  .sm\:z-30 {
    z-index: 30;
  }

  .sm\:z-40 {
    z-index: 40;
  }

  .sm\:z-50 {
    z-index: 50;
  }

  .sm\:z-auto {
    z-index: auto;
  }

  .sm\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .sm\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .sm\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .sm\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .sm\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .sm\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .sm\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .sm\:focus\:z-0:focus {
    z-index: 0;
  }

  .sm\:focus\:z-10:focus {
    z-index: 10;
  }

  .sm\:focus\:z-20:focus {
    z-index: 20;
  }

  .sm\:focus\:z-30:focus {
    z-index: 30;
  }

  .sm\:focus\:z-40:focus {
    z-index: 40;
  }

  .sm\:focus\:z-50:focus {
    z-index: 50;
  }

  .sm\:focus\:z-auto:focus {
    z-index: auto;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-3 {
    order: 3;
  }

  .sm\:order-4 {
    order: 4;
  }

  .sm\:order-5 {
    order: 5;
  }

  .sm\:order-6 {
    order: 6;
  }

  .sm\:order-7 {
    order: 7;
  }

  .sm\:order-8 {
    order: 8;
  }

  .sm\:order-9 {
    order: 9;
  }

  .sm\:order-10 {
    order: 10;
  }

  .sm\:order-11 {
    order: 11;
  }

  .sm\:order-12 {
    order: 12;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:order-none {
    order: 0;
  }

  .sm\:col-auto {
    grid-column: auto;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:col-start-5 {
    grid-column-start: 5;
  }

  .sm\:col-start-6 {
    grid-column-start: 6;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:col-start-8 {
    grid-column-start: 8;
  }

  .sm\:col-start-9 {
    grid-column-start: 9;
  }

  .sm\:col-start-10 {
    grid-column-start: 10;
  }

  .sm\:col-start-11 {
    grid-column-start: 11;
  }

  .sm\:col-start-12 {
    grid-column-start: 12;
  }

  .sm\:col-start-13 {
    grid-column-start: 13;
  }

  .sm\:col-start-auto {
    grid-column-start: auto;
  }

  .sm\:col-end-1 {
    grid-column-end: 1;
  }

  .sm\:col-end-2 {
    grid-column-end: 2;
  }

  .sm\:col-end-3 {
    grid-column-end: 3;
  }

  .sm\:col-end-4 {
    grid-column-end: 4;
  }

  .sm\:col-end-5 {
    grid-column-end: 5;
  }

  .sm\:col-end-6 {
    grid-column-end: 6;
  }

  .sm\:col-end-7 {
    grid-column-end: 7;
  }

  .sm\:col-end-8 {
    grid-column-end: 8;
  }

  .sm\:col-end-9 {
    grid-column-end: 9;
  }

  .sm\:col-end-10 {
    grid-column-end: 10;
  }

  .sm\:col-end-11 {
    grid-column-end: 11;
  }

  .sm\:col-end-12 {
    grid-column-end: 12;
  }

  .sm\:col-end-13 {
    grid-column-end: 13;
  }

  .sm\:col-end-auto {
    grid-column-end: auto;
  }

  .sm\:row-auto {
    grid-row: auto;
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm\:row-span-full {
    grid-row: 1 / -1;
  }

  .sm\:row-start-1 {
    grid-row-start: 1;
  }

  .sm\:row-start-2 {
    grid-row-start: 2;
  }

  .sm\:row-start-3 {
    grid-row-start: 3;
  }

  .sm\:row-start-4 {
    grid-row-start: 4;
  }

  .sm\:row-start-5 {
    grid-row-start: 5;
  }

  .sm\:row-start-6 {
    grid-row-start: 6;
  }

  .sm\:row-start-7 {
    grid-row-start: 7;
  }

  .sm\:row-start-auto {
    grid-row-start: auto;
  }

  .sm\:row-end-1 {
    grid-row-end: 1;
  }

  .sm\:row-end-2 {
    grid-row-end: 2;
  }

  .sm\:row-end-3 {
    grid-row-end: 3;
  }

  .sm\:row-end-4 {
    grid-row-end: 4;
  }

  .sm\:row-end-5 {
    grid-row-end: 5;
  }

  .sm\:row-end-6 {
    grid-row-end: 6;
  }

  .sm\:row-end-7 {
    grid-row-end: 7;
  }

  .sm\:row-end-auto {
    grid-row-end: auto;
  }

  .sm\:float-right {
    float: right;
  }

  .sm\:float-left {
    float: left;
  }

  .sm\:float-none {
    float: none;
  }

  .sm\:clear-left {
    clear: left;
  }

  .sm\:clear-right {
    clear: right;
  }

  .sm\:clear-both {
    clear: both;
  }

  .sm\:clear-none {
    clear: none;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:m-1 {
    margin: .25rem;
  }

  .sm\:m-2 {
    margin: .5rem;
  }

  .sm\:m-3 {
    margin: .75rem;
  }

  .sm\:m-4 {
    margin: 1rem;
  }

  .sm\:m-5 {
    margin: 1.25rem;
  }

  .sm\:m-6 {
    margin: 1.5rem;
  }

  .sm\:m-7 {
    margin: 1.75rem;
  }

  .sm\:m-8 {
    margin: 2rem;
  }

  .sm\:m-9 {
    margin: 2.25rem;
  }

  .sm\:m-10 {
    margin: 2.5rem;
  }

  .sm\:m-11 {
    margin: 2.75rem;
  }

  .sm\:m-12 {
    margin: 3rem;
  }

  .sm\:m-14 {
    margin: 3.5rem;
  }

  .sm\:m-16 {
    margin: 4rem;
  }

  .sm\:m-20 {
    margin: 5rem;
  }

  .sm\:m-24 {
    margin: 6rem;
  }

  .sm\:m-28 {
    margin: 7rem;
  }

  .sm\:m-32 {
    margin: 8rem;
  }

  .sm\:m-36 {
    margin: 9rem;
  }

  .sm\:m-40 {
    margin: 10rem;
  }

  .sm\:m-44 {
    margin: 11rem;
  }

  .sm\:m-48 {
    margin: 12rem;
  }

  .sm\:m-52 {
    margin: 13rem;
  }

  .sm\:m-56 {
    margin: 14rem;
  }

  .sm\:m-60 {
    margin: 15rem;
  }

  .sm\:m-64 {
    margin: 16rem;
  }

  .sm\:m-72 {
    margin: 18rem;
  }

  .sm\:m-80 {
    margin: 20rem;
  }

  .sm\:m-96 {
    margin: 24rem;
  }

  .sm\:m-auto {
    margin: auto;
  }

  .sm\:m-px {
    margin: 1px;
  }

  .sm\:m-0\.5 {
    margin: .125rem;
  }

  .sm\:m-1\.5 {
    margin: .375rem;
  }

  .sm\:m-2\.5 {
    margin: .625rem;
  }

  .sm\:m-3\.5 {
    margin: .875rem;
  }

  .sm\:-m-0 {
    margin: 0;
  }

  .sm\:-m-1 {
    margin: -.25rem;
  }

  .sm\:-m-2 {
    margin: -.5rem;
  }

  .sm\:-m-3 {
    margin: -.75rem;
  }

  .sm\:-m-4 {
    margin: -1rem;
  }

  .sm\:-m-5 {
    margin: -1.25rem;
  }

  .sm\:-m-6 {
    margin: -1.5rem;
  }

  .sm\:-m-7 {
    margin: -1.75rem;
  }

  .sm\:-m-8 {
    margin: -2rem;
  }

  .sm\:-m-9 {
    margin: -2.25rem;
  }

  .sm\:-m-10 {
    margin: -2.5rem;
  }

  .sm\:-m-11 {
    margin: -2.75rem;
  }

  .sm\:-m-12 {
    margin: -3rem;
  }

  .sm\:-m-14 {
    margin: -3.5rem;
  }

  .sm\:-m-16 {
    margin: -4rem;
  }

  .sm\:-m-20 {
    margin: -5rem;
  }

  .sm\:-m-24 {
    margin: -6rem;
  }

  .sm\:-m-28 {
    margin: -7rem;
  }

  .sm\:-m-32 {
    margin: -8rem;
  }

  .sm\:-m-36 {
    margin: -9rem;
  }

  .sm\:-m-40 {
    margin: -10rem;
  }

  .sm\:-m-44 {
    margin: -11rem;
  }

  .sm\:-m-48 {
    margin: -12rem;
  }

  .sm\:-m-52 {
    margin: -13rem;
  }

  .sm\:-m-56 {
    margin: -14rem;
  }

  .sm\:-m-60 {
    margin: -15rem;
  }

  .sm\:-m-64 {
    margin: -16rem;
  }

  .sm\:-m-72 {
    margin: -18rem;
  }

  .sm\:-m-80 {
    margin: -20rem;
  }

  .sm\:-m-96 {
    margin: -24rem;
  }

  .sm\:-m-px {
    margin: -1px;
  }

  .sm\:-m-0\.5 {
    margin: -.125rem;
  }

  .sm\:-m-1\.5 {
    margin: -.375rem;
  }

  .sm\:-m-2\.5 {
    margin: -.625rem;
  }

  .sm\:-m-3\.5 {
    margin: -.875rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .sm\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .sm\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .sm\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .sm\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .sm\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .sm\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .sm\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .sm\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .sm\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .sm\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .sm\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .sm\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .sm\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .sm\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .sm\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .sm\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .sm\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .sm\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .sm\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .sm\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .sm\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .sm\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .sm\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .sm\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .sm\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .sm\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-1 {
    margin-top: .25rem;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mt-3 {
    margin-top: .75rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mt-7 {
    margin-top: 1.75rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mt-9 {
    margin-top: 2.25rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-11 {
    margin-top: 2.75rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-14 {
    margin-top: 3.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mt-28 {
    margin-top: 7rem;
  }

  .sm\:mt-32 {
    margin-top: 8rem;
  }

  .sm\:mt-36 {
    margin-top: 9rem;
  }

  .sm\:mt-40 {
    margin-top: 10rem;
  }

  .sm\:mt-44 {
    margin-top: 11rem;
  }

  .sm\:mt-48 {
    margin-top: 12rem;
  }

  .sm\:mt-52 {
    margin-top: 13rem;
  }

  .sm\:mt-56 {
    margin-top: 14rem;
  }

  .sm\:mt-60 {
    margin-top: 15rem;
  }

  .sm\:mt-64 {
    margin-top: 16rem;
  }

  .sm\:mt-72 {
    margin-top: 18rem;
  }

  .sm\:mt-80 {
    margin-top: 20rem;
  }

  .sm\:mt-96 {
    margin-top: 24rem;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:mt-px {
    margin-top: 1px;
  }

  .sm\:mt-0\.5 {
    margin-top: .125rem;
  }

  .sm\:mt-1\.5 {
    margin-top: .375rem;
  }

  .sm\:mt-2\.5 {
    margin-top: .625rem;
  }

  .sm\:mt-3\.5 {
    margin-top: .875rem;
  }

  .sm\:-mt-0 {
    margin-top: 0;
  }

  .sm\:-mt-1 {
    margin-top: -.25rem;
  }

  .sm\:-mt-2 {
    margin-top: -.5rem;
  }

  .sm\:-mt-3 {
    margin-top: -.75rem;
  }

  .sm\:-mt-4 {
    margin-top: -1rem;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:-mt-7 {
    margin-top: -1.75rem;
  }

  .sm\:-mt-8 {
    margin-top: -2rem;
  }

  .sm\:-mt-9 {
    margin-top: -2.25rem;
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:-mt-11 {
    margin-top: -2.75rem;
  }

  .sm\:-mt-12 {
    margin-top: -3rem;
  }

  .sm\:-mt-14 {
    margin-top: -3.5rem;
  }

  .sm\:-mt-16 {
    margin-top: -4rem;
  }

  .sm\:-mt-20 {
    margin-top: -5rem;
  }

  .sm\:-mt-24 {
    margin-top: -6rem;
  }

  .sm\:-mt-28 {
    margin-top: -7rem;
  }

  .sm\:-mt-32 {
    margin-top: -8rem;
  }

  .sm\:-mt-36 {
    margin-top: -9rem;
  }

  .sm\:-mt-40 {
    margin-top: -10rem;
  }

  .sm\:-mt-44 {
    margin-top: -11rem;
  }

  .sm\:-mt-48 {
    margin-top: -12rem;
  }

  .sm\:-mt-52 {
    margin-top: -13rem;
  }

  .sm\:-mt-56 {
    margin-top: -14rem;
  }

  .sm\:-mt-60 {
    margin-top: -15rem;
  }

  .sm\:-mt-64 {
    margin-top: -16rem;
  }

  .sm\:-mt-72 {
    margin-top: -18rem;
  }

  .sm\:-mt-80 {
    margin-top: -20rem;
  }

  .sm\:-mt-96 {
    margin-top: -24rem;
  }

  .sm\:-mt-px {
    margin-top: -1px;
  }

  .sm\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .sm\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .sm\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mr-1 {
    margin-right: .25rem;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:mr-3 {
    margin-right: .75rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:mr-7 {
    margin-right: 1.75rem;
  }

  .sm\:mr-8 {
    margin-right: 2rem;
  }

  .sm\:mr-9 {
    margin-right: 2.25rem;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:mr-11 {
    margin-right: 2.75rem;
  }

  .sm\:mr-12 {
    margin-right: 3rem;
  }

  .sm\:mr-14 {
    margin-right: 3.5rem;
  }

  .sm\:mr-16 {
    margin-right: 4rem;
  }

  .sm\:mr-20 {
    margin-right: 5rem;
  }

  .sm\:mr-24 {
    margin-right: 6rem;
  }

  .sm\:mr-28 {
    margin-right: 7rem;
  }

  .sm\:mr-32 {
    margin-right: 8rem;
  }

  .sm\:mr-36 {
    margin-right: 9rem;
  }

  .sm\:mr-40 {
    margin-right: 10rem;
  }

  .sm\:mr-44 {
    margin-right: 11rem;
  }

  .sm\:mr-48 {
    margin-right: 12rem;
  }

  .sm\:mr-52 {
    margin-right: 13rem;
  }

  .sm\:mr-56 {
    margin-right: 14rem;
  }

  .sm\:mr-60 {
    margin-right: 15rem;
  }

  .sm\:mr-64 {
    margin-right: 16rem;
  }

  .sm\:mr-72 {
    margin-right: 18rem;
  }

  .sm\:mr-80 {
    margin-right: 20rem;
  }

  .sm\:mr-96 {
    margin-right: 24rem;
  }

  .sm\:mr-auto {
    margin-right: auto;
  }

  .sm\:mr-px {
    margin-right: 1px;
  }

  .sm\:mr-0\.5 {
    margin-right: .125rem;
  }

  .sm\:mr-1\.5 {
    margin-right: .375rem;
  }

  .sm\:mr-2\.5 {
    margin-right: .625rem;
  }

  .sm\:mr-3\.5 {
    margin-right: .875rem;
  }

  .sm\:-mr-0 {
    margin-right: 0;
  }

  .sm\:-mr-1 {
    margin-right: -.25rem;
  }

  .sm\:-mr-2 {
    margin-right: -.5rem;
  }

  .sm\:-mr-3 {
    margin-right: -.75rem;
  }

  .sm\:-mr-4 {
    margin-right: -1rem;
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:-mr-7 {
    margin-right: -1.75rem;
  }

  .sm\:-mr-8 {
    margin-right: -2rem;
  }

  .sm\:-mr-9 {
    margin-right: -2.25rem;
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:-mr-11 {
    margin-right: -2.75rem;
  }

  .sm\:-mr-12 {
    margin-right: -3rem;
  }

  .sm\:-mr-14 {
    margin-right: -3.5rem;
  }

  .sm\:-mr-16 {
    margin-right: -4rem;
  }

  .sm\:-mr-20 {
    margin-right: -5rem;
  }

  .sm\:-mr-24 {
    margin-right: -6rem;
  }

  .sm\:-mr-28 {
    margin-right: -7rem;
  }

  .sm\:-mr-32 {
    margin-right: -8rem;
  }

  .sm\:-mr-36 {
    margin-right: -9rem;
  }

  .sm\:-mr-40 {
    margin-right: -10rem;
  }

  .sm\:-mr-44 {
    margin-right: -11rem;
  }

  .sm\:-mr-48 {
    margin-right: -12rem;
  }

  .sm\:-mr-52 {
    margin-right: -13rem;
  }

  .sm\:-mr-56 {
    margin-right: -14rem;
  }

  .sm\:-mr-60 {
    margin-right: -15rem;
  }

  .sm\:-mr-64 {
    margin-right: -16rem;
  }

  .sm\:-mr-72 {
    margin-right: -18rem;
  }

  .sm\:-mr-80 {
    margin-right: -20rem;
  }

  .sm\:-mr-96 {
    margin-right: -24rem;
  }

  .sm\:-mr-px {
    margin-right: -1px;
  }

  .sm\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .sm\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .sm\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .sm\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:mb-28 {
    margin-bottom: 7rem;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:mb-36 {
    margin-bottom: 9rem;
  }

  .sm\:mb-40 {
    margin-bottom: 10rem;
  }

  .sm\:mb-44 {
    margin-bottom: 11rem;
  }

  .sm\:mb-48 {
    margin-bottom: 12rem;
  }

  .sm\:mb-52 {
    margin-bottom: 13rem;
  }

  .sm\:mb-56 {
    margin-bottom: 14rem;
  }

  .sm\:mb-60 {
    margin-bottom: 15rem;
  }

  .sm\:mb-64 {
    margin-bottom: 16rem;
  }

  .sm\:mb-72 {
    margin-bottom: 18rem;
  }

  .sm\:mb-80 {
    margin-bottom: 20rem;
  }

  .sm\:mb-96 {
    margin-bottom: 24rem;
  }

  .sm\:mb-auto {
    margin-bottom: auto;
  }

  .sm\:mb-px {
    margin-bottom: 1px;
  }

  .sm\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .sm\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .sm\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .sm\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .sm\:-mb-0 {
    margin-bottom: 0;
  }

  .sm\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .sm\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .sm\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:-mb-28 {
    margin-bottom: -7rem;
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:-mb-36 {
    margin-bottom: -9rem;
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem;
  }

  .sm\:-mb-44 {
    margin-bottom: -11rem;
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem;
  }

  .sm\:-mb-52 {
    margin-bottom: -13rem;
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem;
  }

  .sm\:-mb-60 {
    margin-bottom: -15rem;
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem;
  }

  .sm\:-mb-72 {
    margin-bottom: -18rem;
  }

  .sm\:-mb-80 {
    margin-bottom: -20rem;
  }

  .sm\:-mb-96 {
    margin-bottom: -24rem;
  }

  .sm\:-mb-px {
    margin-bottom: -1px;
  }

  .sm\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .sm\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .sm\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .sm\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:ml-7 {
    margin-left: 1.75rem;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:ml-9 {
    margin-left: 2.25rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-11 {
    margin-left: 2.75rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:ml-14 {
    margin-left: 3.5rem;
  }

  .sm\:ml-16 {
    margin-left: 4rem;
  }

  .sm\:ml-20 {
    margin-left: 5rem;
  }

  .sm\:ml-24 {
    margin-left: 6rem;
  }

  .sm\:ml-28 {
    margin-left: 7rem;
  }

  .sm\:ml-32 {
    margin-left: 8rem;
  }

  .sm\:ml-36 {
    margin-left: 9rem;
  }

  .sm\:ml-40 {
    margin-left: 10rem;
  }

  .sm\:ml-44 {
    margin-left: 11rem;
  }

  .sm\:ml-48 {
    margin-left: 12rem;
  }

  .sm\:ml-52 {
    margin-left: 13rem;
  }

  .sm\:ml-56 {
    margin-left: 14rem;
  }

  .sm\:ml-60 {
    margin-left: 15rem;
  }

  .sm\:ml-64 {
    margin-left: 16rem;
  }

  .sm\:ml-72 {
    margin-left: 18rem;
  }

  .sm\:ml-80 {
    margin-left: 20rem;
  }

  .sm\:ml-96 {
    margin-left: 24rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:ml-px {
    margin-left: 1px;
  }

  .sm\:ml-0\.5 {
    margin-left: .125rem;
  }

  .sm\:ml-1\.5 {
    margin-left: .375rem;
  }

  .sm\:ml-2\.5 {
    margin-left: .625rem;
  }

  .sm\:ml-3\.5 {
    margin-left: .875rem;
  }

  .sm\:-ml-0 {
    margin-left: 0;
  }

  .sm\:-ml-1 {
    margin-left: -.25rem;
  }

  .sm\:-ml-2 {
    margin-left: -.5rem;
  }

  .sm\:-ml-3 {
    margin-left: -.75rem;
  }

  .sm\:-ml-4 {
    margin-left: -1rem;
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:-ml-7 {
    margin-left: -1.75rem;
  }

  .sm\:-ml-8 {
    margin-left: -2rem;
  }

  .sm\:-ml-9 {
    margin-left: -2.25rem;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:-ml-11 {
    margin-left: -2.75rem;
  }

  .sm\:-ml-12 {
    margin-left: -3rem;
  }

  .sm\:-ml-14 {
    margin-left: -3.5rem;
  }

  .sm\:-ml-16 {
    margin-left: -4rem;
  }

  .sm\:-ml-20 {
    margin-left: -5rem;
  }

  .sm\:-ml-24 {
    margin-left: -6rem;
  }

  .sm\:-ml-28 {
    margin-left: -7rem;
  }

  .sm\:-ml-32 {
    margin-left: -8rem;
  }

  .sm\:-ml-36 {
    margin-left: -9rem;
  }

  .sm\:-ml-40 {
    margin-left: -10rem;
  }

  .sm\:-ml-44 {
    margin-left: -11rem;
  }

  .sm\:-ml-48 {
    margin-left: -12rem;
  }

  .sm\:-ml-52 {
    margin-left: -13rem;
  }

  .sm\:-ml-56 {
    margin-left: -14rem;
  }

  .sm\:-ml-60 {
    margin-left: -15rem;
  }

  .sm\:-ml-64 {
    margin-left: -16rem;
  }

  .sm\:-ml-72 {
    margin-left: -18rem;
  }

  .sm\:-ml-80 {
    margin-left: -20rem;
  }

  .sm\:-ml-96 {
    margin-left: -24rem;
  }

  .sm\:-ml-px {
    margin-left: -1px;
  }

  .sm\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .sm\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .sm\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .sm\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .sm\:box-border {
    box-sizing: border-box;
  }

  .sm\:box-content {
    box-sizing: content-box;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:table {
    display: table;
  }

  .sm\:inline-table {
    display: inline-table;
  }

  .sm\:table-caption {
    display: table-caption;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-column {
    display: table-column;
  }

  .sm\:table-column-group {
    display: table-column-group;
  }

  .sm\:table-footer-group {
    display: table-footer-group;
  }

  .sm\:table-header-group {
    display: table-header-group;
  }

  .sm\:table-row-group {
    display: table-row-group;
  }

  .sm\:table-row {
    display: table-row;
  }

  .sm\:flow-root {
    display: flow-root;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:inline-grid {
    display: inline-grid;
  }

  .sm\:contents {
    display: contents;
  }

  .sm\:list-item {
    display: list-item;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-0 {
    height: 0;
  }

  .sm\:h-1 {
    height: .25rem;
  }

  .sm\:h-2 {
    height: .5rem;
  }

  .sm\:h-3 {
    height: .75rem;
  }

  .sm\:h-4 {
    height: 1rem;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-7 {
    height: 1.75rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-9 {
    height: 2.25rem;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-11 {
    height: 2.75rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-14 {
    height: 3.5rem;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:h-20 {
    height: 5rem;
  }

  .sm\:h-24 {
    height: 6rem;
  }

  .sm\:h-28 {
    height: 7rem;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:h-36 {
    height: 9rem;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-44 {
    height: 11rem;
  }

  .sm\:h-48 {
    height: 12rem;
  }

  .sm\:h-52 {
    height: 13rem;
  }

  .sm\:h-56 {
    height: 14rem;
  }

  .sm\:h-60 {
    height: 15rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:h-72 {
    height: 18rem;
  }

  .sm\:h-80 {
    height: 20rem;
  }

  .sm\:h-96 {
    height: 24rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:h-px {
    height: 1px;
  }

  .sm\:h-0\.5 {
    height: .125rem;
  }

  .sm\:h-1\.5 {
    height: .375rem;
  }

  .sm\:h-2\.5 {
    height: .625rem;
  }

  .sm\:h-3\.5 {
    height: .875rem;
  }

  .sm\:h-1\/2 {
    height: 50%;
  }

  .sm\:h-1\/3 {
    height: 33.3333%;
  }

  .sm\:h-2\/3 {
    height: 66.6667%;
  }

  .sm\:h-1\/4 {
    height: 25%;
  }

  .sm\:h-2\/4 {
    height: 50%;
  }

  .sm\:h-3\/4 {
    height: 75%;
  }

  .sm\:h-1\/5 {
    height: 20%;
  }

  .sm\:h-2\/5 {
    height: 40%;
  }

  .sm\:h-3\/5 {
    height: 60%;
  }

  .sm\:h-4\/5 {
    height: 80%;
  }

  .sm\:h-1\/6 {
    height: 16.6667%;
  }

  .sm\:h-2\/6 {
    height: 33.3333%;
  }

  .sm\:h-3\/6 {
    height: 50%;
  }

  .sm\:h-4\/6 {
    height: 66.6667%;
  }

  .sm\:h-5\/6 {
    height: 83.3333%;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:max-h-0 {
    max-height: 0;
  }

  .sm\:max-h-1 {
    max-height: .25rem;
  }

  .sm\:max-h-2 {
    max-height: .5rem;
  }

  .sm\:max-h-3 {
    max-height: .75rem;
  }

  .sm\:max-h-4 {
    max-height: 1rem;
  }

  .sm\:max-h-5 {
    max-height: 1.25rem;
  }

  .sm\:max-h-6 {
    max-height: 1.5rem;
  }

  .sm\:max-h-7 {
    max-height: 1.75rem;
  }

  .sm\:max-h-8 {
    max-height: 2rem;
  }

  .sm\:max-h-9 {
    max-height: 2.25rem;
  }

  .sm\:max-h-10 {
    max-height: 2.5rem;
  }

  .sm\:max-h-11 {
    max-height: 2.75rem;
  }

  .sm\:max-h-12 {
    max-height: 3rem;
  }

  .sm\:max-h-14 {
    max-height: 3.5rem;
  }

  .sm\:max-h-16 {
    max-height: 4rem;
  }

  .sm\:max-h-20 {
    max-height: 5rem;
  }

  .sm\:max-h-24 {
    max-height: 6rem;
  }

  .sm\:max-h-28 {
    max-height: 7rem;
  }

  .sm\:max-h-32 {
    max-height: 8rem;
  }

  .sm\:max-h-36 {
    max-height: 9rem;
  }

  .sm\:max-h-40 {
    max-height: 10rem;
  }

  .sm\:max-h-44 {
    max-height: 11rem;
  }

  .sm\:max-h-48 {
    max-height: 12rem;
  }

  .sm\:max-h-52 {
    max-height: 13rem;
  }

  .sm\:max-h-56 {
    max-height: 14rem;
  }

  .sm\:max-h-60 {
    max-height: 15rem;
  }

  .sm\:max-h-64 {
    max-height: 16rem;
  }

  .sm\:max-h-72 {
    max-height: 18rem;
  }

  .sm\:max-h-80 {
    max-height: 20rem;
  }

  .sm\:max-h-96 {
    max-height: 24rem;
  }

  .sm\:max-h-px {
    max-height: 1px;
  }

  .sm\:max-h-0\.5 {
    max-height: .125rem;
  }

  .sm\:max-h-1\.5 {
    max-height: .375rem;
  }

  .sm\:max-h-2\.5 {
    max-height: .625rem;
  }

  .sm\:max-h-3\.5 {
    max-height: .875rem;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:max-h-screen {
    max-height: 100vh;
  }

  .sm\:min-h-0 {
    min-height: 0;
  }

  .sm\:min-h-full {
    min-height: 100%;
  }

  .sm\:min-h-screen {
    min-height: 100vh;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-1 {
    width: .25rem;
  }

  .sm\:w-2 {
    width: .5rem;
  }

  .sm\:w-3 {
    width: .75rem;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-7 {
    width: 1.75rem;
  }

  .sm\:w-8 {
    width: 2rem;
  }

  .sm\:w-9 {
    width: 2.25rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-11 {
    width: 2.75rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-14 {
    width: 3.5rem;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-20 {
    width: 5rem;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-28 {
    width: 7rem;
  }

  .sm\:w-32 {
    width: 8rem;
  }

  .sm\:w-36 {
    width: 9rem;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:w-52 {
    width: 13rem;
  }

  .sm\:w-56 {
    width: 14rem;
  }

  .sm\:w-60 {
    width: 15rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-px {
    width: 1px;
  }

  .sm\:w-0\.5 {
    width: .125rem;
  }

  .sm\:w-1\.5 {
    width: .375rem;
  }

  .sm\:w-2\.5 {
    width: .625rem;
  }

  .sm\:w-3\.5 {
    width: .875rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-2\/4 {
    width: 50%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-1\/5 {
    width: 20%;
  }

  .sm\:w-2\/5 {
    width: 40%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-1\/6 {
    width: 16.6667%;
  }

  .sm\:w-2\/6 {
    width: 33.3333%;
  }

  .sm\:w-3\/6 {
    width: 50%;
  }

  .sm\:w-4\/6 {
    width: 66.6667%;
  }

  .sm\:w-5\/6 {
    width: 83.3333%;
  }

  .sm\:w-1\/12 {
    width: 8.33333%;
  }

  .sm\:w-2\/12 {
    width: 16.6667%;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:w-5\/12 {
    width: 41.6667%;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }

  .sm\:w-7\/12 {
    width: 58.3333%;
  }

  .sm\:w-8\/12 {
    width: 66.6667%;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-10\/12 {
    width: 83.3333%;
  }

  .sm\:w-11\/12 {
    width: 91.6667%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-screen {
    width: 100vw;
  }

  .sm\:w-min {
    width: min-content;
  }

  .sm\:w-max {
    width: max-content;
  }

  .sm\:min-w-0 {
    min-width: 0;
  }

  .sm\:min-w-full {
    min-width: 100%;
  }

  .sm\:min-w-min {
    min-width: min-content;
  }

  .sm\:min-w-max {
    min-width: max-content;
  }

  .sm\:max-w-0 {
    max-width: 0;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:max-w-5xl {
    max-width: 64rem;
  }

  .sm\:max-w-6xl {
    max-width: 72rem;
  }

  .sm\:max-w-7xl {
    max-width: 80rem;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:max-w-min {
    max-width: min-content;
  }

  .sm\:max-w-max {
    max-width: max-content;
  }

  .sm\:max-w-prose {
    max-width: 65ch;
  }

  .sm\:max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:max-w-screen-md {
    max-width: 768px;
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px;
  }

  .sm\:max-w-screen-xl {
    max-width: 1280px;
  }

  .sm\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:flex-initial {
    flex: 0 auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:flex-shrink {
    flex-shrink: 1;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:flex-grow {
    flex-grow: 1;
  }

  .sm\:table-auto {
    table-layout: auto;
  }

  .sm\:table-fixed {
    table-layout: fixed;
  }

  .sm\:border-collapse {
    border-collapse: collapse;
  }

  .sm\:border-separate {
    border-collapse: separate;
  }

  .sm\:origin-center {
    transform-origin: center;
  }

  .sm\:origin-top {
    transform-origin: top;
  }

  .sm\:origin-top-right {
    transform-origin: 100% 0;
  }

  .sm\:origin-right {
    transform-origin: 100%;
  }

  .sm\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .sm\:origin-bottom {
    transform-origin: bottom;
  }

  .sm\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .sm\:origin-left {
    transform-origin: 0;
  }

  .sm\:origin-top-left {
    transform-origin: 0 0;
  }

  .sm\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform-none {
    transform: none;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .sm\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .sm\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .sm\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .sm\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .sm\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .sm\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .sm\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .sm\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .sm\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .sm\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .sm\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .sm\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .sm\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .sm\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .sm\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .sm\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .sm\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .sm\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .sm\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .sm\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .sm\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .sm\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .sm\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .sm\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .sm\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .sm\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .sm\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .sm\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .sm\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .sm\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .sm\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .sm\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .sm\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .sm\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .sm\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .sm\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .sm\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .sm\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .sm\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .sm\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .sm\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .sm\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .sm\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .sm\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .sm\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .sm\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .sm\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .sm\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .sm\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .sm\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .sm\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .sm\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .sm\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .sm\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .sm\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .sm\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .sm\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .sm\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .sm\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .sm\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .sm\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .sm\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .sm\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .sm\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .sm\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .sm\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .sm\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .sm\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .sm\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .sm\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .sm\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .sm\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .sm\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .sm\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .sm\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .sm\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .sm\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .sm\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .sm\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .sm\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .sm\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .sm\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .sm\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .sm\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .sm\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .sm\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .sm\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .sm\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .sm\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .sm\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .sm\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .sm\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .sm\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .sm\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .sm\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .sm\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .sm\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .sm\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .sm\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .sm\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .sm\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .sm\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .sm\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .sm\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .sm\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .sm\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .sm\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .sm\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .sm\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .sm\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .sm\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .sm\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .sm\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .sm\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .sm\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .sm\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .sm\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .sm\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .sm\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .sm\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .sm\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .sm\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .sm\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .sm\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .sm\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .sm\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .sm\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .sm\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .sm\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .sm\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .sm\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .sm\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .sm\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .sm\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .sm\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .sm\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .sm\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .sm\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .sm\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .sm\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .sm\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .sm\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .sm\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .sm\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .sm\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .sm\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .sm\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .sm\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .sm\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .sm\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .sm\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .sm\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .sm\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .sm\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .sm\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .sm\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .sm\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .sm\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .sm\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .sm\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .sm\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .sm\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .sm\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .sm\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .sm\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .sm\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .sm\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .sm\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .sm\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .sm\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .sm\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .sm\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .sm\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .sm\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .sm\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .sm\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .sm\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .sm\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .sm\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .sm\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .sm\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .sm\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .sm\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .sm\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .sm\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .sm\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .sm\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .sm\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .sm\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .sm\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .sm\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .sm\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .sm\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .sm\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .sm\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .sm\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .sm\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .sm\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .sm\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .sm\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .sm\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .sm\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .sm\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .sm\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .sm\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .sm\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .sm\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .sm\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .sm\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .sm\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .sm\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .sm\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .sm\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .sm\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .sm\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .sm\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .sm\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .sm\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .sm\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .sm\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .sm\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .sm\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .sm\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .sm\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .sm\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .sm\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .sm\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .sm\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .sm\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .sm\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .sm\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .sm\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .sm\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .sm\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .sm\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .sm\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .sm\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .sm\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .sm\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .sm\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .sm\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .sm\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .sm\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .sm\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .sm\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .sm\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .sm\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .sm\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .sm\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .sm\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .sm\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .sm\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .sm\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .sm\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .sm\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .sm\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .sm\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .sm\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .sm\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .sm\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .sm\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .sm\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .sm\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .sm\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .sm\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .sm\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .sm\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .sm\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .sm\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .sm\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .sm\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .sm\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .sm\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .sm\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .sm\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .sm\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .sm\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .sm\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .sm\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .sm\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .sm\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .sm\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .sm\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .sm\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .sm\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .sm\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .sm\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .sm\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .sm\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .sm\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .sm\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .sm\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .sm\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .sm\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .sm\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .sm\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .sm\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .sm\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .sm\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .sm\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .sm\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .sm\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .sm\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .sm\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .sm\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .sm\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .sm\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .sm\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .sm\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .sm\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .sm\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .sm\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .sm\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .sm\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .sm\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .sm\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .sm\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .sm\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .sm\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .sm\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .sm\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .sm\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .sm\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .sm\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .sm\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .sm\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .sm\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .sm\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .sm\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .sm\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .sm\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .sm\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .sm\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .sm\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .sm\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .sm\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .sm\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .sm\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .sm\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .sm\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .sm\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .sm\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .sm\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .sm\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .sm\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .sm\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .sm\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .sm\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .sm\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .sm\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .sm\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .sm\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .sm\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .sm\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .sm\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .sm\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .sm\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .sm\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .sm\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .sm\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .sm\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .sm\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .sm\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .sm\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .sm\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .sm\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .sm\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .sm\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .sm\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .sm\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .sm\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .sm\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .sm\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .sm\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .sm\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .sm\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .sm\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .sm\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .sm\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .sm\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .sm\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .sm\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .sm\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .sm\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .sm\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .sm\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .sm\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .sm\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .sm\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .sm\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .sm\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .sm\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .sm\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .sm\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .sm\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .sm\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .sm\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .sm\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .sm\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .sm\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .sm\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .sm\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .sm\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .sm\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .sm\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .sm\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .sm\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .sm\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .sm\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .sm\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .sm\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .sm\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .sm\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .sm\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .sm\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .sm\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .sm\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .sm\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .sm\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .sm\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .sm\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .sm\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .sm\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .sm\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .sm\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .sm\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .sm\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .sm\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .sm\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .sm\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .sm\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .sm\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .sm\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .sm\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .sm\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .sm\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .sm\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .sm\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .sm\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .sm\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .sm\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .sm\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .sm\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .sm\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .sm\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .sm\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .sm\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .sm\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .sm\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .sm\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .sm\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .sm\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .sm\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .sm\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .sm\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .sm\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .sm\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .sm\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .sm\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .sm\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .sm\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .sm\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .sm\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .sm\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .sm\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .sm\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .sm\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .sm\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .sm\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .sm\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .sm\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .sm\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .sm\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .sm\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .sm\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .sm\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .sm\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .sm\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .sm\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .sm\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .sm\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .sm\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .sm\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .sm\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .sm\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .sm\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .sm\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .sm\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .sm\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .sm\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .sm\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .sm\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .sm\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .sm\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .sm\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .sm\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .sm\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .sm\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .sm\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .sm\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .sm\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .sm\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .sm\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .sm\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .sm\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .sm\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .sm\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .sm\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .sm\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .sm\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .sm\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .sm\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .sm\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .sm\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .sm\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .sm\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .sm\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .sm\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .sm\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .sm\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .sm\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .sm\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .sm\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .sm\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .sm\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .sm\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .sm\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .sm\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .sm\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .sm\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .sm\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .sm\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .sm\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .sm\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .sm\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .sm\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .sm\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .sm\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .sm\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .sm\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .sm\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .sm\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .sm\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .sm\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .sm\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .sm\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .sm\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .sm\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .sm\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .sm\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .sm\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .sm\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .sm\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .sm\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .sm\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .sm\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .sm\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .sm\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .sm\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .sm\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .sm\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .sm\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .sm\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .sm\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .sm\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .sm\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .sm\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .sm\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .sm\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .sm\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .sm\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .sm\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .sm\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .sm\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .sm\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .sm\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .sm\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .sm\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .sm\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .sm\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .sm\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .sm\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .sm\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .sm\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .sm\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .sm\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .sm\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .sm\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .sm\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .sm\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .sm\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .sm\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .sm\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .sm\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .sm\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .sm\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .sm\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .sm\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .sm\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .sm\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .sm\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .sm\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .sm\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .sm\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .sm\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .sm\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .sm\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .sm\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .sm\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .sm\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .sm\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .sm\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .sm\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .sm\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .sm\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .sm\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .sm\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .sm\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .sm\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .sm\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .sm\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .sm\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .sm\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .sm\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .sm\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .sm\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .sm\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .sm\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .sm\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .sm\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .sm\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .sm\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .sm\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .sm\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .sm\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .sm\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .sm\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .sm\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .sm\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .sm\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .sm\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .sm\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .sm\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .sm\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .sm\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .sm\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .sm\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .sm\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .sm\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .sm\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .sm\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .sm\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .sm\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .sm\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .sm\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .sm\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .sm\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .sm\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .sm\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .sm\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .sm\:animate-none {
    animation: none;
  }

  .sm\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .sm\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .sm\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .sm\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .sm\:cursor-auto {
    cursor: auto;
  }

  .sm\:cursor-default {
    cursor: default;
  }

  .sm\:cursor-pointer {
    cursor: pointer;
  }

  .sm\:cursor-wait {
    cursor: wait;
  }

  .sm\:cursor-text {
    cursor: text;
  }

  .sm\:cursor-move {
    cursor: move;
  }

  .sm\:cursor-help {
    cursor: help;
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .sm\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .sm\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .sm\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .sm\:resize-none {
    resize: none;
  }

  .sm\:resize-y {
    resize: vertical;
  }

  .sm\:resize-x {
    resize: horizontal;
  }

  .sm\:resize {
    resize: both;
  }

  .sm\:list-inside {
    list-style-position: inside;
  }

  .sm\:list-outside {
    list-style-position: outside;
  }

  .sm\:list-none {
    list-style-type: none;
  }

  .sm\:list-disc {
    list-style-type: disc;
  }

  .sm\:list-decimal {
    list-style-type: decimal;
  }

  .sm\:appearance-none {
    appearance: none;
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .sm\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .sm\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:place-content-center {
    place-content: center;
  }

  .sm\:place-content-start {
    place-content: start;
  }

  .sm\:place-content-end {
    place-content: end;
  }

  .sm\:place-content-between {
    place-content: space-between;
  }

  .sm\:place-content-around {
    place-content: space-around;
  }

  .sm\:place-content-evenly {
    place-content: space-evenly;
  }

  .sm\:place-content-stretch {
    place-content: stretch;
  }

  .sm\:place-items-start {
    place-items: start;
  }

  .sm\:place-items-end {
    place-items: end;
  }

  .sm\:place-items-center {
    place-items: center;
  }

  .sm\:place-items-stretch {
    place-items: stretch stretch;
  }

  .sm\:content-center {
    align-content: center;
  }

  .sm\:content-start {
    align-content: flex-start;
  }

  .sm\:content-end {
    align-content: flex-end;
  }

  .sm\:content-between {
    align-content: space-between;
  }

  .sm\:content-around {
    align-content: space-around;
  }

  .sm\:content-evenly {
    align-content: space-evenly;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:items-baseline {
    align-items: baseline;
  }

  .sm\:items-stretch {
    align-items: stretch;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:justify-items-start {
    justify-items: start;
  }

  .sm\:justify-items-end {
    justify-items: end;
  }

  .sm\:justify-items-center {
    justify-items: center;
  }

  .sm\:justify-items-stretch {
    justify-items: stretch;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-1 {
    gap: .25rem;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:gap-3 {
    gap: .75rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-5 {
    gap: 1.25rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-7 {
    gap: 1.75rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-9 {
    gap: 2.25rem;
  }

  .sm\:gap-10 {
    gap: 2.5rem;
  }

  .sm\:gap-11 {
    gap: 2.75rem;
  }

  .sm\:gap-12 {
    gap: 3rem;
  }

  .sm\:gap-14 {
    gap: 3.5rem;
  }

  .sm\:gap-16 {
    gap: 4rem;
  }

  .sm\:gap-20 {
    gap: 5rem;
  }

  .sm\:gap-24 {
    gap: 6rem;
  }

  .sm\:gap-28 {
    gap: 7rem;
  }

  .sm\:gap-32 {
    gap: 8rem;
  }

  .sm\:gap-36 {
    gap: 9rem;
  }

  .sm\:gap-40 {
    gap: 10rem;
  }

  .sm\:gap-44 {
    gap: 11rem;
  }

  .sm\:gap-48 {
    gap: 12rem;
  }

  .sm\:gap-52 {
    gap: 13rem;
  }

  .sm\:gap-56 {
    gap: 14rem;
  }

  .sm\:gap-60 {
    gap: 15rem;
  }

  .sm\:gap-64 {
    gap: 16rem;
  }

  .sm\:gap-72 {
    gap: 18rem;
  }

  .sm\:gap-80 {
    gap: 20rem;
  }

  .sm\:gap-96 {
    gap: 24rem;
  }

  .sm\:gap-px {
    gap: 1px;
  }

  .sm\:gap-0\.5 {
    gap: .125rem;
  }

  .sm\:gap-1\.5 {
    gap: .375rem;
  }

  .sm\:gap-2\.5 {
    gap: .625rem;
  }

  .sm\:gap-3\.5 {
    gap: .875rem;
  }

  .sm\:gap-x-0 {
    column-gap: 0;
  }

  .sm\:gap-x-1 {
    column-gap: .25rem;
  }

  .sm\:gap-x-2 {
    column-gap: .5rem;
  }

  .sm\:gap-x-3 {
    column-gap: .75rem;
  }

  .sm\:gap-x-4 {
    column-gap: 1rem;
  }

  .sm\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .sm\:gap-x-8 {
    column-gap: 2rem;
  }

  .sm\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .sm\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .sm\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .sm\:gap-x-12 {
    column-gap: 3rem;
  }

  .sm\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .sm\:gap-x-16 {
    column-gap: 4rem;
  }

  .sm\:gap-x-20 {
    column-gap: 5rem;
  }

  .sm\:gap-x-24 {
    column-gap: 6rem;
  }

  .sm\:gap-x-28 {
    column-gap: 7rem;
  }

  .sm\:gap-x-32 {
    column-gap: 8rem;
  }

  .sm\:gap-x-36 {
    column-gap: 9rem;
  }

  .sm\:gap-x-40 {
    column-gap: 10rem;
  }

  .sm\:gap-x-44 {
    column-gap: 11rem;
  }

  .sm\:gap-x-48 {
    column-gap: 12rem;
  }

  .sm\:gap-x-52 {
    column-gap: 13rem;
  }

  .sm\:gap-x-56 {
    column-gap: 14rem;
  }

  .sm\:gap-x-60 {
    column-gap: 15rem;
  }

  .sm\:gap-x-64 {
    column-gap: 16rem;
  }

  .sm\:gap-x-72 {
    column-gap: 18rem;
  }

  .sm\:gap-x-80 {
    column-gap: 20rem;
  }

  .sm\:gap-x-96 {
    column-gap: 24rem;
  }

  .sm\:gap-x-px {
    column-gap: 1px;
  }

  .sm\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .sm\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .sm\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .sm\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .sm\:gap-y-0 {
    row-gap: 0;
  }

  .sm\:gap-y-1 {
    row-gap: .25rem;
  }

  .sm\:gap-y-2 {
    row-gap: .5rem;
  }

  .sm\:gap-y-3 {
    row-gap: .75rem;
  }

  .sm\:gap-y-4 {
    row-gap: 1rem;
  }

  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .sm\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .sm\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .sm\:gap-y-8 {
    row-gap: 2rem;
  }

  .sm\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .sm\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .sm\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .sm\:gap-y-12 {
    row-gap: 3rem;
  }

  .sm\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:gap-y-20 {
    row-gap: 5rem;
  }

  .sm\:gap-y-24 {
    row-gap: 6rem;
  }

  .sm\:gap-y-28 {
    row-gap: 7rem;
  }

  .sm\:gap-y-32 {
    row-gap: 8rem;
  }

  .sm\:gap-y-36 {
    row-gap: 9rem;
  }

  .sm\:gap-y-40 {
    row-gap: 10rem;
  }

  .sm\:gap-y-44 {
    row-gap: 11rem;
  }

  .sm\:gap-y-48 {
    row-gap: 12rem;
  }

  .sm\:gap-y-52 {
    row-gap: 13rem;
  }

  .sm\:gap-y-56 {
    row-gap: 14rem;
  }

  .sm\:gap-y-60 {
    row-gap: 15rem;
  }

  .sm\:gap-y-64 {
    row-gap: 16rem;
  }

  .sm\:gap-y-72 {
    row-gap: 18rem;
  }

  .sm\:gap-y-80 {
    row-gap: 20rem;
  }

  .sm\:gap-y-96 {
    row-gap: 24rem;
  }

  .sm\:gap-y-px {
    row-gap: 1px;
  }

  .sm\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .sm\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .sm\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .sm\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .sm\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .sm\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .sm\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .sm\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .sm\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .sm\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .sm\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .sm\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .sm\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .sm\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .sm\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .sm\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .sm\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .sm\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .sm\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .sm\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .sm\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .sm\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .sm\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .sm\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .sm\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .sm\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .sm\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .sm\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .sm\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .sm\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .sm\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .sm\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .sm\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .sm\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .sm\:place-self-auto {
    place-self: auto;
  }

  .sm\:place-self-start {
    place-self: start;
  }

  .sm\:place-self-end {
    place-self: end;
  }

  .sm\:place-self-center {
    place-self: center;
  }

  .sm\:place-self-stretch {
    place-self: stretch stretch;
  }

  .sm\:self-auto {
    align-self: auto;
  }

  .sm\:self-start {
    align-self: flex-start;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:self-stretch {
    align-self: stretch;
  }

  .sm\:self-baseline {
    align-self: baseline;
  }

  .sm\:justify-self-auto {
    justify-self: auto;
  }

  .sm\:justify-self-start {
    justify-self: start;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:justify-self-center {
    justify-self: center;
  }

  .sm\:justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:overflow-visible {
    overflow: visible;
  }

  .sm\:overflow-scroll {
    overflow: scroll;
  }

  .sm\:overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .sm\:overscroll-none {
    overscroll-behavior: none;
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .sm\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .sm\:overflow-clip {
    text-overflow: clip;
  }

  .sm\:whitespace-normal {
    white-space: normal;
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:whitespace-pre {
    white-space: pre;
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:break-words {
    overflow-wrap: break-word;
  }

  .sm\:break-all {
    word-break: break-all;
  }

  .sm\:rounded-none {
    border-radius: 0;
  }

  .sm\:rounded-sm {
    border-radius: .125rem;
  }

  .sm\:rounded {
    border-radius: .25rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:rounded-full {
    border-radius: 9999px;
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .sm\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .sm\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .sm\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .sm\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:border-0 {
    border-width: 0;
  }

  .sm\:border-2 {
    border-width: 2px;
  }

  .sm\:border-4 {
    border-width: 4px;
  }

  .sm\:border-8 {
    border-width: 8px;
  }

  .sm\:border {
    border-width: 1px;
  }

  .sm\:border-t-0 {
    border-top-width: 0;
  }

  .sm\:border-t-2 {
    border-top-width: 2px;
  }

  .sm\:border-t-4 {
    border-top-width: 4px;
  }

  .sm\:border-t-8 {
    border-top-width: 8px;
  }

  .sm\:border-t {
    border-top-width: 1px;
  }

  .sm\:border-r-0 {
    border-right-width: 0;
  }

  .sm\:border-r-2 {
    border-right-width: 2px;
  }

  .sm\:border-r-4 {
    border-right-width: 4px;
  }

  .sm\:border-r-8 {
    border-right-width: 8px;
  }

  .sm\:border-r {
    border-right-width: 1px;
  }

  .sm\:border-b-0 {
    border-bottom-width: 0;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:border-b {
    border-bottom-width: 1px;
  }

  .sm\:border-l-0 {
    border-left-width: 0;
  }

  .sm\:border-l-2 {
    border-left-width: 2px;
  }

  .sm\:border-l-4 {
    border-left-width: 4px;
  }

  .sm\:border-l-8 {
    border-left-width: 8px;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:border-solid {
    border-style: solid;
  }

  .sm\:border-dashed {
    border-style: dashed;
  }

  .sm\:border-dotted {
    border-style: dotted;
  }

  .sm\:border-double {
    border-style: double;
  }

  .sm\:border-none {
    border-style: none;
  }

  .sm\:border-transparent {
    border-color: #0000;
  }

  .sm\:border-current {
    border-color: currentColor;
  }

  .sm\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .sm\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .sm\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .sm\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .sm\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .sm\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .sm\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .sm\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .sm\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .sm\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .sm\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .sm\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .sm\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .sm\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .sm\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .sm\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .sm\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .sm\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .sm\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .sm\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .sm\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .sm\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .sm\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .sm\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .sm\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .sm\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .sm\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .sm\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .sm\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .sm\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .sm\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .sm\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .sm\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .sm\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .sm\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .sm\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .sm\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .sm\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .sm\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .sm\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .sm\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .sm\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .sm\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .sm\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .sm\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .sm\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .sm\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .sm\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .sm\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .sm\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .sm\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .sm\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .sm\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .sm\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .sm\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .sm\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .sm\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .sm\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .sm\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .sm\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .sm\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .sm\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .sm\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .sm\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .sm\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .sm\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .sm\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .sm\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .sm\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .sm\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .sm\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .sm\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .sm\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .sm\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .sm\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .sm\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .sm\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .sm\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .sm\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .sm\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .sm\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .sm\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .sm\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .sm\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .sm\:bg-transparent {
    background-color: #0000;
  }

  .sm\:bg-current {
    background-color: currentColor;
  }

  .sm\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .sm\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .sm\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .sm\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .sm\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .sm\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .sm\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .sm\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .sm\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .sm\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .sm\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .sm\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .sm\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .sm\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .sm\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .sm\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .sm\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .sm\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .sm\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .sm\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .sm\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .sm\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .sm\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .sm\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .sm\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .sm\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .sm\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .sm\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .sm\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .sm\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .sm\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .sm\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .sm\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .sm\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .sm\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .sm\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .sm\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .sm\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .sm\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .sm\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .sm\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .sm\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .sm\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .sm\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .sm\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .sm\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .sm\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .sm\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .sm\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .sm\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .sm\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .sm\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .sm\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .sm\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .sm\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .sm\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .sm\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .sm\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .sm\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .sm\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .sm\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .sm\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .sm\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .sm\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .sm\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .sm\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .sm\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .sm\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .sm\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .sm\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .sm\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .sm\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .sm\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .sm\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .sm\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .sm\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .sm\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .sm\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .sm\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .sm\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .sm\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .sm\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .sm\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .sm\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .sm\:bg-none {
    background-image: none;
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .sm\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .sm\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .sm\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .sm\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .sm\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .sm\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .sm\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .sm\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .sm\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .sm\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .sm\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .sm\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .sm\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .sm\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .sm\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .sm\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .sm\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .sm\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .sm\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .sm\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .sm\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .sm\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .sm\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .sm\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .sm\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .sm\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .sm\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .sm\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .sm\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .sm\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .sm\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .sm\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .sm\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .sm\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .sm\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .sm\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .sm\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .sm\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .sm\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .sm\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .sm\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .sm\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .sm\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .sm\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .sm\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .sm\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .sm\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .sm\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .sm\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .sm\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .sm\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .sm\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .sm\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .sm\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .sm\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .sm\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .sm\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .sm\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .sm\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .sm\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .sm\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .sm\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .sm\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .sm\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .sm\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .sm\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .sm\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .sm\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .sm\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .sm\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .sm\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .sm\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .sm\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .sm\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .sm\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .sm\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .sm\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .sm\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .sm\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .sm\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .sm\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .sm\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .sm\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .sm\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .sm\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .sm\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .sm\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .sm\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .sm\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .sm\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .sm\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .sm\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .sm\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .sm\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .sm\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .sm\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .sm\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .sm\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .sm\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .sm\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .sm\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .sm\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .sm\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .sm\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .sm\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .sm\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .sm\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .sm\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .sm\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .sm\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .sm\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .sm\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .sm\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .sm\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .sm\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .sm\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .sm\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .sm\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .sm\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .sm\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .sm\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .sm\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .sm\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .sm\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .sm\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .sm\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .sm\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .sm\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .sm\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .sm\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .sm\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .sm\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .sm\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .sm\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .sm\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .sm\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .sm\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .sm\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .sm\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .sm\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .sm\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .sm\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .sm\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .sm\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .sm\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .sm\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .sm\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .sm\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .sm\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .sm\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .sm\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .sm\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .sm\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .sm\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .sm\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .sm\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .sm\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .sm\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .sm\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .sm\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .sm\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .sm\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .sm\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .sm\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .sm\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .sm\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .sm\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .sm\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .sm\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .sm\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .sm\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .sm\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .sm\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .sm\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .sm\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .sm\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .sm\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .sm\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .sm\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .sm\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .sm\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .sm\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .sm\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .sm\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .sm\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .sm\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .sm\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .sm\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .sm\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .sm\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .sm\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .sm\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .sm\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .sm\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .sm\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .sm\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .sm\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .sm\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .sm\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .sm\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .sm\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .sm\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .sm\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .sm\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .sm\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .sm\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .sm\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .sm\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .sm\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .sm\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .sm\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .sm\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .sm\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .sm\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .sm\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .sm\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .sm\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .sm\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .sm\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .sm\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .sm\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .sm\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .sm\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .sm\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .sm\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .sm\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .sm\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .sm\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .sm\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .sm\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .sm\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .sm\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .sm\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .sm\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .sm\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .sm\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .sm\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .sm\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .sm\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .sm\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .sm\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .sm\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .sm\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .sm\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .sm\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .sm\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .sm\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .sm\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .sm\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .sm\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .sm\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .sm\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .sm\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .sm\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .sm\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .sm\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .sm\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .sm\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .sm\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .sm\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .sm\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .sm\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .sm\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .sm\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .sm\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .sm\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .sm\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .sm\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .sm\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .sm\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .sm\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .sm\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .sm\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .sm\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .sm\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .sm\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .sm\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .sm\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .sm\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .sm\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .sm\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .sm\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .sm\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .sm\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .sm\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .sm\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .sm\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .sm\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .sm\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .sm\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .sm\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .sm\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .sm\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .sm\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .sm\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .sm\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .sm\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .sm\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .sm\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .sm\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .sm\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .sm\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .sm\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .sm\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .sm\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .sm\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .sm\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .sm\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .sm\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .sm\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .sm\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .sm\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .sm\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .sm\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .sm\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .sm\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .sm\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .sm\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .sm\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .sm\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .sm\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .sm\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .sm\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .sm\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .sm\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .sm\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .sm\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .sm\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .sm\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .sm\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .sm\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .sm\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .sm\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .sm\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .sm\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .sm\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .sm\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .sm\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .sm\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .sm\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .sm\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .sm\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .sm\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .sm\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .sm\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .sm\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .sm\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .sm\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .sm\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .sm\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .sm\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .sm\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .sm\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .sm\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .sm\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .sm\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .sm\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .sm\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .sm\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .sm\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .sm\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .sm\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .sm\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .sm\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .sm\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .sm\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .sm\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .sm\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .sm\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .sm\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .sm\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .sm\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .sm\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .sm\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .sm\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .sm\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .sm\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .sm\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .sm\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .sm\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .sm\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .sm\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .sm\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .sm\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .sm\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .sm\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .sm\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .sm\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .sm\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .sm\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .sm\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .sm\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .sm\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .sm\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .sm\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .sm\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .sm\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .sm\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .sm\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .sm\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .sm\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .sm\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .sm\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .sm\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .sm\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .sm\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .sm\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .sm\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .sm\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .sm\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .sm\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .sm\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .sm\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .sm\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .sm\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .sm\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .sm\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .sm\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .sm\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .sm\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .sm\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .sm\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .sm\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .sm\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .sm\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .sm\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .sm\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .sm\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .sm\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .sm\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .sm\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .sm\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .sm\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .sm\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .sm\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .sm\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .sm\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .sm\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .sm\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .sm\:to-current {
    --tw-gradient-to: currentColor;
  }

  .sm\:to-black {
    --tw-gradient-to: #000;
  }

  .sm\:to-white {
    --tw-gradient-to: #fff;
  }

  .sm\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .sm\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .sm\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .sm\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .sm\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .sm\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .sm\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .sm\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .sm\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .sm\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .sm\:to-red {
    --tw-gradient-to: red;
  }

  .sm\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .sm\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .sm\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .sm\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .sm\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .sm\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .sm\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .sm\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .sm\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .sm\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .sm\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .sm\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .sm\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .sm\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .sm\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .sm\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .sm\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .sm\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .sm\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .sm\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .sm\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .sm\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .sm\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .sm\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .sm\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .sm\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .sm\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .sm\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .sm\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .sm\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .sm\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .sm\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .sm\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .sm\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .sm\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .sm\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .sm\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .sm\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .sm\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .sm\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .sm\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .sm\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .sm\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .sm\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .sm\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .sm\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .sm\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .sm\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .sm\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .sm\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .sm\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .sm\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .sm\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .sm\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .sm\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .sm\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .sm\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .sm\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .sm\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .sm\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .sm\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .sm\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .sm\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .sm\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .sm\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .sm\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .sm\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .sm\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .sm\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .sm\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .sm\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .sm\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .sm\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .sm\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .sm\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .sm\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .sm\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .sm\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .sm\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .sm\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .sm\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .sm\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .sm\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .sm\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .sm\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .sm\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .sm\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .sm\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .sm\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .sm\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .sm\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .sm\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .sm\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .sm\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .sm\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .sm\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .sm\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .sm\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .sm\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .sm\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .sm\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .sm\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .sm\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .sm\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .sm\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .sm\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .sm\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .sm\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .sm\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .sm\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .sm\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .sm\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .sm\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .sm\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .sm\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .sm\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .sm\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .sm\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .sm\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .sm\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .sm\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .sm\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .sm\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .sm\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .sm\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .sm\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .sm\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .sm\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .sm\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .sm\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .sm\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .sm\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .sm\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .sm\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .sm\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .sm\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .sm\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .sm\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .sm\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .sm\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .sm\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .sm\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .sm\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .sm\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .sm\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .sm\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .sm\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .sm\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .sm\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .sm\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .sm\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .sm\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .sm\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .sm\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .sm\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .sm\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .sm\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .sm\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .sm\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .sm\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .sm\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .sm\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .sm\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .sm\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .sm\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .sm\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .sm\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .sm\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .sm\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .sm\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .sm\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .sm\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .sm\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .sm\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .sm\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .sm\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .sm\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .sm\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .sm\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .sm\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .sm\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .sm\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .sm\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .sm\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .sm\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .sm\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .sm\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .sm\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .sm\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .sm\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .sm\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .sm\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .sm\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .sm\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .sm\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .sm\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .sm\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .sm\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .sm\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .sm\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .sm\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .sm\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .sm\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .sm\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .sm\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .sm\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .sm\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .sm\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .sm\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .sm\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .sm\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .sm\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .sm\:bg-auto {
    background-size: auto;
  }

  .sm\:bg-cover {
    background-size: cover;
  }

  .sm\:bg-contain {
    background-size: contain;
  }

  .sm\:bg-fixed {
    background-attachment: fixed;
  }

  .sm\:bg-local {
    background-attachment: local;
  }

  .sm\:bg-scroll {
    background-attachment: scroll;
  }

  .sm\:bg-clip-border {
    background-clip: border-box;
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box;
  }

  .sm\:bg-clip-content {
    background-clip: content-box;
  }

  .sm\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .sm\:bg-bottom {
    background-position: bottom;
  }

  .sm\:bg-center {
    background-position: center;
  }

  .sm\:bg-left {
    background-position: 0;
  }

  .sm\:bg-left-bottom {
    background-position: 0 100%;
  }

  .sm\:bg-left-top {
    background-position: 0 0;
  }

  .sm\:bg-right {
    background-position: 100%;
  }

  .sm\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .sm\:bg-right-top {
    background-position: 100% 0;
  }

  .sm\:bg-top {
    background-position: top;
  }

  .sm\:bg-repeat {
    background-repeat: repeat;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:bg-repeat-round {
    background-repeat: round;
  }

  .sm\:bg-repeat-space {
    background-repeat: space;
  }

  .sm\:bg-origin-border {
    background-origin: border-box;
  }

  .sm\:bg-origin-padding {
    background-origin: padding-box;
  }

  .sm\:bg-origin-content {
    background-origin: content-box;
  }

  .sm\:fill-current {
    fill: currentColor;
  }

  .sm\:stroke-current {
    stroke: currentColor;
  }

  .sm\:stroke-0 {
    stroke-width: 0;
  }

  .sm\:stroke-1 {
    stroke-width: 1px;
  }

  .sm\:stroke-2 {
    stroke-width: 2px;
  }

  .sm\:object-contain {
    object-fit: contain;
  }

  .sm\:object-cover {
    object-fit: cover;
  }

  .sm\:object-fill {
    object-fit: fill;
  }

  .sm\:object-none {
    object-fit: none;
  }

  .sm\:object-scale-down {
    object-fit: scale-down;
  }

  .sm\:object-bottom {
    object-position: bottom;
  }

  .sm\:object-center {
    object-position: center;
  }

  .sm\:object-left {
    object-position: left;
  }

  .sm\:object-left-bottom {
    object-position: left bottom;
  }

  .sm\:object-left-top {
    object-position: left top;
  }

  .sm\:object-right {
    object-position: right;
  }

  .sm\:object-right-bottom {
    object-position: right bottom;
  }

  .sm\:object-right-top {
    object-position: right top;
  }

  .sm\:object-top {
    object-position: top;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-1 {
    padding: .25rem;
  }

  .sm\:p-2 {
    padding: .5rem;
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-7 {
    padding: 1.75rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-9 {
    padding: 2.25rem;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:p-11 {
    padding: 2.75rem;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:p-14 {
    padding: 3.5rem;
  }

  .sm\:p-16 {
    padding: 4rem;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:p-24 {
    padding: 6rem;
  }

  .sm\:p-28 {
    padding: 7rem;
  }

  .sm\:p-32 {
    padding: 8rem;
  }

  .sm\:p-36 {
    padding: 9rem;
  }

  .sm\:p-40 {
    padding: 10rem;
  }

  .sm\:p-44 {
    padding: 11rem;
  }

  .sm\:p-48 {
    padding: 12rem;
  }

  .sm\:p-52 {
    padding: 13rem;
  }

  .sm\:p-56 {
    padding: 14rem;
  }

  .sm\:p-60 {
    padding: 15rem;
  }

  .sm\:p-64 {
    padding: 16rem;
  }

  .sm\:p-72 {
    padding: 18rem;
  }

  .sm\:p-80 {
    padding: 20rem;
  }

  .sm\:p-96 {
    padding: 24rem;
  }

  .sm\:p-px {
    padding: 1px;
  }

  .sm\:p-0\.5 {
    padding: .125rem;
  }

  .sm\:p-1\.5 {
    padding: .375rem;
  }

  .sm\:p-2\.5 {
    padding: .625rem;
  }

  .sm\:p-3\.5 {
    padding: .875rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .sm\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .sm\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .sm\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .sm\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .sm\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .sm\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .sm\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .sm\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .sm\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .sm\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pt-1 {
    padding-top: .25rem;
  }

  .sm\:pt-2 {
    padding-top: .5rem;
  }

  .sm\:pt-3 {
    padding-top: .75rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pt-7 {
    padding-top: 1.75rem;
  }

  .sm\:pt-8 {
    padding-top: 2rem;
  }

  .sm\:pt-9 {
    padding-top: 2.25rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-11 {
    padding-top: 2.75rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }

  .sm\:pt-14 {
    padding-top: 3.5rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pt-28 {
    padding-top: 7rem;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:pt-36 {
    padding-top: 9rem;
  }

  .sm\:pt-40 {
    padding-top: 10rem;
  }

  .sm\:pt-44 {
    padding-top: 11rem;
  }

  .sm\:pt-48 {
    padding-top: 12rem;
  }

  .sm\:pt-52 {
    padding-top: 13rem;
  }

  .sm\:pt-56 {
    padding-top: 14rem;
  }

  .sm\:pt-60 {
    padding-top: 15rem;
  }

  .sm\:pt-64 {
    padding-top: 16rem;
  }

  .sm\:pt-72 {
    padding-top: 18rem;
  }

  .sm\:pt-80 {
    padding-top: 20rem;
  }

  .sm\:pt-96 {
    padding-top: 24rem;
  }

  .sm\:pt-px {
    padding-top: 1px;
  }

  .sm\:pt-0\.5 {
    padding-top: .125rem;
  }

  .sm\:pt-1\.5 {
    padding-top: .375rem;
  }

  .sm\:pt-2\.5 {
    padding-top: .625rem;
  }

  .sm\:pt-3\.5 {
    padding-top: .875rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pr-1 {
    padding-right: .25rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }

  .sm\:pr-3 {
    padding-right: .75rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:pr-7 {
    padding-right: 1.75rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:pr-9 {
    padding-right: 2.25rem;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:pr-11 {
    padding-right: 2.75rem;
  }

  .sm\:pr-12 {
    padding-right: 3rem;
  }

  .sm\:pr-14 {
    padding-right: 3.5rem;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pr-20 {
    padding-right: 5rem;
  }

  .sm\:pr-24 {
    padding-right: 6rem;
  }

  .sm\:pr-28 {
    padding-right: 7rem;
  }

  .sm\:pr-32 {
    padding-right: 8rem;
  }

  .sm\:pr-36 {
    padding-right: 9rem;
  }

  .sm\:pr-40 {
    padding-right: 10rem;
  }

  .sm\:pr-44 {
    padding-right: 11rem;
  }

  .sm\:pr-48 {
    padding-right: 12rem;
  }

  .sm\:pr-52 {
    padding-right: 13rem;
  }

  .sm\:pr-56 {
    padding-right: 14rem;
  }

  .sm\:pr-60 {
    padding-right: 15rem;
  }

  .sm\:pr-64 {
    padding-right: 16rem;
  }

  .sm\:pr-72 {
    padding-right: 18rem;
  }

  .sm\:pr-80 {
    padding-right: 20rem;
  }

  .sm\:pr-96 {
    padding-right: 24rem;
  }

  .sm\:pr-px {
    padding-right: 1px;
  }

  .sm\:pr-0\.5 {
    padding-right: .125rem;
  }

  .sm\:pr-1\.5 {
    padding-right: .375rem;
  }

  .sm\:pr-2\.5 {
    padding-right: .625rem;
  }

  .sm\:pr-3\.5 {
    padding-right: .875rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pb-1 {
    padding-bottom: .25rem;
  }

  .sm\:pb-2 {
    padding-bottom: .5rem;
  }

  .sm\:pb-3 {
    padding-bottom: .75rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:pb-36 {
    padding-bottom: 9rem;
  }

  .sm\:pb-40 {
    padding-bottom: 10rem;
  }

  .sm\:pb-44 {
    padding-bottom: 11rem;
  }

  .sm\:pb-48 {
    padding-bottom: 12rem;
  }

  .sm\:pb-52 {
    padding-bottom: 13rem;
  }

  .sm\:pb-56 {
    padding-bottom: 14rem;
  }

  .sm\:pb-60 {
    padding-bottom: 15rem;
  }

  .sm\:pb-64 {
    padding-bottom: 16rem;
  }

  .sm\:pb-72 {
    padding-bottom: 18rem;
  }

  .sm\:pb-80 {
    padding-bottom: 20rem;
  }

  .sm\:pb-96 {
    padding-bottom: 24rem;
  }

  .sm\:pb-px {
    padding-bottom: 1px;
  }

  .sm\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .sm\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .sm\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .sm\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pl-1 {
    padding-left: .25rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:pl-3 {
    padding-left: .75rem;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pl-7 {
    padding-left: 1.75rem;
  }

  .sm\:pl-8 {
    padding-left: 2rem;
  }

  .sm\:pl-9 {
    padding-left: 2.25rem;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:pl-11 {
    padding-left: 2.75rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:pl-14 {
    padding-left: 3.5rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pl-20 {
    padding-left: 5rem;
  }

  .sm\:pl-24 {
    padding-left: 6rem;
  }

  .sm\:pl-28 {
    padding-left: 7rem;
  }

  .sm\:pl-32 {
    padding-left: 8rem;
  }

  .sm\:pl-36 {
    padding-left: 9rem;
  }

  .sm\:pl-40 {
    padding-left: 10rem;
  }

  .sm\:pl-44 {
    padding-left: 11rem;
  }

  .sm\:pl-48 {
    padding-left: 12rem;
  }

  .sm\:pl-52 {
    padding-left: 13rem;
  }

  .sm\:pl-56 {
    padding-left: 14rem;
  }

  .sm\:pl-60 {
    padding-left: 15rem;
  }

  .sm\:pl-64 {
    padding-left: 16rem;
  }

  .sm\:pl-72 {
    padding-left: 18rem;
  }

  .sm\:pl-80 {
    padding-left: 20rem;
  }

  .sm\:pl-96 {
    padding-left: 24rem;
  }

  .sm\:pl-px {
    padding-left: 1px;
  }

  .sm\:pl-0\.5 {
    padding-left: .125rem;
  }

  .sm\:pl-1\.5 {
    padding-left: .375rem;
  }

  .sm\:pl-2\.5 {
    padding-left: .625rem;
  }

  .sm\:pl-3\.5 {
    padding-left: .875rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-justify {
    text-align: justify;
  }

  .sm\:align-baseline {
    vertical-align: baseline;
  }

  .sm\:align-top {
    vertical-align: top;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:align-bottom {
    vertical-align: bottom;
  }

  .sm\:align-text-top {
    vertical-align: text-top;
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:font-body {
    font-family: Montserrat;
  }

  .sm\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .sm\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .sm\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .sm\:font-thin {
    font-weight: 100;
  }

  .sm\:font-extralight {
    font-weight: 200;
  }

  .sm\:font-light {
    font-weight: 300;
  }

  .sm\:font-normal {
    font-weight: 400;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:font-bold {
    font-weight: 700;
  }

  .sm\:font-extrabold {
    font-weight: 800;
  }

  .sm\:font-black {
    font-weight: 900;
  }

  .sm\:uppercase {
    text-transform: uppercase;
  }

  .sm\:lowercase {
    text-transform: lowercase;
  }

  .sm\:capitalize {
    text-transform: capitalize;
  }

  .sm\:normal-case {
    text-transform: none;
  }

  .sm\:italic {
    font-style: italic;
  }

  .sm\:not-italic {
    font-style: normal;
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:normal-nums {
    font-variant-numeric: normal;
  }

  .sm\:ordinal {
    --tw-ordinal: ordinal;
  }

  .sm\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .sm\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .sm\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .sm\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .sm\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .sm\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .sm\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .sm\:leading-3 {
    line-height: .75rem;
  }

  .sm\:leading-4 {
    line-height: 1rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:leading-7 {
    line-height: 1.75rem;
  }

  .sm\:leading-8 {
    line-height: 2rem;
  }

  .sm\:leading-9 {
    line-height: 2.25rem;
  }

  .sm\:leading-10 {
    line-height: 2.5rem;
  }

  .sm\:leading-none {
    line-height: 1;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }

  .sm\:leading-normal {
    line-height: 1.5;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }

  .sm\:leading-loose {
    line-height: 2;
  }

  .sm\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .sm\:tracking-normal {
    letter-spacing: 0;
  }

  .sm\:tracking-wide {
    letter-spacing: .025em;
  }

  .sm\:tracking-wider {
    letter-spacing: .05em;
  }

  .sm\:tracking-widest {
    letter-spacing: .1em;
  }

  .sm\:text-transparent {
    color: #0000;
  }

  .sm\:text-current {
    color: currentColor;
  }

  .sm\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .sm\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .sm\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .sm\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .sm\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .sm\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:hover\:text-transparent:hover {
    color: #0000;
  }

  .sm\:hover\:text-current:hover {
    color: currentColor;
  }

  .sm\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:focus\:text-transparent:focus {
    color: #0000;
  }

  .sm\:focus\:text-current:focus {
    color: currentColor;
  }

  .sm\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .sm\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .sm\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .sm\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .sm\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .sm\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .sm\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .sm\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .sm\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .sm\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .sm\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .sm\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .sm\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .sm\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .sm\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .sm\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .sm\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .sm\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .sm\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .sm\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .sm\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .sm\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .sm\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .sm\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .sm\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .sm\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .sm\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .sm\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .sm\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .sm\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .sm\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .sm\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .sm\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .sm\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .sm\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .sm\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .sm\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .sm\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .sm\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .sm\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .sm\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .sm\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .sm\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .sm\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .sm\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .sm\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .sm\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .sm\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .sm\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .sm\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .sm\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .sm\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .sm\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .sm\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .sm\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .sm\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .sm\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .sm\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .sm\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .sm\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .sm\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .sm\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .sm\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .sm\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .sm\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .sm\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .sm\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .sm\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .sm\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .sm\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .sm\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .sm\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .sm\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .sm\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .sm\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .sm\:underline {
    text-decoration: underline;
  }

  .sm\:line-through {
    text-decoration: line-through;
  }

  .sm\:no-underline {
    text-decoration: none;
  }

  .group:hover .sm\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .sm\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .sm\:group-hover\:no-underline {
    text-decoration: none;
  }

  .sm\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .sm\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .sm\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline;
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline;
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .sm\:placeholder-current::placeholder {
    color: currentColor;
  }

  .sm\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .sm\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .sm\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .sm\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .sm\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .sm\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .sm\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .sm\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .sm\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .sm\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .sm\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .sm\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .sm\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .sm\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .sm\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .sm\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .sm\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .sm\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .sm\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .sm\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .sm\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .sm\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .sm\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:opacity-0 {
    opacity: 0;
  }

  .sm\:opacity-5 {
    opacity: .05;
  }

  .sm\:opacity-10 {
    opacity: .1;
  }

  .sm\:opacity-20 {
    opacity: .2;
  }

  .sm\:opacity-25 {
    opacity: .25;
  }

  .sm\:opacity-30 {
    opacity: .3;
  }

  .sm\:opacity-40 {
    opacity: .4;
  }

  .sm\:opacity-50 {
    opacity: .5;
  }

  .sm\:opacity-60 {
    opacity: .6;
  }

  .sm\:opacity-70 {
    opacity: .7;
  }

  .sm\:opacity-75 {
    opacity: .75;
  }

  .sm\:opacity-80 {
    opacity: .8;
  }

  .sm\:opacity-90 {
    opacity: .9;
  }

  .sm\:opacity-95 {
    opacity: .95;
  }

  .sm\:opacity-100 {
    opacity: 1;
  }

  .group:hover .sm\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .sm\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .sm\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .sm\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .sm\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .sm\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .sm\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .sm\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .sm\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .sm\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .sm\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .sm\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .sm\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .sm\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .sm\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .sm\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .sm\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .sm\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .sm\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .sm\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .sm\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .sm\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .sm\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .sm\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .sm\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .sm\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .sm\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .sm\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .sm\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .sm\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .sm\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .sm\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .sm\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .sm\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .sm\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .sm\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .sm\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .sm\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .sm\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .sm\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .sm\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .sm\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .sm\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .sm\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .sm\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .sm\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .sm\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .sm\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .sm\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .sm\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .sm\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .sm\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .sm\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .sm\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .sm\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .sm\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .sm\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .sm\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .sm\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .sm\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .sm\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .sm\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .sm\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .sm\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .sm\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .sm\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .sm\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .sm\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .sm\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .sm\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .sm\:bg-blend-color {
    background-blend-mode: color;
  }

  .sm\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .sm\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .sm\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .sm\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .sm\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .sm\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .sm\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .sm\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .sm\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .sm\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .sm\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .sm\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .sm\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .sm\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .sm\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .sm\:mix-blend-color {
    mix-blend-mode: color;
  }

  .sm\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-inset, .sm\:focus-within\:ring-inset:focus-within, .sm\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .sm\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .sm\:ring-current {
    --tw-ring-color: currentColor;
  }

  .sm\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .sm\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .sm\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .sm\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .sm\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .sm\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .sm\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .sm\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .sm\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .sm\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .sm\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .sm\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .sm\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .sm\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .sm\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .sm\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .sm\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .sm\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .sm\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .sm\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .sm\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .sm\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .sm\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .sm\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .sm\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .sm\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .sm\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .sm\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .sm\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .sm\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .sm\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .sm\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .sm\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .sm\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .sm\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .sm\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .sm\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .sm\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .sm\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .sm\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .sm\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .sm\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .sm\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .sm\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .sm\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .sm\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .sm\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .sm\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .sm\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .sm\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .sm\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .sm\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .sm\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .sm\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .sm\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .sm\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .sm\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .sm\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .sm\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .sm\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .sm\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .sm\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .sm\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .sm\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .sm\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .sm\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .sm\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .sm\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .sm\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .sm\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .sm\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .sm\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .sm\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .sm\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .sm\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .sm\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .sm\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .sm\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .sm\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .sm\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .sm\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .sm\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .sm\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .sm\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .sm\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .sm\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .sm\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .sm\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .sm\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .sm\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .sm\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .sm\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .sm\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .sm\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .sm\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .sm\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .sm\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .sm\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .sm\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .sm\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .sm\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .sm\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .sm\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .sm\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .sm\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .sm\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .sm\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .sm\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .sm\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .sm\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .sm\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .sm\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .sm\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .sm\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .sm\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .sm\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .sm\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .sm\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .sm\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .sm\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .sm\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .sm\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .sm\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .sm\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .sm\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .sm\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .sm\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .sm\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .sm\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .sm\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .sm\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .sm\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .sm\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .sm\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .sm\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .sm\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .sm\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .sm\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .sm\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .sm\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .sm\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .sm\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .sm\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .sm\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .sm\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .sm\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .sm\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .sm\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .sm\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .sm\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .sm\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .sm\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .sm\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:filter-none {
    filter: none;
  }

  .sm\:blur-0, .sm\:blur-none {
    --tw-blur: blur(0);
  }

  .sm\:blur-sm {
    --tw-blur: blur(4px);
  }

  .sm\:blur {
    --tw-blur: blur(8px);
  }

  .sm\:blur-md {
    --tw-blur: blur(12px);
  }

  .sm\:blur-lg {
    --tw-blur: blur(16px);
  }

  .sm\:blur-xl {
    --tw-blur: blur(24px);
  }

  .sm\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .sm\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .sm\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .sm\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .sm\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .sm\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .sm\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .sm\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .sm\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .sm\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .sm\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .sm\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .sm\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .sm\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .sm\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .sm\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .sm\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .sm\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .sm\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .sm\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .sm\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .sm\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .sm\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .sm\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .sm\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .sm\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .sm\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .sm\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .sm\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .sm\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .sm\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .sm\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .sm\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .sm\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .sm\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .sm\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:invert-0 {
    --tw-invert: invert(0);
  }

  .sm\:invert {
    --tw-invert: invert(100%);
  }

  .sm\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .sm\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .sm\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .sm\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .sm\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .sm\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .sm\:sepia {
    --tw-sepia: sepia(100%);
  }

  .sm\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .sm\:backdrop-blur-0, .sm\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .sm\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .sm\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .sm\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .sm\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .sm\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .sm\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .sm\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .sm\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .sm\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .sm\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .sm\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .sm\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .sm\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .sm\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .sm\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .sm\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .sm\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .sm\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .sm\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .sm\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .sm\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .sm\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .sm\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .sm\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .sm\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .sm\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .sm\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .sm\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .sm\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .sm\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .sm\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .sm\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .sm\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .sm\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .sm\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .sm\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .sm\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .sm\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .sm\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .sm\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .sm\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .sm\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .sm\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .sm\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .sm\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .sm\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .sm\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .sm\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .sm\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .sm\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .sm\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .sm\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .sm\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .sm\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .sm\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .sm\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .sm\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .sm\:transition-none {
    transition-property: none;
  }

  .sm\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:delay-75 {
    transition-delay: 75ms;
  }

  .sm\:delay-100 {
    transition-delay: .1s;
  }

  .sm\:delay-150 {
    transition-delay: .15s;
  }

  .sm\:delay-200 {
    transition-delay: .2s;
  }

  .sm\:delay-300 {
    transition-delay: .3s;
  }

  .sm\:delay-500 {
    transition-delay: .5s;
  }

  .sm\:delay-700 {
    transition-delay: .7s;
  }

  .sm\:delay-1000 {
    transition-delay: 1s;
  }

  .sm\:duration-75 {
    transition-duration: 75ms;
  }

  .sm\:duration-100 {
    transition-duration: .1s;
  }

  .sm\:duration-150 {
    transition-duration: .15s;
  }

  .sm\:duration-200 {
    transition-duration: .2s;
  }

  .sm\:duration-300 {
    transition-duration: .3s;
  }

  .sm\:duration-500 {
    transition-duration: .5s;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }

  .sm\:duration-1000 {
    transition-duration: 1s;
  }

  .sm\:ease-linear {
    transition-timing-function: linear;
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (max-width: 640px) {
  .sm-max\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm-max\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm-max\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm-max\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm-max\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .sm-max\:container {
      max-width: 1560px;
    }
  }

  .sm-max\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm-max\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm-max\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm-max\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm-max\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm-max\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm-max\:pointer-events-none {
    pointer-events: none;
  }

  .sm-max\:pointer-events-auto {
    pointer-events: auto;
  }

  .sm-max\:visible {
    visibility: visible;
  }

  .sm-max\:invisible {
    visibility: hidden;
  }

  .sm-max\:static {
    position: static;
  }

  .sm-max\:fixed {
    position: fixed;
  }

  .sm-max\:absolute {
    position: absolute;
  }

  .sm-max\:relative {
    position: relative;
  }

  .sm-max\:sticky {
    position: sticky;
  }

  .sm-max\:inset-0 {
    inset: 0;
  }

  .sm-max\:inset-1 {
    inset: .25rem;
  }

  .sm-max\:inset-2 {
    inset: .5rem;
  }

  .sm-max\:inset-3 {
    inset: .75rem;
  }

  .sm-max\:inset-4 {
    inset: 1rem;
  }

  .sm-max\:inset-5 {
    inset: 1.25rem;
  }

  .sm-max\:inset-6 {
    inset: 1.5rem;
  }

  .sm-max\:inset-7 {
    inset: 1.75rem;
  }

  .sm-max\:inset-8 {
    inset: 2rem;
  }

  .sm-max\:inset-9 {
    inset: 2.25rem;
  }

  .sm-max\:inset-10 {
    inset: 2.5rem;
  }

  .sm-max\:inset-11 {
    inset: 2.75rem;
  }

  .sm-max\:inset-12 {
    inset: 3rem;
  }

  .sm-max\:inset-14 {
    inset: 3.5rem;
  }

  .sm-max\:inset-16 {
    inset: 4rem;
  }

  .sm-max\:inset-20 {
    inset: 5rem;
  }

  .sm-max\:inset-24 {
    inset: 6rem;
  }

  .sm-max\:inset-28 {
    inset: 7rem;
  }

  .sm-max\:inset-32 {
    inset: 8rem;
  }

  .sm-max\:inset-36 {
    inset: 9rem;
  }

  .sm-max\:inset-40 {
    inset: 10rem;
  }

  .sm-max\:inset-44 {
    inset: 11rem;
  }

  .sm-max\:inset-48 {
    inset: 12rem;
  }

  .sm-max\:inset-52 {
    inset: 13rem;
  }

  .sm-max\:inset-56 {
    inset: 14rem;
  }

  .sm-max\:inset-60 {
    inset: 15rem;
  }

  .sm-max\:inset-64 {
    inset: 16rem;
  }

  .sm-max\:inset-72 {
    inset: 18rem;
  }

  .sm-max\:inset-80 {
    inset: 20rem;
  }

  .sm-max\:inset-96 {
    inset: 24rem;
  }

  .sm-max\:inset-auto {
    inset: auto;
  }

  .sm-max\:inset-px {
    inset: 1px;
  }

  .sm-max\:inset-0\.5 {
    inset: .125rem;
  }

  .sm-max\:inset-1\.5 {
    inset: .375rem;
  }

  .sm-max\:inset-2\.5 {
    inset: .625rem;
  }

  .sm-max\:inset-3\.5 {
    inset: .875rem;
  }

  .sm-max\:-inset-0 {
    inset: 0;
  }

  .sm-max\:-inset-1 {
    inset: -.25rem;
  }

  .sm-max\:-inset-2 {
    inset: -.5rem;
  }

  .sm-max\:-inset-3 {
    inset: -.75rem;
  }

  .sm-max\:-inset-4 {
    inset: -1rem;
  }

  .sm-max\:-inset-5 {
    inset: -1.25rem;
  }

  .sm-max\:-inset-6 {
    inset: -1.5rem;
  }

  .sm-max\:-inset-7 {
    inset: -1.75rem;
  }

  .sm-max\:-inset-8 {
    inset: -2rem;
  }

  .sm-max\:-inset-9 {
    inset: -2.25rem;
  }

  .sm-max\:-inset-10 {
    inset: -2.5rem;
  }

  .sm-max\:-inset-11 {
    inset: -2.75rem;
  }

  .sm-max\:-inset-12 {
    inset: -3rem;
  }

  .sm-max\:-inset-14 {
    inset: -3.5rem;
  }

  .sm-max\:-inset-16 {
    inset: -4rem;
  }

  .sm-max\:-inset-20 {
    inset: -5rem;
  }

  .sm-max\:-inset-24 {
    inset: -6rem;
  }

  .sm-max\:-inset-28 {
    inset: -7rem;
  }

  .sm-max\:-inset-32 {
    inset: -8rem;
  }

  .sm-max\:-inset-36 {
    inset: -9rem;
  }

  .sm-max\:-inset-40 {
    inset: -10rem;
  }

  .sm-max\:-inset-44 {
    inset: -11rem;
  }

  .sm-max\:-inset-48 {
    inset: -12rem;
  }

  .sm-max\:-inset-52 {
    inset: -13rem;
  }

  .sm-max\:-inset-56 {
    inset: -14rem;
  }

  .sm-max\:-inset-60 {
    inset: -15rem;
  }

  .sm-max\:-inset-64 {
    inset: -16rem;
  }

  .sm-max\:-inset-72 {
    inset: -18rem;
  }

  .sm-max\:-inset-80 {
    inset: -20rem;
  }

  .sm-max\:-inset-96 {
    inset: -24rem;
  }

  .sm-max\:-inset-px {
    inset: -1px;
  }

  .sm-max\:-inset-0\.5 {
    inset: -.125rem;
  }

  .sm-max\:-inset-1\.5 {
    inset: -.375rem;
  }

  .sm-max\:-inset-2\.5 {
    inset: -.625rem;
  }

  .sm-max\:-inset-3\.5 {
    inset: -.875rem;
  }

  .sm-max\:inset-1\/2 {
    inset: 50%;
  }

  .sm-max\:inset-1\/3 {
    inset: 33.3333%;
  }

  .sm-max\:inset-2\/3 {
    inset: 66.6667%;
  }

  .sm-max\:inset-1\/4 {
    inset: 25%;
  }

  .sm-max\:inset-2\/4 {
    inset: 50%;
  }

  .sm-max\:inset-3\/4 {
    inset: 75%;
  }

  .sm-max\:inset-full {
    inset: 100%;
  }

  .sm-max\:-inset-1\/2 {
    inset: -50%;
  }

  .sm-max\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .sm-max\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .sm-max\:-inset-1\/4 {
    inset: -25%;
  }

  .sm-max\:-inset-2\/4 {
    inset: -50%;
  }

  .sm-max\:-inset-3\/4 {
    inset: -75%;
  }

  .sm-max\:-inset-full {
    inset: -100%;
  }

  .sm-max\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm-max\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .sm-max\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .sm-max\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .sm-max\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .sm-max\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .sm-max\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .sm-max\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .sm-max\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .sm-max\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .sm-max\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .sm-max\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .sm-max\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .sm-max\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .sm-max\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .sm-max\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .sm-max\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .sm-max\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .sm-max\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .sm-max\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .sm-max\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .sm-max\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .sm-max\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .sm-max\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .sm-max\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .sm-max\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .sm-max\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .sm-max\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .sm-max\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .sm-max\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .sm-max\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .sm-max\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .sm-max\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .sm-max\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .sm-max\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .sm-max\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .sm-max\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm-max\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .sm-max\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .sm-max\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .sm-max\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .sm-max\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .sm-max\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .sm-max\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .sm-max\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .sm-max\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .sm-max\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .sm-max\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .sm-max\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .sm-max\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .sm-max\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .sm-max\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .sm-max\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .sm-max\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .sm-max\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .sm-max\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .sm-max\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .sm-max\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .sm-max\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .sm-max\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .sm-max\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .sm-max\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .sm-max\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .sm-max\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .sm-max\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .sm-max\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .sm-max\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .sm-max\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .sm-max\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .sm-max\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .sm-max\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .sm-max\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .sm-max\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .sm-max\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .sm-max\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .sm-max\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .sm-max\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .sm-max\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .sm-max\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .sm-max\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .sm-max\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .sm-max\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .sm-max\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .sm-max\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .sm-max\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .sm-max\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm-max\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .sm-max\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .sm-max\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .sm-max\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .sm-max\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .sm-max\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .sm-max\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .sm-max\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .sm-max\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .sm-max\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .sm-max\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .sm-max\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .sm-max\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .sm-max\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .sm-max\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .sm-max\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .sm-max\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .sm-max\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .sm-max\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .sm-max\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .sm-max\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .sm-max\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .sm-max\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .sm-max\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .sm-max\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .sm-max\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .sm-max\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .sm-max\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .sm-max\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .sm-max\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm-max\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .sm-max\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .sm-max\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .sm-max\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .sm-max\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .sm-max\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm-max\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .sm-max\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .sm-max\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .sm-max\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .sm-max\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .sm-max\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .sm-max\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .sm-max\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .sm-max\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .sm-max\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .sm-max\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .sm-max\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .sm-max\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .sm-max\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .sm-max\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .sm-max\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .sm-max\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .sm-max\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .sm-max\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .sm-max\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .sm-max\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .sm-max\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .sm-max\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .sm-max\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .sm-max\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .sm-max\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .sm-max\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .sm-max\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .sm-max\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .sm-max\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .sm-max\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .sm-max\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .sm-max\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .sm-max\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .sm-max\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .sm-max\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .sm-max\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .sm-max\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .sm-max\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .sm-max\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .sm-max\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .sm-max\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .sm-max\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .sm-max\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .sm-max\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .sm-max\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .sm-max\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .sm-max\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .sm-max\:top-0 {
    top: 0;
  }

  .sm-max\:top-1 {
    top: .25rem;
  }

  .sm-max\:top-2 {
    top: .5rem;
  }

  .sm-max\:top-3 {
    top: .75rem;
  }

  .sm-max\:top-4 {
    top: 1rem;
  }

  .sm-max\:top-5 {
    top: 1.25rem;
  }

  .sm-max\:top-6 {
    top: 1.5rem;
  }

  .sm-max\:top-7 {
    top: 1.75rem;
  }

  .sm-max\:top-8 {
    top: 2rem;
  }

  .sm-max\:top-9 {
    top: 2.25rem;
  }

  .sm-max\:top-10 {
    top: 2.5rem;
  }

  .sm-max\:top-11 {
    top: 2.75rem;
  }

  .sm-max\:top-12 {
    top: 3rem;
  }

  .sm-max\:top-14 {
    top: 3.5rem;
  }

  .sm-max\:top-16 {
    top: 4rem;
  }

  .sm-max\:top-20 {
    top: 5rem;
  }

  .sm-max\:top-24 {
    top: 6rem;
  }

  .sm-max\:top-28 {
    top: 7rem;
  }

  .sm-max\:top-32 {
    top: 8rem;
  }

  .sm-max\:top-36 {
    top: 9rem;
  }

  .sm-max\:top-40 {
    top: 10rem;
  }

  .sm-max\:top-44 {
    top: 11rem;
  }

  .sm-max\:top-48 {
    top: 12rem;
  }

  .sm-max\:top-52 {
    top: 13rem;
  }

  .sm-max\:top-56 {
    top: 14rem;
  }

  .sm-max\:top-60 {
    top: 15rem;
  }

  .sm-max\:top-64 {
    top: 16rem;
  }

  .sm-max\:top-72 {
    top: 18rem;
  }

  .sm-max\:top-80 {
    top: 20rem;
  }

  .sm-max\:top-96 {
    top: 24rem;
  }

  .sm-max\:top-auto {
    top: auto;
  }

  .sm-max\:top-px {
    top: 1px;
  }

  .sm-max\:top-0\.5 {
    top: .125rem;
  }

  .sm-max\:top-1\.5 {
    top: .375rem;
  }

  .sm-max\:top-2\.5 {
    top: .625rem;
  }

  .sm-max\:top-3\.5 {
    top: .875rem;
  }

  .sm-max\:-top-0 {
    top: 0;
  }

  .sm-max\:-top-1 {
    top: -.25rem;
  }

  .sm-max\:-top-2 {
    top: -.5rem;
  }

  .sm-max\:-top-3 {
    top: -.75rem;
  }

  .sm-max\:-top-4 {
    top: -1rem;
  }

  .sm-max\:-top-5 {
    top: -1.25rem;
  }

  .sm-max\:-top-6 {
    top: -1.5rem;
  }

  .sm-max\:-top-7 {
    top: -1.75rem;
  }

  .sm-max\:-top-8 {
    top: -2rem;
  }

  .sm-max\:-top-9 {
    top: -2.25rem;
  }

  .sm-max\:-top-10 {
    top: -2.5rem;
  }

  .sm-max\:-top-11 {
    top: -2.75rem;
  }

  .sm-max\:-top-12 {
    top: -3rem;
  }

  .sm-max\:-top-14 {
    top: -3.5rem;
  }

  .sm-max\:-top-16 {
    top: -4rem;
  }

  .sm-max\:-top-20 {
    top: -5rem;
  }

  .sm-max\:-top-24 {
    top: -6rem;
  }

  .sm-max\:-top-28 {
    top: -7rem;
  }

  .sm-max\:-top-32 {
    top: -8rem;
  }

  .sm-max\:-top-36 {
    top: -9rem;
  }

  .sm-max\:-top-40 {
    top: -10rem;
  }

  .sm-max\:-top-44 {
    top: -11rem;
  }

  .sm-max\:-top-48 {
    top: -12rem;
  }

  .sm-max\:-top-52 {
    top: -13rem;
  }

  .sm-max\:-top-56 {
    top: -14rem;
  }

  .sm-max\:-top-60 {
    top: -15rem;
  }

  .sm-max\:-top-64 {
    top: -16rem;
  }

  .sm-max\:-top-72 {
    top: -18rem;
  }

  .sm-max\:-top-80 {
    top: -20rem;
  }

  .sm-max\:-top-96 {
    top: -24rem;
  }

  .sm-max\:-top-px {
    top: -1px;
  }

  .sm-max\:-top-0\.5 {
    top: -.125rem;
  }

  .sm-max\:-top-1\.5 {
    top: -.375rem;
  }

  .sm-max\:-top-2\.5 {
    top: -.625rem;
  }

  .sm-max\:-top-3\.5 {
    top: -.875rem;
  }

  .sm-max\:top-1\/2 {
    top: 50%;
  }

  .sm-max\:top-1\/3 {
    top: 33.3333%;
  }

  .sm-max\:top-2\/3 {
    top: 66.6667%;
  }

  .sm-max\:top-1\/4 {
    top: 25%;
  }

  .sm-max\:top-2\/4 {
    top: 50%;
  }

  .sm-max\:top-3\/4 {
    top: 75%;
  }

  .sm-max\:top-full {
    top: 100%;
  }

  .sm-max\:-top-1\/2 {
    top: -50%;
  }

  .sm-max\:-top-1\/3 {
    top: -33.3333%;
  }

  .sm-max\:-top-2\/3 {
    top: -66.6667%;
  }

  .sm-max\:-top-1\/4 {
    top: -25%;
  }

  .sm-max\:-top-2\/4 {
    top: -50%;
  }

  .sm-max\:-top-3\/4 {
    top: -75%;
  }

  .sm-max\:-top-full {
    top: -100%;
  }

  .sm-max\:right-0 {
    right: 0;
  }

  .sm-max\:right-1 {
    right: .25rem;
  }

  .sm-max\:right-2 {
    right: .5rem;
  }

  .sm-max\:right-3 {
    right: .75rem;
  }

  .sm-max\:right-4 {
    right: 1rem;
  }

  .sm-max\:right-5 {
    right: 1.25rem;
  }

  .sm-max\:right-6 {
    right: 1.5rem;
  }

  .sm-max\:right-7 {
    right: 1.75rem;
  }

  .sm-max\:right-8 {
    right: 2rem;
  }

  .sm-max\:right-9 {
    right: 2.25rem;
  }

  .sm-max\:right-10 {
    right: 2.5rem;
  }

  .sm-max\:right-11 {
    right: 2.75rem;
  }

  .sm-max\:right-12 {
    right: 3rem;
  }

  .sm-max\:right-14 {
    right: 3.5rem;
  }

  .sm-max\:right-16 {
    right: 4rem;
  }

  .sm-max\:right-20 {
    right: 5rem;
  }

  .sm-max\:right-24 {
    right: 6rem;
  }

  .sm-max\:right-28 {
    right: 7rem;
  }

  .sm-max\:right-32 {
    right: 8rem;
  }

  .sm-max\:right-36 {
    right: 9rem;
  }

  .sm-max\:right-40 {
    right: 10rem;
  }

  .sm-max\:right-44 {
    right: 11rem;
  }

  .sm-max\:right-48 {
    right: 12rem;
  }

  .sm-max\:right-52 {
    right: 13rem;
  }

  .sm-max\:right-56 {
    right: 14rem;
  }

  .sm-max\:right-60 {
    right: 15rem;
  }

  .sm-max\:right-64 {
    right: 16rem;
  }

  .sm-max\:right-72 {
    right: 18rem;
  }

  .sm-max\:right-80 {
    right: 20rem;
  }

  .sm-max\:right-96 {
    right: 24rem;
  }

  .sm-max\:right-auto {
    right: auto;
  }

  .sm-max\:right-px {
    right: 1px;
  }

  .sm-max\:right-0\.5 {
    right: .125rem;
  }

  .sm-max\:right-1\.5 {
    right: .375rem;
  }

  .sm-max\:right-2\.5 {
    right: .625rem;
  }

  .sm-max\:right-3\.5 {
    right: .875rem;
  }

  .sm-max\:-right-0 {
    right: 0;
  }

  .sm-max\:-right-1 {
    right: -.25rem;
  }

  .sm-max\:-right-2 {
    right: -.5rem;
  }

  .sm-max\:-right-3 {
    right: -.75rem;
  }

  .sm-max\:-right-4 {
    right: -1rem;
  }

  .sm-max\:-right-5 {
    right: -1.25rem;
  }

  .sm-max\:-right-6 {
    right: -1.5rem;
  }

  .sm-max\:-right-7 {
    right: -1.75rem;
  }

  .sm-max\:-right-8 {
    right: -2rem;
  }

  .sm-max\:-right-9 {
    right: -2.25rem;
  }

  .sm-max\:-right-10 {
    right: -2.5rem;
  }

  .sm-max\:-right-11 {
    right: -2.75rem;
  }

  .sm-max\:-right-12 {
    right: -3rem;
  }

  .sm-max\:-right-14 {
    right: -3.5rem;
  }

  .sm-max\:-right-16 {
    right: -4rem;
  }

  .sm-max\:-right-20 {
    right: -5rem;
  }

  .sm-max\:-right-24 {
    right: -6rem;
  }

  .sm-max\:-right-28 {
    right: -7rem;
  }

  .sm-max\:-right-32 {
    right: -8rem;
  }

  .sm-max\:-right-36 {
    right: -9rem;
  }

  .sm-max\:-right-40 {
    right: -10rem;
  }

  .sm-max\:-right-44 {
    right: -11rem;
  }

  .sm-max\:-right-48 {
    right: -12rem;
  }

  .sm-max\:-right-52 {
    right: -13rem;
  }

  .sm-max\:-right-56 {
    right: -14rem;
  }

  .sm-max\:-right-60 {
    right: -15rem;
  }

  .sm-max\:-right-64 {
    right: -16rem;
  }

  .sm-max\:-right-72 {
    right: -18rem;
  }

  .sm-max\:-right-80 {
    right: -20rem;
  }

  .sm-max\:-right-96 {
    right: -24rem;
  }

  .sm-max\:-right-px {
    right: -1px;
  }

  .sm-max\:-right-0\.5 {
    right: -.125rem;
  }

  .sm-max\:-right-1\.5 {
    right: -.375rem;
  }

  .sm-max\:-right-2\.5 {
    right: -.625rem;
  }

  .sm-max\:-right-3\.5 {
    right: -.875rem;
  }

  .sm-max\:right-1\/2 {
    right: 50%;
  }

  .sm-max\:right-1\/3 {
    right: 33.3333%;
  }

  .sm-max\:right-2\/3 {
    right: 66.6667%;
  }

  .sm-max\:right-1\/4 {
    right: 25%;
  }

  .sm-max\:right-2\/4 {
    right: 50%;
  }

  .sm-max\:right-3\/4 {
    right: 75%;
  }

  .sm-max\:right-full {
    right: 100%;
  }

  .sm-max\:-right-1\/2 {
    right: -50%;
  }

  .sm-max\:-right-1\/3 {
    right: -33.3333%;
  }

  .sm-max\:-right-2\/3 {
    right: -66.6667%;
  }

  .sm-max\:-right-1\/4 {
    right: -25%;
  }

  .sm-max\:-right-2\/4 {
    right: -50%;
  }

  .sm-max\:-right-3\/4 {
    right: -75%;
  }

  .sm-max\:-right-full {
    right: -100%;
  }

  .sm-max\:bottom-0 {
    bottom: 0;
  }

  .sm-max\:bottom-1 {
    bottom: .25rem;
  }

  .sm-max\:bottom-2 {
    bottom: .5rem;
  }

  .sm-max\:bottom-3 {
    bottom: .75rem;
  }

  .sm-max\:bottom-4 {
    bottom: 1rem;
  }

  .sm-max\:bottom-5 {
    bottom: 1.25rem;
  }

  .sm-max\:bottom-6 {
    bottom: 1.5rem;
  }

  .sm-max\:bottom-7 {
    bottom: 1.75rem;
  }

  .sm-max\:bottom-8 {
    bottom: 2rem;
  }

  .sm-max\:bottom-9 {
    bottom: 2.25rem;
  }

  .sm-max\:bottom-10 {
    bottom: 2.5rem;
  }

  .sm-max\:bottom-11 {
    bottom: 2.75rem;
  }

  .sm-max\:bottom-12 {
    bottom: 3rem;
  }

  .sm-max\:bottom-14 {
    bottom: 3.5rem;
  }

  .sm-max\:bottom-16 {
    bottom: 4rem;
  }

  .sm-max\:bottom-20 {
    bottom: 5rem;
  }

  .sm-max\:bottom-24 {
    bottom: 6rem;
  }

  .sm-max\:bottom-28 {
    bottom: 7rem;
  }

  .sm-max\:bottom-32 {
    bottom: 8rem;
  }

  .sm-max\:bottom-36 {
    bottom: 9rem;
  }

  .sm-max\:bottom-40 {
    bottom: 10rem;
  }

  .sm-max\:bottom-44 {
    bottom: 11rem;
  }

  .sm-max\:bottom-48 {
    bottom: 12rem;
  }

  .sm-max\:bottom-52 {
    bottom: 13rem;
  }

  .sm-max\:bottom-56 {
    bottom: 14rem;
  }

  .sm-max\:bottom-60 {
    bottom: 15rem;
  }

  .sm-max\:bottom-64 {
    bottom: 16rem;
  }

  .sm-max\:bottom-72 {
    bottom: 18rem;
  }

  .sm-max\:bottom-80 {
    bottom: 20rem;
  }

  .sm-max\:bottom-96 {
    bottom: 24rem;
  }

  .sm-max\:bottom-auto {
    bottom: auto;
  }

  .sm-max\:bottom-px {
    bottom: 1px;
  }

  .sm-max\:bottom-0\.5 {
    bottom: .125rem;
  }

  .sm-max\:bottom-1\.5 {
    bottom: .375rem;
  }

  .sm-max\:bottom-2\.5 {
    bottom: .625rem;
  }

  .sm-max\:bottom-3\.5 {
    bottom: .875rem;
  }

  .sm-max\:-bottom-0 {
    bottom: 0;
  }

  .sm-max\:-bottom-1 {
    bottom: -.25rem;
  }

  .sm-max\:-bottom-2 {
    bottom: -.5rem;
  }

  .sm-max\:-bottom-3 {
    bottom: -.75rem;
  }

  .sm-max\:-bottom-4 {
    bottom: -1rem;
  }

  .sm-max\:-bottom-5 {
    bottom: -1.25rem;
  }

  .sm-max\:-bottom-6 {
    bottom: -1.5rem;
  }

  .sm-max\:-bottom-7 {
    bottom: -1.75rem;
  }

  .sm-max\:-bottom-8 {
    bottom: -2rem;
  }

  .sm-max\:-bottom-9 {
    bottom: -2.25rem;
  }

  .sm-max\:-bottom-10 {
    bottom: -2.5rem;
  }

  .sm-max\:-bottom-11 {
    bottom: -2.75rem;
  }

  .sm-max\:-bottom-12 {
    bottom: -3rem;
  }

  .sm-max\:-bottom-14 {
    bottom: -3.5rem;
  }

  .sm-max\:-bottom-16 {
    bottom: -4rem;
  }

  .sm-max\:-bottom-20 {
    bottom: -5rem;
  }

  .sm-max\:-bottom-24 {
    bottom: -6rem;
  }

  .sm-max\:-bottom-28 {
    bottom: -7rem;
  }

  .sm-max\:-bottom-32 {
    bottom: -8rem;
  }

  .sm-max\:-bottom-36 {
    bottom: -9rem;
  }

  .sm-max\:-bottom-40 {
    bottom: -10rem;
  }

  .sm-max\:-bottom-44 {
    bottom: -11rem;
  }

  .sm-max\:-bottom-48 {
    bottom: -12rem;
  }

  .sm-max\:-bottom-52 {
    bottom: -13rem;
  }

  .sm-max\:-bottom-56 {
    bottom: -14rem;
  }

  .sm-max\:-bottom-60 {
    bottom: -15rem;
  }

  .sm-max\:-bottom-64 {
    bottom: -16rem;
  }

  .sm-max\:-bottom-72 {
    bottom: -18rem;
  }

  .sm-max\:-bottom-80 {
    bottom: -20rem;
  }

  .sm-max\:-bottom-96 {
    bottom: -24rem;
  }

  .sm-max\:-bottom-px {
    bottom: -1px;
  }

  .sm-max\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .sm-max\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .sm-max\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .sm-max\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .sm-max\:bottom-1\/2 {
    bottom: 50%;
  }

  .sm-max\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .sm-max\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .sm-max\:bottom-1\/4 {
    bottom: 25%;
  }

  .sm-max\:bottom-2\/4 {
    bottom: 50%;
  }

  .sm-max\:bottom-3\/4 {
    bottom: 75%;
  }

  .sm-max\:bottom-full {
    bottom: 100%;
  }

  .sm-max\:-bottom-1\/2 {
    bottom: -50%;
  }

  .sm-max\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .sm-max\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .sm-max\:-bottom-1\/4 {
    bottom: -25%;
  }

  .sm-max\:-bottom-2\/4 {
    bottom: -50%;
  }

  .sm-max\:-bottom-3\/4 {
    bottom: -75%;
  }

  .sm-max\:-bottom-full {
    bottom: -100%;
  }

  .sm-max\:left-0 {
    left: 0;
  }

  .sm-max\:left-1 {
    left: .25rem;
  }

  .sm-max\:left-2 {
    left: .5rem;
  }

  .sm-max\:left-3 {
    left: .75rem;
  }

  .sm-max\:left-4 {
    left: 1rem;
  }

  .sm-max\:left-5 {
    left: 1.25rem;
  }

  .sm-max\:left-6 {
    left: 1.5rem;
  }

  .sm-max\:left-7 {
    left: 1.75rem;
  }

  .sm-max\:left-8 {
    left: 2rem;
  }

  .sm-max\:left-9 {
    left: 2.25rem;
  }

  .sm-max\:left-10 {
    left: 2.5rem;
  }

  .sm-max\:left-11 {
    left: 2.75rem;
  }

  .sm-max\:left-12 {
    left: 3rem;
  }

  .sm-max\:left-14 {
    left: 3.5rem;
  }

  .sm-max\:left-16 {
    left: 4rem;
  }

  .sm-max\:left-20 {
    left: 5rem;
  }

  .sm-max\:left-24 {
    left: 6rem;
  }

  .sm-max\:left-28 {
    left: 7rem;
  }

  .sm-max\:left-32 {
    left: 8rem;
  }

  .sm-max\:left-36 {
    left: 9rem;
  }

  .sm-max\:left-40 {
    left: 10rem;
  }

  .sm-max\:left-44 {
    left: 11rem;
  }

  .sm-max\:left-48 {
    left: 12rem;
  }

  .sm-max\:left-52 {
    left: 13rem;
  }

  .sm-max\:left-56 {
    left: 14rem;
  }

  .sm-max\:left-60 {
    left: 15rem;
  }

  .sm-max\:left-64 {
    left: 16rem;
  }

  .sm-max\:left-72 {
    left: 18rem;
  }

  .sm-max\:left-80 {
    left: 20rem;
  }

  .sm-max\:left-96 {
    left: 24rem;
  }

  .sm-max\:left-auto {
    left: auto;
  }

  .sm-max\:left-px {
    left: 1px;
  }

  .sm-max\:left-0\.5 {
    left: .125rem;
  }

  .sm-max\:left-1\.5 {
    left: .375rem;
  }

  .sm-max\:left-2\.5 {
    left: .625rem;
  }

  .sm-max\:left-3\.5 {
    left: .875rem;
  }

  .sm-max\:-left-0 {
    left: 0;
  }

  .sm-max\:-left-1 {
    left: -.25rem;
  }

  .sm-max\:-left-2 {
    left: -.5rem;
  }

  .sm-max\:-left-3 {
    left: -.75rem;
  }

  .sm-max\:-left-4 {
    left: -1rem;
  }

  .sm-max\:-left-5 {
    left: -1.25rem;
  }

  .sm-max\:-left-6 {
    left: -1.5rem;
  }

  .sm-max\:-left-7 {
    left: -1.75rem;
  }

  .sm-max\:-left-8 {
    left: -2rem;
  }

  .sm-max\:-left-9 {
    left: -2.25rem;
  }

  .sm-max\:-left-10 {
    left: -2.5rem;
  }

  .sm-max\:-left-11 {
    left: -2.75rem;
  }

  .sm-max\:-left-12 {
    left: -3rem;
  }

  .sm-max\:-left-14 {
    left: -3.5rem;
  }

  .sm-max\:-left-16 {
    left: -4rem;
  }

  .sm-max\:-left-20 {
    left: -5rem;
  }

  .sm-max\:-left-24 {
    left: -6rem;
  }

  .sm-max\:-left-28 {
    left: -7rem;
  }

  .sm-max\:-left-32 {
    left: -8rem;
  }

  .sm-max\:-left-36 {
    left: -9rem;
  }

  .sm-max\:-left-40 {
    left: -10rem;
  }

  .sm-max\:-left-44 {
    left: -11rem;
  }

  .sm-max\:-left-48 {
    left: -12rem;
  }

  .sm-max\:-left-52 {
    left: -13rem;
  }

  .sm-max\:-left-56 {
    left: -14rem;
  }

  .sm-max\:-left-60 {
    left: -15rem;
  }

  .sm-max\:-left-64 {
    left: -16rem;
  }

  .sm-max\:-left-72 {
    left: -18rem;
  }

  .sm-max\:-left-80 {
    left: -20rem;
  }

  .sm-max\:-left-96 {
    left: -24rem;
  }

  .sm-max\:-left-px {
    left: -1px;
  }

  .sm-max\:-left-0\.5 {
    left: -.125rem;
  }

  .sm-max\:-left-1\.5 {
    left: -.375rem;
  }

  .sm-max\:-left-2\.5 {
    left: -.625rem;
  }

  .sm-max\:-left-3\.5 {
    left: -.875rem;
  }

  .sm-max\:left-1\/2 {
    left: 50%;
  }

  .sm-max\:left-1\/3 {
    left: 33.3333%;
  }

  .sm-max\:left-2\/3 {
    left: 66.6667%;
  }

  .sm-max\:left-1\/4 {
    left: 25%;
  }

  .sm-max\:left-2\/4 {
    left: 50%;
  }

  .sm-max\:left-3\/4 {
    left: 75%;
  }

  .sm-max\:left-full {
    left: 100%;
  }

  .sm-max\:-left-1\/2 {
    left: -50%;
  }

  .sm-max\:-left-1\/3 {
    left: -33.3333%;
  }

  .sm-max\:-left-2\/3 {
    left: -66.6667%;
  }

  .sm-max\:-left-1\/4 {
    left: -25%;
  }

  .sm-max\:-left-2\/4 {
    left: -50%;
  }

  .sm-max\:-left-3\/4 {
    left: -75%;
  }

  .sm-max\:-left-full {
    left: -100%;
  }

  .sm-max\:isolate {
    isolation: isolate;
  }

  .sm-max\:isolation-auto {
    isolation: auto;
  }

  .sm-max\:z-0 {
    z-index: 0;
  }

  .sm-max\:z-10 {
    z-index: 10;
  }

  .sm-max\:z-20 {
    z-index: 20;
  }

  .sm-max\:z-30 {
    z-index: 30;
  }

  .sm-max\:z-40 {
    z-index: 40;
  }

  .sm-max\:z-50 {
    z-index: 50;
  }

  .sm-max\:z-auto {
    z-index: auto;
  }

  .sm-max\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .sm-max\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .sm-max\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .sm-max\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .sm-max\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .sm-max\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .sm-max\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .sm-max\:focus\:z-0:focus {
    z-index: 0;
  }

  .sm-max\:focus\:z-10:focus {
    z-index: 10;
  }

  .sm-max\:focus\:z-20:focus {
    z-index: 20;
  }

  .sm-max\:focus\:z-30:focus {
    z-index: 30;
  }

  .sm-max\:focus\:z-40:focus {
    z-index: 40;
  }

  .sm-max\:focus\:z-50:focus {
    z-index: 50;
  }

  .sm-max\:focus\:z-auto:focus {
    z-index: auto;
  }

  .sm-max\:order-1 {
    order: 1;
  }

  .sm-max\:order-2 {
    order: 2;
  }

  .sm-max\:order-3 {
    order: 3;
  }

  .sm-max\:order-4 {
    order: 4;
  }

  .sm-max\:order-5 {
    order: 5;
  }

  .sm-max\:order-6 {
    order: 6;
  }

  .sm-max\:order-7 {
    order: 7;
  }

  .sm-max\:order-8 {
    order: 8;
  }

  .sm-max\:order-9 {
    order: 9;
  }

  .sm-max\:order-10 {
    order: 10;
  }

  .sm-max\:order-11 {
    order: 11;
  }

  .sm-max\:order-12 {
    order: 12;
  }

  .sm-max\:order-first {
    order: -9999;
  }

  .sm-max\:order-last {
    order: 9999;
  }

  .sm-max\:order-none {
    order: 0;
  }

  .sm-max\:col-auto {
    grid-column: auto;
  }

  .sm-max\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm-max\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm-max\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm-max\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm-max\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm-max\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm-max\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm-max\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm-max\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm-max\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm-max\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm-max\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm-max\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm-max\:col-start-1 {
    grid-column-start: 1;
  }

  .sm-max\:col-start-2 {
    grid-column-start: 2;
  }

  .sm-max\:col-start-3 {
    grid-column-start: 3;
  }

  .sm-max\:col-start-4 {
    grid-column-start: 4;
  }

  .sm-max\:col-start-5 {
    grid-column-start: 5;
  }

  .sm-max\:col-start-6 {
    grid-column-start: 6;
  }

  .sm-max\:col-start-7 {
    grid-column-start: 7;
  }

  .sm-max\:col-start-8 {
    grid-column-start: 8;
  }

  .sm-max\:col-start-9 {
    grid-column-start: 9;
  }

  .sm-max\:col-start-10 {
    grid-column-start: 10;
  }

  .sm-max\:col-start-11 {
    grid-column-start: 11;
  }

  .sm-max\:col-start-12 {
    grid-column-start: 12;
  }

  .sm-max\:col-start-13 {
    grid-column-start: 13;
  }

  .sm-max\:col-start-auto {
    grid-column-start: auto;
  }

  .sm-max\:col-end-1 {
    grid-column-end: 1;
  }

  .sm-max\:col-end-2 {
    grid-column-end: 2;
  }

  .sm-max\:col-end-3 {
    grid-column-end: 3;
  }

  .sm-max\:col-end-4 {
    grid-column-end: 4;
  }

  .sm-max\:col-end-5 {
    grid-column-end: 5;
  }

  .sm-max\:col-end-6 {
    grid-column-end: 6;
  }

  .sm-max\:col-end-7 {
    grid-column-end: 7;
  }

  .sm-max\:col-end-8 {
    grid-column-end: 8;
  }

  .sm-max\:col-end-9 {
    grid-column-end: 9;
  }

  .sm-max\:col-end-10 {
    grid-column-end: 10;
  }

  .sm-max\:col-end-11 {
    grid-column-end: 11;
  }

  .sm-max\:col-end-12 {
    grid-column-end: 12;
  }

  .sm-max\:col-end-13 {
    grid-column-end: 13;
  }

  .sm-max\:col-end-auto {
    grid-column-end: auto;
  }

  .sm-max\:row-auto {
    grid-row: auto;
  }

  .sm-max\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm-max\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm-max\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm-max\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm-max\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm-max\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm-max\:row-span-full {
    grid-row: 1 / -1;
  }

  .sm-max\:row-start-1 {
    grid-row-start: 1;
  }

  .sm-max\:row-start-2 {
    grid-row-start: 2;
  }

  .sm-max\:row-start-3 {
    grid-row-start: 3;
  }

  .sm-max\:row-start-4 {
    grid-row-start: 4;
  }

  .sm-max\:row-start-5 {
    grid-row-start: 5;
  }

  .sm-max\:row-start-6 {
    grid-row-start: 6;
  }

  .sm-max\:row-start-7 {
    grid-row-start: 7;
  }

  .sm-max\:row-start-auto {
    grid-row-start: auto;
  }

  .sm-max\:row-end-1 {
    grid-row-end: 1;
  }

  .sm-max\:row-end-2 {
    grid-row-end: 2;
  }

  .sm-max\:row-end-3 {
    grid-row-end: 3;
  }

  .sm-max\:row-end-4 {
    grid-row-end: 4;
  }

  .sm-max\:row-end-5 {
    grid-row-end: 5;
  }

  .sm-max\:row-end-6 {
    grid-row-end: 6;
  }

  .sm-max\:row-end-7 {
    grid-row-end: 7;
  }

  .sm-max\:row-end-auto {
    grid-row-end: auto;
  }

  .sm-max\:float-right {
    float: right;
  }

  .sm-max\:float-left {
    float: left;
  }

  .sm-max\:float-none {
    float: none;
  }

  .sm-max\:clear-left {
    clear: left;
  }

  .sm-max\:clear-right {
    clear: right;
  }

  .sm-max\:clear-both {
    clear: both;
  }

  .sm-max\:clear-none {
    clear: none;
  }

  .sm-max\:m-0 {
    margin: 0;
  }

  .sm-max\:m-1 {
    margin: .25rem;
  }

  .sm-max\:m-2 {
    margin: .5rem;
  }

  .sm-max\:m-3 {
    margin: .75rem;
  }

  .sm-max\:m-4 {
    margin: 1rem;
  }

  .sm-max\:m-5 {
    margin: 1.25rem;
  }

  .sm-max\:m-6 {
    margin: 1.5rem;
  }

  .sm-max\:m-7 {
    margin: 1.75rem;
  }

  .sm-max\:m-8 {
    margin: 2rem;
  }

  .sm-max\:m-9 {
    margin: 2.25rem;
  }

  .sm-max\:m-10 {
    margin: 2.5rem;
  }

  .sm-max\:m-11 {
    margin: 2.75rem;
  }

  .sm-max\:m-12 {
    margin: 3rem;
  }

  .sm-max\:m-14 {
    margin: 3.5rem;
  }

  .sm-max\:m-16 {
    margin: 4rem;
  }

  .sm-max\:m-20 {
    margin: 5rem;
  }

  .sm-max\:m-24 {
    margin: 6rem;
  }

  .sm-max\:m-28 {
    margin: 7rem;
  }

  .sm-max\:m-32 {
    margin: 8rem;
  }

  .sm-max\:m-36 {
    margin: 9rem;
  }

  .sm-max\:m-40 {
    margin: 10rem;
  }

  .sm-max\:m-44 {
    margin: 11rem;
  }

  .sm-max\:m-48 {
    margin: 12rem;
  }

  .sm-max\:m-52 {
    margin: 13rem;
  }

  .sm-max\:m-56 {
    margin: 14rem;
  }

  .sm-max\:m-60 {
    margin: 15rem;
  }

  .sm-max\:m-64 {
    margin: 16rem;
  }

  .sm-max\:m-72 {
    margin: 18rem;
  }

  .sm-max\:m-80 {
    margin: 20rem;
  }

  .sm-max\:m-96 {
    margin: 24rem;
  }

  .sm-max\:m-auto {
    margin: auto;
  }

  .sm-max\:m-px {
    margin: 1px;
  }

  .sm-max\:m-0\.5 {
    margin: .125rem;
  }

  .sm-max\:m-1\.5 {
    margin: .375rem;
  }

  .sm-max\:m-2\.5 {
    margin: .625rem;
  }

  .sm-max\:m-3\.5 {
    margin: .875rem;
  }

  .sm-max\:-m-0 {
    margin: 0;
  }

  .sm-max\:-m-1 {
    margin: -.25rem;
  }

  .sm-max\:-m-2 {
    margin: -.5rem;
  }

  .sm-max\:-m-3 {
    margin: -.75rem;
  }

  .sm-max\:-m-4 {
    margin: -1rem;
  }

  .sm-max\:-m-5 {
    margin: -1.25rem;
  }

  .sm-max\:-m-6 {
    margin: -1.5rem;
  }

  .sm-max\:-m-7 {
    margin: -1.75rem;
  }

  .sm-max\:-m-8 {
    margin: -2rem;
  }

  .sm-max\:-m-9 {
    margin: -2.25rem;
  }

  .sm-max\:-m-10 {
    margin: -2.5rem;
  }

  .sm-max\:-m-11 {
    margin: -2.75rem;
  }

  .sm-max\:-m-12 {
    margin: -3rem;
  }

  .sm-max\:-m-14 {
    margin: -3.5rem;
  }

  .sm-max\:-m-16 {
    margin: -4rem;
  }

  .sm-max\:-m-20 {
    margin: -5rem;
  }

  .sm-max\:-m-24 {
    margin: -6rem;
  }

  .sm-max\:-m-28 {
    margin: -7rem;
  }

  .sm-max\:-m-32 {
    margin: -8rem;
  }

  .sm-max\:-m-36 {
    margin: -9rem;
  }

  .sm-max\:-m-40 {
    margin: -10rem;
  }

  .sm-max\:-m-44 {
    margin: -11rem;
  }

  .sm-max\:-m-48 {
    margin: -12rem;
  }

  .sm-max\:-m-52 {
    margin: -13rem;
  }

  .sm-max\:-m-56 {
    margin: -14rem;
  }

  .sm-max\:-m-60 {
    margin: -15rem;
  }

  .sm-max\:-m-64 {
    margin: -16rem;
  }

  .sm-max\:-m-72 {
    margin: -18rem;
  }

  .sm-max\:-m-80 {
    margin: -20rem;
  }

  .sm-max\:-m-96 {
    margin: -24rem;
  }

  .sm-max\:-m-px {
    margin: -1px;
  }

  .sm-max\:-m-0\.5 {
    margin: -.125rem;
  }

  .sm-max\:-m-1\.5 {
    margin: -.375rem;
  }

  .sm-max\:-m-2\.5 {
    margin: -.625rem;
  }

  .sm-max\:-m-3\.5 {
    margin: -.875rem;
  }

  .sm-max\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm-max\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm-max\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm-max\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm-max\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm-max\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm-max\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm-max\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .sm-max\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm-max\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .sm-max\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm-max\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .sm-max\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm-max\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .sm-max\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm-max\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm-max\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm-max\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .sm-max\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm-max\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .sm-max\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm-max\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .sm-max\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm-max\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .sm-max\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm-max\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .sm-max\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm-max\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .sm-max\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .sm-max\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .sm-max\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm-max\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm-max\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .sm-max\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .sm-max\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .sm-max\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .sm-max\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm-max\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .sm-max\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm-max\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .sm-max\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm-max\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm-max\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm-max\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .sm-max\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm-max\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .sm-max\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm-max\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .sm-max\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm-max\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .sm-max\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm-max\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm-max\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm-max\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .sm-max\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm-max\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .sm-max\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm-max\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .sm-max\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm-max\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .sm-max\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm-max\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .sm-max\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm-max\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .sm-max\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .sm-max\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .sm-max\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm-max\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .sm-max\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .sm-max\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .sm-max\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .sm-max\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm-max\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .sm-max\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .sm-max\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .sm-max\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm-max\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm-max\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm-max\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .sm-max\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm-max\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .sm-max\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm-max\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .sm-max\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm-max\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .sm-max\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm-max\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm-max\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm-max\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .sm-max\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm-max\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .sm-max\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm-max\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .sm-max\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm-max\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .sm-max\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm-max\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .sm-max\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm-max\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .sm-max\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .sm-max\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .sm-max\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm-max\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm-max\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .sm-max\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .sm-max\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .sm-max\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .sm-max\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm-max\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .sm-max\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .sm-max\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .sm-max\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm-max\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm-max\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm-max\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .sm-max\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm-max\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .sm-max\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm-max\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .sm-max\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm-max\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .sm-max\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm-max\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm-max\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm-max\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .sm-max\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm-max\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .sm-max\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm-max\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .sm-max\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm-max\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .sm-max\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm-max\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .sm-max\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm-max\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .sm-max\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .sm-max\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .sm-max\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm-max\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .sm-max\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .sm-max\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .sm-max\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .sm-max\:mt-0 {
    margin-top: 0;
  }

  .sm-max\:mt-1 {
    margin-top: .25rem;
  }

  .sm-max\:mt-2 {
    margin-top: .5rem;
  }

  .sm-max\:mt-3 {
    margin-top: .75rem;
  }

  .sm-max\:mt-4 {
    margin-top: 1rem;
  }

  .sm-max\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm-max\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm-max\:mt-7 {
    margin-top: 1.75rem;
  }

  .sm-max\:mt-8 {
    margin-top: 2rem;
  }

  .sm-max\:mt-9 {
    margin-top: 2.25rem;
  }

  .sm-max\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm-max\:mt-11 {
    margin-top: 2.75rem;
  }

  .sm-max\:mt-12 {
    margin-top: 3rem;
  }

  .sm-max\:mt-14 {
    margin-top: 3.5rem;
  }

  .sm-max\:mt-16 {
    margin-top: 4rem;
  }

  .sm-max\:mt-20 {
    margin-top: 5rem;
  }

  .sm-max\:mt-24 {
    margin-top: 6rem;
  }

  .sm-max\:mt-28 {
    margin-top: 7rem;
  }

  .sm-max\:mt-32 {
    margin-top: 8rem;
  }

  .sm-max\:mt-36 {
    margin-top: 9rem;
  }

  .sm-max\:mt-40 {
    margin-top: 10rem;
  }

  .sm-max\:mt-44 {
    margin-top: 11rem;
  }

  .sm-max\:mt-48 {
    margin-top: 12rem;
  }

  .sm-max\:mt-52 {
    margin-top: 13rem;
  }

  .sm-max\:mt-56 {
    margin-top: 14rem;
  }

  .sm-max\:mt-60 {
    margin-top: 15rem;
  }

  .sm-max\:mt-64 {
    margin-top: 16rem;
  }

  .sm-max\:mt-72 {
    margin-top: 18rem;
  }

  .sm-max\:mt-80 {
    margin-top: 20rem;
  }

  .sm-max\:mt-96 {
    margin-top: 24rem;
  }

  .sm-max\:mt-auto {
    margin-top: auto;
  }

  .sm-max\:mt-px {
    margin-top: 1px;
  }

  .sm-max\:mt-0\.5 {
    margin-top: .125rem;
  }

  .sm-max\:mt-1\.5 {
    margin-top: .375rem;
  }

  .sm-max\:mt-2\.5 {
    margin-top: .625rem;
  }

  .sm-max\:mt-3\.5 {
    margin-top: .875rem;
  }

  .sm-max\:-mt-0 {
    margin-top: 0;
  }

  .sm-max\:-mt-1 {
    margin-top: -.25rem;
  }

  .sm-max\:-mt-2 {
    margin-top: -.5rem;
  }

  .sm-max\:-mt-3 {
    margin-top: -.75rem;
  }

  .sm-max\:-mt-4 {
    margin-top: -1rem;
  }

  .sm-max\:-mt-5 {
    margin-top: -1.25rem;
  }

  .sm-max\:-mt-6 {
    margin-top: -1.5rem;
  }

  .sm-max\:-mt-7 {
    margin-top: -1.75rem;
  }

  .sm-max\:-mt-8 {
    margin-top: -2rem;
  }

  .sm-max\:-mt-9 {
    margin-top: -2.25rem;
  }

  .sm-max\:-mt-10 {
    margin-top: -2.5rem;
  }

  .sm-max\:-mt-11 {
    margin-top: -2.75rem;
  }

  .sm-max\:-mt-12 {
    margin-top: -3rem;
  }

  .sm-max\:-mt-14 {
    margin-top: -3.5rem;
  }

  .sm-max\:-mt-16 {
    margin-top: -4rem;
  }

  .sm-max\:-mt-20 {
    margin-top: -5rem;
  }

  .sm-max\:-mt-24 {
    margin-top: -6rem;
  }

  .sm-max\:-mt-28 {
    margin-top: -7rem;
  }

  .sm-max\:-mt-32 {
    margin-top: -8rem;
  }

  .sm-max\:-mt-36 {
    margin-top: -9rem;
  }

  .sm-max\:-mt-40 {
    margin-top: -10rem;
  }

  .sm-max\:-mt-44 {
    margin-top: -11rem;
  }

  .sm-max\:-mt-48 {
    margin-top: -12rem;
  }

  .sm-max\:-mt-52 {
    margin-top: -13rem;
  }

  .sm-max\:-mt-56 {
    margin-top: -14rem;
  }

  .sm-max\:-mt-60 {
    margin-top: -15rem;
  }

  .sm-max\:-mt-64 {
    margin-top: -16rem;
  }

  .sm-max\:-mt-72 {
    margin-top: -18rem;
  }

  .sm-max\:-mt-80 {
    margin-top: -20rem;
  }

  .sm-max\:-mt-96 {
    margin-top: -24rem;
  }

  .sm-max\:-mt-px {
    margin-top: -1px;
  }

  .sm-max\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm-max\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .sm-max\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .sm-max\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .sm-max\:mr-0 {
    margin-right: 0;
  }

  .sm-max\:mr-1 {
    margin-right: .25rem;
  }

  .sm-max\:mr-2 {
    margin-right: .5rem;
  }

  .sm-max\:mr-3 {
    margin-right: .75rem;
  }

  .sm-max\:mr-4 {
    margin-right: 1rem;
  }

  .sm-max\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm-max\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm-max\:mr-7 {
    margin-right: 1.75rem;
  }

  .sm-max\:mr-8 {
    margin-right: 2rem;
  }

  .sm-max\:mr-9 {
    margin-right: 2.25rem;
  }

  .sm-max\:mr-10 {
    margin-right: 2.5rem;
  }

  .sm-max\:mr-11 {
    margin-right: 2.75rem;
  }

  .sm-max\:mr-12 {
    margin-right: 3rem;
  }

  .sm-max\:mr-14 {
    margin-right: 3.5rem;
  }

  .sm-max\:mr-16 {
    margin-right: 4rem;
  }

  .sm-max\:mr-20 {
    margin-right: 5rem;
  }

  .sm-max\:mr-24 {
    margin-right: 6rem;
  }

  .sm-max\:mr-28 {
    margin-right: 7rem;
  }

  .sm-max\:mr-32 {
    margin-right: 8rem;
  }

  .sm-max\:mr-36 {
    margin-right: 9rem;
  }

  .sm-max\:mr-40 {
    margin-right: 10rem;
  }

  .sm-max\:mr-44 {
    margin-right: 11rem;
  }

  .sm-max\:mr-48 {
    margin-right: 12rem;
  }

  .sm-max\:mr-52 {
    margin-right: 13rem;
  }

  .sm-max\:mr-56 {
    margin-right: 14rem;
  }

  .sm-max\:mr-60 {
    margin-right: 15rem;
  }

  .sm-max\:mr-64 {
    margin-right: 16rem;
  }

  .sm-max\:mr-72 {
    margin-right: 18rem;
  }

  .sm-max\:mr-80 {
    margin-right: 20rem;
  }

  .sm-max\:mr-96 {
    margin-right: 24rem;
  }

  .sm-max\:mr-auto {
    margin-right: auto;
  }

  .sm-max\:mr-px {
    margin-right: 1px;
  }

  .sm-max\:mr-0\.5 {
    margin-right: .125rem;
  }

  .sm-max\:mr-1\.5 {
    margin-right: .375rem;
  }

  .sm-max\:mr-2\.5 {
    margin-right: .625rem;
  }

  .sm-max\:mr-3\.5 {
    margin-right: .875rem;
  }

  .sm-max\:-mr-0 {
    margin-right: 0;
  }

  .sm-max\:-mr-1 {
    margin-right: -.25rem;
  }

  .sm-max\:-mr-2 {
    margin-right: -.5rem;
  }

  .sm-max\:-mr-3 {
    margin-right: -.75rem;
  }

  .sm-max\:-mr-4 {
    margin-right: -1rem;
  }

  .sm-max\:-mr-5 {
    margin-right: -1.25rem;
  }

  .sm-max\:-mr-6 {
    margin-right: -1.5rem;
  }

  .sm-max\:-mr-7 {
    margin-right: -1.75rem;
  }

  .sm-max\:-mr-8 {
    margin-right: -2rem;
  }

  .sm-max\:-mr-9 {
    margin-right: -2.25rem;
  }

  .sm-max\:-mr-10 {
    margin-right: -2.5rem;
  }

  .sm-max\:-mr-11 {
    margin-right: -2.75rem;
  }

  .sm-max\:-mr-12 {
    margin-right: -3rem;
  }

  .sm-max\:-mr-14 {
    margin-right: -3.5rem;
  }

  .sm-max\:-mr-16 {
    margin-right: -4rem;
  }

  .sm-max\:-mr-20 {
    margin-right: -5rem;
  }

  .sm-max\:-mr-24 {
    margin-right: -6rem;
  }

  .sm-max\:-mr-28 {
    margin-right: -7rem;
  }

  .sm-max\:-mr-32 {
    margin-right: -8rem;
  }

  .sm-max\:-mr-36 {
    margin-right: -9rem;
  }

  .sm-max\:-mr-40 {
    margin-right: -10rem;
  }

  .sm-max\:-mr-44 {
    margin-right: -11rem;
  }

  .sm-max\:-mr-48 {
    margin-right: -12rem;
  }

  .sm-max\:-mr-52 {
    margin-right: -13rem;
  }

  .sm-max\:-mr-56 {
    margin-right: -14rem;
  }

  .sm-max\:-mr-60 {
    margin-right: -15rem;
  }

  .sm-max\:-mr-64 {
    margin-right: -16rem;
  }

  .sm-max\:-mr-72 {
    margin-right: -18rem;
  }

  .sm-max\:-mr-80 {
    margin-right: -20rem;
  }

  .sm-max\:-mr-96 {
    margin-right: -24rem;
  }

  .sm-max\:-mr-px {
    margin-right: -1px;
  }

  .sm-max\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .sm-max\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .sm-max\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .sm-max\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .sm-max\:mb-0 {
    margin-bottom: 0;
  }

  .sm-max\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm-max\:mb-2 {
    margin-bottom: .5rem;
  }

  .sm-max\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm-max\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm-max\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm-max\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm-max\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .sm-max\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm-max\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .sm-max\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm-max\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .sm-max\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm-max\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .sm-max\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm-max\:mb-20 {
    margin-bottom: 5rem;
  }

  .sm-max\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm-max\:mb-28 {
    margin-bottom: 7rem;
  }

  .sm-max\:mb-32 {
    margin-bottom: 8rem;
  }

  .sm-max\:mb-36 {
    margin-bottom: 9rem;
  }

  .sm-max\:mb-40 {
    margin-bottom: 10rem;
  }

  .sm-max\:mb-44 {
    margin-bottom: 11rem;
  }

  .sm-max\:mb-48 {
    margin-bottom: 12rem;
  }

  .sm-max\:mb-52 {
    margin-bottom: 13rem;
  }

  .sm-max\:mb-56 {
    margin-bottom: 14rem;
  }

  .sm-max\:mb-60 {
    margin-bottom: 15rem;
  }

  .sm-max\:mb-64 {
    margin-bottom: 16rem;
  }

  .sm-max\:mb-72 {
    margin-bottom: 18rem;
  }

  .sm-max\:mb-80 {
    margin-bottom: 20rem;
  }

  .sm-max\:mb-96 {
    margin-bottom: 24rem;
  }

  .sm-max\:mb-auto {
    margin-bottom: auto;
  }

  .sm-max\:mb-px {
    margin-bottom: 1px;
  }

  .sm-max\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .sm-max\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .sm-max\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .sm-max\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .sm-max\:-mb-0 {
    margin-bottom: 0;
  }

  .sm-max\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .sm-max\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .sm-max\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .sm-max\:-mb-4 {
    margin-bottom: -1rem;
  }

  .sm-max\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm-max\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm-max\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .sm-max\:-mb-8 {
    margin-bottom: -2rem;
  }

  .sm-max\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .sm-max\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm-max\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .sm-max\:-mb-12 {
    margin-bottom: -3rem;
  }

  .sm-max\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .sm-max\:-mb-16 {
    margin-bottom: -4rem;
  }

  .sm-max\:-mb-20 {
    margin-bottom: -5rem;
  }

  .sm-max\:-mb-24 {
    margin-bottom: -6rem;
  }

  .sm-max\:-mb-28 {
    margin-bottom: -7rem;
  }

  .sm-max\:-mb-32 {
    margin-bottom: -8rem;
  }

  .sm-max\:-mb-36 {
    margin-bottom: -9rem;
  }

  .sm-max\:-mb-40 {
    margin-bottom: -10rem;
  }

  .sm-max\:-mb-44 {
    margin-bottom: -11rem;
  }

  .sm-max\:-mb-48 {
    margin-bottom: -12rem;
  }

  .sm-max\:-mb-52 {
    margin-bottom: -13rem;
  }

  .sm-max\:-mb-56 {
    margin-bottom: -14rem;
  }

  .sm-max\:-mb-60 {
    margin-bottom: -15rem;
  }

  .sm-max\:-mb-64 {
    margin-bottom: -16rem;
  }

  .sm-max\:-mb-72 {
    margin-bottom: -18rem;
  }

  .sm-max\:-mb-80 {
    margin-bottom: -20rem;
  }

  .sm-max\:-mb-96 {
    margin-bottom: -24rem;
  }

  .sm-max\:-mb-px {
    margin-bottom: -1px;
  }

  .sm-max\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .sm-max\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .sm-max\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .sm-max\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .sm-max\:ml-0 {
    margin-left: 0;
  }

  .sm-max\:ml-1 {
    margin-left: .25rem;
  }

  .sm-max\:ml-2 {
    margin-left: .5rem;
  }

  .sm-max\:ml-3 {
    margin-left: .75rem;
  }

  .sm-max\:ml-4 {
    margin-left: 1rem;
  }

  .sm-max\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm-max\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm-max\:ml-7 {
    margin-left: 1.75rem;
  }

  .sm-max\:ml-8 {
    margin-left: 2rem;
  }

  .sm-max\:ml-9 {
    margin-left: 2.25rem;
  }

  .sm-max\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm-max\:ml-11 {
    margin-left: 2.75rem;
  }

  .sm-max\:ml-12 {
    margin-left: 3rem;
  }

  .sm-max\:ml-14 {
    margin-left: 3.5rem;
  }

  .sm-max\:ml-16 {
    margin-left: 4rem;
  }

  .sm-max\:ml-20 {
    margin-left: 5rem;
  }

  .sm-max\:ml-24 {
    margin-left: 6rem;
  }

  .sm-max\:ml-28 {
    margin-left: 7rem;
  }

  .sm-max\:ml-32 {
    margin-left: 8rem;
  }

  .sm-max\:ml-36 {
    margin-left: 9rem;
  }

  .sm-max\:ml-40 {
    margin-left: 10rem;
  }

  .sm-max\:ml-44 {
    margin-left: 11rem;
  }

  .sm-max\:ml-48 {
    margin-left: 12rem;
  }

  .sm-max\:ml-52 {
    margin-left: 13rem;
  }

  .sm-max\:ml-56 {
    margin-left: 14rem;
  }

  .sm-max\:ml-60 {
    margin-left: 15rem;
  }

  .sm-max\:ml-64 {
    margin-left: 16rem;
  }

  .sm-max\:ml-72 {
    margin-left: 18rem;
  }

  .sm-max\:ml-80 {
    margin-left: 20rem;
  }

  .sm-max\:ml-96 {
    margin-left: 24rem;
  }

  .sm-max\:ml-auto {
    margin-left: auto;
  }

  .sm-max\:ml-px {
    margin-left: 1px;
  }

  .sm-max\:ml-0\.5 {
    margin-left: .125rem;
  }

  .sm-max\:ml-1\.5 {
    margin-left: .375rem;
  }

  .sm-max\:ml-2\.5 {
    margin-left: .625rem;
  }

  .sm-max\:ml-3\.5 {
    margin-left: .875rem;
  }

  .sm-max\:-ml-0 {
    margin-left: 0;
  }

  .sm-max\:-ml-1 {
    margin-left: -.25rem;
  }

  .sm-max\:-ml-2 {
    margin-left: -.5rem;
  }

  .sm-max\:-ml-3 {
    margin-left: -.75rem;
  }

  .sm-max\:-ml-4 {
    margin-left: -1rem;
  }

  .sm-max\:-ml-5 {
    margin-left: -1.25rem;
  }

  .sm-max\:-ml-6 {
    margin-left: -1.5rem;
  }

  .sm-max\:-ml-7 {
    margin-left: -1.75rem;
  }

  .sm-max\:-ml-8 {
    margin-left: -2rem;
  }

  .sm-max\:-ml-9 {
    margin-left: -2.25rem;
  }

  .sm-max\:-ml-10 {
    margin-left: -2.5rem;
  }

  .sm-max\:-ml-11 {
    margin-left: -2.75rem;
  }

  .sm-max\:-ml-12 {
    margin-left: -3rem;
  }

  .sm-max\:-ml-14 {
    margin-left: -3.5rem;
  }

  .sm-max\:-ml-16 {
    margin-left: -4rem;
  }

  .sm-max\:-ml-20 {
    margin-left: -5rem;
  }

  .sm-max\:-ml-24 {
    margin-left: -6rem;
  }

  .sm-max\:-ml-28 {
    margin-left: -7rem;
  }

  .sm-max\:-ml-32 {
    margin-left: -8rem;
  }

  .sm-max\:-ml-36 {
    margin-left: -9rem;
  }

  .sm-max\:-ml-40 {
    margin-left: -10rem;
  }

  .sm-max\:-ml-44 {
    margin-left: -11rem;
  }

  .sm-max\:-ml-48 {
    margin-left: -12rem;
  }

  .sm-max\:-ml-52 {
    margin-left: -13rem;
  }

  .sm-max\:-ml-56 {
    margin-left: -14rem;
  }

  .sm-max\:-ml-60 {
    margin-left: -15rem;
  }

  .sm-max\:-ml-64 {
    margin-left: -16rem;
  }

  .sm-max\:-ml-72 {
    margin-left: -18rem;
  }

  .sm-max\:-ml-80 {
    margin-left: -20rem;
  }

  .sm-max\:-ml-96 {
    margin-left: -24rem;
  }

  .sm-max\:-ml-px {
    margin-left: -1px;
  }

  .sm-max\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .sm-max\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .sm-max\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .sm-max\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .sm-max\:box-border {
    box-sizing: border-box;
  }

  .sm-max\:box-content {
    box-sizing: content-box;
  }

  .sm-max\:block {
    display: block;
  }

  .sm-max\:inline-block {
    display: inline-block;
  }

  .sm-max\:inline {
    display: inline;
  }

  .sm-max\:flex {
    display: flex;
  }

  .sm-max\:inline-flex {
    display: inline-flex;
  }

  .sm-max\:table {
    display: table;
  }

  .sm-max\:inline-table {
    display: inline-table;
  }

  .sm-max\:table-caption {
    display: table-caption;
  }

  .sm-max\:table-cell {
    display: table-cell;
  }

  .sm-max\:table-column {
    display: table-column;
  }

  .sm-max\:table-column-group {
    display: table-column-group;
  }

  .sm-max\:table-footer-group {
    display: table-footer-group;
  }

  .sm-max\:table-header-group {
    display: table-header-group;
  }

  .sm-max\:table-row-group {
    display: table-row-group;
  }

  .sm-max\:table-row {
    display: table-row;
  }

  .sm-max\:flow-root {
    display: flow-root;
  }

  .sm-max\:grid {
    display: grid;
  }

  .sm-max\:inline-grid {
    display: inline-grid;
  }

  .sm-max\:contents {
    display: contents;
  }

  .sm-max\:list-item {
    display: list-item;
  }

  .sm-max\:hidden {
    display: none;
  }

  .sm-max\:h-0 {
    height: 0;
  }

  .sm-max\:h-1 {
    height: .25rem;
  }

  .sm-max\:h-2 {
    height: .5rem;
  }

  .sm-max\:h-3 {
    height: .75rem;
  }

  .sm-max\:h-4 {
    height: 1rem;
  }

  .sm-max\:h-5 {
    height: 1.25rem;
  }

  .sm-max\:h-6 {
    height: 1.5rem;
  }

  .sm-max\:h-7 {
    height: 1.75rem;
  }

  .sm-max\:h-8 {
    height: 2rem;
  }

  .sm-max\:h-9 {
    height: 2.25rem;
  }

  .sm-max\:h-10 {
    height: 2.5rem;
  }

  .sm-max\:h-11 {
    height: 2.75rem;
  }

  .sm-max\:h-12 {
    height: 3rem;
  }

  .sm-max\:h-14 {
    height: 3.5rem;
  }

  .sm-max\:h-16 {
    height: 4rem;
  }

  .sm-max\:h-20 {
    height: 5rem;
  }

  .sm-max\:h-24 {
    height: 6rem;
  }

  .sm-max\:h-28 {
    height: 7rem;
  }

  .sm-max\:h-32 {
    height: 8rem;
  }

  .sm-max\:h-36 {
    height: 9rem;
  }

  .sm-max\:h-40 {
    height: 10rem;
  }

  .sm-max\:h-44 {
    height: 11rem;
  }

  .sm-max\:h-48 {
    height: 12rem;
  }

  .sm-max\:h-52 {
    height: 13rem;
  }

  .sm-max\:h-56 {
    height: 14rem;
  }

  .sm-max\:h-60 {
    height: 15rem;
  }

  .sm-max\:h-64 {
    height: 16rem;
  }

  .sm-max\:h-72 {
    height: 18rem;
  }

  .sm-max\:h-80 {
    height: 20rem;
  }

  .sm-max\:h-96 {
    height: 24rem;
  }

  .sm-max\:h-auto {
    height: auto;
  }

  .sm-max\:h-px {
    height: 1px;
  }

  .sm-max\:h-0\.5 {
    height: .125rem;
  }

  .sm-max\:h-1\.5 {
    height: .375rem;
  }

  .sm-max\:h-2\.5 {
    height: .625rem;
  }

  .sm-max\:h-3\.5 {
    height: .875rem;
  }

  .sm-max\:h-1\/2 {
    height: 50%;
  }

  .sm-max\:h-1\/3 {
    height: 33.3333%;
  }

  .sm-max\:h-2\/3 {
    height: 66.6667%;
  }

  .sm-max\:h-1\/4 {
    height: 25%;
  }

  .sm-max\:h-2\/4 {
    height: 50%;
  }

  .sm-max\:h-3\/4 {
    height: 75%;
  }

  .sm-max\:h-1\/5 {
    height: 20%;
  }

  .sm-max\:h-2\/5 {
    height: 40%;
  }

  .sm-max\:h-3\/5 {
    height: 60%;
  }

  .sm-max\:h-4\/5 {
    height: 80%;
  }

  .sm-max\:h-1\/6 {
    height: 16.6667%;
  }

  .sm-max\:h-2\/6 {
    height: 33.3333%;
  }

  .sm-max\:h-3\/6 {
    height: 50%;
  }

  .sm-max\:h-4\/6 {
    height: 66.6667%;
  }

  .sm-max\:h-5\/6 {
    height: 83.3333%;
  }

  .sm-max\:h-full {
    height: 100%;
  }

  .sm-max\:h-screen {
    height: 100vh;
  }

  .sm-max\:max-h-0 {
    max-height: 0;
  }

  .sm-max\:max-h-1 {
    max-height: .25rem;
  }

  .sm-max\:max-h-2 {
    max-height: .5rem;
  }

  .sm-max\:max-h-3 {
    max-height: .75rem;
  }

  .sm-max\:max-h-4 {
    max-height: 1rem;
  }

  .sm-max\:max-h-5 {
    max-height: 1.25rem;
  }

  .sm-max\:max-h-6 {
    max-height: 1.5rem;
  }

  .sm-max\:max-h-7 {
    max-height: 1.75rem;
  }

  .sm-max\:max-h-8 {
    max-height: 2rem;
  }

  .sm-max\:max-h-9 {
    max-height: 2.25rem;
  }

  .sm-max\:max-h-10 {
    max-height: 2.5rem;
  }

  .sm-max\:max-h-11 {
    max-height: 2.75rem;
  }

  .sm-max\:max-h-12 {
    max-height: 3rem;
  }

  .sm-max\:max-h-14 {
    max-height: 3.5rem;
  }

  .sm-max\:max-h-16 {
    max-height: 4rem;
  }

  .sm-max\:max-h-20 {
    max-height: 5rem;
  }

  .sm-max\:max-h-24 {
    max-height: 6rem;
  }

  .sm-max\:max-h-28 {
    max-height: 7rem;
  }

  .sm-max\:max-h-32 {
    max-height: 8rem;
  }

  .sm-max\:max-h-36 {
    max-height: 9rem;
  }

  .sm-max\:max-h-40 {
    max-height: 10rem;
  }

  .sm-max\:max-h-44 {
    max-height: 11rem;
  }

  .sm-max\:max-h-48 {
    max-height: 12rem;
  }

  .sm-max\:max-h-52 {
    max-height: 13rem;
  }

  .sm-max\:max-h-56 {
    max-height: 14rem;
  }

  .sm-max\:max-h-60 {
    max-height: 15rem;
  }

  .sm-max\:max-h-64 {
    max-height: 16rem;
  }

  .sm-max\:max-h-72 {
    max-height: 18rem;
  }

  .sm-max\:max-h-80 {
    max-height: 20rem;
  }

  .sm-max\:max-h-96 {
    max-height: 24rem;
  }

  .sm-max\:max-h-px {
    max-height: 1px;
  }

  .sm-max\:max-h-0\.5 {
    max-height: .125rem;
  }

  .sm-max\:max-h-1\.5 {
    max-height: .375rem;
  }

  .sm-max\:max-h-2\.5 {
    max-height: .625rem;
  }

  .sm-max\:max-h-3\.5 {
    max-height: .875rem;
  }

  .sm-max\:max-h-full {
    max-height: 100%;
  }

  .sm-max\:max-h-screen {
    max-height: 100vh;
  }

  .sm-max\:min-h-0 {
    min-height: 0;
  }

  .sm-max\:min-h-full {
    min-height: 100%;
  }

  .sm-max\:min-h-screen {
    min-height: 100vh;
  }

  .sm-max\:w-0 {
    width: 0;
  }

  .sm-max\:w-1 {
    width: .25rem;
  }

  .sm-max\:w-2 {
    width: .5rem;
  }

  .sm-max\:w-3 {
    width: .75rem;
  }

  .sm-max\:w-4 {
    width: 1rem;
  }

  .sm-max\:w-5 {
    width: 1.25rem;
  }

  .sm-max\:w-6 {
    width: 1.5rem;
  }

  .sm-max\:w-7 {
    width: 1.75rem;
  }

  .sm-max\:w-8 {
    width: 2rem;
  }

  .sm-max\:w-9 {
    width: 2.25rem;
  }

  .sm-max\:w-10 {
    width: 2.5rem;
  }

  .sm-max\:w-11 {
    width: 2.75rem;
  }

  .sm-max\:w-12 {
    width: 3rem;
  }

  .sm-max\:w-14 {
    width: 3.5rem;
  }

  .sm-max\:w-16 {
    width: 4rem;
  }

  .sm-max\:w-20 {
    width: 5rem;
  }

  .sm-max\:w-24 {
    width: 6rem;
  }

  .sm-max\:w-28 {
    width: 7rem;
  }

  .sm-max\:w-32 {
    width: 8rem;
  }

  .sm-max\:w-36 {
    width: 9rem;
  }

  .sm-max\:w-40 {
    width: 10rem;
  }

  .sm-max\:w-44 {
    width: 11rem;
  }

  .sm-max\:w-48 {
    width: 12rem;
  }

  .sm-max\:w-52 {
    width: 13rem;
  }

  .sm-max\:w-56 {
    width: 14rem;
  }

  .sm-max\:w-60 {
    width: 15rem;
  }

  .sm-max\:w-64 {
    width: 16rem;
  }

  .sm-max\:w-72 {
    width: 18rem;
  }

  .sm-max\:w-80 {
    width: 20rem;
  }

  .sm-max\:w-96 {
    width: 24rem;
  }

  .sm-max\:w-auto {
    width: auto;
  }

  .sm-max\:w-px {
    width: 1px;
  }

  .sm-max\:w-0\.5 {
    width: .125rem;
  }

  .sm-max\:w-1\.5 {
    width: .375rem;
  }

  .sm-max\:w-2\.5 {
    width: .625rem;
  }

  .sm-max\:w-3\.5 {
    width: .875rem;
  }

  .sm-max\:w-1\/2 {
    width: 50%;
  }

  .sm-max\:w-1\/3 {
    width: 33.3333%;
  }

  .sm-max\:w-2\/3 {
    width: 66.6667%;
  }

  .sm-max\:w-1\/4 {
    width: 25%;
  }

  .sm-max\:w-2\/4 {
    width: 50%;
  }

  .sm-max\:w-3\/4 {
    width: 75%;
  }

  .sm-max\:w-1\/5 {
    width: 20%;
  }

  .sm-max\:w-2\/5 {
    width: 40%;
  }

  .sm-max\:w-3\/5 {
    width: 60%;
  }

  .sm-max\:w-4\/5 {
    width: 80%;
  }

  .sm-max\:w-1\/6 {
    width: 16.6667%;
  }

  .sm-max\:w-2\/6 {
    width: 33.3333%;
  }

  .sm-max\:w-3\/6 {
    width: 50%;
  }

  .sm-max\:w-4\/6 {
    width: 66.6667%;
  }

  .sm-max\:w-5\/6 {
    width: 83.3333%;
  }

  .sm-max\:w-1\/12 {
    width: 8.33333%;
  }

  .sm-max\:w-2\/12 {
    width: 16.6667%;
  }

  .sm-max\:w-3\/12 {
    width: 25%;
  }

  .sm-max\:w-4\/12 {
    width: 33.3333%;
  }

  .sm-max\:w-5\/12 {
    width: 41.6667%;
  }

  .sm-max\:w-6\/12 {
    width: 50%;
  }

  .sm-max\:w-7\/12 {
    width: 58.3333%;
  }

  .sm-max\:w-8\/12 {
    width: 66.6667%;
  }

  .sm-max\:w-9\/12 {
    width: 75%;
  }

  .sm-max\:w-10\/12 {
    width: 83.3333%;
  }

  .sm-max\:w-11\/12 {
    width: 91.6667%;
  }

  .sm-max\:w-full {
    width: 100%;
  }

  .sm-max\:w-screen {
    width: 100vw;
  }

  .sm-max\:w-min {
    width: min-content;
  }

  .sm-max\:w-max {
    width: max-content;
  }

  .sm-max\:min-w-0 {
    min-width: 0;
  }

  .sm-max\:min-w-full {
    min-width: 100%;
  }

  .sm-max\:min-w-min {
    min-width: min-content;
  }

  .sm-max\:min-w-max {
    min-width: max-content;
  }

  .sm-max\:max-w-0 {
    max-width: 0;
  }

  .sm-max\:max-w-none {
    max-width: none;
  }

  .sm-max\:max-w-xs {
    max-width: 20rem;
  }

  .sm-max\:max-w-sm {
    max-width: 24rem;
  }

  .sm-max\:max-w-md {
    max-width: 28rem;
  }

  .sm-max\:max-w-lg {
    max-width: 32rem;
  }

  .sm-max\:max-w-xl {
    max-width: 36rem;
  }

  .sm-max\:max-w-2xl {
    max-width: 42rem;
  }

  .sm-max\:max-w-3xl {
    max-width: 48rem;
  }

  .sm-max\:max-w-4xl {
    max-width: 56rem;
  }

  .sm-max\:max-w-5xl {
    max-width: 64rem;
  }

  .sm-max\:max-w-6xl {
    max-width: 72rem;
  }

  .sm-max\:max-w-7xl {
    max-width: 80rem;
  }

  .sm-max\:max-w-full {
    max-width: 100%;
  }

  .sm-max\:max-w-min {
    max-width: min-content;
  }

  .sm-max\:max-w-max {
    max-width: max-content;
  }

  .sm-max\:max-w-prose {
    max-width: 65ch;
  }

  .sm-max\:max-w-screen-sm {
    max-width: 640px;
  }

  .sm-max\:max-w-screen-md {
    max-width: 768px;
  }

  .sm-max\:max-w-screen-lg {
    max-width: 1024px;
  }

  .sm-max\:max-w-screen-xl {
    max-width: 1280px;
  }

  .sm-max\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .sm-max\:flex-1 {
    flex: 1;
  }

  .sm-max\:flex-auto {
    flex: auto;
  }

  .sm-max\:flex-initial {
    flex: 0 auto;
  }

  .sm-max\:flex-none {
    flex: none;
  }

  .sm-max\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm-max\:flex-shrink {
    flex-shrink: 1;
  }

  .sm-max\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm-max\:flex-grow {
    flex-grow: 1;
  }

  .sm-max\:table-auto {
    table-layout: auto;
  }

  .sm-max\:table-fixed {
    table-layout: fixed;
  }

  .sm-max\:border-collapse {
    border-collapse: collapse;
  }

  .sm-max\:border-separate {
    border-collapse: separate;
  }

  .sm-max\:origin-center {
    transform-origin: center;
  }

  .sm-max\:origin-top {
    transform-origin: top;
  }

  .sm-max\:origin-top-right {
    transform-origin: 100% 0;
  }

  .sm-max\:origin-right {
    transform-origin: 100%;
  }

  .sm-max\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .sm-max\:origin-bottom {
    transform-origin: bottom;
  }

  .sm-max\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .sm-max\:origin-left {
    transform-origin: 0;
  }

  .sm-max\:origin-top-left {
    transform-origin: 0 0;
  }

  .sm-max\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm-max\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm-max\:transform-none {
    transform: none;
  }

  .sm-max\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .sm-max\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .sm-max\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .sm-max\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .sm-max\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .sm-max\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .sm-max\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .sm-max\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .sm-max\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .sm-max\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .sm-max\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .sm-max\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .sm-max\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .sm-max\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .sm-max\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .sm-max\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .sm-max\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .sm-max\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .sm-max\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .sm-max\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .sm-max\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .sm-max\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .sm-max\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .sm-max\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .sm-max\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .sm-max\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .sm-max\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .sm-max\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .sm-max\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .sm-max\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .sm-max\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .sm-max\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .sm-max\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .sm-max\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .sm-max\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .sm-max\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .sm-max\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .sm-max\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .sm-max\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .sm-max\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .sm-max\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .sm-max\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .sm-max\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .sm-max\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .sm-max\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .sm-max\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .sm-max\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .sm-max\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .sm-max\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .sm-max\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .sm-max\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .sm-max\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .sm-max\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .sm-max\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .sm-max\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .sm-max\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .sm-max\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .sm-max\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .sm-max\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .sm-max\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .sm-max\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .sm-max\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .sm-max\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .sm-max\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .sm-max\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .sm-max\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .sm-max\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .sm-max\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .sm-max\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .sm-max\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .sm-max\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .sm-max\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .sm-max\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .sm-max\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .sm-max\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .sm-max\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .sm-max\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .sm-max\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .sm-max\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .sm-max\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .sm-max\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .sm-max\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .sm-max\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .sm-max\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .sm-max\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .sm-max\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .sm-max\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .sm-max\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .sm-max\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .sm-max\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .sm-max\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .sm-max\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .sm-max\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .sm-max\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .sm-max\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .sm-max\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .sm-max\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .sm-max\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .sm-max\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .sm-max\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .sm-max\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .sm-max\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .sm-max\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .sm-max\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .sm-max\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .sm-max\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .sm-max\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .sm-max\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .sm-max\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .sm-max\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .sm-max\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .sm-max\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .sm-max\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .sm-max\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .sm-max\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .sm-max\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .sm-max\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .sm-max\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .sm-max\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .sm-max\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .sm-max\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .sm-max\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .sm-max\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .sm-max\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .sm-max\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .sm-max\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .sm-max\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .sm-max\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .sm-max\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .sm-max\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .sm-max\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .sm-max\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .sm-max\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .sm-max\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .sm-max\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .sm-max\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .sm-max\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .sm-max\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .sm-max\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .sm-max\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .sm-max\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .sm-max\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .sm-max\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .sm-max\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .sm-max\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .sm-max\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .sm-max\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .sm-max\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .sm-max\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .sm-max\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .sm-max\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .sm-max\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .sm-max\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .sm-max\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .sm-max\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .sm-max\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .sm-max\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .sm-max\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .sm-max\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .sm-max\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .sm-max\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .sm-max\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .sm-max\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .sm-max\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .sm-max\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .sm-max\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .sm-max\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .sm-max\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .sm-max\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .sm-max\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .sm-max\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .sm-max\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .sm-max\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .sm-max\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .sm-max\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .sm-max\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .sm-max\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .sm-max\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .sm-max\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .sm-max\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .sm-max\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .sm-max\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .sm-max\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .sm-max\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .sm-max\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .sm-max\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .sm-max\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .sm-max\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .sm-max\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .sm-max\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .sm-max\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .sm-max\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .sm-max\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .sm-max\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .sm-max\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .sm-max\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .sm-max\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .sm-max\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .sm-max\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .sm-max\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .sm-max\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .sm-max\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .sm-max\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .sm-max\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .sm-max\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .sm-max\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .sm-max\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .sm-max\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .sm-max\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .sm-max\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .sm-max\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .sm-max\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .sm-max\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .sm-max\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .sm-max\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .sm-max\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .sm-max\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .sm-max\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .sm-max\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .sm-max\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .sm-max\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .sm-max\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .sm-max\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .sm-max\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .sm-max\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .sm-max\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .sm-max\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .sm-max\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .sm-max\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .sm-max\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .sm-max\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .sm-max\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .sm-max\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .sm-max\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .sm-max\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .sm-max\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .sm-max\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .sm-max\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .sm-max\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .sm-max\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .sm-max\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .sm-max\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .sm-max\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .sm-max\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .sm-max\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .sm-max\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .sm-max\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .sm-max\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .sm-max\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .sm-max\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .sm-max\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .sm-max\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .sm-max\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .sm-max\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .sm-max\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .sm-max\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .sm-max\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .sm-max\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .sm-max\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .sm-max\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .sm-max\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .sm-max\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .sm-max\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .sm-max\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .sm-max\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .sm-max\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .sm-max\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .sm-max\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .sm-max\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .sm-max\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .sm-max\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .sm-max\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .sm-max\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .sm-max\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .sm-max\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .sm-max\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .sm-max\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .sm-max\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .sm-max\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .sm-max\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .sm-max\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .sm-max\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .sm-max\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .sm-max\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .sm-max\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .sm-max\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .sm-max\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .sm-max\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .sm-max\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .sm-max\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .sm-max\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .sm-max\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .sm-max\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .sm-max\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .sm-max\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .sm-max\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .sm-max\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .sm-max\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .sm-max\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .sm-max\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .sm-max\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .sm-max\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .sm-max\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .sm-max\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .sm-max\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .sm-max\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .sm-max\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .sm-max\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .sm-max\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .sm-max\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .sm-max\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .sm-max\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .sm-max\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .sm-max\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .sm-max\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .sm-max\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .sm-max\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .sm-max\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .sm-max\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .sm-max\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .sm-max\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .sm-max\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .sm-max\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .sm-max\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .sm-max\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .sm-max\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .sm-max\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .sm-max\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .sm-max\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .sm-max\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .sm-max\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .sm-max\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .sm-max\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .sm-max\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .sm-max\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .sm-max\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .sm-max\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .sm-max\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .sm-max\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .sm-max\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .sm-max\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .sm-max\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .sm-max\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .sm-max\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .sm-max\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .sm-max\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .sm-max\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .sm-max\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .sm-max\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .sm-max\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .sm-max\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .sm-max\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .sm-max\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .sm-max\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .sm-max\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .sm-max\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .sm-max\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .sm-max\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .sm-max\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .sm-max\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .sm-max\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .sm-max\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .sm-max\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .sm-max\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .sm-max\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .sm-max\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .sm-max\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .sm-max\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .sm-max\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .sm-max\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .sm-max\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .sm-max\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .sm-max\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .sm-max\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .sm-max\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .sm-max\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .sm-max\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .sm-max\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .sm-max\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .sm-max\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .sm-max\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .sm-max\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .sm-max\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .sm-max\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .sm-max\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .sm-max\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .sm-max\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .sm-max\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .sm-max\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .sm-max\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .sm-max\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .sm-max\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .sm-max\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .sm-max\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .sm-max\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .sm-max\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .sm-max\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .sm-max\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .sm-max\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .sm-max\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .sm-max\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .sm-max\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .sm-max\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .sm-max\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .sm-max\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .sm-max\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .sm-max\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .sm-max\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .sm-max\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .sm-max\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .sm-max\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .sm-max\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .sm-max\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .sm-max\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .sm-max\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .sm-max\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .sm-max\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .sm-max\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .sm-max\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .sm-max\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .sm-max\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .sm-max\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .sm-max\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .sm-max\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .sm-max\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .sm-max\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .sm-max\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .sm-max\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .sm-max\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .sm-max\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .sm-max\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .sm-max\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .sm-max\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .sm-max\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .sm-max\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .sm-max\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .sm-max\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .sm-max\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .sm-max\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .sm-max\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .sm-max\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .sm-max\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .sm-max\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .sm-max\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .sm-max\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .sm-max\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .sm-max\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .sm-max\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .sm-max\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .sm-max\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .sm-max\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .sm-max\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .sm-max\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .sm-max\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .sm-max\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .sm-max\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .sm-max\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .sm-max\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .sm-max\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .sm-max\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .sm-max\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .sm-max\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .sm-max\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .sm-max\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .sm-max\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .sm-max\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .sm-max\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .sm-max\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .sm-max\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .sm-max\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .sm-max\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .sm-max\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .sm-max\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .sm-max\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .sm-max\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .sm-max\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .sm-max\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .sm-max\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .sm-max\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .sm-max\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .sm-max\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .sm-max\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .sm-max\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .sm-max\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .sm-max\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .sm-max\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .sm-max\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .sm-max\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .sm-max\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .sm-max\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .sm-max\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .sm-max\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .sm-max\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .sm-max\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .sm-max\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .sm-max\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .sm-max\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .sm-max\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .sm-max\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .sm-max\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .sm-max\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .sm-max\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .sm-max\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .sm-max\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .sm-max\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .sm-max\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .sm-max\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .sm-max\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .sm-max\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .sm-max\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .sm-max\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .sm-max\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .sm-max\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .sm-max\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .sm-max\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .sm-max\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .sm-max\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .sm-max\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .sm-max\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .sm-max\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .sm-max\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .sm-max\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .sm-max\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .sm-max\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .sm-max\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .sm-max\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .sm-max\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .sm-max\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .sm-max\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .sm-max\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .sm-max\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .sm-max\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .sm-max\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .sm-max\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .sm-max\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .sm-max\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .sm-max\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .sm-max\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .sm-max\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .sm-max\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .sm-max\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .sm-max\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .sm-max\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .sm-max\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .sm-max\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .sm-max\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .sm-max\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .sm-max\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .sm-max\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .sm-max\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .sm-max\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .sm-max\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .sm-max\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .sm-max\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .sm-max\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .sm-max\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .sm-max\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .sm-max\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .sm-max\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .sm-max\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .sm-max\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .sm-max\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .sm-max\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .sm-max\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .sm-max\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .sm-max\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .sm-max\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .sm-max\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .sm-max\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .sm-max\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .sm-max\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .sm-max\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .sm-max\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .sm-max\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .sm-max\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .sm-max\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .sm-max\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .sm-max\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .sm-max\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .sm-max\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .sm-max\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .sm-max\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .sm-max\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .sm-max\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .sm-max\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .sm-max\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .sm-max\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .sm-max\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .sm-max\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .sm-max\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .sm-max\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .sm-max\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .sm-max\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .sm-max\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .sm-max\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .sm-max\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .sm-max\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .sm-max\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .sm-max\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .sm-max\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .sm-max\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .sm-max\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .sm-max\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .sm-max\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .sm-max\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .sm-max\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .sm-max\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .sm-max\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .sm-max\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .sm-max\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .sm-max\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .sm-max\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .sm-max\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .sm-max\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .sm-max\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .sm-max\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .sm-max\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .sm-max\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .sm-max\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .sm-max\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .sm-max\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .sm-max\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm-max\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm-max\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm-max\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm-max\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm-max\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm-max\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm-max\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm-max\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm-max\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm-max\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm-max\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm-max\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm-max\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm-max\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm-max\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm-max\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm-max\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm-max\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm-max\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm-max\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm-max\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm-max\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm-max\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm-max\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm-max\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm-max\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm-max\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm-max\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm-max\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm-max\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .sm-max\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .sm-max\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .sm-max\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .sm-max\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .sm-max\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .sm-max\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .sm-max\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .sm-max\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .sm-max\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .sm-max\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .sm-max\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .sm-max\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .sm-max\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .sm-max\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .sm-max\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .sm-max\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .sm-max\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .sm-max\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .sm-max\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .sm-max\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .sm-max\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .sm-max\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .sm-max\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .sm-max\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .sm-max\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .sm-max\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .sm-max\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .sm-max\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .sm-max\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .sm-max\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .sm-max\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .sm-max\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .sm-max\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .sm-max\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .sm-max\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .sm-max\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .sm-max\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .sm-max\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .sm-max\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .sm-max\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .sm-max\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .sm-max\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .sm-max\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .sm-max\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .sm-max\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .sm-max\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .sm-max\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .sm-max\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .sm-max\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .sm-max\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .sm-max\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .sm-max\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .sm-max\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .sm-max\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .sm-max\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .sm-max\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .sm-max\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .sm-max\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .sm-max\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .sm-max\:animate-none {
    animation: none;
  }

  .sm-max\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .sm-max\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .sm-max\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .sm-max\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .sm-max\:cursor-auto {
    cursor: auto;
  }

  .sm-max\:cursor-default {
    cursor: default;
  }

  .sm-max\:cursor-pointer {
    cursor: pointer;
  }

  .sm-max\:cursor-wait {
    cursor: wait;
  }

  .sm-max\:cursor-text {
    cursor: text;
  }

  .sm-max\:cursor-move {
    cursor: move;
  }

  .sm-max\:cursor-help {
    cursor: help;
  }

  .sm-max\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm-max\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .sm-max\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .sm-max\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .sm-max\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .sm-max\:resize-none {
    resize: none;
  }

  .sm-max\:resize-y {
    resize: vertical;
  }

  .sm-max\:resize-x {
    resize: horizontal;
  }

  .sm-max\:resize {
    resize: both;
  }

  .sm-max\:list-inside {
    list-style-position: inside;
  }

  .sm-max\:list-outside {
    list-style-position: outside;
  }

  .sm-max\:list-none {
    list-style-type: none;
  }

  .sm-max\:list-disc {
    list-style-type: disc;
  }

  .sm-max\:list-decimal {
    list-style-type: decimal;
  }

  .sm-max\:appearance-none {
    appearance: none;
  }

  .sm-max\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm-max\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .sm-max\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .sm-max\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm-max\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm-max\:grid-flow-col {
    grid-auto-flow: column;
  }

  .sm-max\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm-max\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm-max\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm-max\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .sm-max\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .sm-max\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm-max\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm-max\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm-max\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm-max\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm-max\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm-max\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm-max\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm-max\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm-max\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm-max\:flex-row {
    flex-direction: row;
  }

  .sm-max\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm-max\:flex-col {
    flex-direction: column;
  }

  .sm-max\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm-max\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm-max\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm-max\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm-max\:place-content-center {
    place-content: center;
  }

  .sm-max\:place-content-start {
    place-content: start;
  }

  .sm-max\:place-content-end {
    place-content: end;
  }

  .sm-max\:place-content-between {
    place-content: space-between;
  }

  .sm-max\:place-content-around {
    place-content: space-around;
  }

  .sm-max\:place-content-evenly {
    place-content: space-evenly;
  }

  .sm-max\:place-content-stretch {
    place-content: stretch;
  }

  .sm-max\:place-items-start {
    place-items: start;
  }

  .sm-max\:place-items-end {
    place-items: end;
  }

  .sm-max\:place-items-center {
    place-items: center;
  }

  .sm-max\:place-items-stretch {
    place-items: stretch stretch;
  }

  .sm-max\:content-center {
    align-content: center;
  }

  .sm-max\:content-start {
    align-content: flex-start;
  }

  .sm-max\:content-end {
    align-content: flex-end;
  }

  .sm-max\:content-between {
    align-content: space-between;
  }

  .sm-max\:content-around {
    align-content: space-around;
  }

  .sm-max\:content-evenly {
    align-content: space-evenly;
  }

  .sm-max\:items-start {
    align-items: flex-start;
  }

  .sm-max\:items-end {
    align-items: flex-end;
  }

  .sm-max\:items-center {
    align-items: center;
  }

  .sm-max\:items-baseline {
    align-items: baseline;
  }

  .sm-max\:items-stretch {
    align-items: stretch;
  }

  .sm-max\:justify-start {
    justify-content: flex-start;
  }

  .sm-max\:justify-end {
    justify-content: flex-end;
  }

  .sm-max\:justify-center {
    justify-content: center;
  }

  .sm-max\:justify-between {
    justify-content: space-between;
  }

  .sm-max\:justify-around {
    justify-content: space-around;
  }

  .sm-max\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm-max\:justify-items-start {
    justify-items: start;
  }

  .sm-max\:justify-items-end {
    justify-items: end;
  }

  .sm-max\:justify-items-center {
    justify-items: center;
  }

  .sm-max\:justify-items-stretch {
    justify-items: stretch;
  }

  .sm-max\:gap-0 {
    gap: 0;
  }

  .sm-max\:gap-1 {
    gap: .25rem;
  }

  .sm-max\:gap-2 {
    gap: .5rem;
  }

  .sm-max\:gap-3 {
    gap: .75rem;
  }

  .sm-max\:gap-4 {
    gap: 1rem;
  }

  .sm-max\:gap-5 {
    gap: 1.25rem;
  }

  .sm-max\:gap-6 {
    gap: 1.5rem;
  }

  .sm-max\:gap-7 {
    gap: 1.75rem;
  }

  .sm-max\:gap-8 {
    gap: 2rem;
  }

  .sm-max\:gap-9 {
    gap: 2.25rem;
  }

  .sm-max\:gap-10 {
    gap: 2.5rem;
  }

  .sm-max\:gap-11 {
    gap: 2.75rem;
  }

  .sm-max\:gap-12 {
    gap: 3rem;
  }

  .sm-max\:gap-14 {
    gap: 3.5rem;
  }

  .sm-max\:gap-16 {
    gap: 4rem;
  }

  .sm-max\:gap-20 {
    gap: 5rem;
  }

  .sm-max\:gap-24 {
    gap: 6rem;
  }

  .sm-max\:gap-28 {
    gap: 7rem;
  }

  .sm-max\:gap-32 {
    gap: 8rem;
  }

  .sm-max\:gap-36 {
    gap: 9rem;
  }

  .sm-max\:gap-40 {
    gap: 10rem;
  }

  .sm-max\:gap-44 {
    gap: 11rem;
  }

  .sm-max\:gap-48 {
    gap: 12rem;
  }

  .sm-max\:gap-52 {
    gap: 13rem;
  }

  .sm-max\:gap-56 {
    gap: 14rem;
  }

  .sm-max\:gap-60 {
    gap: 15rem;
  }

  .sm-max\:gap-64 {
    gap: 16rem;
  }

  .sm-max\:gap-72 {
    gap: 18rem;
  }

  .sm-max\:gap-80 {
    gap: 20rem;
  }

  .sm-max\:gap-96 {
    gap: 24rem;
  }

  .sm-max\:gap-px {
    gap: 1px;
  }

  .sm-max\:gap-0\.5 {
    gap: .125rem;
  }

  .sm-max\:gap-1\.5 {
    gap: .375rem;
  }

  .sm-max\:gap-2\.5 {
    gap: .625rem;
  }

  .sm-max\:gap-3\.5 {
    gap: .875rem;
  }

  .sm-max\:gap-x-0 {
    column-gap: 0;
  }

  .sm-max\:gap-x-1 {
    column-gap: .25rem;
  }

  .sm-max\:gap-x-2 {
    column-gap: .5rem;
  }

  .sm-max\:gap-x-3 {
    column-gap: .75rem;
  }

  .sm-max\:gap-x-4 {
    column-gap: 1rem;
  }

  .sm-max\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .sm-max\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm-max\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .sm-max\:gap-x-8 {
    column-gap: 2rem;
  }

  .sm-max\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .sm-max\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .sm-max\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .sm-max\:gap-x-12 {
    column-gap: 3rem;
  }

  .sm-max\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .sm-max\:gap-x-16 {
    column-gap: 4rem;
  }

  .sm-max\:gap-x-20 {
    column-gap: 5rem;
  }

  .sm-max\:gap-x-24 {
    column-gap: 6rem;
  }

  .sm-max\:gap-x-28 {
    column-gap: 7rem;
  }

  .sm-max\:gap-x-32 {
    column-gap: 8rem;
  }

  .sm-max\:gap-x-36 {
    column-gap: 9rem;
  }

  .sm-max\:gap-x-40 {
    column-gap: 10rem;
  }

  .sm-max\:gap-x-44 {
    column-gap: 11rem;
  }

  .sm-max\:gap-x-48 {
    column-gap: 12rem;
  }

  .sm-max\:gap-x-52 {
    column-gap: 13rem;
  }

  .sm-max\:gap-x-56 {
    column-gap: 14rem;
  }

  .sm-max\:gap-x-60 {
    column-gap: 15rem;
  }

  .sm-max\:gap-x-64 {
    column-gap: 16rem;
  }

  .sm-max\:gap-x-72 {
    column-gap: 18rem;
  }

  .sm-max\:gap-x-80 {
    column-gap: 20rem;
  }

  .sm-max\:gap-x-96 {
    column-gap: 24rem;
  }

  .sm-max\:gap-x-px {
    column-gap: 1px;
  }

  .sm-max\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .sm-max\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .sm-max\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .sm-max\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .sm-max\:gap-y-0 {
    row-gap: 0;
  }

  .sm-max\:gap-y-1 {
    row-gap: .25rem;
  }

  .sm-max\:gap-y-2 {
    row-gap: .5rem;
  }

  .sm-max\:gap-y-3 {
    row-gap: .75rem;
  }

  .sm-max\:gap-y-4 {
    row-gap: 1rem;
  }

  .sm-max\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .sm-max\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .sm-max\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .sm-max\:gap-y-8 {
    row-gap: 2rem;
  }

  .sm-max\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .sm-max\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .sm-max\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .sm-max\:gap-y-12 {
    row-gap: 3rem;
  }

  .sm-max\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .sm-max\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm-max\:gap-y-20 {
    row-gap: 5rem;
  }

  .sm-max\:gap-y-24 {
    row-gap: 6rem;
  }

  .sm-max\:gap-y-28 {
    row-gap: 7rem;
  }

  .sm-max\:gap-y-32 {
    row-gap: 8rem;
  }

  .sm-max\:gap-y-36 {
    row-gap: 9rem;
  }

  .sm-max\:gap-y-40 {
    row-gap: 10rem;
  }

  .sm-max\:gap-y-44 {
    row-gap: 11rem;
  }

  .sm-max\:gap-y-48 {
    row-gap: 12rem;
  }

  .sm-max\:gap-y-52 {
    row-gap: 13rem;
  }

  .sm-max\:gap-y-56 {
    row-gap: 14rem;
  }

  .sm-max\:gap-y-60 {
    row-gap: 15rem;
  }

  .sm-max\:gap-y-64 {
    row-gap: 16rem;
  }

  .sm-max\:gap-y-72 {
    row-gap: 18rem;
  }

  .sm-max\:gap-y-80 {
    row-gap: 20rem;
  }

  .sm-max\:gap-y-96 {
    row-gap: 24rem;
  }

  .sm-max\:gap-y-px {
    row-gap: 1px;
  }

  .sm-max\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .sm-max\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .sm-max\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .sm-max\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .sm-max\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm-max\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .sm-max\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .sm-max\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .sm-max\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .sm-max\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm-max\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm-max\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm-max\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm-max\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm-max\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm-max\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .sm-max\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .sm-max\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .sm-max\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm-max\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .sm-max\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .sm-max\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .sm-max\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .sm-max\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .sm-max\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .sm-max\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .sm-max\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .sm-max\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .sm-max\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .sm-max\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .sm-max\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .sm-max\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .sm-max\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .sm-max\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .sm-max\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .sm-max\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .sm-max\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .sm-max\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .sm-max\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .sm-max\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .sm-max\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .sm-max\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .sm-max\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .sm-max\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .sm-max\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .sm-max\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .sm-max\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .sm-max\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .sm-max\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .sm-max\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .sm-max\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .sm-max\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .sm-max\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .sm-max\:place-self-auto {
    place-self: auto;
  }

  .sm-max\:place-self-start {
    place-self: start;
  }

  .sm-max\:place-self-end {
    place-self: end;
  }

  .sm-max\:place-self-center {
    place-self: center;
  }

  .sm-max\:place-self-stretch {
    place-self: stretch stretch;
  }

  .sm-max\:self-auto {
    align-self: auto;
  }

  .sm-max\:self-start {
    align-self: flex-start;
  }

  .sm-max\:self-end {
    align-self: flex-end;
  }

  .sm-max\:self-center {
    align-self: center;
  }

  .sm-max\:self-stretch {
    align-self: stretch;
  }

  .sm-max\:self-baseline {
    align-self: baseline;
  }

  .sm-max\:justify-self-auto {
    justify-self: auto;
  }

  .sm-max\:justify-self-start {
    justify-self: start;
  }

  .sm-max\:justify-self-end {
    justify-self: end;
  }

  .sm-max\:justify-self-center {
    justify-self: center;
  }

  .sm-max\:justify-self-stretch {
    justify-self: stretch;
  }

  .sm-max\:overflow-auto {
    overflow: auto;
  }

  .sm-max\:overflow-hidden {
    overflow: hidden;
  }

  .sm-max\:overflow-visible {
    overflow: visible;
  }

  .sm-max\:overflow-scroll {
    overflow: scroll;
  }

  .sm-max\:overflow-x-auto {
    overflow-x: auto;
  }

  .sm-max\:overflow-y-auto {
    overflow-y: auto;
  }

  .sm-max\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm-max\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm-max\:overflow-x-visible {
    overflow-x: visible;
  }

  .sm-max\:overflow-y-visible {
    overflow-y: visible;
  }

  .sm-max\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm-max\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm-max\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .sm-max\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .sm-max\:overscroll-none {
    overscroll-behavior: none;
  }

  .sm-max\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm-max\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm-max\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm-max\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm-max\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm-max\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm-max\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .sm-max\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .sm-max\:overflow-clip {
    text-overflow: clip;
  }

  .sm-max\:whitespace-normal {
    white-space: normal;
  }

  .sm-max\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm-max\:whitespace-pre {
    white-space: pre;
  }

  .sm-max\:whitespace-pre-line {
    white-space: pre-line;
  }

  .sm-max\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm-max\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm-max\:break-words {
    overflow-wrap: break-word;
  }

  .sm-max\:break-all {
    word-break: break-all;
  }

  .sm-max\:rounded-none {
    border-radius: 0;
  }

  .sm-max\:rounded-sm {
    border-radius: .125rem;
  }

  .sm-max\:rounded {
    border-radius: .25rem;
  }

  .sm-max\:rounded-md {
    border-radius: .375rem;
  }

  .sm-max\:rounded-lg {
    border-radius: .5rem;
  }

  .sm-max\:rounded-xl {
    border-radius: .75rem;
  }

  .sm-max\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm-max\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm-max\:rounded-full {
    border-radius: 9999px;
  }

  .sm-max\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm-max\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .sm-max\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .sm-max\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .sm-max\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .sm-max\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .sm-max\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm-max\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm-max\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm-max\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm-max\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .sm-max\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .sm-max\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .sm-max\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .sm-max\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .sm-max\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm-max\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm-max\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm-max\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm-max\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm-max\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm-max\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm-max\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm-max\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm-max\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm-max\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm-max\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm-max\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm-max\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm-max\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm-max\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm-max\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm-max\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm-max\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm-max\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm-max\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm-max\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm-max\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .sm-max\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .sm-max\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .sm-max\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .sm-max\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .sm-max\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm-max\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm-max\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm-max\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm-max\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .sm-max\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .sm-max\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .sm-max\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .sm-max\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .sm-max\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm-max\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm-max\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm-max\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm-max\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .sm-max\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .sm-max\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .sm-max\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .sm-max\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .sm-max\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm-max\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm-max\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm-max\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm-max\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .sm-max\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .sm-max\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .sm-max\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .sm-max\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .sm-max\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm-max\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm-max\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm-max\:border-0 {
    border-width: 0;
  }

  .sm-max\:border-2 {
    border-width: 2px;
  }

  .sm-max\:border-4 {
    border-width: 4px;
  }

  .sm-max\:border-8 {
    border-width: 8px;
  }

  .sm-max\:border {
    border-width: 1px;
  }

  .sm-max\:border-t-0 {
    border-top-width: 0;
  }

  .sm-max\:border-t-2 {
    border-top-width: 2px;
  }

  .sm-max\:border-t-4 {
    border-top-width: 4px;
  }

  .sm-max\:border-t-8 {
    border-top-width: 8px;
  }

  .sm-max\:border-t {
    border-top-width: 1px;
  }

  .sm-max\:border-r-0 {
    border-right-width: 0;
  }

  .sm-max\:border-r-2 {
    border-right-width: 2px;
  }

  .sm-max\:border-r-4 {
    border-right-width: 4px;
  }

  .sm-max\:border-r-8 {
    border-right-width: 8px;
  }

  .sm-max\:border-r {
    border-right-width: 1px;
  }

  .sm-max\:border-b-0 {
    border-bottom-width: 0;
  }

  .sm-max\:border-b-2 {
    border-bottom-width: 2px;
  }

  .sm-max\:border-b-4 {
    border-bottom-width: 4px;
  }

  .sm-max\:border-b-8 {
    border-bottom-width: 8px;
  }

  .sm-max\:border-b {
    border-bottom-width: 1px;
  }

  .sm-max\:border-l-0 {
    border-left-width: 0;
  }

  .sm-max\:border-l-2 {
    border-left-width: 2px;
  }

  .sm-max\:border-l-4 {
    border-left-width: 4px;
  }

  .sm-max\:border-l-8 {
    border-left-width: 8px;
  }

  .sm-max\:border-l {
    border-left-width: 1px;
  }

  .sm-max\:border-solid {
    border-style: solid;
  }

  .sm-max\:border-dashed {
    border-style: dashed;
  }

  .sm-max\:border-dotted {
    border-style: dotted;
  }

  .sm-max\:border-double {
    border-style: double;
  }

  .sm-max\:border-none {
    border-style: none;
  }

  .sm-max\:border-transparent {
    border-color: #0000;
  }

  .sm-max\:border-current {
    border-color: currentColor;
  }

  .sm-max\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm-max\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm-max\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm-max\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm-max\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm-max\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm-max\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm-max\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm-max\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm-max\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm-max\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm-max\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm-max\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm-max\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm-max\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm-max\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm-max\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm-max\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .sm-max\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .sm-max\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .sm-max\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .sm-max\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .sm-max\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm-max\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .sm-max\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .sm-max\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm-max\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .sm-max\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .sm-max\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .sm-max\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .sm-max\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .sm-max\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .sm-max\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .sm-max\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .sm-max\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .sm-max\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .sm-max\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .sm-max\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .sm-max\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .sm-max\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .sm-max\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .sm-max\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .sm-max\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .sm-max\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .sm-max\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .sm-max\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .sm-max\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .sm-max\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .sm-max\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .sm-max\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .sm-max\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .sm-max\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .sm-max\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .sm-max\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .sm-max\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .sm-max\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .sm-max\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .sm-max\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .sm-max\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .sm-max\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .sm-max\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .sm-max\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .sm-max\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .sm-max\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .sm-max\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .sm-max\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .sm-max\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .sm-max\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .sm-max\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .sm-max\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .sm-max\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .sm-max\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .sm-max\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .sm-max\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .sm-max\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .sm-max\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .sm-max\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .sm-max\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .sm-max\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .sm-max\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .sm-max\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .sm-max\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .sm-max\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .sm-max\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .sm-max\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .sm-max\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .sm-max\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .sm-max\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .sm-max\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .sm-max\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .sm-max\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .sm-max\:bg-transparent {
    background-color: #0000;
  }

  .sm-max\:bg-current {
    background-color: currentColor;
  }

  .sm-max\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm-max\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm-max\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm-max\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm-max\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm-max\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm-max\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm-max\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm-max\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .sm-max\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .sm-max\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .sm-max\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .sm-max\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .sm-max\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm-max\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .sm-max\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .sm-max\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm-max\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .sm-max\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .sm-max\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .sm-max\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .sm-max\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .sm-max\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .sm-max\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .sm-max\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .sm-max\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .sm-max\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .sm-max\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .sm-max\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .sm-max\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .sm-max\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .sm-max\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .sm-max\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .sm-max\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .sm-max\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .sm-max\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .sm-max\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .sm-max\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .sm-max\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .sm-max\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .sm-max\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .sm-max\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .sm-max\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .sm-max\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .sm-max\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .sm-max\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .sm-max\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .sm-max\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .sm-max\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .sm-max\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .sm-max\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .sm-max\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .sm-max\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .sm-max\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .sm-max\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .sm-max\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .sm-max\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .sm-max\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .sm-max\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .sm-max\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .sm-max\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .sm-max\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .sm-max\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .sm-max\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .sm-max\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .sm-max\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .sm-max\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .sm-max\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .sm-max\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .sm-max\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .sm-max\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .sm-max\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .sm-max\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .sm-max\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .sm-max\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .sm-max\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .sm-max\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .sm-max\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .sm-max\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .sm-max\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .sm-max\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .sm-max\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .sm-max\:bg-none {
    background-image: none;
  }

  .sm-max\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .sm-max\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .sm-max\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm-max\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm-max\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm-max\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm-max\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .sm-max\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .sm-max\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .sm-max\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .sm-max\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .sm-max\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .sm-max\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .sm-max\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .sm-max\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .sm-max\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .sm-max\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .sm-max\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .sm-max\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .sm-max\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .sm-max\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .sm-max\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .sm-max\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .sm-max\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .sm-max\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .sm-max\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .sm-max\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .sm-max\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .sm-max\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .sm-max\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .sm-max\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .sm-max\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .sm-max\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .sm-max\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .sm-max\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .sm-max\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .sm-max\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .sm-max\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .sm-max\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .sm-max\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .sm-max\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .sm-max\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .sm-max\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .sm-max\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .sm-max\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .sm-max\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .sm-max\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .sm-max\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .sm-max\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .sm-max\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .sm-max\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .sm-max\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .sm-max\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .sm-max\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .sm-max\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .sm-max\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .sm-max\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .sm-max\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .sm-max\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .sm-max\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .sm-max\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .sm-max\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .sm-max\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .sm-max\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .sm-max\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .sm-max\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .sm-max\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .sm-max\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .sm-max\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .sm-max\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .sm-max\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .sm-max\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .sm-max\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .sm-max\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .sm-max\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .sm-max\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .sm-max\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .sm-max\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm-max\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm-max\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm-max\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm-max\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .sm-max\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .sm-max\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .sm-max\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .sm-max\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .sm-max\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .sm-max\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .sm-max\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .sm-max\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .sm-max\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .sm-max\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .sm-max\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .sm-max\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .sm-max\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .sm-max\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .sm-max\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .sm-max\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .sm-max\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .sm-max\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .sm-max\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .sm-max\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .sm-max\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .sm-max\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .sm-max\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .sm-max\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .sm-max\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .sm-max\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .sm-max\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .sm-max\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .sm-max\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .sm-max\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .sm-max\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .sm-max\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .sm-max\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .sm-max\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .sm-max\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .sm-max\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .sm-max\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .sm-max\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .sm-max\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .sm-max\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .sm-max\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .sm-max\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .sm-max\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .sm-max\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .sm-max\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .sm-max\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .sm-max\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .sm-max\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .sm-max\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .sm-max\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .sm-max\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .sm-max\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .sm-max\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .sm-max\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .sm-max\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .sm-max\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .sm-max\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .sm-max\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .sm-max\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .sm-max\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .sm-max\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .sm-max\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .sm-max\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .sm-max\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .sm-max\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .sm-max\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .sm-max\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .sm-max\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .sm-max\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .sm-max\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .sm-max\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm-max\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm-max\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .sm-max\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .sm-max\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .sm-max\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .sm-max\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .sm-max\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .sm-max\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .sm-max\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .sm-max\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .sm-max\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .sm-max\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .sm-max\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .sm-max\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .sm-max\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .sm-max\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .sm-max\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .sm-max\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .sm-max\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .sm-max\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .sm-max\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .sm-max\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .sm-max\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .sm-max\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .sm-max\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .sm-max\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .sm-max\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .sm-max\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .sm-max\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .sm-max\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .sm-max\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .sm-max\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .sm-max\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .sm-max\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .sm-max\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .sm-max\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .sm-max\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .sm-max\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .sm-max\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .sm-max\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .sm-max\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .sm-max\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .sm-max\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .sm-max\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .sm-max\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .sm-max\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .sm-max\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .sm-max\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .sm-max\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .sm-max\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .sm-max\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .sm-max\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .sm-max\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .sm-max\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .sm-max\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .sm-max\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .sm-max\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .sm-max\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .sm-max\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .sm-max\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .sm-max\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .sm-max\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .sm-max\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .sm-max\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .sm-max\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .sm-max\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .sm-max\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .sm-max\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .sm-max\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .sm-max\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .sm-max\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .sm-max\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .sm-max\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .sm-max\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .sm-max\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .sm-max\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .sm-max\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .sm-max\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .sm-max\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .sm-max\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .sm-max\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .sm-max\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .sm-max\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .sm-max\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .sm-max\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .sm-max\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .sm-max\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .sm-max\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .sm-max\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .sm-max\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .sm-max\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .sm-max\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .sm-max\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .sm-max\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .sm-max\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .sm-max\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .sm-max\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .sm-max\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .sm-max\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .sm-max\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .sm-max\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .sm-max\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .sm-max\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .sm-max\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .sm-max\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .sm-max\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .sm-max\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .sm-max\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .sm-max\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .sm-max\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .sm-max\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .sm-max\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .sm-max\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .sm-max\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .sm-max\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .sm-max\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .sm-max\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .sm-max\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .sm-max\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .sm-max\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .sm-max\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .sm-max\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .sm-max\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .sm-max\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .sm-max\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .sm-max\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .sm-max\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .sm-max\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .sm-max\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .sm-max\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .sm-max\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .sm-max\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .sm-max\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .sm-max\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .sm-max\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .sm-max\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .sm-max\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .sm-max\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .sm-max\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .sm-max\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .sm-max\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .sm-max\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .sm-max\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .sm-max\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .sm-max\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .sm-max\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .sm-max\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .sm-max\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .sm-max\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .sm-max\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .sm-max\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .sm-max\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .sm-max\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .sm-max\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .sm-max\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .sm-max\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .sm-max\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .sm-max\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .sm-max\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .sm-max\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .sm-max\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .sm-max\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .sm-max\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .sm-max\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .sm-max\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .sm-max\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .sm-max\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .sm-max\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .sm-max\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .sm-max\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .sm-max\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .sm-max\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .sm-max\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .sm-max\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .sm-max\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .sm-max\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .sm-max\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .sm-max\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .sm-max\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .sm-max\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .sm-max\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .sm-max\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .sm-max\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .sm-max\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .sm-max\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .sm-max\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .sm-max\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .sm-max\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .sm-max\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .sm-max\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .sm-max\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .sm-max\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .sm-max\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .sm-max\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .sm-max\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .sm-max\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .sm-max\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .sm-max\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .sm-max\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .sm-max\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .sm-max\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .sm-max\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .sm-max\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .sm-max\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .sm-max\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .sm-max\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .sm-max\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .sm-max\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .sm-max\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .sm-max\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .sm-max\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .sm-max\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .sm-max\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .sm-max\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .sm-max\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .sm-max\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .sm-max\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .sm-max\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .sm-max\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .sm-max\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .sm-max\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .sm-max\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .sm-max\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .sm-max\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .sm-max\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .sm-max\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .sm-max\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .sm-max\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .sm-max\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .sm-max\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .sm-max\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .sm-max\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .sm-max\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .sm-max\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .sm-max\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .sm-max\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .sm-max\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .sm-max\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .sm-max\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .sm-max\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .sm-max\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .sm-max\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .sm-max\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .sm-max\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .sm-max\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .sm-max\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .sm-max\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .sm-max\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .sm-max\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .sm-max\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .sm-max\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .sm-max\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .sm-max\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .sm-max\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .sm-max\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .sm-max\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .sm-max\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .sm-max\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .sm-max\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .sm-max\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .sm-max\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .sm-max\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .sm-max\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .sm-max\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .sm-max\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .sm-max\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .sm-max\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .sm-max\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .sm-max\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .sm-max\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .sm-max\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .sm-max\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .sm-max\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .sm-max\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .sm-max\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .sm-max\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .sm-max\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .sm-max\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .sm-max\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .sm-max\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .sm-max\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .sm-max\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .sm-max\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .sm-max\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .sm-max\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .sm-max\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .sm-max\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .sm-max\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .sm-max\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .sm-max\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .sm-max\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .sm-max\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .sm-max\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .sm-max\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .sm-max\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .sm-max\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .sm-max\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .sm-max\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .sm-max\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .sm-max\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .sm-max\:to-current {
    --tw-gradient-to: currentColor;
  }

  .sm-max\:to-black {
    --tw-gradient-to: #000;
  }

  .sm-max\:to-white {
    --tw-gradient-to: #fff;
  }

  .sm-max\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .sm-max\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .sm-max\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .sm-max\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .sm-max\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .sm-max\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .sm-max\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .sm-max\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .sm-max\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .sm-max\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .sm-max\:to-red {
    --tw-gradient-to: red;
  }

  .sm-max\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .sm-max\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .sm-max\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .sm-max\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .sm-max\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .sm-max\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .sm-max\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .sm-max\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .sm-max\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .sm-max\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .sm-max\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .sm-max\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .sm-max\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .sm-max\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .sm-max\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .sm-max\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .sm-max\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .sm-max\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .sm-max\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .sm-max\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .sm-max\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .sm-max\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .sm-max\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .sm-max\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .sm-max\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .sm-max\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .sm-max\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .sm-max\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .sm-max\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .sm-max\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .sm-max\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .sm-max\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .sm-max\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .sm-max\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .sm-max\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .sm-max\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .sm-max\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .sm-max\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .sm-max\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .sm-max\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .sm-max\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .sm-max\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .sm-max\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .sm-max\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .sm-max\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .sm-max\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .sm-max\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .sm-max\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .sm-max\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .sm-max\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .sm-max\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .sm-max\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .sm-max\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .sm-max\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .sm-max\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .sm-max\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .sm-max\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .sm-max\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .sm-max\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .sm-max\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .sm-max\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .sm-max\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .sm-max\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .sm-max\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .sm-max\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .sm-max\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .sm-max\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .sm-max\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .sm-max\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .sm-max\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .sm-max\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .sm-max\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .sm-max\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .sm-max\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .sm-max\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .sm-max\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .sm-max\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .sm-max\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .sm-max\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .sm-max\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .sm-max\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .sm-max\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .sm-max\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .sm-max\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .sm-max\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .sm-max\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .sm-max\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .sm-max\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .sm-max\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .sm-max\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .sm-max\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .sm-max\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .sm-max\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .sm-max\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .sm-max\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .sm-max\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .sm-max\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .sm-max\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .sm-max\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .sm-max\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .sm-max\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .sm-max\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .sm-max\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .sm-max\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .sm-max\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .sm-max\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .sm-max\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .sm-max\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .sm-max\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .sm-max\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .sm-max\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .sm-max\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .sm-max\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .sm-max\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .sm-max\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .sm-max\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .sm-max\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .sm-max\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .sm-max\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .sm-max\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .sm-max\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .sm-max\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .sm-max\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .sm-max\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .sm-max\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .sm-max\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .sm-max\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .sm-max\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .sm-max\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .sm-max\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .sm-max\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .sm-max\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .sm-max\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .sm-max\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .sm-max\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .sm-max\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .sm-max\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .sm-max\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .sm-max\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .sm-max\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .sm-max\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .sm-max\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .sm-max\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .sm-max\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .sm-max\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .sm-max\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .sm-max\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .sm-max\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .sm-max\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .sm-max\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .sm-max\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .sm-max\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .sm-max\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .sm-max\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .sm-max\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .sm-max\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .sm-max\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .sm-max\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .sm-max\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .sm-max\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .sm-max\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .sm-max\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .sm-max\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .sm-max\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .sm-max\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .sm-max\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .sm-max\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .sm-max\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .sm-max\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .sm-max\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .sm-max\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .sm-max\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .sm-max\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .sm-max\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .sm-max\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .sm-max\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .sm-max\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .sm-max\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .sm-max\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .sm-max\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .sm-max\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .sm-max\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .sm-max\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .sm-max\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .sm-max\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .sm-max\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .sm-max\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .sm-max\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .sm-max\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .sm-max\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .sm-max\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .sm-max\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .sm-max\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .sm-max\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .sm-max\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .sm-max\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .sm-max\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .sm-max\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .sm-max\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .sm-max\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .sm-max\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .sm-max\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .sm-max\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .sm-max\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .sm-max\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .sm-max\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .sm-max\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .sm-max\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .sm-max\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .sm-max\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .sm-max\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .sm-max\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .sm-max\:bg-auto {
    background-size: auto;
  }

  .sm-max\:bg-cover {
    background-size: cover;
  }

  .sm-max\:bg-contain {
    background-size: contain;
  }

  .sm-max\:bg-fixed {
    background-attachment: fixed;
  }

  .sm-max\:bg-local {
    background-attachment: local;
  }

  .sm-max\:bg-scroll {
    background-attachment: scroll;
  }

  .sm-max\:bg-clip-border {
    background-clip: border-box;
  }

  .sm-max\:bg-clip-padding {
    background-clip: padding-box;
  }

  .sm-max\:bg-clip-content {
    background-clip: content-box;
  }

  .sm-max\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .sm-max\:bg-bottom {
    background-position: bottom;
  }

  .sm-max\:bg-center {
    background-position: center;
  }

  .sm-max\:bg-left {
    background-position: 0;
  }

  .sm-max\:bg-left-bottom {
    background-position: 0 100%;
  }

  .sm-max\:bg-left-top {
    background-position: 0 0;
  }

  .sm-max\:bg-right {
    background-position: 100%;
  }

  .sm-max\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .sm-max\:bg-right-top {
    background-position: 100% 0;
  }

  .sm-max\:bg-top {
    background-position: top;
  }

  .sm-max\:bg-repeat {
    background-repeat: repeat;
  }

  .sm-max\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm-max\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm-max\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm-max\:bg-repeat-round {
    background-repeat: round;
  }

  .sm-max\:bg-repeat-space {
    background-repeat: space;
  }

  .sm-max\:bg-origin-border {
    background-origin: border-box;
  }

  .sm-max\:bg-origin-padding {
    background-origin: padding-box;
  }

  .sm-max\:bg-origin-content {
    background-origin: content-box;
  }

  .sm-max\:fill-current {
    fill: currentColor;
  }

  .sm-max\:stroke-current {
    stroke: currentColor;
  }

  .sm-max\:stroke-0 {
    stroke-width: 0;
  }

  .sm-max\:stroke-1 {
    stroke-width: 1px;
  }

  .sm-max\:stroke-2 {
    stroke-width: 2px;
  }

  .sm-max\:object-contain {
    object-fit: contain;
  }

  .sm-max\:object-cover {
    object-fit: cover;
  }

  .sm-max\:object-fill {
    object-fit: fill;
  }

  .sm-max\:object-none {
    object-fit: none;
  }

  .sm-max\:object-scale-down {
    object-fit: scale-down;
  }

  .sm-max\:object-bottom {
    object-position: bottom;
  }

  .sm-max\:object-center {
    object-position: center;
  }

  .sm-max\:object-left {
    object-position: left;
  }

  .sm-max\:object-left-bottom {
    object-position: left bottom;
  }

  .sm-max\:object-left-top {
    object-position: left top;
  }

  .sm-max\:object-right {
    object-position: right;
  }

  .sm-max\:object-right-bottom {
    object-position: right bottom;
  }

  .sm-max\:object-right-top {
    object-position: right top;
  }

  .sm-max\:object-top {
    object-position: top;
  }

  .sm-max\:p-0 {
    padding: 0;
  }

  .sm-max\:p-1 {
    padding: .25rem;
  }

  .sm-max\:p-2 {
    padding: .5rem;
  }

  .sm-max\:p-3 {
    padding: .75rem;
  }

  .sm-max\:p-4 {
    padding: 1rem;
  }

  .sm-max\:p-5 {
    padding: 1.25rem;
  }

  .sm-max\:p-6 {
    padding: 1.5rem;
  }

  .sm-max\:p-7 {
    padding: 1.75rem;
  }

  .sm-max\:p-8 {
    padding: 2rem;
  }

  .sm-max\:p-9 {
    padding: 2.25rem;
  }

  .sm-max\:p-10 {
    padding: 2.5rem;
  }

  .sm-max\:p-11 {
    padding: 2.75rem;
  }

  .sm-max\:p-12 {
    padding: 3rem;
  }

  .sm-max\:p-14 {
    padding: 3.5rem;
  }

  .sm-max\:p-16 {
    padding: 4rem;
  }

  .sm-max\:p-20 {
    padding: 5rem;
  }

  .sm-max\:p-24 {
    padding: 6rem;
  }

  .sm-max\:p-28 {
    padding: 7rem;
  }

  .sm-max\:p-32 {
    padding: 8rem;
  }

  .sm-max\:p-36 {
    padding: 9rem;
  }

  .sm-max\:p-40 {
    padding: 10rem;
  }

  .sm-max\:p-44 {
    padding: 11rem;
  }

  .sm-max\:p-48 {
    padding: 12rem;
  }

  .sm-max\:p-52 {
    padding: 13rem;
  }

  .sm-max\:p-56 {
    padding: 14rem;
  }

  .sm-max\:p-60 {
    padding: 15rem;
  }

  .sm-max\:p-64 {
    padding: 16rem;
  }

  .sm-max\:p-72 {
    padding: 18rem;
  }

  .sm-max\:p-80 {
    padding: 20rem;
  }

  .sm-max\:p-96 {
    padding: 24rem;
  }

  .sm-max\:p-px {
    padding: 1px;
  }

  .sm-max\:p-0\.5 {
    padding: .125rem;
  }

  .sm-max\:p-1\.5 {
    padding: .375rem;
  }

  .sm-max\:p-2\.5 {
    padding: .625rem;
  }

  .sm-max\:p-3\.5 {
    padding: .875rem;
  }

  .sm-max\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm-max\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm-max\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm-max\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm-max\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm-max\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm-max\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm-max\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .sm-max\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm-max\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm-max\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm-max\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .sm-max\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm-max\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm-max\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm-max\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm-max\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm-max\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .sm-max\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm-max\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .sm-max\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm-max\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .sm-max\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm-max\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .sm-max\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm-max\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .sm-max\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm-max\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .sm-max\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .sm-max\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .sm-max\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm-max\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .sm-max\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .sm-max\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .sm-max\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm-max\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm-max\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm-max\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm-max\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm-max\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm-max\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm-max\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm-max\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .sm-max\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm-max\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .sm-max\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm-max\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .sm-max\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm-max\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .sm-max\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm-max\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm-max\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm-max\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .sm-max\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm-max\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .sm-max\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm-max\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .sm-max\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm-max\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .sm-max\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm-max\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .sm-max\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm-max\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .sm-max\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .sm-max\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .sm-max\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm-max\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .sm-max\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .sm-max\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .sm-max\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .sm-max\:pt-0 {
    padding-top: 0;
  }

  .sm-max\:pt-1 {
    padding-top: .25rem;
  }

  .sm-max\:pt-2 {
    padding-top: .5rem;
  }

  .sm-max\:pt-3 {
    padding-top: .75rem;
  }

  .sm-max\:pt-4 {
    padding-top: 1rem;
  }

  .sm-max\:pt-5 {
    padding-top: 1.25rem;
  }

  .sm-max\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm-max\:pt-7 {
    padding-top: 1.75rem;
  }

  .sm-max\:pt-8 {
    padding-top: 2rem;
  }

  .sm-max\:pt-9 {
    padding-top: 2.25rem;
  }

  .sm-max\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm-max\:pt-11 {
    padding-top: 2.75rem;
  }

  .sm-max\:pt-12 {
    padding-top: 3rem;
  }

  .sm-max\:pt-14 {
    padding-top: 3.5rem;
  }

  .sm-max\:pt-16 {
    padding-top: 4rem;
  }

  .sm-max\:pt-20 {
    padding-top: 5rem;
  }

  .sm-max\:pt-24 {
    padding-top: 6rem;
  }

  .sm-max\:pt-28 {
    padding-top: 7rem;
  }

  .sm-max\:pt-32 {
    padding-top: 8rem;
  }

  .sm-max\:pt-36 {
    padding-top: 9rem;
  }

  .sm-max\:pt-40 {
    padding-top: 10rem;
  }

  .sm-max\:pt-44 {
    padding-top: 11rem;
  }

  .sm-max\:pt-48 {
    padding-top: 12rem;
  }

  .sm-max\:pt-52 {
    padding-top: 13rem;
  }

  .sm-max\:pt-56 {
    padding-top: 14rem;
  }

  .sm-max\:pt-60 {
    padding-top: 15rem;
  }

  .sm-max\:pt-64 {
    padding-top: 16rem;
  }

  .sm-max\:pt-72 {
    padding-top: 18rem;
  }

  .sm-max\:pt-80 {
    padding-top: 20rem;
  }

  .sm-max\:pt-96 {
    padding-top: 24rem;
  }

  .sm-max\:pt-px {
    padding-top: 1px;
  }

  .sm-max\:pt-0\.5 {
    padding-top: .125rem;
  }

  .sm-max\:pt-1\.5 {
    padding-top: .375rem;
  }

  .sm-max\:pt-2\.5 {
    padding-top: .625rem;
  }

  .sm-max\:pt-3\.5 {
    padding-top: .875rem;
  }

  .sm-max\:pr-0 {
    padding-right: 0;
  }

  .sm-max\:pr-1 {
    padding-right: .25rem;
  }

  .sm-max\:pr-2 {
    padding-right: .5rem;
  }

  .sm-max\:pr-3 {
    padding-right: .75rem;
  }

  .sm-max\:pr-4 {
    padding-right: 1rem;
  }

  .sm-max\:pr-5 {
    padding-right: 1.25rem;
  }

  .sm-max\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm-max\:pr-7 {
    padding-right: 1.75rem;
  }

  .sm-max\:pr-8 {
    padding-right: 2rem;
  }

  .sm-max\:pr-9 {
    padding-right: 2.25rem;
  }

  .sm-max\:pr-10 {
    padding-right: 2.5rem;
  }

  .sm-max\:pr-11 {
    padding-right: 2.75rem;
  }

  .sm-max\:pr-12 {
    padding-right: 3rem;
  }

  .sm-max\:pr-14 {
    padding-right: 3.5rem;
  }

  .sm-max\:pr-16 {
    padding-right: 4rem;
  }

  .sm-max\:pr-20 {
    padding-right: 5rem;
  }

  .sm-max\:pr-24 {
    padding-right: 6rem;
  }

  .sm-max\:pr-28 {
    padding-right: 7rem;
  }

  .sm-max\:pr-32 {
    padding-right: 8rem;
  }

  .sm-max\:pr-36 {
    padding-right: 9rem;
  }

  .sm-max\:pr-40 {
    padding-right: 10rem;
  }

  .sm-max\:pr-44 {
    padding-right: 11rem;
  }

  .sm-max\:pr-48 {
    padding-right: 12rem;
  }

  .sm-max\:pr-52 {
    padding-right: 13rem;
  }

  .sm-max\:pr-56 {
    padding-right: 14rem;
  }

  .sm-max\:pr-60 {
    padding-right: 15rem;
  }

  .sm-max\:pr-64 {
    padding-right: 16rem;
  }

  .sm-max\:pr-72 {
    padding-right: 18rem;
  }

  .sm-max\:pr-80 {
    padding-right: 20rem;
  }

  .sm-max\:pr-96 {
    padding-right: 24rem;
  }

  .sm-max\:pr-px {
    padding-right: 1px;
  }

  .sm-max\:pr-0\.5 {
    padding-right: .125rem;
  }

  .sm-max\:pr-1\.5 {
    padding-right: .375rem;
  }

  .sm-max\:pr-2\.5 {
    padding-right: .625rem;
  }

  .sm-max\:pr-3\.5 {
    padding-right: .875rem;
  }

  .sm-max\:pb-0 {
    padding-bottom: 0;
  }

  .sm-max\:pb-1 {
    padding-bottom: .25rem;
  }

  .sm-max\:pb-2 {
    padding-bottom: .5rem;
  }

  .sm-max\:pb-3 {
    padding-bottom: .75rem;
  }

  .sm-max\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm-max\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm-max\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm-max\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .sm-max\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm-max\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .sm-max\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm-max\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .sm-max\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm-max\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .sm-max\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm-max\:pb-20 {
    padding-bottom: 5rem;
  }

  .sm-max\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm-max\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm-max\:pb-32 {
    padding-bottom: 8rem;
  }

  .sm-max\:pb-36 {
    padding-bottom: 9rem;
  }

  .sm-max\:pb-40 {
    padding-bottom: 10rem;
  }

  .sm-max\:pb-44 {
    padding-bottom: 11rem;
  }

  .sm-max\:pb-48 {
    padding-bottom: 12rem;
  }

  .sm-max\:pb-52 {
    padding-bottom: 13rem;
  }

  .sm-max\:pb-56 {
    padding-bottom: 14rem;
  }

  .sm-max\:pb-60 {
    padding-bottom: 15rem;
  }

  .sm-max\:pb-64 {
    padding-bottom: 16rem;
  }

  .sm-max\:pb-72 {
    padding-bottom: 18rem;
  }

  .sm-max\:pb-80 {
    padding-bottom: 20rem;
  }

  .sm-max\:pb-96 {
    padding-bottom: 24rem;
  }

  .sm-max\:pb-px {
    padding-bottom: 1px;
  }

  .sm-max\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .sm-max\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .sm-max\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .sm-max\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .sm-max\:pl-0 {
    padding-left: 0;
  }

  .sm-max\:pl-1 {
    padding-left: .25rem;
  }

  .sm-max\:pl-2 {
    padding-left: .5rem;
  }

  .sm-max\:pl-3 {
    padding-left: .75rem;
  }

  .sm-max\:pl-4 {
    padding-left: 1rem;
  }

  .sm-max\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm-max\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm-max\:pl-7 {
    padding-left: 1.75rem;
  }

  .sm-max\:pl-8 {
    padding-left: 2rem;
  }

  .sm-max\:pl-9 {
    padding-left: 2.25rem;
  }

  .sm-max\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm-max\:pl-11 {
    padding-left: 2.75rem;
  }

  .sm-max\:pl-12 {
    padding-left: 3rem;
  }

  .sm-max\:pl-14 {
    padding-left: 3.5rem;
  }

  .sm-max\:pl-16 {
    padding-left: 4rem;
  }

  .sm-max\:pl-20 {
    padding-left: 5rem;
  }

  .sm-max\:pl-24 {
    padding-left: 6rem;
  }

  .sm-max\:pl-28 {
    padding-left: 7rem;
  }

  .sm-max\:pl-32 {
    padding-left: 8rem;
  }

  .sm-max\:pl-36 {
    padding-left: 9rem;
  }

  .sm-max\:pl-40 {
    padding-left: 10rem;
  }

  .sm-max\:pl-44 {
    padding-left: 11rem;
  }

  .sm-max\:pl-48 {
    padding-left: 12rem;
  }

  .sm-max\:pl-52 {
    padding-left: 13rem;
  }

  .sm-max\:pl-56 {
    padding-left: 14rem;
  }

  .sm-max\:pl-60 {
    padding-left: 15rem;
  }

  .sm-max\:pl-64 {
    padding-left: 16rem;
  }

  .sm-max\:pl-72 {
    padding-left: 18rem;
  }

  .sm-max\:pl-80 {
    padding-left: 20rem;
  }

  .sm-max\:pl-96 {
    padding-left: 24rem;
  }

  .sm-max\:pl-px {
    padding-left: 1px;
  }

  .sm-max\:pl-0\.5 {
    padding-left: .125rem;
  }

  .sm-max\:pl-1\.5 {
    padding-left: .375rem;
  }

  .sm-max\:pl-2\.5 {
    padding-left: .625rem;
  }

  .sm-max\:pl-3\.5 {
    padding-left: .875rem;
  }

  .sm-max\:text-left {
    text-align: left;
  }

  .sm-max\:text-center {
    text-align: center;
  }

  .sm-max\:text-right {
    text-align: right;
  }

  .sm-max\:text-justify {
    text-align: justify;
  }

  .sm-max\:align-baseline {
    vertical-align: baseline;
  }

  .sm-max\:align-top {
    vertical-align: top;
  }

  .sm-max\:align-middle {
    vertical-align: middle;
  }

  .sm-max\:align-bottom {
    vertical-align: bottom;
  }

  .sm-max\:align-text-top {
    vertical-align: text-top;
  }

  .sm-max\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm-max\:font-body {
    font-family: Montserrat;
  }

  .sm-max\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .sm-max\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm-max\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm-max\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm-max\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm-max\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm-max\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm-max\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm-max\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm-max\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm-max\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .sm-max\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .sm-max\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .sm-max\:font-thin {
    font-weight: 100;
  }

  .sm-max\:font-extralight {
    font-weight: 200;
  }

  .sm-max\:font-light {
    font-weight: 300;
  }

  .sm-max\:font-normal {
    font-weight: 400;
  }

  .sm-max\:font-medium {
    font-weight: 500;
  }

  .sm-max\:font-semibold {
    font-weight: 600;
  }

  .sm-max\:font-bold {
    font-weight: 700;
  }

  .sm-max\:font-extrabold {
    font-weight: 800;
  }

  .sm-max\:font-black {
    font-weight: 900;
  }

  .sm-max\:uppercase {
    text-transform: uppercase;
  }

  .sm-max\:lowercase {
    text-transform: lowercase;
  }

  .sm-max\:capitalize {
    text-transform: capitalize;
  }

  .sm-max\:normal-case {
    text-transform: none;
  }

  .sm-max\:italic {
    font-style: italic;
  }

  .sm-max\:not-italic {
    font-style: normal;
  }

  .sm-max\:ordinal, .sm-max\:slashed-zero, .sm-max\:lining-nums, .sm-max\:oldstyle-nums, .sm-max\:proportional-nums, .sm-max\:tabular-nums, .sm-max\:diagonal-fractions, .sm-max\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm-max\:normal-nums {
    font-variant-numeric: normal;
  }

  .sm-max\:ordinal {
    --tw-ordinal: ordinal;
  }

  .sm-max\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .sm-max\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .sm-max\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .sm-max\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .sm-max\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .sm-max\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .sm-max\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .sm-max\:leading-3 {
    line-height: .75rem;
  }

  .sm-max\:leading-4 {
    line-height: 1rem;
  }

  .sm-max\:leading-5 {
    line-height: 1.25rem;
  }

  .sm-max\:leading-6 {
    line-height: 1.5rem;
  }

  .sm-max\:leading-7 {
    line-height: 1.75rem;
  }

  .sm-max\:leading-8 {
    line-height: 2rem;
  }

  .sm-max\:leading-9 {
    line-height: 2.25rem;
  }

  .sm-max\:leading-10 {
    line-height: 2.5rem;
  }

  .sm-max\:leading-none {
    line-height: 1;
  }

  .sm-max\:leading-tight {
    line-height: 1.25;
  }

  .sm-max\:leading-snug {
    line-height: 1.375;
  }

  .sm-max\:leading-normal {
    line-height: 1.5;
  }

  .sm-max\:leading-relaxed {
    line-height: 1.625;
  }

  .sm-max\:leading-loose {
    line-height: 2;
  }

  .sm-max\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .sm-max\:tracking-tight {
    letter-spacing: -.025em;
  }

  .sm-max\:tracking-normal {
    letter-spacing: 0;
  }

  .sm-max\:tracking-wide {
    letter-spacing: .025em;
  }

  .sm-max\:tracking-wider {
    letter-spacing: .05em;
  }

  .sm-max\:tracking-widest {
    letter-spacing: .1em;
  }

  .sm-max\:text-transparent {
    color: #0000;
  }

  .sm-max\:text-current {
    color: currentColor;
  }

  .sm-max\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm-max\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm-max\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm-max\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm-max\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm-max\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm-max\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm-max\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm-max\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm-max\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm-max\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm-max\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm-max\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm-max\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm-max\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm-max\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm-max\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm-max\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .sm-max\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .sm-max\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .sm-max\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .sm-max\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .sm-max\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm-max\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-transparent:hover {
    color: #0000;
  }

  .sm-max\:hover\:text-current:hover {
    color: currentColor;
  }

  .sm-max\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm-max\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-transparent:focus {
    color: #0000;
  }

  .sm-max\:focus\:text-current:focus {
    color: currentColor;
  }

  .sm-max\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .sm-max\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .sm-max\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .sm-max\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .sm-max\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .sm-max\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .sm-max\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .sm-max\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .sm-max\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .sm-max\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .sm-max\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .sm-max\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .sm-max\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .sm-max\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .sm-max\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .sm-max\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .sm-max\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .sm-max\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .sm-max\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .sm-max\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .sm-max\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .sm-max\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .sm-max\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .sm-max\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .sm-max\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .sm-max\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .sm-max\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .sm-max\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .sm-max\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .sm-max\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .sm-max\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .sm-max\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .sm-max\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .sm-max\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .sm-max\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .sm-max\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .sm-max\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .sm-max\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .sm-max\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .sm-max\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .sm-max\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .sm-max\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .sm-max\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .sm-max\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .sm-max\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .sm-max\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .sm-max\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .sm-max\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .sm-max\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .sm-max\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .sm-max\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .sm-max\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .sm-max\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .sm-max\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .sm-max\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .sm-max\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .sm-max\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .sm-max\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .sm-max\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .sm-max\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .sm-max\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .sm-max\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .sm-max\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .sm-max\:underline {
    text-decoration: underline;
  }

  .sm-max\:line-through {
    text-decoration: line-through;
  }

  .sm-max\:no-underline {
    text-decoration: none;
  }

  .group:hover .sm-max\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .sm-max\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .sm-max\:group-hover\:no-underline {
    text-decoration: none;
  }

  .sm-max\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .sm-max\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .sm-max\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .sm-max\:hover\:underline:hover {
    text-decoration: underline;
  }

  .sm-max\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .sm-max\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .sm-max\:focus\:underline:focus {
    text-decoration: underline;
  }

  .sm-max\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .sm-max\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .sm-max\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm-max\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm-max\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .sm-max\:placeholder-current::placeholder {
    color: currentColor;
  }

  .sm-max\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .sm-max\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .sm-max\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .sm-max\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .sm-max\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm-max\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .sm-max\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .sm-max\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .sm-max\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .sm-max\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .sm-max\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .sm-max\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .sm-max\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .sm-max\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .sm-max\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .sm-max\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .sm-max\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .sm-max\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .sm-max\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm-max\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm-max\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .sm-max\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .sm-max\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .sm-max\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .sm-max\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .sm-max\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .sm-max\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .sm-max\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .sm-max\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .sm-max\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .sm-max\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .sm-max\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .sm-max\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .sm-max\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm-max\:opacity-0 {
    opacity: 0;
  }

  .sm-max\:opacity-5 {
    opacity: .05;
  }

  .sm-max\:opacity-10 {
    opacity: .1;
  }

  .sm-max\:opacity-20 {
    opacity: .2;
  }

  .sm-max\:opacity-25 {
    opacity: .25;
  }

  .sm-max\:opacity-30 {
    opacity: .3;
  }

  .sm-max\:opacity-40 {
    opacity: .4;
  }

  .sm-max\:opacity-50 {
    opacity: .5;
  }

  .sm-max\:opacity-60 {
    opacity: .6;
  }

  .sm-max\:opacity-70 {
    opacity: .7;
  }

  .sm-max\:opacity-75 {
    opacity: .75;
  }

  .sm-max\:opacity-80 {
    opacity: .8;
  }

  .sm-max\:opacity-90 {
    opacity: .9;
  }

  .sm-max\:opacity-95 {
    opacity: .95;
  }

  .sm-max\:opacity-100 {
    opacity: 1;
  }

  .group:hover .sm-max\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .sm-max\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .sm-max\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .sm-max\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .sm-max\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .sm-max\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .sm-max\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .sm-max\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .sm-max\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .sm-max\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .sm-max\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .sm-max\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .sm-max\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .sm-max\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .sm-max\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .sm-max\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .sm-max\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .sm-max\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .sm-max\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .sm-max\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .sm-max\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .sm-max\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .sm-max\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .sm-max\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .sm-max\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .sm-max\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .sm-max\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .sm-max\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .sm-max\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .sm-max\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .sm-max\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .sm-max\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .sm-max\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .sm-max\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .sm-max\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .sm-max\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .sm-max\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .sm-max\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .sm-max\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .sm-max\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .sm-max\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .sm-max\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .sm-max\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .sm-max\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .sm-max\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .sm-max\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .sm-max\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .sm-max\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .sm-max\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .sm-max\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .sm-max\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .sm-max\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .sm-max\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .sm-max\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .sm-max\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .sm-max\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .sm-max\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .sm-max\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .sm-max\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .sm-max\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .sm-max\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .sm-max\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .sm-max\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .sm-max\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .sm-max\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .sm-max\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .sm-max\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .sm-max\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .sm-max\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .sm-max\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .sm-max\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .sm-max\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .sm-max\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .sm-max\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .sm-max\:bg-blend-color {
    background-blend-mode: color;
  }

  .sm-max\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .sm-max\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .sm-max\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .sm-max\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .sm-max\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .sm-max\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .sm-max\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .sm-max\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .sm-max\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .sm-max\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .sm-max\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .sm-max\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .sm-max\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .sm-max\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .sm-max\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .sm-max\:mix-blend-color {
    mix-blend-mode: color;
  }

  .sm-max\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .sm-max\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .sm-max\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm-max\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm-max\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm-max\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm-max\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm-max\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm-max\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm-max\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm-max\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm-max\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm-max\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm-max\:ring-inset, .sm-max\:focus-within\:ring-inset:focus-within, .sm-max\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .sm-max\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .sm-max\:ring-current {
    --tw-ring-color: currentColor;
  }

  .sm-max\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm-max\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm-max\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm-max\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm-max\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm-max\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm-max\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm-max\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm-max\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm-max\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .sm-max\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .sm-max\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm-max\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .sm-max\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .sm-max\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .sm-max\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .sm-max\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .sm-max\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .sm-max\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .sm-max\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .sm-max\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .sm-max\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .sm-max\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .sm-max\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .sm-max\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .sm-max\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .sm-max\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .sm-max\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .sm-max\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .sm-max\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .sm-max\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .sm-max\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .sm-max\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .sm-max\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .sm-max\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .sm-max\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .sm-max\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .sm-max\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .sm-max\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .sm-max\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .sm-max\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .sm-max\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .sm-max\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .sm-max\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .sm-max\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .sm-max\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .sm-max\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .sm-max\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .sm-max\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .sm-max\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .sm-max\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .sm-max\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .sm-max\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .sm-max\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .sm-max\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .sm-max\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .sm-max\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .sm-max\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .sm-max\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .sm-max\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .sm-max\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .sm-max\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .sm-max\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .sm-max\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .sm-max\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .sm-max\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .sm-max\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .sm-max\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .sm-max\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .sm-max\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .sm-max\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .sm-max\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .sm-max\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .sm-max\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .sm-max\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .sm-max\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .sm-max\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .sm-max\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .sm-max\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .sm-max\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .sm-max\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm-max\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm-max\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm-max\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm-max\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm-max\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .sm-max\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .sm-max\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .sm-max\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .sm-max\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .sm-max\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .sm-max\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm-max\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm-max\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .sm-max\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm-max\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm-max\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm-max\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .sm-max\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .sm-max\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .sm-max\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .sm-max\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm-max\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm-max\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm-max\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm-max\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .sm-max\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .sm-max\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .sm-max\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .sm-max\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .sm-max\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .sm-max\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm-max\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm-max\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm-max\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm-max\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm-max\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm-max\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .sm-max\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm-max\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .sm-max\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm-max\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm-max\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm-max\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm-max\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm-max\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .sm-max\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .sm-max\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm-max\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .sm-max\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .sm-max\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .sm-max\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm-max\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm-max\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm-max\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm-max\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm-max\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm-max\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm-max\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm-max\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm-max\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm-max\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm-max\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm-max\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm-max\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm-max\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .sm-max\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .sm-max\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .sm-max\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .sm-max\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .sm-max\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .sm-max\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .sm-max\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .sm-max\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .sm-max\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .sm-max\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm-max\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm-max\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm-max\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm-max\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm-max\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .sm-max\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .sm-max\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .sm-max\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .sm-max\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .sm-max\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .sm-max\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm-max\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm-max\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .sm-max\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm-max\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm-max\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm-max\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .sm-max\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .sm-max\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .sm-max\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .sm-max\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm-max\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm-max\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm-max\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm-max\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .sm-max\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .sm-max\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .sm-max\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .sm-max\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .sm-max\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .sm-max\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm-max\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm-max\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm-max\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm-max\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm-max\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm-max\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .sm-max\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm-max\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .sm-max\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm-max\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm-max\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm-max\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm-max\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm-max\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .sm-max\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .sm-max\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm-max\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .sm-max\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .sm-max\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .sm-max\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm-max\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm-max\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm-max\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm-max\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm-max\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm-max\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm-max\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm-max\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm-max\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm-max\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm-max\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm-max\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm-max\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm-max\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .sm-max\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .sm-max\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .sm-max\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .sm-max\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .sm-max\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .sm-max\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .sm-max\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .sm-max\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .sm-max\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .sm-max\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .sm-max\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .sm-max\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .sm-max\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .sm-max\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .sm-max\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .sm-max\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .sm-max\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .sm-max\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .sm-max\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .sm-max\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .sm-max\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .sm-max\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .sm-max\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .sm-max\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .sm-max\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .sm-max\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .sm-max\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .sm-max\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .sm-max\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .sm-max\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .sm-max\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .sm-max\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .sm-max\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .sm-max\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .sm-max\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .sm-max\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .sm-max\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .sm-max\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .sm-max\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .sm-max\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .sm-max\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .sm-max\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .sm-max\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .sm-max\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .sm-max\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .sm-max\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .sm-max\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .sm-max\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .sm-max\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .sm-max\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .sm-max\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .sm-max\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .sm-max\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .sm-max\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .sm-max\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .sm-max\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .sm-max\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .sm-max\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .sm-max\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .sm-max\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .sm-max\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .sm-max\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .sm-max\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .sm-max\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .sm-max\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .sm-max\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .sm-max\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .sm-max\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .sm-max\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .sm-max\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .sm-max\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .sm-max\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .sm-max\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .sm-max\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .sm-max\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .sm-max\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .sm-max\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .sm-max\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .sm-max\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .sm-max\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .sm-max\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm-max\:filter-none {
    filter: none;
  }

  .sm-max\:blur-0, .sm-max\:blur-none {
    --tw-blur: blur(0);
  }

  .sm-max\:blur-sm {
    --tw-blur: blur(4px);
  }

  .sm-max\:blur {
    --tw-blur: blur(8px);
  }

  .sm-max\:blur-md {
    --tw-blur: blur(12px);
  }

  .sm-max\:blur-lg {
    --tw-blur: blur(16px);
  }

  .sm-max\:blur-xl {
    --tw-blur: blur(24px);
  }

  .sm-max\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .sm-max\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .sm-max\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .sm-max\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .sm-max\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .sm-max\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .sm-max\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .sm-max\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .sm-max\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .sm-max\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .sm-max\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .sm-max\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .sm-max\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .sm-max\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .sm-max\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .sm-max\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .sm-max\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .sm-max\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .sm-max\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .sm-max\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .sm-max\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .sm-max\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .sm-max\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .sm-max\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .sm-max\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .sm-max\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .sm-max\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .sm-max\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .sm-max\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .sm-max\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .sm-max\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .sm-max\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .sm-max\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .sm-max\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .sm-max\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .sm-max\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .sm-max\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .sm-max\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .sm-max\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .sm-max\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .sm-max\:invert-0 {
    --tw-invert: invert(0);
  }

  .sm-max\:invert {
    --tw-invert: invert(100%);
  }

  .sm-max\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .sm-max\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .sm-max\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .sm-max\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .sm-max\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .sm-max\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .sm-max\:sepia {
    --tw-sepia: sepia(100%);
  }

  .sm-max\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm-max\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .sm-max\:backdrop-blur-0, .sm-max\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .sm-max\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .sm-max\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .sm-max\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .sm-max\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .sm-max\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .sm-max\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .sm-max\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .sm-max\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .sm-max\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .sm-max\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .sm-max\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .sm-max\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .sm-max\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .sm-max\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .sm-max\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .sm-max\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .sm-max\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .sm-max\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .sm-max\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .sm-max\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .sm-max\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .sm-max\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .sm-max\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .sm-max\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .sm-max\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .sm-max\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .sm-max\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .sm-max\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .sm-max\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .sm-max\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .sm-max\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .sm-max\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .sm-max\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .sm-max\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .sm-max\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .sm-max\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .sm-max\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .sm-max\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .sm-max\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .sm-max\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .sm-max\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .sm-max\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .sm-max\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .sm-max\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .sm-max\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .sm-max\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .sm-max\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .sm-max\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .sm-max\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .sm-max\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .sm-max\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .sm-max\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .sm-max\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .sm-max\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .sm-max\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .sm-max\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .sm-max\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .sm-max\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .sm-max\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .sm-max\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .sm-max\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .sm-max\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .sm-max\:transition-none {
    transition-property: none;
  }

  .sm-max\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm-max\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm-max\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm-max\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm-max\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm-max\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm-max\:delay-75 {
    transition-delay: 75ms;
  }

  .sm-max\:delay-100 {
    transition-delay: .1s;
  }

  .sm-max\:delay-150 {
    transition-delay: .15s;
  }

  .sm-max\:delay-200 {
    transition-delay: .2s;
  }

  .sm-max\:delay-300 {
    transition-delay: .3s;
  }

  .sm-max\:delay-500 {
    transition-delay: .5s;
  }

  .sm-max\:delay-700 {
    transition-delay: .7s;
  }

  .sm-max\:delay-1000 {
    transition-delay: 1s;
  }

  .sm-max\:duration-75 {
    transition-duration: 75ms;
  }

  .sm-max\:duration-100 {
    transition-duration: .1s;
  }

  .sm-max\:duration-150 {
    transition-duration: .15s;
  }

  .sm-max\:duration-200 {
    transition-duration: .2s;
  }

  .sm-max\:duration-300 {
    transition-duration: .3s;
  }

  .sm-max\:duration-500 {
    transition-duration: .5s;
  }

  .sm-max\:duration-700 {
    transition-duration: .7s;
  }

  .sm-max\:duration-1000 {
    transition-duration: 1s;
  }

  .sm-max\:ease-linear {
    transition-timing-function: linear;
  }

  .sm-max\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .sm-max\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .sm-max\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .md\:container {
      max-width: 1560px;
    }
  }

  .md\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md\:pointer-events-none {
    pointer-events: none;
  }

  .md\:pointer-events-auto {
    pointer-events: auto;
  }

  .md\:visible {
    visibility: visible;
  }

  .md\:invisible {
    visibility: hidden;
  }

  .md\:static {
    position: static;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:inset-0 {
    inset: 0;
  }

  .md\:inset-1 {
    inset: .25rem;
  }

  .md\:inset-2 {
    inset: .5rem;
  }

  .md\:inset-3 {
    inset: .75rem;
  }

  .md\:inset-4 {
    inset: 1rem;
  }

  .md\:inset-5 {
    inset: 1.25rem;
  }

  .md\:inset-6 {
    inset: 1.5rem;
  }

  .md\:inset-7 {
    inset: 1.75rem;
  }

  .md\:inset-8 {
    inset: 2rem;
  }

  .md\:inset-9 {
    inset: 2.25rem;
  }

  .md\:inset-10 {
    inset: 2.5rem;
  }

  .md\:inset-11 {
    inset: 2.75rem;
  }

  .md\:inset-12 {
    inset: 3rem;
  }

  .md\:inset-14 {
    inset: 3.5rem;
  }

  .md\:inset-16 {
    inset: 4rem;
  }

  .md\:inset-20 {
    inset: 5rem;
  }

  .md\:inset-24 {
    inset: 6rem;
  }

  .md\:inset-28 {
    inset: 7rem;
  }

  .md\:inset-32 {
    inset: 8rem;
  }

  .md\:inset-36 {
    inset: 9rem;
  }

  .md\:inset-40 {
    inset: 10rem;
  }

  .md\:inset-44 {
    inset: 11rem;
  }

  .md\:inset-48 {
    inset: 12rem;
  }

  .md\:inset-52 {
    inset: 13rem;
  }

  .md\:inset-56 {
    inset: 14rem;
  }

  .md\:inset-60 {
    inset: 15rem;
  }

  .md\:inset-64 {
    inset: 16rem;
  }

  .md\:inset-72 {
    inset: 18rem;
  }

  .md\:inset-80 {
    inset: 20rem;
  }

  .md\:inset-96 {
    inset: 24rem;
  }

  .md\:inset-auto {
    inset: auto;
  }

  .md\:inset-px {
    inset: 1px;
  }

  .md\:inset-0\.5 {
    inset: .125rem;
  }

  .md\:inset-1\.5 {
    inset: .375rem;
  }

  .md\:inset-2\.5 {
    inset: .625rem;
  }

  .md\:inset-3\.5 {
    inset: .875rem;
  }

  .md\:-inset-0 {
    inset: 0;
  }

  .md\:-inset-1 {
    inset: -.25rem;
  }

  .md\:-inset-2 {
    inset: -.5rem;
  }

  .md\:-inset-3 {
    inset: -.75rem;
  }

  .md\:-inset-4 {
    inset: -1rem;
  }

  .md\:-inset-5 {
    inset: -1.25rem;
  }

  .md\:-inset-6 {
    inset: -1.5rem;
  }

  .md\:-inset-7 {
    inset: -1.75rem;
  }

  .md\:-inset-8 {
    inset: -2rem;
  }

  .md\:-inset-9 {
    inset: -2.25rem;
  }

  .md\:-inset-10 {
    inset: -2.5rem;
  }

  .md\:-inset-11 {
    inset: -2.75rem;
  }

  .md\:-inset-12 {
    inset: -3rem;
  }

  .md\:-inset-14 {
    inset: -3.5rem;
  }

  .md\:-inset-16 {
    inset: -4rem;
  }

  .md\:-inset-20 {
    inset: -5rem;
  }

  .md\:-inset-24 {
    inset: -6rem;
  }

  .md\:-inset-28 {
    inset: -7rem;
  }

  .md\:-inset-32 {
    inset: -8rem;
  }

  .md\:-inset-36 {
    inset: -9rem;
  }

  .md\:-inset-40 {
    inset: -10rem;
  }

  .md\:-inset-44 {
    inset: -11rem;
  }

  .md\:-inset-48 {
    inset: -12rem;
  }

  .md\:-inset-52 {
    inset: -13rem;
  }

  .md\:-inset-56 {
    inset: -14rem;
  }

  .md\:-inset-60 {
    inset: -15rem;
  }

  .md\:-inset-64 {
    inset: -16rem;
  }

  .md\:-inset-72 {
    inset: -18rem;
  }

  .md\:-inset-80 {
    inset: -20rem;
  }

  .md\:-inset-96 {
    inset: -24rem;
  }

  .md\:-inset-px {
    inset: -1px;
  }

  .md\:-inset-0\.5 {
    inset: -.125rem;
  }

  .md\:-inset-1\.5 {
    inset: -.375rem;
  }

  .md\:-inset-2\.5 {
    inset: -.625rem;
  }

  .md\:-inset-3\.5 {
    inset: -.875rem;
  }

  .md\:inset-1\/2 {
    inset: 50%;
  }

  .md\:inset-1\/3 {
    inset: 33.3333%;
  }

  .md\:inset-2\/3 {
    inset: 66.6667%;
  }

  .md\:inset-1\/4 {
    inset: 25%;
  }

  .md\:inset-2\/4 {
    inset: 50%;
  }

  .md\:inset-3\/4 {
    inset: 75%;
  }

  .md\:inset-full {
    inset: 100%;
  }

  .md\:-inset-1\/2 {
    inset: -50%;
  }

  .md\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .md\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .md\:-inset-1\/4 {
    inset: -25%;
  }

  .md\:-inset-2\/4 {
    inset: -50%;
  }

  .md\:-inset-3\/4 {
    inset: -75%;
  }

  .md\:-inset-full {
    inset: -100%;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .md\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .md\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .md\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .md\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .md\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .md\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .md\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .md\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .md\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .md\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .md\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .md\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .md\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .md\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .md\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .md\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .md\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .md\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .md\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .md\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .md\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .md\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .md\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .md\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .md\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .md\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .md\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .md\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .md\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .md\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .md\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .md\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .md\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .md\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .md\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .md\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .md\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .md\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .md\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .md\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .md\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .md\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .md\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .md\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .md\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .md\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .md\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .md\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .md\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .md\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .md\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .md\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .md\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .md\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .md\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .md\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .md\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .md\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .md\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .md\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .md\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .md\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .md\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .md\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .md\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .md\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .md\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .md\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .md\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .md\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .md\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .md\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .md\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .md\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .md\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .md\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .md\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .md\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .md\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .md\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .md\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .md\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .md\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .md\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .md\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .md\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .md\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .md\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .md\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .md\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .md\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .md\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .md\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .md\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .md\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .md\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .md\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .md\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .md\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .md\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .md\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .md\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .md\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .md\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .md\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .md\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .md\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .md\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .md\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .md\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .md\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .md\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .md\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .md\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .md\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .md\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .md\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .md\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .md\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .md\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .md\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .md\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .md\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .md\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .md\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .md\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .md\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .md\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .md\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .md\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .md\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .md\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .md\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .md\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .md\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .md\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .md\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .md\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .md\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .md\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .md\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .md\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .md\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .md\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .md\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .md\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .md\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .md\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .md\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .md\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .md\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .md\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .md\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .md\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .md\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .md\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .md\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-1 {
    top: .25rem;
  }

  .md\:top-2 {
    top: .5rem;
  }

  .md\:top-3 {
    top: .75rem;
  }

  .md\:top-4 {
    top: 1rem;
  }

  .md\:top-5 {
    top: 1.25rem;
  }

  .md\:top-6 {
    top: 1.5rem;
  }

  .md\:top-7 {
    top: 1.75rem;
  }

  .md\:top-8 {
    top: 2rem;
  }

  .md\:top-9 {
    top: 2.25rem;
  }

  .md\:top-10 {
    top: 2.5rem;
  }

  .md\:top-11 {
    top: 2.75rem;
  }

  .md\:top-12 {
    top: 3rem;
  }

  .md\:top-14 {
    top: 3.5rem;
  }

  .md\:top-16 {
    top: 4rem;
  }

  .md\:top-20 {
    top: 5rem;
  }

  .md\:top-24 {
    top: 6rem;
  }

  .md\:top-28 {
    top: 7rem;
  }

  .md\:top-32 {
    top: 8rem;
  }

  .md\:top-36 {
    top: 9rem;
  }

  .md\:top-40 {
    top: 10rem;
  }

  .md\:top-44 {
    top: 11rem;
  }

  .md\:top-48 {
    top: 12rem;
  }

  .md\:top-52 {
    top: 13rem;
  }

  .md\:top-56 {
    top: 14rem;
  }

  .md\:top-60 {
    top: 15rem;
  }

  .md\:top-64 {
    top: 16rem;
  }

  .md\:top-72 {
    top: 18rem;
  }

  .md\:top-80 {
    top: 20rem;
  }

  .md\:top-96 {
    top: 24rem;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:top-px {
    top: 1px;
  }

  .md\:top-0\.5 {
    top: .125rem;
  }

  .md\:top-1\.5 {
    top: .375rem;
  }

  .md\:top-2\.5 {
    top: .625rem;
  }

  .md\:top-3\.5 {
    top: .875rem;
  }

  .md\:-top-0 {
    top: 0;
  }

  .md\:-top-1 {
    top: -.25rem;
  }

  .md\:-top-2 {
    top: -.5rem;
  }

  .md\:-top-3 {
    top: -.75rem;
  }

  .md\:-top-4 {
    top: -1rem;
  }

  .md\:-top-5 {
    top: -1.25rem;
  }

  .md\:-top-6 {
    top: -1.5rem;
  }

  .md\:-top-7 {
    top: -1.75rem;
  }

  .md\:-top-8 {
    top: -2rem;
  }

  .md\:-top-9 {
    top: -2.25rem;
  }

  .md\:-top-10 {
    top: -2.5rem;
  }

  .md\:-top-11 {
    top: -2.75rem;
  }

  .md\:-top-12 {
    top: -3rem;
  }

  .md\:-top-14 {
    top: -3.5rem;
  }

  .md\:-top-16 {
    top: -4rem;
  }

  .md\:-top-20 {
    top: -5rem;
  }

  .md\:-top-24 {
    top: -6rem;
  }

  .md\:-top-28 {
    top: -7rem;
  }

  .md\:-top-32 {
    top: -8rem;
  }

  .md\:-top-36 {
    top: -9rem;
  }

  .md\:-top-40 {
    top: -10rem;
  }

  .md\:-top-44 {
    top: -11rem;
  }

  .md\:-top-48 {
    top: -12rem;
  }

  .md\:-top-52 {
    top: -13rem;
  }

  .md\:-top-56 {
    top: -14rem;
  }

  .md\:-top-60 {
    top: -15rem;
  }

  .md\:-top-64 {
    top: -16rem;
  }

  .md\:-top-72 {
    top: -18rem;
  }

  .md\:-top-80 {
    top: -20rem;
  }

  .md\:-top-96 {
    top: -24rem;
  }

  .md\:-top-px {
    top: -1px;
  }

  .md\:-top-0\.5 {
    top: -.125rem;
  }

  .md\:-top-1\.5 {
    top: -.375rem;
  }

  .md\:-top-2\.5 {
    top: -.625rem;
  }

  .md\:-top-3\.5 {
    top: -.875rem;
  }

  .md\:top-1\/2 {
    top: 50%;
  }

  .md\:top-1\/3 {
    top: 33.3333%;
  }

  .md\:top-2\/3 {
    top: 66.6667%;
  }

  .md\:top-1\/4 {
    top: 25%;
  }

  .md\:top-2\/4 {
    top: 50%;
  }

  .md\:top-3\/4 {
    top: 75%;
  }

  .md\:top-full {
    top: 100%;
  }

  .md\:-top-1\/2 {
    top: -50%;
  }

  .md\:-top-1\/3 {
    top: -33.3333%;
  }

  .md\:-top-2\/3 {
    top: -66.6667%;
  }

  .md\:-top-1\/4 {
    top: -25%;
  }

  .md\:-top-2\/4 {
    top: -50%;
  }

  .md\:-top-3\/4 {
    top: -75%;
  }

  .md\:-top-full {
    top: -100%;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:right-1 {
    right: .25rem;
  }

  .md\:right-2 {
    right: .5rem;
  }

  .md\:right-3 {
    right: .75rem;
  }

  .md\:right-4 {
    right: 1rem;
  }

  .md\:right-5 {
    right: 1.25rem;
  }

  .md\:right-6 {
    right: 1.5rem;
  }

  .md\:right-7 {
    right: 1.75rem;
  }

  .md\:right-8 {
    right: 2rem;
  }

  .md\:right-9 {
    right: 2.25rem;
  }

  .md\:right-10 {
    right: 2.5rem;
  }

  .md\:right-11 {
    right: 2.75rem;
  }

  .md\:right-12 {
    right: 3rem;
  }

  .md\:right-14 {
    right: 3.5rem;
  }

  .md\:right-16 {
    right: 4rem;
  }

  .md\:right-20 {
    right: 5rem;
  }

  .md\:right-24 {
    right: 6rem;
  }

  .md\:right-28 {
    right: 7rem;
  }

  .md\:right-32 {
    right: 8rem;
  }

  .md\:right-36 {
    right: 9rem;
  }

  .md\:right-40 {
    right: 10rem;
  }

  .md\:right-44 {
    right: 11rem;
  }

  .md\:right-48 {
    right: 12rem;
  }

  .md\:right-52 {
    right: 13rem;
  }

  .md\:right-56 {
    right: 14rem;
  }

  .md\:right-60 {
    right: 15rem;
  }

  .md\:right-64 {
    right: 16rem;
  }

  .md\:right-72 {
    right: 18rem;
  }

  .md\:right-80 {
    right: 20rem;
  }

  .md\:right-96 {
    right: 24rem;
  }

  .md\:right-auto {
    right: auto;
  }

  .md\:right-px {
    right: 1px;
  }

  .md\:right-0\.5 {
    right: .125rem;
  }

  .md\:right-1\.5 {
    right: .375rem;
  }

  .md\:right-2\.5 {
    right: .625rem;
  }

  .md\:right-3\.5 {
    right: .875rem;
  }

  .md\:-right-0 {
    right: 0;
  }

  .md\:-right-1 {
    right: -.25rem;
  }

  .md\:-right-2 {
    right: -.5rem;
  }

  .md\:-right-3 {
    right: -.75rem;
  }

  .md\:-right-4 {
    right: -1rem;
  }

  .md\:-right-5 {
    right: -1.25rem;
  }

  .md\:-right-6 {
    right: -1.5rem;
  }

  .md\:-right-7 {
    right: -1.75rem;
  }

  .md\:-right-8 {
    right: -2rem;
  }

  .md\:-right-9 {
    right: -2.25rem;
  }

  .md\:-right-10 {
    right: -2.5rem;
  }

  .md\:-right-11 {
    right: -2.75rem;
  }

  .md\:-right-12 {
    right: -3rem;
  }

  .md\:-right-14 {
    right: -3.5rem;
  }

  .md\:-right-16 {
    right: -4rem;
  }

  .md\:-right-20 {
    right: -5rem;
  }

  .md\:-right-24 {
    right: -6rem;
  }

  .md\:-right-28 {
    right: -7rem;
  }

  .md\:-right-32 {
    right: -8rem;
  }

  .md\:-right-36 {
    right: -9rem;
  }

  .md\:-right-40 {
    right: -10rem;
  }

  .md\:-right-44 {
    right: -11rem;
  }

  .md\:-right-48 {
    right: -12rem;
  }

  .md\:-right-52 {
    right: -13rem;
  }

  .md\:-right-56 {
    right: -14rem;
  }

  .md\:-right-60 {
    right: -15rem;
  }

  .md\:-right-64 {
    right: -16rem;
  }

  .md\:-right-72 {
    right: -18rem;
  }

  .md\:-right-80 {
    right: -20rem;
  }

  .md\:-right-96 {
    right: -24rem;
  }

  .md\:-right-px {
    right: -1px;
  }

  .md\:-right-0\.5 {
    right: -.125rem;
  }

  .md\:-right-1\.5 {
    right: -.375rem;
  }

  .md\:-right-2\.5 {
    right: -.625rem;
  }

  .md\:-right-3\.5 {
    right: -.875rem;
  }

  .md\:right-1\/2 {
    right: 50%;
  }

  .md\:right-1\/3 {
    right: 33.3333%;
  }

  .md\:right-2\/3 {
    right: 66.6667%;
  }

  .md\:right-1\/4 {
    right: 25%;
  }

  .md\:right-2\/4 {
    right: 50%;
  }

  .md\:right-3\/4 {
    right: 75%;
  }

  .md\:right-full {
    right: 100%;
  }

  .md\:-right-1\/2 {
    right: -50%;
  }

  .md\:-right-1\/3 {
    right: -33.3333%;
  }

  .md\:-right-2\/3 {
    right: -66.6667%;
  }

  .md\:-right-1\/4 {
    right: -25%;
  }

  .md\:-right-2\/4 {
    right: -50%;
  }

  .md\:-right-3\/4 {
    right: -75%;
  }

  .md\:-right-full {
    right: -100%;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-1 {
    bottom: .25rem;
  }

  .md\:bottom-2 {
    bottom: .5rem;
  }

  .md\:bottom-3 {
    bottom: .75rem;
  }

  .md\:bottom-4 {
    bottom: 1rem;
  }

  .md\:bottom-5 {
    bottom: 1.25rem;
  }

  .md\:bottom-6 {
    bottom: 1.5rem;
  }

  .md\:bottom-7 {
    bottom: 1.75rem;
  }

  .md\:bottom-8 {
    bottom: 2rem;
  }

  .md\:bottom-9 {
    bottom: 2.25rem;
  }

  .md\:bottom-10 {
    bottom: 2.5rem;
  }

  .md\:bottom-11 {
    bottom: 2.75rem;
  }

  .md\:bottom-12 {
    bottom: 3rem;
  }

  .md\:bottom-14 {
    bottom: 3.5rem;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:bottom-20 {
    bottom: 5rem;
  }

  .md\:bottom-24 {
    bottom: 6rem;
  }

  .md\:bottom-28 {
    bottom: 7rem;
  }

  .md\:bottom-32 {
    bottom: 8rem;
  }

  .md\:bottom-36 {
    bottom: 9rem;
  }

  .md\:bottom-40 {
    bottom: 10rem;
  }

  .md\:bottom-44 {
    bottom: 11rem;
  }

  .md\:bottom-48 {
    bottom: 12rem;
  }

  .md\:bottom-52 {
    bottom: 13rem;
  }

  .md\:bottom-56 {
    bottom: 14rem;
  }

  .md\:bottom-60 {
    bottom: 15rem;
  }

  .md\:bottom-64 {
    bottom: 16rem;
  }

  .md\:bottom-72 {
    bottom: 18rem;
  }

  .md\:bottom-80 {
    bottom: 20rem;
  }

  .md\:bottom-96 {
    bottom: 24rem;
  }

  .md\:bottom-auto {
    bottom: auto;
  }

  .md\:bottom-px {
    bottom: 1px;
  }

  .md\:bottom-0\.5 {
    bottom: .125rem;
  }

  .md\:bottom-1\.5 {
    bottom: .375rem;
  }

  .md\:bottom-2\.5 {
    bottom: .625rem;
  }

  .md\:bottom-3\.5 {
    bottom: .875rem;
  }

  .md\:-bottom-0 {
    bottom: 0;
  }

  .md\:-bottom-1 {
    bottom: -.25rem;
  }

  .md\:-bottom-2 {
    bottom: -.5rem;
  }

  .md\:-bottom-3 {
    bottom: -.75rem;
  }

  .md\:-bottom-4 {
    bottom: -1rem;
  }

  .md\:-bottom-5 {
    bottom: -1.25rem;
  }

  .md\:-bottom-6 {
    bottom: -1.5rem;
  }

  .md\:-bottom-7 {
    bottom: -1.75rem;
  }

  .md\:-bottom-8 {
    bottom: -2rem;
  }

  .md\:-bottom-9 {
    bottom: -2.25rem;
  }

  .md\:-bottom-10 {
    bottom: -2.5rem;
  }

  .md\:-bottom-11 {
    bottom: -2.75rem;
  }

  .md\:-bottom-12 {
    bottom: -3rem;
  }

  .md\:-bottom-14 {
    bottom: -3.5rem;
  }

  .md\:-bottom-16 {
    bottom: -4rem;
  }

  .md\:-bottom-20 {
    bottom: -5rem;
  }

  .md\:-bottom-24 {
    bottom: -6rem;
  }

  .md\:-bottom-28 {
    bottom: -7rem;
  }

  .md\:-bottom-32 {
    bottom: -8rem;
  }

  .md\:-bottom-36 {
    bottom: -9rem;
  }

  .md\:-bottom-40 {
    bottom: -10rem;
  }

  .md\:-bottom-44 {
    bottom: -11rem;
  }

  .md\:-bottom-48 {
    bottom: -12rem;
  }

  .md\:-bottom-52 {
    bottom: -13rem;
  }

  .md\:-bottom-56 {
    bottom: -14rem;
  }

  .md\:-bottom-60 {
    bottom: -15rem;
  }

  .md\:-bottom-64 {
    bottom: -16rem;
  }

  .md\:-bottom-72 {
    bottom: -18rem;
  }

  .md\:-bottom-80 {
    bottom: -20rem;
  }

  .md\:-bottom-96 {
    bottom: -24rem;
  }

  .md\:-bottom-px {
    bottom: -1px;
  }

  .md\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .md\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .md\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .md\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .md\:bottom-1\/2 {
    bottom: 50%;
  }

  .md\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .md\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .md\:bottom-1\/4 {
    bottom: 25%;
  }

  .md\:bottom-2\/4 {
    bottom: 50%;
  }

  .md\:bottom-3\/4 {
    bottom: 75%;
  }

  .md\:bottom-full {
    bottom: 100%;
  }

  .md\:-bottom-1\/2 {
    bottom: -50%;
  }

  .md\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .md\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .md\:-bottom-1\/4 {
    bottom: -25%;
  }

  .md\:-bottom-2\/4 {
    bottom: -50%;
  }

  .md\:-bottom-3\/4 {
    bottom: -75%;
  }

  .md\:-bottom-full {
    bottom: -100%;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:left-1 {
    left: .25rem;
  }

  .md\:left-2 {
    left: .5rem;
  }

  .md\:left-3 {
    left: .75rem;
  }

  .md\:left-4 {
    left: 1rem;
  }

  .md\:left-5 {
    left: 1.25rem;
  }

  .md\:left-6 {
    left: 1.5rem;
  }

  .md\:left-7 {
    left: 1.75rem;
  }

  .md\:left-8 {
    left: 2rem;
  }

  .md\:left-9 {
    left: 2.25rem;
  }

  .md\:left-10 {
    left: 2.5rem;
  }

  .md\:left-11 {
    left: 2.75rem;
  }

  .md\:left-12 {
    left: 3rem;
  }

  .md\:left-14 {
    left: 3.5rem;
  }

  .md\:left-16 {
    left: 4rem;
  }

  .md\:left-20 {
    left: 5rem;
  }

  .md\:left-24 {
    left: 6rem;
  }

  .md\:left-28 {
    left: 7rem;
  }

  .md\:left-32 {
    left: 8rem;
  }

  .md\:left-36 {
    left: 9rem;
  }

  .md\:left-40 {
    left: 10rem;
  }

  .md\:left-44 {
    left: 11rem;
  }

  .md\:left-48 {
    left: 12rem;
  }

  .md\:left-52 {
    left: 13rem;
  }

  .md\:left-56 {
    left: 14rem;
  }

  .md\:left-60 {
    left: 15rem;
  }

  .md\:left-64 {
    left: 16rem;
  }

  .md\:left-72 {
    left: 18rem;
  }

  .md\:left-80 {
    left: 20rem;
  }

  .md\:left-96 {
    left: 24rem;
  }

  .md\:left-auto {
    left: auto;
  }

  .md\:left-px {
    left: 1px;
  }

  .md\:left-0\.5 {
    left: .125rem;
  }

  .md\:left-1\.5 {
    left: .375rem;
  }

  .md\:left-2\.5 {
    left: .625rem;
  }

  .md\:left-3\.5 {
    left: .875rem;
  }

  .md\:-left-0 {
    left: 0;
  }

  .md\:-left-1 {
    left: -.25rem;
  }

  .md\:-left-2 {
    left: -.5rem;
  }

  .md\:-left-3 {
    left: -.75rem;
  }

  .md\:-left-4 {
    left: -1rem;
  }

  .md\:-left-5 {
    left: -1.25rem;
  }

  .md\:-left-6 {
    left: -1.5rem;
  }

  .md\:-left-7 {
    left: -1.75rem;
  }

  .md\:-left-8 {
    left: -2rem;
  }

  .md\:-left-9 {
    left: -2.25rem;
  }

  .md\:-left-10 {
    left: -2.5rem;
  }

  .md\:-left-11 {
    left: -2.75rem;
  }

  .md\:-left-12 {
    left: -3rem;
  }

  .md\:-left-14 {
    left: -3.5rem;
  }

  .md\:-left-16 {
    left: -4rem;
  }

  .md\:-left-20 {
    left: -5rem;
  }

  .md\:-left-24 {
    left: -6rem;
  }

  .md\:-left-28 {
    left: -7rem;
  }

  .md\:-left-32 {
    left: -8rem;
  }

  .md\:-left-36 {
    left: -9rem;
  }

  .md\:-left-40 {
    left: -10rem;
  }

  .md\:-left-44 {
    left: -11rem;
  }

  .md\:-left-48 {
    left: -12rem;
  }

  .md\:-left-52 {
    left: -13rem;
  }

  .md\:-left-56 {
    left: -14rem;
  }

  .md\:-left-60 {
    left: -15rem;
  }

  .md\:-left-64 {
    left: -16rem;
  }

  .md\:-left-72 {
    left: -18rem;
  }

  .md\:-left-80 {
    left: -20rem;
  }

  .md\:-left-96 {
    left: -24rem;
  }

  .md\:-left-px {
    left: -1px;
  }

  .md\:-left-0\.5 {
    left: -.125rem;
  }

  .md\:-left-1\.5 {
    left: -.375rem;
  }

  .md\:-left-2\.5 {
    left: -.625rem;
  }

  .md\:-left-3\.5 {
    left: -.875rem;
  }

  .md\:left-1\/2 {
    left: 50%;
  }

  .md\:left-1\/3 {
    left: 33.3333%;
  }

  .md\:left-2\/3 {
    left: 66.6667%;
  }

  .md\:left-1\/4 {
    left: 25%;
  }

  .md\:left-2\/4 {
    left: 50%;
  }

  .md\:left-3\/4 {
    left: 75%;
  }

  .md\:left-full {
    left: 100%;
  }

  .md\:-left-1\/2 {
    left: -50%;
  }

  .md\:-left-1\/3 {
    left: -33.3333%;
  }

  .md\:-left-2\/3 {
    left: -66.6667%;
  }

  .md\:-left-1\/4 {
    left: -25%;
  }

  .md\:-left-2\/4 {
    left: -50%;
  }

  .md\:-left-3\/4 {
    left: -75%;
  }

  .md\:-left-full {
    left: -100%;
  }

  .md\:isolate {
    isolation: isolate;
  }

  .md\:isolation-auto {
    isolation: auto;
  }

  .md\:z-0 {
    z-index: 0;
  }

  .md\:z-10 {
    z-index: 10;
  }

  .md\:z-20 {
    z-index: 20;
  }

  .md\:z-30 {
    z-index: 30;
  }

  .md\:z-40 {
    z-index: 40;
  }

  .md\:z-50 {
    z-index: 50;
  }

  .md\:z-auto {
    z-index: auto;
  }

  .md\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .md\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .md\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .md\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .md\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .md\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .md\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .md\:focus\:z-0:focus {
    z-index: 0;
  }

  .md\:focus\:z-10:focus {
    z-index: 10;
  }

  .md\:focus\:z-20:focus {
    z-index: 20;
  }

  .md\:focus\:z-30:focus {
    z-index: 30;
  }

  .md\:focus\:z-40:focus {
    z-index: 40;
  }

  .md\:focus\:z-50:focus {
    z-index: 50;
  }

  .md\:focus\:z-auto:focus {
    z-index: auto;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:order-4 {
    order: 4;
  }

  .md\:order-5 {
    order: 5;
  }

  .md\:order-6 {
    order: 6;
  }

  .md\:order-7 {
    order: 7;
  }

  .md\:order-8 {
    order: 8;
  }

  .md\:order-9 {
    order: 9;
  }

  .md\:order-10 {
    order: 10;
  }

  .md\:order-11 {
    order: 11;
  }

  .md\:order-12 {
    order: 12;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:col-auto {
    grid-column: auto;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-full {
    grid-column: 1 / -1;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:col-start-11 {
    grid-column-start: 11;
  }

  .md\:col-start-12 {
    grid-column-start: 12;
  }

  .md\:col-start-13 {
    grid-column-start: 13;
  }

  .md\:col-start-auto {
    grid-column-start: auto;
  }

  .md\:col-end-1 {
    grid-column-end: 1;
  }

  .md\:col-end-2 {
    grid-column-end: 2;
  }

  .md\:col-end-3 {
    grid-column-end: 3;
  }

  .md\:col-end-4 {
    grid-column-end: 4;
  }

  .md\:col-end-5 {
    grid-column-end: 5;
  }

  .md\:col-end-6 {
    grid-column-end: 6;
  }

  .md\:col-end-7 {
    grid-column-end: 7;
  }

  .md\:col-end-8 {
    grid-column-end: 8;
  }

  .md\:col-end-9 {
    grid-column-end: 9;
  }

  .md\:col-end-10 {
    grid-column-end: 10;
  }

  .md\:col-end-11 {
    grid-column-end: 11;
  }

  .md\:col-end-12 {
    grid-column-end: 12;
  }

  .md\:col-end-13 {
    grid-column-end: 13;
  }

  .md\:col-end-auto {
    grid-column-end: auto;
  }

  .md\:row-auto {
    grid-row: auto;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:row-span-full {
    grid-row: 1 / -1;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:row-start-2 {
    grid-row-start: 2;
  }

  .md\:row-start-3 {
    grid-row-start: 3;
  }

  .md\:row-start-4 {
    grid-row-start: 4;
  }

  .md\:row-start-5 {
    grid-row-start: 5;
  }

  .md\:row-start-6 {
    grid-row-start: 6;
  }

  .md\:row-start-7 {
    grid-row-start: 7;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:row-end-1 {
    grid-row-end: 1;
  }

  .md\:row-end-2 {
    grid-row-end: 2;
  }

  .md\:row-end-3 {
    grid-row-end: 3;
  }

  .md\:row-end-4 {
    grid-row-end: 4;
  }

  .md\:row-end-5 {
    grid-row-end: 5;
  }

  .md\:row-end-6 {
    grid-row-end: 6;
  }

  .md\:row-end-7 {
    grid-row-end: 7;
  }

  .md\:row-end-auto {
    grid-row-end: auto;
  }

  .md\:float-right {
    float: right;
  }

  .md\:float-left {
    float: left;
  }

  .md\:float-none {
    float: none;
  }

  .md\:clear-left {
    clear: left;
  }

  .md\:clear-right {
    clear: right;
  }

  .md\:clear-both {
    clear: both;
  }

  .md\:clear-none {
    clear: none;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:m-1 {
    margin: .25rem;
  }

  .md\:m-2 {
    margin: .5rem;
  }

  .md\:m-3 {
    margin: .75rem;
  }

  .md\:m-4 {
    margin: 1rem;
  }

  .md\:m-5 {
    margin: 1.25rem;
  }

  .md\:m-6 {
    margin: 1.5rem;
  }

  .md\:m-7 {
    margin: 1.75rem;
  }

  .md\:m-8 {
    margin: 2rem;
  }

  .md\:m-9 {
    margin: 2.25rem;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:m-11 {
    margin: 2.75rem;
  }

  .md\:m-12 {
    margin: 3rem;
  }

  .md\:m-14 {
    margin: 3.5rem;
  }

  .md\:m-16 {
    margin: 4rem;
  }

  .md\:m-20 {
    margin: 5rem;
  }

  .md\:m-24 {
    margin: 6rem;
  }

  .md\:m-28 {
    margin: 7rem;
  }

  .md\:m-32 {
    margin: 8rem;
  }

  .md\:m-36 {
    margin: 9rem;
  }

  .md\:m-40 {
    margin: 10rem;
  }

  .md\:m-44 {
    margin: 11rem;
  }

  .md\:m-48 {
    margin: 12rem;
  }

  .md\:m-52 {
    margin: 13rem;
  }

  .md\:m-56 {
    margin: 14rem;
  }

  .md\:m-60 {
    margin: 15rem;
  }

  .md\:m-64 {
    margin: 16rem;
  }

  .md\:m-72 {
    margin: 18rem;
  }

  .md\:m-80 {
    margin: 20rem;
  }

  .md\:m-96 {
    margin: 24rem;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:m-px {
    margin: 1px;
  }

  .md\:m-0\.5 {
    margin: .125rem;
  }

  .md\:m-1\.5 {
    margin: .375rem;
  }

  .md\:m-2\.5 {
    margin: .625rem;
  }

  .md\:m-3\.5 {
    margin: .875rem;
  }

  .md\:-m-0 {
    margin: 0;
  }

  .md\:-m-1 {
    margin: -.25rem;
  }

  .md\:-m-2 {
    margin: -.5rem;
  }

  .md\:-m-3 {
    margin: -.75rem;
  }

  .md\:-m-4 {
    margin: -1rem;
  }

  .md\:-m-5 {
    margin: -1.25rem;
  }

  .md\:-m-6 {
    margin: -1.5rem;
  }

  .md\:-m-7 {
    margin: -1.75rem;
  }

  .md\:-m-8 {
    margin: -2rem;
  }

  .md\:-m-9 {
    margin: -2.25rem;
  }

  .md\:-m-10 {
    margin: -2.5rem;
  }

  .md\:-m-11 {
    margin: -2.75rem;
  }

  .md\:-m-12 {
    margin: -3rem;
  }

  .md\:-m-14 {
    margin: -3.5rem;
  }

  .md\:-m-16 {
    margin: -4rem;
  }

  .md\:-m-20 {
    margin: -5rem;
  }

  .md\:-m-24 {
    margin: -6rem;
  }

  .md\:-m-28 {
    margin: -7rem;
  }

  .md\:-m-32 {
    margin: -8rem;
  }

  .md\:-m-36 {
    margin: -9rem;
  }

  .md\:-m-40 {
    margin: -10rem;
  }

  .md\:-m-44 {
    margin: -11rem;
  }

  .md\:-m-48 {
    margin: -12rem;
  }

  .md\:-m-52 {
    margin: -13rem;
  }

  .md\:-m-56 {
    margin: -14rem;
  }

  .md\:-m-60 {
    margin: -15rem;
  }

  .md\:-m-64 {
    margin: -16rem;
  }

  .md\:-m-72 {
    margin: -18rem;
  }

  .md\:-m-80 {
    margin: -20rem;
  }

  .md\:-m-96 {
    margin: -24rem;
  }

  .md\:-m-px {
    margin: -1px;
  }

  .md\:-m-0\.5 {
    margin: -.125rem;
  }

  .md\:-m-1\.5 {
    margin: -.375rem;
  }

  .md\:-m-2\.5 {
    margin: -.625rem;
  }

  .md\:-m-3\.5 {
    margin: -.875rem;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .md\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .md\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .md\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .md\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .md\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .md\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .md\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .md\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .md\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .md\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .md\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .md\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .md\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .md\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .md\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .md\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .md\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .md\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .md\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .md\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .md\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .md\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .md\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .md\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .md\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .md\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .md\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .md\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mt-3 {
    margin-top: .75rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mt-7 {
    margin-top: 1.75rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-11 {
    margin-top: 2.75rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-14 {
    margin-top: 3.5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-28 {
    margin-top: 7rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-36 {
    margin-top: 9rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-44 {
    margin-top: 11rem;
  }

  .md\:mt-48 {
    margin-top: 12rem;
  }

  .md\:mt-52 {
    margin-top: 13rem;
  }

  .md\:mt-56 {
    margin-top: 14rem;
  }

  .md\:mt-60 {
    margin-top: 15rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:mt-72 {
    margin-top: 18rem;
  }

  .md\:mt-80 {
    margin-top: 20rem;
  }

  .md\:mt-96 {
    margin-top: 24rem;
  }

  .md\:mt-auto {
    margin-top: auto;
  }

  .md\:mt-px {
    margin-top: 1px;
  }

  .md\:mt-0\.5 {
    margin-top: .125rem;
  }

  .md\:mt-1\.5 {
    margin-top: .375rem;
  }

  .md\:mt-2\.5 {
    margin-top: .625rem;
  }

  .md\:mt-3\.5 {
    margin-top: .875rem;
  }

  .md\:-mt-0 {
    margin-top: 0;
  }

  .md\:-mt-1 {
    margin-top: -.25rem;
  }

  .md\:-mt-2 {
    margin-top: -.5rem;
  }

  .md\:-mt-3 {
    margin-top: -.75rem;
  }

  .md\:-mt-4 {
    margin-top: -1rem;
  }

  .md\:-mt-5 {
    margin-top: -1.25rem;
  }

  .md\:-mt-6 {
    margin-top: -1.5rem;
  }

  .md\:-mt-7 {
    margin-top: -1.75rem;
  }

  .md\:-mt-8 {
    margin-top: -2rem;
  }

  .md\:-mt-9 {
    margin-top: -2.25rem;
  }

  .md\:-mt-10 {
    margin-top: -2.5rem;
  }

  .md\:-mt-11 {
    margin-top: -2.75rem;
  }

  .md\:-mt-12 {
    margin-top: -3rem;
  }

  .md\:-mt-14 {
    margin-top: -3.5rem;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:-mt-24 {
    margin-top: -6rem;
  }

  .md\:-mt-28 {
    margin-top: -7rem;
  }

  .md\:-mt-32 {
    margin-top: -8rem;
  }

  .md\:-mt-36 {
    margin-top: -9rem;
  }

  .md\:-mt-40 {
    margin-top: -10rem;
  }

  .md\:-mt-44 {
    margin-top: -11rem;
  }

  .md\:-mt-48 {
    margin-top: -12rem;
  }

  .md\:-mt-52 {
    margin-top: -13rem;
  }

  .md\:-mt-56 {
    margin-top: -14rem;
  }

  .md\:-mt-60 {
    margin-top: -15rem;
  }

  .md\:-mt-64 {
    margin-top: -16rem;
  }

  .md\:-mt-72 {
    margin-top: -18rem;
  }

  .md\:-mt-80 {
    margin-top: -20rem;
  }

  .md\:-mt-96 {
    margin-top: -24rem;
  }

  .md\:-mt-px {
    margin-top: -1px;
  }

  .md\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .md\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .md\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .md\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mr-1 {
    margin-right: .25rem;
  }

  .md\:mr-2 {
    margin-right: .5rem;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mr-5 {
    margin-right: 1.25rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mr-9 {
    margin-right: 2.25rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mr-11 {
    margin-right: 2.75rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mr-14 {
    margin-right: 3.5rem;
  }

  .md\:mr-16 {
    margin-right: 4rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:mr-24 {
    margin-right: 6rem;
  }

  .md\:mr-28 {
    margin-right: 7rem;
  }

  .md\:mr-32 {
    margin-right: 8rem;
  }

  .md\:mr-36 {
    margin-right: 9rem;
  }

  .md\:mr-40 {
    margin-right: 10rem;
  }

  .md\:mr-44 {
    margin-right: 11rem;
  }

  .md\:mr-48 {
    margin-right: 12rem;
  }

  .md\:mr-52 {
    margin-right: 13rem;
  }

  .md\:mr-56 {
    margin-right: 14rem;
  }

  .md\:mr-60 {
    margin-right: 15rem;
  }

  .md\:mr-64 {
    margin-right: 16rem;
  }

  .md\:mr-72 {
    margin-right: 18rem;
  }

  .md\:mr-80 {
    margin-right: 20rem;
  }

  .md\:mr-96 {
    margin-right: 24rem;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mr-px {
    margin-right: 1px;
  }

  .md\:mr-0\.5 {
    margin-right: .125rem;
  }

  .md\:mr-1\.5 {
    margin-right: .375rem;
  }

  .md\:mr-2\.5 {
    margin-right: .625rem;
  }

  .md\:mr-3\.5 {
    margin-right: .875rem;
  }

  .md\:-mr-0 {
    margin-right: 0;
  }

  .md\:-mr-1 {
    margin-right: -.25rem;
  }

  .md\:-mr-2 {
    margin-right: -.5rem;
  }

  .md\:-mr-3 {
    margin-right: -.75rem;
  }

  .md\:-mr-4 {
    margin-right: -1rem;
  }

  .md\:-mr-5 {
    margin-right: -1.25rem;
  }

  .md\:-mr-6 {
    margin-right: -1.5rem;
  }

  .md\:-mr-7 {
    margin-right: -1.75rem;
  }

  .md\:-mr-8 {
    margin-right: -2rem;
  }

  .md\:-mr-9 {
    margin-right: -2.25rem;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md\:-mr-11 {
    margin-right: -2.75rem;
  }

  .md\:-mr-12 {
    margin-right: -3rem;
  }

  .md\:-mr-14 {
    margin-right: -3.5rem;
  }

  .md\:-mr-16 {
    margin-right: -4rem;
  }

  .md\:-mr-20 {
    margin-right: -5rem;
  }

  .md\:-mr-24 {
    margin-right: -6rem;
  }

  .md\:-mr-28 {
    margin-right: -7rem;
  }

  .md\:-mr-32 {
    margin-right: -8rem;
  }

  .md\:-mr-36 {
    margin-right: -9rem;
  }

  .md\:-mr-40 {
    margin-right: -10rem;
  }

  .md\:-mr-44 {
    margin-right: -11rem;
  }

  .md\:-mr-48 {
    margin-right: -12rem;
  }

  .md\:-mr-52 {
    margin-right: -13rem;
  }

  .md\:-mr-56 {
    margin-right: -14rem;
  }

  .md\:-mr-60 {
    margin-right: -15rem;
  }

  .md\:-mr-64 {
    margin-right: -16rem;
  }

  .md\:-mr-72 {
    margin-right: -18rem;
  }

  .md\:-mr-80 {
    margin-right: -20rem;
  }

  .md\:-mr-96 {
    margin-right: -24rem;
  }

  .md\:-mr-px {
    margin-right: -1px;
  }

  .md\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .md\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .md\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .md\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-1 {
    margin-bottom: .25rem;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:mb-3 {
    margin-bottom: .75rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mb-28 {
    margin-bottom: 7rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mb-36 {
    margin-bottom: 9rem;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:mb-48 {
    margin-bottom: 12rem;
  }

  .md\:mb-52 {
    margin-bottom: 13rem;
  }

  .md\:mb-56 {
    margin-bottom: 14rem;
  }

  .md\:mb-60 {
    margin-bottom: 15rem;
  }

  .md\:mb-64 {
    margin-bottom: 16rem;
  }

  .md\:mb-72 {
    margin-bottom: 18rem;
  }

  .md\:mb-80 {
    margin-bottom: 20rem;
  }

  .md\:mb-96 {
    margin-bottom: 24rem;
  }

  .md\:mb-auto {
    margin-bottom: auto;
  }

  .md\:mb-px {
    margin-bottom: 1px;
  }

  .md\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .md\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .md\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .md\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .md\:-mb-0 {
    margin-bottom: 0;
  }

  .md\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .md\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .md\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .md\:-mb-4 {
    margin-bottom: -1rem;
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem;
  }

  .md\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .md\:-mb-12 {
    margin-bottom: -3rem;
  }

  .md\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .md\:-mb-16 {
    margin-bottom: -4rem;
  }

  .md\:-mb-20 {
    margin-bottom: -5rem;
  }

  .md\:-mb-24 {
    margin-bottom: -6rem;
  }

  .md\:-mb-28 {
    margin-bottom: -7rem;
  }

  .md\:-mb-32 {
    margin-bottom: -8rem;
  }

  .md\:-mb-36 {
    margin-bottom: -9rem;
  }

  .md\:-mb-40 {
    margin-bottom: -10rem;
  }

  .md\:-mb-44 {
    margin-bottom: -11rem;
  }

  .md\:-mb-48 {
    margin-bottom: -12rem;
  }

  .md\:-mb-52 {
    margin-bottom: -13rem;
  }

  .md\:-mb-56 {
    margin-bottom: -14rem;
  }

  .md\:-mb-60 {
    margin-bottom: -15rem;
  }

  .md\:-mb-64 {
    margin-bottom: -16rem;
  }

  .md\:-mb-72 {
    margin-bottom: -18rem;
  }

  .md\:-mb-80 {
    margin-bottom: -20rem;
  }

  .md\:-mb-96 {
    margin-bottom: -24rem;
  }

  .md\:-mb-px {
    margin-bottom: -1px;
  }

  .md\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .md\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .md\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .md\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ml-3 {
    margin-left: .75rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:ml-7 {
    margin-left: 1.75rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:ml-9 {
    margin-left: 2.25rem;
  }

  .md\:ml-10 {
    margin-left: 2.5rem;
  }

  .md\:ml-11 {
    margin-left: 2.75rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:ml-14 {
    margin-left: 3.5rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:ml-20 {
    margin-left: 5rem;
  }

  .md\:ml-24 {
    margin-left: 6rem;
  }

  .md\:ml-28 {
    margin-left: 7rem;
  }

  .md\:ml-32 {
    margin-left: 8rem;
  }

  .md\:ml-36 {
    margin-left: 9rem;
  }

  .md\:ml-40 {
    margin-left: 10rem;
  }

  .md\:ml-44 {
    margin-left: 11rem;
  }

  .md\:ml-48 {
    margin-left: 12rem;
  }

  .md\:ml-52 {
    margin-left: 13rem;
  }

  .md\:ml-56 {
    margin-left: 14rem;
  }

  .md\:ml-60 {
    margin-left: 15rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:ml-72 {
    margin-left: 18rem;
  }

  .md\:ml-80 {
    margin-left: 20rem;
  }

  .md\:ml-96 {
    margin-left: 24rem;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:ml-px {
    margin-left: 1px;
  }

  .md\:ml-0\.5 {
    margin-left: .125rem;
  }

  .md\:ml-1\.5 {
    margin-left: .375rem;
  }

  .md\:ml-2\.5 {
    margin-left: .625rem;
  }

  .md\:ml-3\.5 {
    margin-left: .875rem;
  }

  .md\:-ml-0 {
    margin-left: 0;
  }

  .md\:-ml-1 {
    margin-left: -.25rem;
  }

  .md\:-ml-2 {
    margin-left: -.5rem;
  }

  .md\:-ml-3 {
    margin-left: -.75rem;
  }

  .md\:-ml-4 {
    margin-left: -1rem;
  }

  .md\:-ml-5 {
    margin-left: -1.25rem;
  }

  .md\:-ml-6 {
    margin-left: -1.5rem;
  }

  .md\:-ml-7 {
    margin-left: -1.75rem;
  }

  .md\:-ml-8 {
    margin-left: -2rem;
  }

  .md\:-ml-9 {
    margin-left: -2.25rem;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem;
  }

  .md\:-ml-11 {
    margin-left: -2.75rem;
  }

  .md\:-ml-12 {
    margin-left: -3rem;
  }

  .md\:-ml-14 {
    margin-left: -3.5rem;
  }

  .md\:-ml-16 {
    margin-left: -4rem;
  }

  .md\:-ml-20 {
    margin-left: -5rem;
  }

  .md\:-ml-24 {
    margin-left: -6rem;
  }

  .md\:-ml-28 {
    margin-left: -7rem;
  }

  .md\:-ml-32 {
    margin-left: -8rem;
  }

  .md\:-ml-36 {
    margin-left: -9rem;
  }

  .md\:-ml-40 {
    margin-left: -10rem;
  }

  .md\:-ml-44 {
    margin-left: -11rem;
  }

  .md\:-ml-48 {
    margin-left: -12rem;
  }

  .md\:-ml-52 {
    margin-left: -13rem;
  }

  .md\:-ml-56 {
    margin-left: -14rem;
  }

  .md\:-ml-60 {
    margin-left: -15rem;
  }

  .md\:-ml-64 {
    margin-left: -16rem;
  }

  .md\:-ml-72 {
    margin-left: -18rem;
  }

  .md\:-ml-80 {
    margin-left: -20rem;
  }

  .md\:-ml-96 {
    margin-left: -24rem;
  }

  .md\:-ml-px {
    margin-left: -1px;
  }

  .md\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .md\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .md\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .md\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .md\:box-border {
    box-sizing: border-box;
  }

  .md\:box-content {
    box-sizing: content-box;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:table {
    display: table;
  }

  .md\:inline-table {
    display: inline-table;
  }

  .md\:table-caption {
    display: table-caption;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:table-column {
    display: table-column;
  }

  .md\:table-column-group {
    display: table-column-group;
  }

  .md\:table-footer-group {
    display: table-footer-group;
  }

  .md\:table-header-group {
    display: table-header-group;
  }

  .md\:table-row-group {
    display: table-row-group;
  }

  .md\:table-row {
    display: table-row;
  }

  .md\:flow-root {
    display: flow-root;
  }

  .md\:grid {
    display: grid;
  }

  .md\:inline-grid {
    display: inline-grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:list-item {
    display: list-item;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-0 {
    height: 0;
  }

  .md\:h-1 {
    height: .25rem;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-3 {
    height: .75rem;
  }

  .md\:h-4 {
    height: 1rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-9 {
    height: 2.25rem;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-11 {
    height: 2.75rem;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:h-24 {
    height: 6rem;
  }

  .md\:h-28 {
    height: 7rem;
  }

  .md\:h-32 {
    height: 8rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-44 {
    height: 11rem;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:h-80 {
    height: 20rem;
  }

  .md\:h-96 {
    height: 24rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-px {
    height: 1px;
  }

  .md\:h-0\.5 {
    height: .125rem;
  }

  .md\:h-1\.5 {
    height: .375rem;
  }

  .md\:h-2\.5 {
    height: .625rem;
  }

  .md\:h-3\.5 {
    height: .875rem;
  }

  .md\:h-1\/2 {
    height: 50%;
  }

  .md\:h-1\/3 {
    height: 33.3333%;
  }

  .md\:h-2\/3 {
    height: 66.6667%;
  }

  .md\:h-1\/4 {
    height: 25%;
  }

  .md\:h-2\/4 {
    height: 50%;
  }

  .md\:h-3\/4 {
    height: 75%;
  }

  .md\:h-1\/5 {
    height: 20%;
  }

  .md\:h-2\/5 {
    height: 40%;
  }

  .md\:h-3\/5 {
    height: 60%;
  }

  .md\:h-4\/5 {
    height: 80%;
  }

  .md\:h-1\/6 {
    height: 16.6667%;
  }

  .md\:h-2\/6 {
    height: 33.3333%;
  }

  .md\:h-3\/6 {
    height: 50%;
  }

  .md\:h-4\/6 {
    height: 66.6667%;
  }

  .md\:h-5\/6 {
    height: 83.3333%;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:max-h-0 {
    max-height: 0;
  }

  .md\:max-h-1 {
    max-height: .25rem;
  }

  .md\:max-h-2 {
    max-height: .5rem;
  }

  .md\:max-h-3 {
    max-height: .75rem;
  }

  .md\:max-h-4 {
    max-height: 1rem;
  }

  .md\:max-h-5 {
    max-height: 1.25rem;
  }

  .md\:max-h-6 {
    max-height: 1.5rem;
  }

  .md\:max-h-7 {
    max-height: 1.75rem;
  }

  .md\:max-h-8 {
    max-height: 2rem;
  }

  .md\:max-h-9 {
    max-height: 2.25rem;
  }

  .md\:max-h-10 {
    max-height: 2.5rem;
  }

  .md\:max-h-11 {
    max-height: 2.75rem;
  }

  .md\:max-h-12 {
    max-height: 3rem;
  }

  .md\:max-h-14 {
    max-height: 3.5rem;
  }

  .md\:max-h-16 {
    max-height: 4rem;
  }

  .md\:max-h-20 {
    max-height: 5rem;
  }

  .md\:max-h-24 {
    max-height: 6rem;
  }

  .md\:max-h-28 {
    max-height: 7rem;
  }

  .md\:max-h-32 {
    max-height: 8rem;
  }

  .md\:max-h-36 {
    max-height: 9rem;
  }

  .md\:max-h-40 {
    max-height: 10rem;
  }

  .md\:max-h-44 {
    max-height: 11rem;
  }

  .md\:max-h-48 {
    max-height: 12rem;
  }

  .md\:max-h-52 {
    max-height: 13rem;
  }

  .md\:max-h-56 {
    max-height: 14rem;
  }

  .md\:max-h-60 {
    max-height: 15rem;
  }

  .md\:max-h-64 {
    max-height: 16rem;
  }

  .md\:max-h-72 {
    max-height: 18rem;
  }

  .md\:max-h-80 {
    max-height: 20rem;
  }

  .md\:max-h-96 {
    max-height: 24rem;
  }

  .md\:max-h-px {
    max-height: 1px;
  }

  .md\:max-h-0\.5 {
    max-height: .125rem;
  }

  .md\:max-h-1\.5 {
    max-height: .375rem;
  }

  .md\:max-h-2\.5 {
    max-height: .625rem;
  }

  .md\:max-h-3\.5 {
    max-height: .875rem;
  }

  .md\:max-h-full {
    max-height: 100%;
  }

  .md\:max-h-screen {
    max-height: 100vh;
  }

  .md\:min-h-0 {
    min-height: 0;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:w-0 {
    width: 0;
  }

  .md\:w-1 {
    width: .25rem;
  }

  .md\:w-2 {
    width: .5rem;
  }

  .md\:w-3 {
    width: .75rem;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-9 {
    width: 2.25rem;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-11 {
    width: 2.75rem;
  }

  .md\:w-12 {
    width: 3rem;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-52 {
    width: 13rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-px {
    width: 1px;
  }

  .md\:w-0\.5 {
    width: .125rem;
  }

  .md\:w-1\.5 {
    width: .375rem;
  }

  .md\:w-2\.5 {
    width: .625rem;
  }

  .md\:w-3\.5 {
    width: .875rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-1\/6 {
    width: 16.6667%;
  }

  .md\:w-2\/6 {
    width: 33.3333%;
  }

  .md\:w-3\/6 {
    width: 50%;
  }

  .md\:w-4\/6 {
    width: 66.6667%;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-1\/12 {
    width: 8.33333%;
  }

  .md\:w-2\/12 {
    width: 16.6667%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-10\/12 {
    width: 83.3333%;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-screen {
    width: 100vw;
  }

  .md\:w-min {
    width: min-content;
  }

  .md\:w-max {
    width: max-content;
  }

  .md\:min-w-0 {
    min-width: 0;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:min-w-min {
    min-width: min-content;
  }

  .md\:min-w-max {
    min-width: max-content;
  }

  .md\:max-w-0 {
    max-width: 0;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:max-w-lg {
    max-width: 32rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-6xl {
    max-width: 72rem;
  }

  .md\:max-w-7xl {
    max-width: 80rem;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-min {
    max-width: min-content;
  }

  .md\:max-w-max {
    max-width: max-content;
  }

  .md\:max-w-prose {
    max-width: 65ch;
  }

  .md\:max-w-screen-sm {
    max-width: 640px;
  }

  .md\:max-w-screen-md {
    max-width: 768px;
  }

  .md\:max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:max-w-screen-xl {
    max-width: 1280px;
  }

  .md\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-auto {
    flex: auto;
  }

  .md\:flex-initial {
    flex: 0 auto;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-shrink {
    flex-shrink: 1;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:table-auto {
    table-layout: auto;
  }

  .md\:table-fixed {
    table-layout: fixed;
  }

  .md\:border-collapse {
    border-collapse: collapse;
  }

  .md\:border-separate {
    border-collapse: separate;
  }

  .md\:origin-center {
    transform-origin: center;
  }

  .md\:origin-top {
    transform-origin: top;
  }

  .md\:origin-top-right {
    transform-origin: 100% 0;
  }

  .md\:origin-right {
    transform-origin: 100%;
  }

  .md\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .md\:origin-bottom {
    transform-origin: bottom;
  }

  .md\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .md\:origin-left {
    transform-origin: 0;
  }

  .md\:origin-top-left {
    transform-origin: 0 0;
  }

  .md\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-none {
    transform: none;
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .md\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .md\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .md\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .md\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .md\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .md\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .md\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .md\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .md\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .md\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .md\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .md\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .md\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .md\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .md\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .md\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .md\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .md\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .md\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .md\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .md\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .md\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .md\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .md\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .md\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .md\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .md\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .md\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .md\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .md\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .md\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .md\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .md\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .md\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .md\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .md\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .md\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .md\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .md\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .md\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .md\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .md\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .md\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .md\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .md\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .md\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .md\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .md\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .md\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .md\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .md\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .md\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .md\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .md\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .md\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .md\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .md\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .md\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .md\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .md\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .md\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .md\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .md\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .md\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .md\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .md\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .md\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .md\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .md\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .md\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .md\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .md\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .md\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .md\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .md\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .md\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .md\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .md\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .md\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .md\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .md\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .md\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .md\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .md\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .md\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .md\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .md\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .md\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .md\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .md\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .md\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .md\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .md\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .md\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .md\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .md\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .md\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .md\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .md\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .md\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .md\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .md\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .md\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .md\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .md\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .md\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .md\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .md\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .md\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .md\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .md\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .md\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .md\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .md\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .md\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .md\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .md\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .md\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .md\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .md\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .md\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .md\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .md\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .md\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .md\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .md\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .md\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .md\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .md\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .md\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .md\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .md\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .md\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .md\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .md\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .md\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .md\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .md\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .md\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .md\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .md\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .md\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .md\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .md\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .md\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .md\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .md\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .md\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .md\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .md\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .md\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .md\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .md\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .md\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .md\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .md\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .md\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .md\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .md\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .md\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .md\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .md\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .md\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .md\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .md\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .md\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .md\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .md\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .md\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .md\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .md\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .md\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .md\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .md\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .md\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .md\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .md\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .md\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .md\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .md\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .md\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .md\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .md\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .md\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .md\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .md\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .md\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .md\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .md\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .md\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .md\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .md\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .md\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .md\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .md\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .md\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .md\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .md\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .md\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .md\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .md\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .md\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .md\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .md\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .md\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .md\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .md\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .md\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .md\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .md\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .md\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .md\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .md\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .md\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .md\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .md\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .md\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .md\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .md\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .md\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .md\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .md\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .md\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .md\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .md\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .md\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .md\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .md\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .md\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .md\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .md\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .md\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .md\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .md\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .md\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .md\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .md\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .md\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .md\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .md\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .md\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .md\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .md\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .md\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .md\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .md\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .md\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .md\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .md\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .md\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .md\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .md\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .md\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .md\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .md\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .md\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .md\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .md\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .md\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .md\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .md\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .md\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .md\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .md\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .md\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .md\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .md\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .md\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .md\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .md\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .md\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .md\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .md\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .md\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .md\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .md\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .md\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .md\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .md\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .md\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .md\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .md\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .md\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .md\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .md\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .md\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .md\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .md\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .md\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .md\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .md\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .md\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .md\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .md\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .md\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .md\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .md\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .md\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .md\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .md\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .md\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .md\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .md\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .md\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .md\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .md\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .md\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .md\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .md\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .md\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .md\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .md\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .md\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .md\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .md\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .md\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .md\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .md\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .md\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .md\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .md\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .md\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .md\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .md\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .md\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .md\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .md\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .md\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .md\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .md\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .md\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .md\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .md\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .md\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .md\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .md\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .md\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .md\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .md\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .md\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .md\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .md\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .md\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .md\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .md\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .md\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .md\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .md\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .md\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .md\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .md\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .md\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .md\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .md\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .md\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .md\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .md\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .md\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .md\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .md\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .md\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .md\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .md\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .md\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .md\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .md\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .md\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .md\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .md\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .md\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .md\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .md\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .md\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .md\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .md\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .md\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .md\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .md\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .md\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .md\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .md\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .md\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .md\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .md\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .md\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .md\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .md\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .md\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .md\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .md\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .md\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .md\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .md\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .md\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .md\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .md\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .md\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .md\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .md\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .md\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .md\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .md\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .md\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .md\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .md\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .md\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .md\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .md\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .md\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .md\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .md\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .md\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .md\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .md\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .md\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .md\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .md\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .md\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .md\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .md\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .md\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .md\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .md\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .md\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .md\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .md\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .md\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .md\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .md\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .md\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .md\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .md\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .md\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .md\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .md\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .md\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .md\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .md\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .md\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .md\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .md\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .md\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .md\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .md\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .md\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .md\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .md\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .md\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .md\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .md\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .md\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .md\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .md\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .md\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .md\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .md\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .md\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .md\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .md\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .md\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .md\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .md\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .md\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .md\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .md\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .md\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .md\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .md\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .md\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .md\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .md\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .md\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .md\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .md\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .md\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .md\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .md\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .md\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .md\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .md\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .md\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .md\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .md\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .md\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .md\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .md\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .md\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .md\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .md\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .md\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .md\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .md\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .md\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .md\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .md\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .md\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .md\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .md\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .md\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .md\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .md\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .md\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .md\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .md\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .md\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .md\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .md\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .md\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .md\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .md\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .md\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .md\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .md\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .md\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .md\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .md\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .md\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .md\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .md\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .md\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .md\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .md\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .md\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .md\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .md\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .md\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .md\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .md\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .md\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .md\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .md\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .md\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .md\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .md\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .md\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .md\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .md\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .md\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .md\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .md\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .md\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .md\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .md\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .md\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .md\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .md\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .md\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .md\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .md\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .md\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .md\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .md\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .md\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .md\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .md\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .md\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .md\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .md\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .md\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .md\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .md\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .md\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .md\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .md\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .md\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .md\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .md\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .md\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .md\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .md\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .md\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .md\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .md\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .md\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .md\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .md\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .md\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .md\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .md\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .md\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .md\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .md\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .md\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .md\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .md\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .md\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .md\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .md\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .md\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .md\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .md\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .md\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .md\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .md\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .md\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .md\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .md\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .md\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .md\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .md\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .md\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .md\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .md\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .md\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .md\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .md\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .md\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .md\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .md\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .md\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .md\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .md\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .md\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .md\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .md\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .md\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .md\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .md\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .md\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .md\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .md\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .md\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .md\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .md\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .md\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .md\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .md\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .md\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .md\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .md\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .md\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .md\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .md\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .md\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .md\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .md\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .md\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .md\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .md\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .md\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .md\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .md\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .md\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .md\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .md\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .md\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .md\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .md\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .md\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .md\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .md\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .md\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .md\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .md\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .md\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .md\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .md\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .md\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .md\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .md\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .md\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .md\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .md\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .md\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .md\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .md\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .md\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .md\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .md\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .md\:animate-none {
    animation: none;
  }

  .md\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .md\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .md\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .md\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .md\:cursor-auto {
    cursor: auto;
  }

  .md\:cursor-default {
    cursor: default;
  }

  .md\:cursor-pointer {
    cursor: pointer;
  }

  .md\:cursor-wait {
    cursor: wait;
  }

  .md\:cursor-text {
    cursor: text;
  }

  .md\:cursor-move {
    cursor: move;
  }

  .md\:cursor-help {
    cursor: help;
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .md\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .md\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .md\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .md\:resize-none {
    resize: none;
  }

  .md\:resize-y {
    resize: vertical;
  }

  .md\:resize-x {
    resize: horizontal;
  }

  .md\:resize {
    resize: both;
  }

  .md\:list-inside {
    list-style-position: inside;
  }

  .md\:list-outside {
    list-style-position: outside;
  }

  .md\:list-none {
    list-style-type: none;
  }

  .md\:list-disc {
    list-style-type: disc;
  }

  .md\:list-decimal {
    list-style-type: decimal;
  }

  .md\:appearance-none {
    appearance: none;
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .md\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .md\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-rows-none {
    grid-template-rows: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:place-content-center {
    place-content: center;
  }

  .md\:place-content-start {
    place-content: start;
  }

  .md\:place-content-end {
    place-content: end;
  }

  .md\:place-content-between {
    place-content: space-between;
  }

  .md\:place-content-around {
    place-content: space-around;
  }

  .md\:place-content-evenly {
    place-content: space-evenly;
  }

  .md\:place-content-stretch {
    place-content: stretch;
  }

  .md\:place-items-start {
    place-items: start;
  }

  .md\:place-items-end {
    place-items: end;
  }

  .md\:place-items-center {
    place-items: center;
  }

  .md\:place-items-stretch {
    place-items: stretch stretch;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:content-start {
    align-content: flex-start;
  }

  .md\:content-end {
    align-content: flex-end;
  }

  .md\:content-between {
    align-content: space-between;
  }

  .md\:content-around {
    align-content: space-around;
  }

  .md\:content-evenly {
    align-content: space-evenly;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:justify-items-start {
    justify-items: start;
  }

  .md\:justify-items-end {
    justify-items: end;
  }

  .md\:justify-items-center {
    justify-items: center;
  }

  .md\:justify-items-stretch {
    justify-items: stretch;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-1 {
    gap: .25rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-3 {
    gap: .75rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-5 {
    gap: 1.25rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-7 {
    gap: 1.75rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-9 {
    gap: 2.25rem;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-11 {
    gap: 2.75rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-14 {
    gap: 3.5rem;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-28 {
    gap: 7rem;
  }

  .md\:gap-32 {
    gap: 8rem;
  }

  .md\:gap-36 {
    gap: 9rem;
  }

  .md\:gap-40 {
    gap: 10rem;
  }

  .md\:gap-44 {
    gap: 11rem;
  }

  .md\:gap-48 {
    gap: 12rem;
  }

  .md\:gap-52 {
    gap: 13rem;
  }

  .md\:gap-56 {
    gap: 14rem;
  }

  .md\:gap-60 {
    gap: 15rem;
  }

  .md\:gap-64 {
    gap: 16rem;
  }

  .md\:gap-72 {
    gap: 18rem;
  }

  .md\:gap-80 {
    gap: 20rem;
  }

  .md\:gap-96 {
    gap: 24rem;
  }

  .md\:gap-px {
    gap: 1px;
  }

  .md\:gap-0\.5 {
    gap: .125rem;
  }

  .md\:gap-1\.5 {
    gap: .375rem;
  }

  .md\:gap-2\.5 {
    gap: .625rem;
  }

  .md\:gap-3\.5 {
    gap: .875rem;
  }

  .md\:gap-x-0 {
    column-gap: 0;
  }

  .md\:gap-x-1 {
    column-gap: .25rem;
  }

  .md\:gap-x-2 {
    column-gap: .5rem;
  }

  .md\:gap-x-3 {
    column-gap: .75rem;
  }

  .md\:gap-x-4 {
    column-gap: 1rem;
  }

  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .md\:gap-x-8 {
    column-gap: 2rem;
  }

  .md\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .md\:gap-x-16 {
    column-gap: 4rem;
  }

  .md\:gap-x-20 {
    column-gap: 5rem;
  }

  .md\:gap-x-24 {
    column-gap: 6rem;
  }

  .md\:gap-x-28 {
    column-gap: 7rem;
  }

  .md\:gap-x-32 {
    column-gap: 8rem;
  }

  .md\:gap-x-36 {
    column-gap: 9rem;
  }

  .md\:gap-x-40 {
    column-gap: 10rem;
  }

  .md\:gap-x-44 {
    column-gap: 11rem;
  }

  .md\:gap-x-48 {
    column-gap: 12rem;
  }

  .md\:gap-x-52 {
    column-gap: 13rem;
  }

  .md\:gap-x-56 {
    column-gap: 14rem;
  }

  .md\:gap-x-60 {
    column-gap: 15rem;
  }

  .md\:gap-x-64 {
    column-gap: 16rem;
  }

  .md\:gap-x-72 {
    column-gap: 18rem;
  }

  .md\:gap-x-80 {
    column-gap: 20rem;
  }

  .md\:gap-x-96 {
    column-gap: 24rem;
  }

  .md\:gap-x-px {
    column-gap: 1px;
  }

  .md\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .md\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .md\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .md\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .md\:gap-y-0 {
    row-gap: 0;
  }

  .md\:gap-y-1 {
    row-gap: .25rem;
  }

  .md\:gap-y-2 {
    row-gap: .5rem;
  }

  .md\:gap-y-3 {
    row-gap: .75rem;
  }

  .md\:gap-y-4 {
    row-gap: 1rem;
  }

  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .md\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .md\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .md\:gap-y-8 {
    row-gap: 2rem;
  }

  .md\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .md\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .md\:gap-y-12 {
    row-gap: 3rem;
  }

  .md\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .md\:gap-y-16 {
    row-gap: 4rem;
  }

  .md\:gap-y-20 {
    row-gap: 5rem;
  }

  .md\:gap-y-24 {
    row-gap: 6rem;
  }

  .md\:gap-y-28 {
    row-gap: 7rem;
  }

  .md\:gap-y-32 {
    row-gap: 8rem;
  }

  .md\:gap-y-36 {
    row-gap: 9rem;
  }

  .md\:gap-y-40 {
    row-gap: 10rem;
  }

  .md\:gap-y-44 {
    row-gap: 11rem;
  }

  .md\:gap-y-48 {
    row-gap: 12rem;
  }

  .md\:gap-y-52 {
    row-gap: 13rem;
  }

  .md\:gap-y-56 {
    row-gap: 14rem;
  }

  .md\:gap-y-60 {
    row-gap: 15rem;
  }

  .md\:gap-y-64 {
    row-gap: 16rem;
  }

  .md\:gap-y-72 {
    row-gap: 18rem;
  }

  .md\:gap-y-80 {
    row-gap: 20rem;
  }

  .md\:gap-y-96 {
    row-gap: 24rem;
  }

  .md\:gap-y-px {
    row-gap: 1px;
  }

  .md\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .md\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .md\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .md\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .md\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .md\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .md\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .md\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .md\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .md\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .md\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .md\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .md\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .md\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .md\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .md\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .md\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .md\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .md\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .md\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .md\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .md\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .md\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .md\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .md\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .md\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .md\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .md\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .md\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .md\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .md\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .md\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .md\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .md\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .md\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .md\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .md\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .md\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .md\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .md\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .md\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .md\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .md\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .md\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .md\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .md\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .md\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .md\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .md\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .md\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .md\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .md\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .md\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .md\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .md\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .md\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .md\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .md\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .md\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .md\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .md\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .md\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .md\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .md\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .md\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .md\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .md\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .md\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .md\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .md\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .md\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .md\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .md\:place-self-auto {
    place-self: auto;
  }

  .md\:place-self-start {
    place-self: start;
  }

  .md\:place-self-end {
    place-self: end;
  }

  .md\:place-self-center {
    place-self: center;
  }

  .md\:place-self-stretch {
    place-self: stretch stretch;
  }

  .md\:self-auto {
    align-self: auto;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-stretch {
    align-self: stretch;
  }

  .md\:self-baseline {
    align-self: baseline;
  }

  .md\:justify-self-auto {
    justify-self: auto;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:justify-self-center {
    justify-self: center;
  }

  .md\:justify-self-stretch {
    justify-self: stretch;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:overflow-scroll {
    overflow: scroll;
  }

  .md\:overflow-x-auto {
    overflow-x: auto;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:overflow-x-visible {
    overflow-x: visible;
  }

  .md\:overflow-y-visible {
    overflow-y: visible;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .md\:overscroll-none {
    overscroll-behavior: none;
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .md\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .md\:overflow-clip {
    text-overflow: clip;
  }

  .md\:whitespace-normal {
    white-space: normal;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:whitespace-pre {
    white-space: pre;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:break-words {
    overflow-wrap: break-word;
  }

  .md\:break-all {
    word-break: break-all;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-sm {
    border-radius: .125rem;
  }

  .md\:rounded {
    border-radius: .25rem;
  }

  .md\:rounded-md {
    border-radius: .375rem;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:rounded-full {
    border-radius: 9999px;
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .md\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .md\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .md\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .md\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .md\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .md\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .md\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:border-4 {
    border-width: 4px;
  }

  .md\:border-8 {
    border-width: 8px;
  }

  .md\:border {
    border-width: 1px;
  }

  .md\:border-t-0 {
    border-top-width: 0;
  }

  .md\:border-t-2 {
    border-top-width: 2px;
  }

  .md\:border-t-4 {
    border-top-width: 4px;
  }

  .md\:border-t-8 {
    border-top-width: 8px;
  }

  .md\:border-t {
    border-top-width: 1px;
  }

  .md\:border-r-0 {
    border-right-width: 0;
  }

  .md\:border-r-2 {
    border-right-width: 2px;
  }

  .md\:border-r-4 {
    border-right-width: 4px;
  }

  .md\:border-r-8 {
    border-right-width: 8px;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-l-0 {
    border-left-width: 0;
  }

  .md\:border-l-2 {
    border-left-width: 2px;
  }

  .md\:border-l-4 {
    border-left-width: 4px;
  }

  .md\:border-l-8 {
    border-left-width: 8px;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:border-solid {
    border-style: solid;
  }

  .md\:border-dashed {
    border-style: dashed;
  }

  .md\:border-dotted {
    border-style: dotted;
  }

  .md\:border-double {
    border-style: double;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:border-transparent {
    border-color: #0000;
  }

  .md\:border-current {
    border-color: currentColor;
  }

  .md\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .md\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .md\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .md\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .md\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .md\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .md\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .md\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .md\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .md\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .md\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .md\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .md\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .md\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .md\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .md\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .md\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .md\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .md\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .md\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .md\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .md\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .md\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .md\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .md\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .md\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .md\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .md\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .md\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .md\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .md\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .md\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .md\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .md\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .md\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .md\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .md\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .md\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .md\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .md\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .md\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .md\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .md\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .md\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .md\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .md\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .md\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .md\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .md\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .md\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .md\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .md\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .md\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .md\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .md\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .md\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .md\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .md\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .md\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .md\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .md\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .md\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .md\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .md\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .md\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .md\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .md\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .md\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .md\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .md\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .md\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .md\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .md\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .md\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .md\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .md\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .md\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .md\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .md\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .md\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .md\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .md\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .md\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .md\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:bg-current {
    background-color: currentColor;
  }

  .md\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .md\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .md\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .md\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .md\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .md\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .md\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .md\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .md\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .md\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .md\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .md\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .md\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .md\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .md\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .md\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .md\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .md\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .md\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .md\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .md\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .md\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .md\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .md\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .md\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .md\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .md\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .md\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .md\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .md\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .md\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .md\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .md\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .md\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .md\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .md\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .md\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .md\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .md\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .md\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .md\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .md\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .md\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .md\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .md\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .md\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .md\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .md\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .md\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .md\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .md\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .md\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .md\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .md\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .md\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .md\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .md\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .md\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .md\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .md\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .md\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .md\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .md\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .md\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .md\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .md\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .md\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .md\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .md\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .md\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .md\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .md\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .md\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .md\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .md\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .md\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .md\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .md\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .md\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .md\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .md\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .md\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .md\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .md\:bg-none {
    background-image: none;
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .md\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .md\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .md\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .md\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .md\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .md\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .md\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .md\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .md\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .md\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .md\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .md\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .md\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .md\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .md\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .md\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .md\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .md\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .md\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .md\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .md\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .md\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .md\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .md\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .md\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .md\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .md\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .md\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .md\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .md\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .md\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .md\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .md\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .md\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .md\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .md\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .md\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .md\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .md\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .md\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .md\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .md\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .md\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .md\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .md\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .md\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .md\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .md\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .md\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .md\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .md\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .md\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .md\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .md\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .md\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .md\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .md\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .md\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .md\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .md\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .md\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .md\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .md\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .md\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .md\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .md\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .md\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .md\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .md\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .md\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .md\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .md\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .md\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .md\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .md\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .md\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .md\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .md\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .md\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .md\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .md\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .md\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .md\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .md\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .md\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .md\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .md\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .md\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .md\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .md\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .md\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .md\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .md\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .md\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .md\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .md\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .md\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .md\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .md\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .md\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .md\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .md\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .md\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .md\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .md\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .md\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .md\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .md\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .md\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .md\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .md\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .md\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .md\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .md\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .md\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .md\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .md\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .md\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .md\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .md\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .md\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .md\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .md\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .md\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .md\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .md\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .md\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .md\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .md\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .md\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .md\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .md\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .md\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .md\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .md\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .md\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .md\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .md\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .md\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .md\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .md\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .md\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .md\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .md\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .md\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .md\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .md\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .md\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .md\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .md\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .md\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .md\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .md\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .md\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .md\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .md\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .md\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .md\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .md\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .md\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .md\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .md\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .md\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .md\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .md\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .md\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .md\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .md\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .md\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .md\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .md\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .md\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .md\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .md\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .md\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .md\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .md\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .md\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .md\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .md\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .md\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .md\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .md\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .md\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .md\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .md\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .md\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .md\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .md\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .md\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .md\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .md\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .md\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .md\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .md\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .md\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .md\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .md\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .md\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .md\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .md\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .md\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .md\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .md\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .md\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .md\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .md\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .md\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .md\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .md\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .md\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .md\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .md\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .md\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .md\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .md\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .md\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .md\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .md\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .md\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .md\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .md\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .md\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .md\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .md\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .md\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .md\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .md\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .md\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .md\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .md\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .md\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .md\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .md\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .md\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .md\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .md\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .md\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .md\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .md\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .md\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .md\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .md\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .md\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .md\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .md\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .md\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .md\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .md\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .md\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .md\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .md\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .md\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .md\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .md\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .md\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .md\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .md\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .md\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .md\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .md\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .md\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .md\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .md\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .md\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .md\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .md\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .md\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .md\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .md\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .md\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .md\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .md\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .md\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .md\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .md\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .md\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .md\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .md\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .md\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .md\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .md\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .md\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .md\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .md\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .md\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .md\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .md\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .md\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .md\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .md\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .md\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .md\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .md\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .md\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .md\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .md\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .md\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .md\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .md\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .md\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .md\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .md\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .md\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .md\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .md\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .md\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .md\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .md\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .md\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .md\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .md\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .md\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .md\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .md\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .md\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .md\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .md\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .md\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .md\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .md\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .md\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .md\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .md\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .md\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .md\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .md\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .md\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .md\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .md\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .md\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .md\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .md\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .md\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .md\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .md\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .md\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .md\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .md\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .md\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .md\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .md\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .md\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .md\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .md\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .md\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .md\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .md\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .md\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .md\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .md\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .md\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .md\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .md\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .md\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .md\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .md\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .md\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .md\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .md\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .md\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .md\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .md\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .md\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .md\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .md\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .md\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .md\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .md\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .md\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .md\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .md\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .md\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .md\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .md\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .md\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .md\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .md\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .md\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .md\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .md\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .md\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .md\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .md\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .md\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .md\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .md\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .md\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .md\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .md\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .md\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .md\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .md\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .md\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .md\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .md\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .md\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .md\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .md\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .md\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .md\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .md\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .md\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .md\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .md\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .md\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .md\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .md\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .md\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .md\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .md\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .md\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .md\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .md\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .md\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .md\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .md\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .md\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .md\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .md\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .md\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .md\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .md\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .md\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .md\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .md\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .md\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .md\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .md\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .md\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .md\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .md\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .md\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .md\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .md\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .md\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .md\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .md\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .md\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .md\:to-current {
    --tw-gradient-to: currentColor;
  }

  .md\:to-black {
    --tw-gradient-to: #000;
  }

  .md\:to-white {
    --tw-gradient-to: #fff;
  }

  .md\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .md\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .md\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .md\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .md\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .md\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .md\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .md\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .md\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .md\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .md\:to-red {
    --tw-gradient-to: red;
  }

  .md\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .md\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .md\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .md\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .md\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .md\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .md\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .md\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .md\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .md\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .md\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .md\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .md\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .md\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .md\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .md\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .md\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .md\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .md\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .md\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .md\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .md\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .md\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .md\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .md\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .md\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .md\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .md\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .md\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .md\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .md\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .md\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .md\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .md\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .md\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .md\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .md\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .md\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .md\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .md\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .md\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .md\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .md\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .md\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .md\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .md\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .md\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .md\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .md\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .md\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .md\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .md\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .md\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .md\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .md\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .md\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .md\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .md\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .md\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .md\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .md\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .md\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .md\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .md\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .md\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .md\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .md\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .md\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .md\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .md\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .md\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .md\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .md\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .md\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .md\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .md\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .md\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .md\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .md\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .md\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .md\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .md\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .md\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .md\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .md\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .md\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .md\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .md\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .md\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .md\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .md\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .md\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .md\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .md\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .md\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .md\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .md\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .md\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .md\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .md\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .md\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .md\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .md\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .md\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .md\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .md\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .md\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .md\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .md\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .md\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .md\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .md\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .md\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .md\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .md\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .md\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .md\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .md\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .md\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .md\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .md\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .md\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .md\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .md\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .md\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .md\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .md\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .md\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .md\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .md\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .md\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .md\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .md\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .md\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .md\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .md\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .md\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .md\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .md\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .md\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .md\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .md\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .md\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .md\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .md\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .md\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .md\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .md\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .md\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .md\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .md\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .md\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .md\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .md\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .md\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .md\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .md\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .md\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .md\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .md\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .md\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .md\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .md\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .md\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .md\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .md\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .md\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .md\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .md\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .md\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .md\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .md\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .md\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .md\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .md\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .md\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .md\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .md\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .md\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .md\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .md\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .md\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .md\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .md\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .md\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .md\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .md\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .md\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .md\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .md\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .md\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .md\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .md\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .md\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .md\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .md\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .md\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .md\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .md\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .md\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .md\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .md\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .md\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .md\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .md\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .md\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .md\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .md\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .md\:bg-auto {
    background-size: auto;
  }

  .md\:bg-cover {
    background-size: cover;
  }

  .md\:bg-contain {
    background-size: contain;
  }

  .md\:bg-fixed {
    background-attachment: fixed;
  }

  .md\:bg-local {
    background-attachment: local;
  }

  .md\:bg-scroll {
    background-attachment: scroll;
  }

  .md\:bg-clip-border {
    background-clip: border-box;
  }

  .md\:bg-clip-padding {
    background-clip: padding-box;
  }

  .md\:bg-clip-content {
    background-clip: content-box;
  }

  .md\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .md\:bg-bottom {
    background-position: bottom;
  }

  .md\:bg-center {
    background-position: center;
  }

  .md\:bg-left {
    background-position: 0;
  }

  .md\:bg-left-bottom {
    background-position: 0 100%;
  }

  .md\:bg-left-top {
    background-position: 0 0;
  }

  .md\:bg-right {
    background-position: 100%;
  }

  .md\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .md\:bg-right-top {
    background-position: 100% 0;
  }

  .md\:bg-top {
    background-position: top;
  }

  .md\:bg-repeat {
    background-repeat: repeat;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:bg-repeat-round {
    background-repeat: round;
  }

  .md\:bg-repeat-space {
    background-repeat: space;
  }

  .md\:bg-origin-border {
    background-origin: border-box;
  }

  .md\:bg-origin-padding {
    background-origin: padding-box;
  }

  .md\:bg-origin-content {
    background-origin: content-box;
  }

  .md\:fill-current {
    fill: currentColor;
  }

  .md\:stroke-current {
    stroke: currentColor;
  }

  .md\:stroke-0 {
    stroke-width: 0;
  }

  .md\:stroke-1 {
    stroke-width: 1px;
  }

  .md\:stroke-2 {
    stroke-width: 2px;
  }

  .md\:object-contain {
    object-fit: contain;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:object-fill {
    object-fit: fill;
  }

  .md\:object-none {
    object-fit: none;
  }

  .md\:object-scale-down {
    object-fit: scale-down;
  }

  .md\:object-bottom {
    object-position: bottom;
  }

  .md\:object-center {
    object-position: center;
  }

  .md\:object-left {
    object-position: left;
  }

  .md\:object-left-bottom {
    object-position: left bottom;
  }

  .md\:object-left-top {
    object-position: left top;
  }

  .md\:object-right {
    object-position: right;
  }

  .md\:object-right-bottom {
    object-position: right bottom;
  }

  .md\:object-right-top {
    object-position: right top;
  }

  .md\:object-top {
    object-position: top;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-1 {
    padding: .25rem;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-7 {
    padding: 1.75rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:p-9 {
    padding: 2.25rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-11 {
    padding: 2.75rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-14 {
    padding: 3.5rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:p-24 {
    padding: 6rem;
  }

  .md\:p-28 {
    padding: 7rem;
  }

  .md\:p-32 {
    padding: 8rem;
  }

  .md\:p-36 {
    padding: 9rem;
  }

  .md\:p-40 {
    padding: 10rem;
  }

  .md\:p-44 {
    padding: 11rem;
  }

  .md\:p-48 {
    padding: 12rem;
  }

  .md\:p-52 {
    padding: 13rem;
  }

  .md\:p-56 {
    padding: 14rem;
  }

  .md\:p-60 {
    padding: 15rem;
  }

  .md\:p-64 {
    padding: 16rem;
  }

  .md\:p-72 {
    padding: 18rem;
  }

  .md\:p-80 {
    padding: 20rem;
  }

  .md\:p-96 {
    padding: 24rem;
  }

  .md\:p-px {
    padding: 1px;
  }

  .md\:p-0\.5 {
    padding: .125rem;
  }

  .md\:p-1\.5 {
    padding: .375rem;
  }

  .md\:p-2\.5 {
    padding: .625rem;
  }

  .md\:p-3\.5 {
    padding: .875rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .md\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .md\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .md\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .md\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .md\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .md\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .md\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .md\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .md\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .md\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-1 {
    padding-top: .25rem;
  }

  .md\:pt-2 {
    padding-top: .5rem;
  }

  .md\:pt-3 {
    padding-top: .75rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pt-7 {
    padding-top: 1.75rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:pt-9 {
    padding-top: 2.25rem;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pt-11 {
    padding-top: 2.75rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-14 {
    padding-top: 3.5rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pt-24 {
    padding-top: 6rem;
  }

  .md\:pt-28 {
    padding-top: 7rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-36 {
    padding-top: 9rem;
  }

  .md\:pt-40 {
    padding-top: 10rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:pt-52 {
    padding-top: 13rem;
  }

  .md\:pt-56 {
    padding-top: 14rem;
  }

  .md\:pt-60 {
    padding-top: 15rem;
  }

  .md\:pt-64 {
    padding-top: 16rem;
  }

  .md\:pt-72 {
    padding-top: 18rem;
  }

  .md\:pt-80 {
    padding-top: 20rem;
  }

  .md\:pt-96 {
    padding-top: 24rem;
  }

  .md\:pt-px {
    padding-top: 1px;
  }

  .md\:pt-0\.5 {
    padding-top: .125rem;
  }

  .md\:pt-1\.5 {
    padding-top: .375rem;
  }

  .md\:pt-2\.5 {
    padding-top: .625rem;
  }

  .md\:pt-3\.5 {
    padding-top: .875rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-1 {
    padding-right: .25rem;
  }

  .md\:pr-2 {
    padding-right: .5rem;
  }

  .md\:pr-3 {
    padding-right: .75rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pr-5 {
    padding-right: 1.25rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pr-7 {
    padding-right: 1.75rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pr-9 {
    padding-right: 2.25rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }

  .md\:pr-11 {
    padding-right: 2.75rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pr-14 {
    padding-right: 3.5rem;
  }

  .md\:pr-16 {
    padding-right: 4rem;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pr-24 {
    padding-right: 6rem;
  }

  .md\:pr-28 {
    padding-right: 7rem;
  }

  .md\:pr-32 {
    padding-right: 8rem;
  }

  .md\:pr-36 {
    padding-right: 9rem;
  }

  .md\:pr-40 {
    padding-right: 10rem;
  }

  .md\:pr-44 {
    padding-right: 11rem;
  }

  .md\:pr-48 {
    padding-right: 12rem;
  }

  .md\:pr-52 {
    padding-right: 13rem;
  }

  .md\:pr-56 {
    padding-right: 14rem;
  }

  .md\:pr-60 {
    padding-right: 15rem;
  }

  .md\:pr-64 {
    padding-right: 16rem;
  }

  .md\:pr-72 {
    padding-right: 18rem;
  }

  .md\:pr-80 {
    padding-right: 20rem;
  }

  .md\:pr-96 {
    padding-right: 24rem;
  }

  .md\:pr-px {
    padding-right: 1px;
  }

  .md\:pr-0\.5 {
    padding-right: .125rem;
  }

  .md\:pr-1\.5 {
    padding-right: .375rem;
  }

  .md\:pr-2\.5 {
    padding-right: .625rem;
  }

  .md\:pr-3\.5 {
    padding-right: .875rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-1 {
    padding-bottom: .25rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pb-3 {
    padding-bottom: .75rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .md\:pb-12 {
    padding-bottom: 3rem;
  }

  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pb-28 {
    padding-bottom: 7rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pb-36 {
    padding-bottom: 9rem;
  }

  .md\:pb-40 {
    padding-bottom: 10rem;
  }

  .md\:pb-44 {
    padding-bottom: 11rem;
  }

  .md\:pb-48 {
    padding-bottom: 12rem;
  }

  .md\:pb-52 {
    padding-bottom: 13rem;
  }

  .md\:pb-56 {
    padding-bottom: 14rem;
  }

  .md\:pb-60 {
    padding-bottom: 15rem;
  }

  .md\:pb-64 {
    padding-bottom: 16rem;
  }

  .md\:pb-72 {
    padding-bottom: 18rem;
  }

  .md\:pb-80 {
    padding-bottom: 20rem;
  }

  .md\:pb-96 {
    padding-bottom: 24rem;
  }

  .md\:pb-px {
    padding-bottom: 1px;
  }

  .md\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .md\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .md\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .md\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pl-1 {
    padding-left: .25rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:pl-3 {
    padding-left: .75rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pl-6 {
    padding-left: 1.5rem;
  }

  .md\:pl-7 {
    padding-left: 1.75rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pl-9 {
    padding-left: 2.25rem;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pl-11 {
    padding-left: 2.75rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pl-14 {
    padding-left: 3.5rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }

  .md\:pl-28 {
    padding-left: 7rem;
  }

  .md\:pl-32 {
    padding-left: 8rem;
  }

  .md\:pl-36 {
    padding-left: 9rem;
  }

  .md\:pl-40 {
    padding-left: 10rem;
  }

  .md\:pl-44 {
    padding-left: 11rem;
  }

  .md\:pl-48 {
    padding-left: 12rem;
  }

  .md\:pl-52 {
    padding-left: 13rem;
  }

  .md\:pl-56 {
    padding-left: 14rem;
  }

  .md\:pl-60 {
    padding-left: 15rem;
  }

  .md\:pl-64 {
    padding-left: 16rem;
  }

  .md\:pl-72 {
    padding-left: 18rem;
  }

  .md\:pl-80 {
    padding-left: 20rem;
  }

  .md\:pl-96 {
    padding-left: 24rem;
  }

  .md\:pl-px {
    padding-left: 1px;
  }

  .md\:pl-0\.5 {
    padding-left: .125rem;
  }

  .md\:pl-1\.5 {
    padding-left: .375rem;
  }

  .md\:pl-2\.5 {
    padding-left: .625rem;
  }

  .md\:pl-3\.5 {
    padding-left: .875rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-justify {
    text-align: justify;
  }

  .md\:align-baseline {
    vertical-align: baseline;
  }

  .md\:align-top {
    vertical-align: top;
  }

  .md\:align-middle {
    vertical-align: middle;
  }

  .md\:align-bottom {
    vertical-align: bottom;
  }

  .md\:align-text-top {
    vertical-align: text-top;
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:font-body {
    font-family: Montserrat;
  }

  .md\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .md\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .md\:font-thin {
    font-weight: 100;
  }

  .md\:font-extralight {
    font-weight: 200;
  }

  .md\:font-light {
    font-weight: 300;
  }

  .md\:font-normal {
    font-weight: 400;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:font-bold {
    font-weight: 700;
  }

  .md\:font-extrabold {
    font-weight: 800;
  }

  .md\:font-black {
    font-weight: 900;
  }

  .md\:uppercase {
    text-transform: uppercase;
  }

  .md\:lowercase {
    text-transform: lowercase;
  }

  .md\:capitalize {
    text-transform: capitalize;
  }

  .md\:normal-case {
    text-transform: none;
  }

  .md\:italic {
    font-style: italic;
  }

  .md\:not-italic {
    font-style: normal;
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:normal-nums {
    font-variant-numeric: normal;
  }

  .md\:ordinal {
    --tw-ordinal: ordinal;
  }

  .md\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .md\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .md\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .md\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .md\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .md\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .md\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .md\:leading-3 {
    line-height: .75rem;
  }

  .md\:leading-4 {
    line-height: 1rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:leading-7 {
    line-height: 1.75rem;
  }

  .md\:leading-8 {
    line-height: 2rem;
  }

  .md\:leading-9 {
    line-height: 2.25rem;
  }

  .md\:leading-10 {
    line-height: 2.5rem;
  }

  .md\:leading-none {
    line-height: 1;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }

  .md\:leading-normal {
    line-height: 1.5;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }

  .md\:leading-loose {
    line-height: 2;
  }

  .md\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .md\:tracking-tight {
    letter-spacing: -.025em;
  }

  .md\:tracking-normal {
    letter-spacing: 0;
  }

  .md\:tracking-wide {
    letter-spacing: .025em;
  }

  .md\:tracking-wider {
    letter-spacing: .05em;
  }

  .md\:tracking-widest {
    letter-spacing: .1em;
  }

  .md\:text-transparent {
    color: #0000;
  }

  .md\:text-current {
    color: currentColor;
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .md\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .md\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .md\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .md\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:hover\:text-transparent:hover {
    color: #0000;
  }

  .md\:hover\:text-current:hover {
    color: currentColor;
  }

  .md\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:focus\:text-transparent:focus {
    color: #0000;
  }

  .md\:focus\:text-current:focus {
    color: currentColor;
  }

  .md\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .md\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .md\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .md\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .md\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .md\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .md\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .md\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .md\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .md\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .md\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .md\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .md\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .md\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .md\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .md\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .md\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .md\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .md\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .md\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .md\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .md\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .md\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .md\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .md\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .md\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .md\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .md\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .md\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .md\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .md\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .md\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .md\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .md\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .md\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .md\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .md\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .md\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .md\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .md\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .md\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .md\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .md\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .md\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .md\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .md\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .md\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .md\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .md\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .md\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .md\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .md\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .md\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .md\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .md\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .md\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .md\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .md\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .md\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .md\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .md\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .md\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .md\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .md\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .md\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .md\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .md\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .md\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .md\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .md\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .md\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .md\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .md\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .md\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .md\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .md\:underline {
    text-decoration: underline;
  }

  .md\:line-through {
    text-decoration: line-through;
  }

  .md\:no-underline {
    text-decoration: none;
  }

  .group:hover .md\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .md\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .md\:group-hover\:no-underline {
    text-decoration: none;
  }

  .md\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .md\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .md\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .md\:hover\:underline:hover {
    text-decoration: underline;
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .md\:focus\:underline:focus {
    text-decoration: underline;
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .md\:placeholder-current::placeholder {
    color: currentColor;
  }

  .md\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .md\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .md\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .md\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .md\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .md\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .md\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .md\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .md\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .md\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .md\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .md\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .md\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .md\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .md\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .md\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .md\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .md\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .md\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .md\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .md\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .md\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .md\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:opacity-5 {
    opacity: .05;
  }

  .md\:opacity-10 {
    opacity: .1;
  }

  .md\:opacity-20 {
    opacity: .2;
  }

  .md\:opacity-25 {
    opacity: .25;
  }

  .md\:opacity-30 {
    opacity: .3;
  }

  .md\:opacity-40 {
    opacity: .4;
  }

  .md\:opacity-50 {
    opacity: .5;
  }

  .md\:opacity-60 {
    opacity: .6;
  }

  .md\:opacity-70 {
    opacity: .7;
  }

  .md\:opacity-75 {
    opacity: .75;
  }

  .md\:opacity-80 {
    opacity: .8;
  }

  .md\:opacity-90 {
    opacity: .9;
  }

  .md\:opacity-95 {
    opacity: .95;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .group:hover .md\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .md\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .md\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .md\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .md\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .md\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .md\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .md\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .md\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .md\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .md\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .md\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .md\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .md\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .md\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .md\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .md\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .md\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .md\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .md\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .md\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .md\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .md\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .md\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .md\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .md\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .md\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .md\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .md\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .md\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .md\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .md\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .md\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .md\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .md\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .md\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .md\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .md\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .md\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .md\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .md\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .md\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .md\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .md\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .md\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .md\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .md\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .md\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .md\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .md\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .md\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .md\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .md\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .md\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .md\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .md\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .md\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .md\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .md\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .md\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .md\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .md\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .md\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .md\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .md\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .md\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .md\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .md\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .md\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .md\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .md\:bg-blend-color {
    background-blend-mode: color;
  }

  .md\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .md\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .md\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .md\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .md\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .md\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .md\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .md\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .md\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .md\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .md\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .md\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .md\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .md\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .md\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .md\:mix-blend-color {
    mix-blend-mode: color;
  }

  .md\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-inset, .md\:focus-within\:ring-inset:focus-within, .md\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .md\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .md\:ring-current {
    --tw-ring-color: currentColor;
  }

  .md\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .md\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .md\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .md\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .md\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .md\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .md\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .md\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .md\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .md\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .md\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .md\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .md\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .md\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .md\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .md\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .md\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .md\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .md\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .md\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .md\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .md\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .md\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .md\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .md\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .md\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .md\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .md\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .md\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .md\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .md\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .md\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .md\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .md\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .md\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .md\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .md\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .md\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .md\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .md\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .md\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .md\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .md\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .md\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .md\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .md\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .md\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .md\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .md\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .md\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .md\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .md\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .md\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .md\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .md\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .md\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .md\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .md\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .md\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .md\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .md\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .md\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .md\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .md\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .md\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .md\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .md\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .md\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .md\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .md\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .md\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .md\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .md\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .md\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .md\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .md\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .md\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .md\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .md\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .md\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .md\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .md\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .md\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .md\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .md\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .md\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .md\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .md\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .md\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .md\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .md\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .md\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .md\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .md\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .md\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .md\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .md\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .md\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .md\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .md\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .md\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .md\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .md\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .md\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .md\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .md\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .md\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .md\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .md\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .md\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .md\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .md\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .md\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .md\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .md\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .md\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .md\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .md\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .md\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .md\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .md\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .md\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .md\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .md\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .md\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .md\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .md\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .md\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .md\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .md\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .md\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .md\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .md\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .md\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .md\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .md\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .md\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .md\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .md\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .md\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .md\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .md\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .md\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .md\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .md\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .md\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .md\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .md\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .md\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .md\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .md\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .md\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .md\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .md\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .md\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .md\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .md\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .md\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .md\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .md\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .md\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .md\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .md\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .md\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .md\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .md\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .md\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .md\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .md\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .md\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .md\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .md\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .md\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .md\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .md\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .md\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .md\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .md\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .md\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .md\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .md\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .md\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .md\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .md\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .md\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .md\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .md\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .md\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .md\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .md\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .md\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .md\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .md\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .md\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .md\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .md\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .md\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .md\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .md\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .md\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .md\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .md\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .md\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .md\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .md\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .md\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .md\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .md\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .md\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .md\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .md\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .md\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .md\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .md\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .md\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .md\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .md\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .md\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .md\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .md\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .md\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .md\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .md\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .md\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .md\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .md\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .md\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .md\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .md\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .md\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .md\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .md\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .md\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .md\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .md\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .md\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:filter-none {
    filter: none;
  }

  .md\:blur-0, .md\:blur-none {
    --tw-blur: blur(0);
  }

  .md\:blur-sm {
    --tw-blur: blur(4px);
  }

  .md\:blur {
    --tw-blur: blur(8px);
  }

  .md\:blur-md {
    --tw-blur: blur(12px);
  }

  .md\:blur-lg {
    --tw-blur: blur(16px);
  }

  .md\:blur-xl {
    --tw-blur: blur(24px);
  }

  .md\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .md\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .md\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .md\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .md\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .md\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .md\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .md\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .md\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .md\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .md\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .md\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .md\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .md\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .md\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .md\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .md\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .md\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .md\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .md\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .md\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .md\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .md\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .md\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .md\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .md\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .md\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .md\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .md\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .md\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .md\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .md\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .md\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .md\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .md\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .md\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .md\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .md\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .md\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .md\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .md\:invert-0 {
    --tw-invert: invert(0);
  }

  .md\:invert {
    --tw-invert: invert(100%);
  }

  .md\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .md\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .md\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .md\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .md\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .md\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .md\:sepia {
    --tw-sepia: sepia(100%);
  }

  .md\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .md\:backdrop-blur-0, .md\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .md\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .md\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .md\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .md\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .md\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .md\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .md\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .md\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .md\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .md\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .md\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .md\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .md\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .md\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .md\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .md\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .md\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .md\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .md\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .md\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .md\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .md\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .md\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .md\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .md\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .md\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .md\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .md\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .md\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .md\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .md\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .md\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .md\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .md\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .md\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .md\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .md\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .md\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .md\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .md\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .md\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .md\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .md\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .md\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .md\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .md\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .md\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .md\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .md\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .md\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .md\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .md\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .md\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .md\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .md\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .md\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .md\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .md\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .md\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .md\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .md\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .md\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .md\:transition-none {
    transition-property: none;
  }

  .md\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:delay-75 {
    transition-delay: 75ms;
  }

  .md\:delay-100 {
    transition-delay: .1s;
  }

  .md\:delay-150 {
    transition-delay: .15s;
  }

  .md\:delay-200 {
    transition-delay: .2s;
  }

  .md\:delay-300 {
    transition-delay: .3s;
  }

  .md\:delay-500 {
    transition-delay: .5s;
  }

  .md\:delay-700 {
    transition-delay: .7s;
  }

  .md\:delay-1000 {
    transition-delay: 1s;
  }

  .md\:duration-75 {
    transition-duration: 75ms;
  }

  .md\:duration-100 {
    transition-duration: .1s;
  }

  .md\:duration-150 {
    transition-duration: .15s;
  }

  .md\:duration-200 {
    transition-duration: .2s;
  }

  .md\:duration-300 {
    transition-duration: .3s;
  }

  .md\:duration-500 {
    transition-duration: .5s;
  }

  .md\:duration-700 {
    transition-duration: .7s;
  }

  .md\:duration-1000 {
    transition-duration: 1s;
  }

  .md\:ease-linear {
    transition-timing-function: linear;
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (max-width: 767px) {
  .md-max\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md-max\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md-max\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md-max\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md-max\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .md-max\:container {
      max-width: 1560px;
    }
  }

  .md-max\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md-max\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md-max\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md-max\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md-max\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md-max\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md-max\:pointer-events-none {
    pointer-events: none;
  }

  .md-max\:pointer-events-auto {
    pointer-events: auto;
  }

  .md-max\:visible {
    visibility: visible;
  }

  .md-max\:invisible {
    visibility: hidden;
  }

  .md-max\:static {
    position: static;
  }

  .md-max\:fixed {
    position: fixed;
  }

  .md-max\:absolute {
    position: absolute;
  }

  .md-max\:relative {
    position: relative;
  }

  .md-max\:sticky {
    position: sticky;
  }

  .md-max\:inset-0 {
    inset: 0;
  }

  .md-max\:inset-1 {
    inset: .25rem;
  }

  .md-max\:inset-2 {
    inset: .5rem;
  }

  .md-max\:inset-3 {
    inset: .75rem;
  }

  .md-max\:inset-4 {
    inset: 1rem;
  }

  .md-max\:inset-5 {
    inset: 1.25rem;
  }

  .md-max\:inset-6 {
    inset: 1.5rem;
  }

  .md-max\:inset-7 {
    inset: 1.75rem;
  }

  .md-max\:inset-8 {
    inset: 2rem;
  }

  .md-max\:inset-9 {
    inset: 2.25rem;
  }

  .md-max\:inset-10 {
    inset: 2.5rem;
  }

  .md-max\:inset-11 {
    inset: 2.75rem;
  }

  .md-max\:inset-12 {
    inset: 3rem;
  }

  .md-max\:inset-14 {
    inset: 3.5rem;
  }

  .md-max\:inset-16 {
    inset: 4rem;
  }

  .md-max\:inset-20 {
    inset: 5rem;
  }

  .md-max\:inset-24 {
    inset: 6rem;
  }

  .md-max\:inset-28 {
    inset: 7rem;
  }

  .md-max\:inset-32 {
    inset: 8rem;
  }

  .md-max\:inset-36 {
    inset: 9rem;
  }

  .md-max\:inset-40 {
    inset: 10rem;
  }

  .md-max\:inset-44 {
    inset: 11rem;
  }

  .md-max\:inset-48 {
    inset: 12rem;
  }

  .md-max\:inset-52 {
    inset: 13rem;
  }

  .md-max\:inset-56 {
    inset: 14rem;
  }

  .md-max\:inset-60 {
    inset: 15rem;
  }

  .md-max\:inset-64 {
    inset: 16rem;
  }

  .md-max\:inset-72 {
    inset: 18rem;
  }

  .md-max\:inset-80 {
    inset: 20rem;
  }

  .md-max\:inset-96 {
    inset: 24rem;
  }

  .md-max\:inset-auto {
    inset: auto;
  }

  .md-max\:inset-px {
    inset: 1px;
  }

  .md-max\:inset-0\.5 {
    inset: .125rem;
  }

  .md-max\:inset-1\.5 {
    inset: .375rem;
  }

  .md-max\:inset-2\.5 {
    inset: .625rem;
  }

  .md-max\:inset-3\.5 {
    inset: .875rem;
  }

  .md-max\:-inset-0 {
    inset: 0;
  }

  .md-max\:-inset-1 {
    inset: -.25rem;
  }

  .md-max\:-inset-2 {
    inset: -.5rem;
  }

  .md-max\:-inset-3 {
    inset: -.75rem;
  }

  .md-max\:-inset-4 {
    inset: -1rem;
  }

  .md-max\:-inset-5 {
    inset: -1.25rem;
  }

  .md-max\:-inset-6 {
    inset: -1.5rem;
  }

  .md-max\:-inset-7 {
    inset: -1.75rem;
  }

  .md-max\:-inset-8 {
    inset: -2rem;
  }

  .md-max\:-inset-9 {
    inset: -2.25rem;
  }

  .md-max\:-inset-10 {
    inset: -2.5rem;
  }

  .md-max\:-inset-11 {
    inset: -2.75rem;
  }

  .md-max\:-inset-12 {
    inset: -3rem;
  }

  .md-max\:-inset-14 {
    inset: -3.5rem;
  }

  .md-max\:-inset-16 {
    inset: -4rem;
  }

  .md-max\:-inset-20 {
    inset: -5rem;
  }

  .md-max\:-inset-24 {
    inset: -6rem;
  }

  .md-max\:-inset-28 {
    inset: -7rem;
  }

  .md-max\:-inset-32 {
    inset: -8rem;
  }

  .md-max\:-inset-36 {
    inset: -9rem;
  }

  .md-max\:-inset-40 {
    inset: -10rem;
  }

  .md-max\:-inset-44 {
    inset: -11rem;
  }

  .md-max\:-inset-48 {
    inset: -12rem;
  }

  .md-max\:-inset-52 {
    inset: -13rem;
  }

  .md-max\:-inset-56 {
    inset: -14rem;
  }

  .md-max\:-inset-60 {
    inset: -15rem;
  }

  .md-max\:-inset-64 {
    inset: -16rem;
  }

  .md-max\:-inset-72 {
    inset: -18rem;
  }

  .md-max\:-inset-80 {
    inset: -20rem;
  }

  .md-max\:-inset-96 {
    inset: -24rem;
  }

  .md-max\:-inset-px {
    inset: -1px;
  }

  .md-max\:-inset-0\.5 {
    inset: -.125rem;
  }

  .md-max\:-inset-1\.5 {
    inset: -.375rem;
  }

  .md-max\:-inset-2\.5 {
    inset: -.625rem;
  }

  .md-max\:-inset-3\.5 {
    inset: -.875rem;
  }

  .md-max\:inset-1\/2 {
    inset: 50%;
  }

  .md-max\:inset-1\/3 {
    inset: 33.3333%;
  }

  .md-max\:inset-2\/3 {
    inset: 66.6667%;
  }

  .md-max\:inset-1\/4 {
    inset: 25%;
  }

  .md-max\:inset-2\/4 {
    inset: 50%;
  }

  .md-max\:inset-3\/4 {
    inset: 75%;
  }

  .md-max\:inset-full {
    inset: 100%;
  }

  .md-max\:-inset-1\/2 {
    inset: -50%;
  }

  .md-max\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .md-max\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .md-max\:-inset-1\/4 {
    inset: -25%;
  }

  .md-max\:-inset-2\/4 {
    inset: -50%;
  }

  .md-max\:-inset-3\/4 {
    inset: -75%;
  }

  .md-max\:-inset-full {
    inset: -100%;
  }

  .md-max\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md-max\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .md-max\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .md-max\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .md-max\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .md-max\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .md-max\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .md-max\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .md-max\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .md-max\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .md-max\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .md-max\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .md-max\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .md-max\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .md-max\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .md-max\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .md-max\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .md-max\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .md-max\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .md-max\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .md-max\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .md-max\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .md-max\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .md-max\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .md-max\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .md-max\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .md-max\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .md-max\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .md-max\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .md-max\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .md-max\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .md-max\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .md-max\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .md-max\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .md-max\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .md-max\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .md-max\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .md-max\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .md-max\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .md-max\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .md-max\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .md-max\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .md-max\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .md-max\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .md-max\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .md-max\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .md-max\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .md-max\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .md-max\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .md-max\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .md-max\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .md-max\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .md-max\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .md-max\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .md-max\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .md-max\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .md-max\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .md-max\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .md-max\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .md-max\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .md-max\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .md-max\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .md-max\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .md-max\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .md-max\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .md-max\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .md-max\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .md-max\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .md-max\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .md-max\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .md-max\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .md-max\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .md-max\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md-max\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .md-max\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .md-max\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .md-max\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .md-max\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .md-max\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .md-max\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .md-max\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .md-max\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .md-max\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .md-max\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .md-max\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .md-max\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md-max\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .md-max\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .md-max\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .md-max\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .md-max\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .md-max\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .md-max\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .md-max\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .md-max\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .md-max\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .md-max\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .md-max\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .md-max\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .md-max\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .md-max\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .md-max\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .md-max\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .md-max\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .md-max\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .md-max\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .md-max\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .md-max\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .md-max\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .md-max\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .md-max\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .md-max\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .md-max\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .md-max\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .md-max\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .md-max\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md-max\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .md-max\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .md-max\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .md-max\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .md-max\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .md-max\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md-max\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .md-max\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .md-max\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .md-max\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .md-max\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .md-max\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .md-max\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .md-max\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .md-max\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .md-max\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .md-max\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .md-max\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .md-max\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .md-max\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .md-max\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .md-max\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .md-max\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .md-max\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .md-max\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .md-max\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .md-max\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .md-max\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .md-max\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .md-max\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .md-max\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .md-max\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .md-max\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .md-max\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .md-max\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .md-max\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .md-max\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .md-max\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .md-max\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .md-max\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .md-max\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .md-max\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .md-max\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .md-max\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .md-max\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .md-max\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .md-max\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .md-max\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .md-max\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .md-max\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .md-max\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .md-max\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .md-max\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .md-max\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .md-max\:top-0 {
    top: 0;
  }

  .md-max\:top-1 {
    top: .25rem;
  }

  .md-max\:top-2 {
    top: .5rem;
  }

  .md-max\:top-3 {
    top: .75rem;
  }

  .md-max\:top-4 {
    top: 1rem;
  }

  .md-max\:top-5 {
    top: 1.25rem;
  }

  .md-max\:top-6 {
    top: 1.5rem;
  }

  .md-max\:top-7 {
    top: 1.75rem;
  }

  .md-max\:top-8 {
    top: 2rem;
  }

  .md-max\:top-9 {
    top: 2.25rem;
  }

  .md-max\:top-10 {
    top: 2.5rem;
  }

  .md-max\:top-11 {
    top: 2.75rem;
  }

  .md-max\:top-12 {
    top: 3rem;
  }

  .md-max\:top-14 {
    top: 3.5rem;
  }

  .md-max\:top-16 {
    top: 4rem;
  }

  .md-max\:top-20 {
    top: 5rem;
  }

  .md-max\:top-24 {
    top: 6rem;
  }

  .md-max\:top-28 {
    top: 7rem;
  }

  .md-max\:top-32 {
    top: 8rem;
  }

  .md-max\:top-36 {
    top: 9rem;
  }

  .md-max\:top-40 {
    top: 10rem;
  }

  .md-max\:top-44 {
    top: 11rem;
  }

  .md-max\:top-48 {
    top: 12rem;
  }

  .md-max\:top-52 {
    top: 13rem;
  }

  .md-max\:top-56 {
    top: 14rem;
  }

  .md-max\:top-60 {
    top: 15rem;
  }

  .md-max\:top-64 {
    top: 16rem;
  }

  .md-max\:top-72 {
    top: 18rem;
  }

  .md-max\:top-80 {
    top: 20rem;
  }

  .md-max\:top-96 {
    top: 24rem;
  }

  .md-max\:top-auto {
    top: auto;
  }

  .md-max\:top-px {
    top: 1px;
  }

  .md-max\:top-0\.5 {
    top: .125rem;
  }

  .md-max\:top-1\.5 {
    top: .375rem;
  }

  .md-max\:top-2\.5 {
    top: .625rem;
  }

  .md-max\:top-3\.5 {
    top: .875rem;
  }

  .md-max\:-top-0 {
    top: 0;
  }

  .md-max\:-top-1 {
    top: -.25rem;
  }

  .md-max\:-top-2 {
    top: -.5rem;
  }

  .md-max\:-top-3 {
    top: -.75rem;
  }

  .md-max\:-top-4 {
    top: -1rem;
  }

  .md-max\:-top-5 {
    top: -1.25rem;
  }

  .md-max\:-top-6 {
    top: -1.5rem;
  }

  .md-max\:-top-7 {
    top: -1.75rem;
  }

  .md-max\:-top-8 {
    top: -2rem;
  }

  .md-max\:-top-9 {
    top: -2.25rem;
  }

  .md-max\:-top-10 {
    top: -2.5rem;
  }

  .md-max\:-top-11 {
    top: -2.75rem;
  }

  .md-max\:-top-12 {
    top: -3rem;
  }

  .md-max\:-top-14 {
    top: -3.5rem;
  }

  .md-max\:-top-16 {
    top: -4rem;
  }

  .md-max\:-top-20 {
    top: -5rem;
  }

  .md-max\:-top-24 {
    top: -6rem;
  }

  .md-max\:-top-28 {
    top: -7rem;
  }

  .md-max\:-top-32 {
    top: -8rem;
  }

  .md-max\:-top-36 {
    top: -9rem;
  }

  .md-max\:-top-40 {
    top: -10rem;
  }

  .md-max\:-top-44 {
    top: -11rem;
  }

  .md-max\:-top-48 {
    top: -12rem;
  }

  .md-max\:-top-52 {
    top: -13rem;
  }

  .md-max\:-top-56 {
    top: -14rem;
  }

  .md-max\:-top-60 {
    top: -15rem;
  }

  .md-max\:-top-64 {
    top: -16rem;
  }

  .md-max\:-top-72 {
    top: -18rem;
  }

  .md-max\:-top-80 {
    top: -20rem;
  }

  .md-max\:-top-96 {
    top: -24rem;
  }

  .md-max\:-top-px {
    top: -1px;
  }

  .md-max\:-top-0\.5 {
    top: -.125rem;
  }

  .md-max\:-top-1\.5 {
    top: -.375rem;
  }

  .md-max\:-top-2\.5 {
    top: -.625rem;
  }

  .md-max\:-top-3\.5 {
    top: -.875rem;
  }

  .md-max\:top-1\/2 {
    top: 50%;
  }

  .md-max\:top-1\/3 {
    top: 33.3333%;
  }

  .md-max\:top-2\/3 {
    top: 66.6667%;
  }

  .md-max\:top-1\/4 {
    top: 25%;
  }

  .md-max\:top-2\/4 {
    top: 50%;
  }

  .md-max\:top-3\/4 {
    top: 75%;
  }

  .md-max\:top-full {
    top: 100%;
  }

  .md-max\:-top-1\/2 {
    top: -50%;
  }

  .md-max\:-top-1\/3 {
    top: -33.3333%;
  }

  .md-max\:-top-2\/3 {
    top: -66.6667%;
  }

  .md-max\:-top-1\/4 {
    top: -25%;
  }

  .md-max\:-top-2\/4 {
    top: -50%;
  }

  .md-max\:-top-3\/4 {
    top: -75%;
  }

  .md-max\:-top-full {
    top: -100%;
  }

  .md-max\:right-0 {
    right: 0;
  }

  .md-max\:right-1 {
    right: .25rem;
  }

  .md-max\:right-2 {
    right: .5rem;
  }

  .md-max\:right-3 {
    right: .75rem;
  }

  .md-max\:right-4 {
    right: 1rem;
  }

  .md-max\:right-5 {
    right: 1.25rem;
  }

  .md-max\:right-6 {
    right: 1.5rem;
  }

  .md-max\:right-7 {
    right: 1.75rem;
  }

  .md-max\:right-8 {
    right: 2rem;
  }

  .md-max\:right-9 {
    right: 2.25rem;
  }

  .md-max\:right-10 {
    right: 2.5rem;
  }

  .md-max\:right-11 {
    right: 2.75rem;
  }

  .md-max\:right-12 {
    right: 3rem;
  }

  .md-max\:right-14 {
    right: 3.5rem;
  }

  .md-max\:right-16 {
    right: 4rem;
  }

  .md-max\:right-20 {
    right: 5rem;
  }

  .md-max\:right-24 {
    right: 6rem;
  }

  .md-max\:right-28 {
    right: 7rem;
  }

  .md-max\:right-32 {
    right: 8rem;
  }

  .md-max\:right-36 {
    right: 9rem;
  }

  .md-max\:right-40 {
    right: 10rem;
  }

  .md-max\:right-44 {
    right: 11rem;
  }

  .md-max\:right-48 {
    right: 12rem;
  }

  .md-max\:right-52 {
    right: 13rem;
  }

  .md-max\:right-56 {
    right: 14rem;
  }

  .md-max\:right-60 {
    right: 15rem;
  }

  .md-max\:right-64 {
    right: 16rem;
  }

  .md-max\:right-72 {
    right: 18rem;
  }

  .md-max\:right-80 {
    right: 20rem;
  }

  .md-max\:right-96 {
    right: 24rem;
  }

  .md-max\:right-auto {
    right: auto;
  }

  .md-max\:right-px {
    right: 1px;
  }

  .md-max\:right-0\.5 {
    right: .125rem;
  }

  .md-max\:right-1\.5 {
    right: .375rem;
  }

  .md-max\:right-2\.5 {
    right: .625rem;
  }

  .md-max\:right-3\.5 {
    right: .875rem;
  }

  .md-max\:-right-0 {
    right: 0;
  }

  .md-max\:-right-1 {
    right: -.25rem;
  }

  .md-max\:-right-2 {
    right: -.5rem;
  }

  .md-max\:-right-3 {
    right: -.75rem;
  }

  .md-max\:-right-4 {
    right: -1rem;
  }

  .md-max\:-right-5 {
    right: -1.25rem;
  }

  .md-max\:-right-6 {
    right: -1.5rem;
  }

  .md-max\:-right-7 {
    right: -1.75rem;
  }

  .md-max\:-right-8 {
    right: -2rem;
  }

  .md-max\:-right-9 {
    right: -2.25rem;
  }

  .md-max\:-right-10 {
    right: -2.5rem;
  }

  .md-max\:-right-11 {
    right: -2.75rem;
  }

  .md-max\:-right-12 {
    right: -3rem;
  }

  .md-max\:-right-14 {
    right: -3.5rem;
  }

  .md-max\:-right-16 {
    right: -4rem;
  }

  .md-max\:-right-20 {
    right: -5rem;
  }

  .md-max\:-right-24 {
    right: -6rem;
  }

  .md-max\:-right-28 {
    right: -7rem;
  }

  .md-max\:-right-32 {
    right: -8rem;
  }

  .md-max\:-right-36 {
    right: -9rem;
  }

  .md-max\:-right-40 {
    right: -10rem;
  }

  .md-max\:-right-44 {
    right: -11rem;
  }

  .md-max\:-right-48 {
    right: -12rem;
  }

  .md-max\:-right-52 {
    right: -13rem;
  }

  .md-max\:-right-56 {
    right: -14rem;
  }

  .md-max\:-right-60 {
    right: -15rem;
  }

  .md-max\:-right-64 {
    right: -16rem;
  }

  .md-max\:-right-72 {
    right: -18rem;
  }

  .md-max\:-right-80 {
    right: -20rem;
  }

  .md-max\:-right-96 {
    right: -24rem;
  }

  .md-max\:-right-px {
    right: -1px;
  }

  .md-max\:-right-0\.5 {
    right: -.125rem;
  }

  .md-max\:-right-1\.5 {
    right: -.375rem;
  }

  .md-max\:-right-2\.5 {
    right: -.625rem;
  }

  .md-max\:-right-3\.5 {
    right: -.875rem;
  }

  .md-max\:right-1\/2 {
    right: 50%;
  }

  .md-max\:right-1\/3 {
    right: 33.3333%;
  }

  .md-max\:right-2\/3 {
    right: 66.6667%;
  }

  .md-max\:right-1\/4 {
    right: 25%;
  }

  .md-max\:right-2\/4 {
    right: 50%;
  }

  .md-max\:right-3\/4 {
    right: 75%;
  }

  .md-max\:right-full {
    right: 100%;
  }

  .md-max\:-right-1\/2 {
    right: -50%;
  }

  .md-max\:-right-1\/3 {
    right: -33.3333%;
  }

  .md-max\:-right-2\/3 {
    right: -66.6667%;
  }

  .md-max\:-right-1\/4 {
    right: -25%;
  }

  .md-max\:-right-2\/4 {
    right: -50%;
  }

  .md-max\:-right-3\/4 {
    right: -75%;
  }

  .md-max\:-right-full {
    right: -100%;
  }

  .md-max\:bottom-0 {
    bottom: 0;
  }

  .md-max\:bottom-1 {
    bottom: .25rem;
  }

  .md-max\:bottom-2 {
    bottom: .5rem;
  }

  .md-max\:bottom-3 {
    bottom: .75rem;
  }

  .md-max\:bottom-4 {
    bottom: 1rem;
  }

  .md-max\:bottom-5 {
    bottom: 1.25rem;
  }

  .md-max\:bottom-6 {
    bottom: 1.5rem;
  }

  .md-max\:bottom-7 {
    bottom: 1.75rem;
  }

  .md-max\:bottom-8 {
    bottom: 2rem;
  }

  .md-max\:bottom-9 {
    bottom: 2.25rem;
  }

  .md-max\:bottom-10 {
    bottom: 2.5rem;
  }

  .md-max\:bottom-11 {
    bottom: 2.75rem;
  }

  .md-max\:bottom-12 {
    bottom: 3rem;
  }

  .md-max\:bottom-14 {
    bottom: 3.5rem;
  }

  .md-max\:bottom-16 {
    bottom: 4rem;
  }

  .md-max\:bottom-20 {
    bottom: 5rem;
  }

  .md-max\:bottom-24 {
    bottom: 6rem;
  }

  .md-max\:bottom-28 {
    bottom: 7rem;
  }

  .md-max\:bottom-32 {
    bottom: 8rem;
  }

  .md-max\:bottom-36 {
    bottom: 9rem;
  }

  .md-max\:bottom-40 {
    bottom: 10rem;
  }

  .md-max\:bottom-44 {
    bottom: 11rem;
  }

  .md-max\:bottom-48 {
    bottom: 12rem;
  }

  .md-max\:bottom-52 {
    bottom: 13rem;
  }

  .md-max\:bottom-56 {
    bottom: 14rem;
  }

  .md-max\:bottom-60 {
    bottom: 15rem;
  }

  .md-max\:bottom-64 {
    bottom: 16rem;
  }

  .md-max\:bottom-72 {
    bottom: 18rem;
  }

  .md-max\:bottom-80 {
    bottom: 20rem;
  }

  .md-max\:bottom-96 {
    bottom: 24rem;
  }

  .md-max\:bottom-auto {
    bottom: auto;
  }

  .md-max\:bottom-px {
    bottom: 1px;
  }

  .md-max\:bottom-0\.5 {
    bottom: .125rem;
  }

  .md-max\:bottom-1\.5 {
    bottom: .375rem;
  }

  .md-max\:bottom-2\.5 {
    bottom: .625rem;
  }

  .md-max\:bottom-3\.5 {
    bottom: .875rem;
  }

  .md-max\:-bottom-0 {
    bottom: 0;
  }

  .md-max\:-bottom-1 {
    bottom: -.25rem;
  }

  .md-max\:-bottom-2 {
    bottom: -.5rem;
  }

  .md-max\:-bottom-3 {
    bottom: -.75rem;
  }

  .md-max\:-bottom-4 {
    bottom: -1rem;
  }

  .md-max\:-bottom-5 {
    bottom: -1.25rem;
  }

  .md-max\:-bottom-6 {
    bottom: -1.5rem;
  }

  .md-max\:-bottom-7 {
    bottom: -1.75rem;
  }

  .md-max\:-bottom-8 {
    bottom: -2rem;
  }

  .md-max\:-bottom-9 {
    bottom: -2.25rem;
  }

  .md-max\:-bottom-10 {
    bottom: -2.5rem;
  }

  .md-max\:-bottom-11 {
    bottom: -2.75rem;
  }

  .md-max\:-bottom-12 {
    bottom: -3rem;
  }

  .md-max\:-bottom-14 {
    bottom: -3.5rem;
  }

  .md-max\:-bottom-16 {
    bottom: -4rem;
  }

  .md-max\:-bottom-20 {
    bottom: -5rem;
  }

  .md-max\:-bottom-24 {
    bottom: -6rem;
  }

  .md-max\:-bottom-28 {
    bottom: -7rem;
  }

  .md-max\:-bottom-32 {
    bottom: -8rem;
  }

  .md-max\:-bottom-36 {
    bottom: -9rem;
  }

  .md-max\:-bottom-40 {
    bottom: -10rem;
  }

  .md-max\:-bottom-44 {
    bottom: -11rem;
  }

  .md-max\:-bottom-48 {
    bottom: -12rem;
  }

  .md-max\:-bottom-52 {
    bottom: -13rem;
  }

  .md-max\:-bottom-56 {
    bottom: -14rem;
  }

  .md-max\:-bottom-60 {
    bottom: -15rem;
  }

  .md-max\:-bottom-64 {
    bottom: -16rem;
  }

  .md-max\:-bottom-72 {
    bottom: -18rem;
  }

  .md-max\:-bottom-80 {
    bottom: -20rem;
  }

  .md-max\:-bottom-96 {
    bottom: -24rem;
  }

  .md-max\:-bottom-px {
    bottom: -1px;
  }

  .md-max\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .md-max\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .md-max\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .md-max\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .md-max\:bottom-1\/2 {
    bottom: 50%;
  }

  .md-max\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .md-max\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .md-max\:bottom-1\/4 {
    bottom: 25%;
  }

  .md-max\:bottom-2\/4 {
    bottom: 50%;
  }

  .md-max\:bottom-3\/4 {
    bottom: 75%;
  }

  .md-max\:bottom-full {
    bottom: 100%;
  }

  .md-max\:-bottom-1\/2 {
    bottom: -50%;
  }

  .md-max\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .md-max\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .md-max\:-bottom-1\/4 {
    bottom: -25%;
  }

  .md-max\:-bottom-2\/4 {
    bottom: -50%;
  }

  .md-max\:-bottom-3\/4 {
    bottom: -75%;
  }

  .md-max\:-bottom-full {
    bottom: -100%;
  }

  .md-max\:left-0 {
    left: 0;
  }

  .md-max\:left-1 {
    left: .25rem;
  }

  .md-max\:left-2 {
    left: .5rem;
  }

  .md-max\:left-3 {
    left: .75rem;
  }

  .md-max\:left-4 {
    left: 1rem;
  }

  .md-max\:left-5 {
    left: 1.25rem;
  }

  .md-max\:left-6 {
    left: 1.5rem;
  }

  .md-max\:left-7 {
    left: 1.75rem;
  }

  .md-max\:left-8 {
    left: 2rem;
  }

  .md-max\:left-9 {
    left: 2.25rem;
  }

  .md-max\:left-10 {
    left: 2.5rem;
  }

  .md-max\:left-11 {
    left: 2.75rem;
  }

  .md-max\:left-12 {
    left: 3rem;
  }

  .md-max\:left-14 {
    left: 3.5rem;
  }

  .md-max\:left-16 {
    left: 4rem;
  }

  .md-max\:left-20 {
    left: 5rem;
  }

  .md-max\:left-24 {
    left: 6rem;
  }

  .md-max\:left-28 {
    left: 7rem;
  }

  .md-max\:left-32 {
    left: 8rem;
  }

  .md-max\:left-36 {
    left: 9rem;
  }

  .md-max\:left-40 {
    left: 10rem;
  }

  .md-max\:left-44 {
    left: 11rem;
  }

  .md-max\:left-48 {
    left: 12rem;
  }

  .md-max\:left-52 {
    left: 13rem;
  }

  .md-max\:left-56 {
    left: 14rem;
  }

  .md-max\:left-60 {
    left: 15rem;
  }

  .md-max\:left-64 {
    left: 16rem;
  }

  .md-max\:left-72 {
    left: 18rem;
  }

  .md-max\:left-80 {
    left: 20rem;
  }

  .md-max\:left-96 {
    left: 24rem;
  }

  .md-max\:left-auto {
    left: auto;
  }

  .md-max\:left-px {
    left: 1px;
  }

  .md-max\:left-0\.5 {
    left: .125rem;
  }

  .md-max\:left-1\.5 {
    left: .375rem;
  }

  .md-max\:left-2\.5 {
    left: .625rem;
  }

  .md-max\:left-3\.5 {
    left: .875rem;
  }

  .md-max\:-left-0 {
    left: 0;
  }

  .md-max\:-left-1 {
    left: -.25rem;
  }

  .md-max\:-left-2 {
    left: -.5rem;
  }

  .md-max\:-left-3 {
    left: -.75rem;
  }

  .md-max\:-left-4 {
    left: -1rem;
  }

  .md-max\:-left-5 {
    left: -1.25rem;
  }

  .md-max\:-left-6 {
    left: -1.5rem;
  }

  .md-max\:-left-7 {
    left: -1.75rem;
  }

  .md-max\:-left-8 {
    left: -2rem;
  }

  .md-max\:-left-9 {
    left: -2.25rem;
  }

  .md-max\:-left-10 {
    left: -2.5rem;
  }

  .md-max\:-left-11 {
    left: -2.75rem;
  }

  .md-max\:-left-12 {
    left: -3rem;
  }

  .md-max\:-left-14 {
    left: -3.5rem;
  }

  .md-max\:-left-16 {
    left: -4rem;
  }

  .md-max\:-left-20 {
    left: -5rem;
  }

  .md-max\:-left-24 {
    left: -6rem;
  }

  .md-max\:-left-28 {
    left: -7rem;
  }

  .md-max\:-left-32 {
    left: -8rem;
  }

  .md-max\:-left-36 {
    left: -9rem;
  }

  .md-max\:-left-40 {
    left: -10rem;
  }

  .md-max\:-left-44 {
    left: -11rem;
  }

  .md-max\:-left-48 {
    left: -12rem;
  }

  .md-max\:-left-52 {
    left: -13rem;
  }

  .md-max\:-left-56 {
    left: -14rem;
  }

  .md-max\:-left-60 {
    left: -15rem;
  }

  .md-max\:-left-64 {
    left: -16rem;
  }

  .md-max\:-left-72 {
    left: -18rem;
  }

  .md-max\:-left-80 {
    left: -20rem;
  }

  .md-max\:-left-96 {
    left: -24rem;
  }

  .md-max\:-left-px {
    left: -1px;
  }

  .md-max\:-left-0\.5 {
    left: -.125rem;
  }

  .md-max\:-left-1\.5 {
    left: -.375rem;
  }

  .md-max\:-left-2\.5 {
    left: -.625rem;
  }

  .md-max\:-left-3\.5 {
    left: -.875rem;
  }

  .md-max\:left-1\/2 {
    left: 50%;
  }

  .md-max\:left-1\/3 {
    left: 33.3333%;
  }

  .md-max\:left-2\/3 {
    left: 66.6667%;
  }

  .md-max\:left-1\/4 {
    left: 25%;
  }

  .md-max\:left-2\/4 {
    left: 50%;
  }

  .md-max\:left-3\/4 {
    left: 75%;
  }

  .md-max\:left-full {
    left: 100%;
  }

  .md-max\:-left-1\/2 {
    left: -50%;
  }

  .md-max\:-left-1\/3 {
    left: -33.3333%;
  }

  .md-max\:-left-2\/3 {
    left: -66.6667%;
  }

  .md-max\:-left-1\/4 {
    left: -25%;
  }

  .md-max\:-left-2\/4 {
    left: -50%;
  }

  .md-max\:-left-3\/4 {
    left: -75%;
  }

  .md-max\:-left-full {
    left: -100%;
  }

  .md-max\:isolate {
    isolation: isolate;
  }

  .md-max\:isolation-auto {
    isolation: auto;
  }

  .md-max\:z-0 {
    z-index: 0;
  }

  .md-max\:z-10 {
    z-index: 10;
  }

  .md-max\:z-20 {
    z-index: 20;
  }

  .md-max\:z-30 {
    z-index: 30;
  }

  .md-max\:z-40 {
    z-index: 40;
  }

  .md-max\:z-50 {
    z-index: 50;
  }

  .md-max\:z-auto {
    z-index: auto;
  }

  .md-max\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .md-max\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .md-max\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .md-max\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .md-max\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .md-max\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .md-max\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .md-max\:focus\:z-0:focus {
    z-index: 0;
  }

  .md-max\:focus\:z-10:focus {
    z-index: 10;
  }

  .md-max\:focus\:z-20:focus {
    z-index: 20;
  }

  .md-max\:focus\:z-30:focus {
    z-index: 30;
  }

  .md-max\:focus\:z-40:focus {
    z-index: 40;
  }

  .md-max\:focus\:z-50:focus {
    z-index: 50;
  }

  .md-max\:focus\:z-auto:focus {
    z-index: auto;
  }

  .md-max\:order-1 {
    order: 1;
  }

  .md-max\:order-2 {
    order: 2;
  }

  .md-max\:order-3 {
    order: 3;
  }

  .md-max\:order-4 {
    order: 4;
  }

  .md-max\:order-5 {
    order: 5;
  }

  .md-max\:order-6 {
    order: 6;
  }

  .md-max\:order-7 {
    order: 7;
  }

  .md-max\:order-8 {
    order: 8;
  }

  .md-max\:order-9 {
    order: 9;
  }

  .md-max\:order-10 {
    order: 10;
  }

  .md-max\:order-11 {
    order: 11;
  }

  .md-max\:order-12 {
    order: 12;
  }

  .md-max\:order-first {
    order: -9999;
  }

  .md-max\:order-last {
    order: 9999;
  }

  .md-max\:order-none {
    order: 0;
  }

  .md-max\:col-auto {
    grid-column: auto;
  }

  .md-max\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md-max\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md-max\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md-max\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md-max\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md-max\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md-max\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md-max\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md-max\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md-max\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md-max\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md-max\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md-max\:col-span-full {
    grid-column: 1 / -1;
  }

  .md-max\:col-start-1 {
    grid-column-start: 1;
  }

  .md-max\:col-start-2 {
    grid-column-start: 2;
  }

  .md-max\:col-start-3 {
    grid-column-start: 3;
  }

  .md-max\:col-start-4 {
    grid-column-start: 4;
  }

  .md-max\:col-start-5 {
    grid-column-start: 5;
  }

  .md-max\:col-start-6 {
    grid-column-start: 6;
  }

  .md-max\:col-start-7 {
    grid-column-start: 7;
  }

  .md-max\:col-start-8 {
    grid-column-start: 8;
  }

  .md-max\:col-start-9 {
    grid-column-start: 9;
  }

  .md-max\:col-start-10 {
    grid-column-start: 10;
  }

  .md-max\:col-start-11 {
    grid-column-start: 11;
  }

  .md-max\:col-start-12 {
    grid-column-start: 12;
  }

  .md-max\:col-start-13 {
    grid-column-start: 13;
  }

  .md-max\:col-start-auto {
    grid-column-start: auto;
  }

  .md-max\:col-end-1 {
    grid-column-end: 1;
  }

  .md-max\:col-end-2 {
    grid-column-end: 2;
  }

  .md-max\:col-end-3 {
    grid-column-end: 3;
  }

  .md-max\:col-end-4 {
    grid-column-end: 4;
  }

  .md-max\:col-end-5 {
    grid-column-end: 5;
  }

  .md-max\:col-end-6 {
    grid-column-end: 6;
  }

  .md-max\:col-end-7 {
    grid-column-end: 7;
  }

  .md-max\:col-end-8 {
    grid-column-end: 8;
  }

  .md-max\:col-end-9 {
    grid-column-end: 9;
  }

  .md-max\:col-end-10 {
    grid-column-end: 10;
  }

  .md-max\:col-end-11 {
    grid-column-end: 11;
  }

  .md-max\:col-end-12 {
    grid-column-end: 12;
  }

  .md-max\:col-end-13 {
    grid-column-end: 13;
  }

  .md-max\:col-end-auto {
    grid-column-end: auto;
  }

  .md-max\:row-auto {
    grid-row: auto;
  }

  .md-max\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md-max\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md-max\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md-max\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md-max\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md-max\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md-max\:row-span-full {
    grid-row: 1 / -1;
  }

  .md-max\:row-start-1 {
    grid-row-start: 1;
  }

  .md-max\:row-start-2 {
    grid-row-start: 2;
  }

  .md-max\:row-start-3 {
    grid-row-start: 3;
  }

  .md-max\:row-start-4 {
    grid-row-start: 4;
  }

  .md-max\:row-start-5 {
    grid-row-start: 5;
  }

  .md-max\:row-start-6 {
    grid-row-start: 6;
  }

  .md-max\:row-start-7 {
    grid-row-start: 7;
  }

  .md-max\:row-start-auto {
    grid-row-start: auto;
  }

  .md-max\:row-end-1 {
    grid-row-end: 1;
  }

  .md-max\:row-end-2 {
    grid-row-end: 2;
  }

  .md-max\:row-end-3 {
    grid-row-end: 3;
  }

  .md-max\:row-end-4 {
    grid-row-end: 4;
  }

  .md-max\:row-end-5 {
    grid-row-end: 5;
  }

  .md-max\:row-end-6 {
    grid-row-end: 6;
  }

  .md-max\:row-end-7 {
    grid-row-end: 7;
  }

  .md-max\:row-end-auto {
    grid-row-end: auto;
  }

  .md-max\:float-right {
    float: right;
  }

  .md-max\:float-left {
    float: left;
  }

  .md-max\:float-none {
    float: none;
  }

  .md-max\:clear-left {
    clear: left;
  }

  .md-max\:clear-right {
    clear: right;
  }

  .md-max\:clear-both {
    clear: both;
  }

  .md-max\:clear-none {
    clear: none;
  }

  .md-max\:m-0 {
    margin: 0;
  }

  .md-max\:m-1 {
    margin: .25rem;
  }

  .md-max\:m-2 {
    margin: .5rem;
  }

  .md-max\:m-3 {
    margin: .75rem;
  }

  .md-max\:m-4 {
    margin: 1rem;
  }

  .md-max\:m-5 {
    margin: 1.25rem;
  }

  .md-max\:m-6 {
    margin: 1.5rem;
  }

  .md-max\:m-7 {
    margin: 1.75rem;
  }

  .md-max\:m-8 {
    margin: 2rem;
  }

  .md-max\:m-9 {
    margin: 2.25rem;
  }

  .md-max\:m-10 {
    margin: 2.5rem;
  }

  .md-max\:m-11 {
    margin: 2.75rem;
  }

  .md-max\:m-12 {
    margin: 3rem;
  }

  .md-max\:m-14 {
    margin: 3.5rem;
  }

  .md-max\:m-16 {
    margin: 4rem;
  }

  .md-max\:m-20 {
    margin: 5rem;
  }

  .md-max\:m-24 {
    margin: 6rem;
  }

  .md-max\:m-28 {
    margin: 7rem;
  }

  .md-max\:m-32 {
    margin: 8rem;
  }

  .md-max\:m-36 {
    margin: 9rem;
  }

  .md-max\:m-40 {
    margin: 10rem;
  }

  .md-max\:m-44 {
    margin: 11rem;
  }

  .md-max\:m-48 {
    margin: 12rem;
  }

  .md-max\:m-52 {
    margin: 13rem;
  }

  .md-max\:m-56 {
    margin: 14rem;
  }

  .md-max\:m-60 {
    margin: 15rem;
  }

  .md-max\:m-64 {
    margin: 16rem;
  }

  .md-max\:m-72 {
    margin: 18rem;
  }

  .md-max\:m-80 {
    margin: 20rem;
  }

  .md-max\:m-96 {
    margin: 24rem;
  }

  .md-max\:m-auto {
    margin: auto;
  }

  .md-max\:m-px {
    margin: 1px;
  }

  .md-max\:m-0\.5 {
    margin: .125rem;
  }

  .md-max\:m-1\.5 {
    margin: .375rem;
  }

  .md-max\:m-2\.5 {
    margin: .625rem;
  }

  .md-max\:m-3\.5 {
    margin: .875rem;
  }

  .md-max\:-m-0 {
    margin: 0;
  }

  .md-max\:-m-1 {
    margin: -.25rem;
  }

  .md-max\:-m-2 {
    margin: -.5rem;
  }

  .md-max\:-m-3 {
    margin: -.75rem;
  }

  .md-max\:-m-4 {
    margin: -1rem;
  }

  .md-max\:-m-5 {
    margin: -1.25rem;
  }

  .md-max\:-m-6 {
    margin: -1.5rem;
  }

  .md-max\:-m-7 {
    margin: -1.75rem;
  }

  .md-max\:-m-8 {
    margin: -2rem;
  }

  .md-max\:-m-9 {
    margin: -2.25rem;
  }

  .md-max\:-m-10 {
    margin: -2.5rem;
  }

  .md-max\:-m-11 {
    margin: -2.75rem;
  }

  .md-max\:-m-12 {
    margin: -3rem;
  }

  .md-max\:-m-14 {
    margin: -3.5rem;
  }

  .md-max\:-m-16 {
    margin: -4rem;
  }

  .md-max\:-m-20 {
    margin: -5rem;
  }

  .md-max\:-m-24 {
    margin: -6rem;
  }

  .md-max\:-m-28 {
    margin: -7rem;
  }

  .md-max\:-m-32 {
    margin: -8rem;
  }

  .md-max\:-m-36 {
    margin: -9rem;
  }

  .md-max\:-m-40 {
    margin: -10rem;
  }

  .md-max\:-m-44 {
    margin: -11rem;
  }

  .md-max\:-m-48 {
    margin: -12rem;
  }

  .md-max\:-m-52 {
    margin: -13rem;
  }

  .md-max\:-m-56 {
    margin: -14rem;
  }

  .md-max\:-m-60 {
    margin: -15rem;
  }

  .md-max\:-m-64 {
    margin: -16rem;
  }

  .md-max\:-m-72 {
    margin: -18rem;
  }

  .md-max\:-m-80 {
    margin: -20rem;
  }

  .md-max\:-m-96 {
    margin: -24rem;
  }

  .md-max\:-m-px {
    margin: -1px;
  }

  .md-max\:-m-0\.5 {
    margin: -.125rem;
  }

  .md-max\:-m-1\.5 {
    margin: -.375rem;
  }

  .md-max\:-m-2\.5 {
    margin: -.625rem;
  }

  .md-max\:-m-3\.5 {
    margin: -.875rem;
  }

  .md-max\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md-max\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .md-max\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md-max\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md-max\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md-max\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md-max\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md-max\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .md-max\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md-max\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .md-max\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md-max\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .md-max\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md-max\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .md-max\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md-max\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md-max\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md-max\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .md-max\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md-max\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .md-max\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md-max\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .md-max\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md-max\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .md-max\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md-max\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .md-max\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md-max\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .md-max\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .md-max\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .md-max\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md-max\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md-max\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .md-max\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .md-max\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .md-max\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .md-max\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md-max\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .md-max\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .md-max\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .md-max\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md-max\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md-max\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md-max\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .md-max\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md-max\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .md-max\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md-max\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .md-max\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md-max\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .md-max\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md-max\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md-max\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md-max\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .md-max\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md-max\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .md-max\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md-max\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .md-max\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md-max\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .md-max\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md-max\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .md-max\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md-max\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .md-max\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .md-max\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .md-max\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md-max\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .md-max\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .md-max\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .md-max\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .md-max\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md-max\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .md-max\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .md-max\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md-max\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md-max\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md-max\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md-max\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .md-max\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md-max\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .md-max\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md-max\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .md-max\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md-max\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .md-max\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md-max\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md-max\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md-max\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .md-max\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md-max\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .md-max\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md-max\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .md-max\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md-max\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .md-max\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md-max\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .md-max\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md-max\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .md-max\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .md-max\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .md-max\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md-max\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md-max\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .md-max\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .md-max\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .md-max\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .md-max\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md-max\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .md-max\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .md-max\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .md-max\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md-max\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md-max\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md-max\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .md-max\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md-max\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .md-max\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md-max\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .md-max\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md-max\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .md-max\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md-max\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md-max\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md-max\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .md-max\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md-max\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .md-max\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md-max\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .md-max\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md-max\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .md-max\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md-max\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .md-max\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md-max\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .md-max\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .md-max\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .md-max\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md-max\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .md-max\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .md-max\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .md-max\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .md-max\:mt-0 {
    margin-top: 0;
  }

  .md-max\:mt-1 {
    margin-top: .25rem;
  }

  .md-max\:mt-2 {
    margin-top: .5rem;
  }

  .md-max\:mt-3 {
    margin-top: .75rem;
  }

  .md-max\:mt-4 {
    margin-top: 1rem;
  }

  .md-max\:mt-5 {
    margin-top: 1.25rem;
  }

  .md-max\:mt-6 {
    margin-top: 1.5rem;
  }

  .md-max\:mt-7 {
    margin-top: 1.75rem;
  }

  .md-max\:mt-8 {
    margin-top: 2rem;
  }

  .md-max\:mt-9 {
    margin-top: 2.25rem;
  }

  .md-max\:mt-10 {
    margin-top: 2.5rem;
  }

  .md-max\:mt-11 {
    margin-top: 2.75rem;
  }

  .md-max\:mt-12 {
    margin-top: 3rem;
  }

  .md-max\:mt-14 {
    margin-top: 3.5rem;
  }

  .md-max\:mt-16 {
    margin-top: 4rem;
  }

  .md-max\:mt-20 {
    margin-top: 5rem;
  }

  .md-max\:mt-24 {
    margin-top: 6rem;
  }

  .md-max\:mt-28 {
    margin-top: 7rem;
  }

  .md-max\:mt-32 {
    margin-top: 8rem;
  }

  .md-max\:mt-36 {
    margin-top: 9rem;
  }

  .md-max\:mt-40 {
    margin-top: 10rem;
  }

  .md-max\:mt-44 {
    margin-top: 11rem;
  }

  .md-max\:mt-48 {
    margin-top: 12rem;
  }

  .md-max\:mt-52 {
    margin-top: 13rem;
  }

  .md-max\:mt-56 {
    margin-top: 14rem;
  }

  .md-max\:mt-60 {
    margin-top: 15rem;
  }

  .md-max\:mt-64 {
    margin-top: 16rem;
  }

  .md-max\:mt-72 {
    margin-top: 18rem;
  }

  .md-max\:mt-80 {
    margin-top: 20rem;
  }

  .md-max\:mt-96 {
    margin-top: 24rem;
  }

  .md-max\:mt-auto {
    margin-top: auto;
  }

  .md-max\:mt-px {
    margin-top: 1px;
  }

  .md-max\:mt-0\.5 {
    margin-top: .125rem;
  }

  .md-max\:mt-1\.5 {
    margin-top: .375rem;
  }

  .md-max\:mt-2\.5 {
    margin-top: .625rem;
  }

  .md-max\:mt-3\.5 {
    margin-top: .875rem;
  }

  .md-max\:-mt-0 {
    margin-top: 0;
  }

  .md-max\:-mt-1 {
    margin-top: -.25rem;
  }

  .md-max\:-mt-2 {
    margin-top: -.5rem;
  }

  .md-max\:-mt-3 {
    margin-top: -.75rem;
  }

  .md-max\:-mt-4 {
    margin-top: -1rem;
  }

  .md-max\:-mt-5 {
    margin-top: -1.25rem;
  }

  .md-max\:-mt-6 {
    margin-top: -1.5rem;
  }

  .md-max\:-mt-7 {
    margin-top: -1.75rem;
  }

  .md-max\:-mt-8 {
    margin-top: -2rem;
  }

  .md-max\:-mt-9 {
    margin-top: -2.25rem;
  }

  .md-max\:-mt-10 {
    margin-top: -2.5rem;
  }

  .md-max\:-mt-11 {
    margin-top: -2.75rem;
  }

  .md-max\:-mt-12 {
    margin-top: -3rem;
  }

  .md-max\:-mt-14 {
    margin-top: -3.5rem;
  }

  .md-max\:-mt-16 {
    margin-top: -4rem;
  }

  .md-max\:-mt-20 {
    margin-top: -5rem;
  }

  .md-max\:-mt-24 {
    margin-top: -6rem;
  }

  .md-max\:-mt-28 {
    margin-top: -7rem;
  }

  .md-max\:-mt-32 {
    margin-top: -8rem;
  }

  .md-max\:-mt-36 {
    margin-top: -9rem;
  }

  .md-max\:-mt-40 {
    margin-top: -10rem;
  }

  .md-max\:-mt-44 {
    margin-top: -11rem;
  }

  .md-max\:-mt-48 {
    margin-top: -12rem;
  }

  .md-max\:-mt-52 {
    margin-top: -13rem;
  }

  .md-max\:-mt-56 {
    margin-top: -14rem;
  }

  .md-max\:-mt-60 {
    margin-top: -15rem;
  }

  .md-max\:-mt-64 {
    margin-top: -16rem;
  }

  .md-max\:-mt-72 {
    margin-top: -18rem;
  }

  .md-max\:-mt-80 {
    margin-top: -20rem;
  }

  .md-max\:-mt-96 {
    margin-top: -24rem;
  }

  .md-max\:-mt-px {
    margin-top: -1px;
  }

  .md-max\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .md-max\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .md-max\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .md-max\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .md-max\:mr-0 {
    margin-right: 0;
  }

  .md-max\:mr-1 {
    margin-right: .25rem;
  }

  .md-max\:mr-2 {
    margin-right: .5rem;
  }

  .md-max\:mr-3 {
    margin-right: .75rem;
  }

  .md-max\:mr-4 {
    margin-right: 1rem;
  }

  .md-max\:mr-5 {
    margin-right: 1.25rem;
  }

  .md-max\:mr-6 {
    margin-right: 1.5rem;
  }

  .md-max\:mr-7 {
    margin-right: 1.75rem;
  }

  .md-max\:mr-8 {
    margin-right: 2rem;
  }

  .md-max\:mr-9 {
    margin-right: 2.25rem;
  }

  .md-max\:mr-10 {
    margin-right: 2.5rem;
  }

  .md-max\:mr-11 {
    margin-right: 2.75rem;
  }

  .md-max\:mr-12 {
    margin-right: 3rem;
  }

  .md-max\:mr-14 {
    margin-right: 3.5rem;
  }

  .md-max\:mr-16 {
    margin-right: 4rem;
  }

  .md-max\:mr-20 {
    margin-right: 5rem;
  }

  .md-max\:mr-24 {
    margin-right: 6rem;
  }

  .md-max\:mr-28 {
    margin-right: 7rem;
  }

  .md-max\:mr-32 {
    margin-right: 8rem;
  }

  .md-max\:mr-36 {
    margin-right: 9rem;
  }

  .md-max\:mr-40 {
    margin-right: 10rem;
  }

  .md-max\:mr-44 {
    margin-right: 11rem;
  }

  .md-max\:mr-48 {
    margin-right: 12rem;
  }

  .md-max\:mr-52 {
    margin-right: 13rem;
  }

  .md-max\:mr-56 {
    margin-right: 14rem;
  }

  .md-max\:mr-60 {
    margin-right: 15rem;
  }

  .md-max\:mr-64 {
    margin-right: 16rem;
  }

  .md-max\:mr-72 {
    margin-right: 18rem;
  }

  .md-max\:mr-80 {
    margin-right: 20rem;
  }

  .md-max\:mr-96 {
    margin-right: 24rem;
  }

  .md-max\:mr-auto {
    margin-right: auto;
  }

  .md-max\:mr-px {
    margin-right: 1px;
  }

  .md-max\:mr-0\.5 {
    margin-right: .125rem;
  }

  .md-max\:mr-1\.5 {
    margin-right: .375rem;
  }

  .md-max\:mr-2\.5 {
    margin-right: .625rem;
  }

  .md-max\:mr-3\.5 {
    margin-right: .875rem;
  }

  .md-max\:-mr-0 {
    margin-right: 0;
  }

  .md-max\:-mr-1 {
    margin-right: -.25rem;
  }

  .md-max\:-mr-2 {
    margin-right: -.5rem;
  }

  .md-max\:-mr-3 {
    margin-right: -.75rem;
  }

  .md-max\:-mr-4 {
    margin-right: -1rem;
  }

  .md-max\:-mr-5 {
    margin-right: -1.25rem;
  }

  .md-max\:-mr-6 {
    margin-right: -1.5rem;
  }

  .md-max\:-mr-7 {
    margin-right: -1.75rem;
  }

  .md-max\:-mr-8 {
    margin-right: -2rem;
  }

  .md-max\:-mr-9 {
    margin-right: -2.25rem;
  }

  .md-max\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md-max\:-mr-11 {
    margin-right: -2.75rem;
  }

  .md-max\:-mr-12 {
    margin-right: -3rem;
  }

  .md-max\:-mr-14 {
    margin-right: -3.5rem;
  }

  .md-max\:-mr-16 {
    margin-right: -4rem;
  }

  .md-max\:-mr-20 {
    margin-right: -5rem;
  }

  .md-max\:-mr-24 {
    margin-right: -6rem;
  }

  .md-max\:-mr-28 {
    margin-right: -7rem;
  }

  .md-max\:-mr-32 {
    margin-right: -8rem;
  }

  .md-max\:-mr-36 {
    margin-right: -9rem;
  }

  .md-max\:-mr-40 {
    margin-right: -10rem;
  }

  .md-max\:-mr-44 {
    margin-right: -11rem;
  }

  .md-max\:-mr-48 {
    margin-right: -12rem;
  }

  .md-max\:-mr-52 {
    margin-right: -13rem;
  }

  .md-max\:-mr-56 {
    margin-right: -14rem;
  }

  .md-max\:-mr-60 {
    margin-right: -15rem;
  }

  .md-max\:-mr-64 {
    margin-right: -16rem;
  }

  .md-max\:-mr-72 {
    margin-right: -18rem;
  }

  .md-max\:-mr-80 {
    margin-right: -20rem;
  }

  .md-max\:-mr-96 {
    margin-right: -24rem;
  }

  .md-max\:-mr-px {
    margin-right: -1px;
  }

  .md-max\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .md-max\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .md-max\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .md-max\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .md-max\:mb-0 {
    margin-bottom: 0;
  }

  .md-max\:mb-1 {
    margin-bottom: .25rem;
  }

  .md-max\:mb-2 {
    margin-bottom: .5rem;
  }

  .md-max\:mb-3 {
    margin-bottom: .75rem;
  }

  .md-max\:mb-4 {
    margin-bottom: 1rem;
  }

  .md-max\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md-max\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md-max\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .md-max\:mb-8 {
    margin-bottom: 2rem;
  }

  .md-max\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .md-max\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md-max\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .md-max\:mb-12 {
    margin-bottom: 3rem;
  }

  .md-max\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md-max\:mb-16 {
    margin-bottom: 4rem;
  }

  .md-max\:mb-20 {
    margin-bottom: 5rem;
  }

  .md-max\:mb-24 {
    margin-bottom: 6rem;
  }

  .md-max\:mb-28 {
    margin-bottom: 7rem;
  }

  .md-max\:mb-32 {
    margin-bottom: 8rem;
  }

  .md-max\:mb-36 {
    margin-bottom: 9rem;
  }

  .md-max\:mb-40 {
    margin-bottom: 10rem;
  }

  .md-max\:mb-44 {
    margin-bottom: 11rem;
  }

  .md-max\:mb-48 {
    margin-bottom: 12rem;
  }

  .md-max\:mb-52 {
    margin-bottom: 13rem;
  }

  .md-max\:mb-56 {
    margin-bottom: 14rem;
  }

  .md-max\:mb-60 {
    margin-bottom: 15rem;
  }

  .md-max\:mb-64 {
    margin-bottom: 16rem;
  }

  .md-max\:mb-72 {
    margin-bottom: 18rem;
  }

  .md-max\:mb-80 {
    margin-bottom: 20rem;
  }

  .md-max\:mb-96 {
    margin-bottom: 24rem;
  }

  .md-max\:mb-auto {
    margin-bottom: auto;
  }

  .md-max\:mb-px {
    margin-bottom: 1px;
  }

  .md-max\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .md-max\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .md-max\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .md-max\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .md-max\:-mb-0 {
    margin-bottom: 0;
  }

  .md-max\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .md-max\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .md-max\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .md-max\:-mb-4 {
    margin-bottom: -1rem;
  }

  .md-max\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .md-max\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .md-max\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .md-max\:-mb-8 {
    margin-bottom: -2rem;
  }

  .md-max\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .md-max\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .md-max\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .md-max\:-mb-12 {
    margin-bottom: -3rem;
  }

  .md-max\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .md-max\:-mb-16 {
    margin-bottom: -4rem;
  }

  .md-max\:-mb-20 {
    margin-bottom: -5rem;
  }

  .md-max\:-mb-24 {
    margin-bottom: -6rem;
  }

  .md-max\:-mb-28 {
    margin-bottom: -7rem;
  }

  .md-max\:-mb-32 {
    margin-bottom: -8rem;
  }

  .md-max\:-mb-36 {
    margin-bottom: -9rem;
  }

  .md-max\:-mb-40 {
    margin-bottom: -10rem;
  }

  .md-max\:-mb-44 {
    margin-bottom: -11rem;
  }

  .md-max\:-mb-48 {
    margin-bottom: -12rem;
  }

  .md-max\:-mb-52 {
    margin-bottom: -13rem;
  }

  .md-max\:-mb-56 {
    margin-bottom: -14rem;
  }

  .md-max\:-mb-60 {
    margin-bottom: -15rem;
  }

  .md-max\:-mb-64 {
    margin-bottom: -16rem;
  }

  .md-max\:-mb-72 {
    margin-bottom: -18rem;
  }

  .md-max\:-mb-80 {
    margin-bottom: -20rem;
  }

  .md-max\:-mb-96 {
    margin-bottom: -24rem;
  }

  .md-max\:-mb-px {
    margin-bottom: -1px;
  }

  .md-max\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .md-max\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .md-max\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .md-max\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .md-max\:ml-0 {
    margin-left: 0;
  }

  .md-max\:ml-1 {
    margin-left: .25rem;
  }

  .md-max\:ml-2 {
    margin-left: .5rem;
  }

  .md-max\:ml-3 {
    margin-left: .75rem;
  }

  .md-max\:ml-4 {
    margin-left: 1rem;
  }

  .md-max\:ml-5 {
    margin-left: 1.25rem;
  }

  .md-max\:ml-6 {
    margin-left: 1.5rem;
  }

  .md-max\:ml-7 {
    margin-left: 1.75rem;
  }

  .md-max\:ml-8 {
    margin-left: 2rem;
  }

  .md-max\:ml-9 {
    margin-left: 2.25rem;
  }

  .md-max\:ml-10 {
    margin-left: 2.5rem;
  }

  .md-max\:ml-11 {
    margin-left: 2.75rem;
  }

  .md-max\:ml-12 {
    margin-left: 3rem;
  }

  .md-max\:ml-14 {
    margin-left: 3.5rem;
  }

  .md-max\:ml-16 {
    margin-left: 4rem;
  }

  .md-max\:ml-20 {
    margin-left: 5rem;
  }

  .md-max\:ml-24 {
    margin-left: 6rem;
  }

  .md-max\:ml-28 {
    margin-left: 7rem;
  }

  .md-max\:ml-32 {
    margin-left: 8rem;
  }

  .md-max\:ml-36 {
    margin-left: 9rem;
  }

  .md-max\:ml-40 {
    margin-left: 10rem;
  }

  .md-max\:ml-44 {
    margin-left: 11rem;
  }

  .md-max\:ml-48 {
    margin-left: 12rem;
  }

  .md-max\:ml-52 {
    margin-left: 13rem;
  }

  .md-max\:ml-56 {
    margin-left: 14rem;
  }

  .md-max\:ml-60 {
    margin-left: 15rem;
  }

  .md-max\:ml-64 {
    margin-left: 16rem;
  }

  .md-max\:ml-72 {
    margin-left: 18rem;
  }

  .md-max\:ml-80 {
    margin-left: 20rem;
  }

  .md-max\:ml-96 {
    margin-left: 24rem;
  }

  .md-max\:ml-auto {
    margin-left: auto;
  }

  .md-max\:ml-px {
    margin-left: 1px;
  }

  .md-max\:ml-0\.5 {
    margin-left: .125rem;
  }

  .md-max\:ml-1\.5 {
    margin-left: .375rem;
  }

  .md-max\:ml-2\.5 {
    margin-left: .625rem;
  }

  .md-max\:ml-3\.5 {
    margin-left: .875rem;
  }

  .md-max\:-ml-0 {
    margin-left: 0;
  }

  .md-max\:-ml-1 {
    margin-left: -.25rem;
  }

  .md-max\:-ml-2 {
    margin-left: -.5rem;
  }

  .md-max\:-ml-3 {
    margin-left: -.75rem;
  }

  .md-max\:-ml-4 {
    margin-left: -1rem;
  }

  .md-max\:-ml-5 {
    margin-left: -1.25rem;
  }

  .md-max\:-ml-6 {
    margin-left: -1.5rem;
  }

  .md-max\:-ml-7 {
    margin-left: -1.75rem;
  }

  .md-max\:-ml-8 {
    margin-left: -2rem;
  }

  .md-max\:-ml-9 {
    margin-left: -2.25rem;
  }

  .md-max\:-ml-10 {
    margin-left: -2.5rem;
  }

  .md-max\:-ml-11 {
    margin-left: -2.75rem;
  }

  .md-max\:-ml-12 {
    margin-left: -3rem;
  }

  .md-max\:-ml-14 {
    margin-left: -3.5rem;
  }

  .md-max\:-ml-16 {
    margin-left: -4rem;
  }

  .md-max\:-ml-20 {
    margin-left: -5rem;
  }

  .md-max\:-ml-24 {
    margin-left: -6rem;
  }

  .md-max\:-ml-28 {
    margin-left: -7rem;
  }

  .md-max\:-ml-32 {
    margin-left: -8rem;
  }

  .md-max\:-ml-36 {
    margin-left: -9rem;
  }

  .md-max\:-ml-40 {
    margin-left: -10rem;
  }

  .md-max\:-ml-44 {
    margin-left: -11rem;
  }

  .md-max\:-ml-48 {
    margin-left: -12rem;
  }

  .md-max\:-ml-52 {
    margin-left: -13rem;
  }

  .md-max\:-ml-56 {
    margin-left: -14rem;
  }

  .md-max\:-ml-60 {
    margin-left: -15rem;
  }

  .md-max\:-ml-64 {
    margin-left: -16rem;
  }

  .md-max\:-ml-72 {
    margin-left: -18rem;
  }

  .md-max\:-ml-80 {
    margin-left: -20rem;
  }

  .md-max\:-ml-96 {
    margin-left: -24rem;
  }

  .md-max\:-ml-px {
    margin-left: -1px;
  }

  .md-max\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .md-max\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .md-max\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .md-max\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .md-max\:box-border {
    box-sizing: border-box;
  }

  .md-max\:box-content {
    box-sizing: content-box;
  }

  .md-max\:block {
    display: block;
  }

  .md-max\:inline-block {
    display: inline-block;
  }

  .md-max\:inline {
    display: inline;
  }

  .md-max\:flex {
    display: flex;
  }

  .md-max\:inline-flex {
    display: inline-flex;
  }

  .md-max\:table {
    display: table;
  }

  .md-max\:inline-table {
    display: inline-table;
  }

  .md-max\:table-caption {
    display: table-caption;
  }

  .md-max\:table-cell {
    display: table-cell;
  }

  .md-max\:table-column {
    display: table-column;
  }

  .md-max\:table-column-group {
    display: table-column-group;
  }

  .md-max\:table-footer-group {
    display: table-footer-group;
  }

  .md-max\:table-header-group {
    display: table-header-group;
  }

  .md-max\:table-row-group {
    display: table-row-group;
  }

  .md-max\:table-row {
    display: table-row;
  }

  .md-max\:flow-root {
    display: flow-root;
  }

  .md-max\:grid {
    display: grid;
  }

  .md-max\:inline-grid {
    display: inline-grid;
  }

  .md-max\:contents {
    display: contents;
  }

  .md-max\:list-item {
    display: list-item;
  }

  .md-max\:hidden {
    display: none;
  }

  .md-max\:h-0 {
    height: 0;
  }

  .md-max\:h-1 {
    height: .25rem;
  }

  .md-max\:h-2 {
    height: .5rem;
  }

  .md-max\:h-3 {
    height: .75rem;
  }

  .md-max\:h-4 {
    height: 1rem;
  }

  .md-max\:h-5 {
    height: 1.25rem;
  }

  .md-max\:h-6 {
    height: 1.5rem;
  }

  .md-max\:h-7 {
    height: 1.75rem;
  }

  .md-max\:h-8 {
    height: 2rem;
  }

  .md-max\:h-9 {
    height: 2.25rem;
  }

  .md-max\:h-10 {
    height: 2.5rem;
  }

  .md-max\:h-11 {
    height: 2.75rem;
  }

  .md-max\:h-12 {
    height: 3rem;
  }

  .md-max\:h-14 {
    height: 3.5rem;
  }

  .md-max\:h-16 {
    height: 4rem;
  }

  .md-max\:h-20 {
    height: 5rem;
  }

  .md-max\:h-24 {
    height: 6rem;
  }

  .md-max\:h-28 {
    height: 7rem;
  }

  .md-max\:h-32 {
    height: 8rem;
  }

  .md-max\:h-36 {
    height: 9rem;
  }

  .md-max\:h-40 {
    height: 10rem;
  }

  .md-max\:h-44 {
    height: 11rem;
  }

  .md-max\:h-48 {
    height: 12rem;
  }

  .md-max\:h-52 {
    height: 13rem;
  }

  .md-max\:h-56 {
    height: 14rem;
  }

  .md-max\:h-60 {
    height: 15rem;
  }

  .md-max\:h-64 {
    height: 16rem;
  }

  .md-max\:h-72 {
    height: 18rem;
  }

  .md-max\:h-80 {
    height: 20rem;
  }

  .md-max\:h-96 {
    height: 24rem;
  }

  .md-max\:h-auto {
    height: auto;
  }

  .md-max\:h-px {
    height: 1px;
  }

  .md-max\:h-0\.5 {
    height: .125rem;
  }

  .md-max\:h-1\.5 {
    height: .375rem;
  }

  .md-max\:h-2\.5 {
    height: .625rem;
  }

  .md-max\:h-3\.5 {
    height: .875rem;
  }

  .md-max\:h-1\/2 {
    height: 50%;
  }

  .md-max\:h-1\/3 {
    height: 33.3333%;
  }

  .md-max\:h-2\/3 {
    height: 66.6667%;
  }

  .md-max\:h-1\/4 {
    height: 25%;
  }

  .md-max\:h-2\/4 {
    height: 50%;
  }

  .md-max\:h-3\/4 {
    height: 75%;
  }

  .md-max\:h-1\/5 {
    height: 20%;
  }

  .md-max\:h-2\/5 {
    height: 40%;
  }

  .md-max\:h-3\/5 {
    height: 60%;
  }

  .md-max\:h-4\/5 {
    height: 80%;
  }

  .md-max\:h-1\/6 {
    height: 16.6667%;
  }

  .md-max\:h-2\/6 {
    height: 33.3333%;
  }

  .md-max\:h-3\/6 {
    height: 50%;
  }

  .md-max\:h-4\/6 {
    height: 66.6667%;
  }

  .md-max\:h-5\/6 {
    height: 83.3333%;
  }

  .md-max\:h-full {
    height: 100%;
  }

  .md-max\:h-screen {
    height: 100vh;
  }

  .md-max\:max-h-0 {
    max-height: 0;
  }

  .md-max\:max-h-1 {
    max-height: .25rem;
  }

  .md-max\:max-h-2 {
    max-height: .5rem;
  }

  .md-max\:max-h-3 {
    max-height: .75rem;
  }

  .md-max\:max-h-4 {
    max-height: 1rem;
  }

  .md-max\:max-h-5 {
    max-height: 1.25rem;
  }

  .md-max\:max-h-6 {
    max-height: 1.5rem;
  }

  .md-max\:max-h-7 {
    max-height: 1.75rem;
  }

  .md-max\:max-h-8 {
    max-height: 2rem;
  }

  .md-max\:max-h-9 {
    max-height: 2.25rem;
  }

  .md-max\:max-h-10 {
    max-height: 2.5rem;
  }

  .md-max\:max-h-11 {
    max-height: 2.75rem;
  }

  .md-max\:max-h-12 {
    max-height: 3rem;
  }

  .md-max\:max-h-14 {
    max-height: 3.5rem;
  }

  .md-max\:max-h-16 {
    max-height: 4rem;
  }

  .md-max\:max-h-20 {
    max-height: 5rem;
  }

  .md-max\:max-h-24 {
    max-height: 6rem;
  }

  .md-max\:max-h-28 {
    max-height: 7rem;
  }

  .md-max\:max-h-32 {
    max-height: 8rem;
  }

  .md-max\:max-h-36 {
    max-height: 9rem;
  }

  .md-max\:max-h-40 {
    max-height: 10rem;
  }

  .md-max\:max-h-44 {
    max-height: 11rem;
  }

  .md-max\:max-h-48 {
    max-height: 12rem;
  }

  .md-max\:max-h-52 {
    max-height: 13rem;
  }

  .md-max\:max-h-56 {
    max-height: 14rem;
  }

  .md-max\:max-h-60 {
    max-height: 15rem;
  }

  .md-max\:max-h-64 {
    max-height: 16rem;
  }

  .md-max\:max-h-72 {
    max-height: 18rem;
  }

  .md-max\:max-h-80 {
    max-height: 20rem;
  }

  .md-max\:max-h-96 {
    max-height: 24rem;
  }

  .md-max\:max-h-px {
    max-height: 1px;
  }

  .md-max\:max-h-0\.5 {
    max-height: .125rem;
  }

  .md-max\:max-h-1\.5 {
    max-height: .375rem;
  }

  .md-max\:max-h-2\.5 {
    max-height: .625rem;
  }

  .md-max\:max-h-3\.5 {
    max-height: .875rem;
  }

  .md-max\:max-h-full {
    max-height: 100%;
  }

  .md-max\:max-h-screen {
    max-height: 100vh;
  }

  .md-max\:min-h-0 {
    min-height: 0;
  }

  .md-max\:min-h-full {
    min-height: 100%;
  }

  .md-max\:min-h-screen {
    min-height: 100vh;
  }

  .md-max\:w-0 {
    width: 0;
  }

  .md-max\:w-1 {
    width: .25rem;
  }

  .md-max\:w-2 {
    width: .5rem;
  }

  .md-max\:w-3 {
    width: .75rem;
  }

  .md-max\:w-4 {
    width: 1rem;
  }

  .md-max\:w-5 {
    width: 1.25rem;
  }

  .md-max\:w-6 {
    width: 1.5rem;
  }

  .md-max\:w-7 {
    width: 1.75rem;
  }

  .md-max\:w-8 {
    width: 2rem;
  }

  .md-max\:w-9 {
    width: 2.25rem;
  }

  .md-max\:w-10 {
    width: 2.5rem;
  }

  .md-max\:w-11 {
    width: 2.75rem;
  }

  .md-max\:w-12 {
    width: 3rem;
  }

  .md-max\:w-14 {
    width: 3.5rem;
  }

  .md-max\:w-16 {
    width: 4rem;
  }

  .md-max\:w-20 {
    width: 5rem;
  }

  .md-max\:w-24 {
    width: 6rem;
  }

  .md-max\:w-28 {
    width: 7rem;
  }

  .md-max\:w-32 {
    width: 8rem;
  }

  .md-max\:w-36 {
    width: 9rem;
  }

  .md-max\:w-40 {
    width: 10rem;
  }

  .md-max\:w-44 {
    width: 11rem;
  }

  .md-max\:w-48 {
    width: 12rem;
  }

  .md-max\:w-52 {
    width: 13rem;
  }

  .md-max\:w-56 {
    width: 14rem;
  }

  .md-max\:w-60 {
    width: 15rem;
  }

  .md-max\:w-64 {
    width: 16rem;
  }

  .md-max\:w-72 {
    width: 18rem;
  }

  .md-max\:w-80 {
    width: 20rem;
  }

  .md-max\:w-96 {
    width: 24rem;
  }

  .md-max\:w-auto {
    width: auto;
  }

  .md-max\:w-px {
    width: 1px;
  }

  .md-max\:w-0\.5 {
    width: .125rem;
  }

  .md-max\:w-1\.5 {
    width: .375rem;
  }

  .md-max\:w-2\.5 {
    width: .625rem;
  }

  .md-max\:w-3\.5 {
    width: .875rem;
  }

  .md-max\:w-1\/2 {
    width: 50%;
  }

  .md-max\:w-1\/3 {
    width: 33.3333%;
  }

  .md-max\:w-2\/3 {
    width: 66.6667%;
  }

  .md-max\:w-1\/4 {
    width: 25%;
  }

  .md-max\:w-2\/4 {
    width: 50%;
  }

  .md-max\:w-3\/4 {
    width: 75%;
  }

  .md-max\:w-1\/5 {
    width: 20%;
  }

  .md-max\:w-2\/5 {
    width: 40%;
  }

  .md-max\:w-3\/5 {
    width: 60%;
  }

  .md-max\:w-4\/5 {
    width: 80%;
  }

  .md-max\:w-1\/6 {
    width: 16.6667%;
  }

  .md-max\:w-2\/6 {
    width: 33.3333%;
  }

  .md-max\:w-3\/6 {
    width: 50%;
  }

  .md-max\:w-4\/6 {
    width: 66.6667%;
  }

  .md-max\:w-5\/6 {
    width: 83.3333%;
  }

  .md-max\:w-1\/12 {
    width: 8.33333%;
  }

  .md-max\:w-2\/12 {
    width: 16.6667%;
  }

  .md-max\:w-3\/12 {
    width: 25%;
  }

  .md-max\:w-4\/12 {
    width: 33.3333%;
  }

  .md-max\:w-5\/12 {
    width: 41.6667%;
  }

  .md-max\:w-6\/12 {
    width: 50%;
  }

  .md-max\:w-7\/12 {
    width: 58.3333%;
  }

  .md-max\:w-8\/12 {
    width: 66.6667%;
  }

  .md-max\:w-9\/12 {
    width: 75%;
  }

  .md-max\:w-10\/12 {
    width: 83.3333%;
  }

  .md-max\:w-11\/12 {
    width: 91.6667%;
  }

  .md-max\:w-full {
    width: 100%;
  }

  .md-max\:w-screen {
    width: 100vw;
  }

  .md-max\:w-min {
    width: min-content;
  }

  .md-max\:w-max {
    width: max-content;
  }

  .md-max\:min-w-0 {
    min-width: 0;
  }

  .md-max\:min-w-full {
    min-width: 100%;
  }

  .md-max\:min-w-min {
    min-width: min-content;
  }

  .md-max\:min-w-max {
    min-width: max-content;
  }

  .md-max\:max-w-0 {
    max-width: 0;
  }

  .md-max\:max-w-none {
    max-width: none;
  }

  .md-max\:max-w-xs {
    max-width: 20rem;
  }

  .md-max\:max-w-sm {
    max-width: 24rem;
  }

  .md-max\:max-w-md {
    max-width: 28rem;
  }

  .md-max\:max-w-lg {
    max-width: 32rem;
  }

  .md-max\:max-w-xl {
    max-width: 36rem;
  }

  .md-max\:max-w-2xl {
    max-width: 42rem;
  }

  .md-max\:max-w-3xl {
    max-width: 48rem;
  }

  .md-max\:max-w-4xl {
    max-width: 56rem;
  }

  .md-max\:max-w-5xl {
    max-width: 64rem;
  }

  .md-max\:max-w-6xl {
    max-width: 72rem;
  }

  .md-max\:max-w-7xl {
    max-width: 80rem;
  }

  .md-max\:max-w-full {
    max-width: 100%;
  }

  .md-max\:max-w-min {
    max-width: min-content;
  }

  .md-max\:max-w-max {
    max-width: max-content;
  }

  .md-max\:max-w-prose {
    max-width: 65ch;
  }

  .md-max\:max-w-screen-sm {
    max-width: 640px;
  }

  .md-max\:max-w-screen-md {
    max-width: 768px;
  }

  .md-max\:max-w-screen-lg {
    max-width: 1024px;
  }

  .md-max\:max-w-screen-xl {
    max-width: 1280px;
  }

  .md-max\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .md-max\:flex-1 {
    flex: 1;
  }

  .md-max\:flex-auto {
    flex: auto;
  }

  .md-max\:flex-initial {
    flex: 0 auto;
  }

  .md-max\:flex-none {
    flex: none;
  }

  .md-max\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md-max\:flex-shrink {
    flex-shrink: 1;
  }

  .md-max\:flex-grow-0 {
    flex-grow: 0;
  }

  .md-max\:flex-grow {
    flex-grow: 1;
  }

  .md-max\:table-auto {
    table-layout: auto;
  }

  .md-max\:table-fixed {
    table-layout: fixed;
  }

  .md-max\:border-collapse {
    border-collapse: collapse;
  }

  .md-max\:border-separate {
    border-collapse: separate;
  }

  .md-max\:origin-center {
    transform-origin: center;
  }

  .md-max\:origin-top {
    transform-origin: top;
  }

  .md-max\:origin-top-right {
    transform-origin: 100% 0;
  }

  .md-max\:origin-right {
    transform-origin: 100%;
  }

  .md-max\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .md-max\:origin-bottom {
    transform-origin: bottom;
  }

  .md-max\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .md-max\:origin-left {
    transform-origin: 0;
  }

  .md-max\:origin-top-left {
    transform-origin: 0 0;
  }

  .md-max\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md-max\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md-max\:transform-none {
    transform: none;
  }

  .md-max\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .md-max\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .md-max\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .md-max\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .md-max\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .md-max\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .md-max\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .md-max\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .md-max\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .md-max\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .md-max\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .md-max\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .md-max\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .md-max\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .md-max\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .md-max\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .md-max\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .md-max\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .md-max\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .md-max\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .md-max\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .md-max\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .md-max\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .md-max\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .md-max\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .md-max\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .md-max\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .md-max\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .md-max\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .md-max\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .md-max\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .md-max\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .md-max\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .md-max\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .md-max\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .md-max\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .md-max\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .md-max\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .md-max\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .md-max\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .md-max\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .md-max\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .md-max\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .md-max\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .md-max\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .md-max\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .md-max\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .md-max\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .md-max\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .md-max\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .md-max\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .md-max\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .md-max\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .md-max\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .md-max\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .md-max\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .md-max\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .md-max\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .md-max\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .md-max\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .md-max\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .md-max\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .md-max\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .md-max\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .md-max\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .md-max\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .md-max\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .md-max\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .md-max\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .md-max\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .md-max\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .md-max\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .md-max\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .md-max\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .md-max\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .md-max\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .md-max\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .md-max\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .md-max\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .md-max\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .md-max\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .md-max\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .md-max\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .md-max\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .md-max\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .md-max\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .md-max\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .md-max\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .md-max\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .md-max\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .md-max\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .md-max\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .md-max\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .md-max\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .md-max\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .md-max\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .md-max\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .md-max\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .md-max\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .md-max\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .md-max\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .md-max\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .md-max\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .md-max\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .md-max\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .md-max\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .md-max\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .md-max\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .md-max\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .md-max\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .md-max\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .md-max\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .md-max\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .md-max\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .md-max\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .md-max\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .md-max\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .md-max\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .md-max\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .md-max\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .md-max\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .md-max\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .md-max\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .md-max\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .md-max\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .md-max\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .md-max\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .md-max\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .md-max\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .md-max\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .md-max\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .md-max\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .md-max\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .md-max\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .md-max\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .md-max\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .md-max\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .md-max\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .md-max\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .md-max\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .md-max\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .md-max\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .md-max\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .md-max\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .md-max\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .md-max\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .md-max\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .md-max\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .md-max\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .md-max\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .md-max\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .md-max\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .md-max\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .md-max\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .md-max\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .md-max\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .md-max\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .md-max\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .md-max\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .md-max\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .md-max\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .md-max\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .md-max\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .md-max\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .md-max\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .md-max\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .md-max\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .md-max\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .md-max\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .md-max\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .md-max\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .md-max\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .md-max\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .md-max\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .md-max\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .md-max\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .md-max\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .md-max\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .md-max\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .md-max\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .md-max\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .md-max\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .md-max\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .md-max\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .md-max\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .md-max\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .md-max\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .md-max\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .md-max\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .md-max\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .md-max\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .md-max\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .md-max\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .md-max\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .md-max\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .md-max\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .md-max\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .md-max\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .md-max\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .md-max\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .md-max\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .md-max\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .md-max\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .md-max\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .md-max\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .md-max\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .md-max\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .md-max\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .md-max\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .md-max\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .md-max\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .md-max\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .md-max\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .md-max\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .md-max\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .md-max\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .md-max\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .md-max\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .md-max\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .md-max\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .md-max\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .md-max\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .md-max\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .md-max\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .md-max\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .md-max\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .md-max\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .md-max\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .md-max\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .md-max\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .md-max\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .md-max\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .md-max\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .md-max\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .md-max\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .md-max\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .md-max\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .md-max\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .md-max\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .md-max\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .md-max\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .md-max\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .md-max\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .md-max\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .md-max\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .md-max\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .md-max\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .md-max\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .md-max\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .md-max\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .md-max\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .md-max\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .md-max\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .md-max\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .md-max\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .md-max\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .md-max\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .md-max\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .md-max\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .md-max\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .md-max\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .md-max\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .md-max\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .md-max\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .md-max\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .md-max\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .md-max\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .md-max\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .md-max\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .md-max\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .md-max\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .md-max\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .md-max\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .md-max\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .md-max\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .md-max\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .md-max\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .md-max\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .md-max\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .md-max\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .md-max\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .md-max\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .md-max\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .md-max\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .md-max\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .md-max\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .md-max\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .md-max\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .md-max\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .md-max\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .md-max\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .md-max\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .md-max\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .md-max\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .md-max\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .md-max\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .md-max\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .md-max\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .md-max\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .md-max\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .md-max\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .md-max\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .md-max\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .md-max\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .md-max\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .md-max\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .md-max\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .md-max\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .md-max\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .md-max\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .md-max\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .md-max\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .md-max\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .md-max\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .md-max\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .md-max\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .md-max\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .md-max\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .md-max\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .md-max\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .md-max\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .md-max\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .md-max\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .md-max\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .md-max\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .md-max\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .md-max\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .md-max\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .md-max\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .md-max\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .md-max\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .md-max\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .md-max\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .md-max\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .md-max\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .md-max\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .md-max\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .md-max\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .md-max\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .md-max\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .md-max\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .md-max\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .md-max\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .md-max\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .md-max\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .md-max\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .md-max\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .md-max\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .md-max\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .md-max\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .md-max\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .md-max\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .md-max\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .md-max\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .md-max\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .md-max\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .md-max\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .md-max\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .md-max\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .md-max\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .md-max\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .md-max\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .md-max\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .md-max\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .md-max\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .md-max\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .md-max\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .md-max\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .md-max\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .md-max\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .md-max\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .md-max\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .md-max\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .md-max\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .md-max\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .md-max\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .md-max\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .md-max\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .md-max\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .md-max\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .md-max\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .md-max\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .md-max\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .md-max\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .md-max\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .md-max\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .md-max\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .md-max\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .md-max\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .md-max\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .md-max\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .md-max\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .md-max\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .md-max\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .md-max\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .md-max\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .md-max\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .md-max\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .md-max\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .md-max\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .md-max\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .md-max\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .md-max\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .md-max\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .md-max\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .md-max\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .md-max\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .md-max\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .md-max\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .md-max\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .md-max\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .md-max\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .md-max\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .md-max\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .md-max\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .md-max\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .md-max\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .md-max\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .md-max\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .md-max\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .md-max\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .md-max\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .md-max\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .md-max\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .md-max\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .md-max\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .md-max\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .md-max\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .md-max\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .md-max\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .md-max\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .md-max\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .md-max\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .md-max\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .md-max\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .md-max\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .md-max\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .md-max\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .md-max\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .md-max\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .md-max\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .md-max\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .md-max\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .md-max\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .md-max\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .md-max\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .md-max\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .md-max\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .md-max\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .md-max\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .md-max\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .md-max\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .md-max\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .md-max\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .md-max\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .md-max\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .md-max\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .md-max\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .md-max\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .md-max\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .md-max\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .md-max\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .md-max\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .md-max\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .md-max\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .md-max\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .md-max\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .md-max\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .md-max\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .md-max\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .md-max\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .md-max\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .md-max\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .md-max\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .md-max\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .md-max\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .md-max\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .md-max\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .md-max\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .md-max\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .md-max\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .md-max\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .md-max\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .md-max\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .md-max\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .md-max\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .md-max\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .md-max\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .md-max\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .md-max\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .md-max\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .md-max\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .md-max\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .md-max\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .md-max\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .md-max\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .md-max\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .md-max\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .md-max\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .md-max\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .md-max\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .md-max\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .md-max\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .md-max\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .md-max\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .md-max\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .md-max\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .md-max\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .md-max\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .md-max\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .md-max\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .md-max\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .md-max\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .md-max\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .md-max\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .md-max\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .md-max\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .md-max\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .md-max\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .md-max\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .md-max\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .md-max\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .md-max\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .md-max\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .md-max\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .md-max\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .md-max\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .md-max\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .md-max\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .md-max\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .md-max\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .md-max\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .md-max\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .md-max\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .md-max\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .md-max\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .md-max\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .md-max\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .md-max\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .md-max\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .md-max\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .md-max\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .md-max\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .md-max\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .md-max\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .md-max\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .md-max\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .md-max\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .md-max\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .md-max\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .md-max\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .md-max\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .md-max\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .md-max\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .md-max\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .md-max\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .md-max\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .md-max\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .md-max\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .md-max\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .md-max\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .md-max\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .md-max\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .md-max\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .md-max\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .md-max\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .md-max\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .md-max\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .md-max\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .md-max\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .md-max\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .md-max\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .md-max\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .md-max\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .md-max\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .md-max\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .md-max\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .md-max\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .md-max\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .md-max\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .md-max\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .md-max\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .md-max\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .md-max\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .md-max\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .md-max\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .md-max\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .md-max\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .md-max\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .md-max\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .md-max\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .md-max\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .md-max\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .md-max\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .md-max\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .md-max\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .md-max\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .md-max\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .md-max\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .md-max\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .md-max\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .md-max\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .md-max\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .md-max\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .md-max\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .md-max\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .md-max\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .md-max\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .md-max\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .md-max\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .md-max\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .md-max\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .md-max\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .md-max\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .md-max\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .md-max\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .md-max\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .md-max\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .md-max\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .md-max\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .md-max\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .md-max\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md-max\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md-max\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md-max\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md-max\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md-max\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md-max\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md-max\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md-max\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md-max\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md-max\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md-max\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md-max\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md-max\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md-max\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md-max\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md-max\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md-max\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md-max\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md-max\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md-max\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md-max\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md-max\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md-max\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md-max\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md-max\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md-max\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md-max\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md-max\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md-max\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md-max\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .md-max\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .md-max\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .md-max\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .md-max\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .md-max\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .md-max\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .md-max\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .md-max\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .md-max\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .md-max\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .md-max\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .md-max\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .md-max\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .md-max\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .md-max\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .md-max\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .md-max\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .md-max\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .md-max\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .md-max\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .md-max\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .md-max\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .md-max\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .md-max\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .md-max\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .md-max\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .md-max\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .md-max\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .md-max\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .md-max\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .md-max\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .md-max\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .md-max\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .md-max\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .md-max\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .md-max\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .md-max\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .md-max\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .md-max\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .md-max\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .md-max\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .md-max\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .md-max\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .md-max\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .md-max\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .md-max\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .md-max\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .md-max\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .md-max\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .md-max\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .md-max\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .md-max\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .md-max\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .md-max\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .md-max\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .md-max\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .md-max\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .md-max\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .md-max\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .md-max\:animate-none {
    animation: none;
  }

  .md-max\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .md-max\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .md-max\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .md-max\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .md-max\:cursor-auto {
    cursor: auto;
  }

  .md-max\:cursor-default {
    cursor: default;
  }

  .md-max\:cursor-pointer {
    cursor: pointer;
  }

  .md-max\:cursor-wait {
    cursor: wait;
  }

  .md-max\:cursor-text {
    cursor: text;
  }

  .md-max\:cursor-move {
    cursor: move;
  }

  .md-max\:cursor-help {
    cursor: help;
  }

  .md-max\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .md-max\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .md-max\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .md-max\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .md-max\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .md-max\:resize-none {
    resize: none;
  }

  .md-max\:resize-y {
    resize: vertical;
  }

  .md-max\:resize-x {
    resize: horizontal;
  }

  .md-max\:resize {
    resize: both;
  }

  .md-max\:list-inside {
    list-style-position: inside;
  }

  .md-max\:list-outside {
    list-style-position: outside;
  }

  .md-max\:list-none {
    list-style-type: none;
  }

  .md-max\:list-disc {
    list-style-type: disc;
  }

  .md-max\:list-decimal {
    list-style-type: decimal;
  }

  .md-max\:appearance-none {
    appearance: none;
  }

  .md-max\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md-max\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .md-max\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .md-max\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md-max\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md-max\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md-max\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md-max\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md-max\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md-max\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .md-max\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .md-max\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md-max\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md-max\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md-max\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md-max\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md-max\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md-max\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md-max\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md-max\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md-max\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md-max\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md-max\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md-max\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md-max\:grid-cols-none {
    grid-template-columns: none;
  }

  .md-max\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md-max\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md-max\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md-max\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md-max\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md-max\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md-max\:grid-rows-none {
    grid-template-rows: none;
  }

  .md-max\:flex-row {
    flex-direction: row;
  }

  .md-max\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md-max\:flex-col {
    flex-direction: column;
  }

  .md-max\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md-max\:flex-wrap {
    flex-wrap: wrap;
  }

  .md-max\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md-max\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md-max\:place-content-center {
    place-content: center;
  }

  .md-max\:place-content-start {
    place-content: start;
  }

  .md-max\:place-content-end {
    place-content: end;
  }

  .md-max\:place-content-between {
    place-content: space-between;
  }

  .md-max\:place-content-around {
    place-content: space-around;
  }

  .md-max\:place-content-evenly {
    place-content: space-evenly;
  }

  .md-max\:place-content-stretch {
    place-content: stretch;
  }

  .md-max\:place-items-start {
    place-items: start;
  }

  .md-max\:place-items-end {
    place-items: end;
  }

  .md-max\:place-items-center {
    place-items: center;
  }

  .md-max\:place-items-stretch {
    place-items: stretch stretch;
  }

  .md-max\:content-center {
    align-content: center;
  }

  .md-max\:content-start {
    align-content: flex-start;
  }

  .md-max\:content-end {
    align-content: flex-end;
  }

  .md-max\:content-between {
    align-content: space-between;
  }

  .md-max\:content-around {
    align-content: space-around;
  }

  .md-max\:content-evenly {
    align-content: space-evenly;
  }

  .md-max\:items-start {
    align-items: flex-start;
  }

  .md-max\:items-end {
    align-items: flex-end;
  }

  .md-max\:items-center {
    align-items: center;
  }

  .md-max\:items-baseline {
    align-items: baseline;
  }

  .md-max\:items-stretch {
    align-items: stretch;
  }

  .md-max\:justify-start {
    justify-content: flex-start;
  }

  .md-max\:justify-end {
    justify-content: flex-end;
  }

  .md-max\:justify-center {
    justify-content: center;
  }

  .md-max\:justify-between {
    justify-content: space-between;
  }

  .md-max\:justify-around {
    justify-content: space-around;
  }

  .md-max\:justify-evenly {
    justify-content: space-evenly;
  }

  .md-max\:justify-items-start {
    justify-items: start;
  }

  .md-max\:justify-items-end {
    justify-items: end;
  }

  .md-max\:justify-items-center {
    justify-items: center;
  }

  .md-max\:justify-items-stretch {
    justify-items: stretch;
  }

  .md-max\:gap-0 {
    gap: 0;
  }

  .md-max\:gap-1 {
    gap: .25rem;
  }

  .md-max\:gap-2 {
    gap: .5rem;
  }

  .md-max\:gap-3 {
    gap: .75rem;
  }

  .md-max\:gap-4 {
    gap: 1rem;
  }

  .md-max\:gap-5 {
    gap: 1.25rem;
  }

  .md-max\:gap-6 {
    gap: 1.5rem;
  }

  .md-max\:gap-7 {
    gap: 1.75rem;
  }

  .md-max\:gap-8 {
    gap: 2rem;
  }

  .md-max\:gap-9 {
    gap: 2.25rem;
  }

  .md-max\:gap-10 {
    gap: 2.5rem;
  }

  .md-max\:gap-11 {
    gap: 2.75rem;
  }

  .md-max\:gap-12 {
    gap: 3rem;
  }

  .md-max\:gap-14 {
    gap: 3.5rem;
  }

  .md-max\:gap-16 {
    gap: 4rem;
  }

  .md-max\:gap-20 {
    gap: 5rem;
  }

  .md-max\:gap-24 {
    gap: 6rem;
  }

  .md-max\:gap-28 {
    gap: 7rem;
  }

  .md-max\:gap-32 {
    gap: 8rem;
  }

  .md-max\:gap-36 {
    gap: 9rem;
  }

  .md-max\:gap-40 {
    gap: 10rem;
  }

  .md-max\:gap-44 {
    gap: 11rem;
  }

  .md-max\:gap-48 {
    gap: 12rem;
  }

  .md-max\:gap-52 {
    gap: 13rem;
  }

  .md-max\:gap-56 {
    gap: 14rem;
  }

  .md-max\:gap-60 {
    gap: 15rem;
  }

  .md-max\:gap-64 {
    gap: 16rem;
  }

  .md-max\:gap-72 {
    gap: 18rem;
  }

  .md-max\:gap-80 {
    gap: 20rem;
  }

  .md-max\:gap-96 {
    gap: 24rem;
  }

  .md-max\:gap-px {
    gap: 1px;
  }

  .md-max\:gap-0\.5 {
    gap: .125rem;
  }

  .md-max\:gap-1\.5 {
    gap: .375rem;
  }

  .md-max\:gap-2\.5 {
    gap: .625rem;
  }

  .md-max\:gap-3\.5 {
    gap: .875rem;
  }

  .md-max\:gap-x-0 {
    column-gap: 0;
  }

  .md-max\:gap-x-1 {
    column-gap: .25rem;
  }

  .md-max\:gap-x-2 {
    column-gap: .5rem;
  }

  .md-max\:gap-x-3 {
    column-gap: .75rem;
  }

  .md-max\:gap-x-4 {
    column-gap: 1rem;
  }

  .md-max\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .md-max\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md-max\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .md-max\:gap-x-8 {
    column-gap: 2rem;
  }

  .md-max\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .md-max\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md-max\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .md-max\:gap-x-12 {
    column-gap: 3rem;
  }

  .md-max\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .md-max\:gap-x-16 {
    column-gap: 4rem;
  }

  .md-max\:gap-x-20 {
    column-gap: 5rem;
  }

  .md-max\:gap-x-24 {
    column-gap: 6rem;
  }

  .md-max\:gap-x-28 {
    column-gap: 7rem;
  }

  .md-max\:gap-x-32 {
    column-gap: 8rem;
  }

  .md-max\:gap-x-36 {
    column-gap: 9rem;
  }

  .md-max\:gap-x-40 {
    column-gap: 10rem;
  }

  .md-max\:gap-x-44 {
    column-gap: 11rem;
  }

  .md-max\:gap-x-48 {
    column-gap: 12rem;
  }

  .md-max\:gap-x-52 {
    column-gap: 13rem;
  }

  .md-max\:gap-x-56 {
    column-gap: 14rem;
  }

  .md-max\:gap-x-60 {
    column-gap: 15rem;
  }

  .md-max\:gap-x-64 {
    column-gap: 16rem;
  }

  .md-max\:gap-x-72 {
    column-gap: 18rem;
  }

  .md-max\:gap-x-80 {
    column-gap: 20rem;
  }

  .md-max\:gap-x-96 {
    column-gap: 24rem;
  }

  .md-max\:gap-x-px {
    column-gap: 1px;
  }

  .md-max\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .md-max\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .md-max\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .md-max\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .md-max\:gap-y-0 {
    row-gap: 0;
  }

  .md-max\:gap-y-1 {
    row-gap: .25rem;
  }

  .md-max\:gap-y-2 {
    row-gap: .5rem;
  }

  .md-max\:gap-y-3 {
    row-gap: .75rem;
  }

  .md-max\:gap-y-4 {
    row-gap: 1rem;
  }

  .md-max\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .md-max\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .md-max\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .md-max\:gap-y-8 {
    row-gap: 2rem;
  }

  .md-max\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .md-max\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .md-max\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .md-max\:gap-y-12 {
    row-gap: 3rem;
  }

  .md-max\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .md-max\:gap-y-16 {
    row-gap: 4rem;
  }

  .md-max\:gap-y-20 {
    row-gap: 5rem;
  }

  .md-max\:gap-y-24 {
    row-gap: 6rem;
  }

  .md-max\:gap-y-28 {
    row-gap: 7rem;
  }

  .md-max\:gap-y-32 {
    row-gap: 8rem;
  }

  .md-max\:gap-y-36 {
    row-gap: 9rem;
  }

  .md-max\:gap-y-40 {
    row-gap: 10rem;
  }

  .md-max\:gap-y-44 {
    row-gap: 11rem;
  }

  .md-max\:gap-y-48 {
    row-gap: 12rem;
  }

  .md-max\:gap-y-52 {
    row-gap: 13rem;
  }

  .md-max\:gap-y-56 {
    row-gap: 14rem;
  }

  .md-max\:gap-y-60 {
    row-gap: 15rem;
  }

  .md-max\:gap-y-64 {
    row-gap: 16rem;
  }

  .md-max\:gap-y-72 {
    row-gap: 18rem;
  }

  .md-max\:gap-y-80 {
    row-gap: 20rem;
  }

  .md-max\:gap-y-96 {
    row-gap: 24rem;
  }

  .md-max\:gap-y-px {
    row-gap: 1px;
  }

  .md-max\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .md-max\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .md-max\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .md-max\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .md-max\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md-max\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .md-max\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .md-max\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .md-max\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .md-max\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md-max\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md-max\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md-max\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md-max\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md-max\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md-max\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .md-max\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .md-max\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .md-max\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .md-max\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .md-max\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .md-max\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .md-max\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .md-max\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .md-max\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .md-max\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .md-max\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .md-max\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .md-max\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .md-max\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .md-max\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .md-max\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .md-max\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .md-max\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .md-max\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .md-max\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .md-max\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .md-max\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .md-max\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .md-max\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .md-max\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .md-max\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .md-max\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .md-max\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .md-max\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .md-max\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .md-max\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .md-max\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .md-max\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .md-max\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .md-max\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .md-max\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .md-max\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .md-max\:place-self-auto {
    place-self: auto;
  }

  .md-max\:place-self-start {
    place-self: start;
  }

  .md-max\:place-self-end {
    place-self: end;
  }

  .md-max\:place-self-center {
    place-self: center;
  }

  .md-max\:place-self-stretch {
    place-self: stretch stretch;
  }

  .md-max\:self-auto {
    align-self: auto;
  }

  .md-max\:self-start {
    align-self: flex-start;
  }

  .md-max\:self-end {
    align-self: flex-end;
  }

  .md-max\:self-center {
    align-self: center;
  }

  .md-max\:self-stretch {
    align-self: stretch;
  }

  .md-max\:self-baseline {
    align-self: baseline;
  }

  .md-max\:justify-self-auto {
    justify-self: auto;
  }

  .md-max\:justify-self-start {
    justify-self: start;
  }

  .md-max\:justify-self-end {
    justify-self: end;
  }

  .md-max\:justify-self-center {
    justify-self: center;
  }

  .md-max\:justify-self-stretch {
    justify-self: stretch;
  }

  .md-max\:overflow-auto {
    overflow: auto;
  }

  .md-max\:overflow-hidden {
    overflow: hidden;
  }

  .md-max\:overflow-visible {
    overflow: visible;
  }

  .md-max\:overflow-scroll {
    overflow: scroll;
  }

  .md-max\:overflow-x-auto {
    overflow-x: auto;
  }

  .md-max\:overflow-y-auto {
    overflow-y: auto;
  }

  .md-max\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .md-max\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md-max\:overflow-x-visible {
    overflow-x: visible;
  }

  .md-max\:overflow-y-visible {
    overflow-y: visible;
  }

  .md-max\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .md-max\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .md-max\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .md-max\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .md-max\:overscroll-none {
    overscroll-behavior: none;
  }

  .md-max\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md-max\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md-max\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md-max\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md-max\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md-max\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md-max\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .md-max\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .md-max\:overflow-clip {
    text-overflow: clip;
  }

  .md-max\:whitespace-normal {
    white-space: normal;
  }

  .md-max\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md-max\:whitespace-pre {
    white-space: pre;
  }

  .md-max\:whitespace-pre-line {
    white-space: pre-line;
  }

  .md-max\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md-max\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .md-max\:break-words {
    overflow-wrap: break-word;
  }

  .md-max\:break-all {
    word-break: break-all;
  }

  .md-max\:rounded-none {
    border-radius: 0;
  }

  .md-max\:rounded-sm {
    border-radius: .125rem;
  }

  .md-max\:rounded {
    border-radius: .25rem;
  }

  .md-max\:rounded-md {
    border-radius: .375rem;
  }

  .md-max\:rounded-lg {
    border-radius: .5rem;
  }

  .md-max\:rounded-xl {
    border-radius: .75rem;
  }

  .md-max\:rounded-2xl {
    border-radius: 1rem;
  }

  .md-max\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md-max\:rounded-full {
    border-radius: 9999px;
  }

  .md-max\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md-max\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .md-max\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .md-max\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .md-max\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .md-max\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .md-max\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md-max\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md-max\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md-max\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md-max\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .md-max\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .md-max\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .md-max\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md-max\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .md-max\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md-max\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md-max\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md-max\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md-max\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md-max\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md-max\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md-max\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md-max\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md-max\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md-max\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md-max\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md-max\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md-max\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md-max\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md-max\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md-max\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md-max\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md-max\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md-max\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md-max\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md-max\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md-max\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .md-max\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .md-max\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .md-max\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .md-max\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .md-max\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md-max\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md-max\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md-max\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md-max\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .md-max\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .md-max\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .md-max\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .md-max\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .md-max\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md-max\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md-max\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md-max\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md-max\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .md-max\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .md-max\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .md-max\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .md-max\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .md-max\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md-max\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md-max\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md-max\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md-max\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .md-max\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .md-max\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .md-max\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .md-max\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .md-max\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md-max\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md-max\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md-max\:border-0 {
    border-width: 0;
  }

  .md-max\:border-2 {
    border-width: 2px;
  }

  .md-max\:border-4 {
    border-width: 4px;
  }

  .md-max\:border-8 {
    border-width: 8px;
  }

  .md-max\:border {
    border-width: 1px;
  }

  .md-max\:border-t-0 {
    border-top-width: 0;
  }

  .md-max\:border-t-2 {
    border-top-width: 2px;
  }

  .md-max\:border-t-4 {
    border-top-width: 4px;
  }

  .md-max\:border-t-8 {
    border-top-width: 8px;
  }

  .md-max\:border-t {
    border-top-width: 1px;
  }

  .md-max\:border-r-0 {
    border-right-width: 0;
  }

  .md-max\:border-r-2 {
    border-right-width: 2px;
  }

  .md-max\:border-r-4 {
    border-right-width: 4px;
  }

  .md-max\:border-r-8 {
    border-right-width: 8px;
  }

  .md-max\:border-r {
    border-right-width: 1px;
  }

  .md-max\:border-b-0 {
    border-bottom-width: 0;
  }

  .md-max\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md-max\:border-b-4 {
    border-bottom-width: 4px;
  }

  .md-max\:border-b-8 {
    border-bottom-width: 8px;
  }

  .md-max\:border-b {
    border-bottom-width: 1px;
  }

  .md-max\:border-l-0 {
    border-left-width: 0;
  }

  .md-max\:border-l-2 {
    border-left-width: 2px;
  }

  .md-max\:border-l-4 {
    border-left-width: 4px;
  }

  .md-max\:border-l-8 {
    border-left-width: 8px;
  }

  .md-max\:border-l {
    border-left-width: 1px;
  }

  .md-max\:border-solid {
    border-style: solid;
  }

  .md-max\:border-dashed {
    border-style: dashed;
  }

  .md-max\:border-dotted {
    border-style: dotted;
  }

  .md-max\:border-double {
    border-style: double;
  }

  .md-max\:border-none {
    border-style: none;
  }

  .md-max\:border-transparent {
    border-color: #0000;
  }

  .md-max\:border-current {
    border-color: currentColor;
  }

  .md-max\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md-max\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md-max\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md-max\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md-max\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md-max\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md-max\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md-max\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md-max\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md-max\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md-max\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md-max\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md-max\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md-max\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md-max\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md-max\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md-max\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md-max\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md-max\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md-max\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md-max\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md-max\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md-max\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md-max\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md-max\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md-max\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md-max\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md-max\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md-max\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md-max\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md-max\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md-max\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md-max\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md-max\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md-max\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md-max\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md-max\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md-max\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md-max\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md-max\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md-max\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md-max\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md-max\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md-max\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md-max\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md-max\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md-max\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md-max\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md-max\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md-max\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md-max\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md-max\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md-max\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md-max\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .md-max\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .md-max\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .md-max\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .md-max\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .md-max\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md-max\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .md-max\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .md-max\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md-max\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .md-max\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .md-max\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .md-max\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .md-max\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .md-max\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .md-max\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .md-max\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .md-max\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .md-max\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .md-max\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .md-max\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .md-max\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .md-max\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .md-max\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .md-max\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .md-max\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .md-max\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .md-max\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .md-max\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .md-max\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .md-max\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .md-max\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .md-max\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .md-max\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .md-max\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .md-max\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .md-max\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .md-max\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .md-max\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .md-max\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .md-max\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .md-max\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .md-max\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .md-max\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .md-max\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .md-max\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .md-max\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .md-max\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .md-max\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .md-max\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .md-max\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .md-max\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .md-max\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .md-max\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .md-max\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .md-max\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .md-max\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .md-max\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .md-max\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .md-max\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .md-max\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .md-max\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .md-max\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .md-max\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .md-max\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .md-max\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .md-max\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .md-max\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .md-max\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .md-max\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .md-max\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .md-max\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .md-max\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .md-max\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .md-max\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .md-max\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .md-max\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .md-max\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .md-max\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .md-max\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .md-max\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .md-max\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .md-max\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .md-max\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .md-max\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .md-max\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .md-max\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .md-max\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .md-max\:bg-transparent {
    background-color: #0000;
  }

  .md-max\:bg-current {
    background-color: currentColor;
  }

  .md-max\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md-max\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md-max\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md-max\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md-max\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md-max\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md-max\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md-max\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md-max\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .md-max\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .md-max\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .md-max\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .md-max\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .md-max\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md-max\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .md-max\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .md-max\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md-max\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .md-max\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .md-max\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .md-max\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .md-max\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .md-max\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .md-max\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .md-max\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .md-max\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .md-max\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .md-max\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .md-max\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .md-max\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .md-max\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .md-max\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .md-max\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .md-max\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .md-max\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .md-max\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .md-max\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .md-max\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .md-max\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .md-max\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .md-max\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .md-max\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .md-max\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .md-max\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .md-max\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .md-max\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .md-max\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .md-max\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .md-max\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .md-max\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .md-max\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .md-max\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .md-max\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .md-max\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .md-max\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .md-max\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .md-max\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .md-max\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .md-max\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .md-max\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .md-max\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .md-max\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .md-max\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .md-max\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .md-max\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .md-max\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .md-max\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .md-max\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .md-max\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .md-max\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .md-max\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .md-max\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .md-max\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .md-max\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .md-max\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .md-max\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .md-max\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .md-max\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .md-max\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .md-max\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .md-max\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .md-max\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .md-max\:bg-none {
    background-image: none;
  }

  .md-max\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .md-max\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .md-max\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md-max\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md-max\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md-max\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md-max\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .md-max\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .md-max\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .md-max\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .md-max\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .md-max\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .md-max\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .md-max\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .md-max\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .md-max\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .md-max\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .md-max\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .md-max\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .md-max\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .md-max\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .md-max\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .md-max\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .md-max\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .md-max\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .md-max\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .md-max\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .md-max\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .md-max\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .md-max\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .md-max\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .md-max\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .md-max\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .md-max\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .md-max\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .md-max\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .md-max\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .md-max\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .md-max\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .md-max\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .md-max\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .md-max\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .md-max\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .md-max\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .md-max\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .md-max\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .md-max\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .md-max\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .md-max\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .md-max\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .md-max\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .md-max\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .md-max\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .md-max\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .md-max\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .md-max\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .md-max\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .md-max\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .md-max\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .md-max\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .md-max\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .md-max\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .md-max\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .md-max\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .md-max\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .md-max\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .md-max\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .md-max\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .md-max\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .md-max\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .md-max\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .md-max\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .md-max\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .md-max\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .md-max\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .md-max\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .md-max\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .md-max\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md-max\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md-max\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md-max\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md-max\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .md-max\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .md-max\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .md-max\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .md-max\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .md-max\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .md-max\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .md-max\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .md-max\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .md-max\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .md-max\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .md-max\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .md-max\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .md-max\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .md-max\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .md-max\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .md-max\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .md-max\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .md-max\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .md-max\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .md-max\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .md-max\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .md-max\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .md-max\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .md-max\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .md-max\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .md-max\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .md-max\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .md-max\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .md-max\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .md-max\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .md-max\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .md-max\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .md-max\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .md-max\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .md-max\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .md-max\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .md-max\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .md-max\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .md-max\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .md-max\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .md-max\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .md-max\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .md-max\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .md-max\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .md-max\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .md-max\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .md-max\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .md-max\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .md-max\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .md-max\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .md-max\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .md-max\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .md-max\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .md-max\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .md-max\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .md-max\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .md-max\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .md-max\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .md-max\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .md-max\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .md-max\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .md-max\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .md-max\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .md-max\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .md-max\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .md-max\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .md-max\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .md-max\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .md-max\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .md-max\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .md-max\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md-max\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md-max\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .md-max\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .md-max\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .md-max\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .md-max\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .md-max\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .md-max\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .md-max\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .md-max\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .md-max\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .md-max\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .md-max\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .md-max\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .md-max\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .md-max\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .md-max\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .md-max\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .md-max\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .md-max\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .md-max\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .md-max\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .md-max\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .md-max\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .md-max\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .md-max\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .md-max\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .md-max\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .md-max\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .md-max\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .md-max\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .md-max\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .md-max\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .md-max\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .md-max\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .md-max\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .md-max\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .md-max\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .md-max\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .md-max\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .md-max\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .md-max\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .md-max\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .md-max\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .md-max\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .md-max\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .md-max\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .md-max\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .md-max\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .md-max\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .md-max\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .md-max\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .md-max\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .md-max\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .md-max\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .md-max\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .md-max\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .md-max\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .md-max\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .md-max\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .md-max\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .md-max\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .md-max\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .md-max\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .md-max\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .md-max\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .md-max\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .md-max\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .md-max\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .md-max\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .md-max\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .md-max\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .md-max\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .md-max\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .md-max\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .md-max\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .md-max\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .md-max\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .md-max\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .md-max\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .md-max\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .md-max\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .md-max\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .md-max\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .md-max\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .md-max\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .md-max\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .md-max\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .md-max\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .md-max\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .md-max\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .md-max\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .md-max\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .md-max\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .md-max\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .md-max\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .md-max\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .md-max\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .md-max\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .md-max\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .md-max\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .md-max\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .md-max\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .md-max\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .md-max\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .md-max\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .md-max\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .md-max\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .md-max\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .md-max\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .md-max\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .md-max\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .md-max\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .md-max\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .md-max\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .md-max\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .md-max\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .md-max\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .md-max\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .md-max\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .md-max\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .md-max\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .md-max\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .md-max\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .md-max\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .md-max\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .md-max\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .md-max\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .md-max\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .md-max\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .md-max\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .md-max\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .md-max\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .md-max\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .md-max\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .md-max\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .md-max\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .md-max\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .md-max\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .md-max\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .md-max\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .md-max\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .md-max\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .md-max\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .md-max\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .md-max\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .md-max\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .md-max\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .md-max\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .md-max\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .md-max\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .md-max\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .md-max\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .md-max\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .md-max\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .md-max\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .md-max\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .md-max\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .md-max\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .md-max\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .md-max\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .md-max\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .md-max\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .md-max\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .md-max\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .md-max\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .md-max\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .md-max\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .md-max\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .md-max\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .md-max\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .md-max\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .md-max\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .md-max\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .md-max\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .md-max\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .md-max\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .md-max\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .md-max\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .md-max\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .md-max\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .md-max\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .md-max\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .md-max\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .md-max\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .md-max\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .md-max\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .md-max\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .md-max\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .md-max\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .md-max\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .md-max\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .md-max\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .md-max\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .md-max\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .md-max\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .md-max\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .md-max\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .md-max\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .md-max\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .md-max\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .md-max\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .md-max\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .md-max\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .md-max\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .md-max\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .md-max\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .md-max\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .md-max\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .md-max\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .md-max\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .md-max\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .md-max\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .md-max\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .md-max\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .md-max\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .md-max\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .md-max\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .md-max\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .md-max\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .md-max\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .md-max\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .md-max\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .md-max\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .md-max\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .md-max\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .md-max\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .md-max\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .md-max\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .md-max\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .md-max\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .md-max\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .md-max\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .md-max\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .md-max\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .md-max\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .md-max\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .md-max\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .md-max\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .md-max\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .md-max\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .md-max\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .md-max\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .md-max\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .md-max\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .md-max\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .md-max\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .md-max\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .md-max\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .md-max\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .md-max\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .md-max\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .md-max\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .md-max\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .md-max\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .md-max\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .md-max\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .md-max\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .md-max\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .md-max\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .md-max\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .md-max\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .md-max\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .md-max\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .md-max\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .md-max\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .md-max\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .md-max\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .md-max\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .md-max\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .md-max\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .md-max\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .md-max\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .md-max\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .md-max\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .md-max\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .md-max\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .md-max\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .md-max\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .md-max\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .md-max\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .md-max\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .md-max\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .md-max\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .md-max\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .md-max\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .md-max\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .md-max\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .md-max\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .md-max\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .md-max\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .md-max\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .md-max\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .md-max\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .md-max\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .md-max\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .md-max\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .md-max\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .md-max\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .md-max\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .md-max\:to-current {
    --tw-gradient-to: currentColor;
  }

  .md-max\:to-black {
    --tw-gradient-to: #000;
  }

  .md-max\:to-white {
    --tw-gradient-to: #fff;
  }

  .md-max\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .md-max\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .md-max\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .md-max\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .md-max\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .md-max\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .md-max\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .md-max\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .md-max\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .md-max\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .md-max\:to-red {
    --tw-gradient-to: red;
  }

  .md-max\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .md-max\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .md-max\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .md-max\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .md-max\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .md-max\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .md-max\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .md-max\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .md-max\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .md-max\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .md-max\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .md-max\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .md-max\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .md-max\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .md-max\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .md-max\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .md-max\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .md-max\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .md-max\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .md-max\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .md-max\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .md-max\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .md-max\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .md-max\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .md-max\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .md-max\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .md-max\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .md-max\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .md-max\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .md-max\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .md-max\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .md-max\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .md-max\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .md-max\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .md-max\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .md-max\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .md-max\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .md-max\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .md-max\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .md-max\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .md-max\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .md-max\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .md-max\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .md-max\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .md-max\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .md-max\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .md-max\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .md-max\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .md-max\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .md-max\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .md-max\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .md-max\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .md-max\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .md-max\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .md-max\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .md-max\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .md-max\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .md-max\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .md-max\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .md-max\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .md-max\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .md-max\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .md-max\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .md-max\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .md-max\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .md-max\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .md-max\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .md-max\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .md-max\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .md-max\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .md-max\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .md-max\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .md-max\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .md-max\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .md-max\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .md-max\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .md-max\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .md-max\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .md-max\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .md-max\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .md-max\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .md-max\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .md-max\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .md-max\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .md-max\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .md-max\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .md-max\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .md-max\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .md-max\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .md-max\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .md-max\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .md-max\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .md-max\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .md-max\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .md-max\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .md-max\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .md-max\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .md-max\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .md-max\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .md-max\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .md-max\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .md-max\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .md-max\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .md-max\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .md-max\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .md-max\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .md-max\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .md-max\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .md-max\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .md-max\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .md-max\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .md-max\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .md-max\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .md-max\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .md-max\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .md-max\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .md-max\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .md-max\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .md-max\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .md-max\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .md-max\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .md-max\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .md-max\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .md-max\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .md-max\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .md-max\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .md-max\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .md-max\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .md-max\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .md-max\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .md-max\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .md-max\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .md-max\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .md-max\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .md-max\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .md-max\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .md-max\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .md-max\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .md-max\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .md-max\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .md-max\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .md-max\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .md-max\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .md-max\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .md-max\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .md-max\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .md-max\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .md-max\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .md-max\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .md-max\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .md-max\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .md-max\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .md-max\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .md-max\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .md-max\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .md-max\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .md-max\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .md-max\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .md-max\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .md-max\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .md-max\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .md-max\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .md-max\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .md-max\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .md-max\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .md-max\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .md-max\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .md-max\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .md-max\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .md-max\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .md-max\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .md-max\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .md-max\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .md-max\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .md-max\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .md-max\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .md-max\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .md-max\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .md-max\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .md-max\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .md-max\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .md-max\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .md-max\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .md-max\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .md-max\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .md-max\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .md-max\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .md-max\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .md-max\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .md-max\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .md-max\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .md-max\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .md-max\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .md-max\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .md-max\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .md-max\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .md-max\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .md-max\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .md-max\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .md-max\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .md-max\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .md-max\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .md-max\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .md-max\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .md-max\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .md-max\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .md-max\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .md-max\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .md-max\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .md-max\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .md-max\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .md-max\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .md-max\:bg-auto {
    background-size: auto;
  }

  .md-max\:bg-cover {
    background-size: cover;
  }

  .md-max\:bg-contain {
    background-size: contain;
  }

  .md-max\:bg-fixed {
    background-attachment: fixed;
  }

  .md-max\:bg-local {
    background-attachment: local;
  }

  .md-max\:bg-scroll {
    background-attachment: scroll;
  }

  .md-max\:bg-clip-border {
    background-clip: border-box;
  }

  .md-max\:bg-clip-padding {
    background-clip: padding-box;
  }

  .md-max\:bg-clip-content {
    background-clip: content-box;
  }

  .md-max\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .md-max\:bg-bottom {
    background-position: bottom;
  }

  .md-max\:bg-center {
    background-position: center;
  }

  .md-max\:bg-left {
    background-position: 0;
  }

  .md-max\:bg-left-bottom {
    background-position: 0 100%;
  }

  .md-max\:bg-left-top {
    background-position: 0 0;
  }

  .md-max\:bg-right {
    background-position: 100%;
  }

  .md-max\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .md-max\:bg-right-top {
    background-position: 100% 0;
  }

  .md-max\:bg-top {
    background-position: top;
  }

  .md-max\:bg-repeat {
    background-repeat: repeat;
  }

  .md-max\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md-max\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md-max\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md-max\:bg-repeat-round {
    background-repeat: round;
  }

  .md-max\:bg-repeat-space {
    background-repeat: space;
  }

  .md-max\:bg-origin-border {
    background-origin: border-box;
  }

  .md-max\:bg-origin-padding {
    background-origin: padding-box;
  }

  .md-max\:bg-origin-content {
    background-origin: content-box;
  }

  .md-max\:fill-current {
    fill: currentColor;
  }

  .md-max\:stroke-current {
    stroke: currentColor;
  }

  .md-max\:stroke-0 {
    stroke-width: 0;
  }

  .md-max\:stroke-1 {
    stroke-width: 1px;
  }

  .md-max\:stroke-2 {
    stroke-width: 2px;
  }

  .md-max\:object-contain {
    object-fit: contain;
  }

  .md-max\:object-cover {
    object-fit: cover;
  }

  .md-max\:object-fill {
    object-fit: fill;
  }

  .md-max\:object-none {
    object-fit: none;
  }

  .md-max\:object-scale-down {
    object-fit: scale-down;
  }

  .md-max\:object-bottom {
    object-position: bottom;
  }

  .md-max\:object-center {
    object-position: center;
  }

  .md-max\:object-left {
    object-position: left;
  }

  .md-max\:object-left-bottom {
    object-position: left bottom;
  }

  .md-max\:object-left-top {
    object-position: left top;
  }

  .md-max\:object-right {
    object-position: right;
  }

  .md-max\:object-right-bottom {
    object-position: right bottom;
  }

  .md-max\:object-right-top {
    object-position: right top;
  }

  .md-max\:object-top {
    object-position: top;
  }

  .md-max\:p-0 {
    padding: 0;
  }

  .md-max\:p-1 {
    padding: .25rem;
  }

  .md-max\:p-2 {
    padding: .5rem;
  }

  .md-max\:p-3 {
    padding: .75rem;
  }

  .md-max\:p-4 {
    padding: 1rem;
  }

  .md-max\:p-5 {
    padding: 1.25rem;
  }

  .md-max\:p-6 {
    padding: 1.5rem;
  }

  .md-max\:p-7 {
    padding: 1.75rem;
  }

  .md-max\:p-8 {
    padding: 2rem;
  }

  .md-max\:p-9 {
    padding: 2.25rem;
  }

  .md-max\:p-10 {
    padding: 2.5rem;
  }

  .md-max\:p-11 {
    padding: 2.75rem;
  }

  .md-max\:p-12 {
    padding: 3rem;
  }

  .md-max\:p-14 {
    padding: 3.5rem;
  }

  .md-max\:p-16 {
    padding: 4rem;
  }

  .md-max\:p-20 {
    padding: 5rem;
  }

  .md-max\:p-24 {
    padding: 6rem;
  }

  .md-max\:p-28 {
    padding: 7rem;
  }

  .md-max\:p-32 {
    padding: 8rem;
  }

  .md-max\:p-36 {
    padding: 9rem;
  }

  .md-max\:p-40 {
    padding: 10rem;
  }

  .md-max\:p-44 {
    padding: 11rem;
  }

  .md-max\:p-48 {
    padding: 12rem;
  }

  .md-max\:p-52 {
    padding: 13rem;
  }

  .md-max\:p-56 {
    padding: 14rem;
  }

  .md-max\:p-60 {
    padding: 15rem;
  }

  .md-max\:p-64 {
    padding: 16rem;
  }

  .md-max\:p-72 {
    padding: 18rem;
  }

  .md-max\:p-80 {
    padding: 20rem;
  }

  .md-max\:p-96 {
    padding: 24rem;
  }

  .md-max\:p-px {
    padding: 1px;
  }

  .md-max\:p-0\.5 {
    padding: .125rem;
  }

  .md-max\:p-1\.5 {
    padding: .375rem;
  }

  .md-max\:p-2\.5 {
    padding: .625rem;
  }

  .md-max\:p-3\.5 {
    padding: .875rem;
  }

  .md-max\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md-max\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .md-max\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md-max\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md-max\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md-max\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md-max\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md-max\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md-max\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md-max\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md-max\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md-max\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md-max\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md-max\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md-max\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md-max\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md-max\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md-max\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md-max\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md-max\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .md-max\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md-max\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .md-max\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md-max\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .md-max\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md-max\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .md-max\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md-max\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .md-max\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .md-max\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .md-max\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md-max\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .md-max\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .md-max\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .md-max\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .md-max\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md-max\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .md-max\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md-max\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md-max\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md-max\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md-max\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-max\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md-max\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md-max\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .md-max\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md-max\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .md-max\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md-max\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md-max\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md-max\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md-max\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md-max\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md-max\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md-max\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .md-max\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md-max\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .md-max\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md-max\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .md-max\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md-max\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .md-max\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md-max\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .md-max\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .md-max\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .md-max\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md-max\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .md-max\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .md-max\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .md-max\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .md-max\:pt-0 {
    padding-top: 0;
  }

  .md-max\:pt-1 {
    padding-top: .25rem;
  }

  .md-max\:pt-2 {
    padding-top: .5rem;
  }

  .md-max\:pt-3 {
    padding-top: .75rem;
  }

  .md-max\:pt-4 {
    padding-top: 1rem;
  }

  .md-max\:pt-5 {
    padding-top: 1.25rem;
  }

  .md-max\:pt-6 {
    padding-top: 1.5rem;
  }

  .md-max\:pt-7 {
    padding-top: 1.75rem;
  }

  .md-max\:pt-8 {
    padding-top: 2rem;
  }

  .md-max\:pt-9 {
    padding-top: 2.25rem;
  }

  .md-max\:pt-10 {
    padding-top: 2.5rem;
  }

  .md-max\:pt-11 {
    padding-top: 2.75rem;
  }

  .md-max\:pt-12 {
    padding-top: 3rem;
  }

  .md-max\:pt-14 {
    padding-top: 3.5rem;
  }

  .md-max\:pt-16 {
    padding-top: 4rem;
  }

  .md-max\:pt-20 {
    padding-top: 5rem;
  }

  .md-max\:pt-24 {
    padding-top: 6rem;
  }

  .md-max\:pt-28 {
    padding-top: 7rem;
  }

  .md-max\:pt-32 {
    padding-top: 8rem;
  }

  .md-max\:pt-36 {
    padding-top: 9rem;
  }

  .md-max\:pt-40 {
    padding-top: 10rem;
  }

  .md-max\:pt-44 {
    padding-top: 11rem;
  }

  .md-max\:pt-48 {
    padding-top: 12rem;
  }

  .md-max\:pt-52 {
    padding-top: 13rem;
  }

  .md-max\:pt-56 {
    padding-top: 14rem;
  }

  .md-max\:pt-60 {
    padding-top: 15rem;
  }

  .md-max\:pt-64 {
    padding-top: 16rem;
  }

  .md-max\:pt-72 {
    padding-top: 18rem;
  }

  .md-max\:pt-80 {
    padding-top: 20rem;
  }

  .md-max\:pt-96 {
    padding-top: 24rem;
  }

  .md-max\:pt-px {
    padding-top: 1px;
  }

  .md-max\:pt-0\.5 {
    padding-top: .125rem;
  }

  .md-max\:pt-1\.5 {
    padding-top: .375rem;
  }

  .md-max\:pt-2\.5 {
    padding-top: .625rem;
  }

  .md-max\:pt-3\.5 {
    padding-top: .875rem;
  }

  .md-max\:pr-0 {
    padding-right: 0;
  }

  .md-max\:pr-1 {
    padding-right: .25rem;
  }

  .md-max\:pr-2 {
    padding-right: .5rem;
  }

  .md-max\:pr-3 {
    padding-right: .75rem;
  }

  .md-max\:pr-4 {
    padding-right: 1rem;
  }

  .md-max\:pr-5 {
    padding-right: 1.25rem;
  }

  .md-max\:pr-6 {
    padding-right: 1.5rem;
  }

  .md-max\:pr-7 {
    padding-right: 1.75rem;
  }

  .md-max\:pr-8 {
    padding-right: 2rem;
  }

  .md-max\:pr-9 {
    padding-right: 2.25rem;
  }

  .md-max\:pr-10 {
    padding-right: 2.5rem;
  }

  .md-max\:pr-11 {
    padding-right: 2.75rem;
  }

  .md-max\:pr-12 {
    padding-right: 3rem;
  }

  .md-max\:pr-14 {
    padding-right: 3.5rem;
  }

  .md-max\:pr-16 {
    padding-right: 4rem;
  }

  .md-max\:pr-20 {
    padding-right: 5rem;
  }

  .md-max\:pr-24 {
    padding-right: 6rem;
  }

  .md-max\:pr-28 {
    padding-right: 7rem;
  }

  .md-max\:pr-32 {
    padding-right: 8rem;
  }

  .md-max\:pr-36 {
    padding-right: 9rem;
  }

  .md-max\:pr-40 {
    padding-right: 10rem;
  }

  .md-max\:pr-44 {
    padding-right: 11rem;
  }

  .md-max\:pr-48 {
    padding-right: 12rem;
  }

  .md-max\:pr-52 {
    padding-right: 13rem;
  }

  .md-max\:pr-56 {
    padding-right: 14rem;
  }

  .md-max\:pr-60 {
    padding-right: 15rem;
  }

  .md-max\:pr-64 {
    padding-right: 16rem;
  }

  .md-max\:pr-72 {
    padding-right: 18rem;
  }

  .md-max\:pr-80 {
    padding-right: 20rem;
  }

  .md-max\:pr-96 {
    padding-right: 24rem;
  }

  .md-max\:pr-px {
    padding-right: 1px;
  }

  .md-max\:pr-0\.5 {
    padding-right: .125rem;
  }

  .md-max\:pr-1\.5 {
    padding-right: .375rem;
  }

  .md-max\:pr-2\.5 {
    padding-right: .625rem;
  }

  .md-max\:pr-3\.5 {
    padding-right: .875rem;
  }

  .md-max\:pb-0 {
    padding-bottom: 0;
  }

  .md-max\:pb-1 {
    padding-bottom: .25rem;
  }

  .md-max\:pb-2 {
    padding-bottom: .5rem;
  }

  .md-max\:pb-3 {
    padding-bottom: .75rem;
  }

  .md-max\:pb-4 {
    padding-bottom: 1rem;
  }

  .md-max\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md-max\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md-max\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .md-max\:pb-8 {
    padding-bottom: 2rem;
  }

  .md-max\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .md-max\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md-max\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .md-max\:pb-12 {
    padding-bottom: 3rem;
  }

  .md-max\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .md-max\:pb-16 {
    padding-bottom: 4rem;
  }

  .md-max\:pb-20 {
    padding-bottom: 5rem;
  }

  .md-max\:pb-24 {
    padding-bottom: 6rem;
  }

  .md-max\:pb-28 {
    padding-bottom: 7rem;
  }

  .md-max\:pb-32 {
    padding-bottom: 8rem;
  }

  .md-max\:pb-36 {
    padding-bottom: 9rem;
  }

  .md-max\:pb-40 {
    padding-bottom: 10rem;
  }

  .md-max\:pb-44 {
    padding-bottom: 11rem;
  }

  .md-max\:pb-48 {
    padding-bottom: 12rem;
  }

  .md-max\:pb-52 {
    padding-bottom: 13rem;
  }

  .md-max\:pb-56 {
    padding-bottom: 14rem;
  }

  .md-max\:pb-60 {
    padding-bottom: 15rem;
  }

  .md-max\:pb-64 {
    padding-bottom: 16rem;
  }

  .md-max\:pb-72 {
    padding-bottom: 18rem;
  }

  .md-max\:pb-80 {
    padding-bottom: 20rem;
  }

  .md-max\:pb-96 {
    padding-bottom: 24rem;
  }

  .md-max\:pb-px {
    padding-bottom: 1px;
  }

  .md-max\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .md-max\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .md-max\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .md-max\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .md-max\:pl-0 {
    padding-left: 0;
  }

  .md-max\:pl-1 {
    padding-left: .25rem;
  }

  .md-max\:pl-2 {
    padding-left: .5rem;
  }

  .md-max\:pl-3 {
    padding-left: .75rem;
  }

  .md-max\:pl-4 {
    padding-left: 1rem;
  }

  .md-max\:pl-5 {
    padding-left: 1.25rem;
  }

  .md-max\:pl-6 {
    padding-left: 1.5rem;
  }

  .md-max\:pl-7 {
    padding-left: 1.75rem;
  }

  .md-max\:pl-8 {
    padding-left: 2rem;
  }

  .md-max\:pl-9 {
    padding-left: 2.25rem;
  }

  .md-max\:pl-10 {
    padding-left: 2.5rem;
  }

  .md-max\:pl-11 {
    padding-left: 2.75rem;
  }

  .md-max\:pl-12 {
    padding-left: 3rem;
  }

  .md-max\:pl-14 {
    padding-left: 3.5rem;
  }

  .md-max\:pl-16 {
    padding-left: 4rem;
  }

  .md-max\:pl-20 {
    padding-left: 5rem;
  }

  .md-max\:pl-24 {
    padding-left: 6rem;
  }

  .md-max\:pl-28 {
    padding-left: 7rem;
  }

  .md-max\:pl-32 {
    padding-left: 8rem;
  }

  .md-max\:pl-36 {
    padding-left: 9rem;
  }

  .md-max\:pl-40 {
    padding-left: 10rem;
  }

  .md-max\:pl-44 {
    padding-left: 11rem;
  }

  .md-max\:pl-48 {
    padding-left: 12rem;
  }

  .md-max\:pl-52 {
    padding-left: 13rem;
  }

  .md-max\:pl-56 {
    padding-left: 14rem;
  }

  .md-max\:pl-60 {
    padding-left: 15rem;
  }

  .md-max\:pl-64 {
    padding-left: 16rem;
  }

  .md-max\:pl-72 {
    padding-left: 18rem;
  }

  .md-max\:pl-80 {
    padding-left: 20rem;
  }

  .md-max\:pl-96 {
    padding-left: 24rem;
  }

  .md-max\:pl-px {
    padding-left: 1px;
  }

  .md-max\:pl-0\.5 {
    padding-left: .125rem;
  }

  .md-max\:pl-1\.5 {
    padding-left: .375rem;
  }

  .md-max\:pl-2\.5 {
    padding-left: .625rem;
  }

  .md-max\:pl-3\.5 {
    padding-left: .875rem;
  }

  .md-max\:text-left {
    text-align: left;
  }

  .md-max\:text-center {
    text-align: center;
  }

  .md-max\:text-right {
    text-align: right;
  }

  .md-max\:text-justify {
    text-align: justify;
  }

  .md-max\:align-baseline {
    vertical-align: baseline;
  }

  .md-max\:align-top {
    vertical-align: top;
  }

  .md-max\:align-middle {
    vertical-align: middle;
  }

  .md-max\:align-bottom {
    vertical-align: bottom;
  }

  .md-max\:align-text-top {
    vertical-align: text-top;
  }

  .md-max\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .md-max\:font-body {
    font-family: Montserrat;
  }

  .md-max\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .md-max\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md-max\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md-max\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md-max\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md-max\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md-max\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md-max\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md-max\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md-max\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md-max\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md-max\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .md-max\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .md-max\:font-thin {
    font-weight: 100;
  }

  .md-max\:font-extralight {
    font-weight: 200;
  }

  .md-max\:font-light {
    font-weight: 300;
  }

  .md-max\:font-normal {
    font-weight: 400;
  }

  .md-max\:font-medium {
    font-weight: 500;
  }

  .md-max\:font-semibold {
    font-weight: 600;
  }

  .md-max\:font-bold {
    font-weight: 700;
  }

  .md-max\:font-extrabold {
    font-weight: 800;
  }

  .md-max\:font-black {
    font-weight: 900;
  }

  .md-max\:uppercase {
    text-transform: uppercase;
  }

  .md-max\:lowercase {
    text-transform: lowercase;
  }

  .md-max\:capitalize {
    text-transform: capitalize;
  }

  .md-max\:normal-case {
    text-transform: none;
  }

  .md-max\:italic {
    font-style: italic;
  }

  .md-max\:not-italic {
    font-style: normal;
  }

  .md-max\:ordinal, .md-max\:slashed-zero, .md-max\:lining-nums, .md-max\:oldstyle-nums, .md-max\:proportional-nums, .md-max\:tabular-nums, .md-max\:diagonal-fractions, .md-max\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md-max\:normal-nums {
    font-variant-numeric: normal;
  }

  .md-max\:ordinal {
    --tw-ordinal: ordinal;
  }

  .md-max\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .md-max\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .md-max\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .md-max\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .md-max\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .md-max\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .md-max\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .md-max\:leading-3 {
    line-height: .75rem;
  }

  .md-max\:leading-4 {
    line-height: 1rem;
  }

  .md-max\:leading-5 {
    line-height: 1.25rem;
  }

  .md-max\:leading-6 {
    line-height: 1.5rem;
  }

  .md-max\:leading-7 {
    line-height: 1.75rem;
  }

  .md-max\:leading-8 {
    line-height: 2rem;
  }

  .md-max\:leading-9 {
    line-height: 2.25rem;
  }

  .md-max\:leading-10 {
    line-height: 2.5rem;
  }

  .md-max\:leading-none {
    line-height: 1;
  }

  .md-max\:leading-tight {
    line-height: 1.25;
  }

  .md-max\:leading-snug {
    line-height: 1.375;
  }

  .md-max\:leading-normal {
    line-height: 1.5;
  }

  .md-max\:leading-relaxed {
    line-height: 1.625;
  }

  .md-max\:leading-loose {
    line-height: 2;
  }

  .md-max\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .md-max\:tracking-tight {
    letter-spacing: -.025em;
  }

  .md-max\:tracking-normal {
    letter-spacing: 0;
  }

  .md-max\:tracking-wide {
    letter-spacing: .025em;
  }

  .md-max\:tracking-wider {
    letter-spacing: .05em;
  }

  .md-max\:tracking-widest {
    letter-spacing: .1em;
  }

  .md-max\:text-transparent {
    color: #0000;
  }

  .md-max\:text-current {
    color: currentColor;
  }

  .md-max\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md-max\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md-max\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md-max\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md-max\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md-max\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md-max\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md-max\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md-max\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md-max\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md-max\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md-max\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md-max\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md-max\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md-max\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md-max\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md-max\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md-max\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md-max\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md-max\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md-max\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md-max\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md-max\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md-max\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md-max\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md-max\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md-max\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md-max\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md-max\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md-max\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md-max\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md-max\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md-max\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md-max\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md-max\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md-max\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md-max\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md-max\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md-max\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md-max\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md-max\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md-max\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md-max\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md-max\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md-max\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md-max\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md-max\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md-max\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md-max\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md-max\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md-max\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md-max\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md-max\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md-max\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .md-max\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .md-max\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .md-max\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .md-max\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .md-max\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md-max\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-transparent:hover {
    color: #0000;
  }

  .md-max\:hover\:text-current:hover {
    color: currentColor;
  }

  .md-max\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md-max\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-transparent:focus {
    color: #0000;
  }

  .md-max\:focus\:text-current:focus {
    color: currentColor;
  }

  .md-max\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .md-max\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .md-max\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .md-max\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .md-max\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .md-max\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .md-max\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .md-max\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .md-max\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .md-max\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .md-max\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .md-max\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .md-max\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .md-max\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .md-max\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .md-max\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .md-max\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .md-max\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .md-max\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .md-max\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .md-max\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .md-max\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .md-max\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .md-max\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .md-max\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .md-max\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .md-max\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .md-max\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .md-max\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .md-max\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .md-max\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .md-max\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .md-max\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .md-max\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .md-max\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .md-max\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .md-max\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .md-max\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .md-max\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .md-max\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .md-max\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .md-max\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .md-max\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .md-max\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .md-max\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .md-max\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .md-max\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .md-max\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .md-max\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .md-max\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .md-max\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .md-max\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .md-max\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .md-max\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .md-max\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .md-max\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .md-max\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .md-max\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .md-max\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .md-max\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .md-max\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .md-max\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .md-max\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .md-max\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .md-max\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .md-max\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .md-max\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .md-max\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .md-max\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .md-max\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .md-max\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .md-max\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .md-max\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .md-max\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .md-max\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .md-max\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .md-max\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .md-max\:underline {
    text-decoration: underline;
  }

  .md-max\:line-through {
    text-decoration: line-through;
  }

  .md-max\:no-underline {
    text-decoration: none;
  }

  .group:hover .md-max\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .md-max\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .md-max\:group-hover\:no-underline {
    text-decoration: none;
  }

  .md-max\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .md-max\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .md-max\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .md-max\:hover\:underline:hover {
    text-decoration: underline;
  }

  .md-max\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .md-max\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .md-max\:focus\:underline:focus {
    text-decoration: underline;
  }

  .md-max\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .md-max\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .md-max\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md-max\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md-max\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .md-max\:placeholder-current::placeholder {
    color: currentColor;
  }

  .md-max\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .md-max\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .md-max\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .md-max\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .md-max\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md-max\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .md-max\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .md-max\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .md-max\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .md-max\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .md-max\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .md-max\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .md-max\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .md-max\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .md-max\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .md-max\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .md-max\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .md-max\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .md-max\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md-max\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md-max\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .md-max\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .md-max\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .md-max\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .md-max\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .md-max\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .md-max\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .md-max\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .md-max\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .md-max\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .md-max\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .md-max\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .md-max\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .md-max\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md-max\:opacity-0 {
    opacity: 0;
  }

  .md-max\:opacity-5 {
    opacity: .05;
  }

  .md-max\:opacity-10 {
    opacity: .1;
  }

  .md-max\:opacity-20 {
    opacity: .2;
  }

  .md-max\:opacity-25 {
    opacity: .25;
  }

  .md-max\:opacity-30 {
    opacity: .3;
  }

  .md-max\:opacity-40 {
    opacity: .4;
  }

  .md-max\:opacity-50 {
    opacity: .5;
  }

  .md-max\:opacity-60 {
    opacity: .6;
  }

  .md-max\:opacity-70 {
    opacity: .7;
  }

  .md-max\:opacity-75 {
    opacity: .75;
  }

  .md-max\:opacity-80 {
    opacity: .8;
  }

  .md-max\:opacity-90 {
    opacity: .9;
  }

  .md-max\:opacity-95 {
    opacity: .95;
  }

  .md-max\:opacity-100 {
    opacity: 1;
  }

  .group:hover .md-max\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .md-max\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .md-max\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .md-max\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .md-max\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .md-max\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .md-max\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .md-max\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .md-max\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .md-max\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .md-max\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .md-max\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .md-max\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .md-max\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .md-max\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .md-max\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .md-max\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .md-max\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .md-max\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .md-max\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .md-max\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .md-max\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .md-max\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .md-max\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .md-max\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .md-max\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .md-max\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .md-max\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .md-max\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .md-max\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .md-max\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .md-max\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .md-max\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .md-max\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .md-max\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .md-max\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .md-max\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .md-max\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .md-max\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .md-max\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .md-max\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .md-max\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .md-max\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .md-max\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .md-max\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .md-max\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .md-max\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .md-max\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .md-max\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .md-max\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .md-max\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .md-max\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .md-max\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .md-max\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .md-max\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .md-max\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .md-max\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .md-max\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .md-max\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .md-max\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .md-max\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .md-max\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .md-max\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .md-max\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .md-max\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .md-max\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .md-max\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .md-max\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .md-max\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .md-max\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .md-max\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .md-max\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .md-max\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .md-max\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .md-max\:bg-blend-color {
    background-blend-mode: color;
  }

  .md-max\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .md-max\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .md-max\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .md-max\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .md-max\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .md-max\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .md-max\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .md-max\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .md-max\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .md-max\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .md-max\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .md-max\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .md-max\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .md-max\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .md-max\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .md-max\:mix-blend-color {
    mix-blend-mode: color;
  }

  .md-max\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .md-max\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .md-max\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md-max\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md-max\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md-max\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md-max\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md-max\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md-max\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md-max\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md-max\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md-max\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md-max\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md-max\:ring-inset, .md-max\:focus-within\:ring-inset:focus-within, .md-max\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .md-max\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .md-max\:ring-current {
    --tw-ring-color: currentColor;
  }

  .md-max\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md-max\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md-max\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md-max\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md-max\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md-max\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md-max\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md-max\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md-max\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md-max\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .md-max\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .md-max\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md-max\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .md-max\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .md-max\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .md-max\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .md-max\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .md-max\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .md-max\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .md-max\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .md-max\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .md-max\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .md-max\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .md-max\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .md-max\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .md-max\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .md-max\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .md-max\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .md-max\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .md-max\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .md-max\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .md-max\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .md-max\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .md-max\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .md-max\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .md-max\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .md-max\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .md-max\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .md-max\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .md-max\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .md-max\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .md-max\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .md-max\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .md-max\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .md-max\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .md-max\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .md-max\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .md-max\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .md-max\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .md-max\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .md-max\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .md-max\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .md-max\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .md-max\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .md-max\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .md-max\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .md-max\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .md-max\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .md-max\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .md-max\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .md-max\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .md-max\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .md-max\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .md-max\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .md-max\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .md-max\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .md-max\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .md-max\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .md-max\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .md-max\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .md-max\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .md-max\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .md-max\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .md-max\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .md-max\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .md-max\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .md-max\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .md-max\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .md-max\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .md-max\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .md-max\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .md-max\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md-max\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md-max\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .md-max\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .md-max\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .md-max\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .md-max\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .md-max\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .md-max\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .md-max\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .md-max\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .md-max\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .md-max\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .md-max\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .md-max\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .md-max\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .md-max\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .md-max\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .md-max\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .md-max\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .md-max\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md-max\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .md-max\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md-max\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md-max\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .md-max\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .md-max\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .md-max\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .md-max\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .md-max\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .md-max\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .md-max\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .md-max\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md-max\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .md-max\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .md-max\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .md-max\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .md-max\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md-max\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .md-max\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md-max\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .md-max\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md-max\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md-max\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md-max\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .md-max\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .md-max\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .md-max\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .md-max\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .md-max\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .md-max\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md-max\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .md-max\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md-max\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md-max\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .md-max\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md-max\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .md-max\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .md-max\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .md-max\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .md-max\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md-max\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .md-max\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md-max\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md-max\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .md-max\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .md-max\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .md-max\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .md-max\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .md-max\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .md-max\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .md-max\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .md-max\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .md-max\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .md-max\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .md-max\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md-max\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md-max\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .md-max\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .md-max\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .md-max\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .md-max\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .md-max\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .md-max\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .md-max\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .md-max\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .md-max\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .md-max\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .md-max\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .md-max\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .md-max\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .md-max\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .md-max\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .md-max\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .md-max\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .md-max\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md-max\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .md-max\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md-max\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md-max\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .md-max\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .md-max\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .md-max\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .md-max\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .md-max\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .md-max\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .md-max\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .md-max\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md-max\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .md-max\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .md-max\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .md-max\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .md-max\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md-max\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .md-max\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md-max\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .md-max\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md-max\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md-max\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md-max\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .md-max\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .md-max\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .md-max\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .md-max\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .md-max\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .md-max\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md-max\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .md-max\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md-max\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md-max\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .md-max\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md-max\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .md-max\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .md-max\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .md-max\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .md-max\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md-max\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .md-max\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md-max\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md-max\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .md-max\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .md-max\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .md-max\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .md-max\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .md-max\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .md-max\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .md-max\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .md-max\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .md-max\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .md-max\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .md-max\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .md-max\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .md-max\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .md-max\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .md-max\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .md-max\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .md-max\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .md-max\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .md-max\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .md-max\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .md-max\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .md-max\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .md-max\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .md-max\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .md-max\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .md-max\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .md-max\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .md-max\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .md-max\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .md-max\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .md-max\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .md-max\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .md-max\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .md-max\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .md-max\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .md-max\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .md-max\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .md-max\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .md-max\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .md-max\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .md-max\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .md-max\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .md-max\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .md-max\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .md-max\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .md-max\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .md-max\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .md-max\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .md-max\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .md-max\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .md-max\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .md-max\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .md-max\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .md-max\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .md-max\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .md-max\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .md-max\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .md-max\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .md-max\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .md-max\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .md-max\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .md-max\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .md-max\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .md-max\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .md-max\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .md-max\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .md-max\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .md-max\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .md-max\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .md-max\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .md-max\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .md-max\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .md-max\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .md-max\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .md-max\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .md-max\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .md-max\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .md-max\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .md-max\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .md-max\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .md-max\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md-max\:filter-none {
    filter: none;
  }

  .md-max\:blur-0, .md-max\:blur-none {
    --tw-blur: blur(0);
  }

  .md-max\:blur-sm {
    --tw-blur: blur(4px);
  }

  .md-max\:blur {
    --tw-blur: blur(8px);
  }

  .md-max\:blur-md {
    --tw-blur: blur(12px);
  }

  .md-max\:blur-lg {
    --tw-blur: blur(16px);
  }

  .md-max\:blur-xl {
    --tw-blur: blur(24px);
  }

  .md-max\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .md-max\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .md-max\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .md-max\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .md-max\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .md-max\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .md-max\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .md-max\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .md-max\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .md-max\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .md-max\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .md-max\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .md-max\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .md-max\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .md-max\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .md-max\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .md-max\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .md-max\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .md-max\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .md-max\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .md-max\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .md-max\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .md-max\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .md-max\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .md-max\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .md-max\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .md-max\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .md-max\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .md-max\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .md-max\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .md-max\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .md-max\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .md-max\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .md-max\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .md-max\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .md-max\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .md-max\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .md-max\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .md-max\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .md-max\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .md-max\:invert-0 {
    --tw-invert: invert(0);
  }

  .md-max\:invert {
    --tw-invert: invert(100%);
  }

  .md-max\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .md-max\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .md-max\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .md-max\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .md-max\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .md-max\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .md-max\:sepia {
    --tw-sepia: sepia(100%);
  }

  .md-max\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md-max\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .md-max\:backdrop-blur-0, .md-max\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .md-max\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .md-max\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .md-max\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .md-max\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .md-max\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .md-max\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .md-max\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .md-max\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .md-max\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .md-max\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .md-max\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .md-max\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .md-max\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .md-max\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .md-max\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .md-max\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .md-max\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .md-max\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .md-max\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .md-max\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .md-max\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .md-max\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .md-max\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .md-max\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .md-max\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .md-max\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .md-max\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .md-max\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .md-max\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .md-max\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .md-max\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .md-max\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .md-max\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .md-max\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .md-max\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .md-max\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .md-max\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .md-max\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .md-max\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .md-max\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .md-max\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .md-max\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .md-max\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .md-max\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .md-max\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .md-max\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .md-max\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .md-max\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .md-max\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .md-max\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .md-max\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .md-max\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .md-max\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .md-max\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .md-max\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .md-max\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .md-max\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .md-max\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .md-max\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .md-max\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .md-max\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .md-max\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .md-max\:transition-none {
    transition-property: none;
  }

  .md-max\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md-max\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md-max\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md-max\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md-max\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md-max\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md-max\:delay-75 {
    transition-delay: 75ms;
  }

  .md-max\:delay-100 {
    transition-delay: .1s;
  }

  .md-max\:delay-150 {
    transition-delay: .15s;
  }

  .md-max\:delay-200 {
    transition-delay: .2s;
  }

  .md-max\:delay-300 {
    transition-delay: .3s;
  }

  .md-max\:delay-500 {
    transition-delay: .5s;
  }

  .md-max\:delay-700 {
    transition-delay: .7s;
  }

  .md-max\:delay-1000 {
    transition-delay: 1s;
  }

  .md-max\:duration-75 {
    transition-duration: 75ms;
  }

  .md-max\:duration-100 {
    transition-duration: .1s;
  }

  .md-max\:duration-150 {
    transition-duration: .15s;
  }

  .md-max\:duration-200 {
    transition-duration: .2s;
  }

  .md-max\:duration-300 {
    transition-duration: .3s;
  }

  .md-max\:duration-500 {
    transition-duration: .5s;
  }

  .md-max\:duration-700 {
    transition-duration: .7s;
  }

  .md-max\:duration-1000 {
    transition-duration: 1s;
  }

  .md-max\:ease-linear {
    transition-timing-function: linear;
  }

  .md-max\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .md-max\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .md-max\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .lg\:container {
      max-width: 1560px;
    }
  }

  .lg\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg\:pointer-events-none {
    pointer-events: none;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:visible {
    visibility: visible;
  }

  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:static {
    position: static;
  }

  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:inset-0 {
    inset: 0;
  }

  .lg\:inset-1 {
    inset: .25rem;
  }

  .lg\:inset-2 {
    inset: .5rem;
  }

  .lg\:inset-3 {
    inset: .75rem;
  }

  .lg\:inset-4 {
    inset: 1rem;
  }

  .lg\:inset-5 {
    inset: 1.25rem;
  }

  .lg\:inset-6 {
    inset: 1.5rem;
  }

  .lg\:inset-7 {
    inset: 1.75rem;
  }

  .lg\:inset-8 {
    inset: 2rem;
  }

  .lg\:inset-9 {
    inset: 2.25rem;
  }

  .lg\:inset-10 {
    inset: 2.5rem;
  }

  .lg\:inset-11 {
    inset: 2.75rem;
  }

  .lg\:inset-12 {
    inset: 3rem;
  }

  .lg\:inset-14 {
    inset: 3.5rem;
  }

  .lg\:inset-16 {
    inset: 4rem;
  }

  .lg\:inset-20 {
    inset: 5rem;
  }

  .lg\:inset-24 {
    inset: 6rem;
  }

  .lg\:inset-28 {
    inset: 7rem;
  }

  .lg\:inset-32 {
    inset: 8rem;
  }

  .lg\:inset-36 {
    inset: 9rem;
  }

  .lg\:inset-40 {
    inset: 10rem;
  }

  .lg\:inset-44 {
    inset: 11rem;
  }

  .lg\:inset-48 {
    inset: 12rem;
  }

  .lg\:inset-52 {
    inset: 13rem;
  }

  .lg\:inset-56 {
    inset: 14rem;
  }

  .lg\:inset-60 {
    inset: 15rem;
  }

  .lg\:inset-64 {
    inset: 16rem;
  }

  .lg\:inset-72 {
    inset: 18rem;
  }

  .lg\:inset-80 {
    inset: 20rem;
  }

  .lg\:inset-96 {
    inset: 24rem;
  }

  .lg\:inset-auto {
    inset: auto;
  }

  .lg\:inset-px {
    inset: 1px;
  }

  .lg\:inset-0\.5 {
    inset: .125rem;
  }

  .lg\:inset-1\.5 {
    inset: .375rem;
  }

  .lg\:inset-2\.5 {
    inset: .625rem;
  }

  .lg\:inset-3\.5 {
    inset: .875rem;
  }

  .lg\:-inset-0 {
    inset: 0;
  }

  .lg\:-inset-1 {
    inset: -.25rem;
  }

  .lg\:-inset-2 {
    inset: -.5rem;
  }

  .lg\:-inset-3 {
    inset: -.75rem;
  }

  .lg\:-inset-4 {
    inset: -1rem;
  }

  .lg\:-inset-5 {
    inset: -1.25rem;
  }

  .lg\:-inset-6 {
    inset: -1.5rem;
  }

  .lg\:-inset-7 {
    inset: -1.75rem;
  }

  .lg\:-inset-8 {
    inset: -2rem;
  }

  .lg\:-inset-9 {
    inset: -2.25rem;
  }

  .lg\:-inset-10 {
    inset: -2.5rem;
  }

  .lg\:-inset-11 {
    inset: -2.75rem;
  }

  .lg\:-inset-12 {
    inset: -3rem;
  }

  .lg\:-inset-14 {
    inset: -3.5rem;
  }

  .lg\:-inset-16 {
    inset: -4rem;
  }

  .lg\:-inset-20 {
    inset: -5rem;
  }

  .lg\:-inset-24 {
    inset: -6rem;
  }

  .lg\:-inset-28 {
    inset: -7rem;
  }

  .lg\:-inset-32 {
    inset: -8rem;
  }

  .lg\:-inset-36 {
    inset: -9rem;
  }

  .lg\:-inset-40 {
    inset: -10rem;
  }

  .lg\:-inset-44 {
    inset: -11rem;
  }

  .lg\:-inset-48 {
    inset: -12rem;
  }

  .lg\:-inset-52 {
    inset: -13rem;
  }

  .lg\:-inset-56 {
    inset: -14rem;
  }

  .lg\:-inset-60 {
    inset: -15rem;
  }

  .lg\:-inset-64 {
    inset: -16rem;
  }

  .lg\:-inset-72 {
    inset: -18rem;
  }

  .lg\:-inset-80 {
    inset: -20rem;
  }

  .lg\:-inset-96 {
    inset: -24rem;
  }

  .lg\:-inset-px {
    inset: -1px;
  }

  .lg\:-inset-0\.5 {
    inset: -.125rem;
  }

  .lg\:-inset-1\.5 {
    inset: -.375rem;
  }

  .lg\:-inset-2\.5 {
    inset: -.625rem;
  }

  .lg\:-inset-3\.5 {
    inset: -.875rem;
  }

  .lg\:inset-1\/2 {
    inset: 50%;
  }

  .lg\:inset-1\/3 {
    inset: 33.3333%;
  }

  .lg\:inset-2\/3 {
    inset: 66.6667%;
  }

  .lg\:inset-1\/4 {
    inset: 25%;
  }

  .lg\:inset-2\/4 {
    inset: 50%;
  }

  .lg\:inset-3\/4 {
    inset: 75%;
  }

  .lg\:inset-full {
    inset: 100%;
  }

  .lg\:-inset-1\/2 {
    inset: -50%;
  }

  .lg\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .lg\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .lg\:-inset-1\/4 {
    inset: -25%;
  }

  .lg\:-inset-2\/4 {
    inset: -50%;
  }

  .lg\:-inset-3\/4 {
    inset: -75%;
  }

  .lg\:-inset-full {
    inset: -100%;
  }

  .lg\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .lg\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .lg\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .lg\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .lg\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .lg\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .lg\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .lg\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .lg\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .lg\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .lg\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .lg\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .lg\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .lg\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .lg\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .lg\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .lg\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .lg\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .lg\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .lg\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .lg\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .lg\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .lg\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .lg\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .lg\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .lg\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .lg\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .lg\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .lg\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .lg\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .lg\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .lg\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .lg\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .lg\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .lg\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .lg\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .lg\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .lg\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .lg\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .lg\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .lg\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .lg\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .lg\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .lg\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .lg\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .lg\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .lg\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .lg\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .lg\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .lg\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .lg\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .lg\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .lg\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .lg\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .lg\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .lg\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .lg\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .lg\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .lg\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .lg\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .lg\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .lg\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .lg\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .lg\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .lg\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .lg\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .lg\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .lg\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .lg\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .lg\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .lg\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .lg\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .lg\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .lg\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .lg\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .lg\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .lg\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .lg\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .lg\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .lg\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .lg\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .lg\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .lg\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .lg\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .lg\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .lg\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .lg\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .lg\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .lg\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .lg\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .lg\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .lg\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .lg\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .lg\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .lg\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .lg\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .lg\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .lg\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .lg\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .lg\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .lg\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .lg\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .lg\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .lg\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .lg\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .lg\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .lg\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .lg\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .lg\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .lg\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .lg\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .lg\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .lg\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .lg\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .lg\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .lg\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .lg\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .lg\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .lg\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .lg\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .lg\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .lg\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .lg\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .lg\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .lg\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .lg\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .lg\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .lg\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .lg\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .lg\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .lg\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .lg\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .lg\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .lg\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .lg\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .lg\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .lg\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .lg\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .lg\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .lg\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .lg\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .lg\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .lg\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .lg\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .lg\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .lg\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .lg\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .lg\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .lg\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .lg\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .lg\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .lg\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .lg\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .lg\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .lg\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .lg\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .lg\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .lg\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .lg\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:top-1 {
    top: .25rem;
  }

  .lg\:top-2 {
    top: .5rem;
  }

  .lg\:top-3 {
    top: .75rem;
  }

  .lg\:top-4 {
    top: 1rem;
  }

  .lg\:top-5 {
    top: 1.25rem;
  }

  .lg\:top-6 {
    top: 1.5rem;
  }

  .lg\:top-7 {
    top: 1.75rem;
  }

  .lg\:top-8 {
    top: 2rem;
  }

  .lg\:top-9 {
    top: 2.25rem;
  }

  .lg\:top-10 {
    top: 2.5rem;
  }

  .lg\:top-11 {
    top: 2.75rem;
  }

  .lg\:top-12 {
    top: 3rem;
  }

  .lg\:top-14 {
    top: 3.5rem;
  }

  .lg\:top-16 {
    top: 4rem;
  }

  .lg\:top-20 {
    top: 5rem;
  }

  .lg\:top-24 {
    top: 6rem;
  }

  .lg\:top-28 {
    top: 7rem;
  }

  .lg\:top-32 {
    top: 8rem;
  }

  .lg\:top-36 {
    top: 9rem;
  }

  .lg\:top-40 {
    top: 10rem;
  }

  .lg\:top-44 {
    top: 11rem;
  }

  .lg\:top-48 {
    top: 12rem;
  }

  .lg\:top-52 {
    top: 13rem;
  }

  .lg\:top-56 {
    top: 14rem;
  }

  .lg\:top-60 {
    top: 15rem;
  }

  .lg\:top-64 {
    top: 16rem;
  }

  .lg\:top-72 {
    top: 18rem;
  }

  .lg\:top-80 {
    top: 20rem;
  }

  .lg\:top-96 {
    top: 24rem;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:top-px {
    top: 1px;
  }

  .lg\:top-0\.5 {
    top: .125rem;
  }

  .lg\:top-1\.5 {
    top: .375rem;
  }

  .lg\:top-2\.5 {
    top: .625rem;
  }

  .lg\:top-3\.5 {
    top: .875rem;
  }

  .lg\:-top-0 {
    top: 0;
  }

  .lg\:-top-1 {
    top: -.25rem;
  }

  .lg\:-top-2 {
    top: -.5rem;
  }

  .lg\:-top-3 {
    top: -.75rem;
  }

  .lg\:-top-4 {
    top: -1rem;
  }

  .lg\:-top-5 {
    top: -1.25rem;
  }

  .lg\:-top-6 {
    top: -1.5rem;
  }

  .lg\:-top-7 {
    top: -1.75rem;
  }

  .lg\:-top-8 {
    top: -2rem;
  }

  .lg\:-top-9 {
    top: -2.25rem;
  }

  .lg\:-top-10 {
    top: -2.5rem;
  }

  .lg\:-top-11 {
    top: -2.75rem;
  }

  .lg\:-top-12 {
    top: -3rem;
  }

  .lg\:-top-14 {
    top: -3.5rem;
  }

  .lg\:-top-16 {
    top: -4rem;
  }

  .lg\:-top-20 {
    top: -5rem;
  }

  .lg\:-top-24 {
    top: -6rem;
  }

  .lg\:-top-28 {
    top: -7rem;
  }

  .lg\:-top-32 {
    top: -8rem;
  }

  .lg\:-top-36 {
    top: -9rem;
  }

  .lg\:-top-40 {
    top: -10rem;
  }

  .lg\:-top-44 {
    top: -11rem;
  }

  .lg\:-top-48 {
    top: -12rem;
  }

  .lg\:-top-52 {
    top: -13rem;
  }

  .lg\:-top-56 {
    top: -14rem;
  }

  .lg\:-top-60 {
    top: -15rem;
  }

  .lg\:-top-64 {
    top: -16rem;
  }

  .lg\:-top-72 {
    top: -18rem;
  }

  .lg\:-top-80 {
    top: -20rem;
  }

  .lg\:-top-96 {
    top: -24rem;
  }

  .lg\:-top-px {
    top: -1px;
  }

  .lg\:-top-0\.5 {
    top: -.125rem;
  }

  .lg\:-top-1\.5 {
    top: -.375rem;
  }

  .lg\:-top-2\.5 {
    top: -.625rem;
  }

  .lg\:-top-3\.5 {
    top: -.875rem;
  }

  .lg\:top-1\/2 {
    top: 50%;
  }

  .lg\:top-1\/3 {
    top: 33.3333%;
  }

  .lg\:top-2\/3 {
    top: 66.6667%;
  }

  .lg\:top-1\/4 {
    top: 25%;
  }

  .lg\:top-2\/4 {
    top: 50%;
  }

  .lg\:top-3\/4 {
    top: 75%;
  }

  .lg\:top-full {
    top: 100%;
  }

  .lg\:-top-1\/2 {
    top: -50%;
  }

  .lg\:-top-1\/3 {
    top: -33.3333%;
  }

  .lg\:-top-2\/3 {
    top: -66.6667%;
  }

  .lg\:-top-1\/4 {
    top: -25%;
  }

  .lg\:-top-2\/4 {
    top: -50%;
  }

  .lg\:-top-3\/4 {
    top: -75%;
  }

  .lg\:-top-full {
    top: -100%;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:right-1 {
    right: .25rem;
  }

  .lg\:right-2 {
    right: .5rem;
  }

  .lg\:right-3 {
    right: .75rem;
  }

  .lg\:right-4 {
    right: 1rem;
  }

  .lg\:right-5 {
    right: 1.25rem;
  }

  .lg\:right-6 {
    right: 1.5rem;
  }

  .lg\:right-7 {
    right: 1.75rem;
  }

  .lg\:right-8 {
    right: 2rem;
  }

  .lg\:right-9 {
    right: 2.25rem;
  }

  .lg\:right-10 {
    right: 2.5rem;
  }

  .lg\:right-11 {
    right: 2.75rem;
  }

  .lg\:right-12 {
    right: 3rem;
  }

  .lg\:right-14 {
    right: 3.5rem;
  }

  .lg\:right-16 {
    right: 4rem;
  }

  .lg\:right-20 {
    right: 5rem;
  }

  .lg\:right-24 {
    right: 6rem;
  }

  .lg\:right-28 {
    right: 7rem;
  }

  .lg\:right-32 {
    right: 8rem;
  }

  .lg\:right-36 {
    right: 9rem;
  }

  .lg\:right-40 {
    right: 10rem;
  }

  .lg\:right-44 {
    right: 11rem;
  }

  .lg\:right-48 {
    right: 12rem;
  }

  .lg\:right-52 {
    right: 13rem;
  }

  .lg\:right-56 {
    right: 14rem;
  }

  .lg\:right-60 {
    right: 15rem;
  }

  .lg\:right-64 {
    right: 16rem;
  }

  .lg\:right-72 {
    right: 18rem;
  }

  .lg\:right-80 {
    right: 20rem;
  }

  .lg\:right-96 {
    right: 24rem;
  }

  .lg\:right-auto {
    right: auto;
  }

  .lg\:right-px {
    right: 1px;
  }

  .lg\:right-0\.5 {
    right: .125rem;
  }

  .lg\:right-1\.5 {
    right: .375rem;
  }

  .lg\:right-2\.5 {
    right: .625rem;
  }

  .lg\:right-3\.5 {
    right: .875rem;
  }

  .lg\:-right-0 {
    right: 0;
  }

  .lg\:-right-1 {
    right: -.25rem;
  }

  .lg\:-right-2 {
    right: -.5rem;
  }

  .lg\:-right-3 {
    right: -.75rem;
  }

  .lg\:-right-4 {
    right: -1rem;
  }

  .lg\:-right-5 {
    right: -1.25rem;
  }

  .lg\:-right-6 {
    right: -1.5rem;
  }

  .lg\:-right-7 {
    right: -1.75rem;
  }

  .lg\:-right-8 {
    right: -2rem;
  }

  .lg\:-right-9 {
    right: -2.25rem;
  }

  .lg\:-right-10 {
    right: -2.5rem;
  }

  .lg\:-right-11 {
    right: -2.75rem;
  }

  .lg\:-right-12 {
    right: -3rem;
  }

  .lg\:-right-14 {
    right: -3.5rem;
  }

  .lg\:-right-16 {
    right: -4rem;
  }

  .lg\:-right-20 {
    right: -5rem;
  }

  .lg\:-right-24 {
    right: -6rem;
  }

  .lg\:-right-28 {
    right: -7rem;
  }

  .lg\:-right-32 {
    right: -8rem;
  }

  .lg\:-right-36 {
    right: -9rem;
  }

  .lg\:-right-40 {
    right: -10rem;
  }

  .lg\:-right-44 {
    right: -11rem;
  }

  .lg\:-right-48 {
    right: -12rem;
  }

  .lg\:-right-52 {
    right: -13rem;
  }

  .lg\:-right-56 {
    right: -14rem;
  }

  .lg\:-right-60 {
    right: -15rem;
  }

  .lg\:-right-64 {
    right: -16rem;
  }

  .lg\:-right-72 {
    right: -18rem;
  }

  .lg\:-right-80 {
    right: -20rem;
  }

  .lg\:-right-96 {
    right: -24rem;
  }

  .lg\:-right-px {
    right: -1px;
  }

  .lg\:-right-0\.5 {
    right: -.125rem;
  }

  .lg\:-right-1\.5 {
    right: -.375rem;
  }

  .lg\:-right-2\.5 {
    right: -.625rem;
  }

  .lg\:-right-3\.5 {
    right: -.875rem;
  }

  .lg\:right-1\/2 {
    right: 50%;
  }

  .lg\:right-1\/3 {
    right: 33.3333%;
  }

  .lg\:right-2\/3 {
    right: 66.6667%;
  }

  .lg\:right-1\/4 {
    right: 25%;
  }

  .lg\:right-2\/4 {
    right: 50%;
  }

  .lg\:right-3\/4 {
    right: 75%;
  }

  .lg\:right-full {
    right: 100%;
  }

  .lg\:-right-1\/2 {
    right: -50%;
  }

  .lg\:-right-1\/3 {
    right: -33.3333%;
  }

  .lg\:-right-2\/3 {
    right: -66.6667%;
  }

  .lg\:-right-1\/4 {
    right: -25%;
  }

  .lg\:-right-2\/4 {
    right: -50%;
  }

  .lg\:-right-3\/4 {
    right: -75%;
  }

  .lg\:-right-full {
    right: -100%;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:bottom-1 {
    bottom: .25rem;
  }

  .lg\:bottom-2 {
    bottom: .5rem;
  }

  .lg\:bottom-3 {
    bottom: .75rem;
  }

  .lg\:bottom-4 {
    bottom: 1rem;
  }

  .lg\:bottom-5 {
    bottom: 1.25rem;
  }

  .lg\:bottom-6 {
    bottom: 1.5rem;
  }

  .lg\:bottom-7 {
    bottom: 1.75rem;
  }

  .lg\:bottom-8 {
    bottom: 2rem;
  }

  .lg\:bottom-9 {
    bottom: 2.25rem;
  }

  .lg\:bottom-10 {
    bottom: 2.5rem;
  }

  .lg\:bottom-11 {
    bottom: 2.75rem;
  }

  .lg\:bottom-12 {
    bottom: 3rem;
  }

  .lg\:bottom-14 {
    bottom: 3.5rem;
  }

  .lg\:bottom-16 {
    bottom: 4rem;
  }

  .lg\:bottom-20 {
    bottom: 5rem;
  }

  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:bottom-28 {
    bottom: 7rem;
  }

  .lg\:bottom-32 {
    bottom: 8rem;
  }

  .lg\:bottom-36 {
    bottom: 9rem;
  }

  .lg\:bottom-40 {
    bottom: 10rem;
  }

  .lg\:bottom-44 {
    bottom: 11rem;
  }

  .lg\:bottom-48 {
    bottom: 12rem;
  }

  .lg\:bottom-52 {
    bottom: 13rem;
  }

  .lg\:bottom-56 {
    bottom: 14rem;
  }

  .lg\:bottom-60 {
    bottom: 15rem;
  }

  .lg\:bottom-64 {
    bottom: 16rem;
  }

  .lg\:bottom-72 {
    bottom: 18rem;
  }

  .lg\:bottom-80 {
    bottom: 20rem;
  }

  .lg\:bottom-96 {
    bottom: 24rem;
  }

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:bottom-px {
    bottom: 1px;
  }

  .lg\:bottom-0\.5 {
    bottom: .125rem;
  }

  .lg\:bottom-1\.5 {
    bottom: .375rem;
  }

  .lg\:bottom-2\.5 {
    bottom: .625rem;
  }

  .lg\:bottom-3\.5 {
    bottom: .875rem;
  }

  .lg\:-bottom-0 {
    bottom: 0;
  }

  .lg\:-bottom-1 {
    bottom: -.25rem;
  }

  .lg\:-bottom-2 {
    bottom: -.5rem;
  }

  .lg\:-bottom-3 {
    bottom: -.75rem;
  }

  .lg\:-bottom-4 {
    bottom: -1rem;
  }

  .lg\:-bottom-5 {
    bottom: -1.25rem;
  }

  .lg\:-bottom-6 {
    bottom: -1.5rem;
  }

  .lg\:-bottom-7 {
    bottom: -1.75rem;
  }

  .lg\:-bottom-8 {
    bottom: -2rem;
  }

  .lg\:-bottom-9 {
    bottom: -2.25rem;
  }

  .lg\:-bottom-10 {
    bottom: -2.5rem;
  }

  .lg\:-bottom-11 {
    bottom: -2.75rem;
  }

  .lg\:-bottom-12 {
    bottom: -3rem;
  }

  .lg\:-bottom-14 {
    bottom: -3.5rem;
  }

  .lg\:-bottom-16 {
    bottom: -4rem;
  }

  .lg\:-bottom-20 {
    bottom: -5rem;
  }

  .lg\:-bottom-24 {
    bottom: -6rem;
  }

  .lg\:-bottom-28 {
    bottom: -7rem;
  }

  .lg\:-bottom-32 {
    bottom: -8rem;
  }

  .lg\:-bottom-36 {
    bottom: -9rem;
  }

  .lg\:-bottom-40 {
    bottom: -10rem;
  }

  .lg\:-bottom-44 {
    bottom: -11rem;
  }

  .lg\:-bottom-48 {
    bottom: -12rem;
  }

  .lg\:-bottom-52 {
    bottom: -13rem;
  }

  .lg\:-bottom-56 {
    bottom: -14rem;
  }

  .lg\:-bottom-60 {
    bottom: -15rem;
  }

  .lg\:-bottom-64 {
    bottom: -16rem;
  }

  .lg\:-bottom-72 {
    bottom: -18rem;
  }

  .lg\:-bottom-80 {
    bottom: -20rem;
  }

  .lg\:-bottom-96 {
    bottom: -24rem;
  }

  .lg\:-bottom-px {
    bottom: -1px;
  }

  .lg\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .lg\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .lg\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .lg\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .lg\:bottom-1\/2 {
    bottom: 50%;
  }

  .lg\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .lg\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .lg\:bottom-1\/4 {
    bottom: 25%;
  }

  .lg\:bottom-2\/4 {
    bottom: 50%;
  }

  .lg\:bottom-3\/4 {
    bottom: 75%;
  }

  .lg\:bottom-full {
    bottom: 100%;
  }

  .lg\:-bottom-1\/2 {
    bottom: -50%;
  }

  .lg\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .lg\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .lg\:-bottom-1\/4 {
    bottom: -25%;
  }

  .lg\:-bottom-2\/4 {
    bottom: -50%;
  }

  .lg\:-bottom-3\/4 {
    bottom: -75%;
  }

  .lg\:-bottom-full {
    bottom: -100%;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:left-1 {
    left: .25rem;
  }

  .lg\:left-2 {
    left: .5rem;
  }

  .lg\:left-3 {
    left: .75rem;
  }

  .lg\:left-4 {
    left: 1rem;
  }

  .lg\:left-5 {
    left: 1.25rem;
  }

  .lg\:left-6 {
    left: 1.5rem;
  }

  .lg\:left-7 {
    left: 1.75rem;
  }

  .lg\:left-8 {
    left: 2rem;
  }

  .lg\:left-9 {
    left: 2.25rem;
  }

  .lg\:left-10 {
    left: 2.5rem;
  }

  .lg\:left-11 {
    left: 2.75rem;
  }

  .lg\:left-12 {
    left: 3rem;
  }

  .lg\:left-14 {
    left: 3.5rem;
  }

  .lg\:left-16 {
    left: 4rem;
  }

  .lg\:left-20 {
    left: 5rem;
  }

  .lg\:left-24 {
    left: 6rem;
  }

  .lg\:left-28 {
    left: 7rem;
  }

  .lg\:left-32 {
    left: 8rem;
  }

  .lg\:left-36 {
    left: 9rem;
  }

  .lg\:left-40 {
    left: 10rem;
  }

  .lg\:left-44 {
    left: 11rem;
  }

  .lg\:left-48 {
    left: 12rem;
  }

  .lg\:left-52 {
    left: 13rem;
  }

  .lg\:left-56 {
    left: 14rem;
  }

  .lg\:left-60 {
    left: 15rem;
  }

  .lg\:left-64 {
    left: 16rem;
  }

  .lg\:left-72 {
    left: 18rem;
  }

  .lg\:left-80 {
    left: 20rem;
  }

  .lg\:left-96 {
    left: 24rem;
  }

  .lg\:left-auto {
    left: auto;
  }

  .lg\:left-px {
    left: 1px;
  }

  .lg\:left-0\.5 {
    left: .125rem;
  }

  .lg\:left-1\.5 {
    left: .375rem;
  }

  .lg\:left-2\.5 {
    left: .625rem;
  }

  .lg\:left-3\.5 {
    left: .875rem;
  }

  .lg\:-left-0 {
    left: 0;
  }

  .lg\:-left-1 {
    left: -.25rem;
  }

  .lg\:-left-2 {
    left: -.5rem;
  }

  .lg\:-left-3 {
    left: -.75rem;
  }

  .lg\:-left-4 {
    left: -1rem;
  }

  .lg\:-left-5 {
    left: -1.25rem;
  }

  .lg\:-left-6 {
    left: -1.5rem;
  }

  .lg\:-left-7 {
    left: -1.75rem;
  }

  .lg\:-left-8 {
    left: -2rem;
  }

  .lg\:-left-9 {
    left: -2.25rem;
  }

  .lg\:-left-10 {
    left: -2.5rem;
  }

  .lg\:-left-11 {
    left: -2.75rem;
  }

  .lg\:-left-12 {
    left: -3rem;
  }

  .lg\:-left-14 {
    left: -3.5rem;
  }

  .lg\:-left-16 {
    left: -4rem;
  }

  .lg\:-left-20 {
    left: -5rem;
  }

  .lg\:-left-24 {
    left: -6rem;
  }

  .lg\:-left-28 {
    left: -7rem;
  }

  .lg\:-left-32 {
    left: -8rem;
  }

  .lg\:-left-36 {
    left: -9rem;
  }

  .lg\:-left-40 {
    left: -10rem;
  }

  .lg\:-left-44 {
    left: -11rem;
  }

  .lg\:-left-48 {
    left: -12rem;
  }

  .lg\:-left-52 {
    left: -13rem;
  }

  .lg\:-left-56 {
    left: -14rem;
  }

  .lg\:-left-60 {
    left: -15rem;
  }

  .lg\:-left-64 {
    left: -16rem;
  }

  .lg\:-left-72 {
    left: -18rem;
  }

  .lg\:-left-80 {
    left: -20rem;
  }

  .lg\:-left-96 {
    left: -24rem;
  }

  .lg\:-left-px {
    left: -1px;
  }

  .lg\:-left-0\.5 {
    left: -.125rem;
  }

  .lg\:-left-1\.5 {
    left: -.375rem;
  }

  .lg\:-left-2\.5 {
    left: -.625rem;
  }

  .lg\:-left-3\.5 {
    left: -.875rem;
  }

  .lg\:left-1\/2 {
    left: 50%;
  }

  .lg\:left-1\/3 {
    left: 33.3333%;
  }

  .lg\:left-2\/3 {
    left: 66.6667%;
  }

  .lg\:left-1\/4 {
    left: 25%;
  }

  .lg\:left-2\/4 {
    left: 50%;
  }

  .lg\:left-3\/4 {
    left: 75%;
  }

  .lg\:left-full {
    left: 100%;
  }

  .lg\:-left-1\/2 {
    left: -50%;
  }

  .lg\:-left-1\/3 {
    left: -33.3333%;
  }

  .lg\:-left-2\/3 {
    left: -66.6667%;
  }

  .lg\:-left-1\/4 {
    left: -25%;
  }

  .lg\:-left-2\/4 {
    left: -50%;
  }

  .lg\:-left-3\/4 {
    left: -75%;
  }

  .lg\:-left-full {
    left: -100%;
  }

  .lg\:isolate {
    isolation: isolate;
  }

  .lg\:isolation-auto {
    isolation: auto;
  }

  .lg\:z-0 {
    z-index: 0;
  }

  .lg\:z-10 {
    z-index: 10;
  }

  .lg\:z-20 {
    z-index: 20;
  }

  .lg\:z-30 {
    z-index: 30;
  }

  .lg\:z-40 {
    z-index: 40;
  }

  .lg\:z-50 {
    z-index: 50;
  }

  .lg\:z-auto {
    z-index: auto;
  }

  .lg\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .lg\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .lg\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .lg\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .lg\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .lg\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .lg\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .lg\:focus\:z-0:focus {
    z-index: 0;
  }

  .lg\:focus\:z-10:focus {
    z-index: 10;
  }

  .lg\:focus\:z-20:focus {
    z-index: 20;
  }

  .lg\:focus\:z-30:focus {
    z-index: 30;
  }

  .lg\:focus\:z-40:focus {
    z-index: 40;
  }

  .lg\:focus\:z-50:focus {
    z-index: 50;
  }

  .lg\:focus\:z-auto:focus {
    z-index: auto;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:order-5 {
    order: 5;
  }

  .lg\:order-6 {
    order: 6;
  }

  .lg\:order-7 {
    order: 7;
  }

  .lg\:order-8 {
    order: 8;
  }

  .lg\:order-9 {
    order: 9;
  }

  .lg\:order-10 {
    order: 10;
  }

  .lg\:order-11 {
    order: 11;
  }

  .lg\:order-12 {
    order: 12;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:col-auto {
    grid-column: auto;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-11 {
    grid-column-start: 11;
  }

  .lg\:col-start-12 {
    grid-column-start: 12;
  }

  .lg\:col-start-13 {
    grid-column-start: 13;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:col-end-3 {
    grid-column-end: 3;
  }

  .lg\:col-end-4 {
    grid-column-end: 4;
  }

  .lg\:col-end-5 {
    grid-column-end: 5;
  }

  .lg\:col-end-6 {
    grid-column-end: 6;
  }

  .lg\:col-end-7 {
    grid-column-end: 7;
  }

  .lg\:col-end-8 {
    grid-column-end: 8;
  }

  .lg\:col-end-9 {
    grid-column-end: 9;
  }

  .lg\:col-end-10 {
    grid-column-end: 10;
  }

  .lg\:col-end-11 {
    grid-column-end: 11;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:col-end-auto {
    grid-column-end: auto;
  }

  .lg\:row-auto {
    grid-row: auto;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:row-start-4 {
    grid-row-start: 4;
  }

  .lg\:row-start-5 {
    grid-row-start: 5;
  }

  .lg\:row-start-6 {
    grid-row-start: 6;
  }

  .lg\:row-start-7 {
    grid-row-start: 7;
  }

  .lg\:row-start-auto {
    grid-row-start: auto;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:row-end-3 {
    grid-row-end: 3;
  }

  .lg\:row-end-4 {
    grid-row-end: 4;
  }

  .lg\:row-end-5 {
    grid-row-end: 5;
  }

  .lg\:row-end-6 {
    grid-row-end: 6;
  }

  .lg\:row-end-7 {
    grid-row-end: 7;
  }

  .lg\:row-end-auto {
    grid-row-end: auto;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:float-none {
    float: none;
  }

  .lg\:clear-left {
    clear: left;
  }

  .lg\:clear-right {
    clear: right;
  }

  .lg\:clear-both {
    clear: both;
  }

  .lg\:clear-none {
    clear: none;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:m-1 {
    margin: .25rem;
  }

  .lg\:m-2 {
    margin: .5rem;
  }

  .lg\:m-3 {
    margin: .75rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:m-5 {
    margin: 1.25rem;
  }

  .lg\:m-6 {
    margin: 1.5rem;
  }

  .lg\:m-7 {
    margin: 1.75rem;
  }

  .lg\:m-8 {
    margin: 2rem;
  }

  .lg\:m-9 {
    margin: 2.25rem;
  }

  .lg\:m-10 {
    margin: 2.5rem;
  }

  .lg\:m-11 {
    margin: 2.75rem;
  }

  .lg\:m-12 {
    margin: 3rem;
  }

  .lg\:m-14 {
    margin: 3.5rem;
  }

  .lg\:m-16 {
    margin: 4rem;
  }

  .lg\:m-20 {
    margin: 5rem;
  }

  .lg\:m-24 {
    margin: 6rem;
  }

  .lg\:m-28 {
    margin: 7rem;
  }

  .lg\:m-32 {
    margin: 8rem;
  }

  .lg\:m-36 {
    margin: 9rem;
  }

  .lg\:m-40 {
    margin: 10rem;
  }

  .lg\:m-44 {
    margin: 11rem;
  }

  .lg\:m-48 {
    margin: 12rem;
  }

  .lg\:m-52 {
    margin: 13rem;
  }

  .lg\:m-56 {
    margin: 14rem;
  }

  .lg\:m-60 {
    margin: 15rem;
  }

  .lg\:m-64 {
    margin: 16rem;
  }

  .lg\:m-72 {
    margin: 18rem;
  }

  .lg\:m-80 {
    margin: 20rem;
  }

  .lg\:m-96 {
    margin: 24rem;
  }

  .lg\:m-auto {
    margin: auto;
  }

  .lg\:m-px {
    margin: 1px;
  }

  .lg\:m-0\.5 {
    margin: .125rem;
  }

  .lg\:m-1\.5 {
    margin: .375rem;
  }

  .lg\:m-2\.5 {
    margin: .625rem;
  }

  .lg\:m-3\.5 {
    margin: .875rem;
  }

  .lg\:-m-0 {
    margin: 0;
  }

  .lg\:-m-1 {
    margin: -.25rem;
  }

  .lg\:-m-2 {
    margin: -.5rem;
  }

  .lg\:-m-3 {
    margin: -.75rem;
  }

  .lg\:-m-4 {
    margin: -1rem;
  }

  .lg\:-m-5 {
    margin: -1.25rem;
  }

  .lg\:-m-6 {
    margin: -1.5rem;
  }

  .lg\:-m-7 {
    margin: -1.75rem;
  }

  .lg\:-m-8 {
    margin: -2rem;
  }

  .lg\:-m-9 {
    margin: -2.25rem;
  }

  .lg\:-m-10 {
    margin: -2.5rem;
  }

  .lg\:-m-11 {
    margin: -2.75rem;
  }

  .lg\:-m-12 {
    margin: -3rem;
  }

  .lg\:-m-14 {
    margin: -3.5rem;
  }

  .lg\:-m-16 {
    margin: -4rem;
  }

  .lg\:-m-20 {
    margin: -5rem;
  }

  .lg\:-m-24 {
    margin: -6rem;
  }

  .lg\:-m-28 {
    margin: -7rem;
  }

  .lg\:-m-32 {
    margin: -8rem;
  }

  .lg\:-m-36 {
    margin: -9rem;
  }

  .lg\:-m-40 {
    margin: -10rem;
  }

  .lg\:-m-44 {
    margin: -11rem;
  }

  .lg\:-m-48 {
    margin: -12rem;
  }

  .lg\:-m-52 {
    margin: -13rem;
  }

  .lg\:-m-56 {
    margin: -14rem;
  }

  .lg\:-m-60 {
    margin: -15rem;
  }

  .lg\:-m-64 {
    margin: -16rem;
  }

  .lg\:-m-72 {
    margin: -18rem;
  }

  .lg\:-m-80 {
    margin: -20rem;
  }

  .lg\:-m-96 {
    margin: -24rem;
  }

  .lg\:-m-px {
    margin: -1px;
  }

  .lg\:-m-0\.5 {
    margin: -.125rem;
  }

  .lg\:-m-1\.5 {
    margin: -.375rem;
  }

  .lg\:-m-2\.5 {
    margin: -.625rem;
  }

  .lg\:-m-3\.5 {
    margin: -.875rem;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .lg\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .lg\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .lg\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .lg\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .lg\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .lg\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .lg\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .lg\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .lg\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .lg\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .lg\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .lg\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .lg\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .lg\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .lg\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .lg\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .lg\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .lg\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .lg\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .lg\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .lg\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .lg\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .lg\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .lg\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .lg\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .lg\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-1 {
    margin-top: .25rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-3 {
    margin-top: .75rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mt-7 {
    margin-top: 1.75rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-11 {
    margin-top: 2.75rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-28 {
    margin-top: 7rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mt-36 {
    margin-top: 9rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mt-44 {
    margin-top: 11rem;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:mt-52 {
    margin-top: 13rem;
  }

  .lg\:mt-56 {
    margin-top: 14rem;
  }

  .lg\:mt-60 {
    margin-top: 15rem;
  }

  .lg\:mt-64 {
    margin-top: 16rem;
  }

  .lg\:mt-72 {
    margin-top: 18rem;
  }

  .lg\:mt-80 {
    margin-top: 20rem;
  }

  .lg\:mt-96 {
    margin-top: 24rem;
  }

  .lg\:mt-auto {
    margin-top: auto;
  }

  .lg\:mt-px {
    margin-top: 1px;
  }

  .lg\:mt-0\.5 {
    margin-top: .125rem;
  }

  .lg\:mt-1\.5 {
    margin-top: .375rem;
  }

  .lg\:mt-2\.5 {
    margin-top: .625rem;
  }

  .lg\:mt-3\.5 {
    margin-top: .875rem;
  }

  .lg\:-mt-0 {
    margin-top: 0;
  }

  .lg\:-mt-1 {
    margin-top: -.25rem;
  }

  .lg\:-mt-2 {
    margin-top: -.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg\:-mt-4 {
    margin-top: -1rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:-mt-7 {
    margin-top: -1.75rem;
  }

  .lg\:-mt-8 {
    margin-top: -2rem;
  }

  .lg\:-mt-9 {
    margin-top: -2.25rem;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:-mt-11 {
    margin-top: -2.75rem;
  }

  .lg\:-mt-12 {
    margin-top: -3rem;
  }

  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }

  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mt-20 {
    margin-top: -5rem;
  }

  .lg\:-mt-24 {
    margin-top: -6rem;
  }

  .lg\:-mt-28 {
    margin-top: -7rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:-mt-36 {
    margin-top: -9rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:-mt-44 {
    margin-top: -11rem;
  }

  .lg\:-mt-48 {
    margin-top: -12rem;
  }

  .lg\:-mt-52 {
    margin-top: -13rem;
  }

  .lg\:-mt-56 {
    margin-top: -14rem;
  }

  .lg\:-mt-60 {
    margin-top: -15rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:-mt-72 {
    margin-top: -18rem;
  }

  .lg\:-mt-80 {
    margin-top: -20rem;
  }

  .lg\:-mt-96 {
    margin-top: -24rem;
  }

  .lg\:-mt-px {
    margin-top: -1px;
  }

  .lg\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .lg\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .lg\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .lg\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-1 {
    margin-right: .25rem;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mr-7 {
    margin-right: 1.75rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mr-9 {
    margin-right: 2.25rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mr-11 {
    margin-right: 2.75rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:mr-14 {
    margin-right: 3.5rem;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mr-20 {
    margin-right: 5rem;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mr-28 {
    margin-right: 7rem;
  }

  .lg\:mr-32 {
    margin-right: 8rem;
  }

  .lg\:mr-36 {
    margin-right: 9rem;
  }

  .lg\:mr-40 {
    margin-right: 10rem;
  }

  .lg\:mr-44 {
    margin-right: 11rem;
  }

  .lg\:mr-48 {
    margin-right: 12rem;
  }

  .lg\:mr-52 {
    margin-right: 13rem;
  }

  .lg\:mr-56 {
    margin-right: 14rem;
  }

  .lg\:mr-60 {
    margin-right: 15rem;
  }

  .lg\:mr-64 {
    margin-right: 16rem;
  }

  .lg\:mr-72 {
    margin-right: 18rem;
  }

  .lg\:mr-80 {
    margin-right: 20rem;
  }

  .lg\:mr-96 {
    margin-right: 24rem;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mr-px {
    margin-right: 1px;
  }

  .lg\:mr-0\.5 {
    margin-right: .125rem;
  }

  .lg\:mr-1\.5 {
    margin-right: .375rem;
  }

  .lg\:mr-2\.5 {
    margin-right: .625rem;
  }

  .lg\:mr-3\.5 {
    margin-right: .875rem;
  }

  .lg\:-mr-0 {
    margin-right: 0;
  }

  .lg\:-mr-1 {
    margin-right: -.25rem;
  }

  .lg\:-mr-2 {
    margin-right: -.5rem;
  }

  .lg\:-mr-3 {
    margin-right: -.75rem;
  }

  .lg\:-mr-4 {
    margin-right: -1rem;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:-mr-7 {
    margin-right: -1.75rem;
  }

  .lg\:-mr-8 {
    margin-right: -2rem;
  }

  .lg\:-mr-9 {
    margin-right: -2.25rem;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:-mr-11 {
    margin-right: -2.75rem;
  }

  .lg\:-mr-12 {
    margin-right: -3rem;
  }

  .lg\:-mr-14 {
    margin-right: -3.5rem;
  }

  .lg\:-mr-16 {
    margin-right: -4rem;
  }

  .lg\:-mr-20 {
    margin-right: -5rem;
  }

  .lg\:-mr-24 {
    margin-right: -6rem;
  }

  .lg\:-mr-28 {
    margin-right: -7rem;
  }

  .lg\:-mr-32 {
    margin-right: -8rem;
  }

  .lg\:-mr-36 {
    margin-right: -9rem;
  }

  .lg\:-mr-40 {
    margin-right: -10rem;
  }

  .lg\:-mr-44 {
    margin-right: -11rem;
  }

  .lg\:-mr-48 {
    margin-right: -12rem;
  }

  .lg\:-mr-52 {
    margin-right: -13rem;
  }

  .lg\:-mr-56 {
    margin-right: -14rem;
  }

  .lg\:-mr-60 {
    margin-right: -15rem;
  }

  .lg\:-mr-64 {
    margin-right: -16rem;
  }

  .lg\:-mr-72 {
    margin-right: -18rem;
  }

  .lg\:-mr-80 {
    margin-right: -20rem;
  }

  .lg\:-mr-96 {
    margin-right: -24rem;
  }

  .lg\:-mr-px {
    margin-right: -1px;
  }

  .lg\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .lg\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .lg\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .lg\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-28 {
    margin-bottom: 7rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:mb-36 {
    margin-bottom: 9rem;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:mb-44 {
    margin-bottom: 11rem;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:mb-52 {
    margin-bottom: 13rem;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:mb-60 {
    margin-bottom: 15rem;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:mb-72 {
    margin-bottom: 18rem;
  }

  .lg\:mb-80 {
    margin-bottom: 20rem;
  }

  .lg\:mb-96 {
    margin-bottom: 24rem;
  }

  .lg\:mb-auto {
    margin-bottom: auto;
  }

  .lg\:mb-px {
    margin-bottom: 1px;
  }

  .lg\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .lg\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .lg\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .lg\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .lg\:-mb-0 {
    margin-bottom: 0;
  }

  .lg\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .lg\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .lg\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:-mb-28 {
    margin-bottom: -7rem;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:-mb-36 {
    margin-bottom: -9rem;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:-mb-44 {
    margin-bottom: -11rem;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:-mb-52 {
    margin-bottom: -13rem;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:-mb-60 {
    margin-bottom: -15rem;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:-mb-72 {
    margin-bottom: -18rem;
  }

  .lg\:-mb-80 {
    margin-bottom: -20rem;
  }

  .lg\:-mb-96 {
    margin-bottom: -24rem;
  }

  .lg\:-mb-px {
    margin-bottom: -1px;
  }

  .lg\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .lg\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .lg\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .lg\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:ml-3 {
    margin-left: .75rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:ml-7 {
    margin-left: 1.75rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:ml-9 {
    margin-left: 2.25rem;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:ml-11 {
    margin-left: 2.75rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:ml-14 {
    margin-left: 3.5rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:ml-20 {
    margin-left: 5rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:ml-28 {
    margin-left: 7rem;
  }

  .lg\:ml-32 {
    margin-left: 8rem;
  }

  .lg\:ml-36 {
    margin-left: 9rem;
  }

  .lg\:ml-40 {
    margin-left: 10rem;
  }

  .lg\:ml-44 {
    margin-left: 11rem;
  }

  .lg\:ml-48 {
    margin-left: 12rem;
  }

  .lg\:ml-52 {
    margin-left: 13rem;
  }

  .lg\:ml-56 {
    margin-left: 14rem;
  }

  .lg\:ml-60 {
    margin-left: 15rem;
  }

  .lg\:ml-64 {
    margin-left: 16rem;
  }

  .lg\:ml-72 {
    margin-left: 18rem;
  }

  .lg\:ml-80 {
    margin-left: 20rem;
  }

  .lg\:ml-96 {
    margin-left: 24rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:ml-px {
    margin-left: 1px;
  }

  .lg\:ml-0\.5 {
    margin-left: .125rem;
  }

  .lg\:ml-1\.5 {
    margin-left: .375rem;
  }

  .lg\:ml-2\.5 {
    margin-left: .625rem;
  }

  .lg\:ml-3\.5 {
    margin-left: .875rem;
  }

  .lg\:-ml-0 {
    margin-left: 0;
  }

  .lg\:-ml-1 {
    margin-left: -.25rem;
  }

  .lg\:-ml-2 {
    margin-left: -.5rem;
  }

  .lg\:-ml-3 {
    margin-left: -.75rem;
  }

  .lg\:-ml-4 {
    margin-left: -1rem;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:-ml-7 {
    margin-left: -1.75rem;
  }

  .lg\:-ml-8 {
    margin-left: -2rem;
  }

  .lg\:-ml-9 {
    margin-left: -2.25rem;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:-ml-11 {
    margin-left: -2.75rem;
  }

  .lg\:-ml-12 {
    margin-left: -3rem;
  }

  .lg\:-ml-14 {
    margin-left: -3.5rem;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-ml-20 {
    margin-left: -5rem;
  }

  .lg\:-ml-24 {
    margin-left: -6rem;
  }

  .lg\:-ml-28 {
    margin-left: -7rem;
  }

  .lg\:-ml-32 {
    margin-left: -8rem;
  }

  .lg\:-ml-36 {
    margin-left: -9rem;
  }

  .lg\:-ml-40 {
    margin-left: -10rem;
  }

  .lg\:-ml-44 {
    margin-left: -11rem;
  }

  .lg\:-ml-48 {
    margin-left: -12rem;
  }

  .lg\:-ml-52 {
    margin-left: -13rem;
  }

  .lg\:-ml-56 {
    margin-left: -14rem;
  }

  .lg\:-ml-60 {
    margin-left: -15rem;
  }

  .lg\:-ml-64 {
    margin-left: -16rem;
  }

  .lg\:-ml-72 {
    margin-left: -18rem;
  }

  .lg\:-ml-80 {
    margin-left: -20rem;
  }

  .lg\:-ml-96 {
    margin-left: -24rem;
  }

  .lg\:-ml-px {
    margin-left: -1px;
  }

  .lg\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .lg\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .lg\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .lg\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .lg\:box-border {
    box-sizing: border-box;
  }

  .lg\:box-content {
    box-sizing: content-box;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:table {
    display: table;
  }

  .lg\:inline-table {
    display: inline-table;
  }

  .lg\:table-caption {
    display: table-caption;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:table-column {
    display: table-column;
  }

  .lg\:table-column-group {
    display: table-column-group;
  }

  .lg\:table-footer-group {
    display: table-footer-group;
  }

  .lg\:table-header-group {
    display: table-header-group;
  }

  .lg\:table-row-group {
    display: table-row-group;
  }

  .lg\:table-row {
    display: table-row;
  }

  .lg\:flow-root {
    display: flow-root;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:inline-grid {
    display: inline-grid;
  }

  .lg\:contents {
    display: contents;
  }

  .lg\:list-item {
    display: list-item;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-0 {
    height: 0;
  }

  .lg\:h-1 {
    height: .25rem;
  }

  .lg\:h-2 {
    height: .5rem;
  }

  .lg\:h-3 {
    height: .75rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-7 {
    height: 1.75rem;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-9 {
    height: 2.25rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-11 {
    height: 2.75rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-14 {
    height: 3.5rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-28 {
    height: 7rem;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:h-36 {
    height: 9rem;
  }

  .lg\:h-40 {
    height: 10rem;
  }

  .lg\:h-44 {
    height: 11rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:h-52 {
    height: 13rem;
  }

  .lg\:h-56 {
    height: 14rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:h-72 {
    height: 18rem;
  }

  .lg\:h-80 {
    height: 20rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-px {
    height: 1px;
  }

  .lg\:h-0\.5 {
    height: .125rem;
  }

  .lg\:h-1\.5 {
    height: .375rem;
  }

  .lg\:h-2\.5 {
    height: .625rem;
  }

  .lg\:h-3\.5 {
    height: .875rem;
  }

  .lg\:h-1\/2 {
    height: 50%;
  }

  .lg\:h-1\/3 {
    height: 33.3333%;
  }

  .lg\:h-2\/3 {
    height: 66.6667%;
  }

  .lg\:h-1\/4 {
    height: 25%;
  }

  .lg\:h-2\/4 {
    height: 50%;
  }

  .lg\:h-3\/4 {
    height: 75%;
  }

  .lg\:h-1\/5 {
    height: 20%;
  }

  .lg\:h-2\/5 {
    height: 40%;
  }

  .lg\:h-3\/5 {
    height: 60%;
  }

  .lg\:h-4\/5 {
    height: 80%;
  }

  .lg\:h-1\/6 {
    height: 16.6667%;
  }

  .lg\:h-2\/6 {
    height: 33.3333%;
  }

  .lg\:h-3\/6 {
    height: 50%;
  }

  .lg\:h-4\/6 {
    height: 66.6667%;
  }

  .lg\:h-5\/6 {
    height: 83.3333%;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-0 {
    max-height: 0;
  }

  .lg\:max-h-1 {
    max-height: .25rem;
  }

  .lg\:max-h-2 {
    max-height: .5rem;
  }

  .lg\:max-h-3 {
    max-height: .75rem;
  }

  .lg\:max-h-4 {
    max-height: 1rem;
  }

  .lg\:max-h-5 {
    max-height: 1.25rem;
  }

  .lg\:max-h-6 {
    max-height: 1.5rem;
  }

  .lg\:max-h-7 {
    max-height: 1.75rem;
  }

  .lg\:max-h-8 {
    max-height: 2rem;
  }

  .lg\:max-h-9 {
    max-height: 2.25rem;
  }

  .lg\:max-h-10 {
    max-height: 2.5rem;
  }

  .lg\:max-h-11 {
    max-height: 2.75rem;
  }

  .lg\:max-h-12 {
    max-height: 3rem;
  }

  .lg\:max-h-14 {
    max-height: 3.5rem;
  }

  .lg\:max-h-16 {
    max-height: 4rem;
  }

  .lg\:max-h-20 {
    max-height: 5rem;
  }

  .lg\:max-h-24 {
    max-height: 6rem;
  }

  .lg\:max-h-28 {
    max-height: 7rem;
  }

  .lg\:max-h-32 {
    max-height: 8rem;
  }

  .lg\:max-h-36 {
    max-height: 9rem;
  }

  .lg\:max-h-40 {
    max-height: 10rem;
  }

  .lg\:max-h-44 {
    max-height: 11rem;
  }

  .lg\:max-h-48 {
    max-height: 12rem;
  }

  .lg\:max-h-52 {
    max-height: 13rem;
  }

  .lg\:max-h-56 {
    max-height: 14rem;
  }

  .lg\:max-h-60 {
    max-height: 15rem;
  }

  .lg\:max-h-64 {
    max-height: 16rem;
  }

  .lg\:max-h-72 {
    max-height: 18rem;
  }

  .lg\:max-h-80 {
    max-height: 20rem;
  }

  .lg\:max-h-96 {
    max-height: 24rem;
  }

  .lg\:max-h-px {
    max-height: 1px;
  }

  .lg\:max-h-0\.5 {
    max-height: .125rem;
  }

  .lg\:max-h-1\.5 {
    max-height: .375rem;
  }

  .lg\:max-h-2\.5 {
    max-height: .625rem;
  }

  .lg\:max-h-3\.5 {
    max-height: .875rem;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:max-h-screen {
    max-height: 100vh;
  }

  .lg\:min-h-0 {
    min-height: 0;
  }

  .lg\:min-h-full {
    min-height: 100%;
  }

  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-1 {
    width: .25rem;
  }

  .lg\:w-2 {
    width: .5rem;
  }

  .lg\:w-3 {
    width: .75rem;
  }

  .lg\:w-4 {
    width: 1rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-7 {
    width: 1.75rem;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-9 {
    width: 2.25rem;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-11 {
    width: 2.75rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-14 {
    width: 3.5rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-52 {
    width: 13rem;
  }

  .lg\:w-56 {
    width: 14rem;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-px {
    width: 1px;
  }

  .lg\:w-0\.5 {
    width: .125rem;
  }

  .lg\:w-1\.5 {
    width: .375rem;
  }

  .lg\:w-2\.5 {
    width: .625rem;
  }

  .lg\:w-3\.5 {
    width: .875rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-1\/5 {
    width: 20%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-1\/6 {
    width: 16.6667%;
  }

  .lg\:w-2\/6 {
    width: 33.3333%;
  }

  .lg\:w-3\/6 {
    width: 50%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-1\/12 {
    width: 8.33333%;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-screen {
    width: 100vw;
  }

  .lg\:w-min {
    width: min-content;
  }

  .lg\:w-max {
    width: max-content;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:min-w-full {
    min-width: 100%;
  }

  .lg\:min-w-min {
    min-width: min-content;
  }

  .lg\:min-w-max {
    min-width: max-content;
  }

  .lg\:max-w-0 {
    max-width: 0;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-min {
    max-width: min-content;
  }

  .lg\:max-w-max {
    max-width: max-content;
  }

  .lg\:max-w-prose {
    max-width: 65ch;
  }

  .lg\:max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:max-w-screen-md {
    max-width: 768px;
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px;
  }

  .lg\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-initial {
    flex: 0 auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-shrink {
    flex-shrink: 1;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:table-auto {
    table-layout: auto;
  }

  .lg\:table-fixed {
    table-layout: fixed;
  }

  .lg\:border-collapse {
    border-collapse: collapse;
  }

  .lg\:border-separate {
    border-collapse: separate;
  }

  .lg\:origin-center {
    transform-origin: center;
  }

  .lg\:origin-top {
    transform-origin: top;
  }

  .lg\:origin-top-right {
    transform-origin: 100% 0;
  }

  .lg\:origin-right {
    transform-origin: 100%;
  }

  .lg\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .lg\:origin-bottom {
    transform-origin: bottom;
  }

  .lg\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .lg\:origin-left {
    transform-origin: 0;
  }

  .lg\:origin-top-left {
    transform-origin: 0 0;
  }

  .lg\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-none {
    transform: none;
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .lg\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .lg\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .lg\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .lg\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .lg\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .lg\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .lg\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .lg\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .lg\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .lg\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .lg\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .lg\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .lg\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .lg\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .lg\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .lg\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .lg\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .lg\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .lg\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .lg\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .lg\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .lg\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .lg\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .lg\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .lg\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .lg\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .lg\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .lg\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .lg\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .lg\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .lg\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .lg\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .lg\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .lg\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .lg\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .lg\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .lg\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .lg\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .lg\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .lg\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .lg\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .lg\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .lg\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .lg\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .lg\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .lg\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .lg\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .lg\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .lg\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .lg\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .lg\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .lg\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .lg\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .lg\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .lg\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .lg\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .lg\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .lg\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .lg\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .lg\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .lg\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .lg\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .lg\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .lg\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .lg\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .lg\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .lg\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .lg\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .lg\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .lg\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .lg\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .lg\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .lg\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .lg\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .lg\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .lg\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .lg\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .lg\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .lg\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .lg\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .lg\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .lg\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .lg\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .lg\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .lg\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .lg\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .lg\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .lg\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .lg\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .lg\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .lg\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .lg\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .lg\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .lg\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .lg\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .lg\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .lg\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .lg\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .lg\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .lg\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .lg\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .lg\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .lg\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .lg\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .lg\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .lg\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .lg\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .lg\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .lg\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .lg\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .lg\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .lg\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .lg\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .lg\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .lg\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .lg\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .lg\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .lg\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .lg\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .lg\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .lg\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .lg\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .lg\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .lg\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .lg\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .lg\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .lg\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .lg\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .lg\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .lg\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .lg\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .lg\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .lg\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .lg\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .lg\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .lg\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .lg\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .lg\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .lg\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .lg\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .lg\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .lg\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .lg\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .lg\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .lg\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .lg\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .lg\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .lg\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .lg\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .lg\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .lg\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .lg\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .lg\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .lg\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .lg\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .lg\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .lg\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .lg\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .lg\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .lg\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .lg\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .lg\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .lg\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .lg\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .lg\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .lg\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .lg\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .lg\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .lg\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .lg\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .lg\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .lg\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .lg\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .lg\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .lg\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .lg\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .lg\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .lg\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .lg\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .lg\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .lg\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .lg\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .lg\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .lg\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .lg\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .lg\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .lg\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .lg\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .lg\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .lg\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .lg\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .lg\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .lg\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .lg\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .lg\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .lg\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .lg\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .lg\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .lg\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .lg\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .lg\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .lg\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .lg\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .lg\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .lg\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .lg\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .lg\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .lg\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .lg\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .lg\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .lg\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .lg\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .lg\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .lg\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .lg\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .lg\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .lg\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .lg\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .lg\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .lg\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .lg\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .lg\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .lg\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .lg\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .lg\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .lg\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .lg\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .lg\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .lg\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .lg\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .lg\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .lg\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .lg\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .lg\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .lg\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .lg\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .lg\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .lg\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .lg\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .lg\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .lg\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .lg\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .lg\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .lg\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .lg\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .lg\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .lg\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .lg\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .lg\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .lg\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .lg\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .lg\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .lg\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .lg\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .lg\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .lg\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .lg\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .lg\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .lg\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .lg\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .lg\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .lg\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .lg\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .lg\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .lg\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .lg\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .lg\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .lg\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .lg\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .lg\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .lg\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .lg\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .lg\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .lg\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .lg\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .lg\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .lg\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .lg\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .lg\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .lg\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .lg\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .lg\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .lg\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .lg\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .lg\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .lg\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .lg\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .lg\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .lg\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .lg\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .lg\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .lg\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .lg\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .lg\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .lg\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .lg\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .lg\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .lg\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .lg\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .lg\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .lg\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .lg\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .lg\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .lg\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .lg\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .lg\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .lg\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .lg\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .lg\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .lg\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .lg\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .lg\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .lg\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .lg\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .lg\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .lg\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .lg\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .lg\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .lg\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .lg\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .lg\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .lg\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .lg\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .lg\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .lg\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .lg\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .lg\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .lg\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .lg\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .lg\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .lg\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .lg\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .lg\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .lg\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .lg\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .lg\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .lg\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .lg\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .lg\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .lg\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .lg\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .lg\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .lg\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .lg\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .lg\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .lg\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .lg\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .lg\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .lg\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .lg\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .lg\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .lg\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .lg\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .lg\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .lg\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .lg\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .lg\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .lg\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .lg\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .lg\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .lg\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .lg\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .lg\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .lg\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .lg\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .lg\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .lg\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .lg\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .lg\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .lg\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .lg\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .lg\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .lg\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .lg\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .lg\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .lg\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .lg\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .lg\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .lg\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .lg\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .lg\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .lg\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .lg\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .lg\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .lg\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .lg\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .lg\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .lg\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .lg\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .lg\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .lg\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .lg\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .lg\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .lg\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .lg\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .lg\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .lg\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .lg\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .lg\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .lg\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .lg\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .lg\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .lg\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .lg\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .lg\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .lg\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .lg\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .lg\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .lg\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .lg\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .lg\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .lg\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .lg\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .lg\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .lg\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .lg\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .lg\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .lg\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .lg\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .lg\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .lg\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .lg\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .lg\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .lg\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .lg\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .lg\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .lg\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .lg\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .lg\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .lg\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .lg\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .lg\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .lg\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .lg\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .lg\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .lg\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .lg\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .lg\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .lg\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .lg\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .lg\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .lg\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .lg\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .lg\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .lg\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .lg\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .lg\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .lg\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .lg\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .lg\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .lg\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .lg\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .lg\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .lg\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .lg\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .lg\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .lg\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .lg\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .lg\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .lg\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .lg\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .lg\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .lg\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .lg\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .lg\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .lg\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .lg\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .lg\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .lg\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .lg\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .lg\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .lg\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .lg\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .lg\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .lg\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .lg\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .lg\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .lg\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .lg\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .lg\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .lg\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .lg\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .lg\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .lg\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .lg\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .lg\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .lg\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .lg\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .lg\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .lg\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .lg\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .lg\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .lg\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .lg\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .lg\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .lg\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .lg\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .lg\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .lg\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .lg\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .lg\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .lg\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .lg\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .lg\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .lg\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .lg\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .lg\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .lg\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .lg\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .lg\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .lg\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .lg\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .lg\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .lg\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .lg\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .lg\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .lg\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .lg\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .lg\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .lg\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .lg\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .lg\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .lg\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .lg\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .lg\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .lg\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .lg\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .lg\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .lg\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .lg\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .lg\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .lg\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .lg\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .lg\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .lg\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .lg\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .lg\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .lg\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .lg\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .lg\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .lg\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .lg\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .lg\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .lg\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .lg\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .lg\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .lg\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .lg\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .lg\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .lg\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .lg\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .lg\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .lg\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .lg\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .lg\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .lg\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .lg\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .lg\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .lg\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .lg\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .lg\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .lg\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .lg\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .lg\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .lg\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .lg\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .lg\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .lg\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .lg\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .lg\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .lg\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .lg\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .lg\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .lg\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .lg\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .lg\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .lg\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .lg\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .lg\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .lg\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .lg\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .lg\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .lg\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .lg\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .lg\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .lg\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .lg\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .lg\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .lg\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .lg\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .lg\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .lg\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .lg\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .lg\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .lg\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .lg\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .lg\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .lg\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .lg\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .lg\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .lg\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .lg\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .lg\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .lg\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .lg\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .lg\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .lg\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .lg\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .lg\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .lg\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .lg\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .lg\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .lg\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .lg\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .lg\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .lg\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .lg\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .lg\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .lg\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .lg\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .lg\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .lg\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .lg\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .lg\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .lg\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .lg\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .lg\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .lg\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .lg\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .lg\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .lg\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .lg\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .lg\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .lg\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .lg\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .lg\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .lg\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .lg\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .lg\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .lg\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .lg\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .lg\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .lg\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .lg\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .lg\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .lg\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .lg\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .lg\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .lg\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .lg\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .lg\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .lg\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .lg\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .lg\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .lg\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .lg\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .lg\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .lg\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .lg\:animate-none {
    animation: none;
  }

  .lg\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .lg\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .lg\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .lg\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .lg\:cursor-auto {
    cursor: auto;
  }

  .lg\:cursor-default {
    cursor: default;
  }

  .lg\:cursor-pointer {
    cursor: pointer;
  }

  .lg\:cursor-wait {
    cursor: wait;
  }

  .lg\:cursor-text {
    cursor: text;
  }

  .lg\:cursor-move {
    cursor: move;
  }

  .lg\:cursor-help {
    cursor: help;
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .lg\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .lg\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .lg\:resize-none {
    resize: none;
  }

  .lg\:resize-y {
    resize: vertical;
  }

  .lg\:resize-x {
    resize: horizontal;
  }

  .lg\:resize {
    resize: both;
  }

  .lg\:list-inside {
    list-style-position: inside;
  }

  .lg\:list-outside {
    list-style-position: outside;
  }

  .lg\:list-none {
    list-style-type: none;
  }

  .lg\:list-disc {
    list-style-type: disc;
  }

  .lg\:list-decimal {
    list-style-type: decimal;
  }

  .lg\:appearance-none {
    appearance: none;
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .lg\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .lg\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:place-content-center {
    place-content: center;
  }

  .lg\:place-content-start {
    place-content: start;
  }

  .lg\:place-content-end {
    place-content: end;
  }

  .lg\:place-content-between {
    place-content: space-between;
  }

  .lg\:place-content-around {
    place-content: space-around;
  }

  .lg\:place-content-evenly {
    place-content: space-evenly;
  }

  .lg\:place-content-stretch {
    place-content: stretch;
  }

  .lg\:place-items-start {
    place-items: start;
  }

  .lg\:place-items-end {
    place-items: end;
  }

  .lg\:place-items-center {
    place-items: center;
  }

  .lg\:place-items-stretch {
    place-items: stretch stretch;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:content-evenly {
    align-content: space-evenly;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-baseline {
    align-items: baseline;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:justify-items-start {
    justify-items: start;
  }

  .lg\:justify-items-end {
    justify-items: end;
  }

  .lg\:justify-items-center {
    justify-items: center;
  }

  .lg\:justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-1 {
    gap: .25rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-3 {
    gap: .75rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-7 {
    gap: 1.75rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-9 {
    gap: 2.25rem;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-11 {
    gap: 2.75rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-28 {
    gap: 7rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-36 {
    gap: 9rem;
  }

  .lg\:gap-40 {
    gap: 10rem;
  }

  .lg\:gap-44 {
    gap: 11rem;
  }

  .lg\:gap-48 {
    gap: 12rem;
  }

  .lg\:gap-52 {
    gap: 13rem;
  }

  .lg\:gap-56 {
    gap: 14rem;
  }

  .lg\:gap-60 {
    gap: 15rem;
  }

  .lg\:gap-64 {
    gap: 16rem;
  }

  .lg\:gap-72 {
    gap: 18rem;
  }

  .lg\:gap-80 {
    gap: 20rem;
  }

  .lg\:gap-96 {
    gap: 24rem;
  }

  .lg\:gap-px {
    gap: 1px;
  }

  .lg\:gap-0\.5 {
    gap: .125rem;
  }

  .lg\:gap-1\.5 {
    gap: .375rem;
  }

  .lg\:gap-2\.5 {
    gap: .625rem;
  }

  .lg\:gap-3\.5 {
    gap: .875rem;
  }

  .lg\:gap-x-0 {
    column-gap: 0;
  }

  .lg\:gap-x-1 {
    column-gap: .25rem;
  }

  .lg\:gap-x-2 {
    column-gap: .5rem;
  }

  .lg\:gap-x-3 {
    column-gap: .75rem;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .lg\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .lg\:gap-x-16 {
    column-gap: 4rem;
  }

  .lg\:gap-x-20 {
    column-gap: 5rem;
  }

  .lg\:gap-x-24 {
    column-gap: 6rem;
  }

  .lg\:gap-x-28 {
    column-gap: 7rem;
  }

  .lg\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg\:gap-x-36 {
    column-gap: 9rem;
  }

  .lg\:gap-x-40 {
    column-gap: 10rem;
  }

  .lg\:gap-x-44 {
    column-gap: 11rem;
  }

  .lg\:gap-x-48 {
    column-gap: 12rem;
  }

  .lg\:gap-x-52 {
    column-gap: 13rem;
  }

  .lg\:gap-x-56 {
    column-gap: 14rem;
  }

  .lg\:gap-x-60 {
    column-gap: 15rem;
  }

  .lg\:gap-x-64 {
    column-gap: 16rem;
  }

  .lg\:gap-x-72 {
    column-gap: 18rem;
  }

  .lg\:gap-x-80 {
    column-gap: 20rem;
  }

  .lg\:gap-x-96 {
    column-gap: 24rem;
  }

  .lg\:gap-x-px {
    column-gap: 1px;
  }

  .lg\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .lg\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .lg\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .lg\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .lg\:gap-y-0 {
    row-gap: 0;
  }

  .lg\:gap-y-1 {
    row-gap: .25rem;
  }

  .lg\:gap-y-2 {
    row-gap: .5rem;
  }

  .lg\:gap-y-3 {
    row-gap: .75rem;
  }

  .lg\:gap-y-4 {
    row-gap: 1rem;
  }

  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .lg\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .lg\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .lg\:gap-y-8 {
    row-gap: 2rem;
  }

  .lg\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .lg\:gap-y-12 {
    row-gap: 3rem;
  }

  .lg\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .lg\:gap-y-16 {
    row-gap: 4rem;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:gap-y-24 {
    row-gap: 6rem;
  }

  .lg\:gap-y-28 {
    row-gap: 7rem;
  }

  .lg\:gap-y-32 {
    row-gap: 8rem;
  }

  .lg\:gap-y-36 {
    row-gap: 9rem;
  }

  .lg\:gap-y-40 {
    row-gap: 10rem;
  }

  .lg\:gap-y-44 {
    row-gap: 11rem;
  }

  .lg\:gap-y-48 {
    row-gap: 12rem;
  }

  .lg\:gap-y-52 {
    row-gap: 13rem;
  }

  .lg\:gap-y-56 {
    row-gap: 14rem;
  }

  .lg\:gap-y-60 {
    row-gap: 15rem;
  }

  .lg\:gap-y-64 {
    row-gap: 16rem;
  }

  .lg\:gap-y-72 {
    row-gap: 18rem;
  }

  .lg\:gap-y-80 {
    row-gap: 20rem;
  }

  .lg\:gap-y-96 {
    row-gap: 24rem;
  }

  .lg\:gap-y-px {
    row-gap: 1px;
  }

  .lg\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .lg\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .lg\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .lg\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .lg\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .lg\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .lg\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .lg\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .lg\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .lg\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .lg\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .lg\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .lg\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .lg\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .lg\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .lg\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .lg\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .lg\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .lg\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .lg\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .lg\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .lg\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .lg\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .lg\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .lg\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .lg\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .lg\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .lg\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .lg\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .lg\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .lg\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .lg\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .lg\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .lg\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .lg\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .lg\:place-self-auto {
    place-self: auto;
  }

  .lg\:place-self-start {
    place-self: start;
  }

  .lg\:place-self-end {
    place-self: end;
  }

  .lg\:place-self-center {
    place-self: center;
  }

  .lg\:place-self-stretch {
    place-self: stretch stretch;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:self-baseline {
    align-self: baseline;
  }

  .lg\:justify-self-auto {
    justify-self: auto;
  }

  .lg\:justify-self-start {
    justify-self: start;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:justify-self-center {
    justify-self: center;
  }

  .lg\:justify-self-stretch {
    justify-self: stretch;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:overflow-scroll {
    overflow: scroll;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .lg\:overscroll-none {
    overscroll-behavior: none;
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .lg\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .lg\:overflow-clip {
    text-overflow: clip;
  }

  .lg\:whitespace-normal {
    white-space: normal;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:whitespace-pre {
    white-space: pre;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:break-words {
    overflow-wrap: break-word;
  }

  .lg\:break-all {
    word-break: break-all;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:rounded-sm {
    border-radius: .125rem;
  }

  .lg\:rounded {
    border-radius: .25rem;
  }

  .lg\:rounded-md {
    border-radius: .375rem;
  }

  .lg\:rounded-lg {
    border-radius: .5rem;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg\:rounded-full {
    border-radius: 9999px;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .lg\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .lg\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .lg\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-2 {
    border-width: 2px;
  }

  .lg\:border-4 {
    border-width: 4px;
  }

  .lg\:border-8 {
    border-width: 8px;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-t-2 {
    border-top-width: 2px;
  }

  .lg\:border-t-4 {
    border-top-width: 4px;
  }

  .lg\:border-t-8 {
    border-top-width: 8px;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-r-4 {
    border-right-width: 4px;
  }

  .lg\:border-r-8 {
    border-right-width: 8px;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-l-0 {
    border-left-width: 0;
  }

  .lg\:border-l-2 {
    border-left-width: 2px;
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-l-8 {
    border-left-width: 8px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:border-solid {
    border-style: solid;
  }

  .lg\:border-dashed {
    border-style: dashed;
  }

  .lg\:border-dotted {
    border-style: dotted;
  }

  .lg\:border-double {
    border-style: double;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-transparent {
    border-color: #0000;
  }

  .lg\:border-current {
    border-color: currentColor;
  }

  .lg\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .lg\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .lg\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .lg\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .lg\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .lg\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .lg\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .lg\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .lg\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .lg\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .lg\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .lg\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .lg\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .lg\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .lg\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .lg\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .lg\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .lg\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .lg\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .lg\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .lg\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .lg\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .lg\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .lg\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .lg\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .lg\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .lg\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .lg\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .lg\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .lg\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .lg\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .lg\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .lg\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .lg\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .lg\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .lg\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .lg\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .lg\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .lg\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .lg\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .lg\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .lg\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .lg\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .lg\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .lg\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .lg\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .lg\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .lg\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .lg\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .lg\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .lg\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .lg\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .lg\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .lg\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .lg\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .lg\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .lg\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .lg\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .lg\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .lg\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .lg\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .lg\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .lg\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .lg\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .lg\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .lg\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .lg\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .lg\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .lg\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .lg\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .lg\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .lg\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .lg\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .lg\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .lg\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .lg\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .lg\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .lg\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .lg\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .lg\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .lg\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .lg\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .lg\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .lg\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:bg-current {
    background-color: currentColor;
  }

  .lg\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .lg\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .lg\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .lg\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .lg\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .lg\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .lg\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .lg\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .lg\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .lg\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .lg\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .lg\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .lg\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .lg\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .lg\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .lg\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .lg\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .lg\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .lg\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .lg\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .lg\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .lg\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .lg\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .lg\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .lg\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .lg\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .lg\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .lg\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .lg\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .lg\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .lg\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .lg\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .lg\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .lg\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .lg\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .lg\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .lg\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .lg\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .lg\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .lg\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .lg\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .lg\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .lg\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .lg\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .lg\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .lg\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .lg\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .lg\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .lg\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .lg\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .lg\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .lg\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .lg\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .lg\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .lg\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .lg\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .lg\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .lg\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .lg\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .lg\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .lg\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .lg\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .lg\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .lg\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .lg\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .lg\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .lg\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .lg\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .lg\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .lg\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .lg\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .lg\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .lg\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .lg\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .lg\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .lg\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .lg\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .lg\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .lg\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .lg\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .lg\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .lg\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .lg\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .lg\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .lg\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .lg\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .lg\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .lg\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .lg\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .lg\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .lg\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .lg\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .lg\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .lg\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .lg\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .lg\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .lg\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .lg\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .lg\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .lg\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .lg\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .lg\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .lg\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .lg\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .lg\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .lg\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .lg\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .lg\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .lg\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .lg\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .lg\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .lg\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .lg\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .lg\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .lg\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .lg\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .lg\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .lg\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .lg\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .lg\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .lg\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .lg\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .lg\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .lg\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .lg\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .lg\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .lg\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .lg\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .lg\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .lg\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .lg\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .lg\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .lg\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .lg\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .lg\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .lg\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .lg\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .lg\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .lg\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .lg\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .lg\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .lg\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .lg\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .lg\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .lg\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .lg\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .lg\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .lg\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .lg\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .lg\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .lg\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .lg\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .lg\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .lg\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .lg\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .lg\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .lg\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .lg\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .lg\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .lg\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .lg\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .lg\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .lg\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .lg\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .lg\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .lg\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .lg\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .lg\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .lg\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .lg\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .lg\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .lg\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .lg\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .lg\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .lg\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .lg\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .lg\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .lg\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .lg\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .lg\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .lg\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .lg\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .lg\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .lg\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .lg\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .lg\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .lg\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .lg\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .lg\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .lg\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .lg\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .lg\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .lg\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .lg\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .lg\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .lg\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .lg\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .lg\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .lg\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .lg\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .lg\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .lg\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .lg\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .lg\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .lg\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .lg\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .lg\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .lg\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .lg\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .lg\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .lg\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .lg\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .lg\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .lg\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .lg\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .lg\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .lg\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .lg\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .lg\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .lg\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .lg\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .lg\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .lg\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .lg\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .lg\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .lg\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .lg\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .lg\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .lg\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .lg\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .lg\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .lg\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .lg\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .lg\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .lg\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .lg\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .lg\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .lg\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .lg\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .lg\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .lg\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .lg\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .lg\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .lg\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .lg\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .lg\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .lg\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .lg\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .lg\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .lg\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .lg\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .lg\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .lg\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .lg\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .lg\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .lg\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .lg\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .lg\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .lg\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .lg\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .lg\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .lg\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .lg\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .lg\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .lg\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .lg\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .lg\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .lg\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .lg\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .lg\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .lg\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .lg\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .lg\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .lg\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .lg\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .lg\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .lg\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .lg\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .lg\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .lg\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .lg\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .lg\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .lg\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .lg\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .lg\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .lg\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .lg\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .lg\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .lg\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .lg\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .lg\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .lg\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .lg\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .lg\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .lg\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .lg\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .lg\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .lg\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .lg\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .lg\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .lg\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .lg\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .lg\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .lg\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .lg\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .lg\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .lg\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .lg\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .lg\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .lg\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .lg\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .lg\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .lg\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .lg\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .lg\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .lg\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .lg\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .lg\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .lg\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .lg\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .lg\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .lg\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .lg\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .lg\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .lg\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .lg\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .lg\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .lg\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .lg\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .lg\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .lg\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .lg\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .lg\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .lg\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .lg\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .lg\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .lg\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .lg\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .lg\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .lg\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .lg\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .lg\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .lg\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .lg\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .lg\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .lg\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .lg\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .lg\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .lg\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .lg\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .lg\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .lg\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .lg\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .lg\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .lg\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .lg\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .lg\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .lg\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .lg\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .lg\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .lg\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .lg\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .lg\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .lg\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .lg\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .lg\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .lg\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .lg\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .lg\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .lg\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .lg\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .lg\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .lg\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .lg\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .lg\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .lg\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .lg\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .lg\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .lg\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .lg\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .lg\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .lg\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .lg\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .lg\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .lg\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .lg\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .lg\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .lg\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .lg\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .lg\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .lg\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .lg\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .lg\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .lg\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .lg\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .lg\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .lg\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .lg\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .lg\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .lg\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .lg\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .lg\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .lg\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .lg\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .lg\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .lg\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .lg\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .lg\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .lg\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .lg\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .lg\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .lg\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .lg\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .lg\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .lg\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .lg\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .lg\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .lg\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .lg\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .lg\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .lg\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .lg\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .lg\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .lg\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .lg\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .lg\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .lg\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .lg\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .lg\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .lg\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .lg\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .lg\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .lg\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .lg\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .lg\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .lg\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .lg\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .lg\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .lg\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .lg\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .lg\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .lg\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .lg\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .lg\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .lg\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .lg\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .lg\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .lg\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .lg\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .lg\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .lg\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .lg\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .lg\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .lg\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .lg\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .lg\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .lg\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .lg\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .lg\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .lg\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .lg\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .lg\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .lg\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .lg\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .lg\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .lg\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .lg\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .lg\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .lg\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .lg\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .lg\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .lg\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .lg\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .lg\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .lg\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .lg\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .lg\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .lg\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .lg\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .lg\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .lg\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .lg\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .lg\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .lg\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .lg\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .lg\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .lg\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .lg\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .lg\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .lg\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .lg\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .lg\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .lg\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .lg\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .lg\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .lg\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .lg\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .lg\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .lg\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .lg\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .lg\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .lg\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .lg\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .lg\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .lg\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .lg\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .lg\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .lg\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .lg\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .lg\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .lg\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .lg\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .lg\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .lg\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .lg\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .lg\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .lg\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .lg\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .lg\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .lg\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .lg\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .lg\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .lg\:to-current {
    --tw-gradient-to: currentColor;
  }

  .lg\:to-black {
    --tw-gradient-to: #000;
  }

  .lg\:to-white {
    --tw-gradient-to: #fff;
  }

  .lg\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .lg\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .lg\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .lg\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .lg\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .lg\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .lg\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .lg\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .lg\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .lg\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .lg\:to-red {
    --tw-gradient-to: red;
  }

  .lg\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .lg\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .lg\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .lg\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .lg\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .lg\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .lg\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .lg\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .lg\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .lg\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .lg\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .lg\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .lg\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .lg\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .lg\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .lg\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .lg\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .lg\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .lg\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .lg\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .lg\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .lg\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .lg\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .lg\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .lg\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .lg\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .lg\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .lg\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .lg\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .lg\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .lg\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .lg\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .lg\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .lg\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .lg\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .lg\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .lg\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .lg\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .lg\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .lg\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .lg\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .lg\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .lg\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .lg\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .lg\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .lg\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .lg\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .lg\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .lg\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .lg\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .lg\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .lg\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .lg\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .lg\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .lg\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .lg\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .lg\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .lg\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .lg\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .lg\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .lg\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .lg\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .lg\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .lg\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .lg\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .lg\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .lg\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .lg\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .lg\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .lg\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .lg\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .lg\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .lg\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .lg\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .lg\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .lg\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .lg\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .lg\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .lg\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .lg\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .lg\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .lg\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .lg\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .lg\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .lg\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .lg\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .lg\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .lg\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .lg\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .lg\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .lg\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .lg\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .lg\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .lg\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .lg\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .lg\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .lg\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .lg\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .lg\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .lg\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .lg\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .lg\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .lg\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .lg\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .lg\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .lg\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .lg\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .lg\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .lg\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .lg\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .lg\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .lg\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .lg\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .lg\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .lg\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .lg\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .lg\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .lg\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .lg\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .lg\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .lg\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .lg\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .lg\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .lg\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .lg\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .lg\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .lg\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .lg\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .lg\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .lg\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .lg\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .lg\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .lg\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .lg\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .lg\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .lg\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .lg\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .lg\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .lg\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .lg\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .lg\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .lg\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .lg\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .lg\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .lg\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .lg\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .lg\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .lg\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .lg\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .lg\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .lg\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .lg\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .lg\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .lg\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .lg\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .lg\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .lg\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .lg\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .lg\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .lg\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .lg\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .lg\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .lg\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .lg\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .lg\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .lg\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .lg\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .lg\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .lg\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .lg\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .lg\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .lg\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .lg\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .lg\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .lg\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .lg\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .lg\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .lg\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .lg\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .lg\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .lg\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .lg\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .lg\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .lg\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .lg\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .lg\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .lg\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .lg\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .lg\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .lg\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .lg\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .lg\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .lg\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .lg\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .lg\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .lg\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .lg\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .lg\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .lg\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .lg\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .lg\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .lg\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .lg\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .lg\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .lg\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .lg\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .lg\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .lg\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .lg\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .lg\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .lg\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .lg\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .lg\:bg-auto {
    background-size: auto;
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:bg-fixed {
    background-attachment: fixed;
  }

  .lg\:bg-local {
    background-attachment: local;
  }

  .lg\:bg-scroll {
    background-attachment: scroll;
  }

  .lg\:bg-clip-border {
    background-clip: border-box;
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box;
  }

  .lg\:bg-clip-content {
    background-clip: content-box;
  }

  .lg\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .lg\:bg-bottom {
    background-position: bottom;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:bg-left {
    background-position: 0;
  }

  .lg\:bg-left-bottom {
    background-position: 0 100%;
  }

  .lg\:bg-left-top {
    background-position: 0 0;
  }

  .lg\:bg-right {
    background-position: 100%;
  }

  .lg\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .lg\:bg-right-top {
    background-position: 100% 0;
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:bg-repeat {
    background-repeat: repeat;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:bg-repeat-round {
    background-repeat: round;
  }

  .lg\:bg-repeat-space {
    background-repeat: space;
  }

  .lg\:bg-origin-border {
    background-origin: border-box;
  }

  .lg\:bg-origin-padding {
    background-origin: padding-box;
  }

  .lg\:bg-origin-content {
    background-origin: content-box;
  }

  .lg\:fill-current {
    fill: currentColor;
  }

  .lg\:stroke-current {
    stroke: currentColor;
  }

  .lg\:stroke-0 {
    stroke-width: 0;
  }

  .lg\:stroke-1 {
    stroke-width: 1px;
  }

  .lg\:stroke-2 {
    stroke-width: 2px;
  }

  .lg\:object-contain {
    object-fit: contain;
  }

  .lg\:object-cover {
    object-fit: cover;
  }

  .lg\:object-fill {
    object-fit: fill;
  }

  .lg\:object-none {
    object-fit: none;
  }

  .lg\:object-scale-down {
    object-fit: scale-down;
  }

  .lg\:object-bottom {
    object-position: bottom;
  }

  .lg\:object-center {
    object-position: center;
  }

  .lg\:object-left {
    object-position: left;
  }

  .lg\:object-left-bottom {
    object-position: left bottom;
  }

  .lg\:object-left-top {
    object-position: left top;
  }

  .lg\:object-right {
    object-position: right;
  }

  .lg\:object-right-bottom {
    object-position: right bottom;
  }

  .lg\:object-right-top {
    object-position: right top;
  }

  .lg\:object-top {
    object-position: top;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-7 {
    padding: 1.75rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-9 {
    padding: 2.25rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-11 {
    padding: 2.75rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-14 {
    padding: 3.5rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-24 {
    padding: 6rem;
  }

  .lg\:p-28 {
    padding: 7rem;
  }

  .lg\:p-32 {
    padding: 8rem;
  }

  .lg\:p-36 {
    padding: 9rem;
  }

  .lg\:p-40 {
    padding: 10rem;
  }

  .lg\:p-44 {
    padding: 11rem;
  }

  .lg\:p-48 {
    padding: 12rem;
  }

  .lg\:p-52 {
    padding: 13rem;
  }

  .lg\:p-56 {
    padding: 14rem;
  }

  .lg\:p-60 {
    padding: 15rem;
  }

  .lg\:p-64 {
    padding: 16rem;
  }

  .lg\:p-72 {
    padding: 18rem;
  }

  .lg\:p-80 {
    padding: 20rem;
  }

  .lg\:p-96 {
    padding: 24rem;
  }

  .lg\:p-px {
    padding: 1px;
  }

  .lg\:p-0\.5 {
    padding: .125rem;
  }

  .lg\:p-1\.5 {
    padding: .375rem;
  }

  .lg\:p-2\.5 {
    padding: .625rem;
  }

  .lg\:p-3\.5 {
    padding: .875rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .lg\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .lg\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .lg\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .lg\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .lg\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .lg\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .lg\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .lg\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .lg\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .lg\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-1 {
    padding-top: .25rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-3 {
    padding-top: .75rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pt-7 {
    padding-top: 1.75rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pt-9 {
    padding-top: 2.25rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-36 {
    padding-top: 9rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:pt-48 {
    padding-top: 12rem;
  }

  .lg\:pt-52 {
    padding-top: 13rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:pt-64 {
    padding-top: 16rem;
  }

  .lg\:pt-72 {
    padding-top: 18rem;
  }

  .lg\:pt-80 {
    padding-top: 20rem;
  }

  .lg\:pt-96 {
    padding-top: 24rem;
  }

  .lg\:pt-px {
    padding-top: 1px;
  }

  .lg\:pt-0\.5 {
    padding-top: .125rem;
  }

  .lg\:pt-1\.5 {
    padding-top: .375rem;
  }

  .lg\:pt-2\.5 {
    padding-top: .625rem;
  }

  .lg\:pt-3\.5 {
    padding-top: .875rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-1 {
    padding-right: .25rem;
  }

  .lg\:pr-2 {
    padding-right: .5rem;
  }

  .lg\:pr-3 {
    padding-right: .75rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pr-7 {
    padding-right: 1.75rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pr-9 {
    padding-right: 2.25rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pr-11 {
    padding-right: 2.75rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-14 {
    padding-right: 3.5rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pr-28 {
    padding-right: 7rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pr-36 {
    padding-right: 9rem;
  }

  .lg\:pr-40 {
    padding-right: 10rem;
  }

  .lg\:pr-44 {
    padding-right: 11rem;
  }

  .lg\:pr-48 {
    padding-right: 12rem;
  }

  .lg\:pr-52 {
    padding-right: 13rem;
  }

  .lg\:pr-56 {
    padding-right: 14rem;
  }

  .lg\:pr-60 {
    padding-right: 15rem;
  }

  .lg\:pr-64 {
    padding-right: 16rem;
  }

  .lg\:pr-72 {
    padding-right: 18rem;
  }

  .lg\:pr-80 {
    padding-right: 20rem;
  }

  .lg\:pr-96 {
    padding-right: 24rem;
  }

  .lg\:pr-px {
    padding-right: 1px;
  }

  .lg\:pr-0\.5 {
    padding-right: .125rem;
  }

  .lg\:pr-1\.5 {
    padding-right: .375rem;
  }

  .lg\:pr-2\.5 {
    padding-right: .625rem;
  }

  .lg\:pr-3\.5 {
    padding-right: .875rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-1 {
    padding-bottom: .25rem;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:pb-52 {
    padding-bottom: 13rem;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:pb-72 {
    padding-bottom: 18rem;
  }

  .lg\:pb-80 {
    padding-bottom: 20rem;
  }

  .lg\:pb-96 {
    padding-bottom: 24rem;
  }

  .lg\:pb-px {
    padding-bottom: 1px;
  }

  .lg\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .lg\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .lg\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .lg\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pl-1 {
    padding-left: .25rem;
  }

  .lg\:pl-2 {
    padding-left: .5rem;
  }

  .lg\:pl-3 {
    padding-left: .75rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-7 {
    padding-left: 1.75rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pl-9 {
    padding-left: 2.25rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-11 {
    padding-left: 2.75rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pl-28 {
    padding-left: 7rem;
  }

  .lg\:pl-32 {
    padding-left: 8rem;
  }

  .lg\:pl-36 {
    padding-left: 9rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pl-44 {
    padding-left: 11rem;
  }

  .lg\:pl-48 {
    padding-left: 12rem;
  }

  .lg\:pl-52 {
    padding-left: 13rem;
  }

  .lg\:pl-56 {
    padding-left: 14rem;
  }

  .lg\:pl-60 {
    padding-left: 15rem;
  }

  .lg\:pl-64 {
    padding-left: 16rem;
  }

  .lg\:pl-72 {
    padding-left: 18rem;
  }

  .lg\:pl-80 {
    padding-left: 20rem;
  }

  .lg\:pl-96 {
    padding-left: 24rem;
  }

  .lg\:pl-px {
    padding-left: 1px;
  }

  .lg\:pl-0\.5 {
    padding-left: .125rem;
  }

  .lg\:pl-1\.5 {
    padding-left: .375rem;
  }

  .lg\:pl-2\.5 {
    padding-left: .625rem;
  }

  .lg\:pl-3\.5 {
    padding-left: .875rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:align-baseline {
    vertical-align: baseline;
  }

  .lg\:align-top {
    vertical-align: top;
  }

  .lg\:align-middle {
    vertical-align: middle;
  }

  .lg\:align-bottom {
    vertical-align: bottom;
  }

  .lg\:align-text-top {
    vertical-align: text-top;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:font-body {
    font-family: Montserrat;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .lg\:font-thin {
    font-weight: 100;
  }

  .lg\:font-extralight {
    font-weight: 200;
  }

  .lg\:font-light {
    font-weight: 300;
  }

  .lg\:font-normal {
    font-weight: 400;
  }

  .lg\:font-medium {
    font-weight: 500;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:font-bold {
    font-weight: 700;
  }

  .lg\:font-extrabold {
    font-weight: 800;
  }

  .lg\:font-black {
    font-weight: 900;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:lowercase {
    text-transform: lowercase;
  }

  .lg\:capitalize {
    text-transform: capitalize;
  }

  .lg\:normal-case {
    text-transform: none;
  }

  .lg\:italic {
    font-style: italic;
  }

  .lg\:not-italic {
    font-style: normal;
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:normal-nums {
    font-variant-numeric: normal;
  }

  .lg\:ordinal {
    --tw-ordinal: ordinal;
  }

  .lg\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .lg\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .lg\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .lg\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .lg\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .lg\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .lg\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .lg\:leading-3 {
    line-height: .75rem;
  }

  .lg\:leading-4 {
    line-height: 1rem;
  }

  .lg\:leading-5 {
    line-height: 1.25rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:leading-7 {
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:leading-9 {
    line-height: 2.25rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-none {
    line-height: 1;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:leading-snug {
    line-height: 1.375;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }

  .lg\:leading-loose {
    line-height: 2;
  }

  .lg\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .lg\:tracking-tight {
    letter-spacing: -.025em;
  }

  .lg\:tracking-normal {
    letter-spacing: 0;
  }

  .lg\:tracking-wide {
    letter-spacing: .025em;
  }

  .lg\:tracking-wider {
    letter-spacing: .05em;
  }

  .lg\:tracking-widest {
    letter-spacing: .1em;
  }

  .lg\:text-transparent {
    color: #0000;
  }

  .lg\:text-current {
    color: currentColor;
  }

  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .lg\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .lg\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .lg\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .lg\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:hover\:text-transparent:hover {
    color: #0000;
  }

  .lg\:hover\:text-current:hover {
    color: currentColor;
  }

  .lg\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:focus\:text-transparent:focus {
    color: #0000;
  }

  .lg\:focus\:text-current:focus {
    color: currentColor;
  }

  .lg\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .lg\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .lg\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .lg\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .lg\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .lg\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .lg\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .lg\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .lg\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .lg\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .lg\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .lg\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .lg\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .lg\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .lg\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .lg\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .lg\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .lg\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .lg\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .lg\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .lg\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .lg\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .lg\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .lg\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .lg\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .lg\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .lg\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .lg\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .lg\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .lg\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .lg\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .lg\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .lg\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .lg\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .lg\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .lg\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .lg\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .lg\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .lg\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .lg\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .lg\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .lg\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .lg\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .lg\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .lg\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .lg\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .lg\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .lg\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .lg\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .lg\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .lg\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .lg\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .lg\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .lg\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .lg\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .lg\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .lg\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .lg\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .lg\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .lg\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .lg\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .lg\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .lg\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .lg\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .lg\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .lg\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .lg\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .lg\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .lg\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .lg\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .lg\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .lg\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .lg\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .lg\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .lg\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .lg\:underline {
    text-decoration: underline;
  }

  .lg\:line-through {
    text-decoration: line-through;
  }

  .lg\:no-underline {
    text-decoration: none;
  }

  .group:hover .lg\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .lg\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .lg\:group-hover\:no-underline {
    text-decoration: none;
  }

  .lg\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .lg\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .lg\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .lg\:placeholder-current::placeholder {
    color: currentColor;
  }

  .lg\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .lg\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .lg\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .lg\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .lg\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .lg\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .lg\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .lg\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .lg\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .lg\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .lg\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .lg\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .lg\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .lg\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .lg\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .lg\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .lg\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .lg\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .lg\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:opacity-5 {
    opacity: .05;
  }

  .lg\:opacity-10 {
    opacity: .1;
  }

  .lg\:opacity-20 {
    opacity: .2;
  }

  .lg\:opacity-25 {
    opacity: .25;
  }

  .lg\:opacity-30 {
    opacity: .3;
  }

  .lg\:opacity-40 {
    opacity: .4;
  }

  .lg\:opacity-50 {
    opacity: .5;
  }

  .lg\:opacity-60 {
    opacity: .6;
  }

  .lg\:opacity-70 {
    opacity: .7;
  }

  .lg\:opacity-75 {
    opacity: .75;
  }

  .lg\:opacity-80 {
    opacity: .8;
  }

  .lg\:opacity-90 {
    opacity: .9;
  }

  .lg\:opacity-95 {
    opacity: .95;
  }

  .lg\:opacity-100 {
    opacity: 1;
  }

  .group:hover .lg\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .lg\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .lg\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .lg\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .lg\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .lg\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .lg\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .lg\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .lg\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .lg\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .lg\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .lg\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .lg\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .lg\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .lg\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .lg\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .lg\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .lg\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .lg\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .lg\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .lg\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .lg\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .lg\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .lg\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .lg\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .lg\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .lg\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .lg\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .lg\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .lg\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .lg\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .lg\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .lg\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .lg\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .lg\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .lg\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .lg\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .lg\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .lg\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .lg\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .lg\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .lg\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .lg\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .lg\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .lg\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .lg\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .lg\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .lg\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .lg\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .lg\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .lg\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .lg\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .lg\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .lg\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .lg\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .lg\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .lg\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .lg\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .lg\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .lg\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .lg\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .lg\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .lg\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .lg\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .lg\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .lg\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .lg\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .lg\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .lg\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .lg\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .lg\:bg-blend-color {
    background-blend-mode: color;
  }

  .lg\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .lg\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .lg\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .lg\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .lg\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .lg\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .lg\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .lg\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .lg\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .lg\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .lg\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .lg\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .lg\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .lg\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .lg\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .lg\:mix-blend-color {
    mix-blend-mode: color;
  }

  .lg\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-inset, .lg\:focus-within\:ring-inset:focus-within, .lg\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .lg\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .lg\:ring-current {
    --tw-ring-color: currentColor;
  }

  .lg\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .lg\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .lg\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .lg\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .lg\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .lg\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .lg\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .lg\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .lg\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .lg\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .lg\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .lg\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .lg\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .lg\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .lg\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .lg\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .lg\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .lg\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .lg\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .lg\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .lg\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .lg\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .lg\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .lg\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .lg\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .lg\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .lg\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .lg\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .lg\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .lg\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .lg\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .lg\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .lg\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .lg\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .lg\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .lg\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .lg\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .lg\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .lg\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .lg\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .lg\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .lg\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .lg\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .lg\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .lg\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .lg\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .lg\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .lg\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .lg\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .lg\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .lg\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .lg\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .lg\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .lg\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .lg\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .lg\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .lg\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .lg\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .lg\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .lg\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .lg\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .lg\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .lg\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .lg\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .lg\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .lg\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .lg\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .lg\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .lg\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .lg\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .lg\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .lg\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .lg\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .lg\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .lg\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .lg\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .lg\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .lg\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .lg\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .lg\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .lg\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .lg\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .lg\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .lg\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .lg\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .lg\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .lg\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .lg\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .lg\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .lg\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .lg\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .lg\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .lg\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .lg\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .lg\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .lg\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .lg\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .lg\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .lg\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .lg\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .lg\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .lg\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .lg\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .lg\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .lg\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .lg\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .lg\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .lg\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .lg\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .lg\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .lg\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .lg\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .lg\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .lg\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .lg\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .lg\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .lg\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .lg\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .lg\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .lg\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .lg\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .lg\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .lg\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .lg\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .lg\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .lg\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .lg\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .lg\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .lg\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .lg\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .lg\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .lg\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .lg\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .lg\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .lg\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .lg\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .lg\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .lg\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .lg\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .lg\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .lg\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .lg\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .lg\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .lg\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .lg\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .lg\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .lg\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .lg\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .lg\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .lg\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .lg\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .lg\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .lg\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:filter-none {
    filter: none;
  }

  .lg\:blur-0, .lg\:blur-none {
    --tw-blur: blur(0);
  }

  .lg\:blur-sm {
    --tw-blur: blur(4px);
  }

  .lg\:blur {
    --tw-blur: blur(8px);
  }

  .lg\:blur-md {
    --tw-blur: blur(12px);
  }

  .lg\:blur-lg {
    --tw-blur: blur(16px);
  }

  .lg\:blur-xl {
    --tw-blur: blur(24px);
  }

  .lg\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .lg\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .lg\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .lg\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .lg\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .lg\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .lg\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .lg\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .lg\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .lg\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .lg\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .lg\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .lg\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .lg\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .lg\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .lg\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .lg\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .lg\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .lg\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .lg\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .lg\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .lg\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .lg\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .lg\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .lg\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .lg\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .lg\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .lg\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .lg\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .lg\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .lg\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .lg\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .lg\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .lg\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .lg\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .lg\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:invert-0 {
    --tw-invert: invert(0);
  }

  .lg\:invert {
    --tw-invert: invert(100%);
  }

  .lg\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .lg\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .lg\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .lg\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .lg\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .lg\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .lg\:sepia {
    --tw-sepia: sepia(100%);
  }

  .lg\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .lg\:backdrop-blur-0, .lg\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .lg\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .lg\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .lg\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .lg\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .lg\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .lg\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .lg\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .lg\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .lg\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .lg\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .lg\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .lg\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .lg\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .lg\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .lg\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .lg\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .lg\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .lg\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .lg\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .lg\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .lg\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .lg\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .lg\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .lg\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .lg\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .lg\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .lg\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .lg\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .lg\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .lg\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .lg\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .lg\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .lg\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .lg\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .lg\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .lg\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .lg\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .lg\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .lg\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .lg\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .lg\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .lg\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .lg\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .lg\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .lg\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .lg\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .lg\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .lg\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .lg\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .lg\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .lg\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .lg\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .lg\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .lg\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .lg\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .lg\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .lg\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .lg\:transition-none {
    transition-property: none;
  }

  .lg\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:delay-75 {
    transition-delay: 75ms;
  }

  .lg\:delay-100 {
    transition-delay: .1s;
  }

  .lg\:delay-150 {
    transition-delay: .15s;
  }

  .lg\:delay-200 {
    transition-delay: .2s;
  }

  .lg\:delay-300 {
    transition-delay: .3s;
  }

  .lg\:delay-500 {
    transition-delay: .5s;
  }

  .lg\:delay-700 {
    transition-delay: .7s;
  }

  .lg\:delay-1000 {
    transition-delay: 1s;
  }

  .lg\:duration-75 {
    transition-duration: 75ms;
  }

  .lg\:duration-100 {
    transition-duration: .1s;
  }

  .lg\:duration-150 {
    transition-duration: .15s;
  }

  .lg\:duration-200 {
    transition-duration: .2s;
  }

  .lg\:duration-300 {
    transition-duration: .3s;
  }

  .lg\:duration-500 {
    transition-duration: .5s;
  }

  .lg\:duration-700 {
    transition-duration: .7s;
  }

  .lg\:duration-1000 {
    transition-duration: 1s;
  }

  .lg\:ease-linear {
    transition-timing-function: linear;
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (max-width: 1023px) {
  .lg-max\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg-max\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg-max\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg-max\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg-max\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .lg-max\:container {
      max-width: 1560px;
    }
  }

  .lg-max\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg-max\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg-max\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg-max\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg-max\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg-max\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg-max\:pointer-events-none {
    pointer-events: none;
  }

  .lg-max\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg-max\:visible {
    visibility: visible;
  }

  .lg-max\:invisible {
    visibility: hidden;
  }

  .lg-max\:static {
    position: static;
  }

  .lg-max\:fixed {
    position: fixed;
  }

  .lg-max\:absolute {
    position: absolute;
  }

  .lg-max\:relative {
    position: relative;
  }

  .lg-max\:sticky {
    position: sticky;
  }

  .lg-max\:inset-0 {
    inset: 0;
  }

  .lg-max\:inset-1 {
    inset: .25rem;
  }

  .lg-max\:inset-2 {
    inset: .5rem;
  }

  .lg-max\:inset-3 {
    inset: .75rem;
  }

  .lg-max\:inset-4 {
    inset: 1rem;
  }

  .lg-max\:inset-5 {
    inset: 1.25rem;
  }

  .lg-max\:inset-6 {
    inset: 1.5rem;
  }

  .lg-max\:inset-7 {
    inset: 1.75rem;
  }

  .lg-max\:inset-8 {
    inset: 2rem;
  }

  .lg-max\:inset-9 {
    inset: 2.25rem;
  }

  .lg-max\:inset-10 {
    inset: 2.5rem;
  }

  .lg-max\:inset-11 {
    inset: 2.75rem;
  }

  .lg-max\:inset-12 {
    inset: 3rem;
  }

  .lg-max\:inset-14 {
    inset: 3.5rem;
  }

  .lg-max\:inset-16 {
    inset: 4rem;
  }

  .lg-max\:inset-20 {
    inset: 5rem;
  }

  .lg-max\:inset-24 {
    inset: 6rem;
  }

  .lg-max\:inset-28 {
    inset: 7rem;
  }

  .lg-max\:inset-32 {
    inset: 8rem;
  }

  .lg-max\:inset-36 {
    inset: 9rem;
  }

  .lg-max\:inset-40 {
    inset: 10rem;
  }

  .lg-max\:inset-44 {
    inset: 11rem;
  }

  .lg-max\:inset-48 {
    inset: 12rem;
  }

  .lg-max\:inset-52 {
    inset: 13rem;
  }

  .lg-max\:inset-56 {
    inset: 14rem;
  }

  .lg-max\:inset-60 {
    inset: 15rem;
  }

  .lg-max\:inset-64 {
    inset: 16rem;
  }

  .lg-max\:inset-72 {
    inset: 18rem;
  }

  .lg-max\:inset-80 {
    inset: 20rem;
  }

  .lg-max\:inset-96 {
    inset: 24rem;
  }

  .lg-max\:inset-auto {
    inset: auto;
  }

  .lg-max\:inset-px {
    inset: 1px;
  }

  .lg-max\:inset-0\.5 {
    inset: .125rem;
  }

  .lg-max\:inset-1\.5 {
    inset: .375rem;
  }

  .lg-max\:inset-2\.5 {
    inset: .625rem;
  }

  .lg-max\:inset-3\.5 {
    inset: .875rem;
  }

  .lg-max\:-inset-0 {
    inset: 0;
  }

  .lg-max\:-inset-1 {
    inset: -.25rem;
  }

  .lg-max\:-inset-2 {
    inset: -.5rem;
  }

  .lg-max\:-inset-3 {
    inset: -.75rem;
  }

  .lg-max\:-inset-4 {
    inset: -1rem;
  }

  .lg-max\:-inset-5 {
    inset: -1.25rem;
  }

  .lg-max\:-inset-6 {
    inset: -1.5rem;
  }

  .lg-max\:-inset-7 {
    inset: -1.75rem;
  }

  .lg-max\:-inset-8 {
    inset: -2rem;
  }

  .lg-max\:-inset-9 {
    inset: -2.25rem;
  }

  .lg-max\:-inset-10 {
    inset: -2.5rem;
  }

  .lg-max\:-inset-11 {
    inset: -2.75rem;
  }

  .lg-max\:-inset-12 {
    inset: -3rem;
  }

  .lg-max\:-inset-14 {
    inset: -3.5rem;
  }

  .lg-max\:-inset-16 {
    inset: -4rem;
  }

  .lg-max\:-inset-20 {
    inset: -5rem;
  }

  .lg-max\:-inset-24 {
    inset: -6rem;
  }

  .lg-max\:-inset-28 {
    inset: -7rem;
  }

  .lg-max\:-inset-32 {
    inset: -8rem;
  }

  .lg-max\:-inset-36 {
    inset: -9rem;
  }

  .lg-max\:-inset-40 {
    inset: -10rem;
  }

  .lg-max\:-inset-44 {
    inset: -11rem;
  }

  .lg-max\:-inset-48 {
    inset: -12rem;
  }

  .lg-max\:-inset-52 {
    inset: -13rem;
  }

  .lg-max\:-inset-56 {
    inset: -14rem;
  }

  .lg-max\:-inset-60 {
    inset: -15rem;
  }

  .lg-max\:-inset-64 {
    inset: -16rem;
  }

  .lg-max\:-inset-72 {
    inset: -18rem;
  }

  .lg-max\:-inset-80 {
    inset: -20rem;
  }

  .lg-max\:-inset-96 {
    inset: -24rem;
  }

  .lg-max\:-inset-px {
    inset: -1px;
  }

  .lg-max\:-inset-0\.5 {
    inset: -.125rem;
  }

  .lg-max\:-inset-1\.5 {
    inset: -.375rem;
  }

  .lg-max\:-inset-2\.5 {
    inset: -.625rem;
  }

  .lg-max\:-inset-3\.5 {
    inset: -.875rem;
  }

  .lg-max\:inset-1\/2 {
    inset: 50%;
  }

  .lg-max\:inset-1\/3 {
    inset: 33.3333%;
  }

  .lg-max\:inset-2\/3 {
    inset: 66.6667%;
  }

  .lg-max\:inset-1\/4 {
    inset: 25%;
  }

  .lg-max\:inset-2\/4 {
    inset: 50%;
  }

  .lg-max\:inset-3\/4 {
    inset: 75%;
  }

  .lg-max\:inset-full {
    inset: 100%;
  }

  .lg-max\:-inset-1\/2 {
    inset: -50%;
  }

  .lg-max\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .lg-max\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .lg-max\:-inset-1\/4 {
    inset: -25%;
  }

  .lg-max\:-inset-2\/4 {
    inset: -50%;
  }

  .lg-max\:-inset-3\/4 {
    inset: -75%;
  }

  .lg-max\:-inset-full {
    inset: -100%;
  }

  .lg-max\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg-max\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .lg-max\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .lg-max\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .lg-max\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .lg-max\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .lg-max\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .lg-max\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .lg-max\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .lg-max\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .lg-max\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .lg-max\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .lg-max\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .lg-max\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .lg-max\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .lg-max\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .lg-max\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .lg-max\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .lg-max\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .lg-max\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .lg-max\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .lg-max\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .lg-max\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .lg-max\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .lg-max\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .lg-max\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .lg-max\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .lg-max\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .lg-max\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .lg-max\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .lg-max\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .lg-max\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .lg-max\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .lg-max\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .lg-max\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .lg-max\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .lg-max\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg-max\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .lg-max\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .lg-max\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .lg-max\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .lg-max\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .lg-max\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .lg-max\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .lg-max\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .lg-max\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .lg-max\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .lg-max\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .lg-max\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .lg-max\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .lg-max\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .lg-max\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .lg-max\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .lg-max\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .lg-max\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .lg-max\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .lg-max\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .lg-max\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .lg-max\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .lg-max\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .lg-max\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .lg-max\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .lg-max\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .lg-max\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .lg-max\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .lg-max\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .lg-max\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .lg-max\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .lg-max\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .lg-max\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .lg-max\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .lg-max\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .lg-max\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .lg-max\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .lg-max\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .lg-max\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .lg-max\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .lg-max\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .lg-max\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .lg-max\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .lg-max\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .lg-max\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .lg-max\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .lg-max\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .lg-max\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .lg-max\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg-max\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .lg-max\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .lg-max\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .lg-max\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .lg-max\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .lg-max\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .lg-max\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .lg-max\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .lg-max\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .lg-max\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .lg-max\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .lg-max\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .lg-max\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .lg-max\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .lg-max\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .lg-max\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .lg-max\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .lg-max\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .lg-max\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .lg-max\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .lg-max\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .lg-max\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .lg-max\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .lg-max\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .lg-max\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .lg-max\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .lg-max\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .lg-max\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .lg-max\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .lg-max\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg-max\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .lg-max\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .lg-max\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .lg-max\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .lg-max\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .lg-max\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg-max\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .lg-max\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .lg-max\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .lg-max\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .lg-max\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .lg-max\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .lg-max\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .lg-max\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .lg-max\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .lg-max\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .lg-max\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .lg-max\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .lg-max\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .lg-max\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .lg-max\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .lg-max\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .lg-max\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .lg-max\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .lg-max\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .lg-max\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .lg-max\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .lg-max\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .lg-max\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .lg-max\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .lg-max\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .lg-max\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .lg-max\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .lg-max\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .lg-max\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .lg-max\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .lg-max\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .lg-max\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .lg-max\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .lg-max\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .lg-max\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .lg-max\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .lg-max\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .lg-max\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .lg-max\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .lg-max\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .lg-max\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .lg-max\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .lg-max\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .lg-max\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .lg-max\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .lg-max\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .lg-max\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .lg-max\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .lg-max\:top-0 {
    top: 0;
  }

  .lg-max\:top-1 {
    top: .25rem;
  }

  .lg-max\:top-2 {
    top: .5rem;
  }

  .lg-max\:top-3 {
    top: .75rem;
  }

  .lg-max\:top-4 {
    top: 1rem;
  }

  .lg-max\:top-5 {
    top: 1.25rem;
  }

  .lg-max\:top-6 {
    top: 1.5rem;
  }

  .lg-max\:top-7 {
    top: 1.75rem;
  }

  .lg-max\:top-8 {
    top: 2rem;
  }

  .lg-max\:top-9 {
    top: 2.25rem;
  }

  .lg-max\:top-10 {
    top: 2.5rem;
  }

  .lg-max\:top-11 {
    top: 2.75rem;
  }

  .lg-max\:top-12 {
    top: 3rem;
  }

  .lg-max\:top-14 {
    top: 3.5rem;
  }

  .lg-max\:top-16 {
    top: 4rem;
  }

  .lg-max\:top-20 {
    top: 5rem;
  }

  .lg-max\:top-24 {
    top: 6rem;
  }

  .lg-max\:top-28 {
    top: 7rem;
  }

  .lg-max\:top-32 {
    top: 8rem;
  }

  .lg-max\:top-36 {
    top: 9rem;
  }

  .lg-max\:top-40 {
    top: 10rem;
  }

  .lg-max\:top-44 {
    top: 11rem;
  }

  .lg-max\:top-48 {
    top: 12rem;
  }

  .lg-max\:top-52 {
    top: 13rem;
  }

  .lg-max\:top-56 {
    top: 14rem;
  }

  .lg-max\:top-60 {
    top: 15rem;
  }

  .lg-max\:top-64 {
    top: 16rem;
  }

  .lg-max\:top-72 {
    top: 18rem;
  }

  .lg-max\:top-80 {
    top: 20rem;
  }

  .lg-max\:top-96 {
    top: 24rem;
  }

  .lg-max\:top-auto {
    top: auto;
  }

  .lg-max\:top-px {
    top: 1px;
  }

  .lg-max\:top-0\.5 {
    top: .125rem;
  }

  .lg-max\:top-1\.5 {
    top: .375rem;
  }

  .lg-max\:top-2\.5 {
    top: .625rem;
  }

  .lg-max\:top-3\.5 {
    top: .875rem;
  }

  .lg-max\:-top-0 {
    top: 0;
  }

  .lg-max\:-top-1 {
    top: -.25rem;
  }

  .lg-max\:-top-2 {
    top: -.5rem;
  }

  .lg-max\:-top-3 {
    top: -.75rem;
  }

  .lg-max\:-top-4 {
    top: -1rem;
  }

  .lg-max\:-top-5 {
    top: -1.25rem;
  }

  .lg-max\:-top-6 {
    top: -1.5rem;
  }

  .lg-max\:-top-7 {
    top: -1.75rem;
  }

  .lg-max\:-top-8 {
    top: -2rem;
  }

  .lg-max\:-top-9 {
    top: -2.25rem;
  }

  .lg-max\:-top-10 {
    top: -2.5rem;
  }

  .lg-max\:-top-11 {
    top: -2.75rem;
  }

  .lg-max\:-top-12 {
    top: -3rem;
  }

  .lg-max\:-top-14 {
    top: -3.5rem;
  }

  .lg-max\:-top-16 {
    top: -4rem;
  }

  .lg-max\:-top-20 {
    top: -5rem;
  }

  .lg-max\:-top-24 {
    top: -6rem;
  }

  .lg-max\:-top-28 {
    top: -7rem;
  }

  .lg-max\:-top-32 {
    top: -8rem;
  }

  .lg-max\:-top-36 {
    top: -9rem;
  }

  .lg-max\:-top-40 {
    top: -10rem;
  }

  .lg-max\:-top-44 {
    top: -11rem;
  }

  .lg-max\:-top-48 {
    top: -12rem;
  }

  .lg-max\:-top-52 {
    top: -13rem;
  }

  .lg-max\:-top-56 {
    top: -14rem;
  }

  .lg-max\:-top-60 {
    top: -15rem;
  }

  .lg-max\:-top-64 {
    top: -16rem;
  }

  .lg-max\:-top-72 {
    top: -18rem;
  }

  .lg-max\:-top-80 {
    top: -20rem;
  }

  .lg-max\:-top-96 {
    top: -24rem;
  }

  .lg-max\:-top-px {
    top: -1px;
  }

  .lg-max\:-top-0\.5 {
    top: -.125rem;
  }

  .lg-max\:-top-1\.5 {
    top: -.375rem;
  }

  .lg-max\:-top-2\.5 {
    top: -.625rem;
  }

  .lg-max\:-top-3\.5 {
    top: -.875rem;
  }

  .lg-max\:top-1\/2 {
    top: 50%;
  }

  .lg-max\:top-1\/3 {
    top: 33.3333%;
  }

  .lg-max\:top-2\/3 {
    top: 66.6667%;
  }

  .lg-max\:top-1\/4 {
    top: 25%;
  }

  .lg-max\:top-2\/4 {
    top: 50%;
  }

  .lg-max\:top-3\/4 {
    top: 75%;
  }

  .lg-max\:top-full {
    top: 100%;
  }

  .lg-max\:-top-1\/2 {
    top: -50%;
  }

  .lg-max\:-top-1\/3 {
    top: -33.3333%;
  }

  .lg-max\:-top-2\/3 {
    top: -66.6667%;
  }

  .lg-max\:-top-1\/4 {
    top: -25%;
  }

  .lg-max\:-top-2\/4 {
    top: -50%;
  }

  .lg-max\:-top-3\/4 {
    top: -75%;
  }

  .lg-max\:-top-full {
    top: -100%;
  }

  .lg-max\:right-0 {
    right: 0;
  }

  .lg-max\:right-1 {
    right: .25rem;
  }

  .lg-max\:right-2 {
    right: .5rem;
  }

  .lg-max\:right-3 {
    right: .75rem;
  }

  .lg-max\:right-4 {
    right: 1rem;
  }

  .lg-max\:right-5 {
    right: 1.25rem;
  }

  .lg-max\:right-6 {
    right: 1.5rem;
  }

  .lg-max\:right-7 {
    right: 1.75rem;
  }

  .lg-max\:right-8 {
    right: 2rem;
  }

  .lg-max\:right-9 {
    right: 2.25rem;
  }

  .lg-max\:right-10 {
    right: 2.5rem;
  }

  .lg-max\:right-11 {
    right: 2.75rem;
  }

  .lg-max\:right-12 {
    right: 3rem;
  }

  .lg-max\:right-14 {
    right: 3.5rem;
  }

  .lg-max\:right-16 {
    right: 4rem;
  }

  .lg-max\:right-20 {
    right: 5rem;
  }

  .lg-max\:right-24 {
    right: 6rem;
  }

  .lg-max\:right-28 {
    right: 7rem;
  }

  .lg-max\:right-32 {
    right: 8rem;
  }

  .lg-max\:right-36 {
    right: 9rem;
  }

  .lg-max\:right-40 {
    right: 10rem;
  }

  .lg-max\:right-44 {
    right: 11rem;
  }

  .lg-max\:right-48 {
    right: 12rem;
  }

  .lg-max\:right-52 {
    right: 13rem;
  }

  .lg-max\:right-56 {
    right: 14rem;
  }

  .lg-max\:right-60 {
    right: 15rem;
  }

  .lg-max\:right-64 {
    right: 16rem;
  }

  .lg-max\:right-72 {
    right: 18rem;
  }

  .lg-max\:right-80 {
    right: 20rem;
  }

  .lg-max\:right-96 {
    right: 24rem;
  }

  .lg-max\:right-auto {
    right: auto;
  }

  .lg-max\:right-px {
    right: 1px;
  }

  .lg-max\:right-0\.5 {
    right: .125rem;
  }

  .lg-max\:right-1\.5 {
    right: .375rem;
  }

  .lg-max\:right-2\.5 {
    right: .625rem;
  }

  .lg-max\:right-3\.5 {
    right: .875rem;
  }

  .lg-max\:-right-0 {
    right: 0;
  }

  .lg-max\:-right-1 {
    right: -.25rem;
  }

  .lg-max\:-right-2 {
    right: -.5rem;
  }

  .lg-max\:-right-3 {
    right: -.75rem;
  }

  .lg-max\:-right-4 {
    right: -1rem;
  }

  .lg-max\:-right-5 {
    right: -1.25rem;
  }

  .lg-max\:-right-6 {
    right: -1.5rem;
  }

  .lg-max\:-right-7 {
    right: -1.75rem;
  }

  .lg-max\:-right-8 {
    right: -2rem;
  }

  .lg-max\:-right-9 {
    right: -2.25rem;
  }

  .lg-max\:-right-10 {
    right: -2.5rem;
  }

  .lg-max\:-right-11 {
    right: -2.75rem;
  }

  .lg-max\:-right-12 {
    right: -3rem;
  }

  .lg-max\:-right-14 {
    right: -3.5rem;
  }

  .lg-max\:-right-16 {
    right: -4rem;
  }

  .lg-max\:-right-20 {
    right: -5rem;
  }

  .lg-max\:-right-24 {
    right: -6rem;
  }

  .lg-max\:-right-28 {
    right: -7rem;
  }

  .lg-max\:-right-32 {
    right: -8rem;
  }

  .lg-max\:-right-36 {
    right: -9rem;
  }

  .lg-max\:-right-40 {
    right: -10rem;
  }

  .lg-max\:-right-44 {
    right: -11rem;
  }

  .lg-max\:-right-48 {
    right: -12rem;
  }

  .lg-max\:-right-52 {
    right: -13rem;
  }

  .lg-max\:-right-56 {
    right: -14rem;
  }

  .lg-max\:-right-60 {
    right: -15rem;
  }

  .lg-max\:-right-64 {
    right: -16rem;
  }

  .lg-max\:-right-72 {
    right: -18rem;
  }

  .lg-max\:-right-80 {
    right: -20rem;
  }

  .lg-max\:-right-96 {
    right: -24rem;
  }

  .lg-max\:-right-px {
    right: -1px;
  }

  .lg-max\:-right-0\.5 {
    right: -.125rem;
  }

  .lg-max\:-right-1\.5 {
    right: -.375rem;
  }

  .lg-max\:-right-2\.5 {
    right: -.625rem;
  }

  .lg-max\:-right-3\.5 {
    right: -.875rem;
  }

  .lg-max\:right-1\/2 {
    right: 50%;
  }

  .lg-max\:right-1\/3 {
    right: 33.3333%;
  }

  .lg-max\:right-2\/3 {
    right: 66.6667%;
  }

  .lg-max\:right-1\/4 {
    right: 25%;
  }

  .lg-max\:right-2\/4 {
    right: 50%;
  }

  .lg-max\:right-3\/4 {
    right: 75%;
  }

  .lg-max\:right-full {
    right: 100%;
  }

  .lg-max\:-right-1\/2 {
    right: -50%;
  }

  .lg-max\:-right-1\/3 {
    right: -33.3333%;
  }

  .lg-max\:-right-2\/3 {
    right: -66.6667%;
  }

  .lg-max\:-right-1\/4 {
    right: -25%;
  }

  .lg-max\:-right-2\/4 {
    right: -50%;
  }

  .lg-max\:-right-3\/4 {
    right: -75%;
  }

  .lg-max\:-right-full {
    right: -100%;
  }

  .lg-max\:bottom-0 {
    bottom: 0;
  }

  .lg-max\:bottom-1 {
    bottom: .25rem;
  }

  .lg-max\:bottom-2 {
    bottom: .5rem;
  }

  .lg-max\:bottom-3 {
    bottom: .75rem;
  }

  .lg-max\:bottom-4 {
    bottom: 1rem;
  }

  .lg-max\:bottom-5 {
    bottom: 1.25rem;
  }

  .lg-max\:bottom-6 {
    bottom: 1.5rem;
  }

  .lg-max\:bottom-7 {
    bottom: 1.75rem;
  }

  .lg-max\:bottom-8 {
    bottom: 2rem;
  }

  .lg-max\:bottom-9 {
    bottom: 2.25rem;
  }

  .lg-max\:bottom-10 {
    bottom: 2.5rem;
  }

  .lg-max\:bottom-11 {
    bottom: 2.75rem;
  }

  .lg-max\:bottom-12 {
    bottom: 3rem;
  }

  .lg-max\:bottom-14 {
    bottom: 3.5rem;
  }

  .lg-max\:bottom-16 {
    bottom: 4rem;
  }

  .lg-max\:bottom-20 {
    bottom: 5rem;
  }

  .lg-max\:bottom-24 {
    bottom: 6rem;
  }

  .lg-max\:bottom-28 {
    bottom: 7rem;
  }

  .lg-max\:bottom-32 {
    bottom: 8rem;
  }

  .lg-max\:bottom-36 {
    bottom: 9rem;
  }

  .lg-max\:bottom-40 {
    bottom: 10rem;
  }

  .lg-max\:bottom-44 {
    bottom: 11rem;
  }

  .lg-max\:bottom-48 {
    bottom: 12rem;
  }

  .lg-max\:bottom-52 {
    bottom: 13rem;
  }

  .lg-max\:bottom-56 {
    bottom: 14rem;
  }

  .lg-max\:bottom-60 {
    bottom: 15rem;
  }

  .lg-max\:bottom-64 {
    bottom: 16rem;
  }

  .lg-max\:bottom-72 {
    bottom: 18rem;
  }

  .lg-max\:bottom-80 {
    bottom: 20rem;
  }

  .lg-max\:bottom-96 {
    bottom: 24rem;
  }

  .lg-max\:bottom-auto {
    bottom: auto;
  }

  .lg-max\:bottom-px {
    bottom: 1px;
  }

  .lg-max\:bottom-0\.5 {
    bottom: .125rem;
  }

  .lg-max\:bottom-1\.5 {
    bottom: .375rem;
  }

  .lg-max\:bottom-2\.5 {
    bottom: .625rem;
  }

  .lg-max\:bottom-3\.5 {
    bottom: .875rem;
  }

  .lg-max\:-bottom-0 {
    bottom: 0;
  }

  .lg-max\:-bottom-1 {
    bottom: -.25rem;
  }

  .lg-max\:-bottom-2 {
    bottom: -.5rem;
  }

  .lg-max\:-bottom-3 {
    bottom: -.75rem;
  }

  .lg-max\:-bottom-4 {
    bottom: -1rem;
  }

  .lg-max\:-bottom-5 {
    bottom: -1.25rem;
  }

  .lg-max\:-bottom-6 {
    bottom: -1.5rem;
  }

  .lg-max\:-bottom-7 {
    bottom: -1.75rem;
  }

  .lg-max\:-bottom-8 {
    bottom: -2rem;
  }

  .lg-max\:-bottom-9 {
    bottom: -2.25rem;
  }

  .lg-max\:-bottom-10 {
    bottom: -2.5rem;
  }

  .lg-max\:-bottom-11 {
    bottom: -2.75rem;
  }

  .lg-max\:-bottom-12 {
    bottom: -3rem;
  }

  .lg-max\:-bottom-14 {
    bottom: -3.5rem;
  }

  .lg-max\:-bottom-16 {
    bottom: -4rem;
  }

  .lg-max\:-bottom-20 {
    bottom: -5rem;
  }

  .lg-max\:-bottom-24 {
    bottom: -6rem;
  }

  .lg-max\:-bottom-28 {
    bottom: -7rem;
  }

  .lg-max\:-bottom-32 {
    bottom: -8rem;
  }

  .lg-max\:-bottom-36 {
    bottom: -9rem;
  }

  .lg-max\:-bottom-40 {
    bottom: -10rem;
  }

  .lg-max\:-bottom-44 {
    bottom: -11rem;
  }

  .lg-max\:-bottom-48 {
    bottom: -12rem;
  }

  .lg-max\:-bottom-52 {
    bottom: -13rem;
  }

  .lg-max\:-bottom-56 {
    bottom: -14rem;
  }

  .lg-max\:-bottom-60 {
    bottom: -15rem;
  }

  .lg-max\:-bottom-64 {
    bottom: -16rem;
  }

  .lg-max\:-bottom-72 {
    bottom: -18rem;
  }

  .lg-max\:-bottom-80 {
    bottom: -20rem;
  }

  .lg-max\:-bottom-96 {
    bottom: -24rem;
  }

  .lg-max\:-bottom-px {
    bottom: -1px;
  }

  .lg-max\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .lg-max\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .lg-max\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .lg-max\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .lg-max\:bottom-1\/2 {
    bottom: 50%;
  }

  .lg-max\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .lg-max\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .lg-max\:bottom-1\/4 {
    bottom: 25%;
  }

  .lg-max\:bottom-2\/4 {
    bottom: 50%;
  }

  .lg-max\:bottom-3\/4 {
    bottom: 75%;
  }

  .lg-max\:bottom-full {
    bottom: 100%;
  }

  .lg-max\:-bottom-1\/2 {
    bottom: -50%;
  }

  .lg-max\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .lg-max\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .lg-max\:-bottom-1\/4 {
    bottom: -25%;
  }

  .lg-max\:-bottom-2\/4 {
    bottom: -50%;
  }

  .lg-max\:-bottom-3\/4 {
    bottom: -75%;
  }

  .lg-max\:-bottom-full {
    bottom: -100%;
  }

  .lg-max\:left-0 {
    left: 0;
  }

  .lg-max\:left-1 {
    left: .25rem;
  }

  .lg-max\:left-2 {
    left: .5rem;
  }

  .lg-max\:left-3 {
    left: .75rem;
  }

  .lg-max\:left-4 {
    left: 1rem;
  }

  .lg-max\:left-5 {
    left: 1.25rem;
  }

  .lg-max\:left-6 {
    left: 1.5rem;
  }

  .lg-max\:left-7 {
    left: 1.75rem;
  }

  .lg-max\:left-8 {
    left: 2rem;
  }

  .lg-max\:left-9 {
    left: 2.25rem;
  }

  .lg-max\:left-10 {
    left: 2.5rem;
  }

  .lg-max\:left-11 {
    left: 2.75rem;
  }

  .lg-max\:left-12 {
    left: 3rem;
  }

  .lg-max\:left-14 {
    left: 3.5rem;
  }

  .lg-max\:left-16 {
    left: 4rem;
  }

  .lg-max\:left-20 {
    left: 5rem;
  }

  .lg-max\:left-24 {
    left: 6rem;
  }

  .lg-max\:left-28 {
    left: 7rem;
  }

  .lg-max\:left-32 {
    left: 8rem;
  }

  .lg-max\:left-36 {
    left: 9rem;
  }

  .lg-max\:left-40 {
    left: 10rem;
  }

  .lg-max\:left-44 {
    left: 11rem;
  }

  .lg-max\:left-48 {
    left: 12rem;
  }

  .lg-max\:left-52 {
    left: 13rem;
  }

  .lg-max\:left-56 {
    left: 14rem;
  }

  .lg-max\:left-60 {
    left: 15rem;
  }

  .lg-max\:left-64 {
    left: 16rem;
  }

  .lg-max\:left-72 {
    left: 18rem;
  }

  .lg-max\:left-80 {
    left: 20rem;
  }

  .lg-max\:left-96 {
    left: 24rem;
  }

  .lg-max\:left-auto {
    left: auto;
  }

  .lg-max\:left-px {
    left: 1px;
  }

  .lg-max\:left-0\.5 {
    left: .125rem;
  }

  .lg-max\:left-1\.5 {
    left: .375rem;
  }

  .lg-max\:left-2\.5 {
    left: .625rem;
  }

  .lg-max\:left-3\.5 {
    left: .875rem;
  }

  .lg-max\:-left-0 {
    left: 0;
  }

  .lg-max\:-left-1 {
    left: -.25rem;
  }

  .lg-max\:-left-2 {
    left: -.5rem;
  }

  .lg-max\:-left-3 {
    left: -.75rem;
  }

  .lg-max\:-left-4 {
    left: -1rem;
  }

  .lg-max\:-left-5 {
    left: -1.25rem;
  }

  .lg-max\:-left-6 {
    left: -1.5rem;
  }

  .lg-max\:-left-7 {
    left: -1.75rem;
  }

  .lg-max\:-left-8 {
    left: -2rem;
  }

  .lg-max\:-left-9 {
    left: -2.25rem;
  }

  .lg-max\:-left-10 {
    left: -2.5rem;
  }

  .lg-max\:-left-11 {
    left: -2.75rem;
  }

  .lg-max\:-left-12 {
    left: -3rem;
  }

  .lg-max\:-left-14 {
    left: -3.5rem;
  }

  .lg-max\:-left-16 {
    left: -4rem;
  }

  .lg-max\:-left-20 {
    left: -5rem;
  }

  .lg-max\:-left-24 {
    left: -6rem;
  }

  .lg-max\:-left-28 {
    left: -7rem;
  }

  .lg-max\:-left-32 {
    left: -8rem;
  }

  .lg-max\:-left-36 {
    left: -9rem;
  }

  .lg-max\:-left-40 {
    left: -10rem;
  }

  .lg-max\:-left-44 {
    left: -11rem;
  }

  .lg-max\:-left-48 {
    left: -12rem;
  }

  .lg-max\:-left-52 {
    left: -13rem;
  }

  .lg-max\:-left-56 {
    left: -14rem;
  }

  .lg-max\:-left-60 {
    left: -15rem;
  }

  .lg-max\:-left-64 {
    left: -16rem;
  }

  .lg-max\:-left-72 {
    left: -18rem;
  }

  .lg-max\:-left-80 {
    left: -20rem;
  }

  .lg-max\:-left-96 {
    left: -24rem;
  }

  .lg-max\:-left-px {
    left: -1px;
  }

  .lg-max\:-left-0\.5 {
    left: -.125rem;
  }

  .lg-max\:-left-1\.5 {
    left: -.375rem;
  }

  .lg-max\:-left-2\.5 {
    left: -.625rem;
  }

  .lg-max\:-left-3\.5 {
    left: -.875rem;
  }

  .lg-max\:left-1\/2 {
    left: 50%;
  }

  .lg-max\:left-1\/3 {
    left: 33.3333%;
  }

  .lg-max\:left-2\/3 {
    left: 66.6667%;
  }

  .lg-max\:left-1\/4 {
    left: 25%;
  }

  .lg-max\:left-2\/4 {
    left: 50%;
  }

  .lg-max\:left-3\/4 {
    left: 75%;
  }

  .lg-max\:left-full {
    left: 100%;
  }

  .lg-max\:-left-1\/2 {
    left: -50%;
  }

  .lg-max\:-left-1\/3 {
    left: -33.3333%;
  }

  .lg-max\:-left-2\/3 {
    left: -66.6667%;
  }

  .lg-max\:-left-1\/4 {
    left: -25%;
  }

  .lg-max\:-left-2\/4 {
    left: -50%;
  }

  .lg-max\:-left-3\/4 {
    left: -75%;
  }

  .lg-max\:-left-full {
    left: -100%;
  }

  .lg-max\:isolate {
    isolation: isolate;
  }

  .lg-max\:isolation-auto {
    isolation: auto;
  }

  .lg-max\:z-0 {
    z-index: 0;
  }

  .lg-max\:z-10 {
    z-index: 10;
  }

  .lg-max\:z-20 {
    z-index: 20;
  }

  .lg-max\:z-30 {
    z-index: 30;
  }

  .lg-max\:z-40 {
    z-index: 40;
  }

  .lg-max\:z-50 {
    z-index: 50;
  }

  .lg-max\:z-auto {
    z-index: auto;
  }

  .lg-max\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .lg-max\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .lg-max\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .lg-max\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .lg-max\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .lg-max\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .lg-max\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .lg-max\:focus\:z-0:focus {
    z-index: 0;
  }

  .lg-max\:focus\:z-10:focus {
    z-index: 10;
  }

  .lg-max\:focus\:z-20:focus {
    z-index: 20;
  }

  .lg-max\:focus\:z-30:focus {
    z-index: 30;
  }

  .lg-max\:focus\:z-40:focus {
    z-index: 40;
  }

  .lg-max\:focus\:z-50:focus {
    z-index: 50;
  }

  .lg-max\:focus\:z-auto:focus {
    z-index: auto;
  }

  .lg-max\:order-1 {
    order: 1;
  }

  .lg-max\:order-2 {
    order: 2;
  }

  .lg-max\:order-3 {
    order: 3;
  }

  .lg-max\:order-4 {
    order: 4;
  }

  .lg-max\:order-5 {
    order: 5;
  }

  .lg-max\:order-6 {
    order: 6;
  }

  .lg-max\:order-7 {
    order: 7;
  }

  .lg-max\:order-8 {
    order: 8;
  }

  .lg-max\:order-9 {
    order: 9;
  }

  .lg-max\:order-10 {
    order: 10;
  }

  .lg-max\:order-11 {
    order: 11;
  }

  .lg-max\:order-12 {
    order: 12;
  }

  .lg-max\:order-first {
    order: -9999;
  }

  .lg-max\:order-last {
    order: 9999;
  }

  .lg-max\:order-none {
    order: 0;
  }

  .lg-max\:col-auto {
    grid-column: auto;
  }

  .lg-max\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg-max\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg-max\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg-max\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg-max\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg-max\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg-max\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg-max\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg-max\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg-max\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg-max\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg-max\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg-max\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg-max\:col-start-1 {
    grid-column-start: 1;
  }

  .lg-max\:col-start-2 {
    grid-column-start: 2;
  }

  .lg-max\:col-start-3 {
    grid-column-start: 3;
  }

  .lg-max\:col-start-4 {
    grid-column-start: 4;
  }

  .lg-max\:col-start-5 {
    grid-column-start: 5;
  }

  .lg-max\:col-start-6 {
    grid-column-start: 6;
  }

  .lg-max\:col-start-7 {
    grid-column-start: 7;
  }

  .lg-max\:col-start-8 {
    grid-column-start: 8;
  }

  .lg-max\:col-start-9 {
    grid-column-start: 9;
  }

  .lg-max\:col-start-10 {
    grid-column-start: 10;
  }

  .lg-max\:col-start-11 {
    grid-column-start: 11;
  }

  .lg-max\:col-start-12 {
    grid-column-start: 12;
  }

  .lg-max\:col-start-13 {
    grid-column-start: 13;
  }

  .lg-max\:col-start-auto {
    grid-column-start: auto;
  }

  .lg-max\:col-end-1 {
    grid-column-end: 1;
  }

  .lg-max\:col-end-2 {
    grid-column-end: 2;
  }

  .lg-max\:col-end-3 {
    grid-column-end: 3;
  }

  .lg-max\:col-end-4 {
    grid-column-end: 4;
  }

  .lg-max\:col-end-5 {
    grid-column-end: 5;
  }

  .lg-max\:col-end-6 {
    grid-column-end: 6;
  }

  .lg-max\:col-end-7 {
    grid-column-end: 7;
  }

  .lg-max\:col-end-8 {
    grid-column-end: 8;
  }

  .lg-max\:col-end-9 {
    grid-column-end: 9;
  }

  .lg-max\:col-end-10 {
    grid-column-end: 10;
  }

  .lg-max\:col-end-11 {
    grid-column-end: 11;
  }

  .lg-max\:col-end-12 {
    grid-column-end: 12;
  }

  .lg-max\:col-end-13 {
    grid-column-end: 13;
  }

  .lg-max\:col-end-auto {
    grid-column-end: auto;
  }

  .lg-max\:row-auto {
    grid-row: auto;
  }

  .lg-max\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg-max\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg-max\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg-max\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg-max\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg-max\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg-max\:row-span-full {
    grid-row: 1 / -1;
  }

  .lg-max\:row-start-1 {
    grid-row-start: 1;
  }

  .lg-max\:row-start-2 {
    grid-row-start: 2;
  }

  .lg-max\:row-start-3 {
    grid-row-start: 3;
  }

  .lg-max\:row-start-4 {
    grid-row-start: 4;
  }

  .lg-max\:row-start-5 {
    grid-row-start: 5;
  }

  .lg-max\:row-start-6 {
    grid-row-start: 6;
  }

  .lg-max\:row-start-7 {
    grid-row-start: 7;
  }

  .lg-max\:row-start-auto {
    grid-row-start: auto;
  }

  .lg-max\:row-end-1 {
    grid-row-end: 1;
  }

  .lg-max\:row-end-2 {
    grid-row-end: 2;
  }

  .lg-max\:row-end-3 {
    grid-row-end: 3;
  }

  .lg-max\:row-end-4 {
    grid-row-end: 4;
  }

  .lg-max\:row-end-5 {
    grid-row-end: 5;
  }

  .lg-max\:row-end-6 {
    grid-row-end: 6;
  }

  .lg-max\:row-end-7 {
    grid-row-end: 7;
  }

  .lg-max\:row-end-auto {
    grid-row-end: auto;
  }

  .lg-max\:float-right {
    float: right;
  }

  .lg-max\:float-left {
    float: left;
  }

  .lg-max\:float-none {
    float: none;
  }

  .lg-max\:clear-left {
    clear: left;
  }

  .lg-max\:clear-right {
    clear: right;
  }

  .lg-max\:clear-both {
    clear: both;
  }

  .lg-max\:clear-none {
    clear: none;
  }

  .lg-max\:m-0 {
    margin: 0;
  }

  .lg-max\:m-1 {
    margin: .25rem;
  }

  .lg-max\:m-2 {
    margin: .5rem;
  }

  .lg-max\:m-3 {
    margin: .75rem;
  }

  .lg-max\:m-4 {
    margin: 1rem;
  }

  .lg-max\:m-5 {
    margin: 1.25rem;
  }

  .lg-max\:m-6 {
    margin: 1.5rem;
  }

  .lg-max\:m-7 {
    margin: 1.75rem;
  }

  .lg-max\:m-8 {
    margin: 2rem;
  }

  .lg-max\:m-9 {
    margin: 2.25rem;
  }

  .lg-max\:m-10 {
    margin: 2.5rem;
  }

  .lg-max\:m-11 {
    margin: 2.75rem;
  }

  .lg-max\:m-12 {
    margin: 3rem;
  }

  .lg-max\:m-14 {
    margin: 3.5rem;
  }

  .lg-max\:m-16 {
    margin: 4rem;
  }

  .lg-max\:m-20 {
    margin: 5rem;
  }

  .lg-max\:m-24 {
    margin: 6rem;
  }

  .lg-max\:m-28 {
    margin: 7rem;
  }

  .lg-max\:m-32 {
    margin: 8rem;
  }

  .lg-max\:m-36 {
    margin: 9rem;
  }

  .lg-max\:m-40 {
    margin: 10rem;
  }

  .lg-max\:m-44 {
    margin: 11rem;
  }

  .lg-max\:m-48 {
    margin: 12rem;
  }

  .lg-max\:m-52 {
    margin: 13rem;
  }

  .lg-max\:m-56 {
    margin: 14rem;
  }

  .lg-max\:m-60 {
    margin: 15rem;
  }

  .lg-max\:m-64 {
    margin: 16rem;
  }

  .lg-max\:m-72 {
    margin: 18rem;
  }

  .lg-max\:m-80 {
    margin: 20rem;
  }

  .lg-max\:m-96 {
    margin: 24rem;
  }

  .lg-max\:m-auto {
    margin: auto;
  }

  .lg-max\:m-px {
    margin: 1px;
  }

  .lg-max\:m-0\.5 {
    margin: .125rem;
  }

  .lg-max\:m-1\.5 {
    margin: .375rem;
  }

  .lg-max\:m-2\.5 {
    margin: .625rem;
  }

  .lg-max\:m-3\.5 {
    margin: .875rem;
  }

  .lg-max\:-m-0 {
    margin: 0;
  }

  .lg-max\:-m-1 {
    margin: -.25rem;
  }

  .lg-max\:-m-2 {
    margin: -.5rem;
  }

  .lg-max\:-m-3 {
    margin: -.75rem;
  }

  .lg-max\:-m-4 {
    margin: -1rem;
  }

  .lg-max\:-m-5 {
    margin: -1.25rem;
  }

  .lg-max\:-m-6 {
    margin: -1.5rem;
  }

  .lg-max\:-m-7 {
    margin: -1.75rem;
  }

  .lg-max\:-m-8 {
    margin: -2rem;
  }

  .lg-max\:-m-9 {
    margin: -2.25rem;
  }

  .lg-max\:-m-10 {
    margin: -2.5rem;
  }

  .lg-max\:-m-11 {
    margin: -2.75rem;
  }

  .lg-max\:-m-12 {
    margin: -3rem;
  }

  .lg-max\:-m-14 {
    margin: -3.5rem;
  }

  .lg-max\:-m-16 {
    margin: -4rem;
  }

  .lg-max\:-m-20 {
    margin: -5rem;
  }

  .lg-max\:-m-24 {
    margin: -6rem;
  }

  .lg-max\:-m-28 {
    margin: -7rem;
  }

  .lg-max\:-m-32 {
    margin: -8rem;
  }

  .lg-max\:-m-36 {
    margin: -9rem;
  }

  .lg-max\:-m-40 {
    margin: -10rem;
  }

  .lg-max\:-m-44 {
    margin: -11rem;
  }

  .lg-max\:-m-48 {
    margin: -12rem;
  }

  .lg-max\:-m-52 {
    margin: -13rem;
  }

  .lg-max\:-m-56 {
    margin: -14rem;
  }

  .lg-max\:-m-60 {
    margin: -15rem;
  }

  .lg-max\:-m-64 {
    margin: -16rem;
  }

  .lg-max\:-m-72 {
    margin: -18rem;
  }

  .lg-max\:-m-80 {
    margin: -20rem;
  }

  .lg-max\:-m-96 {
    margin: -24rem;
  }

  .lg-max\:-m-px {
    margin: -1px;
  }

  .lg-max\:-m-0\.5 {
    margin: -.125rem;
  }

  .lg-max\:-m-1\.5 {
    margin: -.375rem;
  }

  .lg-max\:-m-2\.5 {
    margin: -.625rem;
  }

  .lg-max\:-m-3\.5 {
    margin: -.875rem;
  }

  .lg-max\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg-max\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .lg-max\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg-max\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .lg-max\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg-max\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg-max\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg-max\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .lg-max\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg-max\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .lg-max\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg-max\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .lg-max\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg-max\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .lg-max\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg-max\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg-max\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg-max\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg-max\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg-max\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .lg-max\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg-max\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .lg-max\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg-max\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .lg-max\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg-max\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .lg-max\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg-max\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .lg-max\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .lg-max\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .lg-max\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg-max\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg-max\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .lg-max\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .lg-max\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .lg-max\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .lg-max\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg-max\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .lg-max\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .lg-max\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .lg-max\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg-max\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg-max\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg-max\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .lg-max\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg-max\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .lg-max\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg-max\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .lg-max\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg-max\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .lg-max\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg-max\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg-max\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg-max\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .lg-max\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg-max\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .lg-max\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg-max\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .lg-max\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg-max\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .lg-max\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg-max\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .lg-max\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg-max\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .lg-max\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .lg-max\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .lg-max\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg-max\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .lg-max\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .lg-max\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .lg-max\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .lg-max\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg-max\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .lg-max\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg-max\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg-max\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg-max\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg-max\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg-max\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .lg-max\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg-max\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .lg-max\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg-max\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg-max\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg-max\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .lg-max\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg-max\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg-max\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg-max\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg-max\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg-max\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg-max\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg-max\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .lg-max\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg-max\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .lg-max\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg-max\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .lg-max\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg-max\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .lg-max\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .lg-max\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .lg-max\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg-max\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg-max\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .lg-max\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .lg-max\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .lg-max\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .lg-max\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg-max\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .lg-max\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .lg-max\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .lg-max\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg-max\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg-max\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg-max\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .lg-max\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg-max\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .lg-max\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg-max\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .lg-max\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg-max\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .lg-max\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg-max\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg-max\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg-max\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .lg-max\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg-max\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .lg-max\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg-max\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .lg-max\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg-max\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .lg-max\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg-max\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .lg-max\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg-max\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .lg-max\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .lg-max\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .lg-max\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg-max\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .lg-max\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .lg-max\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .lg-max\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .lg-max\:mt-0 {
    margin-top: 0;
  }

  .lg-max\:mt-1 {
    margin-top: .25rem;
  }

  .lg-max\:mt-2 {
    margin-top: .5rem;
  }

  .lg-max\:mt-3 {
    margin-top: .75rem;
  }

  .lg-max\:mt-4 {
    margin-top: 1rem;
  }

  .lg-max\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg-max\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg-max\:mt-7 {
    margin-top: 1.75rem;
  }

  .lg-max\:mt-8 {
    margin-top: 2rem;
  }

  .lg-max\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg-max\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg-max\:mt-11 {
    margin-top: 2.75rem;
  }

  .lg-max\:mt-12 {
    margin-top: 3rem;
  }

  .lg-max\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg-max\:mt-16 {
    margin-top: 4rem;
  }

  .lg-max\:mt-20 {
    margin-top: 5rem;
  }

  .lg-max\:mt-24 {
    margin-top: 6rem;
  }

  .lg-max\:mt-28 {
    margin-top: 7rem;
  }

  .lg-max\:mt-32 {
    margin-top: 8rem;
  }

  .lg-max\:mt-36 {
    margin-top: 9rem;
  }

  .lg-max\:mt-40 {
    margin-top: 10rem;
  }

  .lg-max\:mt-44 {
    margin-top: 11rem;
  }

  .lg-max\:mt-48 {
    margin-top: 12rem;
  }

  .lg-max\:mt-52 {
    margin-top: 13rem;
  }

  .lg-max\:mt-56 {
    margin-top: 14rem;
  }

  .lg-max\:mt-60 {
    margin-top: 15rem;
  }

  .lg-max\:mt-64 {
    margin-top: 16rem;
  }

  .lg-max\:mt-72 {
    margin-top: 18rem;
  }

  .lg-max\:mt-80 {
    margin-top: 20rem;
  }

  .lg-max\:mt-96 {
    margin-top: 24rem;
  }

  .lg-max\:mt-auto {
    margin-top: auto;
  }

  .lg-max\:mt-px {
    margin-top: 1px;
  }

  .lg-max\:mt-0\.5 {
    margin-top: .125rem;
  }

  .lg-max\:mt-1\.5 {
    margin-top: .375rem;
  }

  .lg-max\:mt-2\.5 {
    margin-top: .625rem;
  }

  .lg-max\:mt-3\.5 {
    margin-top: .875rem;
  }

  .lg-max\:-mt-0 {
    margin-top: 0;
  }

  .lg-max\:-mt-1 {
    margin-top: -.25rem;
  }

  .lg-max\:-mt-2 {
    margin-top: -.5rem;
  }

  .lg-max\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg-max\:-mt-4 {
    margin-top: -1rem;
  }

  .lg-max\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg-max\:-mt-6 {
    margin-top: -1.5rem;
  }

  .lg-max\:-mt-7 {
    margin-top: -1.75rem;
  }

  .lg-max\:-mt-8 {
    margin-top: -2rem;
  }

  .lg-max\:-mt-9 {
    margin-top: -2.25rem;
  }

  .lg-max\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg-max\:-mt-11 {
    margin-top: -2.75rem;
  }

  .lg-max\:-mt-12 {
    margin-top: -3rem;
  }

  .lg-max\:-mt-14 {
    margin-top: -3.5rem;
  }

  .lg-max\:-mt-16 {
    margin-top: -4rem;
  }

  .lg-max\:-mt-20 {
    margin-top: -5rem;
  }

  .lg-max\:-mt-24 {
    margin-top: -6rem;
  }

  .lg-max\:-mt-28 {
    margin-top: -7rem;
  }

  .lg-max\:-mt-32 {
    margin-top: -8rem;
  }

  .lg-max\:-mt-36 {
    margin-top: -9rem;
  }

  .lg-max\:-mt-40 {
    margin-top: -10rem;
  }

  .lg-max\:-mt-44 {
    margin-top: -11rem;
  }

  .lg-max\:-mt-48 {
    margin-top: -12rem;
  }

  .lg-max\:-mt-52 {
    margin-top: -13rem;
  }

  .lg-max\:-mt-56 {
    margin-top: -14rem;
  }

  .lg-max\:-mt-60 {
    margin-top: -15rem;
  }

  .lg-max\:-mt-64 {
    margin-top: -16rem;
  }

  .lg-max\:-mt-72 {
    margin-top: -18rem;
  }

  .lg-max\:-mt-80 {
    margin-top: -20rem;
  }

  .lg-max\:-mt-96 {
    margin-top: -24rem;
  }

  .lg-max\:-mt-px {
    margin-top: -1px;
  }

  .lg-max\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .lg-max\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .lg-max\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .lg-max\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .lg-max\:mr-0 {
    margin-right: 0;
  }

  .lg-max\:mr-1 {
    margin-right: .25rem;
  }

  .lg-max\:mr-2 {
    margin-right: .5rem;
  }

  .lg-max\:mr-3 {
    margin-right: .75rem;
  }

  .lg-max\:mr-4 {
    margin-right: 1rem;
  }

  .lg-max\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg-max\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg-max\:mr-7 {
    margin-right: 1.75rem;
  }

  .lg-max\:mr-8 {
    margin-right: 2rem;
  }

  .lg-max\:mr-9 {
    margin-right: 2.25rem;
  }

  .lg-max\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg-max\:mr-11 {
    margin-right: 2.75rem;
  }

  .lg-max\:mr-12 {
    margin-right: 3rem;
  }

  .lg-max\:mr-14 {
    margin-right: 3.5rem;
  }

  .lg-max\:mr-16 {
    margin-right: 4rem;
  }

  .lg-max\:mr-20 {
    margin-right: 5rem;
  }

  .lg-max\:mr-24 {
    margin-right: 6rem;
  }

  .lg-max\:mr-28 {
    margin-right: 7rem;
  }

  .lg-max\:mr-32 {
    margin-right: 8rem;
  }

  .lg-max\:mr-36 {
    margin-right: 9rem;
  }

  .lg-max\:mr-40 {
    margin-right: 10rem;
  }

  .lg-max\:mr-44 {
    margin-right: 11rem;
  }

  .lg-max\:mr-48 {
    margin-right: 12rem;
  }

  .lg-max\:mr-52 {
    margin-right: 13rem;
  }

  .lg-max\:mr-56 {
    margin-right: 14rem;
  }

  .lg-max\:mr-60 {
    margin-right: 15rem;
  }

  .lg-max\:mr-64 {
    margin-right: 16rem;
  }

  .lg-max\:mr-72 {
    margin-right: 18rem;
  }

  .lg-max\:mr-80 {
    margin-right: 20rem;
  }

  .lg-max\:mr-96 {
    margin-right: 24rem;
  }

  .lg-max\:mr-auto {
    margin-right: auto;
  }

  .lg-max\:mr-px {
    margin-right: 1px;
  }

  .lg-max\:mr-0\.5 {
    margin-right: .125rem;
  }

  .lg-max\:mr-1\.5 {
    margin-right: .375rem;
  }

  .lg-max\:mr-2\.5 {
    margin-right: .625rem;
  }

  .lg-max\:mr-3\.5 {
    margin-right: .875rem;
  }

  .lg-max\:-mr-0 {
    margin-right: 0;
  }

  .lg-max\:-mr-1 {
    margin-right: -.25rem;
  }

  .lg-max\:-mr-2 {
    margin-right: -.5rem;
  }

  .lg-max\:-mr-3 {
    margin-right: -.75rem;
  }

  .lg-max\:-mr-4 {
    margin-right: -1rem;
  }

  .lg-max\:-mr-5 {
    margin-right: -1.25rem;
  }

  .lg-max\:-mr-6 {
    margin-right: -1.5rem;
  }

  .lg-max\:-mr-7 {
    margin-right: -1.75rem;
  }

  .lg-max\:-mr-8 {
    margin-right: -2rem;
  }

  .lg-max\:-mr-9 {
    margin-right: -2.25rem;
  }

  .lg-max\:-mr-10 {
    margin-right: -2.5rem;
  }

  .lg-max\:-mr-11 {
    margin-right: -2.75rem;
  }

  .lg-max\:-mr-12 {
    margin-right: -3rem;
  }

  .lg-max\:-mr-14 {
    margin-right: -3.5rem;
  }

  .lg-max\:-mr-16 {
    margin-right: -4rem;
  }

  .lg-max\:-mr-20 {
    margin-right: -5rem;
  }

  .lg-max\:-mr-24 {
    margin-right: -6rem;
  }

  .lg-max\:-mr-28 {
    margin-right: -7rem;
  }

  .lg-max\:-mr-32 {
    margin-right: -8rem;
  }

  .lg-max\:-mr-36 {
    margin-right: -9rem;
  }

  .lg-max\:-mr-40 {
    margin-right: -10rem;
  }

  .lg-max\:-mr-44 {
    margin-right: -11rem;
  }

  .lg-max\:-mr-48 {
    margin-right: -12rem;
  }

  .lg-max\:-mr-52 {
    margin-right: -13rem;
  }

  .lg-max\:-mr-56 {
    margin-right: -14rem;
  }

  .lg-max\:-mr-60 {
    margin-right: -15rem;
  }

  .lg-max\:-mr-64 {
    margin-right: -16rem;
  }

  .lg-max\:-mr-72 {
    margin-right: -18rem;
  }

  .lg-max\:-mr-80 {
    margin-right: -20rem;
  }

  .lg-max\:-mr-96 {
    margin-right: -24rem;
  }

  .lg-max\:-mr-px {
    margin-right: -1px;
  }

  .lg-max\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .lg-max\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .lg-max\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .lg-max\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .lg-max\:mb-0 {
    margin-bottom: 0;
  }

  .lg-max\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg-max\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg-max\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg-max\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg-max\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg-max\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg-max\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg-max\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg-max\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg-max\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg-max\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .lg-max\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg-max\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg-max\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg-max\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg-max\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg-max\:mb-28 {
    margin-bottom: 7rem;
  }

  .lg-max\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg-max\:mb-36 {
    margin-bottom: 9rem;
  }

  .lg-max\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg-max\:mb-44 {
    margin-bottom: 11rem;
  }

  .lg-max\:mb-48 {
    margin-bottom: 12rem;
  }

  .lg-max\:mb-52 {
    margin-bottom: 13rem;
  }

  .lg-max\:mb-56 {
    margin-bottom: 14rem;
  }

  .lg-max\:mb-60 {
    margin-bottom: 15rem;
  }

  .lg-max\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg-max\:mb-72 {
    margin-bottom: 18rem;
  }

  .lg-max\:mb-80 {
    margin-bottom: 20rem;
  }

  .lg-max\:mb-96 {
    margin-bottom: 24rem;
  }

  .lg-max\:mb-auto {
    margin-bottom: auto;
  }

  .lg-max\:mb-px {
    margin-bottom: 1px;
  }

  .lg-max\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .lg-max\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .lg-max\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .lg-max\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .lg-max\:-mb-0 {
    margin-bottom: 0;
  }

  .lg-max\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .lg-max\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .lg-max\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .lg-max\:-mb-4 {
    margin-bottom: -1rem;
  }

  .lg-max\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg-max\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg-max\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .lg-max\:-mb-8 {
    margin-bottom: -2rem;
  }

  .lg-max\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .lg-max\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg-max\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .lg-max\:-mb-12 {
    margin-bottom: -3rem;
  }

  .lg-max\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .lg-max\:-mb-16 {
    margin-bottom: -4rem;
  }

  .lg-max\:-mb-20 {
    margin-bottom: -5rem;
  }

  .lg-max\:-mb-24 {
    margin-bottom: -6rem;
  }

  .lg-max\:-mb-28 {
    margin-bottom: -7rem;
  }

  .lg-max\:-mb-32 {
    margin-bottom: -8rem;
  }

  .lg-max\:-mb-36 {
    margin-bottom: -9rem;
  }

  .lg-max\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg-max\:-mb-44 {
    margin-bottom: -11rem;
  }

  .lg-max\:-mb-48 {
    margin-bottom: -12rem;
  }

  .lg-max\:-mb-52 {
    margin-bottom: -13rem;
  }

  .lg-max\:-mb-56 {
    margin-bottom: -14rem;
  }

  .lg-max\:-mb-60 {
    margin-bottom: -15rem;
  }

  .lg-max\:-mb-64 {
    margin-bottom: -16rem;
  }

  .lg-max\:-mb-72 {
    margin-bottom: -18rem;
  }

  .lg-max\:-mb-80 {
    margin-bottom: -20rem;
  }

  .lg-max\:-mb-96 {
    margin-bottom: -24rem;
  }

  .lg-max\:-mb-px {
    margin-bottom: -1px;
  }

  .lg-max\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .lg-max\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .lg-max\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .lg-max\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .lg-max\:ml-0 {
    margin-left: 0;
  }

  .lg-max\:ml-1 {
    margin-left: .25rem;
  }

  .lg-max\:ml-2 {
    margin-left: .5rem;
  }

  .lg-max\:ml-3 {
    margin-left: .75rem;
  }

  .lg-max\:ml-4 {
    margin-left: 1rem;
  }

  .lg-max\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg-max\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg-max\:ml-7 {
    margin-left: 1.75rem;
  }

  .lg-max\:ml-8 {
    margin-left: 2rem;
  }

  .lg-max\:ml-9 {
    margin-left: 2.25rem;
  }

  .lg-max\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg-max\:ml-11 {
    margin-left: 2.75rem;
  }

  .lg-max\:ml-12 {
    margin-left: 3rem;
  }

  .lg-max\:ml-14 {
    margin-left: 3.5rem;
  }

  .lg-max\:ml-16 {
    margin-left: 4rem;
  }

  .lg-max\:ml-20 {
    margin-left: 5rem;
  }

  .lg-max\:ml-24 {
    margin-left: 6rem;
  }

  .lg-max\:ml-28 {
    margin-left: 7rem;
  }

  .lg-max\:ml-32 {
    margin-left: 8rem;
  }

  .lg-max\:ml-36 {
    margin-left: 9rem;
  }

  .lg-max\:ml-40 {
    margin-left: 10rem;
  }

  .lg-max\:ml-44 {
    margin-left: 11rem;
  }

  .lg-max\:ml-48 {
    margin-left: 12rem;
  }

  .lg-max\:ml-52 {
    margin-left: 13rem;
  }

  .lg-max\:ml-56 {
    margin-left: 14rem;
  }

  .lg-max\:ml-60 {
    margin-left: 15rem;
  }

  .lg-max\:ml-64 {
    margin-left: 16rem;
  }

  .lg-max\:ml-72 {
    margin-left: 18rem;
  }

  .lg-max\:ml-80 {
    margin-left: 20rem;
  }

  .lg-max\:ml-96 {
    margin-left: 24rem;
  }

  .lg-max\:ml-auto {
    margin-left: auto;
  }

  .lg-max\:ml-px {
    margin-left: 1px;
  }

  .lg-max\:ml-0\.5 {
    margin-left: .125rem;
  }

  .lg-max\:ml-1\.5 {
    margin-left: .375rem;
  }

  .lg-max\:ml-2\.5 {
    margin-left: .625rem;
  }

  .lg-max\:ml-3\.5 {
    margin-left: .875rem;
  }

  .lg-max\:-ml-0 {
    margin-left: 0;
  }

  .lg-max\:-ml-1 {
    margin-left: -.25rem;
  }

  .lg-max\:-ml-2 {
    margin-left: -.5rem;
  }

  .lg-max\:-ml-3 {
    margin-left: -.75rem;
  }

  .lg-max\:-ml-4 {
    margin-left: -1rem;
  }

  .lg-max\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg-max\:-ml-6 {
    margin-left: -1.5rem;
  }

  .lg-max\:-ml-7 {
    margin-left: -1.75rem;
  }

  .lg-max\:-ml-8 {
    margin-left: -2rem;
  }

  .lg-max\:-ml-9 {
    margin-left: -2.25rem;
  }

  .lg-max\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg-max\:-ml-11 {
    margin-left: -2.75rem;
  }

  .lg-max\:-ml-12 {
    margin-left: -3rem;
  }

  .lg-max\:-ml-14 {
    margin-left: -3.5rem;
  }

  .lg-max\:-ml-16 {
    margin-left: -4rem;
  }

  .lg-max\:-ml-20 {
    margin-left: -5rem;
  }

  .lg-max\:-ml-24 {
    margin-left: -6rem;
  }

  .lg-max\:-ml-28 {
    margin-left: -7rem;
  }

  .lg-max\:-ml-32 {
    margin-left: -8rem;
  }

  .lg-max\:-ml-36 {
    margin-left: -9rem;
  }

  .lg-max\:-ml-40 {
    margin-left: -10rem;
  }

  .lg-max\:-ml-44 {
    margin-left: -11rem;
  }

  .lg-max\:-ml-48 {
    margin-left: -12rem;
  }

  .lg-max\:-ml-52 {
    margin-left: -13rem;
  }

  .lg-max\:-ml-56 {
    margin-left: -14rem;
  }

  .lg-max\:-ml-60 {
    margin-left: -15rem;
  }

  .lg-max\:-ml-64 {
    margin-left: -16rem;
  }

  .lg-max\:-ml-72 {
    margin-left: -18rem;
  }

  .lg-max\:-ml-80 {
    margin-left: -20rem;
  }

  .lg-max\:-ml-96 {
    margin-left: -24rem;
  }

  .lg-max\:-ml-px {
    margin-left: -1px;
  }

  .lg-max\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .lg-max\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .lg-max\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .lg-max\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .lg-max\:box-border {
    box-sizing: border-box;
  }

  .lg-max\:box-content {
    box-sizing: content-box;
  }

  .lg-max\:block {
    display: block;
  }

  .lg-max\:inline-block {
    display: inline-block;
  }

  .lg-max\:inline {
    display: inline;
  }

  .lg-max\:flex {
    display: flex;
  }

  .lg-max\:inline-flex {
    display: inline-flex;
  }

  .lg-max\:table {
    display: table;
  }

  .lg-max\:inline-table {
    display: inline-table;
  }

  .lg-max\:table-caption {
    display: table-caption;
  }

  .lg-max\:table-cell {
    display: table-cell;
  }

  .lg-max\:table-column {
    display: table-column;
  }

  .lg-max\:table-column-group {
    display: table-column-group;
  }

  .lg-max\:table-footer-group {
    display: table-footer-group;
  }

  .lg-max\:table-header-group {
    display: table-header-group;
  }

  .lg-max\:table-row-group {
    display: table-row-group;
  }

  .lg-max\:table-row {
    display: table-row;
  }

  .lg-max\:flow-root {
    display: flow-root;
  }

  .lg-max\:grid {
    display: grid;
  }

  .lg-max\:inline-grid {
    display: inline-grid;
  }

  .lg-max\:contents {
    display: contents;
  }

  .lg-max\:list-item {
    display: list-item;
  }

  .lg-max\:hidden {
    display: none;
  }

  .lg-max\:h-0 {
    height: 0;
  }

  .lg-max\:h-1 {
    height: .25rem;
  }

  .lg-max\:h-2 {
    height: .5rem;
  }

  .lg-max\:h-3 {
    height: .75rem;
  }

  .lg-max\:h-4 {
    height: 1rem;
  }

  .lg-max\:h-5 {
    height: 1.25rem;
  }

  .lg-max\:h-6 {
    height: 1.5rem;
  }

  .lg-max\:h-7 {
    height: 1.75rem;
  }

  .lg-max\:h-8 {
    height: 2rem;
  }

  .lg-max\:h-9 {
    height: 2.25rem;
  }

  .lg-max\:h-10 {
    height: 2.5rem;
  }

  .lg-max\:h-11 {
    height: 2.75rem;
  }

  .lg-max\:h-12 {
    height: 3rem;
  }

  .lg-max\:h-14 {
    height: 3.5rem;
  }

  .lg-max\:h-16 {
    height: 4rem;
  }

  .lg-max\:h-20 {
    height: 5rem;
  }

  .lg-max\:h-24 {
    height: 6rem;
  }

  .lg-max\:h-28 {
    height: 7rem;
  }

  .lg-max\:h-32 {
    height: 8rem;
  }

  .lg-max\:h-36 {
    height: 9rem;
  }

  .lg-max\:h-40 {
    height: 10rem;
  }

  .lg-max\:h-44 {
    height: 11rem;
  }

  .lg-max\:h-48 {
    height: 12rem;
  }

  .lg-max\:h-52 {
    height: 13rem;
  }

  .lg-max\:h-56 {
    height: 14rem;
  }

  .lg-max\:h-60 {
    height: 15rem;
  }

  .lg-max\:h-64 {
    height: 16rem;
  }

  .lg-max\:h-72 {
    height: 18rem;
  }

  .lg-max\:h-80 {
    height: 20rem;
  }

  .lg-max\:h-96 {
    height: 24rem;
  }

  .lg-max\:h-auto {
    height: auto;
  }

  .lg-max\:h-px {
    height: 1px;
  }

  .lg-max\:h-0\.5 {
    height: .125rem;
  }

  .lg-max\:h-1\.5 {
    height: .375rem;
  }

  .lg-max\:h-2\.5 {
    height: .625rem;
  }

  .lg-max\:h-3\.5 {
    height: .875rem;
  }

  .lg-max\:h-1\/2 {
    height: 50%;
  }

  .lg-max\:h-1\/3 {
    height: 33.3333%;
  }

  .lg-max\:h-2\/3 {
    height: 66.6667%;
  }

  .lg-max\:h-1\/4 {
    height: 25%;
  }

  .lg-max\:h-2\/4 {
    height: 50%;
  }

  .lg-max\:h-3\/4 {
    height: 75%;
  }

  .lg-max\:h-1\/5 {
    height: 20%;
  }

  .lg-max\:h-2\/5 {
    height: 40%;
  }

  .lg-max\:h-3\/5 {
    height: 60%;
  }

  .lg-max\:h-4\/5 {
    height: 80%;
  }

  .lg-max\:h-1\/6 {
    height: 16.6667%;
  }

  .lg-max\:h-2\/6 {
    height: 33.3333%;
  }

  .lg-max\:h-3\/6 {
    height: 50%;
  }

  .lg-max\:h-4\/6 {
    height: 66.6667%;
  }

  .lg-max\:h-5\/6 {
    height: 83.3333%;
  }

  .lg-max\:h-full {
    height: 100%;
  }

  .lg-max\:h-screen {
    height: 100vh;
  }

  .lg-max\:max-h-0 {
    max-height: 0;
  }

  .lg-max\:max-h-1 {
    max-height: .25rem;
  }

  .lg-max\:max-h-2 {
    max-height: .5rem;
  }

  .lg-max\:max-h-3 {
    max-height: .75rem;
  }

  .lg-max\:max-h-4 {
    max-height: 1rem;
  }

  .lg-max\:max-h-5 {
    max-height: 1.25rem;
  }

  .lg-max\:max-h-6 {
    max-height: 1.5rem;
  }

  .lg-max\:max-h-7 {
    max-height: 1.75rem;
  }

  .lg-max\:max-h-8 {
    max-height: 2rem;
  }

  .lg-max\:max-h-9 {
    max-height: 2.25rem;
  }

  .lg-max\:max-h-10 {
    max-height: 2.5rem;
  }

  .lg-max\:max-h-11 {
    max-height: 2.75rem;
  }

  .lg-max\:max-h-12 {
    max-height: 3rem;
  }

  .lg-max\:max-h-14 {
    max-height: 3.5rem;
  }

  .lg-max\:max-h-16 {
    max-height: 4rem;
  }

  .lg-max\:max-h-20 {
    max-height: 5rem;
  }

  .lg-max\:max-h-24 {
    max-height: 6rem;
  }

  .lg-max\:max-h-28 {
    max-height: 7rem;
  }

  .lg-max\:max-h-32 {
    max-height: 8rem;
  }

  .lg-max\:max-h-36 {
    max-height: 9rem;
  }

  .lg-max\:max-h-40 {
    max-height: 10rem;
  }

  .lg-max\:max-h-44 {
    max-height: 11rem;
  }

  .lg-max\:max-h-48 {
    max-height: 12rem;
  }

  .lg-max\:max-h-52 {
    max-height: 13rem;
  }

  .lg-max\:max-h-56 {
    max-height: 14rem;
  }

  .lg-max\:max-h-60 {
    max-height: 15rem;
  }

  .lg-max\:max-h-64 {
    max-height: 16rem;
  }

  .lg-max\:max-h-72 {
    max-height: 18rem;
  }

  .lg-max\:max-h-80 {
    max-height: 20rem;
  }

  .lg-max\:max-h-96 {
    max-height: 24rem;
  }

  .lg-max\:max-h-px {
    max-height: 1px;
  }

  .lg-max\:max-h-0\.5 {
    max-height: .125rem;
  }

  .lg-max\:max-h-1\.5 {
    max-height: .375rem;
  }

  .lg-max\:max-h-2\.5 {
    max-height: .625rem;
  }

  .lg-max\:max-h-3\.5 {
    max-height: .875rem;
  }

  .lg-max\:max-h-full {
    max-height: 100%;
  }

  .lg-max\:max-h-screen {
    max-height: 100vh;
  }

  .lg-max\:min-h-0 {
    min-height: 0;
  }

  .lg-max\:min-h-full {
    min-height: 100%;
  }

  .lg-max\:min-h-screen {
    min-height: 100vh;
  }

  .lg-max\:w-0 {
    width: 0;
  }

  .lg-max\:w-1 {
    width: .25rem;
  }

  .lg-max\:w-2 {
    width: .5rem;
  }

  .lg-max\:w-3 {
    width: .75rem;
  }

  .lg-max\:w-4 {
    width: 1rem;
  }

  .lg-max\:w-5 {
    width: 1.25rem;
  }

  .lg-max\:w-6 {
    width: 1.5rem;
  }

  .lg-max\:w-7 {
    width: 1.75rem;
  }

  .lg-max\:w-8 {
    width: 2rem;
  }

  .lg-max\:w-9 {
    width: 2.25rem;
  }

  .lg-max\:w-10 {
    width: 2.5rem;
  }

  .lg-max\:w-11 {
    width: 2.75rem;
  }

  .lg-max\:w-12 {
    width: 3rem;
  }

  .lg-max\:w-14 {
    width: 3.5rem;
  }

  .lg-max\:w-16 {
    width: 4rem;
  }

  .lg-max\:w-20 {
    width: 5rem;
  }

  .lg-max\:w-24 {
    width: 6rem;
  }

  .lg-max\:w-28 {
    width: 7rem;
  }

  .lg-max\:w-32 {
    width: 8rem;
  }

  .lg-max\:w-36 {
    width: 9rem;
  }

  .lg-max\:w-40 {
    width: 10rem;
  }

  .lg-max\:w-44 {
    width: 11rem;
  }

  .lg-max\:w-48 {
    width: 12rem;
  }

  .lg-max\:w-52 {
    width: 13rem;
  }

  .lg-max\:w-56 {
    width: 14rem;
  }

  .lg-max\:w-60 {
    width: 15rem;
  }

  .lg-max\:w-64 {
    width: 16rem;
  }

  .lg-max\:w-72 {
    width: 18rem;
  }

  .lg-max\:w-80 {
    width: 20rem;
  }

  .lg-max\:w-96 {
    width: 24rem;
  }

  .lg-max\:w-auto {
    width: auto;
  }

  .lg-max\:w-px {
    width: 1px;
  }

  .lg-max\:w-0\.5 {
    width: .125rem;
  }

  .lg-max\:w-1\.5 {
    width: .375rem;
  }

  .lg-max\:w-2\.5 {
    width: .625rem;
  }

  .lg-max\:w-3\.5 {
    width: .875rem;
  }

  .lg-max\:w-1\/2 {
    width: 50%;
  }

  .lg-max\:w-1\/3 {
    width: 33.3333%;
  }

  .lg-max\:w-2\/3 {
    width: 66.6667%;
  }

  .lg-max\:w-1\/4 {
    width: 25%;
  }

  .lg-max\:w-2\/4 {
    width: 50%;
  }

  .lg-max\:w-3\/4 {
    width: 75%;
  }

  .lg-max\:w-1\/5 {
    width: 20%;
  }

  .lg-max\:w-2\/5 {
    width: 40%;
  }

  .lg-max\:w-3\/5 {
    width: 60%;
  }

  .lg-max\:w-4\/5 {
    width: 80%;
  }

  .lg-max\:w-1\/6 {
    width: 16.6667%;
  }

  .lg-max\:w-2\/6 {
    width: 33.3333%;
  }

  .lg-max\:w-3\/6 {
    width: 50%;
  }

  .lg-max\:w-4\/6 {
    width: 66.6667%;
  }

  .lg-max\:w-5\/6 {
    width: 83.3333%;
  }

  .lg-max\:w-1\/12 {
    width: 8.33333%;
  }

  .lg-max\:w-2\/12 {
    width: 16.6667%;
  }

  .lg-max\:w-3\/12 {
    width: 25%;
  }

  .lg-max\:w-4\/12 {
    width: 33.3333%;
  }

  .lg-max\:w-5\/12 {
    width: 41.6667%;
  }

  .lg-max\:w-6\/12 {
    width: 50%;
  }

  .lg-max\:w-7\/12 {
    width: 58.3333%;
  }

  .lg-max\:w-8\/12 {
    width: 66.6667%;
  }

  .lg-max\:w-9\/12 {
    width: 75%;
  }

  .lg-max\:w-10\/12 {
    width: 83.3333%;
  }

  .lg-max\:w-11\/12 {
    width: 91.6667%;
  }

  .lg-max\:w-full {
    width: 100%;
  }

  .lg-max\:w-screen {
    width: 100vw;
  }

  .lg-max\:w-min {
    width: min-content;
  }

  .lg-max\:w-max {
    width: max-content;
  }

  .lg-max\:min-w-0 {
    min-width: 0;
  }

  .lg-max\:min-w-full {
    min-width: 100%;
  }

  .lg-max\:min-w-min {
    min-width: min-content;
  }

  .lg-max\:min-w-max {
    min-width: max-content;
  }

  .lg-max\:max-w-0 {
    max-width: 0;
  }

  .lg-max\:max-w-none {
    max-width: none;
  }

  .lg-max\:max-w-xs {
    max-width: 20rem;
  }

  .lg-max\:max-w-sm {
    max-width: 24rem;
  }

  .lg-max\:max-w-md {
    max-width: 28rem;
  }

  .lg-max\:max-w-lg {
    max-width: 32rem;
  }

  .lg-max\:max-w-xl {
    max-width: 36rem;
  }

  .lg-max\:max-w-2xl {
    max-width: 42rem;
  }

  .lg-max\:max-w-3xl {
    max-width: 48rem;
  }

  .lg-max\:max-w-4xl {
    max-width: 56rem;
  }

  .lg-max\:max-w-5xl {
    max-width: 64rem;
  }

  .lg-max\:max-w-6xl {
    max-width: 72rem;
  }

  .lg-max\:max-w-7xl {
    max-width: 80rem;
  }

  .lg-max\:max-w-full {
    max-width: 100%;
  }

  .lg-max\:max-w-min {
    max-width: min-content;
  }

  .lg-max\:max-w-max {
    max-width: max-content;
  }

  .lg-max\:max-w-prose {
    max-width: 65ch;
  }

  .lg-max\:max-w-screen-sm {
    max-width: 640px;
  }

  .lg-max\:max-w-screen-md {
    max-width: 768px;
  }

  .lg-max\:max-w-screen-lg {
    max-width: 1024px;
  }

  .lg-max\:max-w-screen-xl {
    max-width: 1280px;
  }

  .lg-max\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .lg-max\:flex-1 {
    flex: 1;
  }

  .lg-max\:flex-auto {
    flex: auto;
  }

  .lg-max\:flex-initial {
    flex: 0 auto;
  }

  .lg-max\:flex-none {
    flex: none;
  }

  .lg-max\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg-max\:flex-shrink {
    flex-shrink: 1;
  }

  .lg-max\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg-max\:flex-grow {
    flex-grow: 1;
  }

  .lg-max\:table-auto {
    table-layout: auto;
  }

  .lg-max\:table-fixed {
    table-layout: fixed;
  }

  .lg-max\:border-collapse {
    border-collapse: collapse;
  }

  .lg-max\:border-separate {
    border-collapse: separate;
  }

  .lg-max\:origin-center {
    transform-origin: center;
  }

  .lg-max\:origin-top {
    transform-origin: top;
  }

  .lg-max\:origin-top-right {
    transform-origin: 100% 0;
  }

  .lg-max\:origin-right {
    transform-origin: 100%;
  }

  .lg-max\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .lg-max\:origin-bottom {
    transform-origin: bottom;
  }

  .lg-max\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .lg-max\:origin-left {
    transform-origin: 0;
  }

  .lg-max\:origin-top-left {
    transform-origin: 0 0;
  }

  .lg-max\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg-max\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg-max\:transform-none {
    transform: none;
  }

  .lg-max\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .lg-max\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .lg-max\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .lg-max\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .lg-max\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .lg-max\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .lg-max\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .lg-max\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .lg-max\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .lg-max\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .lg-max\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .lg-max\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .lg-max\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .lg-max\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .lg-max\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .lg-max\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .lg-max\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .lg-max\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .lg-max\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .lg-max\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .lg-max\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .lg-max\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .lg-max\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .lg-max\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .lg-max\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .lg-max\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .lg-max\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .lg-max\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .lg-max\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .lg-max\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .lg-max\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .lg-max\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .lg-max\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .lg-max\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .lg-max\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .lg-max\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .lg-max\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .lg-max\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .lg-max\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .lg-max\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .lg-max\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .lg-max\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .lg-max\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .lg-max\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .lg-max\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .lg-max\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .lg-max\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .lg-max\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .lg-max\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .lg-max\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .lg-max\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .lg-max\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .lg-max\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .lg-max\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .lg-max\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .lg-max\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .lg-max\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .lg-max\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .lg-max\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .lg-max\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .lg-max\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .lg-max\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .lg-max\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .lg-max\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .lg-max\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .lg-max\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .lg-max\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .lg-max\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .lg-max\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .lg-max\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .lg-max\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .lg-max\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .lg-max\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .lg-max\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .lg-max\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .lg-max\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .lg-max\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .lg-max\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .lg-max\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .lg-max\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .lg-max\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .lg-max\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .lg-max\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .lg-max\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .lg-max\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .lg-max\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .lg-max\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .lg-max\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .lg-max\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .lg-max\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .lg-max\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .lg-max\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .lg-max\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .lg-max\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .lg-max\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .lg-max\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .lg-max\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .lg-max\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .lg-max\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .lg-max\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .lg-max\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .lg-max\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .lg-max\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .lg-max\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .lg-max\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .lg-max\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .lg-max\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .lg-max\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .lg-max\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .lg-max\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .lg-max\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .lg-max\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .lg-max\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .lg-max\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .lg-max\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .lg-max\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .lg-max\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .lg-max\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .lg-max\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .lg-max\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .lg-max\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .lg-max\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .lg-max\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .lg-max\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .lg-max\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .lg-max\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .lg-max\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .lg-max\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .lg-max\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .lg-max\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .lg-max\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .lg-max\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .lg-max\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .lg-max\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .lg-max\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .lg-max\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .lg-max\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .lg-max\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .lg-max\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .lg-max\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .lg-max\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .lg-max\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .lg-max\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .lg-max\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .lg-max\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .lg-max\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .lg-max\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .lg-max\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .lg-max\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .lg-max\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .lg-max\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .lg-max\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .lg-max\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .lg-max\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .lg-max\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .lg-max\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .lg-max\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .lg-max\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .lg-max\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .lg-max\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .lg-max\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .lg-max\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .lg-max\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .lg-max\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .lg-max\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .lg-max\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .lg-max\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .lg-max\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .lg-max\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .lg-max\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .lg-max\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .lg-max\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .lg-max\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .lg-max\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .lg-max\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .lg-max\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .lg-max\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .lg-max\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .lg-max\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .lg-max\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .lg-max\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .lg-max\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .lg-max\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .lg-max\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .lg-max\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .lg-max\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .lg-max\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .lg-max\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .lg-max\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .lg-max\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .lg-max\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .lg-max\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .lg-max\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .lg-max\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .lg-max\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .lg-max\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .lg-max\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .lg-max\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .lg-max\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .lg-max\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .lg-max\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .lg-max\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .lg-max\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .lg-max\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .lg-max\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .lg-max\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .lg-max\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .lg-max\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .lg-max\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .lg-max\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .lg-max\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .lg-max\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .lg-max\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .lg-max\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .lg-max\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .lg-max\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .lg-max\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .lg-max\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .lg-max\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .lg-max\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .lg-max\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .lg-max\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .lg-max\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .lg-max\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .lg-max\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .lg-max\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .lg-max\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .lg-max\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .lg-max\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .lg-max\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .lg-max\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .lg-max\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .lg-max\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .lg-max\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .lg-max\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .lg-max\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .lg-max\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .lg-max\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .lg-max\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .lg-max\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .lg-max\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .lg-max\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .lg-max\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .lg-max\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .lg-max\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .lg-max\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .lg-max\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .lg-max\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .lg-max\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .lg-max\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .lg-max\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .lg-max\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .lg-max\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .lg-max\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .lg-max\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .lg-max\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .lg-max\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .lg-max\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .lg-max\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .lg-max\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .lg-max\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .lg-max\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .lg-max\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .lg-max\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .lg-max\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .lg-max\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .lg-max\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .lg-max\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .lg-max\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .lg-max\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .lg-max\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .lg-max\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .lg-max\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .lg-max\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .lg-max\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .lg-max\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .lg-max\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .lg-max\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .lg-max\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .lg-max\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .lg-max\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .lg-max\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .lg-max\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .lg-max\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .lg-max\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .lg-max\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .lg-max\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .lg-max\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .lg-max\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .lg-max\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .lg-max\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .lg-max\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .lg-max\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .lg-max\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .lg-max\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .lg-max\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .lg-max\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .lg-max\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .lg-max\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .lg-max\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .lg-max\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .lg-max\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .lg-max\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .lg-max\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .lg-max\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .lg-max\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .lg-max\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .lg-max\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .lg-max\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .lg-max\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .lg-max\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .lg-max\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .lg-max\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .lg-max\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .lg-max\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .lg-max\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .lg-max\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .lg-max\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .lg-max\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .lg-max\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .lg-max\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .lg-max\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .lg-max\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .lg-max\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .lg-max\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .lg-max\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .lg-max\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .lg-max\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .lg-max\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .lg-max\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .lg-max\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .lg-max\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .lg-max\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .lg-max\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .lg-max\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .lg-max\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .lg-max\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .lg-max\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .lg-max\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .lg-max\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .lg-max\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .lg-max\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .lg-max\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .lg-max\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .lg-max\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .lg-max\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .lg-max\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .lg-max\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .lg-max\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .lg-max\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .lg-max\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .lg-max\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .lg-max\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .lg-max\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .lg-max\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .lg-max\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .lg-max\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .lg-max\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .lg-max\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .lg-max\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .lg-max\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .lg-max\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .lg-max\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .lg-max\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .lg-max\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .lg-max\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .lg-max\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .lg-max\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .lg-max\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .lg-max\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .lg-max\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .lg-max\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .lg-max\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .lg-max\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .lg-max\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .lg-max\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .lg-max\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .lg-max\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .lg-max\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .lg-max\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .lg-max\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .lg-max\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .lg-max\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .lg-max\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .lg-max\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .lg-max\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .lg-max\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .lg-max\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .lg-max\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .lg-max\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .lg-max\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .lg-max\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .lg-max\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .lg-max\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .lg-max\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .lg-max\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .lg-max\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .lg-max\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .lg-max\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .lg-max\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .lg-max\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .lg-max\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .lg-max\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .lg-max\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .lg-max\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .lg-max\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .lg-max\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .lg-max\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .lg-max\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .lg-max\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .lg-max\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .lg-max\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .lg-max\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .lg-max\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .lg-max\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .lg-max\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .lg-max\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .lg-max\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .lg-max\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .lg-max\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .lg-max\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .lg-max\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .lg-max\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .lg-max\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .lg-max\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .lg-max\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .lg-max\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .lg-max\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .lg-max\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .lg-max\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .lg-max\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .lg-max\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .lg-max\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .lg-max\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .lg-max\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .lg-max\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .lg-max\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .lg-max\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .lg-max\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .lg-max\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .lg-max\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .lg-max\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .lg-max\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .lg-max\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .lg-max\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .lg-max\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .lg-max\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .lg-max\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .lg-max\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .lg-max\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .lg-max\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .lg-max\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .lg-max\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .lg-max\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .lg-max\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .lg-max\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .lg-max\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .lg-max\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .lg-max\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .lg-max\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .lg-max\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .lg-max\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .lg-max\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .lg-max\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .lg-max\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .lg-max\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .lg-max\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .lg-max\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .lg-max\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .lg-max\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .lg-max\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .lg-max\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .lg-max\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .lg-max\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .lg-max\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .lg-max\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .lg-max\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .lg-max\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .lg-max\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .lg-max\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .lg-max\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .lg-max\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .lg-max\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .lg-max\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .lg-max\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .lg-max\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .lg-max\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .lg-max\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .lg-max\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .lg-max\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .lg-max\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .lg-max\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .lg-max\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .lg-max\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .lg-max\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .lg-max\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .lg-max\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .lg-max\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .lg-max\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .lg-max\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .lg-max\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .lg-max\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .lg-max\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .lg-max\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .lg-max\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .lg-max\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .lg-max\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .lg-max\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .lg-max\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .lg-max\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .lg-max\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .lg-max\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .lg-max\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .lg-max\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .lg-max\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .lg-max\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .lg-max\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .lg-max\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .lg-max\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .lg-max\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .lg-max\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .lg-max\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .lg-max\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .lg-max\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .lg-max\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .lg-max\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .lg-max\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .lg-max\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .lg-max\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .lg-max\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .lg-max\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .lg-max\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .lg-max\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .lg-max\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .lg-max\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .lg-max\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .lg-max\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .lg-max\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .lg-max\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .lg-max\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .lg-max\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .lg-max\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .lg-max\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .lg-max\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .lg-max\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .lg-max\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .lg-max\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .lg-max\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .lg-max\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .lg-max\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .lg-max\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .lg-max\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .lg-max\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .lg-max\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .lg-max\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .lg-max\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .lg-max\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .lg-max\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .lg-max\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .lg-max\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .lg-max\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .lg-max\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .lg-max\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .lg-max\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .lg-max\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .lg-max\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .lg-max\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .lg-max\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .lg-max\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .lg-max\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .lg-max\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .lg-max\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .lg-max\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .lg-max\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .lg-max\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .lg-max\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .lg-max\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .lg-max\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .lg-max\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .lg-max\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .lg-max\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .lg-max\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .lg-max\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .lg-max\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .lg-max\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .lg-max\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .lg-max\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .lg-max\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .lg-max\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .lg-max\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .lg-max\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .lg-max\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .lg-max\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .lg-max\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .lg-max\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .lg-max\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .lg-max\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .lg-max\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .lg-max\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .lg-max\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .lg-max\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .lg-max\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .lg-max\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .lg-max\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .lg-max\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .lg-max\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .lg-max\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .lg-max\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .lg-max\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .lg-max\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .lg-max\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .lg-max\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .lg-max\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .lg-max\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .lg-max\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .lg-max\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .lg-max\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .lg-max\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .lg-max\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .lg-max\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .lg-max\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .lg-max\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg-max\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg-max\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg-max\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg-max\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg-max\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg-max\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg-max\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg-max\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg-max\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg-max\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg-max\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg-max\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg-max\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg-max\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg-max\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg-max\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg-max\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg-max\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg-max\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg-max\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg-max\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg-max\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg-max\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg-max\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg-max\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg-max\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg-max\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg-max\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg-max\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg-max\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .lg-max\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .lg-max\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .lg-max\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .lg-max\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .lg-max\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .lg-max\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .lg-max\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .lg-max\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .lg-max\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .lg-max\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .lg-max\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .lg-max\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .lg-max\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .lg-max\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .lg-max\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .lg-max\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .lg-max\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .lg-max\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .lg-max\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .lg-max\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .lg-max\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .lg-max\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .lg-max\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .lg-max\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .lg-max\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .lg-max\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .lg-max\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .lg-max\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .lg-max\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .lg-max\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .lg-max\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .lg-max\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .lg-max\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .lg-max\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .lg-max\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .lg-max\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .lg-max\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .lg-max\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .lg-max\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .lg-max\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .lg-max\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .lg-max\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .lg-max\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .lg-max\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .lg-max\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .lg-max\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .lg-max\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .lg-max\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .lg-max\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .lg-max\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .lg-max\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .lg-max\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .lg-max\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .lg-max\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .lg-max\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .lg-max\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .lg-max\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .lg-max\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .lg-max\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .lg-max\:animate-none {
    animation: none;
  }

  .lg-max\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .lg-max\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .lg-max\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .lg-max\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .lg-max\:cursor-auto {
    cursor: auto;
  }

  .lg-max\:cursor-default {
    cursor: default;
  }

  .lg-max\:cursor-pointer {
    cursor: pointer;
  }

  .lg-max\:cursor-wait {
    cursor: wait;
  }

  .lg-max\:cursor-text {
    cursor: text;
  }

  .lg-max\:cursor-move {
    cursor: move;
  }

  .lg-max\:cursor-help {
    cursor: help;
  }

  .lg-max\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg-max\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .lg-max\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .lg-max\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .lg-max\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .lg-max\:resize-none {
    resize: none;
  }

  .lg-max\:resize-y {
    resize: vertical;
  }

  .lg-max\:resize-x {
    resize: horizontal;
  }

  .lg-max\:resize {
    resize: both;
  }

  .lg-max\:list-inside {
    list-style-position: inside;
  }

  .lg-max\:list-outside {
    list-style-position: outside;
  }

  .lg-max\:list-none {
    list-style-type: none;
  }

  .lg-max\:list-disc {
    list-style-type: disc;
  }

  .lg-max\:list-decimal {
    list-style-type: decimal;
  }

  .lg-max\:appearance-none {
    appearance: none;
  }

  .lg-max\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg-max\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .lg-max\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .lg-max\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg-max\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg-max\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg-max\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg-max\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg-max\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg-max\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .lg-max\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .lg-max\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg-max\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg-max\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg-max\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg-max\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg-max\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg-max\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg-max\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg-max\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg-max\:grid-rows-none {
    grid-template-rows: none;
  }

  .lg-max\:flex-row {
    flex-direction: row;
  }

  .lg-max\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg-max\:flex-col {
    flex-direction: column;
  }

  .lg-max\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg-max\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg-max\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg-max\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg-max\:place-content-center {
    place-content: center;
  }

  .lg-max\:place-content-start {
    place-content: start;
  }

  .lg-max\:place-content-end {
    place-content: end;
  }

  .lg-max\:place-content-between {
    place-content: space-between;
  }

  .lg-max\:place-content-around {
    place-content: space-around;
  }

  .lg-max\:place-content-evenly {
    place-content: space-evenly;
  }

  .lg-max\:place-content-stretch {
    place-content: stretch;
  }

  .lg-max\:place-items-start {
    place-items: start;
  }

  .lg-max\:place-items-end {
    place-items: end;
  }

  .lg-max\:place-items-center {
    place-items: center;
  }

  .lg-max\:place-items-stretch {
    place-items: stretch stretch;
  }

  .lg-max\:content-center {
    align-content: center;
  }

  .lg-max\:content-start {
    align-content: flex-start;
  }

  .lg-max\:content-end {
    align-content: flex-end;
  }

  .lg-max\:content-between {
    align-content: space-between;
  }

  .lg-max\:content-around {
    align-content: space-around;
  }

  .lg-max\:content-evenly {
    align-content: space-evenly;
  }

  .lg-max\:items-start {
    align-items: flex-start;
  }

  .lg-max\:items-end {
    align-items: flex-end;
  }

  .lg-max\:items-center {
    align-items: center;
  }

  .lg-max\:items-baseline {
    align-items: baseline;
  }

  .lg-max\:items-stretch {
    align-items: stretch;
  }

  .lg-max\:justify-start {
    justify-content: flex-start;
  }

  .lg-max\:justify-end {
    justify-content: flex-end;
  }

  .lg-max\:justify-center {
    justify-content: center;
  }

  .lg-max\:justify-between {
    justify-content: space-between;
  }

  .lg-max\:justify-around {
    justify-content: space-around;
  }

  .lg-max\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg-max\:justify-items-start {
    justify-items: start;
  }

  .lg-max\:justify-items-end {
    justify-items: end;
  }

  .lg-max\:justify-items-center {
    justify-items: center;
  }

  .lg-max\:justify-items-stretch {
    justify-items: stretch;
  }

  .lg-max\:gap-0 {
    gap: 0;
  }

  .lg-max\:gap-1 {
    gap: .25rem;
  }

  .lg-max\:gap-2 {
    gap: .5rem;
  }

  .lg-max\:gap-3 {
    gap: .75rem;
  }

  .lg-max\:gap-4 {
    gap: 1rem;
  }

  .lg-max\:gap-5 {
    gap: 1.25rem;
  }

  .lg-max\:gap-6 {
    gap: 1.5rem;
  }

  .lg-max\:gap-7 {
    gap: 1.75rem;
  }

  .lg-max\:gap-8 {
    gap: 2rem;
  }

  .lg-max\:gap-9 {
    gap: 2.25rem;
  }

  .lg-max\:gap-10 {
    gap: 2.5rem;
  }

  .lg-max\:gap-11 {
    gap: 2.75rem;
  }

  .lg-max\:gap-12 {
    gap: 3rem;
  }

  .lg-max\:gap-14 {
    gap: 3.5rem;
  }

  .lg-max\:gap-16 {
    gap: 4rem;
  }

  .lg-max\:gap-20 {
    gap: 5rem;
  }

  .lg-max\:gap-24 {
    gap: 6rem;
  }

  .lg-max\:gap-28 {
    gap: 7rem;
  }

  .lg-max\:gap-32 {
    gap: 8rem;
  }

  .lg-max\:gap-36 {
    gap: 9rem;
  }

  .lg-max\:gap-40 {
    gap: 10rem;
  }

  .lg-max\:gap-44 {
    gap: 11rem;
  }

  .lg-max\:gap-48 {
    gap: 12rem;
  }

  .lg-max\:gap-52 {
    gap: 13rem;
  }

  .lg-max\:gap-56 {
    gap: 14rem;
  }

  .lg-max\:gap-60 {
    gap: 15rem;
  }

  .lg-max\:gap-64 {
    gap: 16rem;
  }

  .lg-max\:gap-72 {
    gap: 18rem;
  }

  .lg-max\:gap-80 {
    gap: 20rem;
  }

  .lg-max\:gap-96 {
    gap: 24rem;
  }

  .lg-max\:gap-px {
    gap: 1px;
  }

  .lg-max\:gap-0\.5 {
    gap: .125rem;
  }

  .lg-max\:gap-1\.5 {
    gap: .375rem;
  }

  .lg-max\:gap-2\.5 {
    gap: .625rem;
  }

  .lg-max\:gap-3\.5 {
    gap: .875rem;
  }

  .lg-max\:gap-x-0 {
    column-gap: 0;
  }

  .lg-max\:gap-x-1 {
    column-gap: .25rem;
  }

  .lg-max\:gap-x-2 {
    column-gap: .5rem;
  }

  .lg-max\:gap-x-3 {
    column-gap: .75rem;
  }

  .lg-max\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg-max\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg-max\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg-max\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .lg-max\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg-max\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .lg-max\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg-max\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .lg-max\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg-max\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .lg-max\:gap-x-16 {
    column-gap: 4rem;
  }

  .lg-max\:gap-x-20 {
    column-gap: 5rem;
  }

  .lg-max\:gap-x-24 {
    column-gap: 6rem;
  }

  .lg-max\:gap-x-28 {
    column-gap: 7rem;
  }

  .lg-max\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg-max\:gap-x-36 {
    column-gap: 9rem;
  }

  .lg-max\:gap-x-40 {
    column-gap: 10rem;
  }

  .lg-max\:gap-x-44 {
    column-gap: 11rem;
  }

  .lg-max\:gap-x-48 {
    column-gap: 12rem;
  }

  .lg-max\:gap-x-52 {
    column-gap: 13rem;
  }

  .lg-max\:gap-x-56 {
    column-gap: 14rem;
  }

  .lg-max\:gap-x-60 {
    column-gap: 15rem;
  }

  .lg-max\:gap-x-64 {
    column-gap: 16rem;
  }

  .lg-max\:gap-x-72 {
    column-gap: 18rem;
  }

  .lg-max\:gap-x-80 {
    column-gap: 20rem;
  }

  .lg-max\:gap-x-96 {
    column-gap: 24rem;
  }

  .lg-max\:gap-x-px {
    column-gap: 1px;
  }

  .lg-max\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .lg-max\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .lg-max\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .lg-max\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .lg-max\:gap-y-0 {
    row-gap: 0;
  }

  .lg-max\:gap-y-1 {
    row-gap: .25rem;
  }

  .lg-max\:gap-y-2 {
    row-gap: .5rem;
  }

  .lg-max\:gap-y-3 {
    row-gap: .75rem;
  }

  .lg-max\:gap-y-4 {
    row-gap: 1rem;
  }

  .lg-max\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .lg-max\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .lg-max\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .lg-max\:gap-y-8 {
    row-gap: 2rem;
  }

  .lg-max\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .lg-max\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg-max\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .lg-max\:gap-y-12 {
    row-gap: 3rem;
  }

  .lg-max\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .lg-max\:gap-y-16 {
    row-gap: 4rem;
  }

  .lg-max\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg-max\:gap-y-24 {
    row-gap: 6rem;
  }

  .lg-max\:gap-y-28 {
    row-gap: 7rem;
  }

  .lg-max\:gap-y-32 {
    row-gap: 8rem;
  }

  .lg-max\:gap-y-36 {
    row-gap: 9rem;
  }

  .lg-max\:gap-y-40 {
    row-gap: 10rem;
  }

  .lg-max\:gap-y-44 {
    row-gap: 11rem;
  }

  .lg-max\:gap-y-48 {
    row-gap: 12rem;
  }

  .lg-max\:gap-y-52 {
    row-gap: 13rem;
  }

  .lg-max\:gap-y-56 {
    row-gap: 14rem;
  }

  .lg-max\:gap-y-60 {
    row-gap: 15rem;
  }

  .lg-max\:gap-y-64 {
    row-gap: 16rem;
  }

  .lg-max\:gap-y-72 {
    row-gap: 18rem;
  }

  .lg-max\:gap-y-80 {
    row-gap: 20rem;
  }

  .lg-max\:gap-y-96 {
    row-gap: 24rem;
  }

  .lg-max\:gap-y-px {
    row-gap: 1px;
  }

  .lg-max\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .lg-max\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .lg-max\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .lg-max\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .lg-max\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg-max\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .lg-max\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .lg-max\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .lg-max\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .lg-max\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg-max\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg-max\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg-max\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg-max\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg-max\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg-max\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .lg-max\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .lg-max\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .lg-max\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .lg-max\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .lg-max\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .lg-max\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .lg-max\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .lg-max\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .lg-max\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .lg-max\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .lg-max\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .lg-max\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .lg-max\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .lg-max\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .lg-max\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .lg-max\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .lg-max\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .lg-max\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .lg-max\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .lg-max\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .lg-max\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .lg-max\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .lg-max\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .lg-max\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .lg-max\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .lg-max\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .lg-max\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .lg-max\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .lg-max\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .lg-max\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .lg-max\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .lg-max\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .lg-max\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .lg-max\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .lg-max\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .lg-max\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .lg-max\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .lg-max\:place-self-auto {
    place-self: auto;
  }

  .lg-max\:place-self-start {
    place-self: start;
  }

  .lg-max\:place-self-end {
    place-self: end;
  }

  .lg-max\:place-self-center {
    place-self: center;
  }

  .lg-max\:place-self-stretch {
    place-self: stretch stretch;
  }

  .lg-max\:self-auto {
    align-self: auto;
  }

  .lg-max\:self-start {
    align-self: flex-start;
  }

  .lg-max\:self-end {
    align-self: flex-end;
  }

  .lg-max\:self-center {
    align-self: center;
  }

  .lg-max\:self-stretch {
    align-self: stretch;
  }

  .lg-max\:self-baseline {
    align-self: baseline;
  }

  .lg-max\:justify-self-auto {
    justify-self: auto;
  }

  .lg-max\:justify-self-start {
    justify-self: start;
  }

  .lg-max\:justify-self-end {
    justify-self: end;
  }

  .lg-max\:justify-self-center {
    justify-self: center;
  }

  .lg-max\:justify-self-stretch {
    justify-self: stretch;
  }

  .lg-max\:overflow-auto {
    overflow: auto;
  }

  .lg-max\:overflow-hidden {
    overflow: hidden;
  }

  .lg-max\:overflow-visible {
    overflow: visible;
  }

  .lg-max\:overflow-scroll {
    overflow: scroll;
  }

  .lg-max\:overflow-x-auto {
    overflow-x: auto;
  }

  .lg-max\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg-max\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg-max\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg-max\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg-max\:overflow-y-visible {
    overflow-y: visible;
  }

  .lg-max\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg-max\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg-max\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .lg-max\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .lg-max\:overscroll-none {
    overscroll-behavior: none;
  }

  .lg-max\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg-max\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg-max\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg-max\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg-max\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg-max\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg-max\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .lg-max\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .lg-max\:overflow-clip {
    text-overflow: clip;
  }

  .lg-max\:whitespace-normal {
    white-space: normal;
  }

  .lg-max\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg-max\:whitespace-pre {
    white-space: pre;
  }

  .lg-max\:whitespace-pre-line {
    white-space: pre-line;
  }

  .lg-max\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg-max\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg-max\:break-words {
    overflow-wrap: break-word;
  }

  .lg-max\:break-all {
    word-break: break-all;
  }

  .lg-max\:rounded-none {
    border-radius: 0;
  }

  .lg-max\:rounded-sm {
    border-radius: .125rem;
  }

  .lg-max\:rounded {
    border-radius: .25rem;
  }

  .lg-max\:rounded-md {
    border-radius: .375rem;
  }

  .lg-max\:rounded-lg {
    border-radius: .5rem;
  }

  .lg-max\:rounded-xl {
    border-radius: .75rem;
  }

  .lg-max\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg-max\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg-max\:rounded-full {
    border-radius: 9999px;
  }

  .lg-max\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg-max\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .lg-max\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .lg-max\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .lg-max\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .lg-max\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .lg-max\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg-max\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg-max\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg-max\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg-max\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .lg-max\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .lg-max\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .lg-max\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .lg-max\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .lg-max\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg-max\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg-max\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg-max\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg-max\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg-max\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg-max\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg-max\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg-max\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg-max\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg-max\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg-max\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg-max\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg-max\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg-max\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg-max\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg-max\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg-max\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg-max\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg-max\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg-max\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg-max\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg-max\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .lg-max\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .lg-max\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .lg-max\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .lg-max\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .lg-max\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg-max\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg-max\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg-max\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg-max\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .lg-max\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .lg-max\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .lg-max\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .lg-max\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .lg-max\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg-max\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg-max\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg-max\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg-max\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .lg-max\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .lg-max\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .lg-max\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .lg-max\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .lg-max\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg-max\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg-max\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg-max\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg-max\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .lg-max\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .lg-max\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .lg-max\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .lg-max\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .lg-max\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg-max\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg-max\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg-max\:border-0 {
    border-width: 0;
  }

  .lg-max\:border-2 {
    border-width: 2px;
  }

  .lg-max\:border-4 {
    border-width: 4px;
  }

  .lg-max\:border-8 {
    border-width: 8px;
  }

  .lg-max\:border {
    border-width: 1px;
  }

  .lg-max\:border-t-0 {
    border-top-width: 0;
  }

  .lg-max\:border-t-2 {
    border-top-width: 2px;
  }

  .lg-max\:border-t-4 {
    border-top-width: 4px;
  }

  .lg-max\:border-t-8 {
    border-top-width: 8px;
  }

  .lg-max\:border-t {
    border-top-width: 1px;
  }

  .lg-max\:border-r-0 {
    border-right-width: 0;
  }

  .lg-max\:border-r-2 {
    border-right-width: 2px;
  }

  .lg-max\:border-r-4 {
    border-right-width: 4px;
  }

  .lg-max\:border-r-8 {
    border-right-width: 8px;
  }

  .lg-max\:border-r {
    border-right-width: 1px;
  }

  .lg-max\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg-max\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg-max\:border-b-4 {
    border-bottom-width: 4px;
  }

  .lg-max\:border-b-8 {
    border-bottom-width: 8px;
  }

  .lg-max\:border-b {
    border-bottom-width: 1px;
  }

  .lg-max\:border-l-0 {
    border-left-width: 0;
  }

  .lg-max\:border-l-2 {
    border-left-width: 2px;
  }

  .lg-max\:border-l-4 {
    border-left-width: 4px;
  }

  .lg-max\:border-l-8 {
    border-left-width: 8px;
  }

  .lg-max\:border-l {
    border-left-width: 1px;
  }

  .lg-max\:border-solid {
    border-style: solid;
  }

  .lg-max\:border-dashed {
    border-style: dashed;
  }

  .lg-max\:border-dotted {
    border-style: dotted;
  }

  .lg-max\:border-double {
    border-style: double;
  }

  .lg-max\:border-none {
    border-style: none;
  }

  .lg-max\:border-transparent {
    border-color: #0000;
  }

  .lg-max\:border-current {
    border-color: currentColor;
  }

  .lg-max\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg-max\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg-max\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg-max\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg-max\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg-max\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg-max\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg-max\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg-max\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg-max\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg-max\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg-max\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg-max\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg-max\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg-max\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg-max\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg-max\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg-max\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .lg-max\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .lg-max\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .lg-max\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .lg-max\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .lg-max\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg-max\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .lg-max\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .lg-max\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg-max\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .lg-max\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .lg-max\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .lg-max\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .lg-max\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .lg-max\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .lg-max\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .lg-max\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .lg-max\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .lg-max\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .lg-max\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .lg-max\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .lg-max\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .lg-max\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .lg-max\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .lg-max\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .lg-max\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .lg-max\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .lg-max\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .lg-max\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .lg-max\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .lg-max\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .lg-max\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .lg-max\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .lg-max\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .lg-max\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .lg-max\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .lg-max\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .lg-max\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .lg-max\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .lg-max\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .lg-max\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .lg-max\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .lg-max\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .lg-max\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .lg-max\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .lg-max\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .lg-max\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .lg-max\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .lg-max\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .lg-max\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .lg-max\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .lg-max\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .lg-max\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .lg-max\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .lg-max\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .lg-max\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .lg-max\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .lg-max\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .lg-max\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .lg-max\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .lg-max\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .lg-max\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .lg-max\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .lg-max\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .lg-max\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .lg-max\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .lg-max\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .lg-max\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .lg-max\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .lg-max\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .lg-max\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .lg-max\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .lg-max\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .lg-max\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .lg-max\:bg-transparent {
    background-color: #0000;
  }

  .lg-max\:bg-current {
    background-color: currentColor;
  }

  .lg-max\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg-max\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg-max\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg-max\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg-max\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg-max\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg-max\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg-max\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg-max\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .lg-max\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .lg-max\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .lg-max\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .lg-max\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .lg-max\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg-max\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .lg-max\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .lg-max\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg-max\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .lg-max\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .lg-max\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .lg-max\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .lg-max\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg-max\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .lg-max\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .lg-max\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .lg-max\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .lg-max\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .lg-max\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .lg-max\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .lg-max\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .lg-max\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .lg-max\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .lg-max\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .lg-max\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .lg-max\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .lg-max\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .lg-max\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .lg-max\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .lg-max\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .lg-max\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .lg-max\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .lg-max\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .lg-max\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .lg-max\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .lg-max\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .lg-max\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .lg-max\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .lg-max\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .lg-max\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .lg-max\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .lg-max\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .lg-max\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .lg-max\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .lg-max\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .lg-max\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .lg-max\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .lg-max\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .lg-max\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .lg-max\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .lg-max\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .lg-max\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .lg-max\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .lg-max\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .lg-max\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .lg-max\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .lg-max\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .lg-max\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .lg-max\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .lg-max\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .lg-max\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .lg-max\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .lg-max\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .lg-max\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .lg-max\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .lg-max\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .lg-max\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .lg-max\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .lg-max\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .lg-max\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .lg-max\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .lg-max\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .lg-max\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .lg-max\:bg-none {
    background-image: none;
  }

  .lg-max\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .lg-max\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .lg-max\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg-max\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg-max\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg-max\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg-max\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .lg-max\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .lg-max\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .lg-max\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .lg-max\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .lg-max\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .lg-max\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .lg-max\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .lg-max\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .lg-max\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .lg-max\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .lg-max\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .lg-max\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .lg-max\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .lg-max\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .lg-max\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .lg-max\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .lg-max\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .lg-max\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .lg-max\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .lg-max\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .lg-max\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .lg-max\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .lg-max\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .lg-max\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .lg-max\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .lg-max\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .lg-max\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .lg-max\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .lg-max\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .lg-max\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .lg-max\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .lg-max\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .lg-max\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .lg-max\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .lg-max\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .lg-max\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .lg-max\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .lg-max\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .lg-max\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .lg-max\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .lg-max\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .lg-max\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .lg-max\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .lg-max\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .lg-max\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .lg-max\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .lg-max\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .lg-max\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .lg-max\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .lg-max\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .lg-max\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .lg-max\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .lg-max\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .lg-max\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .lg-max\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .lg-max\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .lg-max\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .lg-max\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .lg-max\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .lg-max\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .lg-max\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .lg-max\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .lg-max\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .lg-max\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .lg-max\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .lg-max\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .lg-max\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .lg-max\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .lg-max\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .lg-max\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .lg-max\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg-max\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg-max\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg-max\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg-max\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .lg-max\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .lg-max\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .lg-max\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .lg-max\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .lg-max\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .lg-max\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .lg-max\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .lg-max\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .lg-max\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .lg-max\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .lg-max\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .lg-max\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .lg-max\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .lg-max\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .lg-max\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .lg-max\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .lg-max\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .lg-max\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .lg-max\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .lg-max\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .lg-max\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .lg-max\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .lg-max\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .lg-max\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .lg-max\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .lg-max\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .lg-max\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .lg-max\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .lg-max\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .lg-max\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .lg-max\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .lg-max\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .lg-max\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .lg-max\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .lg-max\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .lg-max\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .lg-max\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .lg-max\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .lg-max\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .lg-max\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .lg-max\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .lg-max\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .lg-max\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .lg-max\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .lg-max\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .lg-max\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .lg-max\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .lg-max\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .lg-max\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .lg-max\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .lg-max\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .lg-max\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .lg-max\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .lg-max\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .lg-max\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .lg-max\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .lg-max\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .lg-max\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .lg-max\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .lg-max\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .lg-max\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .lg-max\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .lg-max\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .lg-max\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .lg-max\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .lg-max\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .lg-max\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .lg-max\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .lg-max\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .lg-max\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .lg-max\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg-max\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg-max\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .lg-max\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .lg-max\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .lg-max\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .lg-max\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .lg-max\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .lg-max\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .lg-max\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .lg-max\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .lg-max\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .lg-max\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .lg-max\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .lg-max\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .lg-max\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .lg-max\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .lg-max\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .lg-max\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .lg-max\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .lg-max\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .lg-max\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .lg-max\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .lg-max\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .lg-max\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .lg-max\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .lg-max\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .lg-max\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .lg-max\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .lg-max\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .lg-max\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .lg-max\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .lg-max\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .lg-max\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .lg-max\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .lg-max\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .lg-max\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .lg-max\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .lg-max\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .lg-max\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .lg-max\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .lg-max\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .lg-max\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .lg-max\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .lg-max\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .lg-max\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .lg-max\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .lg-max\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .lg-max\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .lg-max\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .lg-max\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .lg-max\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .lg-max\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .lg-max\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .lg-max\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .lg-max\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .lg-max\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .lg-max\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .lg-max\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .lg-max\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .lg-max\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .lg-max\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .lg-max\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .lg-max\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .lg-max\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .lg-max\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .lg-max\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .lg-max\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .lg-max\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .lg-max\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .lg-max\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .lg-max\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .lg-max\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .lg-max\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .lg-max\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .lg-max\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .lg-max\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .lg-max\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .lg-max\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .lg-max\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .lg-max\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .lg-max\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .lg-max\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .lg-max\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .lg-max\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .lg-max\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .lg-max\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .lg-max\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .lg-max\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .lg-max\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .lg-max\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .lg-max\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .lg-max\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .lg-max\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .lg-max\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .lg-max\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .lg-max\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .lg-max\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .lg-max\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .lg-max\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .lg-max\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .lg-max\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .lg-max\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .lg-max\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .lg-max\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .lg-max\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .lg-max\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .lg-max\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .lg-max\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .lg-max\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .lg-max\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .lg-max\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .lg-max\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .lg-max\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .lg-max\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .lg-max\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .lg-max\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .lg-max\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .lg-max\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .lg-max\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .lg-max\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .lg-max\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .lg-max\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .lg-max\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .lg-max\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .lg-max\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .lg-max\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .lg-max\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .lg-max\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .lg-max\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .lg-max\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .lg-max\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .lg-max\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .lg-max\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .lg-max\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .lg-max\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .lg-max\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .lg-max\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .lg-max\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .lg-max\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .lg-max\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .lg-max\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .lg-max\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .lg-max\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .lg-max\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .lg-max\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .lg-max\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .lg-max\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .lg-max\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .lg-max\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .lg-max\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .lg-max\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .lg-max\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .lg-max\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .lg-max\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .lg-max\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .lg-max\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .lg-max\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .lg-max\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .lg-max\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .lg-max\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .lg-max\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .lg-max\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .lg-max\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .lg-max\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .lg-max\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .lg-max\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .lg-max\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .lg-max\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .lg-max\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .lg-max\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .lg-max\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .lg-max\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .lg-max\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .lg-max\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .lg-max\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .lg-max\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .lg-max\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .lg-max\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .lg-max\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .lg-max\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .lg-max\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .lg-max\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .lg-max\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .lg-max\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .lg-max\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .lg-max\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .lg-max\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .lg-max\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .lg-max\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .lg-max\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .lg-max\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .lg-max\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .lg-max\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .lg-max\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .lg-max\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .lg-max\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .lg-max\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .lg-max\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .lg-max\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .lg-max\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .lg-max\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .lg-max\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .lg-max\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .lg-max\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .lg-max\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .lg-max\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .lg-max\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .lg-max\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .lg-max\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .lg-max\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .lg-max\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .lg-max\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .lg-max\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .lg-max\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .lg-max\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .lg-max\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .lg-max\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .lg-max\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .lg-max\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .lg-max\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .lg-max\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .lg-max\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .lg-max\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .lg-max\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .lg-max\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .lg-max\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .lg-max\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .lg-max\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .lg-max\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .lg-max\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .lg-max\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .lg-max\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .lg-max\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .lg-max\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .lg-max\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .lg-max\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .lg-max\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .lg-max\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .lg-max\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .lg-max\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .lg-max\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .lg-max\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .lg-max\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .lg-max\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .lg-max\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .lg-max\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .lg-max\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .lg-max\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .lg-max\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .lg-max\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .lg-max\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .lg-max\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .lg-max\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .lg-max\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .lg-max\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .lg-max\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .lg-max\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .lg-max\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .lg-max\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .lg-max\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .lg-max\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .lg-max\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .lg-max\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .lg-max\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .lg-max\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .lg-max\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .lg-max\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .lg-max\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .lg-max\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .lg-max\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .lg-max\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .lg-max\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .lg-max\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .lg-max\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .lg-max\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .lg-max\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .lg-max\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .lg-max\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .lg-max\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .lg-max\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .lg-max\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .lg-max\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .lg-max\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .lg-max\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .lg-max\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .lg-max\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .lg-max\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .lg-max\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .lg-max\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .lg-max\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .lg-max\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .lg-max\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .lg-max\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .lg-max\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .lg-max\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .lg-max\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .lg-max\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .lg-max\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .lg-max\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .lg-max\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .lg-max\:to-current {
    --tw-gradient-to: currentColor;
  }

  .lg-max\:to-black {
    --tw-gradient-to: #000;
  }

  .lg-max\:to-white {
    --tw-gradient-to: #fff;
  }

  .lg-max\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .lg-max\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .lg-max\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .lg-max\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .lg-max\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .lg-max\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .lg-max\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .lg-max\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .lg-max\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .lg-max\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .lg-max\:to-red {
    --tw-gradient-to: red;
  }

  .lg-max\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .lg-max\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .lg-max\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .lg-max\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .lg-max\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .lg-max\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .lg-max\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .lg-max\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .lg-max\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .lg-max\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .lg-max\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .lg-max\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .lg-max\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .lg-max\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .lg-max\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .lg-max\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .lg-max\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .lg-max\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .lg-max\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .lg-max\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .lg-max\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .lg-max\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .lg-max\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .lg-max\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .lg-max\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .lg-max\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .lg-max\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .lg-max\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .lg-max\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .lg-max\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .lg-max\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .lg-max\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .lg-max\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .lg-max\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .lg-max\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .lg-max\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .lg-max\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .lg-max\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .lg-max\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .lg-max\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .lg-max\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .lg-max\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .lg-max\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .lg-max\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .lg-max\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .lg-max\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .lg-max\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .lg-max\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .lg-max\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .lg-max\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .lg-max\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .lg-max\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .lg-max\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .lg-max\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .lg-max\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .lg-max\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .lg-max\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .lg-max\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .lg-max\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .lg-max\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .lg-max\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .lg-max\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .lg-max\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .lg-max\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .lg-max\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .lg-max\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .lg-max\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .lg-max\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .lg-max\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .lg-max\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .lg-max\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .lg-max\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .lg-max\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .lg-max\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .lg-max\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .lg-max\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .lg-max\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .lg-max\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .lg-max\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .lg-max\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .lg-max\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .lg-max\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .lg-max\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .lg-max\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .lg-max\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .lg-max\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .lg-max\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .lg-max\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .lg-max\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .lg-max\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .lg-max\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .lg-max\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .lg-max\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .lg-max\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .lg-max\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .lg-max\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .lg-max\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .lg-max\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .lg-max\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .lg-max\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .lg-max\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .lg-max\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .lg-max\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .lg-max\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .lg-max\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .lg-max\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .lg-max\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .lg-max\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .lg-max\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .lg-max\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .lg-max\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .lg-max\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .lg-max\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .lg-max\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .lg-max\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .lg-max\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .lg-max\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .lg-max\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .lg-max\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .lg-max\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .lg-max\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .lg-max\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .lg-max\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .lg-max\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .lg-max\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .lg-max\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .lg-max\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .lg-max\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .lg-max\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .lg-max\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .lg-max\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .lg-max\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .lg-max\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .lg-max\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .lg-max\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .lg-max\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .lg-max\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .lg-max\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .lg-max\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .lg-max\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .lg-max\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .lg-max\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .lg-max\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .lg-max\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .lg-max\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .lg-max\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .lg-max\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .lg-max\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .lg-max\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .lg-max\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .lg-max\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .lg-max\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .lg-max\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .lg-max\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .lg-max\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .lg-max\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .lg-max\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .lg-max\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .lg-max\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .lg-max\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .lg-max\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .lg-max\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .lg-max\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .lg-max\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .lg-max\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .lg-max\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .lg-max\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .lg-max\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .lg-max\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .lg-max\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .lg-max\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .lg-max\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .lg-max\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .lg-max\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .lg-max\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .lg-max\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .lg-max\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .lg-max\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .lg-max\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .lg-max\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .lg-max\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .lg-max\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .lg-max\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .lg-max\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .lg-max\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .lg-max\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .lg-max\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .lg-max\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .lg-max\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .lg-max\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .lg-max\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .lg-max\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .lg-max\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .lg-max\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .lg-max\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .lg-max\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .lg-max\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .lg-max\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .lg-max\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .lg-max\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .lg-max\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .lg-max\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .lg-max\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .lg-max\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .lg-max\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .lg-max\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .lg-max\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .lg-max\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .lg-max\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .lg-max\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .lg-max\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .lg-max\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .lg-max\:bg-auto {
    background-size: auto;
  }

  .lg-max\:bg-cover {
    background-size: cover;
  }

  .lg-max\:bg-contain {
    background-size: contain;
  }

  .lg-max\:bg-fixed {
    background-attachment: fixed;
  }

  .lg-max\:bg-local {
    background-attachment: local;
  }

  .lg-max\:bg-scroll {
    background-attachment: scroll;
  }

  .lg-max\:bg-clip-border {
    background-clip: border-box;
  }

  .lg-max\:bg-clip-padding {
    background-clip: padding-box;
  }

  .lg-max\:bg-clip-content {
    background-clip: content-box;
  }

  .lg-max\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .lg-max\:bg-bottom {
    background-position: bottom;
  }

  .lg-max\:bg-center {
    background-position: center;
  }

  .lg-max\:bg-left {
    background-position: 0;
  }

  .lg-max\:bg-left-bottom {
    background-position: 0 100%;
  }

  .lg-max\:bg-left-top {
    background-position: 0 0;
  }

  .lg-max\:bg-right {
    background-position: 100%;
  }

  .lg-max\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .lg-max\:bg-right-top {
    background-position: 100% 0;
  }

  .lg-max\:bg-top {
    background-position: top;
  }

  .lg-max\:bg-repeat {
    background-repeat: repeat;
  }

  .lg-max\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg-max\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg-max\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg-max\:bg-repeat-round {
    background-repeat: round;
  }

  .lg-max\:bg-repeat-space {
    background-repeat: space;
  }

  .lg-max\:bg-origin-border {
    background-origin: border-box;
  }

  .lg-max\:bg-origin-padding {
    background-origin: padding-box;
  }

  .lg-max\:bg-origin-content {
    background-origin: content-box;
  }

  .lg-max\:fill-current {
    fill: currentColor;
  }

  .lg-max\:stroke-current {
    stroke: currentColor;
  }

  .lg-max\:stroke-0 {
    stroke-width: 0;
  }

  .lg-max\:stroke-1 {
    stroke-width: 1px;
  }

  .lg-max\:stroke-2 {
    stroke-width: 2px;
  }

  .lg-max\:object-contain {
    object-fit: contain;
  }

  .lg-max\:object-cover {
    object-fit: cover;
  }

  .lg-max\:object-fill {
    object-fit: fill;
  }

  .lg-max\:object-none {
    object-fit: none;
  }

  .lg-max\:object-scale-down {
    object-fit: scale-down;
  }

  .lg-max\:object-bottom {
    object-position: bottom;
  }

  .lg-max\:object-center {
    object-position: center;
  }

  .lg-max\:object-left {
    object-position: left;
  }

  .lg-max\:object-left-bottom {
    object-position: left bottom;
  }

  .lg-max\:object-left-top {
    object-position: left top;
  }

  .lg-max\:object-right {
    object-position: right;
  }

  .lg-max\:object-right-bottom {
    object-position: right bottom;
  }

  .lg-max\:object-right-top {
    object-position: right top;
  }

  .lg-max\:object-top {
    object-position: top;
  }

  .lg-max\:p-0 {
    padding: 0;
  }

  .lg-max\:p-1 {
    padding: .25rem;
  }

  .lg-max\:p-2 {
    padding: .5rem;
  }

  .lg-max\:p-3 {
    padding: .75rem;
  }

  .lg-max\:p-4 {
    padding: 1rem;
  }

  .lg-max\:p-5 {
    padding: 1.25rem;
  }

  .lg-max\:p-6 {
    padding: 1.5rem;
  }

  .lg-max\:p-7 {
    padding: 1.75rem;
  }

  .lg-max\:p-8 {
    padding: 2rem;
  }

  .lg-max\:p-9 {
    padding: 2.25rem;
  }

  .lg-max\:p-10 {
    padding: 2.5rem;
  }

  .lg-max\:p-11 {
    padding: 2.75rem;
  }

  .lg-max\:p-12 {
    padding: 3rem;
  }

  .lg-max\:p-14 {
    padding: 3.5rem;
  }

  .lg-max\:p-16 {
    padding: 4rem;
  }

  .lg-max\:p-20 {
    padding: 5rem;
  }

  .lg-max\:p-24 {
    padding: 6rem;
  }

  .lg-max\:p-28 {
    padding: 7rem;
  }

  .lg-max\:p-32 {
    padding: 8rem;
  }

  .lg-max\:p-36 {
    padding: 9rem;
  }

  .lg-max\:p-40 {
    padding: 10rem;
  }

  .lg-max\:p-44 {
    padding: 11rem;
  }

  .lg-max\:p-48 {
    padding: 12rem;
  }

  .lg-max\:p-52 {
    padding: 13rem;
  }

  .lg-max\:p-56 {
    padding: 14rem;
  }

  .lg-max\:p-60 {
    padding: 15rem;
  }

  .lg-max\:p-64 {
    padding: 16rem;
  }

  .lg-max\:p-72 {
    padding: 18rem;
  }

  .lg-max\:p-80 {
    padding: 20rem;
  }

  .lg-max\:p-96 {
    padding: 24rem;
  }

  .lg-max\:p-px {
    padding: 1px;
  }

  .lg-max\:p-0\.5 {
    padding: .125rem;
  }

  .lg-max\:p-1\.5 {
    padding: .375rem;
  }

  .lg-max\:p-2\.5 {
    padding: .625rem;
  }

  .lg-max\:p-3\.5 {
    padding: .875rem;
  }

  .lg-max\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg-max\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .lg-max\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg-max\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg-max\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg-max\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg-max\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg-max\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg-max\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg-max\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg-max\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg-max\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .lg-max\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg-max\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg-max\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg-max\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg-max\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg-max\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg-max\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg-max\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg-max\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg-max\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg-max\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg-max\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .lg-max\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg-max\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .lg-max\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg-max\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .lg-max\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .lg-max\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .lg-max\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg-max\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .lg-max\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .lg-max\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .lg-max\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .lg-max\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg-max\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg-max\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg-max\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg-max\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg-max\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg-max\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg-max\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg-max\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg-max\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg-max\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg-max\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .lg-max\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg-max\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg-max\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg-max\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg-max\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg-max\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg-max\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg-max\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .lg-max\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg-max\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .lg-max\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg-max\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .lg-max\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg-max\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .lg-max\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg-max\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .lg-max\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .lg-max\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .lg-max\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg-max\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .lg-max\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .lg-max\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .lg-max\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .lg-max\:pt-0 {
    padding-top: 0;
  }

  .lg-max\:pt-1 {
    padding-top: .25rem;
  }

  .lg-max\:pt-2 {
    padding-top: .5rem;
  }

  .lg-max\:pt-3 {
    padding-top: .75rem;
  }

  .lg-max\:pt-4 {
    padding-top: 1rem;
  }

  .lg-max\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg-max\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg-max\:pt-7 {
    padding-top: 1.75rem;
  }

  .lg-max\:pt-8 {
    padding-top: 2rem;
  }

  .lg-max\:pt-9 {
    padding-top: 2.25rem;
  }

  .lg-max\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg-max\:pt-11 {
    padding-top: 2.75rem;
  }

  .lg-max\:pt-12 {
    padding-top: 3rem;
  }

  .lg-max\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg-max\:pt-16 {
    padding-top: 4rem;
  }

  .lg-max\:pt-20 {
    padding-top: 5rem;
  }

  .lg-max\:pt-24 {
    padding-top: 6rem;
  }

  .lg-max\:pt-28 {
    padding-top: 7rem;
  }

  .lg-max\:pt-32 {
    padding-top: 8rem;
  }

  .lg-max\:pt-36 {
    padding-top: 9rem;
  }

  .lg-max\:pt-40 {
    padding-top: 10rem;
  }

  .lg-max\:pt-44 {
    padding-top: 11rem;
  }

  .lg-max\:pt-48 {
    padding-top: 12rem;
  }

  .lg-max\:pt-52 {
    padding-top: 13rem;
  }

  .lg-max\:pt-56 {
    padding-top: 14rem;
  }

  .lg-max\:pt-60 {
    padding-top: 15rem;
  }

  .lg-max\:pt-64 {
    padding-top: 16rem;
  }

  .lg-max\:pt-72 {
    padding-top: 18rem;
  }

  .lg-max\:pt-80 {
    padding-top: 20rem;
  }

  .lg-max\:pt-96 {
    padding-top: 24rem;
  }

  .lg-max\:pt-px {
    padding-top: 1px;
  }

  .lg-max\:pt-0\.5 {
    padding-top: .125rem;
  }

  .lg-max\:pt-1\.5 {
    padding-top: .375rem;
  }

  .lg-max\:pt-2\.5 {
    padding-top: .625rem;
  }

  .lg-max\:pt-3\.5 {
    padding-top: .875rem;
  }

  .lg-max\:pr-0 {
    padding-right: 0;
  }

  .lg-max\:pr-1 {
    padding-right: .25rem;
  }

  .lg-max\:pr-2 {
    padding-right: .5rem;
  }

  .lg-max\:pr-3 {
    padding-right: .75rem;
  }

  .lg-max\:pr-4 {
    padding-right: 1rem;
  }

  .lg-max\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg-max\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg-max\:pr-7 {
    padding-right: 1.75rem;
  }

  .lg-max\:pr-8 {
    padding-right: 2rem;
  }

  .lg-max\:pr-9 {
    padding-right: 2.25rem;
  }

  .lg-max\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg-max\:pr-11 {
    padding-right: 2.75rem;
  }

  .lg-max\:pr-12 {
    padding-right: 3rem;
  }

  .lg-max\:pr-14 {
    padding-right: 3.5rem;
  }

  .lg-max\:pr-16 {
    padding-right: 4rem;
  }

  .lg-max\:pr-20 {
    padding-right: 5rem;
  }

  .lg-max\:pr-24 {
    padding-right: 6rem;
  }

  .lg-max\:pr-28 {
    padding-right: 7rem;
  }

  .lg-max\:pr-32 {
    padding-right: 8rem;
  }

  .lg-max\:pr-36 {
    padding-right: 9rem;
  }

  .lg-max\:pr-40 {
    padding-right: 10rem;
  }

  .lg-max\:pr-44 {
    padding-right: 11rem;
  }

  .lg-max\:pr-48 {
    padding-right: 12rem;
  }

  .lg-max\:pr-52 {
    padding-right: 13rem;
  }

  .lg-max\:pr-56 {
    padding-right: 14rem;
  }

  .lg-max\:pr-60 {
    padding-right: 15rem;
  }

  .lg-max\:pr-64 {
    padding-right: 16rem;
  }

  .lg-max\:pr-72 {
    padding-right: 18rem;
  }

  .lg-max\:pr-80 {
    padding-right: 20rem;
  }

  .lg-max\:pr-96 {
    padding-right: 24rem;
  }

  .lg-max\:pr-px {
    padding-right: 1px;
  }

  .lg-max\:pr-0\.5 {
    padding-right: .125rem;
  }

  .lg-max\:pr-1\.5 {
    padding-right: .375rem;
  }

  .lg-max\:pr-2\.5 {
    padding-right: .625rem;
  }

  .lg-max\:pr-3\.5 {
    padding-right: .875rem;
  }

  .lg-max\:pb-0 {
    padding-bottom: 0;
  }

  .lg-max\:pb-1 {
    padding-bottom: .25rem;
  }

  .lg-max\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg-max\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg-max\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg-max\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg-max\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg-max\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg-max\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg-max\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .lg-max\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg-max\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .lg-max\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg-max\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg-max\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg-max\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg-max\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg-max\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg-max\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg-max\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg-max\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg-max\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg-max\:pb-48 {
    padding-bottom: 12rem;
  }

  .lg-max\:pb-52 {
    padding-bottom: 13rem;
  }

  .lg-max\:pb-56 {
    padding-bottom: 14rem;
  }

  .lg-max\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg-max\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg-max\:pb-72 {
    padding-bottom: 18rem;
  }

  .lg-max\:pb-80 {
    padding-bottom: 20rem;
  }

  .lg-max\:pb-96 {
    padding-bottom: 24rem;
  }

  .lg-max\:pb-px {
    padding-bottom: 1px;
  }

  .lg-max\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .lg-max\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .lg-max\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .lg-max\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .lg-max\:pl-0 {
    padding-left: 0;
  }

  .lg-max\:pl-1 {
    padding-left: .25rem;
  }

  .lg-max\:pl-2 {
    padding-left: .5rem;
  }

  .lg-max\:pl-3 {
    padding-left: .75rem;
  }

  .lg-max\:pl-4 {
    padding-left: 1rem;
  }

  .lg-max\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg-max\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg-max\:pl-7 {
    padding-left: 1.75rem;
  }

  .lg-max\:pl-8 {
    padding-left: 2rem;
  }

  .lg-max\:pl-9 {
    padding-left: 2.25rem;
  }

  .lg-max\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg-max\:pl-11 {
    padding-left: 2.75rem;
  }

  .lg-max\:pl-12 {
    padding-left: 3rem;
  }

  .lg-max\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg-max\:pl-16 {
    padding-left: 4rem;
  }

  .lg-max\:pl-20 {
    padding-left: 5rem;
  }

  .lg-max\:pl-24 {
    padding-left: 6rem;
  }

  .lg-max\:pl-28 {
    padding-left: 7rem;
  }

  .lg-max\:pl-32 {
    padding-left: 8rem;
  }

  .lg-max\:pl-36 {
    padding-left: 9rem;
  }

  .lg-max\:pl-40 {
    padding-left: 10rem;
  }

  .lg-max\:pl-44 {
    padding-left: 11rem;
  }

  .lg-max\:pl-48 {
    padding-left: 12rem;
  }

  .lg-max\:pl-52 {
    padding-left: 13rem;
  }

  .lg-max\:pl-56 {
    padding-left: 14rem;
  }

  .lg-max\:pl-60 {
    padding-left: 15rem;
  }

  .lg-max\:pl-64 {
    padding-left: 16rem;
  }

  .lg-max\:pl-72 {
    padding-left: 18rem;
  }

  .lg-max\:pl-80 {
    padding-left: 20rem;
  }

  .lg-max\:pl-96 {
    padding-left: 24rem;
  }

  .lg-max\:pl-px {
    padding-left: 1px;
  }

  .lg-max\:pl-0\.5 {
    padding-left: .125rem;
  }

  .lg-max\:pl-1\.5 {
    padding-left: .375rem;
  }

  .lg-max\:pl-2\.5 {
    padding-left: .625rem;
  }

  .lg-max\:pl-3\.5 {
    padding-left: .875rem;
  }

  .lg-max\:text-left {
    text-align: left;
  }

  .lg-max\:text-center {
    text-align: center;
  }

  .lg-max\:text-right {
    text-align: right;
  }

  .lg-max\:text-justify {
    text-align: justify;
  }

  .lg-max\:align-baseline {
    vertical-align: baseline;
  }

  .lg-max\:align-top {
    vertical-align: top;
  }

  .lg-max\:align-middle {
    vertical-align: middle;
  }

  .lg-max\:align-bottom {
    vertical-align: bottom;
  }

  .lg-max\:align-text-top {
    vertical-align: text-top;
  }

  .lg-max\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg-max\:font-body {
    font-family: Montserrat;
  }

  .lg-max\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .lg-max\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg-max\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg-max\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg-max\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg-max\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg-max\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg-max\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg-max\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg-max\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg-max\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg-max\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg-max\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .lg-max\:font-thin {
    font-weight: 100;
  }

  .lg-max\:font-extralight {
    font-weight: 200;
  }

  .lg-max\:font-light {
    font-weight: 300;
  }

  .lg-max\:font-normal {
    font-weight: 400;
  }

  .lg-max\:font-medium {
    font-weight: 500;
  }

  .lg-max\:font-semibold {
    font-weight: 600;
  }

  .lg-max\:font-bold {
    font-weight: 700;
  }

  .lg-max\:font-extrabold {
    font-weight: 800;
  }

  .lg-max\:font-black {
    font-weight: 900;
  }

  .lg-max\:uppercase {
    text-transform: uppercase;
  }

  .lg-max\:lowercase {
    text-transform: lowercase;
  }

  .lg-max\:capitalize {
    text-transform: capitalize;
  }

  .lg-max\:normal-case {
    text-transform: none;
  }

  .lg-max\:italic {
    font-style: italic;
  }

  .lg-max\:not-italic {
    font-style: normal;
  }

  .lg-max\:ordinal, .lg-max\:slashed-zero, .lg-max\:lining-nums, .lg-max\:oldstyle-nums, .lg-max\:proportional-nums, .lg-max\:tabular-nums, .lg-max\:diagonal-fractions, .lg-max\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg-max\:normal-nums {
    font-variant-numeric: normal;
  }

  .lg-max\:ordinal {
    --tw-ordinal: ordinal;
  }

  .lg-max\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .lg-max\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .lg-max\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .lg-max\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .lg-max\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .lg-max\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .lg-max\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .lg-max\:leading-3 {
    line-height: .75rem;
  }

  .lg-max\:leading-4 {
    line-height: 1rem;
  }

  .lg-max\:leading-5 {
    line-height: 1.25rem;
  }

  .lg-max\:leading-6 {
    line-height: 1.5rem;
  }

  .lg-max\:leading-7 {
    line-height: 1.75rem;
  }

  .lg-max\:leading-8 {
    line-height: 2rem;
  }

  .lg-max\:leading-9 {
    line-height: 2.25rem;
  }

  .lg-max\:leading-10 {
    line-height: 2.5rem;
  }

  .lg-max\:leading-none {
    line-height: 1;
  }

  .lg-max\:leading-tight {
    line-height: 1.25;
  }

  .lg-max\:leading-snug {
    line-height: 1.375;
  }

  .lg-max\:leading-normal {
    line-height: 1.5;
  }

  .lg-max\:leading-relaxed {
    line-height: 1.625;
  }

  .lg-max\:leading-loose {
    line-height: 2;
  }

  .lg-max\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .lg-max\:tracking-tight {
    letter-spacing: -.025em;
  }

  .lg-max\:tracking-normal {
    letter-spacing: 0;
  }

  .lg-max\:tracking-wide {
    letter-spacing: .025em;
  }

  .lg-max\:tracking-wider {
    letter-spacing: .05em;
  }

  .lg-max\:tracking-widest {
    letter-spacing: .1em;
  }

  .lg-max\:text-transparent {
    color: #0000;
  }

  .lg-max\:text-current {
    color: currentColor;
  }

  .lg-max\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg-max\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg-max\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg-max\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg-max\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg-max\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg-max\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg-max\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg-max\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg-max\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg-max\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg-max\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg-max\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg-max\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg-max\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg-max\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg-max\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg-max\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .lg-max\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .lg-max\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .lg-max\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .lg-max\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .lg-max\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg-max\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-transparent:hover {
    color: #0000;
  }

  .lg-max\:hover\:text-current:hover {
    color: currentColor;
  }

  .lg-max\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg-max\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-transparent:focus {
    color: #0000;
  }

  .lg-max\:focus\:text-current:focus {
    color: currentColor;
  }

  .lg-max\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .lg-max\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .lg-max\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .lg-max\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .lg-max\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .lg-max\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .lg-max\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .lg-max\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .lg-max\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .lg-max\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .lg-max\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .lg-max\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .lg-max\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .lg-max\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .lg-max\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .lg-max\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .lg-max\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .lg-max\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .lg-max\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .lg-max\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .lg-max\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .lg-max\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .lg-max\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .lg-max\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .lg-max\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .lg-max\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .lg-max\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .lg-max\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .lg-max\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .lg-max\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .lg-max\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .lg-max\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .lg-max\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .lg-max\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .lg-max\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .lg-max\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .lg-max\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .lg-max\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .lg-max\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .lg-max\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .lg-max\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .lg-max\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .lg-max\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .lg-max\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .lg-max\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .lg-max\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .lg-max\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .lg-max\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .lg-max\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .lg-max\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .lg-max\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .lg-max\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .lg-max\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .lg-max\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .lg-max\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .lg-max\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .lg-max\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .lg-max\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .lg-max\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .lg-max\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .lg-max\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .lg-max\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .lg-max\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .lg-max\:underline {
    text-decoration: underline;
  }

  .lg-max\:line-through {
    text-decoration: line-through;
  }

  .lg-max\:no-underline {
    text-decoration: none;
  }

  .group:hover .lg-max\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .lg-max\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .lg-max\:group-hover\:no-underline {
    text-decoration: none;
  }

  .lg-max\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .lg-max\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .lg-max\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .lg-max\:hover\:underline:hover {
    text-decoration: underline;
  }

  .lg-max\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .lg-max\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .lg-max\:focus\:underline:focus {
    text-decoration: underline;
  }

  .lg-max\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .lg-max\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .lg-max\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg-max\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg-max\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .lg-max\:placeholder-current::placeholder {
    color: currentColor;
  }

  .lg-max\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .lg-max\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .lg-max\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .lg-max\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .lg-max\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg-max\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .lg-max\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .lg-max\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .lg-max\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .lg-max\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .lg-max\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .lg-max\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .lg-max\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .lg-max\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .lg-max\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .lg-max\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .lg-max\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .lg-max\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .lg-max\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg-max\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg-max\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .lg-max\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .lg-max\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .lg-max\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .lg-max\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .lg-max\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .lg-max\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .lg-max\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .lg-max\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .lg-max\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .lg-max\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .lg-max\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .lg-max\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .lg-max\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg-max\:opacity-0 {
    opacity: 0;
  }

  .lg-max\:opacity-5 {
    opacity: .05;
  }

  .lg-max\:opacity-10 {
    opacity: .1;
  }

  .lg-max\:opacity-20 {
    opacity: .2;
  }

  .lg-max\:opacity-25 {
    opacity: .25;
  }

  .lg-max\:opacity-30 {
    opacity: .3;
  }

  .lg-max\:opacity-40 {
    opacity: .4;
  }

  .lg-max\:opacity-50 {
    opacity: .5;
  }

  .lg-max\:opacity-60 {
    opacity: .6;
  }

  .lg-max\:opacity-70 {
    opacity: .7;
  }

  .lg-max\:opacity-75 {
    opacity: .75;
  }

  .lg-max\:opacity-80 {
    opacity: .8;
  }

  .lg-max\:opacity-90 {
    opacity: .9;
  }

  .lg-max\:opacity-95 {
    opacity: .95;
  }

  .lg-max\:opacity-100 {
    opacity: 1;
  }

  .group:hover .lg-max\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .lg-max\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .lg-max\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .lg-max\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .lg-max\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .lg-max\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .lg-max\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .lg-max\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .lg-max\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .lg-max\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .lg-max\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .lg-max\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .lg-max\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .lg-max\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .lg-max\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .lg-max\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .lg-max\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .lg-max\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .lg-max\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .lg-max\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .lg-max\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .lg-max\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .lg-max\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .lg-max\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .lg-max\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .lg-max\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .lg-max\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .lg-max\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .lg-max\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .lg-max\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .lg-max\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .lg-max\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .lg-max\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .lg-max\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .lg-max\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .lg-max\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .lg-max\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .lg-max\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .lg-max\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .lg-max\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .lg-max\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .lg-max\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .lg-max\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .lg-max\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .lg-max\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .lg-max\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .lg-max\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .lg-max\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .lg-max\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .lg-max\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .lg-max\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .lg-max\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .lg-max\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .lg-max\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .lg-max\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .lg-max\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .lg-max\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .lg-max\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .lg-max\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .lg-max\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .lg-max\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .lg-max\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .lg-max\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .lg-max\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .lg-max\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .lg-max\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .lg-max\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .lg-max\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .lg-max\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .lg-max\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .lg-max\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .lg-max\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .lg-max\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .lg-max\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .lg-max\:bg-blend-color {
    background-blend-mode: color;
  }

  .lg-max\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .lg-max\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .lg-max\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .lg-max\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .lg-max\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .lg-max\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .lg-max\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .lg-max\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .lg-max\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .lg-max\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .lg-max\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .lg-max\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .lg-max\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .lg-max\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .lg-max\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .lg-max\:mix-blend-color {
    mix-blend-mode: color;
  }

  .lg-max\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .lg-max\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg-max\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg-max\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg-max\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg-max\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg-max\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg-max\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg-max\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg-max\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg-max\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg-max\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg-max\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg-max\:ring-inset, .lg-max\:focus-within\:ring-inset:focus-within, .lg-max\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .lg-max\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .lg-max\:ring-current {
    --tw-ring-color: currentColor;
  }

  .lg-max\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg-max\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg-max\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg-max\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg-max\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg-max\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg-max\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg-max\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg-max\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg-max\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .lg-max\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .lg-max\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg-max\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .lg-max\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .lg-max\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .lg-max\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .lg-max\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .lg-max\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .lg-max\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .lg-max\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .lg-max\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .lg-max\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .lg-max\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .lg-max\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .lg-max\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .lg-max\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .lg-max\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .lg-max\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .lg-max\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .lg-max\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .lg-max\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .lg-max\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .lg-max\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .lg-max\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .lg-max\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .lg-max\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .lg-max\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .lg-max\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .lg-max\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .lg-max\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .lg-max\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .lg-max\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .lg-max\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .lg-max\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .lg-max\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .lg-max\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .lg-max\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .lg-max\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .lg-max\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .lg-max\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .lg-max\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .lg-max\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .lg-max\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .lg-max\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .lg-max\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .lg-max\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .lg-max\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .lg-max\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .lg-max\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .lg-max\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .lg-max\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .lg-max\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .lg-max\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .lg-max\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .lg-max\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .lg-max\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .lg-max\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .lg-max\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .lg-max\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .lg-max\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .lg-max\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .lg-max\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .lg-max\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .lg-max\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .lg-max\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .lg-max\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .lg-max\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .lg-max\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .lg-max\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .lg-max\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .lg-max\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg-max\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg-max\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg-max\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg-max\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg-max\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .lg-max\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .lg-max\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .lg-max\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .lg-max\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .lg-max\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .lg-max\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg-max\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg-max\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .lg-max\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg-max\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg-max\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg-max\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .lg-max\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .lg-max\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .lg-max\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .lg-max\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg-max\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg-max\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg-max\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg-max\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .lg-max\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .lg-max\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .lg-max\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .lg-max\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .lg-max\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .lg-max\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg-max\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg-max\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg-max\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg-max\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg-max\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg-max\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .lg-max\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg-max\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .lg-max\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg-max\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg-max\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg-max\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg-max\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg-max\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .lg-max\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .lg-max\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg-max\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .lg-max\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .lg-max\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .lg-max\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg-max\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg-max\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg-max\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg-max\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg-max\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg-max\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg-max\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg-max\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg-max\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg-max\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg-max\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg-max\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg-max\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg-max\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .lg-max\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .lg-max\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .lg-max\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .lg-max\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .lg-max\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .lg-max\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .lg-max\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .lg-max\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .lg-max\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .lg-max\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg-max\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg-max\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg-max\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg-max\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg-max\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .lg-max\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .lg-max\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .lg-max\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .lg-max\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .lg-max\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .lg-max\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg-max\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg-max\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .lg-max\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg-max\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg-max\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg-max\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .lg-max\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .lg-max\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .lg-max\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .lg-max\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg-max\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg-max\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg-max\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg-max\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .lg-max\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .lg-max\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .lg-max\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .lg-max\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .lg-max\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .lg-max\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg-max\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg-max\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg-max\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg-max\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg-max\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg-max\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .lg-max\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg-max\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .lg-max\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg-max\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg-max\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg-max\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg-max\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg-max\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .lg-max\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .lg-max\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg-max\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .lg-max\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .lg-max\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .lg-max\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg-max\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg-max\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg-max\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg-max\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg-max\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg-max\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg-max\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg-max\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg-max\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg-max\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg-max\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg-max\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg-max\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg-max\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .lg-max\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .lg-max\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .lg-max\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .lg-max\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .lg-max\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .lg-max\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .lg-max\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .lg-max\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .lg-max\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .lg-max\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .lg-max\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .lg-max\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .lg-max\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .lg-max\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .lg-max\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .lg-max\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .lg-max\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .lg-max\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .lg-max\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .lg-max\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .lg-max\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .lg-max\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .lg-max\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .lg-max\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .lg-max\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .lg-max\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .lg-max\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .lg-max\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .lg-max\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .lg-max\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .lg-max\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .lg-max\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .lg-max\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .lg-max\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .lg-max\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .lg-max\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .lg-max\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .lg-max\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .lg-max\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .lg-max\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .lg-max\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .lg-max\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .lg-max\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .lg-max\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .lg-max\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .lg-max\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .lg-max\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .lg-max\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .lg-max\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .lg-max\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .lg-max\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .lg-max\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .lg-max\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .lg-max\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .lg-max\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .lg-max\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .lg-max\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .lg-max\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .lg-max\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .lg-max\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .lg-max\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .lg-max\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .lg-max\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .lg-max\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .lg-max\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .lg-max\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .lg-max\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .lg-max\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .lg-max\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .lg-max\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .lg-max\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .lg-max\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .lg-max\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .lg-max\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .lg-max\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .lg-max\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .lg-max\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .lg-max\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .lg-max\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .lg-max\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .lg-max\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg-max\:filter-none {
    filter: none;
  }

  .lg-max\:blur-0, .lg-max\:blur-none {
    --tw-blur: blur(0);
  }

  .lg-max\:blur-sm {
    --tw-blur: blur(4px);
  }

  .lg-max\:blur {
    --tw-blur: blur(8px);
  }

  .lg-max\:blur-md {
    --tw-blur: blur(12px);
  }

  .lg-max\:blur-lg {
    --tw-blur: blur(16px);
  }

  .lg-max\:blur-xl {
    --tw-blur: blur(24px);
  }

  .lg-max\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .lg-max\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .lg-max\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .lg-max\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .lg-max\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .lg-max\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .lg-max\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .lg-max\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .lg-max\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .lg-max\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .lg-max\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .lg-max\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .lg-max\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .lg-max\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .lg-max\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .lg-max\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .lg-max\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .lg-max\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .lg-max\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .lg-max\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .lg-max\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .lg-max\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .lg-max\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .lg-max\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .lg-max\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .lg-max\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .lg-max\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .lg-max\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .lg-max\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .lg-max\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .lg-max\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .lg-max\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .lg-max\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .lg-max\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .lg-max\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .lg-max\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .lg-max\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .lg-max\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .lg-max\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .lg-max\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .lg-max\:invert-0 {
    --tw-invert: invert(0);
  }

  .lg-max\:invert {
    --tw-invert: invert(100%);
  }

  .lg-max\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .lg-max\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .lg-max\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .lg-max\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .lg-max\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .lg-max\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .lg-max\:sepia {
    --tw-sepia: sepia(100%);
  }

  .lg-max\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg-max\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .lg-max\:backdrop-blur-0, .lg-max\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .lg-max\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .lg-max\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .lg-max\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .lg-max\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .lg-max\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .lg-max\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .lg-max\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .lg-max\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .lg-max\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .lg-max\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .lg-max\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .lg-max\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .lg-max\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .lg-max\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .lg-max\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .lg-max\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .lg-max\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .lg-max\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .lg-max\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .lg-max\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .lg-max\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .lg-max\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .lg-max\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .lg-max\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .lg-max\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .lg-max\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .lg-max\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .lg-max\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .lg-max\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .lg-max\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .lg-max\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .lg-max\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .lg-max\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .lg-max\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .lg-max\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .lg-max\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .lg-max\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .lg-max\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .lg-max\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .lg-max\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .lg-max\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .lg-max\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .lg-max\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .lg-max\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .lg-max\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .lg-max\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .lg-max\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .lg-max\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .lg-max\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .lg-max\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .lg-max\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .lg-max\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .lg-max\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .lg-max\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .lg-max\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .lg-max\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .lg-max\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .lg-max\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .lg-max\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .lg-max\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .lg-max\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .lg-max\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .lg-max\:transition-none {
    transition-property: none;
  }

  .lg-max\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg-max\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg-max\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg-max\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg-max\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg-max\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg-max\:delay-75 {
    transition-delay: 75ms;
  }

  .lg-max\:delay-100 {
    transition-delay: .1s;
  }

  .lg-max\:delay-150 {
    transition-delay: .15s;
  }

  .lg-max\:delay-200 {
    transition-delay: .2s;
  }

  .lg-max\:delay-300 {
    transition-delay: .3s;
  }

  .lg-max\:delay-500 {
    transition-delay: .5s;
  }

  .lg-max\:delay-700 {
    transition-delay: .7s;
  }

  .lg-max\:delay-1000 {
    transition-delay: 1s;
  }

  .lg-max\:duration-75 {
    transition-duration: 75ms;
  }

  .lg-max\:duration-100 {
    transition-duration: .1s;
  }

  .lg-max\:duration-150 {
    transition-duration: .15s;
  }

  .lg-max\:duration-200 {
    transition-duration: .2s;
  }

  .lg-max\:duration-300 {
    transition-duration: .3s;
  }

  .lg-max\:duration-500 {
    transition-duration: .5s;
  }

  .lg-max\:duration-700 {
    transition-duration: .7s;
  }

  .lg-max\:duration-1000 {
    transition-duration: 1s;
  }

  .lg-max\:ease-linear {
    transition-timing-function: linear;
  }

  .lg-max\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .lg-max\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .lg-max\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .xl\:container {
      max-width: 1560px;
    }
  }

  .xl\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xl\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xl\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xl\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xl\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xl\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xl\:pointer-events-none {
    pointer-events: none;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:visible {
    visibility: visible;
  }

  .xl\:invisible {
    visibility: hidden;
  }

  .xl\:static {
    position: static;
  }

  .xl\:fixed {
    position: fixed;
  }

  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:sticky {
    position: sticky;
  }

  .xl\:inset-0 {
    inset: 0;
  }

  .xl\:inset-1 {
    inset: .25rem;
  }

  .xl\:inset-2 {
    inset: .5rem;
  }

  .xl\:inset-3 {
    inset: .75rem;
  }

  .xl\:inset-4 {
    inset: 1rem;
  }

  .xl\:inset-5 {
    inset: 1.25rem;
  }

  .xl\:inset-6 {
    inset: 1.5rem;
  }

  .xl\:inset-7 {
    inset: 1.75rem;
  }

  .xl\:inset-8 {
    inset: 2rem;
  }

  .xl\:inset-9 {
    inset: 2.25rem;
  }

  .xl\:inset-10 {
    inset: 2.5rem;
  }

  .xl\:inset-11 {
    inset: 2.75rem;
  }

  .xl\:inset-12 {
    inset: 3rem;
  }

  .xl\:inset-14 {
    inset: 3.5rem;
  }

  .xl\:inset-16 {
    inset: 4rem;
  }

  .xl\:inset-20 {
    inset: 5rem;
  }

  .xl\:inset-24 {
    inset: 6rem;
  }

  .xl\:inset-28 {
    inset: 7rem;
  }

  .xl\:inset-32 {
    inset: 8rem;
  }

  .xl\:inset-36 {
    inset: 9rem;
  }

  .xl\:inset-40 {
    inset: 10rem;
  }

  .xl\:inset-44 {
    inset: 11rem;
  }

  .xl\:inset-48 {
    inset: 12rem;
  }

  .xl\:inset-52 {
    inset: 13rem;
  }

  .xl\:inset-56 {
    inset: 14rem;
  }

  .xl\:inset-60 {
    inset: 15rem;
  }

  .xl\:inset-64 {
    inset: 16rem;
  }

  .xl\:inset-72 {
    inset: 18rem;
  }

  .xl\:inset-80 {
    inset: 20rem;
  }

  .xl\:inset-96 {
    inset: 24rem;
  }

  .xl\:inset-auto {
    inset: auto;
  }

  .xl\:inset-px {
    inset: 1px;
  }

  .xl\:inset-0\.5 {
    inset: .125rem;
  }

  .xl\:inset-1\.5 {
    inset: .375rem;
  }

  .xl\:inset-2\.5 {
    inset: .625rem;
  }

  .xl\:inset-3\.5 {
    inset: .875rem;
  }

  .xl\:-inset-0 {
    inset: 0;
  }

  .xl\:-inset-1 {
    inset: -.25rem;
  }

  .xl\:-inset-2 {
    inset: -.5rem;
  }

  .xl\:-inset-3 {
    inset: -.75rem;
  }

  .xl\:-inset-4 {
    inset: -1rem;
  }

  .xl\:-inset-5 {
    inset: -1.25rem;
  }

  .xl\:-inset-6 {
    inset: -1.5rem;
  }

  .xl\:-inset-7 {
    inset: -1.75rem;
  }

  .xl\:-inset-8 {
    inset: -2rem;
  }

  .xl\:-inset-9 {
    inset: -2.25rem;
  }

  .xl\:-inset-10 {
    inset: -2.5rem;
  }

  .xl\:-inset-11 {
    inset: -2.75rem;
  }

  .xl\:-inset-12 {
    inset: -3rem;
  }

  .xl\:-inset-14 {
    inset: -3.5rem;
  }

  .xl\:-inset-16 {
    inset: -4rem;
  }

  .xl\:-inset-20 {
    inset: -5rem;
  }

  .xl\:-inset-24 {
    inset: -6rem;
  }

  .xl\:-inset-28 {
    inset: -7rem;
  }

  .xl\:-inset-32 {
    inset: -8rem;
  }

  .xl\:-inset-36 {
    inset: -9rem;
  }

  .xl\:-inset-40 {
    inset: -10rem;
  }

  .xl\:-inset-44 {
    inset: -11rem;
  }

  .xl\:-inset-48 {
    inset: -12rem;
  }

  .xl\:-inset-52 {
    inset: -13rem;
  }

  .xl\:-inset-56 {
    inset: -14rem;
  }

  .xl\:-inset-60 {
    inset: -15rem;
  }

  .xl\:-inset-64 {
    inset: -16rem;
  }

  .xl\:-inset-72 {
    inset: -18rem;
  }

  .xl\:-inset-80 {
    inset: -20rem;
  }

  .xl\:-inset-96 {
    inset: -24rem;
  }

  .xl\:-inset-px {
    inset: -1px;
  }

  .xl\:-inset-0\.5 {
    inset: -.125rem;
  }

  .xl\:-inset-1\.5 {
    inset: -.375rem;
  }

  .xl\:-inset-2\.5 {
    inset: -.625rem;
  }

  .xl\:-inset-3\.5 {
    inset: -.875rem;
  }

  .xl\:inset-1\/2 {
    inset: 50%;
  }

  .xl\:inset-1\/3 {
    inset: 33.3333%;
  }

  .xl\:inset-2\/3 {
    inset: 66.6667%;
  }

  .xl\:inset-1\/4 {
    inset: 25%;
  }

  .xl\:inset-2\/4 {
    inset: 50%;
  }

  .xl\:inset-3\/4 {
    inset: 75%;
  }

  .xl\:inset-full {
    inset: 100%;
  }

  .xl\:-inset-1\/2 {
    inset: -50%;
  }

  .xl\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .xl\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .xl\:-inset-1\/4 {
    inset: -25%;
  }

  .xl\:-inset-2\/4 {
    inset: -50%;
  }

  .xl\:-inset-3\/4 {
    inset: -75%;
  }

  .xl\:-inset-full {
    inset: -100%;
  }

  .xl\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .xl\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .xl\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .xl\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .xl\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .xl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .xl\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .xl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .xl\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .xl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .xl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .xl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .xl\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .xl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .xl\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .xl\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .xl\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .xl\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .xl\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .xl\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .xl\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .xl\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .xl\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .xl\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .xl\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .xl\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .xl\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .xl\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .xl\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .xl\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .xl\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .xl\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .xl\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .xl\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .xl\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .xl\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .xl\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .xl\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .xl\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .xl\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .xl\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .xl\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .xl\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .xl\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .xl\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .xl\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .xl\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .xl\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .xl\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .xl\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .xl\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .xl\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .xl\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .xl\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .xl\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .xl\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .xl\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .xl\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .xl\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .xl\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .xl\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .xl\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .xl\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .xl\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .xl\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .xl\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .xl\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .xl\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .xl\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .xl\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .xl\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .xl\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .xl\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .xl\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .xl\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .xl\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .xl\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .xl\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .xl\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .xl\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .xl\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .xl\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .xl\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .xl\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .xl\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .xl\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .xl\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .xl\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .xl\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .xl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .xl\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .xl\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .xl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .xl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .xl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .xl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .xl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .xl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .xl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .xl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .xl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .xl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .xl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .xl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .xl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .xl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .xl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .xl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .xl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .xl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .xl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .xl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .xl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .xl\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .xl\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .xl\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .xl\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .xl\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .xl\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .xl\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .xl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .xl\:top-0 {
    top: 0;
  }

  .xl\:top-1 {
    top: .25rem;
  }

  .xl\:top-2 {
    top: .5rem;
  }

  .xl\:top-3 {
    top: .75rem;
  }

  .xl\:top-4 {
    top: 1rem;
  }

  .xl\:top-5 {
    top: 1.25rem;
  }

  .xl\:top-6 {
    top: 1.5rem;
  }

  .xl\:top-7 {
    top: 1.75rem;
  }

  .xl\:top-8 {
    top: 2rem;
  }

  .xl\:top-9 {
    top: 2.25rem;
  }

  .xl\:top-10 {
    top: 2.5rem;
  }

  .xl\:top-11 {
    top: 2.75rem;
  }

  .xl\:top-12 {
    top: 3rem;
  }

  .xl\:top-14 {
    top: 3.5rem;
  }

  .xl\:top-16 {
    top: 4rem;
  }

  .xl\:top-20 {
    top: 5rem;
  }

  .xl\:top-24 {
    top: 6rem;
  }

  .xl\:top-28 {
    top: 7rem;
  }

  .xl\:top-32 {
    top: 8rem;
  }

  .xl\:top-36 {
    top: 9rem;
  }

  .xl\:top-40 {
    top: 10rem;
  }

  .xl\:top-44 {
    top: 11rem;
  }

  .xl\:top-48 {
    top: 12rem;
  }

  .xl\:top-52 {
    top: 13rem;
  }

  .xl\:top-56 {
    top: 14rem;
  }

  .xl\:top-60 {
    top: 15rem;
  }

  .xl\:top-64 {
    top: 16rem;
  }

  .xl\:top-72 {
    top: 18rem;
  }

  .xl\:top-80 {
    top: 20rem;
  }

  .xl\:top-96 {
    top: 24rem;
  }

  .xl\:top-auto {
    top: auto;
  }

  .xl\:top-px {
    top: 1px;
  }

  .xl\:top-0\.5 {
    top: .125rem;
  }

  .xl\:top-1\.5 {
    top: .375rem;
  }

  .xl\:top-2\.5 {
    top: .625rem;
  }

  .xl\:top-3\.5 {
    top: .875rem;
  }

  .xl\:-top-0 {
    top: 0;
  }

  .xl\:-top-1 {
    top: -.25rem;
  }

  .xl\:-top-2 {
    top: -.5rem;
  }

  .xl\:-top-3 {
    top: -.75rem;
  }

  .xl\:-top-4 {
    top: -1rem;
  }

  .xl\:-top-5 {
    top: -1.25rem;
  }

  .xl\:-top-6 {
    top: -1.5rem;
  }

  .xl\:-top-7 {
    top: -1.75rem;
  }

  .xl\:-top-8 {
    top: -2rem;
  }

  .xl\:-top-9 {
    top: -2.25rem;
  }

  .xl\:-top-10 {
    top: -2.5rem;
  }

  .xl\:-top-11 {
    top: -2.75rem;
  }

  .xl\:-top-12 {
    top: -3rem;
  }

  .xl\:-top-14 {
    top: -3.5rem;
  }

  .xl\:-top-16 {
    top: -4rem;
  }

  .xl\:-top-20 {
    top: -5rem;
  }

  .xl\:-top-24 {
    top: -6rem;
  }

  .xl\:-top-28 {
    top: -7rem;
  }

  .xl\:-top-32 {
    top: -8rem;
  }

  .xl\:-top-36 {
    top: -9rem;
  }

  .xl\:-top-40 {
    top: -10rem;
  }

  .xl\:-top-44 {
    top: -11rem;
  }

  .xl\:-top-48 {
    top: -12rem;
  }

  .xl\:-top-52 {
    top: -13rem;
  }

  .xl\:-top-56 {
    top: -14rem;
  }

  .xl\:-top-60 {
    top: -15rem;
  }

  .xl\:-top-64 {
    top: -16rem;
  }

  .xl\:-top-72 {
    top: -18rem;
  }

  .xl\:-top-80 {
    top: -20rem;
  }

  .xl\:-top-96 {
    top: -24rem;
  }

  .xl\:-top-px {
    top: -1px;
  }

  .xl\:-top-0\.5 {
    top: -.125rem;
  }

  .xl\:-top-1\.5 {
    top: -.375rem;
  }

  .xl\:-top-2\.5 {
    top: -.625rem;
  }

  .xl\:-top-3\.5 {
    top: -.875rem;
  }

  .xl\:top-1\/2 {
    top: 50%;
  }

  .xl\:top-1\/3 {
    top: 33.3333%;
  }

  .xl\:top-2\/3 {
    top: 66.6667%;
  }

  .xl\:top-1\/4 {
    top: 25%;
  }

  .xl\:top-2\/4 {
    top: 50%;
  }

  .xl\:top-3\/4 {
    top: 75%;
  }

  .xl\:top-full {
    top: 100%;
  }

  .xl\:-top-1\/2 {
    top: -50%;
  }

  .xl\:-top-1\/3 {
    top: -33.3333%;
  }

  .xl\:-top-2\/3 {
    top: -66.6667%;
  }

  .xl\:-top-1\/4 {
    top: -25%;
  }

  .xl\:-top-2\/4 {
    top: -50%;
  }

  .xl\:-top-3\/4 {
    top: -75%;
  }

  .xl\:-top-full {
    top: -100%;
  }

  .xl\:right-0 {
    right: 0;
  }

  .xl\:right-1 {
    right: .25rem;
  }

  .xl\:right-2 {
    right: .5rem;
  }

  .xl\:right-3 {
    right: .75rem;
  }

  .xl\:right-4 {
    right: 1rem;
  }

  .xl\:right-5 {
    right: 1.25rem;
  }

  .xl\:right-6 {
    right: 1.5rem;
  }

  .xl\:right-7 {
    right: 1.75rem;
  }

  .xl\:right-8 {
    right: 2rem;
  }

  .xl\:right-9 {
    right: 2.25rem;
  }

  .xl\:right-10 {
    right: 2.5rem;
  }

  .xl\:right-11 {
    right: 2.75rem;
  }

  .xl\:right-12 {
    right: 3rem;
  }

  .xl\:right-14 {
    right: 3.5rem;
  }

  .xl\:right-16 {
    right: 4rem;
  }

  .xl\:right-20 {
    right: 5rem;
  }

  .xl\:right-24 {
    right: 6rem;
  }

  .xl\:right-28 {
    right: 7rem;
  }

  .xl\:right-32 {
    right: 8rem;
  }

  .xl\:right-36 {
    right: 9rem;
  }

  .xl\:right-40 {
    right: 10rem;
  }

  .xl\:right-44 {
    right: 11rem;
  }

  .xl\:right-48 {
    right: 12rem;
  }

  .xl\:right-52 {
    right: 13rem;
  }

  .xl\:right-56 {
    right: 14rem;
  }

  .xl\:right-60 {
    right: 15rem;
  }

  .xl\:right-64 {
    right: 16rem;
  }

  .xl\:right-72 {
    right: 18rem;
  }

  .xl\:right-80 {
    right: 20rem;
  }

  .xl\:right-96 {
    right: 24rem;
  }

  .xl\:right-auto {
    right: auto;
  }

  .xl\:right-px {
    right: 1px;
  }

  .xl\:right-0\.5 {
    right: .125rem;
  }

  .xl\:right-1\.5 {
    right: .375rem;
  }

  .xl\:right-2\.5 {
    right: .625rem;
  }

  .xl\:right-3\.5 {
    right: .875rem;
  }

  .xl\:-right-0 {
    right: 0;
  }

  .xl\:-right-1 {
    right: -.25rem;
  }

  .xl\:-right-2 {
    right: -.5rem;
  }

  .xl\:-right-3 {
    right: -.75rem;
  }

  .xl\:-right-4 {
    right: -1rem;
  }

  .xl\:-right-5 {
    right: -1.25rem;
  }

  .xl\:-right-6 {
    right: -1.5rem;
  }

  .xl\:-right-7 {
    right: -1.75rem;
  }

  .xl\:-right-8 {
    right: -2rem;
  }

  .xl\:-right-9 {
    right: -2.25rem;
  }

  .xl\:-right-10 {
    right: -2.5rem;
  }

  .xl\:-right-11 {
    right: -2.75rem;
  }

  .xl\:-right-12 {
    right: -3rem;
  }

  .xl\:-right-14 {
    right: -3.5rem;
  }

  .xl\:-right-16 {
    right: -4rem;
  }

  .xl\:-right-20 {
    right: -5rem;
  }

  .xl\:-right-24 {
    right: -6rem;
  }

  .xl\:-right-28 {
    right: -7rem;
  }

  .xl\:-right-32 {
    right: -8rem;
  }

  .xl\:-right-36 {
    right: -9rem;
  }

  .xl\:-right-40 {
    right: -10rem;
  }

  .xl\:-right-44 {
    right: -11rem;
  }

  .xl\:-right-48 {
    right: -12rem;
  }

  .xl\:-right-52 {
    right: -13rem;
  }

  .xl\:-right-56 {
    right: -14rem;
  }

  .xl\:-right-60 {
    right: -15rem;
  }

  .xl\:-right-64 {
    right: -16rem;
  }

  .xl\:-right-72 {
    right: -18rem;
  }

  .xl\:-right-80 {
    right: -20rem;
  }

  .xl\:-right-96 {
    right: -24rem;
  }

  .xl\:-right-px {
    right: -1px;
  }

  .xl\:-right-0\.5 {
    right: -.125rem;
  }

  .xl\:-right-1\.5 {
    right: -.375rem;
  }

  .xl\:-right-2\.5 {
    right: -.625rem;
  }

  .xl\:-right-3\.5 {
    right: -.875rem;
  }

  .xl\:right-1\/2 {
    right: 50%;
  }

  .xl\:right-1\/3 {
    right: 33.3333%;
  }

  .xl\:right-2\/3 {
    right: 66.6667%;
  }

  .xl\:right-1\/4 {
    right: 25%;
  }

  .xl\:right-2\/4 {
    right: 50%;
  }

  .xl\:right-3\/4 {
    right: 75%;
  }

  .xl\:right-full {
    right: 100%;
  }

  .xl\:-right-1\/2 {
    right: -50%;
  }

  .xl\:-right-1\/3 {
    right: -33.3333%;
  }

  .xl\:-right-2\/3 {
    right: -66.6667%;
  }

  .xl\:-right-1\/4 {
    right: -25%;
  }

  .xl\:-right-2\/4 {
    right: -50%;
  }

  .xl\:-right-3\/4 {
    right: -75%;
  }

  .xl\:-right-full {
    right: -100%;
  }

  .xl\:bottom-0 {
    bottom: 0;
  }

  .xl\:bottom-1 {
    bottom: .25rem;
  }

  .xl\:bottom-2 {
    bottom: .5rem;
  }

  .xl\:bottom-3 {
    bottom: .75rem;
  }

  .xl\:bottom-4 {
    bottom: 1rem;
  }

  .xl\:bottom-5 {
    bottom: 1.25rem;
  }

  .xl\:bottom-6 {
    bottom: 1.5rem;
  }

  .xl\:bottom-7 {
    bottom: 1.75rem;
  }

  .xl\:bottom-8 {
    bottom: 2rem;
  }

  .xl\:bottom-9 {
    bottom: 2.25rem;
  }

  .xl\:bottom-10 {
    bottom: 2.5rem;
  }

  .xl\:bottom-11 {
    bottom: 2.75rem;
  }

  .xl\:bottom-12 {
    bottom: 3rem;
  }

  .xl\:bottom-14 {
    bottom: 3.5rem;
  }

  .xl\:bottom-16 {
    bottom: 4rem;
  }

  .xl\:bottom-20 {
    bottom: 5rem;
  }

  .xl\:bottom-24 {
    bottom: 6rem;
  }

  .xl\:bottom-28 {
    bottom: 7rem;
  }

  .xl\:bottom-32 {
    bottom: 8rem;
  }

  .xl\:bottom-36 {
    bottom: 9rem;
  }

  .xl\:bottom-40 {
    bottom: 10rem;
  }

  .xl\:bottom-44 {
    bottom: 11rem;
  }

  .xl\:bottom-48 {
    bottom: 12rem;
  }

  .xl\:bottom-52 {
    bottom: 13rem;
  }

  .xl\:bottom-56 {
    bottom: 14rem;
  }

  .xl\:bottom-60 {
    bottom: 15rem;
  }

  .xl\:bottom-64 {
    bottom: 16rem;
  }

  .xl\:bottom-72 {
    bottom: 18rem;
  }

  .xl\:bottom-80 {
    bottom: 20rem;
  }

  .xl\:bottom-96 {
    bottom: 24rem;
  }

  .xl\:bottom-auto {
    bottom: auto;
  }

  .xl\:bottom-px {
    bottom: 1px;
  }

  .xl\:bottom-0\.5 {
    bottom: .125rem;
  }

  .xl\:bottom-1\.5 {
    bottom: .375rem;
  }

  .xl\:bottom-2\.5 {
    bottom: .625rem;
  }

  .xl\:bottom-3\.5 {
    bottom: .875rem;
  }

  .xl\:-bottom-0 {
    bottom: 0;
  }

  .xl\:-bottom-1 {
    bottom: -.25rem;
  }

  .xl\:-bottom-2 {
    bottom: -.5rem;
  }

  .xl\:-bottom-3 {
    bottom: -.75rem;
  }

  .xl\:-bottom-4 {
    bottom: -1rem;
  }

  .xl\:-bottom-5 {
    bottom: -1.25rem;
  }

  .xl\:-bottom-6 {
    bottom: -1.5rem;
  }

  .xl\:-bottom-7 {
    bottom: -1.75rem;
  }

  .xl\:-bottom-8 {
    bottom: -2rem;
  }

  .xl\:-bottom-9 {
    bottom: -2.25rem;
  }

  .xl\:-bottom-10 {
    bottom: -2.5rem;
  }

  .xl\:-bottom-11 {
    bottom: -2.75rem;
  }

  .xl\:-bottom-12 {
    bottom: -3rem;
  }

  .xl\:-bottom-14 {
    bottom: -3.5rem;
  }

  .xl\:-bottom-16 {
    bottom: -4rem;
  }

  .xl\:-bottom-20 {
    bottom: -5rem;
  }

  .xl\:-bottom-24 {
    bottom: -6rem;
  }

  .xl\:-bottom-28 {
    bottom: -7rem;
  }

  .xl\:-bottom-32 {
    bottom: -8rem;
  }

  .xl\:-bottom-36 {
    bottom: -9rem;
  }

  .xl\:-bottom-40 {
    bottom: -10rem;
  }

  .xl\:-bottom-44 {
    bottom: -11rem;
  }

  .xl\:-bottom-48 {
    bottom: -12rem;
  }

  .xl\:-bottom-52 {
    bottom: -13rem;
  }

  .xl\:-bottom-56 {
    bottom: -14rem;
  }

  .xl\:-bottom-60 {
    bottom: -15rem;
  }

  .xl\:-bottom-64 {
    bottom: -16rem;
  }

  .xl\:-bottom-72 {
    bottom: -18rem;
  }

  .xl\:-bottom-80 {
    bottom: -20rem;
  }

  .xl\:-bottom-96 {
    bottom: -24rem;
  }

  .xl\:-bottom-px {
    bottom: -1px;
  }

  .xl\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .xl\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .xl\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .xl\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .xl\:bottom-1\/2 {
    bottom: 50%;
  }

  .xl\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .xl\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .xl\:bottom-1\/4 {
    bottom: 25%;
  }

  .xl\:bottom-2\/4 {
    bottom: 50%;
  }

  .xl\:bottom-3\/4 {
    bottom: 75%;
  }

  .xl\:bottom-full {
    bottom: 100%;
  }

  .xl\:-bottom-1\/2 {
    bottom: -50%;
  }

  .xl\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .xl\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .xl\:-bottom-1\/4 {
    bottom: -25%;
  }

  .xl\:-bottom-2\/4 {
    bottom: -50%;
  }

  .xl\:-bottom-3\/4 {
    bottom: -75%;
  }

  .xl\:-bottom-full {
    bottom: -100%;
  }

  .xl\:left-0 {
    left: 0;
  }

  .xl\:left-1 {
    left: .25rem;
  }

  .xl\:left-2 {
    left: .5rem;
  }

  .xl\:left-3 {
    left: .75rem;
  }

  .xl\:left-4 {
    left: 1rem;
  }

  .xl\:left-5 {
    left: 1.25rem;
  }

  .xl\:left-6 {
    left: 1.5rem;
  }

  .xl\:left-7 {
    left: 1.75rem;
  }

  .xl\:left-8 {
    left: 2rem;
  }

  .xl\:left-9 {
    left: 2.25rem;
  }

  .xl\:left-10 {
    left: 2.5rem;
  }

  .xl\:left-11 {
    left: 2.75rem;
  }

  .xl\:left-12 {
    left: 3rem;
  }

  .xl\:left-14 {
    left: 3.5rem;
  }

  .xl\:left-16 {
    left: 4rem;
  }

  .xl\:left-20 {
    left: 5rem;
  }

  .xl\:left-24 {
    left: 6rem;
  }

  .xl\:left-28 {
    left: 7rem;
  }

  .xl\:left-32 {
    left: 8rem;
  }

  .xl\:left-36 {
    left: 9rem;
  }

  .xl\:left-40 {
    left: 10rem;
  }

  .xl\:left-44 {
    left: 11rem;
  }

  .xl\:left-48 {
    left: 12rem;
  }

  .xl\:left-52 {
    left: 13rem;
  }

  .xl\:left-56 {
    left: 14rem;
  }

  .xl\:left-60 {
    left: 15rem;
  }

  .xl\:left-64 {
    left: 16rem;
  }

  .xl\:left-72 {
    left: 18rem;
  }

  .xl\:left-80 {
    left: 20rem;
  }

  .xl\:left-96 {
    left: 24rem;
  }

  .xl\:left-auto {
    left: auto;
  }

  .xl\:left-px {
    left: 1px;
  }

  .xl\:left-0\.5 {
    left: .125rem;
  }

  .xl\:left-1\.5 {
    left: .375rem;
  }

  .xl\:left-2\.5 {
    left: .625rem;
  }

  .xl\:left-3\.5 {
    left: .875rem;
  }

  .xl\:-left-0 {
    left: 0;
  }

  .xl\:-left-1 {
    left: -.25rem;
  }

  .xl\:-left-2 {
    left: -.5rem;
  }

  .xl\:-left-3 {
    left: -.75rem;
  }

  .xl\:-left-4 {
    left: -1rem;
  }

  .xl\:-left-5 {
    left: -1.25rem;
  }

  .xl\:-left-6 {
    left: -1.5rem;
  }

  .xl\:-left-7 {
    left: -1.75rem;
  }

  .xl\:-left-8 {
    left: -2rem;
  }

  .xl\:-left-9 {
    left: -2.25rem;
  }

  .xl\:-left-10 {
    left: -2.5rem;
  }

  .xl\:-left-11 {
    left: -2.75rem;
  }

  .xl\:-left-12 {
    left: -3rem;
  }

  .xl\:-left-14 {
    left: -3.5rem;
  }

  .xl\:-left-16 {
    left: -4rem;
  }

  .xl\:-left-20 {
    left: -5rem;
  }

  .xl\:-left-24 {
    left: -6rem;
  }

  .xl\:-left-28 {
    left: -7rem;
  }

  .xl\:-left-32 {
    left: -8rem;
  }

  .xl\:-left-36 {
    left: -9rem;
  }

  .xl\:-left-40 {
    left: -10rem;
  }

  .xl\:-left-44 {
    left: -11rem;
  }

  .xl\:-left-48 {
    left: -12rem;
  }

  .xl\:-left-52 {
    left: -13rem;
  }

  .xl\:-left-56 {
    left: -14rem;
  }

  .xl\:-left-60 {
    left: -15rem;
  }

  .xl\:-left-64 {
    left: -16rem;
  }

  .xl\:-left-72 {
    left: -18rem;
  }

  .xl\:-left-80 {
    left: -20rem;
  }

  .xl\:-left-96 {
    left: -24rem;
  }

  .xl\:-left-px {
    left: -1px;
  }

  .xl\:-left-0\.5 {
    left: -.125rem;
  }

  .xl\:-left-1\.5 {
    left: -.375rem;
  }

  .xl\:-left-2\.5 {
    left: -.625rem;
  }

  .xl\:-left-3\.5 {
    left: -.875rem;
  }

  .xl\:left-1\/2 {
    left: 50%;
  }

  .xl\:left-1\/3 {
    left: 33.3333%;
  }

  .xl\:left-2\/3 {
    left: 66.6667%;
  }

  .xl\:left-1\/4 {
    left: 25%;
  }

  .xl\:left-2\/4 {
    left: 50%;
  }

  .xl\:left-3\/4 {
    left: 75%;
  }

  .xl\:left-full {
    left: 100%;
  }

  .xl\:-left-1\/2 {
    left: -50%;
  }

  .xl\:-left-1\/3 {
    left: -33.3333%;
  }

  .xl\:-left-2\/3 {
    left: -66.6667%;
  }

  .xl\:-left-1\/4 {
    left: -25%;
  }

  .xl\:-left-2\/4 {
    left: -50%;
  }

  .xl\:-left-3\/4 {
    left: -75%;
  }

  .xl\:-left-full {
    left: -100%;
  }

  .xl\:isolate {
    isolation: isolate;
  }

  .xl\:isolation-auto {
    isolation: auto;
  }

  .xl\:z-0 {
    z-index: 0;
  }

  .xl\:z-10 {
    z-index: 10;
  }

  .xl\:z-20 {
    z-index: 20;
  }

  .xl\:z-30 {
    z-index: 30;
  }

  .xl\:z-40 {
    z-index: 40;
  }

  .xl\:z-50 {
    z-index: 50;
  }

  .xl\:z-auto {
    z-index: auto;
  }

  .xl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .xl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .xl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .xl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .xl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .xl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .xl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .xl\:focus\:z-0:focus {
    z-index: 0;
  }

  .xl\:focus\:z-10:focus {
    z-index: 10;
  }

  .xl\:focus\:z-20:focus {
    z-index: 20;
  }

  .xl\:focus\:z-30:focus {
    z-index: 30;
  }

  .xl\:focus\:z-40:focus {
    z-index: 40;
  }

  .xl\:focus\:z-50:focus {
    z-index: 50;
  }

  .xl\:focus\:z-auto:focus {
    z-index: auto;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:order-4 {
    order: 4;
  }

  .xl\:order-5 {
    order: 5;
  }

  .xl\:order-6 {
    order: 6;
  }

  .xl\:order-7 {
    order: 7;
  }

  .xl\:order-8 {
    order: 8;
  }

  .xl\:order-9 {
    order: 9;
  }

  .xl\:order-10 {
    order: 10;
  }

  .xl\:order-11 {
    order: 11;
  }

  .xl\:order-12 {
    order: 12;
  }

  .xl\:order-first {
    order: -9999;
  }

  .xl\:order-last {
    order: 9999;
  }

  .xl\:order-none {
    order: 0;
  }

  .xl\:col-auto {
    grid-column: auto;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:col-start-1 {
    grid-column-start: 1;
  }

  .xl\:col-start-2 {
    grid-column-start: 2;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-4 {
    grid-column-start: 4;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:col-start-9 {
    grid-column-start: 9;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:col-start-11 {
    grid-column-start: 11;
  }

  .xl\:col-start-12 {
    grid-column-start: 12;
  }

  .xl\:col-start-13 {
    grid-column-start: 13;
  }

  .xl\:col-start-auto {
    grid-column-start: auto;
  }

  .xl\:col-end-1 {
    grid-column-end: 1;
  }

  .xl\:col-end-2 {
    grid-column-end: 2;
  }

  .xl\:col-end-3 {
    grid-column-end: 3;
  }

  .xl\:col-end-4 {
    grid-column-end: 4;
  }

  .xl\:col-end-5 {
    grid-column-end: 5;
  }

  .xl\:col-end-6 {
    grid-column-end: 6;
  }

  .xl\:col-end-7 {
    grid-column-end: 7;
  }

  .xl\:col-end-8 {
    grid-column-end: 8;
  }

  .xl\:col-end-9 {
    grid-column-end: 9;
  }

  .xl\:col-end-10 {
    grid-column-end: 10;
  }

  .xl\:col-end-11 {
    grid-column-end: 11;
  }

  .xl\:col-end-12 {
    grid-column-end: 12;
  }

  .xl\:col-end-13 {
    grid-column-end: 13;
  }

  .xl\:col-end-auto {
    grid-column-end: auto;
  }

  .xl\:row-auto {
    grid-row: auto;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xl\:row-span-full {
    grid-row: 1 / -1;
  }

  .xl\:row-start-1 {
    grid-row-start: 1;
  }

  .xl\:row-start-2 {
    grid-row-start: 2;
  }

  .xl\:row-start-3 {
    grid-row-start: 3;
  }

  .xl\:row-start-4 {
    grid-row-start: 4;
  }

  .xl\:row-start-5 {
    grid-row-start: 5;
  }

  .xl\:row-start-6 {
    grid-row-start: 6;
  }

  .xl\:row-start-7 {
    grid-row-start: 7;
  }

  .xl\:row-start-auto {
    grid-row-start: auto;
  }

  .xl\:row-end-1 {
    grid-row-end: 1;
  }

  .xl\:row-end-2 {
    grid-row-end: 2;
  }

  .xl\:row-end-3 {
    grid-row-end: 3;
  }

  .xl\:row-end-4 {
    grid-row-end: 4;
  }

  .xl\:row-end-5 {
    grid-row-end: 5;
  }

  .xl\:row-end-6 {
    grid-row-end: 6;
  }

  .xl\:row-end-7 {
    grid-row-end: 7;
  }

  .xl\:row-end-auto {
    grid-row-end: auto;
  }

  .xl\:float-right {
    float: right;
  }

  .xl\:float-left {
    float: left;
  }

  .xl\:float-none {
    float: none;
  }

  .xl\:clear-left {
    clear: left;
  }

  .xl\:clear-right {
    clear: right;
  }

  .xl\:clear-both {
    clear: both;
  }

  .xl\:clear-none {
    clear: none;
  }

  .xl\:m-0 {
    margin: 0;
  }

  .xl\:m-1 {
    margin: .25rem;
  }

  .xl\:m-2 {
    margin: .5rem;
  }

  .xl\:m-3 {
    margin: .75rem;
  }

  .xl\:m-4 {
    margin: 1rem;
  }

  .xl\:m-5 {
    margin: 1.25rem;
  }

  .xl\:m-6 {
    margin: 1.5rem;
  }

  .xl\:m-7 {
    margin: 1.75rem;
  }

  .xl\:m-8 {
    margin: 2rem;
  }

  .xl\:m-9 {
    margin: 2.25rem;
  }

  .xl\:m-10 {
    margin: 2.5rem;
  }

  .xl\:m-11 {
    margin: 2.75rem;
  }

  .xl\:m-12 {
    margin: 3rem;
  }

  .xl\:m-14 {
    margin: 3.5rem;
  }

  .xl\:m-16 {
    margin: 4rem;
  }

  .xl\:m-20 {
    margin: 5rem;
  }

  .xl\:m-24 {
    margin: 6rem;
  }

  .xl\:m-28 {
    margin: 7rem;
  }

  .xl\:m-32 {
    margin: 8rem;
  }

  .xl\:m-36 {
    margin: 9rem;
  }

  .xl\:m-40 {
    margin: 10rem;
  }

  .xl\:m-44 {
    margin: 11rem;
  }

  .xl\:m-48 {
    margin: 12rem;
  }

  .xl\:m-52 {
    margin: 13rem;
  }

  .xl\:m-56 {
    margin: 14rem;
  }

  .xl\:m-60 {
    margin: 15rem;
  }

  .xl\:m-64 {
    margin: 16rem;
  }

  .xl\:m-72 {
    margin: 18rem;
  }

  .xl\:m-80 {
    margin: 20rem;
  }

  .xl\:m-96 {
    margin: 24rem;
  }

  .xl\:m-auto {
    margin: auto;
  }

  .xl\:m-px {
    margin: 1px;
  }

  .xl\:m-0\.5 {
    margin: .125rem;
  }

  .xl\:m-1\.5 {
    margin: .375rem;
  }

  .xl\:m-2\.5 {
    margin: .625rem;
  }

  .xl\:m-3\.5 {
    margin: .875rem;
  }

  .xl\:-m-0 {
    margin: 0;
  }

  .xl\:-m-1 {
    margin: -.25rem;
  }

  .xl\:-m-2 {
    margin: -.5rem;
  }

  .xl\:-m-3 {
    margin: -.75rem;
  }

  .xl\:-m-4 {
    margin: -1rem;
  }

  .xl\:-m-5 {
    margin: -1.25rem;
  }

  .xl\:-m-6 {
    margin: -1.5rem;
  }

  .xl\:-m-7 {
    margin: -1.75rem;
  }

  .xl\:-m-8 {
    margin: -2rem;
  }

  .xl\:-m-9 {
    margin: -2.25rem;
  }

  .xl\:-m-10 {
    margin: -2.5rem;
  }

  .xl\:-m-11 {
    margin: -2.75rem;
  }

  .xl\:-m-12 {
    margin: -3rem;
  }

  .xl\:-m-14 {
    margin: -3.5rem;
  }

  .xl\:-m-16 {
    margin: -4rem;
  }

  .xl\:-m-20 {
    margin: -5rem;
  }

  .xl\:-m-24 {
    margin: -6rem;
  }

  .xl\:-m-28 {
    margin: -7rem;
  }

  .xl\:-m-32 {
    margin: -8rem;
  }

  .xl\:-m-36 {
    margin: -9rem;
  }

  .xl\:-m-40 {
    margin: -10rem;
  }

  .xl\:-m-44 {
    margin: -11rem;
  }

  .xl\:-m-48 {
    margin: -12rem;
  }

  .xl\:-m-52 {
    margin: -13rem;
  }

  .xl\:-m-56 {
    margin: -14rem;
  }

  .xl\:-m-60 {
    margin: -15rem;
  }

  .xl\:-m-64 {
    margin: -16rem;
  }

  .xl\:-m-72 {
    margin: -18rem;
  }

  .xl\:-m-80 {
    margin: -20rem;
  }

  .xl\:-m-96 {
    margin: -24rem;
  }

  .xl\:-m-px {
    margin: -1px;
  }

  .xl\:-m-0\.5 {
    margin: -.125rem;
  }

  .xl\:-m-1\.5 {
    margin: -.375rem;
  }

  .xl\:-m-2\.5 {
    margin: -.625rem;
  }

  .xl\:-m-3\.5 {
    margin: -.875rem;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .xl\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .xl\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .xl\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .xl\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .xl\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .xl\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .xl\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .xl\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .xl\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .xl\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .xl\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .xl\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .xl\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .xl\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .xl\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .xl\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .xl\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .xl\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .xl\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .xl\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-1 {
    margin-top: .25rem;
  }

  .xl\:mt-2 {
    margin-top: .5rem;
  }

  .xl\:mt-3 {
    margin-top: .75rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:mt-7 {
    margin-top: 1.75rem;
  }

  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:mt-9 {
    margin-top: 2.25rem;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mt-11 {
    margin-top: 2.75rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mt-14 {
    margin-top: 3.5rem;
  }

  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mt-24 {
    margin-top: 6rem;
  }

  .xl\:mt-28 {
    margin-top: 7rem;
  }

  .xl\:mt-32 {
    margin-top: 8rem;
  }

  .xl\:mt-36 {
    margin-top: 9rem;
  }

  .xl\:mt-40 {
    margin-top: 10rem;
  }

  .xl\:mt-44 {
    margin-top: 11rem;
  }

  .xl\:mt-48 {
    margin-top: 12rem;
  }

  .xl\:mt-52 {
    margin-top: 13rem;
  }

  .xl\:mt-56 {
    margin-top: 14rem;
  }

  .xl\:mt-60 {
    margin-top: 15rem;
  }

  .xl\:mt-64 {
    margin-top: 16rem;
  }

  .xl\:mt-72 {
    margin-top: 18rem;
  }

  .xl\:mt-80 {
    margin-top: 20rem;
  }

  .xl\:mt-96 {
    margin-top: 24rem;
  }

  .xl\:mt-auto {
    margin-top: auto;
  }

  .xl\:mt-px {
    margin-top: 1px;
  }

  .xl\:mt-0\.5 {
    margin-top: .125rem;
  }

  .xl\:mt-1\.5 {
    margin-top: .375rem;
  }

  .xl\:mt-2\.5 {
    margin-top: .625rem;
  }

  .xl\:mt-3\.5 {
    margin-top: .875rem;
  }

  .xl\:-mt-0 {
    margin-top: 0;
  }

  .xl\:-mt-1 {
    margin-top: -.25rem;
  }

  .xl\:-mt-2 {
    margin-top: -.5rem;
  }

  .xl\:-mt-3 {
    margin-top: -.75rem;
  }

  .xl\:-mt-4 {
    margin-top: -1rem;
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:-mt-7 {
    margin-top: -1.75rem;
  }

  .xl\:-mt-8 {
    margin-top: -2rem;
  }

  .xl\:-mt-9 {
    margin-top: -2.25rem;
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:-mt-11 {
    margin-top: -2.75rem;
  }

  .xl\:-mt-12 {
    margin-top: -3rem;
  }

  .xl\:-mt-14 {
    margin-top: -3.5rem;
  }

  .xl\:-mt-16 {
    margin-top: -4rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:-mt-24 {
    margin-top: -6rem;
  }

  .xl\:-mt-28 {
    margin-top: -7rem;
  }

  .xl\:-mt-32 {
    margin-top: -8rem;
  }

  .xl\:-mt-36 {
    margin-top: -9rem;
  }

  .xl\:-mt-40 {
    margin-top: -10rem;
  }

  .xl\:-mt-44 {
    margin-top: -11rem;
  }

  .xl\:-mt-48 {
    margin-top: -12rem;
  }

  .xl\:-mt-52 {
    margin-top: -13rem;
  }

  .xl\:-mt-56 {
    margin-top: -14rem;
  }

  .xl\:-mt-60 {
    margin-top: -15rem;
  }

  .xl\:-mt-64 {
    margin-top: -16rem;
  }

  .xl\:-mt-72 {
    margin-top: -18rem;
  }

  .xl\:-mt-80 {
    margin-top: -20rem;
  }

  .xl\:-mt-96 {
    margin-top: -24rem;
  }

  .xl\:-mt-px {
    margin-top: -1px;
  }

  .xl\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .xl\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .xl\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .xl\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mr-1 {
    margin-right: .25rem;
  }

  .xl\:mr-2 {
    margin-right: .5rem;
  }

  .xl\:mr-3 {
    margin-right: .75rem;
  }

  .xl\:mr-4 {
    margin-right: 1rem;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:mr-7 {
    margin-right: 1.75rem;
  }

  .xl\:mr-8 {
    margin-right: 2rem;
  }

  .xl\:mr-9 {
    margin-right: 2.25rem;
  }

  .xl\:mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:mr-11 {
    margin-right: 2.75rem;
  }

  .xl\:mr-12 {
    margin-right: 3rem;
  }

  .xl\:mr-14 {
    margin-right: 3.5rem;
  }

  .xl\:mr-16 {
    margin-right: 4rem;
  }

  .xl\:mr-20 {
    margin-right: 5rem;
  }

  .xl\:mr-24 {
    margin-right: 6rem;
  }

  .xl\:mr-28 {
    margin-right: 7rem;
  }

  .xl\:mr-32 {
    margin-right: 8rem;
  }

  .xl\:mr-36 {
    margin-right: 9rem;
  }

  .xl\:mr-40 {
    margin-right: 10rem;
  }

  .xl\:mr-44 {
    margin-right: 11rem;
  }

  .xl\:mr-48 {
    margin-right: 12rem;
  }

  .xl\:mr-52 {
    margin-right: 13rem;
  }

  .xl\:mr-56 {
    margin-right: 14rem;
  }

  .xl\:mr-60 {
    margin-right: 15rem;
  }

  .xl\:mr-64 {
    margin-right: 16rem;
  }

  .xl\:mr-72 {
    margin-right: 18rem;
  }

  .xl\:mr-80 {
    margin-right: 20rem;
  }

  .xl\:mr-96 {
    margin-right: 24rem;
  }

  .xl\:mr-auto {
    margin-right: auto;
  }

  .xl\:mr-px {
    margin-right: 1px;
  }

  .xl\:mr-0\.5 {
    margin-right: .125rem;
  }

  .xl\:mr-1\.5 {
    margin-right: .375rem;
  }

  .xl\:mr-2\.5 {
    margin-right: .625rem;
  }

  .xl\:mr-3\.5 {
    margin-right: .875rem;
  }

  .xl\:-mr-0 {
    margin-right: 0;
  }

  .xl\:-mr-1 {
    margin-right: -.25rem;
  }

  .xl\:-mr-2 {
    margin-right: -.5rem;
  }

  .xl\:-mr-3 {
    margin-right: -.75rem;
  }

  .xl\:-mr-4 {
    margin-right: -1rem;
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:-mr-7 {
    margin-right: -1.75rem;
  }

  .xl\:-mr-8 {
    margin-right: -2rem;
  }

  .xl\:-mr-9 {
    margin-right: -2.25rem;
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:-mr-11 {
    margin-right: -2.75rem;
  }

  .xl\:-mr-12 {
    margin-right: -3rem;
  }

  .xl\:-mr-14 {
    margin-right: -3.5rem;
  }

  .xl\:-mr-16 {
    margin-right: -4rem;
  }

  .xl\:-mr-20 {
    margin-right: -5rem;
  }

  .xl\:-mr-24 {
    margin-right: -6rem;
  }

  .xl\:-mr-28 {
    margin-right: -7rem;
  }

  .xl\:-mr-32 {
    margin-right: -8rem;
  }

  .xl\:-mr-36 {
    margin-right: -9rem;
  }

  .xl\:-mr-40 {
    margin-right: -10rem;
  }

  .xl\:-mr-44 {
    margin-right: -11rem;
  }

  .xl\:-mr-48 {
    margin-right: -12rem;
  }

  .xl\:-mr-52 {
    margin-right: -13rem;
  }

  .xl\:-mr-56 {
    margin-right: -14rem;
  }

  .xl\:-mr-60 {
    margin-right: -15rem;
  }

  .xl\:-mr-64 {
    margin-right: -16rem;
  }

  .xl\:-mr-72 {
    margin-right: -18rem;
  }

  .xl\:-mr-80 {
    margin-right: -20rem;
  }

  .xl\:-mr-96 {
    margin-right: -24rem;
  }

  .xl\:-mr-px {
    margin-right: -1px;
  }

  .xl\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .xl\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .xl\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .xl\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-1 {
    margin-bottom: .25rem;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:mb-3 {
    margin-bottom: .75rem;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:mb-28 {
    margin-bottom: 7rem;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:mb-36 {
    margin-bottom: 9rem;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:mb-44 {
    margin-bottom: 11rem;
  }

  .xl\:mb-48 {
    margin-bottom: 12rem;
  }

  .xl\:mb-52 {
    margin-bottom: 13rem;
  }

  .xl\:mb-56 {
    margin-bottom: 14rem;
  }

  .xl\:mb-60 {
    margin-bottom: 15rem;
  }

  .xl\:mb-64 {
    margin-bottom: 16rem;
  }

  .xl\:mb-72 {
    margin-bottom: 18rem;
  }

  .xl\:mb-80 {
    margin-bottom: 20rem;
  }

  .xl\:mb-96 {
    margin-bottom: 24rem;
  }

  .xl\:mb-auto {
    margin-bottom: auto;
  }

  .xl\:mb-px {
    margin-bottom: 1px;
  }

  .xl\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .xl\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .xl\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .xl\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .xl\:-mb-0 {
    margin-bottom: 0;
  }

  .xl\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .xl\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .xl\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:-mb-28 {
    margin-bottom: -7rem;
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:-mb-36 {
    margin-bottom: -9rem;
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem;
  }

  .xl\:-mb-44 {
    margin-bottom: -11rem;
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem;
  }

  .xl\:-mb-52 {
    margin-bottom: -13rem;
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem;
  }

  .xl\:-mb-60 {
    margin-bottom: -15rem;
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem;
  }

  .xl\:-mb-72 {
    margin-bottom: -18rem;
  }

  .xl\:-mb-80 {
    margin-bottom: -20rem;
  }

  .xl\:-mb-96 {
    margin-bottom: -24rem;
  }

  .xl\:-mb-px {
    margin-bottom: -1px;
  }

  .xl\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .xl\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .xl\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .xl\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:ml-1 {
    margin-left: .25rem;
  }

  .xl\:ml-2 {
    margin-left: .5rem;
  }

  .xl\:ml-3 {
    margin-left: .75rem;
  }

  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:ml-7 {
    margin-left: 1.75rem;
  }

  .xl\:ml-8 {
    margin-left: 2rem;
  }

  .xl\:ml-9 {
    margin-left: 2.25rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:ml-11 {
    margin-left: 2.75rem;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:ml-14 {
    margin-left: 3.5rem;
  }

  .xl\:ml-16 {
    margin-left: 4rem;
  }

  .xl\:ml-20 {
    margin-left: 5rem;
  }

  .xl\:ml-24 {
    margin-left: 6rem;
  }

  .xl\:ml-28 {
    margin-left: 7rem;
  }

  .xl\:ml-32 {
    margin-left: 8rem;
  }

  .xl\:ml-36 {
    margin-left: 9rem;
  }

  .xl\:ml-40 {
    margin-left: 10rem;
  }

  .xl\:ml-44 {
    margin-left: 11rem;
  }

  .xl\:ml-48 {
    margin-left: 12rem;
  }

  .xl\:ml-52 {
    margin-left: 13rem;
  }

  .xl\:ml-56 {
    margin-left: 14rem;
  }

  .xl\:ml-60 {
    margin-left: 15rem;
  }

  .xl\:ml-64 {
    margin-left: 16rem;
  }

  .xl\:ml-72 {
    margin-left: 18rem;
  }

  .xl\:ml-80 {
    margin-left: 20rem;
  }

  .xl\:ml-96 {
    margin-left: 24rem;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:ml-px {
    margin-left: 1px;
  }

  .xl\:ml-0\.5 {
    margin-left: .125rem;
  }

  .xl\:ml-1\.5 {
    margin-left: .375rem;
  }

  .xl\:ml-2\.5 {
    margin-left: .625rem;
  }

  .xl\:ml-3\.5 {
    margin-left: .875rem;
  }

  .xl\:-ml-0 {
    margin-left: 0;
  }

  .xl\:-ml-1 {
    margin-left: -.25rem;
  }

  .xl\:-ml-2 {
    margin-left: -.5rem;
  }

  .xl\:-ml-3 {
    margin-left: -.75rem;
  }

  .xl\:-ml-4 {
    margin-left: -1rem;
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:-ml-7 {
    margin-left: -1.75rem;
  }

  .xl\:-ml-8 {
    margin-left: -2rem;
  }

  .xl\:-ml-9 {
    margin-left: -2.25rem;
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:-ml-11 {
    margin-left: -2.75rem;
  }

  .xl\:-ml-12 {
    margin-left: -3rem;
  }

  .xl\:-ml-14 {
    margin-left: -3.5rem;
  }

  .xl\:-ml-16 {
    margin-left: -4rem;
  }

  .xl\:-ml-20 {
    margin-left: -5rem;
  }

  .xl\:-ml-24 {
    margin-left: -6rem;
  }

  .xl\:-ml-28 {
    margin-left: -7rem;
  }

  .xl\:-ml-32 {
    margin-left: -8rem;
  }

  .xl\:-ml-36 {
    margin-left: -9rem;
  }

  .xl\:-ml-40 {
    margin-left: -10rem;
  }

  .xl\:-ml-44 {
    margin-left: -11rem;
  }

  .xl\:-ml-48 {
    margin-left: -12rem;
  }

  .xl\:-ml-52 {
    margin-left: -13rem;
  }

  .xl\:-ml-56 {
    margin-left: -14rem;
  }

  .xl\:-ml-60 {
    margin-left: -15rem;
  }

  .xl\:-ml-64 {
    margin-left: -16rem;
  }

  .xl\:-ml-72 {
    margin-left: -18rem;
  }

  .xl\:-ml-80 {
    margin-left: -20rem;
  }

  .xl\:-ml-96 {
    margin-left: -24rem;
  }

  .xl\:-ml-px {
    margin-left: -1px;
  }

  .xl\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .xl\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .xl\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .xl\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .xl\:box-border {
    box-sizing: border-box;
  }

  .xl\:box-content {
    box-sizing: content-box;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline-block {
    display: inline-block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:inline-flex {
    display: inline-flex;
  }

  .xl\:table {
    display: table;
  }

  .xl\:inline-table {
    display: inline-table;
  }

  .xl\:table-caption {
    display: table-caption;
  }

  .xl\:table-cell {
    display: table-cell;
  }

  .xl\:table-column {
    display: table-column;
  }

  .xl\:table-column-group {
    display: table-column-group;
  }

  .xl\:table-footer-group {
    display: table-footer-group;
  }

  .xl\:table-header-group {
    display: table-header-group;
  }

  .xl\:table-row-group {
    display: table-row-group;
  }

  .xl\:table-row {
    display: table-row;
  }

  .xl\:flow-root {
    display: flow-root;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:inline-grid {
    display: inline-grid;
  }

  .xl\:contents {
    display: contents;
  }

  .xl\:list-item {
    display: list-item;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:h-0 {
    height: 0;
  }

  .xl\:h-1 {
    height: .25rem;
  }

  .xl\:h-2 {
    height: .5rem;
  }

  .xl\:h-3 {
    height: .75rem;
  }

  .xl\:h-4 {
    height: 1rem;
  }

  .xl\:h-5 {
    height: 1.25rem;
  }

  .xl\:h-6 {
    height: 1.5rem;
  }

  .xl\:h-7 {
    height: 1.75rem;
  }

  .xl\:h-8 {
    height: 2rem;
  }

  .xl\:h-9 {
    height: 2.25rem;
  }

  .xl\:h-10 {
    height: 2.5rem;
  }

  .xl\:h-11 {
    height: 2.75rem;
  }

  .xl\:h-12 {
    height: 3rem;
  }

  .xl\:h-14 {
    height: 3.5rem;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-24 {
    height: 6rem;
  }

  .xl\:h-28 {
    height: 7rem;
  }

  .xl\:h-32 {
    height: 8rem;
  }

  .xl\:h-36 {
    height: 9rem;
  }

  .xl\:h-40 {
    height: 10rem;
  }

  .xl\:h-44 {
    height: 11rem;
  }

  .xl\:h-48 {
    height: 12rem;
  }

  .xl\:h-52 {
    height: 13rem;
  }

  .xl\:h-56 {
    height: 14rem;
  }

  .xl\:h-60 {
    height: 15rem;
  }

  .xl\:h-64 {
    height: 16rem;
  }

  .xl\:h-72 {
    height: 18rem;
  }

  .xl\:h-80 {
    height: 20rem;
  }

  .xl\:h-96 {
    height: 24rem;
  }

  .xl\:h-auto {
    height: auto;
  }

  .xl\:h-px {
    height: 1px;
  }

  .xl\:h-0\.5 {
    height: .125rem;
  }

  .xl\:h-1\.5 {
    height: .375rem;
  }

  .xl\:h-2\.5 {
    height: .625rem;
  }

  .xl\:h-3\.5 {
    height: .875rem;
  }

  .xl\:h-1\/2 {
    height: 50%;
  }

  .xl\:h-1\/3 {
    height: 33.3333%;
  }

  .xl\:h-2\/3 {
    height: 66.6667%;
  }

  .xl\:h-1\/4 {
    height: 25%;
  }

  .xl\:h-2\/4 {
    height: 50%;
  }

  .xl\:h-3\/4 {
    height: 75%;
  }

  .xl\:h-1\/5 {
    height: 20%;
  }

  .xl\:h-2\/5 {
    height: 40%;
  }

  .xl\:h-3\/5 {
    height: 60%;
  }

  .xl\:h-4\/5 {
    height: 80%;
  }

  .xl\:h-1\/6 {
    height: 16.6667%;
  }

  .xl\:h-2\/6 {
    height: 33.3333%;
  }

  .xl\:h-3\/6 {
    height: 50%;
  }

  .xl\:h-4\/6 {
    height: 66.6667%;
  }

  .xl\:h-5\/6 {
    height: 83.3333%;
  }

  .xl\:h-full {
    height: 100%;
  }

  .xl\:h-screen {
    height: 100vh;
  }

  .xl\:max-h-0 {
    max-height: 0;
  }

  .xl\:max-h-1 {
    max-height: .25rem;
  }

  .xl\:max-h-2 {
    max-height: .5rem;
  }

  .xl\:max-h-3 {
    max-height: .75rem;
  }

  .xl\:max-h-4 {
    max-height: 1rem;
  }

  .xl\:max-h-5 {
    max-height: 1.25rem;
  }

  .xl\:max-h-6 {
    max-height: 1.5rem;
  }

  .xl\:max-h-7 {
    max-height: 1.75rem;
  }

  .xl\:max-h-8 {
    max-height: 2rem;
  }

  .xl\:max-h-9 {
    max-height: 2.25rem;
  }

  .xl\:max-h-10 {
    max-height: 2.5rem;
  }

  .xl\:max-h-11 {
    max-height: 2.75rem;
  }

  .xl\:max-h-12 {
    max-height: 3rem;
  }

  .xl\:max-h-14 {
    max-height: 3.5rem;
  }

  .xl\:max-h-16 {
    max-height: 4rem;
  }

  .xl\:max-h-20 {
    max-height: 5rem;
  }

  .xl\:max-h-24 {
    max-height: 6rem;
  }

  .xl\:max-h-28 {
    max-height: 7rem;
  }

  .xl\:max-h-32 {
    max-height: 8rem;
  }

  .xl\:max-h-36 {
    max-height: 9rem;
  }

  .xl\:max-h-40 {
    max-height: 10rem;
  }

  .xl\:max-h-44 {
    max-height: 11rem;
  }

  .xl\:max-h-48 {
    max-height: 12rem;
  }

  .xl\:max-h-52 {
    max-height: 13rem;
  }

  .xl\:max-h-56 {
    max-height: 14rem;
  }

  .xl\:max-h-60 {
    max-height: 15rem;
  }

  .xl\:max-h-64 {
    max-height: 16rem;
  }

  .xl\:max-h-72 {
    max-height: 18rem;
  }

  .xl\:max-h-80 {
    max-height: 20rem;
  }

  .xl\:max-h-96 {
    max-height: 24rem;
  }

  .xl\:max-h-px {
    max-height: 1px;
  }

  .xl\:max-h-0\.5 {
    max-height: .125rem;
  }

  .xl\:max-h-1\.5 {
    max-height: .375rem;
  }

  .xl\:max-h-2\.5 {
    max-height: .625rem;
  }

  .xl\:max-h-3\.5 {
    max-height: .875rem;
  }

  .xl\:max-h-full {
    max-height: 100%;
  }

  .xl\:max-h-screen {
    max-height: 100vh;
  }

  .xl\:min-h-0 {
    min-height: 0;
  }

  .xl\:min-h-full {
    min-height: 100%;
  }

  .xl\:min-h-screen {
    min-height: 100vh;
  }

  .xl\:w-0 {
    width: 0;
  }

  .xl\:w-1 {
    width: .25rem;
  }

  .xl\:w-2 {
    width: .5rem;
  }

  .xl\:w-3 {
    width: .75rem;
  }

  .xl\:w-4 {
    width: 1rem;
  }

  .xl\:w-5 {
    width: 1.25rem;
  }

  .xl\:w-6 {
    width: 1.5rem;
  }

  .xl\:w-7 {
    width: 1.75rem;
  }

  .xl\:w-8 {
    width: 2rem;
  }

  .xl\:w-9 {
    width: 2.25rem;
  }

  .xl\:w-10 {
    width: 2.5rem;
  }

  .xl\:w-11 {
    width: 2.75rem;
  }

  .xl\:w-12 {
    width: 3rem;
  }

  .xl\:w-14 {
    width: 3.5rem;
  }

  .xl\:w-16 {
    width: 4rem;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-24 {
    width: 6rem;
  }

  .xl\:w-28 {
    width: 7rem;
  }

  .xl\:w-32 {
    width: 8rem;
  }

  .xl\:w-36 {
    width: 9rem;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-44 {
    width: 11rem;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-52 {
    width: 13rem;
  }

  .xl\:w-56 {
    width: 14rem;
  }

  .xl\:w-60 {
    width: 15rem;
  }

  .xl\:w-64 {
    width: 16rem;
  }

  .xl\:w-72 {
    width: 18rem;
  }

  .xl\:w-80 {
    width: 20rem;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:w-px {
    width: 1px;
  }

  .xl\:w-0\.5 {
    width: .125rem;
  }

  .xl\:w-1\.5 {
    width: .375rem;
  }

  .xl\:w-2\.5 {
    width: .625rem;
  }

  .xl\:w-3\.5 {
    width: .875rem;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-2\/4 {
    width: 50%;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-1\/5 {
    width: 20%;
  }

  .xl\:w-2\/5 {
    width: 40%;
  }

  .xl\:w-3\/5 {
    width: 60%;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:w-1\/6 {
    width: 16.6667%;
  }

  .xl\:w-2\/6 {
    width: 33.3333%;
  }

  .xl\:w-3\/6 {
    width: 50%;
  }

  .xl\:w-4\/6 {
    width: 66.6667%;
  }

  .xl\:w-5\/6 {
    width: 83.3333%;
  }

  .xl\:w-1\/12 {
    width: 8.33333%;
  }

  .xl\:w-2\/12 {
    width: 16.6667%;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-4\/12 {
    width: 33.3333%;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-7\/12 {
    width: 58.3333%;
  }

  .xl\:w-8\/12 {
    width: 66.6667%;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:w-10\/12 {
    width: 83.3333%;
  }

  .xl\:w-11\/12 {
    width: 91.6667%;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:w-screen {
    width: 100vw;
  }

  .xl\:w-min {
    width: min-content;
  }

  .xl\:w-max {
    width: max-content;
  }

  .xl\:min-w-0 {
    min-width: 0;
  }

  .xl\:min-w-full {
    min-width: 100%;
  }

  .xl\:min-w-min {
    min-width: min-content;
  }

  .xl\:min-w-max {
    min-width: max-content;
  }

  .xl\:max-w-0 {
    max-width: 0;
  }

  .xl\:max-w-none {
    max-width: none;
  }

  .xl\:max-w-xs {
    max-width: 20rem;
  }

  .xl\:max-w-sm {
    max-width: 24rem;
  }

  .xl\:max-w-md {
    max-width: 28rem;
  }

  .xl\:max-w-lg {
    max-width: 32rem;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:max-w-3xl {
    max-width: 48rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:max-w-5xl {
    max-width: 64rem;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:max-w-full {
    max-width: 100%;
  }

  .xl\:max-w-min {
    max-width: min-content;
  }

  .xl\:max-w-max {
    max-width: max-content;
  }

  .xl\:max-w-prose {
    max-width: 65ch;
  }

  .xl\:max-w-screen-sm {
    max-width: 640px;
  }

  .xl\:max-w-screen-md {
    max-width: 768px;
  }

  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }

  .xl\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .xl\:flex-1 {
    flex: 1;
  }

  .xl\:flex-auto {
    flex: auto;
  }

  .xl\:flex-initial {
    flex: 0 auto;
  }

  .xl\:flex-none {
    flex: none;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:flex-shrink {
    flex-shrink: 1;
  }

  .xl\:flex-grow-0 {
    flex-grow: 0;
  }

  .xl\:flex-grow {
    flex-grow: 1;
  }

  .xl\:table-auto {
    table-layout: auto;
  }

  .xl\:table-fixed {
    table-layout: fixed;
  }

  .xl\:border-collapse {
    border-collapse: collapse;
  }

  .xl\:border-separate {
    border-collapse: separate;
  }

  .xl\:origin-center {
    transform-origin: center;
  }

  .xl\:origin-top {
    transform-origin: top;
  }

  .xl\:origin-top-right {
    transform-origin: 100% 0;
  }

  .xl\:origin-right {
    transform-origin: 100%;
  }

  .xl\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .xl\:origin-bottom {
    transform-origin: bottom;
  }

  .xl\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .xl\:origin-left {
    transform-origin: 0;
  }

  .xl\:origin-top-left {
    transform-origin: 0 0;
  }

  .xl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform-none {
    transform: none;
  }

  .xl\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .xl\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .xl\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .xl\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .xl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .xl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .xl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .xl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .xl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .xl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .xl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .xl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .xl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .xl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .xl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .xl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .xl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .xl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .xl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .xl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .xl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .xl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .xl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .xl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .xl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .xl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .xl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .xl\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .xl\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .xl\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .xl\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .xl\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .xl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .xl\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .xl\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .xl\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .xl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .xl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .xl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .xl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .xl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .xl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .xl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .xl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .xl\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .xl\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .xl\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .xl\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .xl\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .xl\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .xl\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .xl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .xl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .xl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .xl\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .xl\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .xl\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .xl\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .xl\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .xl\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .xl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .xl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .xl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .xl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .xl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .xl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .xl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .xl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .xl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .xl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .xl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .xl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .xl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .xl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .xl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .xl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .xl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .xl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .xl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .xl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .xl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .xl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .xl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .xl\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .xl\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .xl\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .xl\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .xl\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .xl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .xl\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .xl\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .xl\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .xl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .xl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .xl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .xl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .xl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .xl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .xl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .xl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .xl\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .xl\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .xl\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .xl\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .xl\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .xl\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .xl\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .xl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .xl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .xl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .xl\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .xl\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .xl\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .xl\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .xl\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .xl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .xl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .xl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .xl\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .xl\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .xl\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .xl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .xl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .xl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .xl\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .xl\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .xl\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .xl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .xl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .xl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .xl\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .xl\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .xl\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .xl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .xl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .xl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .xl\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .xl\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .xl\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .xl\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .xl\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .xl\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .xl\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .xl\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .xl\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .xl\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .xl\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .xl\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .xl\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .xl\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .xl\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .xl\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .xl\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .xl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .xl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .xl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .xl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .xl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .xl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .xl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .xl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .xl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .xl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .xl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .xl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .xl\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .xl\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .xl\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .xl\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .xl\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .xl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .xl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .xl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .xl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .xl\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .xl\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .xl\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .xl\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .xl\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .xl\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .xl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .xl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .xl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .xl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .xl\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .xl\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .xl\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .xl\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .xl\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .xl\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .xl\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .xl\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .xl\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .xl\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .xl\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .xl\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .xl\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .xl\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .xl\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .xl\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .xl\:animate-none {
    animation: none;
  }

  .xl\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .xl\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .xl\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .xl\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .xl\:cursor-auto {
    cursor: auto;
  }

  .xl\:cursor-default {
    cursor: default;
  }

  .xl\:cursor-pointer {
    cursor: pointer;
  }

  .xl\:cursor-wait {
    cursor: wait;
  }

  .xl\:cursor-text {
    cursor: text;
  }

  .xl\:cursor-move {
    cursor: move;
  }

  .xl\:cursor-help {
    cursor: help;
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .xl\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .xl\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .xl\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .xl\:resize-none {
    resize: none;
  }

  .xl\:resize-y {
    resize: vertical;
  }

  .xl\:resize-x {
    resize: horizontal;
  }

  .xl\:resize {
    resize: both;
  }

  .xl\:list-inside {
    list-style-position: inside;
  }

  .xl\:list-outside {
    list-style-position: outside;
  }

  .xl\:list-none {
    list-style-type: none;
  }

  .xl\:list-disc {
    list-style-type: disc;
  }

  .xl\:list-decimal {
    list-style-type: decimal;
  }

  .xl\:appearance-none {
    appearance: none;
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xl\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xl\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xl\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-rows-none {
    grid-template-rows: none;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:place-content-center {
    place-content: center;
  }

  .xl\:place-content-start {
    place-content: start;
  }

  .xl\:place-content-end {
    place-content: end;
  }

  .xl\:place-content-between {
    place-content: space-between;
  }

  .xl\:place-content-around {
    place-content: space-around;
  }

  .xl\:place-content-evenly {
    place-content: space-evenly;
  }

  .xl\:place-content-stretch {
    place-content: stretch;
  }

  .xl\:place-items-start {
    place-items: start;
  }

  .xl\:place-items-end {
    place-items: end;
  }

  .xl\:place-items-center {
    place-items: center;
  }

  .xl\:place-items-stretch {
    place-items: stretch stretch;
  }

  .xl\:content-center {
    align-content: center;
  }

  .xl\:content-start {
    align-content: flex-start;
  }

  .xl\:content-end {
    align-content: flex-end;
  }

  .xl\:content-between {
    align-content: space-between;
  }

  .xl\:content-around {
    align-content: space-around;
  }

  .xl\:content-evenly {
    align-content: space-evenly;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:items-end {
    align-items: flex-end;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:items-baseline {
    align-items: baseline;
  }

  .xl\:items-stretch {
    align-items: stretch;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:justify-around {
    justify-content: space-around;
  }

  .xl\:justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:justify-items-start {
    justify-items: start;
  }

  .xl\:justify-items-end {
    justify-items: end;
  }

  .xl\:justify-items-center {
    justify-items: center;
  }

  .xl\:justify-items-stretch {
    justify-items: stretch;
  }

  .xl\:gap-0 {
    gap: 0;
  }

  .xl\:gap-1 {
    gap: .25rem;
  }

  .xl\:gap-2 {
    gap: .5rem;
  }

  .xl\:gap-3 {
    gap: .75rem;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-5 {
    gap: 1.25rem;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:gap-7 {
    gap: 1.75rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:gap-9 {
    gap: 2.25rem;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }

  .xl\:gap-11 {
    gap: 2.75rem;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:gap-14 {
    gap: 3.5rem;
  }

  .xl\:gap-16 {
    gap: 4rem;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:gap-24 {
    gap: 6rem;
  }

  .xl\:gap-28 {
    gap: 7rem;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-36 {
    gap: 9rem;
  }

  .xl\:gap-40 {
    gap: 10rem;
  }

  .xl\:gap-44 {
    gap: 11rem;
  }

  .xl\:gap-48 {
    gap: 12rem;
  }

  .xl\:gap-52 {
    gap: 13rem;
  }

  .xl\:gap-56 {
    gap: 14rem;
  }

  .xl\:gap-60 {
    gap: 15rem;
  }

  .xl\:gap-64 {
    gap: 16rem;
  }

  .xl\:gap-72 {
    gap: 18rem;
  }

  .xl\:gap-80 {
    gap: 20rem;
  }

  .xl\:gap-96 {
    gap: 24rem;
  }

  .xl\:gap-px {
    gap: 1px;
  }

  .xl\:gap-0\.5 {
    gap: .125rem;
  }

  .xl\:gap-1\.5 {
    gap: .375rem;
  }

  .xl\:gap-2\.5 {
    gap: .625rem;
  }

  .xl\:gap-3\.5 {
    gap: .875rem;
  }

  .xl\:gap-x-0 {
    column-gap: 0;
  }

  .xl\:gap-x-1 {
    column-gap: .25rem;
  }

  .xl\:gap-x-2 {
    column-gap: .5rem;
  }

  .xl\:gap-x-3 {
    column-gap: .75rem;
  }

  .xl\:gap-x-4 {
    column-gap: 1rem;
  }

  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xl\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .xl\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xl\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .xl\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .xl\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .xl\:gap-x-12 {
    column-gap: 3rem;
  }

  .xl\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .xl\:gap-x-16 {
    column-gap: 4rem;
  }

  .xl\:gap-x-20 {
    column-gap: 5rem;
  }

  .xl\:gap-x-24 {
    column-gap: 6rem;
  }

  .xl\:gap-x-28 {
    column-gap: 7rem;
  }

  .xl\:gap-x-32 {
    column-gap: 8rem;
  }

  .xl\:gap-x-36 {
    column-gap: 9rem;
  }

  .xl\:gap-x-40 {
    column-gap: 10rem;
  }

  .xl\:gap-x-44 {
    column-gap: 11rem;
  }

  .xl\:gap-x-48 {
    column-gap: 12rem;
  }

  .xl\:gap-x-52 {
    column-gap: 13rem;
  }

  .xl\:gap-x-56 {
    column-gap: 14rem;
  }

  .xl\:gap-x-60 {
    column-gap: 15rem;
  }

  .xl\:gap-x-64 {
    column-gap: 16rem;
  }

  .xl\:gap-x-72 {
    column-gap: 18rem;
  }

  .xl\:gap-x-80 {
    column-gap: 20rem;
  }

  .xl\:gap-x-96 {
    column-gap: 24rem;
  }

  .xl\:gap-x-px {
    column-gap: 1px;
  }

  .xl\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .xl\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .xl\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .xl\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .xl\:gap-y-0 {
    row-gap: 0;
  }

  .xl\:gap-y-1 {
    row-gap: .25rem;
  }

  .xl\:gap-y-2 {
    row-gap: .5rem;
  }

  .xl\:gap-y-3 {
    row-gap: .75rem;
  }

  .xl\:gap-y-4 {
    row-gap: 1rem;
  }

  .xl\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .xl\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .xl\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .xl\:gap-y-8 {
    row-gap: 2rem;
  }

  .xl\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .xl\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .xl\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .xl\:gap-y-12 {
    row-gap: 3rem;
  }

  .xl\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .xl\:gap-y-16 {
    row-gap: 4rem;
  }

  .xl\:gap-y-20 {
    row-gap: 5rem;
  }

  .xl\:gap-y-24 {
    row-gap: 6rem;
  }

  .xl\:gap-y-28 {
    row-gap: 7rem;
  }

  .xl\:gap-y-32 {
    row-gap: 8rem;
  }

  .xl\:gap-y-36 {
    row-gap: 9rem;
  }

  .xl\:gap-y-40 {
    row-gap: 10rem;
  }

  .xl\:gap-y-44 {
    row-gap: 11rem;
  }

  .xl\:gap-y-48 {
    row-gap: 12rem;
  }

  .xl\:gap-y-52 {
    row-gap: 13rem;
  }

  .xl\:gap-y-56 {
    row-gap: 14rem;
  }

  .xl\:gap-y-60 {
    row-gap: 15rem;
  }

  .xl\:gap-y-64 {
    row-gap: 16rem;
  }

  .xl\:gap-y-72 {
    row-gap: 18rem;
  }

  .xl\:gap-y-80 {
    row-gap: 20rem;
  }

  .xl\:gap-y-96 {
    row-gap: 24rem;
  }

  .xl\:gap-y-px {
    row-gap: 1px;
  }

  .xl\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .xl\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .xl\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .xl\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .xl\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .xl\:place-self-auto {
    place-self: auto;
  }

  .xl\:place-self-start {
    place-self: start;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:place-self-center {
    place-self: center;
  }

  .xl\:place-self-stretch {
    place-self: stretch stretch;
  }

  .xl\:self-auto {
    align-self: auto;
  }

  .xl\:self-start {
    align-self: flex-start;
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:self-stretch {
    align-self: stretch;
  }

  .xl\:self-baseline {
    align-self: baseline;
  }

  .xl\:justify-self-auto {
    justify-self: auto;
  }

  .xl\:justify-self-start {
    justify-self: start;
  }

  .xl\:justify-self-end {
    justify-self: end;
  }

  .xl\:justify-self-center {
    justify-self: center;
  }

  .xl\:justify-self-stretch {
    justify-self: stretch;
  }

  .xl\:overflow-auto {
    overflow: auto;
  }

  .xl\:overflow-hidden {
    overflow: hidden;
  }

  .xl\:overflow-visible {
    overflow: visible;
  }

  .xl\:overflow-scroll {
    overflow: scroll;
  }

  .xl\:overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .xl\:overscroll-none {
    overscroll-behavior: none;
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xl\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .xl\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .xl\:overflow-clip {
    text-overflow: clip;
  }

  .xl\:whitespace-normal {
    white-space: normal;
  }

  .xl\:whitespace-nowrap {
    white-space: nowrap;
  }

  .xl\:whitespace-pre {
    white-space: pre;
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:break-words {
    overflow-wrap: break-word;
  }

  .xl\:break-all {
    word-break: break-all;
  }

  .xl\:rounded-none {
    border-radius: 0;
  }

  .xl\:rounded-sm {
    border-radius: .125rem;
  }

  .xl\:rounded {
    border-radius: .25rem;
  }

  .xl\:rounded-md {
    border-radius: .375rem;
  }

  .xl\:rounded-lg {
    border-radius: .5rem;
  }

  .xl\:rounded-xl {
    border-radius: .75rem;
  }

  .xl\:rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:rounded-full {
    border-radius: 9999px;
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .xl\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .xl\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .xl\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .xl\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:border-0 {
    border-width: 0;
  }

  .xl\:border-2 {
    border-width: 2px;
  }

  .xl\:border-4 {
    border-width: 4px;
  }

  .xl\:border-8 {
    border-width: 8px;
  }

  .xl\:border {
    border-width: 1px;
  }

  .xl\:border-t-0 {
    border-top-width: 0;
  }

  .xl\:border-t-2 {
    border-top-width: 2px;
  }

  .xl\:border-t-4 {
    border-top-width: 4px;
  }

  .xl\:border-t-8 {
    border-top-width: 8px;
  }

  .xl\:border-t {
    border-top-width: 1px;
  }

  .xl\:border-r-0 {
    border-right-width: 0;
  }

  .xl\:border-r-2 {
    border-right-width: 2px;
  }

  .xl\:border-r-4 {
    border-right-width: 4px;
  }

  .xl\:border-r-8 {
    border-right-width: 8px;
  }

  .xl\:border-r {
    border-right-width: 1px;
  }

  .xl\:border-b-0 {
    border-bottom-width: 0;
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:border-b {
    border-bottom-width: 1px;
  }

  .xl\:border-l-0 {
    border-left-width: 0;
  }

  .xl\:border-l-2 {
    border-left-width: 2px;
  }

  .xl\:border-l-4 {
    border-left-width: 4px;
  }

  .xl\:border-l-8 {
    border-left-width: 8px;
  }

  .xl\:border-l {
    border-left-width: 1px;
  }

  .xl\:border-solid {
    border-style: solid;
  }

  .xl\:border-dashed {
    border-style: dashed;
  }

  .xl\:border-dotted {
    border-style: dotted;
  }

  .xl\:border-double {
    border-style: double;
  }

  .xl\:border-none {
    border-style: none;
  }

  .xl\:border-transparent {
    border-color: #0000;
  }

  .xl\:border-current {
    border-color: currentColor;
  }

  .xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .xl\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .xl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .xl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .xl\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .xl\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xl\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .xl\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .xl\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .xl\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .xl\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .xl\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .xl\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .xl\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .xl\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .xl\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .xl\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .xl\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .xl\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .xl\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .xl\:bg-transparent {
    background-color: #0000;
  }

  .xl\:bg-current {
    background-color: currentColor;
  }

  .xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .xl\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .xl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .xl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .xl\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .xl\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .xl\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .xl\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .xl\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .xl\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .xl\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .xl\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .xl\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .xl\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .xl\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .xl\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .xl\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .xl\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .xl\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .xl\:bg-none {
    background-image: none;
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .xl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .xl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .xl\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .xl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .xl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .xl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .xl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .xl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .xl\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .xl\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .xl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .xl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .xl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .xl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .xl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .xl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .xl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .xl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .xl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .xl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .xl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .xl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .xl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .xl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .xl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .xl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .xl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .xl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .xl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .xl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .xl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .xl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .xl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .xl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .xl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .xl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .xl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .xl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .xl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .xl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .xl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .xl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .xl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .xl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .xl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .xl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .xl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .xl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .xl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .xl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .xl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .xl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .xl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .xl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .xl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .xl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .xl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .xl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .xl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .xl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .xl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .xl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .xl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .xl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .xl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .xl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .xl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .xl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .xl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .xl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .xl\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .xl\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .xl\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .xl\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .xl\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .xl\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .xl\:to-current {
    --tw-gradient-to: currentColor;
  }

  .xl\:to-black {
    --tw-gradient-to: #000;
  }

  .xl\:to-white {
    --tw-gradient-to: #fff;
  }

  .xl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .xl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .xl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .xl\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .xl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .xl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .xl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .xl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .xl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .xl\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .xl\:to-red {
    --tw-gradient-to: red;
  }

  .xl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .xl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .xl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .xl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .xl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .xl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .xl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .xl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .xl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .xl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .xl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .xl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .xl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .xl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .xl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .xl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .xl\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .xl\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .xl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .xl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .xl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .xl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .xl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .xl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .xl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .xl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .xl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .xl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .xl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .xl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .xl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .xl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .xl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .xl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .xl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .xl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .xl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .xl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .xl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .xl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .xl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .xl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .xl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .xl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .xl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .xl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .xl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .xl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .xl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .xl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .xl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .xl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .xl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .xl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .xl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .xl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .xl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .xl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .xl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .xl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .xl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .xl\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .xl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .xl\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .xl\:bg-auto {
    background-size: auto;
  }

  .xl\:bg-cover {
    background-size: cover;
  }

  .xl\:bg-contain {
    background-size: contain;
  }

  .xl\:bg-fixed {
    background-attachment: fixed;
  }

  .xl\:bg-local {
    background-attachment: local;
  }

  .xl\:bg-scroll {
    background-attachment: scroll;
  }

  .xl\:bg-clip-border {
    background-clip: border-box;
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box;
  }

  .xl\:bg-clip-content {
    background-clip: content-box;
  }

  .xl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .xl\:bg-bottom {
    background-position: bottom;
  }

  .xl\:bg-center {
    background-position: center;
  }

  .xl\:bg-left {
    background-position: 0;
  }

  .xl\:bg-left-bottom {
    background-position: 0 100%;
  }

  .xl\:bg-left-top {
    background-position: 0 0;
  }

  .xl\:bg-right {
    background-position: 100%;
  }

  .xl\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .xl\:bg-right-top {
    background-position: 100% 0;
  }

  .xl\:bg-top {
    background-position: top;
  }

  .xl\:bg-repeat {
    background-repeat: repeat;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:bg-repeat-round {
    background-repeat: round;
  }

  .xl\:bg-repeat-space {
    background-repeat: space;
  }

  .xl\:bg-origin-border {
    background-origin: border-box;
  }

  .xl\:bg-origin-padding {
    background-origin: padding-box;
  }

  .xl\:bg-origin-content {
    background-origin: content-box;
  }

  .xl\:fill-current {
    fill: currentColor;
  }

  .xl\:stroke-current {
    stroke: currentColor;
  }

  .xl\:stroke-0 {
    stroke-width: 0;
  }

  .xl\:stroke-1 {
    stroke-width: 1px;
  }

  .xl\:stroke-2 {
    stroke-width: 2px;
  }

  .xl\:object-contain {
    object-fit: contain;
  }

  .xl\:object-cover {
    object-fit: cover;
  }

  .xl\:object-fill {
    object-fit: fill;
  }

  .xl\:object-none {
    object-fit: none;
  }

  .xl\:object-scale-down {
    object-fit: scale-down;
  }

  .xl\:object-bottom {
    object-position: bottom;
  }

  .xl\:object-center {
    object-position: center;
  }

  .xl\:object-left {
    object-position: left;
  }

  .xl\:object-left-bottom {
    object-position: left bottom;
  }

  .xl\:object-left-top {
    object-position: left top;
  }

  .xl\:object-right {
    object-position: right;
  }

  .xl\:object-right-bottom {
    object-position: right bottom;
  }

  .xl\:object-right-top {
    object-position: right top;
  }

  .xl\:object-top {
    object-position: top;
  }

  .xl\:p-0 {
    padding: 0;
  }

  .xl\:p-1 {
    padding: .25rem;
  }

  .xl\:p-2 {
    padding: .5rem;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:p-5 {
    padding: 1.25rem;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:p-7 {
    padding: 1.75rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:p-9 {
    padding: 2.25rem;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:p-14 {
    padding: 3.5rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-20 {
    padding: 5rem;
  }

  .xl\:p-24 {
    padding: 6rem;
  }

  .xl\:p-28 {
    padding: 7rem;
  }

  .xl\:p-32 {
    padding: 8rem;
  }

  .xl\:p-36 {
    padding: 9rem;
  }

  .xl\:p-40 {
    padding: 10rem;
  }

  .xl\:p-44 {
    padding: 11rem;
  }

  .xl\:p-48 {
    padding: 12rem;
  }

  .xl\:p-52 {
    padding: 13rem;
  }

  .xl\:p-56 {
    padding: 14rem;
  }

  .xl\:p-60 {
    padding: 15rem;
  }

  .xl\:p-64 {
    padding: 16rem;
  }

  .xl\:p-72 {
    padding: 18rem;
  }

  .xl\:p-80 {
    padding: 20rem;
  }

  .xl\:p-96 {
    padding: 24rem;
  }

  .xl\:p-px {
    padding: 1px;
  }

  .xl\:p-0\.5 {
    padding: .125rem;
  }

  .xl\:p-1\.5 {
    padding: .375rem;
  }

  .xl\:p-2\.5 {
    padding: .625rem;
  }

  .xl\:p-3\.5 {
    padding: .875rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .xl\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .xl\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .xl\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .xl\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .xl\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .xl\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:pt-1 {
    padding-top: .25rem;
  }

  .xl\:pt-2 {
    padding-top: .5rem;
  }

  .xl\:pt-3 {
    padding-top: .75rem;
  }

  .xl\:pt-4 {
    padding-top: 1rem;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:pt-7 {
    padding-top: 1.75rem;
  }

  .xl\:pt-8 {
    padding-top: 2rem;
  }

  .xl\:pt-9 {
    padding-top: 2.25rem;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:pt-11 {
    padding-top: 2.75rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }

  .xl\:pt-14 {
    padding-top: 3.5rem;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }

  .xl\:pt-20 {
    padding-top: 5rem;
  }

  .xl\:pt-24 {
    padding-top: 6rem;
  }

  .xl\:pt-28 {
    padding-top: 7rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:pt-36 {
    padding-top: 9rem;
  }

  .xl\:pt-40 {
    padding-top: 10rem;
  }

  .xl\:pt-44 {
    padding-top: 11rem;
  }

  .xl\:pt-48 {
    padding-top: 12rem;
  }

  .xl\:pt-52 {
    padding-top: 13rem;
  }

  .xl\:pt-56 {
    padding-top: 14rem;
  }

  .xl\:pt-60 {
    padding-top: 15rem;
  }

  .xl\:pt-64 {
    padding-top: 16rem;
  }

  .xl\:pt-72 {
    padding-top: 18rem;
  }

  .xl\:pt-80 {
    padding-top: 20rem;
  }

  .xl\:pt-96 {
    padding-top: 24rem;
  }

  .xl\:pt-px {
    padding-top: 1px;
  }

  .xl\:pt-0\.5 {
    padding-top: .125rem;
  }

  .xl\:pt-1\.5 {
    padding-top: .375rem;
  }

  .xl\:pt-2\.5 {
    padding-top: .625rem;
  }

  .xl\:pt-3\.5 {
    padding-top: .875rem;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pr-1 {
    padding-right: .25rem;
  }

  .xl\:pr-2 {
    padding-right: .5rem;
  }

  .xl\:pr-3 {
    padding-right: .75rem;
  }

  .xl\:pr-4 {
    padding-right: 1rem;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:pr-7 {
    padding-right: 1.75rem;
  }

  .xl\:pr-8 {
    padding-right: 2rem;
  }

  .xl\:pr-9 {
    padding-right: 2.25rem;
  }

  .xl\:pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:pr-11 {
    padding-right: 2.75rem;
  }

  .xl\:pr-12 {
    padding-right: 3rem;
  }

  .xl\:pr-14 {
    padding-right: 3.5rem;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:pr-20 {
    padding-right: 5rem;
  }

  .xl\:pr-24 {
    padding-right: 6rem;
  }

  .xl\:pr-28 {
    padding-right: 7rem;
  }

  .xl\:pr-32 {
    padding-right: 8rem;
  }

  .xl\:pr-36 {
    padding-right: 9rem;
  }

  .xl\:pr-40 {
    padding-right: 10rem;
  }

  .xl\:pr-44 {
    padding-right: 11rem;
  }

  .xl\:pr-48 {
    padding-right: 12rem;
  }

  .xl\:pr-52 {
    padding-right: 13rem;
  }

  .xl\:pr-56 {
    padding-right: 14rem;
  }

  .xl\:pr-60 {
    padding-right: 15rem;
  }

  .xl\:pr-64 {
    padding-right: 16rem;
  }

  .xl\:pr-72 {
    padding-right: 18rem;
  }

  .xl\:pr-80 {
    padding-right: 20rem;
  }

  .xl\:pr-96 {
    padding-right: 24rem;
  }

  .xl\:pr-px {
    padding-right: 1px;
  }

  .xl\:pr-0\.5 {
    padding-right: .125rem;
  }

  .xl\:pr-1\.5 {
    padding-right: .375rem;
  }

  .xl\:pr-2\.5 {
    padding-right: .625rem;
  }

  .xl\:pr-3\.5 {
    padding-right: .875rem;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pb-1 {
    padding-bottom: .25rem;
  }

  .xl\:pb-2 {
    padding-bottom: .5rem;
  }

  .xl\:pb-3 {
    padding-bottom: .75rem;
  }

  .xl\:pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:pb-28 {
    padding-bottom: 7rem;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:pb-36 {
    padding-bottom: 9rem;
  }

  .xl\:pb-40 {
    padding-bottom: 10rem;
  }

  .xl\:pb-44 {
    padding-bottom: 11rem;
  }

  .xl\:pb-48 {
    padding-bottom: 12rem;
  }

  .xl\:pb-52 {
    padding-bottom: 13rem;
  }

  .xl\:pb-56 {
    padding-bottom: 14rem;
  }

  .xl\:pb-60 {
    padding-bottom: 15rem;
  }

  .xl\:pb-64 {
    padding-bottom: 16rem;
  }

  .xl\:pb-72 {
    padding-bottom: 18rem;
  }

  .xl\:pb-80 {
    padding-bottom: 20rem;
  }

  .xl\:pb-96 {
    padding-bottom: 24rem;
  }

  .xl\:pb-px {
    padding-bottom: 1px;
  }

  .xl\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .xl\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .xl\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .xl\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:pl-1 {
    padding-left: .25rem;
  }

  .xl\:pl-2 {
    padding-left: .5rem;
  }

  .xl\:pl-3 {
    padding-left: .75rem;
  }

  .xl\:pl-4 {
    padding-left: 1rem;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:pl-7 {
    padding-left: 1.75rem;
  }

  .xl\:pl-8 {
    padding-left: 2rem;
  }

  .xl\:pl-9 {
    padding-left: 2.25rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:pl-11 {
    padding-left: 2.75rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-14 {
    padding-left: 3.5rem;
  }

  .xl\:pl-16 {
    padding-left: 4rem;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pl-24 {
    padding-left: 6rem;
  }

  .xl\:pl-28 {
    padding-left: 7rem;
  }

  .xl\:pl-32 {
    padding-left: 8rem;
  }

  .xl\:pl-36 {
    padding-left: 9rem;
  }

  .xl\:pl-40 {
    padding-left: 10rem;
  }

  .xl\:pl-44 {
    padding-left: 11rem;
  }

  .xl\:pl-48 {
    padding-left: 12rem;
  }

  .xl\:pl-52 {
    padding-left: 13rem;
  }

  .xl\:pl-56 {
    padding-left: 14rem;
  }

  .xl\:pl-60 {
    padding-left: 15rem;
  }

  .xl\:pl-64 {
    padding-left: 16rem;
  }

  .xl\:pl-72 {
    padding-left: 18rem;
  }

  .xl\:pl-80 {
    padding-left: 20rem;
  }

  .xl\:pl-96 {
    padding-left: 24rem;
  }

  .xl\:pl-px {
    padding-left: 1px;
  }

  .xl\:pl-0\.5 {
    padding-left: .125rem;
  }

  .xl\:pl-1\.5 {
    padding-left: .375rem;
  }

  .xl\:pl-2\.5 {
    padding-left: .625rem;
  }

  .xl\:pl-3\.5 {
    padding-left: .875rem;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:text-justify {
    text-align: justify;
  }

  .xl\:align-baseline {
    vertical-align: baseline;
  }

  .xl\:align-top {
    vertical-align: top;
  }

  .xl\:align-middle {
    vertical-align: middle;
  }

  .xl\:align-bottom {
    vertical-align: bottom;
  }

  .xl\:align-text-top {
    vertical-align: text-top;
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:font-body {
    font-family: Montserrat;
  }

  .xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .xl\:font-thin {
    font-weight: 100;
  }

  .xl\:font-extralight {
    font-weight: 200;
  }

  .xl\:font-light {
    font-weight: 300;
  }

  .xl\:font-normal {
    font-weight: 400;
  }

  .xl\:font-medium {
    font-weight: 500;
  }

  .xl\:font-semibold {
    font-weight: 600;
  }

  .xl\:font-bold {
    font-weight: 700;
  }

  .xl\:font-extrabold {
    font-weight: 800;
  }

  .xl\:font-black {
    font-weight: 900;
  }

  .xl\:uppercase {
    text-transform: uppercase;
  }

  .xl\:lowercase {
    text-transform: lowercase;
  }

  .xl\:capitalize {
    text-transform: capitalize;
  }

  .xl\:normal-case {
    text-transform: none;
  }

  .xl\:italic {
    font-style: italic;
  }

  .xl\:not-italic {
    font-style: normal;
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:normal-nums {
    font-variant-numeric: normal;
  }

  .xl\:ordinal {
    --tw-ordinal: ordinal;
  }

  .xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .xl\:leading-3 {
    line-height: .75rem;
  }

  .xl\:leading-4 {
    line-height: 1rem;
  }

  .xl\:leading-5 {
    line-height: 1.25rem;
  }

  .xl\:leading-6 {
    line-height: 1.5rem;
  }

  .xl\:leading-7 {
    line-height: 1.75rem;
  }

  .xl\:leading-8 {
    line-height: 2rem;
  }

  .xl\:leading-9 {
    line-height: 2.25rem;
  }

  .xl\:leading-10 {
    line-height: 2.5rem;
  }

  .xl\:leading-none {
    line-height: 1;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:leading-snug {
    line-height: 1.375;
  }

  .xl\:leading-normal {
    line-height: 1.5;
  }

  .xl\:leading-relaxed {
    line-height: 1.625;
  }

  .xl\:leading-loose {
    line-height: 2;
  }

  .xl\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .xl\:tracking-tight {
    letter-spacing: -.025em;
  }

  .xl\:tracking-normal {
    letter-spacing: 0;
  }

  .xl\:tracking-wide {
    letter-spacing: .025em;
  }

  .xl\:tracking-wider {
    letter-spacing: .05em;
  }

  .xl\:tracking-widest {
    letter-spacing: .1em;
  }

  .xl\:text-transparent {
    color: #0000;
  }

  .xl\:text-current {
    color: currentColor;
  }

  .xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .xl\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .xl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .xl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:hover\:text-transparent:hover {
    color: #0000;
  }

  .xl\:hover\:text-current:hover {
    color: currentColor;
  }

  .xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:focus\:text-transparent:focus {
    color: #0000;
  }

  .xl\:focus\:text-current:focus {
    color: currentColor;
  }

  .xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xl\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .xl\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .xl\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .xl\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .xl\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .xl\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .xl\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .xl\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .xl\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .xl\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .xl\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .xl\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .xl\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .xl\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .xl\:underline {
    text-decoration: underline;
  }

  .xl\:line-through {
    text-decoration: line-through;
  }

  .xl\:no-underline {
    text-decoration: none;
  }

  .group:hover .xl\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .xl\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .xl\:group-hover\:no-underline {
    text-decoration: none;
  }

  .xl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .xl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .xl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline;
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline;
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .xl\:placeholder-current::placeholder {
    color: currentColor;
  }

  .xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:opacity-5 {
    opacity: .05;
  }

  .xl\:opacity-10 {
    opacity: .1;
  }

  .xl\:opacity-20 {
    opacity: .2;
  }

  .xl\:opacity-25 {
    opacity: .25;
  }

  .xl\:opacity-30 {
    opacity: .3;
  }

  .xl\:opacity-40 {
    opacity: .4;
  }

  .xl\:opacity-50 {
    opacity: .5;
  }

  .xl\:opacity-60 {
    opacity: .6;
  }

  .xl\:opacity-70 {
    opacity: .7;
  }

  .xl\:opacity-75 {
    opacity: .75;
  }

  .xl\:opacity-80 {
    opacity: .8;
  }

  .xl\:opacity-90 {
    opacity: .9;
  }

  .xl\:opacity-95 {
    opacity: .95;
  }

  .xl\:opacity-100 {
    opacity: 1;
  }

  .group:hover .xl\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .xl\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .xl\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .xl\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .xl\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .xl\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .xl\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .xl\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .xl\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .xl\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .xl\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .xl\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .xl\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .xl\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .xl\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .xl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .xl\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .xl\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .xl\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .xl\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .xl\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .xl\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .xl\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .xl\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .xl\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .xl\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .xl\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .xl\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .xl\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .xl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .xl\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .xl\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .xl\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .xl\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .xl\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .xl\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .xl\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .xl\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .xl\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .xl\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .xl\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .xl\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .xl\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .xl\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .xl\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .xl\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .xl\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .xl\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .xl\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .xl\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .xl\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .xl\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .xl\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .xl\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .xl\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .xl\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .xl\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .xl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .xl\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .xl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .xl\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .xl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .xl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .xl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .xl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .xl\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .xl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .xl\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .xl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .xl\:bg-blend-color {
    background-blend-mode: color;
  }

  .xl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .xl\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .xl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .xl\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .xl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .xl\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .xl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .xl\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .xl\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .xl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .xl\:mix-blend-color {
    mix-blend-mode: color;
  }

  .xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xl\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xl\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xl\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xl\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xl\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xl\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xl\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xl\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-inset, .xl\:focus-within\:ring-inset:focus-within, .xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .xl\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .xl\:ring-current {
    --tw-ring-color: currentColor;
  }

  .xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xl\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .xl\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .xl\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .xl\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .xl\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .xl\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .xl\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .xl\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .xl\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .xl\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .xl\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .xl\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .xl\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .xl\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .xl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .xl\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .xl\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .xl\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .xl\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:filter-none {
    filter: none;
  }

  .xl\:blur-0, .xl\:blur-none {
    --tw-blur: blur(0);
  }

  .xl\:blur-sm {
    --tw-blur: blur(4px);
  }

  .xl\:blur {
    --tw-blur: blur(8px);
  }

  .xl\:blur-md {
    --tw-blur: blur(12px);
  }

  .xl\:blur-lg {
    --tw-blur: blur(16px);
  }

  .xl\:blur-xl {
    --tw-blur: blur(24px);
  }

  .xl\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .xl\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .xl\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .xl\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .xl\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .xl\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .xl\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .xl\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .xl\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .xl\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .xl\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .xl\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .xl\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .xl\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .xl\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .xl\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .xl\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .xl\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .xl\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .xl\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .xl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .xl\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:invert-0 {
    --tw-invert: invert(0);
  }

  .xl\:invert {
    --tw-invert: invert(100%);
  }

  .xl\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .xl\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .xl\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .xl\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .xl\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .xl\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .xl\:sepia {
    --tw-sepia: sepia(100%);
  }

  .xl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .xl\:backdrop-blur-0, .xl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .xl\:transition-none {
    transition-property: none;
  }

  .xl\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:delay-75 {
    transition-delay: 75ms;
  }

  .xl\:delay-100 {
    transition-delay: .1s;
  }

  .xl\:delay-150 {
    transition-delay: .15s;
  }

  .xl\:delay-200 {
    transition-delay: .2s;
  }

  .xl\:delay-300 {
    transition-delay: .3s;
  }

  .xl\:delay-500 {
    transition-delay: .5s;
  }

  .xl\:delay-700 {
    transition-delay: .7s;
  }

  .xl\:delay-1000 {
    transition-delay: 1s;
  }

  .xl\:duration-75 {
    transition-duration: 75ms;
  }

  .xl\:duration-100 {
    transition-duration: .1s;
  }

  .xl\:duration-150 {
    transition-duration: .15s;
  }

  .xl\:duration-200 {
    transition-duration: .2s;
  }

  .xl\:duration-300 {
    transition-duration: .3s;
  }

  .xl\:duration-500 {
    transition-duration: .5s;
  }

  .xl\:duration-700 {
    transition-duration: .7s;
  }

  .xl\:duration-1000 {
    transition-duration: 1s;
  }

  .xl\:ease-linear {
    transition-timing-function: linear;
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

@media (min-width: 1560px) {
  .xxl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xxl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xxl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xxl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xxl\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1560px) {
    .xxl\:container {
      max-width: 1560px;
    }
  }

  .xxl\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xxl\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xxl\:focus-within\:sr-only:focus-within {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xxl\:focus-within\:not-sr-only:focus-within {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xxl\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xxl\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xxl\:pointer-events-none {
    pointer-events: none;
  }

  .xxl\:pointer-events-auto {
    pointer-events: auto;
  }

  .xxl\:visible {
    visibility: visible;
  }

  .xxl\:invisible {
    visibility: hidden;
  }

  .xxl\:static {
    position: static;
  }

  .xxl\:fixed {
    position: fixed;
  }

  .xxl\:absolute {
    position: absolute;
  }

  .xxl\:relative {
    position: relative;
  }

  .xxl\:sticky {
    position: sticky;
  }

  .xxl\:inset-0 {
    inset: 0;
  }

  .xxl\:inset-1 {
    inset: .25rem;
  }

  .xxl\:inset-2 {
    inset: .5rem;
  }

  .xxl\:inset-3 {
    inset: .75rem;
  }

  .xxl\:inset-4 {
    inset: 1rem;
  }

  .xxl\:inset-5 {
    inset: 1.25rem;
  }

  .xxl\:inset-6 {
    inset: 1.5rem;
  }

  .xxl\:inset-7 {
    inset: 1.75rem;
  }

  .xxl\:inset-8 {
    inset: 2rem;
  }

  .xxl\:inset-9 {
    inset: 2.25rem;
  }

  .xxl\:inset-10 {
    inset: 2.5rem;
  }

  .xxl\:inset-11 {
    inset: 2.75rem;
  }

  .xxl\:inset-12 {
    inset: 3rem;
  }

  .xxl\:inset-14 {
    inset: 3.5rem;
  }

  .xxl\:inset-16 {
    inset: 4rem;
  }

  .xxl\:inset-20 {
    inset: 5rem;
  }

  .xxl\:inset-24 {
    inset: 6rem;
  }

  .xxl\:inset-28 {
    inset: 7rem;
  }

  .xxl\:inset-32 {
    inset: 8rem;
  }

  .xxl\:inset-36 {
    inset: 9rem;
  }

  .xxl\:inset-40 {
    inset: 10rem;
  }

  .xxl\:inset-44 {
    inset: 11rem;
  }

  .xxl\:inset-48 {
    inset: 12rem;
  }

  .xxl\:inset-52 {
    inset: 13rem;
  }

  .xxl\:inset-56 {
    inset: 14rem;
  }

  .xxl\:inset-60 {
    inset: 15rem;
  }

  .xxl\:inset-64 {
    inset: 16rem;
  }

  .xxl\:inset-72 {
    inset: 18rem;
  }

  .xxl\:inset-80 {
    inset: 20rem;
  }

  .xxl\:inset-96 {
    inset: 24rem;
  }

  .xxl\:inset-auto {
    inset: auto;
  }

  .xxl\:inset-px {
    inset: 1px;
  }

  .xxl\:inset-0\.5 {
    inset: .125rem;
  }

  .xxl\:inset-1\.5 {
    inset: .375rem;
  }

  .xxl\:inset-2\.5 {
    inset: .625rem;
  }

  .xxl\:inset-3\.5 {
    inset: .875rem;
  }

  .xxl\:-inset-0 {
    inset: 0;
  }

  .xxl\:-inset-1 {
    inset: -.25rem;
  }

  .xxl\:-inset-2 {
    inset: -.5rem;
  }

  .xxl\:-inset-3 {
    inset: -.75rem;
  }

  .xxl\:-inset-4 {
    inset: -1rem;
  }

  .xxl\:-inset-5 {
    inset: -1.25rem;
  }

  .xxl\:-inset-6 {
    inset: -1.5rem;
  }

  .xxl\:-inset-7 {
    inset: -1.75rem;
  }

  .xxl\:-inset-8 {
    inset: -2rem;
  }

  .xxl\:-inset-9 {
    inset: -2.25rem;
  }

  .xxl\:-inset-10 {
    inset: -2.5rem;
  }

  .xxl\:-inset-11 {
    inset: -2.75rem;
  }

  .xxl\:-inset-12 {
    inset: -3rem;
  }

  .xxl\:-inset-14 {
    inset: -3.5rem;
  }

  .xxl\:-inset-16 {
    inset: -4rem;
  }

  .xxl\:-inset-20 {
    inset: -5rem;
  }

  .xxl\:-inset-24 {
    inset: -6rem;
  }

  .xxl\:-inset-28 {
    inset: -7rem;
  }

  .xxl\:-inset-32 {
    inset: -8rem;
  }

  .xxl\:-inset-36 {
    inset: -9rem;
  }

  .xxl\:-inset-40 {
    inset: -10rem;
  }

  .xxl\:-inset-44 {
    inset: -11rem;
  }

  .xxl\:-inset-48 {
    inset: -12rem;
  }

  .xxl\:-inset-52 {
    inset: -13rem;
  }

  .xxl\:-inset-56 {
    inset: -14rem;
  }

  .xxl\:-inset-60 {
    inset: -15rem;
  }

  .xxl\:-inset-64 {
    inset: -16rem;
  }

  .xxl\:-inset-72 {
    inset: -18rem;
  }

  .xxl\:-inset-80 {
    inset: -20rem;
  }

  .xxl\:-inset-96 {
    inset: -24rem;
  }

  .xxl\:-inset-px {
    inset: -1px;
  }

  .xxl\:-inset-0\.5 {
    inset: -.125rem;
  }

  .xxl\:-inset-1\.5 {
    inset: -.375rem;
  }

  .xxl\:-inset-2\.5 {
    inset: -.625rem;
  }

  .xxl\:-inset-3\.5 {
    inset: -.875rem;
  }

  .xxl\:inset-1\/2 {
    inset: 50%;
  }

  .xxl\:inset-1\/3 {
    inset: 33.3333%;
  }

  .xxl\:inset-2\/3 {
    inset: 66.6667%;
  }

  .xxl\:inset-1\/4 {
    inset: 25%;
  }

  .xxl\:inset-2\/4 {
    inset: 50%;
  }

  .xxl\:inset-3\/4 {
    inset: 75%;
  }

  .xxl\:inset-full {
    inset: 100%;
  }

  .xxl\:-inset-1\/2 {
    inset: -50%;
  }

  .xxl\:-inset-1\/3 {
    inset: -33.3333%;
  }

  .xxl\:-inset-2\/3 {
    inset: -66.6667%;
  }

  .xxl\:-inset-1\/4 {
    inset: -25%;
  }

  .xxl\:-inset-2\/4 {
    inset: -50%;
  }

  .xxl\:-inset-3\/4 {
    inset: -75%;
  }

  .xxl\:-inset-full {
    inset: -100%;
  }

  .xxl\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .xxl\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .xxl\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .xxl\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .xxl\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .xxl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .xxl\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .xxl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .xxl\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .xxl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .xxl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .xxl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .xxl\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .xxl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .xxl\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .xxl\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .xxl\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .xxl\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .xxl\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .xxl\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .xxl\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .xxl\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .xxl\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .xxl\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .xxl\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .xxl\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .xxl\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .xxl\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .xxl\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .xxl\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .xxl\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .xxl\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .xxl\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .xxl\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .xxl\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .xxl\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .xxl\:-inset-x-0 {
    left: 0;
    right: 0;
  }

  .xxl\:-inset-x-1 {
    left: -.25rem;
    right: -.25rem;
  }

  .xxl\:-inset-x-2 {
    left: -.5rem;
    right: -.5rem;
  }

  .xxl\:-inset-x-3 {
    left: -.75rem;
    right: -.75rem;
  }

  .xxl\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }

  .xxl\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }

  .xxl\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }

  .xxl\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }

  .xxl\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }

  .xxl\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }

  .xxl\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }

  .xxl\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }

  .xxl\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }

  .xxl\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }

  .xxl\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }

  .xxl\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }

  .xxl\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }

  .xxl\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }

  .xxl\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .xxl\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }

  .xxl\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }

  .xxl\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }

  .xxl\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }

  .xxl\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }

  .xxl\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }

  .xxl\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }

  .xxl\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }

  .xxl\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }

  .xxl\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }

  .xxl\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }

  .xxl\:-inset-x-px {
    left: -1px;
    right: -1px;
  }

  .xxl\:-inset-x-0\.5 {
    left: -.125rem;
    right: -.125rem;
  }

  .xxl\:-inset-x-1\.5 {
    left: -.375rem;
    right: -.375rem;
  }

  .xxl\:-inset-x-2\.5 {
    left: -.625rem;
    right: -.625rem;
  }

  .xxl\:-inset-x-3\.5 {
    left: -.875rem;
    right: -.875rem;
  }

  .xxl\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .xxl\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .xxl\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .xxl\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .xxl\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .xxl\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .xxl\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .xxl\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }

  .xxl\:-inset-x-1\/3 {
    left: -33.3333%;
    right: -33.3333%;
  }

  .xxl\:-inset-x-2\/3 {
    left: -66.6667%;
    right: -66.6667%;
  }

  .xxl\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }

  .xxl\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }

  .xxl\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }

  .xxl\:-inset-x-full {
    left: -100%;
    right: -100%;
  }

  .xxl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xxl\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .xxl\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .xxl\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .xxl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .xxl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .xxl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .xxl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .xxl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .xxl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .xxl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .xxl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .xxl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .xxl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .xxl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .xxl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .xxl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .xxl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .xxl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .xxl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .xxl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .xxl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .xxl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .xxl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .xxl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .xxl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .xxl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .xxl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .xxl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .xxl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .xxl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xxl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .xxl\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .xxl\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .xxl\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .xxl\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .xxl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xxl\:-inset-y-1 {
    top: -.25rem;
    bottom: -.25rem;
  }

  .xxl\:-inset-y-2 {
    top: -.5rem;
    bottom: -.5rem;
  }

  .xxl\:-inset-y-3 {
    top: -.75rem;
    bottom: -.75rem;
  }

  .xxl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }

  .xxl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }

  .xxl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }

  .xxl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }

  .xxl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }

  .xxl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }

  .xxl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }

  .xxl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }

  .xxl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }

  .xxl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }

  .xxl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }

  .xxl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }

  .xxl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }

  .xxl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }

  .xxl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }

  .xxl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }

  .xxl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }

  .xxl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }

  .xxl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }

  .xxl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }

  .xxl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }

  .xxl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }

  .xxl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }

  .xxl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }

  .xxl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }

  .xxl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }

  .xxl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }

  .xxl\:-inset-y-0\.5 {
    top: -.125rem;
    bottom: -.125rem;
  }

  .xxl\:-inset-y-1\.5 {
    top: -.375rem;
    bottom: -.375rem;
  }

  .xxl\:-inset-y-2\.5 {
    top: -.625rem;
    bottom: -.625rem;
  }

  .xxl\:-inset-y-3\.5 {
    top: -.875rem;
    bottom: -.875rem;
  }

  .xxl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .xxl\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .xxl\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .xxl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .xxl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .xxl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .xxl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .xxl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }

  .xxl\:-inset-y-1\/3 {
    top: -33.3333%;
    bottom: -33.3333%;
  }

  .xxl\:-inset-y-2\/3 {
    top: -66.6667%;
    bottom: -66.6667%;
  }

  .xxl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }

  .xxl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }

  .xxl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }

  .xxl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }

  .xxl\:top-0 {
    top: 0;
  }

  .xxl\:top-1 {
    top: .25rem;
  }

  .xxl\:top-2 {
    top: .5rem;
  }

  .xxl\:top-3 {
    top: .75rem;
  }

  .xxl\:top-4 {
    top: 1rem;
  }

  .xxl\:top-5 {
    top: 1.25rem;
  }

  .xxl\:top-6 {
    top: 1.5rem;
  }

  .xxl\:top-7 {
    top: 1.75rem;
  }

  .xxl\:top-8 {
    top: 2rem;
  }

  .xxl\:top-9 {
    top: 2.25rem;
  }

  .xxl\:top-10 {
    top: 2.5rem;
  }

  .xxl\:top-11 {
    top: 2.75rem;
  }

  .xxl\:top-12 {
    top: 3rem;
  }

  .xxl\:top-14 {
    top: 3.5rem;
  }

  .xxl\:top-16 {
    top: 4rem;
  }

  .xxl\:top-20 {
    top: 5rem;
  }

  .xxl\:top-24 {
    top: 6rem;
  }

  .xxl\:top-28 {
    top: 7rem;
  }

  .xxl\:top-32 {
    top: 8rem;
  }

  .xxl\:top-36 {
    top: 9rem;
  }

  .xxl\:top-40 {
    top: 10rem;
  }

  .xxl\:top-44 {
    top: 11rem;
  }

  .xxl\:top-48 {
    top: 12rem;
  }

  .xxl\:top-52 {
    top: 13rem;
  }

  .xxl\:top-56 {
    top: 14rem;
  }

  .xxl\:top-60 {
    top: 15rem;
  }

  .xxl\:top-64 {
    top: 16rem;
  }

  .xxl\:top-72 {
    top: 18rem;
  }

  .xxl\:top-80 {
    top: 20rem;
  }

  .xxl\:top-96 {
    top: 24rem;
  }

  .xxl\:top-auto {
    top: auto;
  }

  .xxl\:top-px {
    top: 1px;
  }

  .xxl\:top-0\.5 {
    top: .125rem;
  }

  .xxl\:top-1\.5 {
    top: .375rem;
  }

  .xxl\:top-2\.5 {
    top: .625rem;
  }

  .xxl\:top-3\.5 {
    top: .875rem;
  }

  .xxl\:-top-0 {
    top: 0;
  }

  .xxl\:-top-1 {
    top: -.25rem;
  }

  .xxl\:-top-2 {
    top: -.5rem;
  }

  .xxl\:-top-3 {
    top: -.75rem;
  }

  .xxl\:-top-4 {
    top: -1rem;
  }

  .xxl\:-top-5 {
    top: -1.25rem;
  }

  .xxl\:-top-6 {
    top: -1.5rem;
  }

  .xxl\:-top-7 {
    top: -1.75rem;
  }

  .xxl\:-top-8 {
    top: -2rem;
  }

  .xxl\:-top-9 {
    top: -2.25rem;
  }

  .xxl\:-top-10 {
    top: -2.5rem;
  }

  .xxl\:-top-11 {
    top: -2.75rem;
  }

  .xxl\:-top-12 {
    top: -3rem;
  }

  .xxl\:-top-14 {
    top: -3.5rem;
  }

  .xxl\:-top-16 {
    top: -4rem;
  }

  .xxl\:-top-20 {
    top: -5rem;
  }

  .xxl\:-top-24 {
    top: -6rem;
  }

  .xxl\:-top-28 {
    top: -7rem;
  }

  .xxl\:-top-32 {
    top: -8rem;
  }

  .xxl\:-top-36 {
    top: -9rem;
  }

  .xxl\:-top-40 {
    top: -10rem;
  }

  .xxl\:-top-44 {
    top: -11rem;
  }

  .xxl\:-top-48 {
    top: -12rem;
  }

  .xxl\:-top-52 {
    top: -13rem;
  }

  .xxl\:-top-56 {
    top: -14rem;
  }

  .xxl\:-top-60 {
    top: -15rem;
  }

  .xxl\:-top-64 {
    top: -16rem;
  }

  .xxl\:-top-72 {
    top: -18rem;
  }

  .xxl\:-top-80 {
    top: -20rem;
  }

  .xxl\:-top-96 {
    top: -24rem;
  }

  .xxl\:-top-px {
    top: -1px;
  }

  .xxl\:-top-0\.5 {
    top: -.125rem;
  }

  .xxl\:-top-1\.5 {
    top: -.375rem;
  }

  .xxl\:-top-2\.5 {
    top: -.625rem;
  }

  .xxl\:-top-3\.5 {
    top: -.875rem;
  }

  .xxl\:top-1\/2 {
    top: 50%;
  }

  .xxl\:top-1\/3 {
    top: 33.3333%;
  }

  .xxl\:top-2\/3 {
    top: 66.6667%;
  }

  .xxl\:top-1\/4 {
    top: 25%;
  }

  .xxl\:top-2\/4 {
    top: 50%;
  }

  .xxl\:top-3\/4 {
    top: 75%;
  }

  .xxl\:top-full {
    top: 100%;
  }

  .xxl\:-top-1\/2 {
    top: -50%;
  }

  .xxl\:-top-1\/3 {
    top: -33.3333%;
  }

  .xxl\:-top-2\/3 {
    top: -66.6667%;
  }

  .xxl\:-top-1\/4 {
    top: -25%;
  }

  .xxl\:-top-2\/4 {
    top: -50%;
  }

  .xxl\:-top-3\/4 {
    top: -75%;
  }

  .xxl\:-top-full {
    top: -100%;
  }

  .xxl\:right-0 {
    right: 0;
  }

  .xxl\:right-1 {
    right: .25rem;
  }

  .xxl\:right-2 {
    right: .5rem;
  }

  .xxl\:right-3 {
    right: .75rem;
  }

  .xxl\:right-4 {
    right: 1rem;
  }

  .xxl\:right-5 {
    right: 1.25rem;
  }

  .xxl\:right-6 {
    right: 1.5rem;
  }

  .xxl\:right-7 {
    right: 1.75rem;
  }

  .xxl\:right-8 {
    right: 2rem;
  }

  .xxl\:right-9 {
    right: 2.25rem;
  }

  .xxl\:right-10 {
    right: 2.5rem;
  }

  .xxl\:right-11 {
    right: 2.75rem;
  }

  .xxl\:right-12 {
    right: 3rem;
  }

  .xxl\:right-14 {
    right: 3.5rem;
  }

  .xxl\:right-16 {
    right: 4rem;
  }

  .xxl\:right-20 {
    right: 5rem;
  }

  .xxl\:right-24 {
    right: 6rem;
  }

  .xxl\:right-28 {
    right: 7rem;
  }

  .xxl\:right-32 {
    right: 8rem;
  }

  .xxl\:right-36 {
    right: 9rem;
  }

  .xxl\:right-40 {
    right: 10rem;
  }

  .xxl\:right-44 {
    right: 11rem;
  }

  .xxl\:right-48 {
    right: 12rem;
  }

  .xxl\:right-52 {
    right: 13rem;
  }

  .xxl\:right-56 {
    right: 14rem;
  }

  .xxl\:right-60 {
    right: 15rem;
  }

  .xxl\:right-64 {
    right: 16rem;
  }

  .xxl\:right-72 {
    right: 18rem;
  }

  .xxl\:right-80 {
    right: 20rem;
  }

  .xxl\:right-96 {
    right: 24rem;
  }

  .xxl\:right-auto {
    right: auto;
  }

  .xxl\:right-px {
    right: 1px;
  }

  .xxl\:right-0\.5 {
    right: .125rem;
  }

  .xxl\:right-1\.5 {
    right: .375rem;
  }

  .xxl\:right-2\.5 {
    right: .625rem;
  }

  .xxl\:right-3\.5 {
    right: .875rem;
  }

  .xxl\:-right-0 {
    right: 0;
  }

  .xxl\:-right-1 {
    right: -.25rem;
  }

  .xxl\:-right-2 {
    right: -.5rem;
  }

  .xxl\:-right-3 {
    right: -.75rem;
  }

  .xxl\:-right-4 {
    right: -1rem;
  }

  .xxl\:-right-5 {
    right: -1.25rem;
  }

  .xxl\:-right-6 {
    right: -1.5rem;
  }

  .xxl\:-right-7 {
    right: -1.75rem;
  }

  .xxl\:-right-8 {
    right: -2rem;
  }

  .xxl\:-right-9 {
    right: -2.25rem;
  }

  .xxl\:-right-10 {
    right: -2.5rem;
  }

  .xxl\:-right-11 {
    right: -2.75rem;
  }

  .xxl\:-right-12 {
    right: -3rem;
  }

  .xxl\:-right-14 {
    right: -3.5rem;
  }

  .xxl\:-right-16 {
    right: -4rem;
  }

  .xxl\:-right-20 {
    right: -5rem;
  }

  .xxl\:-right-24 {
    right: -6rem;
  }

  .xxl\:-right-28 {
    right: -7rem;
  }

  .xxl\:-right-32 {
    right: -8rem;
  }

  .xxl\:-right-36 {
    right: -9rem;
  }

  .xxl\:-right-40 {
    right: -10rem;
  }

  .xxl\:-right-44 {
    right: -11rem;
  }

  .xxl\:-right-48 {
    right: -12rem;
  }

  .xxl\:-right-52 {
    right: -13rem;
  }

  .xxl\:-right-56 {
    right: -14rem;
  }

  .xxl\:-right-60 {
    right: -15rem;
  }

  .xxl\:-right-64 {
    right: -16rem;
  }

  .xxl\:-right-72 {
    right: -18rem;
  }

  .xxl\:-right-80 {
    right: -20rem;
  }

  .xxl\:-right-96 {
    right: -24rem;
  }

  .xxl\:-right-px {
    right: -1px;
  }

  .xxl\:-right-0\.5 {
    right: -.125rem;
  }

  .xxl\:-right-1\.5 {
    right: -.375rem;
  }

  .xxl\:-right-2\.5 {
    right: -.625rem;
  }

  .xxl\:-right-3\.5 {
    right: -.875rem;
  }

  .xxl\:right-1\/2 {
    right: 50%;
  }

  .xxl\:right-1\/3 {
    right: 33.3333%;
  }

  .xxl\:right-2\/3 {
    right: 66.6667%;
  }

  .xxl\:right-1\/4 {
    right: 25%;
  }

  .xxl\:right-2\/4 {
    right: 50%;
  }

  .xxl\:right-3\/4 {
    right: 75%;
  }

  .xxl\:right-full {
    right: 100%;
  }

  .xxl\:-right-1\/2 {
    right: -50%;
  }

  .xxl\:-right-1\/3 {
    right: -33.3333%;
  }

  .xxl\:-right-2\/3 {
    right: -66.6667%;
  }

  .xxl\:-right-1\/4 {
    right: -25%;
  }

  .xxl\:-right-2\/4 {
    right: -50%;
  }

  .xxl\:-right-3\/4 {
    right: -75%;
  }

  .xxl\:-right-full {
    right: -100%;
  }

  .xxl\:bottom-0 {
    bottom: 0;
  }

  .xxl\:bottom-1 {
    bottom: .25rem;
  }

  .xxl\:bottom-2 {
    bottom: .5rem;
  }

  .xxl\:bottom-3 {
    bottom: .75rem;
  }

  .xxl\:bottom-4 {
    bottom: 1rem;
  }

  .xxl\:bottom-5 {
    bottom: 1.25rem;
  }

  .xxl\:bottom-6 {
    bottom: 1.5rem;
  }

  .xxl\:bottom-7 {
    bottom: 1.75rem;
  }

  .xxl\:bottom-8 {
    bottom: 2rem;
  }

  .xxl\:bottom-9 {
    bottom: 2.25rem;
  }

  .xxl\:bottom-10 {
    bottom: 2.5rem;
  }

  .xxl\:bottom-11 {
    bottom: 2.75rem;
  }

  .xxl\:bottom-12 {
    bottom: 3rem;
  }

  .xxl\:bottom-14 {
    bottom: 3.5rem;
  }

  .xxl\:bottom-16 {
    bottom: 4rem;
  }

  .xxl\:bottom-20 {
    bottom: 5rem;
  }

  .xxl\:bottom-24 {
    bottom: 6rem;
  }

  .xxl\:bottom-28 {
    bottom: 7rem;
  }

  .xxl\:bottom-32 {
    bottom: 8rem;
  }

  .xxl\:bottom-36 {
    bottom: 9rem;
  }

  .xxl\:bottom-40 {
    bottom: 10rem;
  }

  .xxl\:bottom-44 {
    bottom: 11rem;
  }

  .xxl\:bottom-48 {
    bottom: 12rem;
  }

  .xxl\:bottom-52 {
    bottom: 13rem;
  }

  .xxl\:bottom-56 {
    bottom: 14rem;
  }

  .xxl\:bottom-60 {
    bottom: 15rem;
  }

  .xxl\:bottom-64 {
    bottom: 16rem;
  }

  .xxl\:bottom-72 {
    bottom: 18rem;
  }

  .xxl\:bottom-80 {
    bottom: 20rem;
  }

  .xxl\:bottom-96 {
    bottom: 24rem;
  }

  .xxl\:bottom-auto {
    bottom: auto;
  }

  .xxl\:bottom-px {
    bottom: 1px;
  }

  .xxl\:bottom-0\.5 {
    bottom: .125rem;
  }

  .xxl\:bottom-1\.5 {
    bottom: .375rem;
  }

  .xxl\:bottom-2\.5 {
    bottom: .625rem;
  }

  .xxl\:bottom-3\.5 {
    bottom: .875rem;
  }

  .xxl\:-bottom-0 {
    bottom: 0;
  }

  .xxl\:-bottom-1 {
    bottom: -.25rem;
  }

  .xxl\:-bottom-2 {
    bottom: -.5rem;
  }

  .xxl\:-bottom-3 {
    bottom: -.75rem;
  }

  .xxl\:-bottom-4 {
    bottom: -1rem;
  }

  .xxl\:-bottom-5 {
    bottom: -1.25rem;
  }

  .xxl\:-bottom-6 {
    bottom: -1.5rem;
  }

  .xxl\:-bottom-7 {
    bottom: -1.75rem;
  }

  .xxl\:-bottom-8 {
    bottom: -2rem;
  }

  .xxl\:-bottom-9 {
    bottom: -2.25rem;
  }

  .xxl\:-bottom-10 {
    bottom: -2.5rem;
  }

  .xxl\:-bottom-11 {
    bottom: -2.75rem;
  }

  .xxl\:-bottom-12 {
    bottom: -3rem;
  }

  .xxl\:-bottom-14 {
    bottom: -3.5rem;
  }

  .xxl\:-bottom-16 {
    bottom: -4rem;
  }

  .xxl\:-bottom-20 {
    bottom: -5rem;
  }

  .xxl\:-bottom-24 {
    bottom: -6rem;
  }

  .xxl\:-bottom-28 {
    bottom: -7rem;
  }

  .xxl\:-bottom-32 {
    bottom: -8rem;
  }

  .xxl\:-bottom-36 {
    bottom: -9rem;
  }

  .xxl\:-bottom-40 {
    bottom: -10rem;
  }

  .xxl\:-bottom-44 {
    bottom: -11rem;
  }

  .xxl\:-bottom-48 {
    bottom: -12rem;
  }

  .xxl\:-bottom-52 {
    bottom: -13rem;
  }

  .xxl\:-bottom-56 {
    bottom: -14rem;
  }

  .xxl\:-bottom-60 {
    bottom: -15rem;
  }

  .xxl\:-bottom-64 {
    bottom: -16rem;
  }

  .xxl\:-bottom-72 {
    bottom: -18rem;
  }

  .xxl\:-bottom-80 {
    bottom: -20rem;
  }

  .xxl\:-bottom-96 {
    bottom: -24rem;
  }

  .xxl\:-bottom-px {
    bottom: -1px;
  }

  .xxl\:-bottom-0\.5 {
    bottom: -.125rem;
  }

  .xxl\:-bottom-1\.5 {
    bottom: -.375rem;
  }

  .xxl\:-bottom-2\.5 {
    bottom: -.625rem;
  }

  .xxl\:-bottom-3\.5 {
    bottom: -.875rem;
  }

  .xxl\:bottom-1\/2 {
    bottom: 50%;
  }

  .xxl\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .xxl\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .xxl\:bottom-1\/4 {
    bottom: 25%;
  }

  .xxl\:bottom-2\/4 {
    bottom: 50%;
  }

  .xxl\:bottom-3\/4 {
    bottom: 75%;
  }

  .xxl\:bottom-full {
    bottom: 100%;
  }

  .xxl\:-bottom-1\/2 {
    bottom: -50%;
  }

  .xxl\:-bottom-1\/3 {
    bottom: -33.3333%;
  }

  .xxl\:-bottom-2\/3 {
    bottom: -66.6667%;
  }

  .xxl\:-bottom-1\/4 {
    bottom: -25%;
  }

  .xxl\:-bottom-2\/4 {
    bottom: -50%;
  }

  .xxl\:-bottom-3\/4 {
    bottom: -75%;
  }

  .xxl\:-bottom-full {
    bottom: -100%;
  }

  .xxl\:left-0 {
    left: 0;
  }

  .xxl\:left-1 {
    left: .25rem;
  }

  .xxl\:left-2 {
    left: .5rem;
  }

  .xxl\:left-3 {
    left: .75rem;
  }

  .xxl\:left-4 {
    left: 1rem;
  }

  .xxl\:left-5 {
    left: 1.25rem;
  }

  .xxl\:left-6 {
    left: 1.5rem;
  }

  .xxl\:left-7 {
    left: 1.75rem;
  }

  .xxl\:left-8 {
    left: 2rem;
  }

  .xxl\:left-9 {
    left: 2.25rem;
  }

  .xxl\:left-10 {
    left: 2.5rem;
  }

  .xxl\:left-11 {
    left: 2.75rem;
  }

  .xxl\:left-12 {
    left: 3rem;
  }

  .xxl\:left-14 {
    left: 3.5rem;
  }

  .xxl\:left-16 {
    left: 4rem;
  }

  .xxl\:left-20 {
    left: 5rem;
  }

  .xxl\:left-24 {
    left: 6rem;
  }

  .xxl\:left-28 {
    left: 7rem;
  }

  .xxl\:left-32 {
    left: 8rem;
  }

  .xxl\:left-36 {
    left: 9rem;
  }

  .xxl\:left-40 {
    left: 10rem;
  }

  .xxl\:left-44 {
    left: 11rem;
  }

  .xxl\:left-48 {
    left: 12rem;
  }

  .xxl\:left-52 {
    left: 13rem;
  }

  .xxl\:left-56 {
    left: 14rem;
  }

  .xxl\:left-60 {
    left: 15rem;
  }

  .xxl\:left-64 {
    left: 16rem;
  }

  .xxl\:left-72 {
    left: 18rem;
  }

  .xxl\:left-80 {
    left: 20rem;
  }

  .xxl\:left-96 {
    left: 24rem;
  }

  .xxl\:left-auto {
    left: auto;
  }

  .xxl\:left-px {
    left: 1px;
  }

  .xxl\:left-0\.5 {
    left: .125rem;
  }

  .xxl\:left-1\.5 {
    left: .375rem;
  }

  .xxl\:left-2\.5 {
    left: .625rem;
  }

  .xxl\:left-3\.5 {
    left: .875rem;
  }

  .xxl\:-left-0 {
    left: 0;
  }

  .xxl\:-left-1 {
    left: -.25rem;
  }

  .xxl\:-left-2 {
    left: -.5rem;
  }

  .xxl\:-left-3 {
    left: -.75rem;
  }

  .xxl\:-left-4 {
    left: -1rem;
  }

  .xxl\:-left-5 {
    left: -1.25rem;
  }

  .xxl\:-left-6 {
    left: -1.5rem;
  }

  .xxl\:-left-7 {
    left: -1.75rem;
  }

  .xxl\:-left-8 {
    left: -2rem;
  }

  .xxl\:-left-9 {
    left: -2.25rem;
  }

  .xxl\:-left-10 {
    left: -2.5rem;
  }

  .xxl\:-left-11 {
    left: -2.75rem;
  }

  .xxl\:-left-12 {
    left: -3rem;
  }

  .xxl\:-left-14 {
    left: -3.5rem;
  }

  .xxl\:-left-16 {
    left: -4rem;
  }

  .xxl\:-left-20 {
    left: -5rem;
  }

  .xxl\:-left-24 {
    left: -6rem;
  }

  .xxl\:-left-28 {
    left: -7rem;
  }

  .xxl\:-left-32 {
    left: -8rem;
  }

  .xxl\:-left-36 {
    left: -9rem;
  }

  .xxl\:-left-40 {
    left: -10rem;
  }

  .xxl\:-left-44 {
    left: -11rem;
  }

  .xxl\:-left-48 {
    left: -12rem;
  }

  .xxl\:-left-52 {
    left: -13rem;
  }

  .xxl\:-left-56 {
    left: -14rem;
  }

  .xxl\:-left-60 {
    left: -15rem;
  }

  .xxl\:-left-64 {
    left: -16rem;
  }

  .xxl\:-left-72 {
    left: -18rem;
  }

  .xxl\:-left-80 {
    left: -20rem;
  }

  .xxl\:-left-96 {
    left: -24rem;
  }

  .xxl\:-left-px {
    left: -1px;
  }

  .xxl\:-left-0\.5 {
    left: -.125rem;
  }

  .xxl\:-left-1\.5 {
    left: -.375rem;
  }

  .xxl\:-left-2\.5 {
    left: -.625rem;
  }

  .xxl\:-left-3\.5 {
    left: -.875rem;
  }

  .xxl\:left-1\/2 {
    left: 50%;
  }

  .xxl\:left-1\/3 {
    left: 33.3333%;
  }

  .xxl\:left-2\/3 {
    left: 66.6667%;
  }

  .xxl\:left-1\/4 {
    left: 25%;
  }

  .xxl\:left-2\/4 {
    left: 50%;
  }

  .xxl\:left-3\/4 {
    left: 75%;
  }

  .xxl\:left-full {
    left: 100%;
  }

  .xxl\:-left-1\/2 {
    left: -50%;
  }

  .xxl\:-left-1\/3 {
    left: -33.3333%;
  }

  .xxl\:-left-2\/3 {
    left: -66.6667%;
  }

  .xxl\:-left-1\/4 {
    left: -25%;
  }

  .xxl\:-left-2\/4 {
    left: -50%;
  }

  .xxl\:-left-3\/4 {
    left: -75%;
  }

  .xxl\:-left-full {
    left: -100%;
  }

  .xxl\:isolate {
    isolation: isolate;
  }

  .xxl\:isolation-auto {
    isolation: auto;
  }

  .xxl\:z-0 {
    z-index: 0;
  }

  .xxl\:z-10 {
    z-index: 10;
  }

  .xxl\:z-20 {
    z-index: 20;
  }

  .xxl\:z-30 {
    z-index: 30;
  }

  .xxl\:z-40 {
    z-index: 40;
  }

  .xxl\:z-50 {
    z-index: 50;
  }

  .xxl\:z-auto {
    z-index: auto;
  }

  .xxl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .xxl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .xxl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .xxl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .xxl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .xxl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .xxl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .xxl\:focus\:z-0:focus {
    z-index: 0;
  }

  .xxl\:focus\:z-10:focus {
    z-index: 10;
  }

  .xxl\:focus\:z-20:focus {
    z-index: 20;
  }

  .xxl\:focus\:z-30:focus {
    z-index: 30;
  }

  .xxl\:focus\:z-40:focus {
    z-index: 40;
  }

  .xxl\:focus\:z-50:focus {
    z-index: 50;
  }

  .xxl\:focus\:z-auto:focus {
    z-index: auto;
  }

  .xxl\:order-1 {
    order: 1;
  }

  .xxl\:order-2 {
    order: 2;
  }

  .xxl\:order-3 {
    order: 3;
  }

  .xxl\:order-4 {
    order: 4;
  }

  .xxl\:order-5 {
    order: 5;
  }

  .xxl\:order-6 {
    order: 6;
  }

  .xxl\:order-7 {
    order: 7;
  }

  .xxl\:order-8 {
    order: 8;
  }

  .xxl\:order-9 {
    order: 9;
  }

  .xxl\:order-10 {
    order: 10;
  }

  .xxl\:order-11 {
    order: 11;
  }

  .xxl\:order-12 {
    order: 12;
  }

  .xxl\:order-first {
    order: -9999;
  }

  .xxl\:order-last {
    order: 9999;
  }

  .xxl\:order-none {
    order: 0;
  }

  .xxl\:col-auto {
    grid-column: auto;
  }

  .xxl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xxl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xxl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xxl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xxl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xxl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xxl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xxl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xxl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xxl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xxl\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xxl\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xxl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xxl\:col-start-1 {
    grid-column-start: 1;
  }

  .xxl\:col-start-2 {
    grid-column-start: 2;
  }

  .xxl\:col-start-3 {
    grid-column-start: 3;
  }

  .xxl\:col-start-4 {
    grid-column-start: 4;
  }

  .xxl\:col-start-5 {
    grid-column-start: 5;
  }

  .xxl\:col-start-6 {
    grid-column-start: 6;
  }

  .xxl\:col-start-7 {
    grid-column-start: 7;
  }

  .xxl\:col-start-8 {
    grid-column-start: 8;
  }

  .xxl\:col-start-9 {
    grid-column-start: 9;
  }

  .xxl\:col-start-10 {
    grid-column-start: 10;
  }

  .xxl\:col-start-11 {
    grid-column-start: 11;
  }

  .xxl\:col-start-12 {
    grid-column-start: 12;
  }

  .xxl\:col-start-13 {
    grid-column-start: 13;
  }

  .xxl\:col-start-auto {
    grid-column-start: auto;
  }

  .xxl\:col-end-1 {
    grid-column-end: 1;
  }

  .xxl\:col-end-2 {
    grid-column-end: 2;
  }

  .xxl\:col-end-3 {
    grid-column-end: 3;
  }

  .xxl\:col-end-4 {
    grid-column-end: 4;
  }

  .xxl\:col-end-5 {
    grid-column-end: 5;
  }

  .xxl\:col-end-6 {
    grid-column-end: 6;
  }

  .xxl\:col-end-7 {
    grid-column-end: 7;
  }

  .xxl\:col-end-8 {
    grid-column-end: 8;
  }

  .xxl\:col-end-9 {
    grid-column-end: 9;
  }

  .xxl\:col-end-10 {
    grid-column-end: 10;
  }

  .xxl\:col-end-11 {
    grid-column-end: 11;
  }

  .xxl\:col-end-12 {
    grid-column-end: 12;
  }

  .xxl\:col-end-13 {
    grid-column-end: 13;
  }

  .xxl\:col-end-auto {
    grid-column-end: auto;
  }

  .xxl\:row-auto {
    grid-row: auto;
  }

  .xxl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xxl\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xxl\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xxl\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xxl\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xxl\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xxl\:row-span-full {
    grid-row: 1 / -1;
  }

  .xxl\:row-start-1 {
    grid-row-start: 1;
  }

  .xxl\:row-start-2 {
    grid-row-start: 2;
  }

  .xxl\:row-start-3 {
    grid-row-start: 3;
  }

  .xxl\:row-start-4 {
    grid-row-start: 4;
  }

  .xxl\:row-start-5 {
    grid-row-start: 5;
  }

  .xxl\:row-start-6 {
    grid-row-start: 6;
  }

  .xxl\:row-start-7 {
    grid-row-start: 7;
  }

  .xxl\:row-start-auto {
    grid-row-start: auto;
  }

  .xxl\:row-end-1 {
    grid-row-end: 1;
  }

  .xxl\:row-end-2 {
    grid-row-end: 2;
  }

  .xxl\:row-end-3 {
    grid-row-end: 3;
  }

  .xxl\:row-end-4 {
    grid-row-end: 4;
  }

  .xxl\:row-end-5 {
    grid-row-end: 5;
  }

  .xxl\:row-end-6 {
    grid-row-end: 6;
  }

  .xxl\:row-end-7 {
    grid-row-end: 7;
  }

  .xxl\:row-end-auto {
    grid-row-end: auto;
  }

  .xxl\:float-right {
    float: right;
  }

  .xxl\:float-left {
    float: left;
  }

  .xxl\:float-none {
    float: none;
  }

  .xxl\:clear-left {
    clear: left;
  }

  .xxl\:clear-right {
    clear: right;
  }

  .xxl\:clear-both {
    clear: both;
  }

  .xxl\:clear-none {
    clear: none;
  }

  .xxl\:m-0 {
    margin: 0;
  }

  .xxl\:m-1 {
    margin: .25rem;
  }

  .xxl\:m-2 {
    margin: .5rem;
  }

  .xxl\:m-3 {
    margin: .75rem;
  }

  .xxl\:m-4 {
    margin: 1rem;
  }

  .xxl\:m-5 {
    margin: 1.25rem;
  }

  .xxl\:m-6 {
    margin: 1.5rem;
  }

  .xxl\:m-7 {
    margin: 1.75rem;
  }

  .xxl\:m-8 {
    margin: 2rem;
  }

  .xxl\:m-9 {
    margin: 2.25rem;
  }

  .xxl\:m-10 {
    margin: 2.5rem;
  }

  .xxl\:m-11 {
    margin: 2.75rem;
  }

  .xxl\:m-12 {
    margin: 3rem;
  }

  .xxl\:m-14 {
    margin: 3.5rem;
  }

  .xxl\:m-16 {
    margin: 4rem;
  }

  .xxl\:m-20 {
    margin: 5rem;
  }

  .xxl\:m-24 {
    margin: 6rem;
  }

  .xxl\:m-28 {
    margin: 7rem;
  }

  .xxl\:m-32 {
    margin: 8rem;
  }

  .xxl\:m-36 {
    margin: 9rem;
  }

  .xxl\:m-40 {
    margin: 10rem;
  }

  .xxl\:m-44 {
    margin: 11rem;
  }

  .xxl\:m-48 {
    margin: 12rem;
  }

  .xxl\:m-52 {
    margin: 13rem;
  }

  .xxl\:m-56 {
    margin: 14rem;
  }

  .xxl\:m-60 {
    margin: 15rem;
  }

  .xxl\:m-64 {
    margin: 16rem;
  }

  .xxl\:m-72 {
    margin: 18rem;
  }

  .xxl\:m-80 {
    margin: 20rem;
  }

  .xxl\:m-96 {
    margin: 24rem;
  }

  .xxl\:m-auto {
    margin: auto;
  }

  .xxl\:m-px {
    margin: 1px;
  }

  .xxl\:m-0\.5 {
    margin: .125rem;
  }

  .xxl\:m-1\.5 {
    margin: .375rem;
  }

  .xxl\:m-2\.5 {
    margin: .625rem;
  }

  .xxl\:m-3\.5 {
    margin: .875rem;
  }

  .xxl\:-m-0 {
    margin: 0;
  }

  .xxl\:-m-1 {
    margin: -.25rem;
  }

  .xxl\:-m-2 {
    margin: -.5rem;
  }

  .xxl\:-m-3 {
    margin: -.75rem;
  }

  .xxl\:-m-4 {
    margin: -1rem;
  }

  .xxl\:-m-5 {
    margin: -1.25rem;
  }

  .xxl\:-m-6 {
    margin: -1.5rem;
  }

  .xxl\:-m-7 {
    margin: -1.75rem;
  }

  .xxl\:-m-8 {
    margin: -2rem;
  }

  .xxl\:-m-9 {
    margin: -2.25rem;
  }

  .xxl\:-m-10 {
    margin: -2.5rem;
  }

  .xxl\:-m-11 {
    margin: -2.75rem;
  }

  .xxl\:-m-12 {
    margin: -3rem;
  }

  .xxl\:-m-14 {
    margin: -3.5rem;
  }

  .xxl\:-m-16 {
    margin: -4rem;
  }

  .xxl\:-m-20 {
    margin: -5rem;
  }

  .xxl\:-m-24 {
    margin: -6rem;
  }

  .xxl\:-m-28 {
    margin: -7rem;
  }

  .xxl\:-m-32 {
    margin: -8rem;
  }

  .xxl\:-m-36 {
    margin: -9rem;
  }

  .xxl\:-m-40 {
    margin: -10rem;
  }

  .xxl\:-m-44 {
    margin: -11rem;
  }

  .xxl\:-m-48 {
    margin: -12rem;
  }

  .xxl\:-m-52 {
    margin: -13rem;
  }

  .xxl\:-m-56 {
    margin: -14rem;
  }

  .xxl\:-m-60 {
    margin: -15rem;
  }

  .xxl\:-m-64 {
    margin: -16rem;
  }

  .xxl\:-m-72 {
    margin: -18rem;
  }

  .xxl\:-m-80 {
    margin: -20rem;
  }

  .xxl\:-m-96 {
    margin: -24rem;
  }

  .xxl\:-m-px {
    margin: -1px;
  }

  .xxl\:-m-0\.5 {
    margin: -.125rem;
  }

  .xxl\:-m-1\.5 {
    margin: -.375rem;
  }

  .xxl\:-m-2\.5 {
    margin: -.625rem;
  }

  .xxl\:-m-3\.5 {
    margin: -.875rem;
  }

  .xxl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xxl\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .xxl\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .xxl\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .xxl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xxl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xxl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xxl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .xxl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xxl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .xxl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xxl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .xxl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xxl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .xxl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xxl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xxl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xxl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .xxl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xxl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .xxl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xxl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .xxl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xxl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .xxl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xxl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .xxl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xxl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .xxl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .xxl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .xxl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xxl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xxl\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .xxl\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .xxl\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .xxl\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .xxl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xxl\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .xxl\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .xxl\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .xxl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xxl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xxl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xxl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .xxl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xxl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .xxl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xxl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .xxl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xxl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .xxl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xxl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xxl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xxl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .xxl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xxl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .xxl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xxl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .xxl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xxl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .xxl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xxl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .xxl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xxl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .xxl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .xxl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .xxl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xxl\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .xxl\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .xxl\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .xxl\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .xxl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xxl\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .xxl\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .xxl\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .xxl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xxl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xxl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xxl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .xxl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xxl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .xxl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xxl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .xxl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xxl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .xxl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xxl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xxl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xxl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .xxl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xxl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .xxl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xxl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .xxl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xxl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .xxl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xxl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .xxl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xxl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .xxl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .xxl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .xxl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xxl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xxl\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .xxl\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .xxl\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .xxl\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .xxl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xxl\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .xxl\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .xxl\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .xxl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xxl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xxl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xxl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .xxl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xxl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .xxl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xxl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .xxl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xxl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .xxl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xxl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xxl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xxl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .xxl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xxl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .xxl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xxl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .xxl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xxl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .xxl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xxl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .xxl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xxl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .xxl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .xxl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .xxl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xxl\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .xxl\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .xxl\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .xxl\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .xxl\:mt-0 {
    margin-top: 0;
  }

  .xxl\:mt-1 {
    margin-top: .25rem;
  }

  .xxl\:mt-2 {
    margin-top: .5rem;
  }

  .xxl\:mt-3 {
    margin-top: .75rem;
  }

  .xxl\:mt-4 {
    margin-top: 1rem;
  }

  .xxl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xxl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xxl\:mt-7 {
    margin-top: 1.75rem;
  }

  .xxl\:mt-8 {
    margin-top: 2rem;
  }

  .xxl\:mt-9 {
    margin-top: 2.25rem;
  }

  .xxl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xxl\:mt-11 {
    margin-top: 2.75rem;
  }

  .xxl\:mt-12 {
    margin-top: 3rem;
  }

  .xxl\:mt-14 {
    margin-top: 3.5rem;
  }

  .xxl\:mt-16 {
    margin-top: 4rem;
  }

  .xxl\:mt-20 {
    margin-top: 5rem;
  }

  .xxl\:mt-24 {
    margin-top: 6rem;
  }

  .xxl\:mt-28 {
    margin-top: 7rem;
  }

  .xxl\:mt-32 {
    margin-top: 8rem;
  }

  .xxl\:mt-36 {
    margin-top: 9rem;
  }

  .xxl\:mt-40 {
    margin-top: 10rem;
  }

  .xxl\:mt-44 {
    margin-top: 11rem;
  }

  .xxl\:mt-48 {
    margin-top: 12rem;
  }

  .xxl\:mt-52 {
    margin-top: 13rem;
  }

  .xxl\:mt-56 {
    margin-top: 14rem;
  }

  .xxl\:mt-60 {
    margin-top: 15rem;
  }

  .xxl\:mt-64 {
    margin-top: 16rem;
  }

  .xxl\:mt-72 {
    margin-top: 18rem;
  }

  .xxl\:mt-80 {
    margin-top: 20rem;
  }

  .xxl\:mt-96 {
    margin-top: 24rem;
  }

  .xxl\:mt-auto {
    margin-top: auto;
  }

  .xxl\:mt-px {
    margin-top: 1px;
  }

  .xxl\:mt-0\.5 {
    margin-top: .125rem;
  }

  .xxl\:mt-1\.5 {
    margin-top: .375rem;
  }

  .xxl\:mt-2\.5 {
    margin-top: .625rem;
  }

  .xxl\:mt-3\.5 {
    margin-top: .875rem;
  }

  .xxl\:-mt-0 {
    margin-top: 0;
  }

  .xxl\:-mt-1 {
    margin-top: -.25rem;
  }

  .xxl\:-mt-2 {
    margin-top: -.5rem;
  }

  .xxl\:-mt-3 {
    margin-top: -.75rem;
  }

  .xxl\:-mt-4 {
    margin-top: -1rem;
  }

  .xxl\:-mt-5 {
    margin-top: -1.25rem;
  }

  .xxl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xxl\:-mt-7 {
    margin-top: -1.75rem;
  }

  .xxl\:-mt-8 {
    margin-top: -2rem;
  }

  .xxl\:-mt-9 {
    margin-top: -2.25rem;
  }

  .xxl\:-mt-10 {
    margin-top: -2.5rem;
  }

  .xxl\:-mt-11 {
    margin-top: -2.75rem;
  }

  .xxl\:-mt-12 {
    margin-top: -3rem;
  }

  .xxl\:-mt-14 {
    margin-top: -3.5rem;
  }

  .xxl\:-mt-16 {
    margin-top: -4rem;
  }

  .xxl\:-mt-20 {
    margin-top: -5rem;
  }

  .xxl\:-mt-24 {
    margin-top: -6rem;
  }

  .xxl\:-mt-28 {
    margin-top: -7rem;
  }

  .xxl\:-mt-32 {
    margin-top: -8rem;
  }

  .xxl\:-mt-36 {
    margin-top: -9rem;
  }

  .xxl\:-mt-40 {
    margin-top: -10rem;
  }

  .xxl\:-mt-44 {
    margin-top: -11rem;
  }

  .xxl\:-mt-48 {
    margin-top: -12rem;
  }

  .xxl\:-mt-52 {
    margin-top: -13rem;
  }

  .xxl\:-mt-56 {
    margin-top: -14rem;
  }

  .xxl\:-mt-60 {
    margin-top: -15rem;
  }

  .xxl\:-mt-64 {
    margin-top: -16rem;
  }

  .xxl\:-mt-72 {
    margin-top: -18rem;
  }

  .xxl\:-mt-80 {
    margin-top: -20rem;
  }

  .xxl\:-mt-96 {
    margin-top: -24rem;
  }

  .xxl\:-mt-px {
    margin-top: -1px;
  }

  .xxl\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .xxl\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .xxl\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .xxl\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .xxl\:mr-0 {
    margin-right: 0;
  }

  .xxl\:mr-1 {
    margin-right: .25rem;
  }

  .xxl\:mr-2 {
    margin-right: .5rem;
  }

  .xxl\:mr-3 {
    margin-right: .75rem;
  }

  .xxl\:mr-4 {
    margin-right: 1rem;
  }

  .xxl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xxl\:mr-6 {
    margin-right: 1.5rem;
  }

  .xxl\:mr-7 {
    margin-right: 1.75rem;
  }

  .xxl\:mr-8 {
    margin-right: 2rem;
  }

  .xxl\:mr-9 {
    margin-right: 2.25rem;
  }

  .xxl\:mr-10 {
    margin-right: 2.5rem;
  }

  .xxl\:mr-11 {
    margin-right: 2.75rem;
  }

  .xxl\:mr-12 {
    margin-right: 3rem;
  }

  .xxl\:mr-14 {
    margin-right: 3.5rem;
  }

  .xxl\:mr-16 {
    margin-right: 4rem;
  }

  .xxl\:mr-20 {
    margin-right: 5rem;
  }

  .xxl\:mr-24 {
    margin-right: 6rem;
  }

  .xxl\:mr-28 {
    margin-right: 7rem;
  }

  .xxl\:mr-32 {
    margin-right: 8rem;
  }

  .xxl\:mr-36 {
    margin-right: 9rem;
  }

  .xxl\:mr-40 {
    margin-right: 10rem;
  }

  .xxl\:mr-44 {
    margin-right: 11rem;
  }

  .xxl\:mr-48 {
    margin-right: 12rem;
  }

  .xxl\:mr-52 {
    margin-right: 13rem;
  }

  .xxl\:mr-56 {
    margin-right: 14rem;
  }

  .xxl\:mr-60 {
    margin-right: 15rem;
  }

  .xxl\:mr-64 {
    margin-right: 16rem;
  }

  .xxl\:mr-72 {
    margin-right: 18rem;
  }

  .xxl\:mr-80 {
    margin-right: 20rem;
  }

  .xxl\:mr-96 {
    margin-right: 24rem;
  }

  .xxl\:mr-auto {
    margin-right: auto;
  }

  .xxl\:mr-px {
    margin-right: 1px;
  }

  .xxl\:mr-0\.5 {
    margin-right: .125rem;
  }

  .xxl\:mr-1\.5 {
    margin-right: .375rem;
  }

  .xxl\:mr-2\.5 {
    margin-right: .625rem;
  }

  .xxl\:mr-3\.5 {
    margin-right: .875rem;
  }

  .xxl\:-mr-0 {
    margin-right: 0;
  }

  .xxl\:-mr-1 {
    margin-right: -.25rem;
  }

  .xxl\:-mr-2 {
    margin-right: -.5rem;
  }

  .xxl\:-mr-3 {
    margin-right: -.75rem;
  }

  .xxl\:-mr-4 {
    margin-right: -1rem;
  }

  .xxl\:-mr-5 {
    margin-right: -1.25rem;
  }

  .xxl\:-mr-6 {
    margin-right: -1.5rem;
  }

  .xxl\:-mr-7 {
    margin-right: -1.75rem;
  }

  .xxl\:-mr-8 {
    margin-right: -2rem;
  }

  .xxl\:-mr-9 {
    margin-right: -2.25rem;
  }

  .xxl\:-mr-10 {
    margin-right: -2.5rem;
  }

  .xxl\:-mr-11 {
    margin-right: -2.75rem;
  }

  .xxl\:-mr-12 {
    margin-right: -3rem;
  }

  .xxl\:-mr-14 {
    margin-right: -3.5rem;
  }

  .xxl\:-mr-16 {
    margin-right: -4rem;
  }

  .xxl\:-mr-20 {
    margin-right: -5rem;
  }

  .xxl\:-mr-24 {
    margin-right: -6rem;
  }

  .xxl\:-mr-28 {
    margin-right: -7rem;
  }

  .xxl\:-mr-32 {
    margin-right: -8rem;
  }

  .xxl\:-mr-36 {
    margin-right: -9rem;
  }

  .xxl\:-mr-40 {
    margin-right: -10rem;
  }

  .xxl\:-mr-44 {
    margin-right: -11rem;
  }

  .xxl\:-mr-48 {
    margin-right: -12rem;
  }

  .xxl\:-mr-52 {
    margin-right: -13rem;
  }

  .xxl\:-mr-56 {
    margin-right: -14rem;
  }

  .xxl\:-mr-60 {
    margin-right: -15rem;
  }

  .xxl\:-mr-64 {
    margin-right: -16rem;
  }

  .xxl\:-mr-72 {
    margin-right: -18rem;
  }

  .xxl\:-mr-80 {
    margin-right: -20rem;
  }

  .xxl\:-mr-96 {
    margin-right: -24rem;
  }

  .xxl\:-mr-px {
    margin-right: -1px;
  }

  .xxl\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .xxl\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .xxl\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .xxl\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .xxl\:mb-0 {
    margin-bottom: 0;
  }

  .xxl\:mb-1 {
    margin-bottom: .25rem;
  }

  .xxl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xxl\:mb-3 {
    margin-bottom: .75rem;
  }

  .xxl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xxl\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .xxl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xxl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xxl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xxl\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .xxl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xxl\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .xxl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xxl\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .xxl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xxl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xxl\:mb-24 {
    margin-bottom: 6rem;
  }

  .xxl\:mb-28 {
    margin-bottom: 7rem;
  }

  .xxl\:mb-32 {
    margin-bottom: 8rem;
  }

  .xxl\:mb-36 {
    margin-bottom: 9rem;
  }

  .xxl\:mb-40 {
    margin-bottom: 10rem;
  }

  .xxl\:mb-44 {
    margin-bottom: 11rem;
  }

  .xxl\:mb-48 {
    margin-bottom: 12rem;
  }

  .xxl\:mb-52 {
    margin-bottom: 13rem;
  }

  .xxl\:mb-56 {
    margin-bottom: 14rem;
  }

  .xxl\:mb-60 {
    margin-bottom: 15rem;
  }

  .xxl\:mb-64 {
    margin-bottom: 16rem;
  }

  .xxl\:mb-72 {
    margin-bottom: 18rem;
  }

  .xxl\:mb-80 {
    margin-bottom: 20rem;
  }

  .xxl\:mb-96 {
    margin-bottom: 24rem;
  }

  .xxl\:mb-auto {
    margin-bottom: auto;
  }

  .xxl\:mb-px {
    margin-bottom: 1px;
  }

  .xxl\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .xxl\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .xxl\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .xxl\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .xxl\:-mb-0 {
    margin-bottom: 0;
  }

  .xxl\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .xxl\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .xxl\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .xxl\:-mb-4 {
    margin-bottom: -1rem;
  }

  .xxl\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .xxl\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .xxl\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .xxl\:-mb-8 {
    margin-bottom: -2rem;
  }

  .xxl\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .xxl\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .xxl\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .xxl\:-mb-12 {
    margin-bottom: -3rem;
  }

  .xxl\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .xxl\:-mb-16 {
    margin-bottom: -4rem;
  }

  .xxl\:-mb-20 {
    margin-bottom: -5rem;
  }

  .xxl\:-mb-24 {
    margin-bottom: -6rem;
  }

  .xxl\:-mb-28 {
    margin-bottom: -7rem;
  }

  .xxl\:-mb-32 {
    margin-bottom: -8rem;
  }

  .xxl\:-mb-36 {
    margin-bottom: -9rem;
  }

  .xxl\:-mb-40 {
    margin-bottom: -10rem;
  }

  .xxl\:-mb-44 {
    margin-bottom: -11rem;
  }

  .xxl\:-mb-48 {
    margin-bottom: -12rem;
  }

  .xxl\:-mb-52 {
    margin-bottom: -13rem;
  }

  .xxl\:-mb-56 {
    margin-bottom: -14rem;
  }

  .xxl\:-mb-60 {
    margin-bottom: -15rem;
  }

  .xxl\:-mb-64 {
    margin-bottom: -16rem;
  }

  .xxl\:-mb-72 {
    margin-bottom: -18rem;
  }

  .xxl\:-mb-80 {
    margin-bottom: -20rem;
  }

  .xxl\:-mb-96 {
    margin-bottom: -24rem;
  }

  .xxl\:-mb-px {
    margin-bottom: -1px;
  }

  .xxl\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .xxl\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .xxl\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .xxl\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .xxl\:ml-0 {
    margin-left: 0;
  }

  .xxl\:ml-1 {
    margin-left: .25rem;
  }

  .xxl\:ml-2 {
    margin-left: .5rem;
  }

  .xxl\:ml-3 {
    margin-left: .75rem;
  }

  .xxl\:ml-4 {
    margin-left: 1rem;
  }

  .xxl\:ml-5 {
    margin-left: 1.25rem;
  }

  .xxl\:ml-6 {
    margin-left: 1.5rem;
  }

  .xxl\:ml-7 {
    margin-left: 1.75rem;
  }

  .xxl\:ml-8 {
    margin-left: 2rem;
  }

  .xxl\:ml-9 {
    margin-left: 2.25rem;
  }

  .xxl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xxl\:ml-11 {
    margin-left: 2.75rem;
  }

  .xxl\:ml-12 {
    margin-left: 3rem;
  }

  .xxl\:ml-14 {
    margin-left: 3.5rem;
  }

  .xxl\:ml-16 {
    margin-left: 4rem;
  }

  .xxl\:ml-20 {
    margin-left: 5rem;
  }

  .xxl\:ml-24 {
    margin-left: 6rem;
  }

  .xxl\:ml-28 {
    margin-left: 7rem;
  }

  .xxl\:ml-32 {
    margin-left: 8rem;
  }

  .xxl\:ml-36 {
    margin-left: 9rem;
  }

  .xxl\:ml-40 {
    margin-left: 10rem;
  }

  .xxl\:ml-44 {
    margin-left: 11rem;
  }

  .xxl\:ml-48 {
    margin-left: 12rem;
  }

  .xxl\:ml-52 {
    margin-left: 13rem;
  }

  .xxl\:ml-56 {
    margin-left: 14rem;
  }

  .xxl\:ml-60 {
    margin-left: 15rem;
  }

  .xxl\:ml-64 {
    margin-left: 16rem;
  }

  .xxl\:ml-72 {
    margin-left: 18rem;
  }

  .xxl\:ml-80 {
    margin-left: 20rem;
  }

  .xxl\:ml-96 {
    margin-left: 24rem;
  }

  .xxl\:ml-auto {
    margin-left: auto;
  }

  .xxl\:ml-px {
    margin-left: 1px;
  }

  .xxl\:ml-0\.5 {
    margin-left: .125rem;
  }

  .xxl\:ml-1\.5 {
    margin-left: .375rem;
  }

  .xxl\:ml-2\.5 {
    margin-left: .625rem;
  }

  .xxl\:ml-3\.5 {
    margin-left: .875rem;
  }

  .xxl\:-ml-0 {
    margin-left: 0;
  }

  .xxl\:-ml-1 {
    margin-left: -.25rem;
  }

  .xxl\:-ml-2 {
    margin-left: -.5rem;
  }

  .xxl\:-ml-3 {
    margin-left: -.75rem;
  }

  .xxl\:-ml-4 {
    margin-left: -1rem;
  }

  .xxl\:-ml-5 {
    margin-left: -1.25rem;
  }

  .xxl\:-ml-6 {
    margin-left: -1.5rem;
  }

  .xxl\:-ml-7 {
    margin-left: -1.75rem;
  }

  .xxl\:-ml-8 {
    margin-left: -2rem;
  }

  .xxl\:-ml-9 {
    margin-left: -2.25rem;
  }

  .xxl\:-ml-10 {
    margin-left: -2.5rem;
  }

  .xxl\:-ml-11 {
    margin-left: -2.75rem;
  }

  .xxl\:-ml-12 {
    margin-left: -3rem;
  }

  .xxl\:-ml-14 {
    margin-left: -3.5rem;
  }

  .xxl\:-ml-16 {
    margin-left: -4rem;
  }

  .xxl\:-ml-20 {
    margin-left: -5rem;
  }

  .xxl\:-ml-24 {
    margin-left: -6rem;
  }

  .xxl\:-ml-28 {
    margin-left: -7rem;
  }

  .xxl\:-ml-32 {
    margin-left: -8rem;
  }

  .xxl\:-ml-36 {
    margin-left: -9rem;
  }

  .xxl\:-ml-40 {
    margin-left: -10rem;
  }

  .xxl\:-ml-44 {
    margin-left: -11rem;
  }

  .xxl\:-ml-48 {
    margin-left: -12rem;
  }

  .xxl\:-ml-52 {
    margin-left: -13rem;
  }

  .xxl\:-ml-56 {
    margin-left: -14rem;
  }

  .xxl\:-ml-60 {
    margin-left: -15rem;
  }

  .xxl\:-ml-64 {
    margin-left: -16rem;
  }

  .xxl\:-ml-72 {
    margin-left: -18rem;
  }

  .xxl\:-ml-80 {
    margin-left: -20rem;
  }

  .xxl\:-ml-96 {
    margin-left: -24rem;
  }

  .xxl\:-ml-px {
    margin-left: -1px;
  }

  .xxl\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .xxl\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .xxl\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .xxl\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .xxl\:box-border {
    box-sizing: border-box;
  }

  .xxl\:box-content {
    box-sizing: content-box;
  }

  .xxl\:block {
    display: block;
  }

  .xxl\:inline-block {
    display: inline-block;
  }

  .xxl\:inline {
    display: inline;
  }

  .xxl\:flex {
    display: flex;
  }

  .xxl\:inline-flex {
    display: inline-flex;
  }

  .xxl\:table {
    display: table;
  }

  .xxl\:inline-table {
    display: inline-table;
  }

  .xxl\:table-caption {
    display: table-caption;
  }

  .xxl\:table-cell {
    display: table-cell;
  }

  .xxl\:table-column {
    display: table-column;
  }

  .xxl\:table-column-group {
    display: table-column-group;
  }

  .xxl\:table-footer-group {
    display: table-footer-group;
  }

  .xxl\:table-header-group {
    display: table-header-group;
  }

  .xxl\:table-row-group {
    display: table-row-group;
  }

  .xxl\:table-row {
    display: table-row;
  }

  .xxl\:flow-root {
    display: flow-root;
  }

  .xxl\:grid {
    display: grid;
  }

  .xxl\:inline-grid {
    display: inline-grid;
  }

  .xxl\:contents {
    display: contents;
  }

  .xxl\:list-item {
    display: list-item;
  }

  .xxl\:hidden {
    display: none;
  }

  .xxl\:h-0 {
    height: 0;
  }

  .xxl\:h-1 {
    height: .25rem;
  }

  .xxl\:h-2 {
    height: .5rem;
  }

  .xxl\:h-3 {
    height: .75rem;
  }

  .xxl\:h-4 {
    height: 1rem;
  }

  .xxl\:h-5 {
    height: 1.25rem;
  }

  .xxl\:h-6 {
    height: 1.5rem;
  }

  .xxl\:h-7 {
    height: 1.75rem;
  }

  .xxl\:h-8 {
    height: 2rem;
  }

  .xxl\:h-9 {
    height: 2.25rem;
  }

  .xxl\:h-10 {
    height: 2.5rem;
  }

  .xxl\:h-11 {
    height: 2.75rem;
  }

  .xxl\:h-12 {
    height: 3rem;
  }

  .xxl\:h-14 {
    height: 3.5rem;
  }

  .xxl\:h-16 {
    height: 4rem;
  }

  .xxl\:h-20 {
    height: 5rem;
  }

  .xxl\:h-24 {
    height: 6rem;
  }

  .xxl\:h-28 {
    height: 7rem;
  }

  .xxl\:h-32 {
    height: 8rem;
  }

  .xxl\:h-36 {
    height: 9rem;
  }

  .xxl\:h-40 {
    height: 10rem;
  }

  .xxl\:h-44 {
    height: 11rem;
  }

  .xxl\:h-48 {
    height: 12rem;
  }

  .xxl\:h-52 {
    height: 13rem;
  }

  .xxl\:h-56 {
    height: 14rem;
  }

  .xxl\:h-60 {
    height: 15rem;
  }

  .xxl\:h-64 {
    height: 16rem;
  }

  .xxl\:h-72 {
    height: 18rem;
  }

  .xxl\:h-80 {
    height: 20rem;
  }

  .xxl\:h-96 {
    height: 24rem;
  }

  .xxl\:h-auto {
    height: auto;
  }

  .xxl\:h-px {
    height: 1px;
  }

  .xxl\:h-0\.5 {
    height: .125rem;
  }

  .xxl\:h-1\.5 {
    height: .375rem;
  }

  .xxl\:h-2\.5 {
    height: .625rem;
  }

  .xxl\:h-3\.5 {
    height: .875rem;
  }

  .xxl\:h-1\/2 {
    height: 50%;
  }

  .xxl\:h-1\/3 {
    height: 33.3333%;
  }

  .xxl\:h-2\/3 {
    height: 66.6667%;
  }

  .xxl\:h-1\/4 {
    height: 25%;
  }

  .xxl\:h-2\/4 {
    height: 50%;
  }

  .xxl\:h-3\/4 {
    height: 75%;
  }

  .xxl\:h-1\/5 {
    height: 20%;
  }

  .xxl\:h-2\/5 {
    height: 40%;
  }

  .xxl\:h-3\/5 {
    height: 60%;
  }

  .xxl\:h-4\/5 {
    height: 80%;
  }

  .xxl\:h-1\/6 {
    height: 16.6667%;
  }

  .xxl\:h-2\/6 {
    height: 33.3333%;
  }

  .xxl\:h-3\/6 {
    height: 50%;
  }

  .xxl\:h-4\/6 {
    height: 66.6667%;
  }

  .xxl\:h-5\/6 {
    height: 83.3333%;
  }

  .xxl\:h-full {
    height: 100%;
  }

  .xxl\:h-screen {
    height: 100vh;
  }

  .xxl\:max-h-0 {
    max-height: 0;
  }

  .xxl\:max-h-1 {
    max-height: .25rem;
  }

  .xxl\:max-h-2 {
    max-height: .5rem;
  }

  .xxl\:max-h-3 {
    max-height: .75rem;
  }

  .xxl\:max-h-4 {
    max-height: 1rem;
  }

  .xxl\:max-h-5 {
    max-height: 1.25rem;
  }

  .xxl\:max-h-6 {
    max-height: 1.5rem;
  }

  .xxl\:max-h-7 {
    max-height: 1.75rem;
  }

  .xxl\:max-h-8 {
    max-height: 2rem;
  }

  .xxl\:max-h-9 {
    max-height: 2.25rem;
  }

  .xxl\:max-h-10 {
    max-height: 2.5rem;
  }

  .xxl\:max-h-11 {
    max-height: 2.75rem;
  }

  .xxl\:max-h-12 {
    max-height: 3rem;
  }

  .xxl\:max-h-14 {
    max-height: 3.5rem;
  }

  .xxl\:max-h-16 {
    max-height: 4rem;
  }

  .xxl\:max-h-20 {
    max-height: 5rem;
  }

  .xxl\:max-h-24 {
    max-height: 6rem;
  }

  .xxl\:max-h-28 {
    max-height: 7rem;
  }

  .xxl\:max-h-32 {
    max-height: 8rem;
  }

  .xxl\:max-h-36 {
    max-height: 9rem;
  }

  .xxl\:max-h-40 {
    max-height: 10rem;
  }

  .xxl\:max-h-44 {
    max-height: 11rem;
  }

  .xxl\:max-h-48 {
    max-height: 12rem;
  }

  .xxl\:max-h-52 {
    max-height: 13rem;
  }

  .xxl\:max-h-56 {
    max-height: 14rem;
  }

  .xxl\:max-h-60 {
    max-height: 15rem;
  }

  .xxl\:max-h-64 {
    max-height: 16rem;
  }

  .xxl\:max-h-72 {
    max-height: 18rem;
  }

  .xxl\:max-h-80 {
    max-height: 20rem;
  }

  .xxl\:max-h-96 {
    max-height: 24rem;
  }

  .xxl\:max-h-px {
    max-height: 1px;
  }

  .xxl\:max-h-0\.5 {
    max-height: .125rem;
  }

  .xxl\:max-h-1\.5 {
    max-height: .375rem;
  }

  .xxl\:max-h-2\.5 {
    max-height: .625rem;
  }

  .xxl\:max-h-3\.5 {
    max-height: .875rem;
  }

  .xxl\:max-h-full {
    max-height: 100%;
  }

  .xxl\:max-h-screen {
    max-height: 100vh;
  }

  .xxl\:min-h-0 {
    min-height: 0;
  }

  .xxl\:min-h-full {
    min-height: 100%;
  }

  .xxl\:min-h-screen {
    min-height: 100vh;
  }

  .xxl\:w-0 {
    width: 0;
  }

  .xxl\:w-1 {
    width: .25rem;
  }

  .xxl\:w-2 {
    width: .5rem;
  }

  .xxl\:w-3 {
    width: .75rem;
  }

  .xxl\:w-4 {
    width: 1rem;
  }

  .xxl\:w-5 {
    width: 1.25rem;
  }

  .xxl\:w-6 {
    width: 1.5rem;
  }

  .xxl\:w-7 {
    width: 1.75rem;
  }

  .xxl\:w-8 {
    width: 2rem;
  }

  .xxl\:w-9 {
    width: 2.25rem;
  }

  .xxl\:w-10 {
    width: 2.5rem;
  }

  .xxl\:w-11 {
    width: 2.75rem;
  }

  .xxl\:w-12 {
    width: 3rem;
  }

  .xxl\:w-14 {
    width: 3.5rem;
  }

  .xxl\:w-16 {
    width: 4rem;
  }

  .xxl\:w-20 {
    width: 5rem;
  }

  .xxl\:w-24 {
    width: 6rem;
  }

  .xxl\:w-28 {
    width: 7rem;
  }

  .xxl\:w-32 {
    width: 8rem;
  }

  .xxl\:w-36 {
    width: 9rem;
  }

  .xxl\:w-40 {
    width: 10rem;
  }

  .xxl\:w-44 {
    width: 11rem;
  }

  .xxl\:w-48 {
    width: 12rem;
  }

  .xxl\:w-52 {
    width: 13rem;
  }

  .xxl\:w-56 {
    width: 14rem;
  }

  .xxl\:w-60 {
    width: 15rem;
  }

  .xxl\:w-64 {
    width: 16rem;
  }

  .xxl\:w-72 {
    width: 18rem;
  }

  .xxl\:w-80 {
    width: 20rem;
  }

  .xxl\:w-96 {
    width: 24rem;
  }

  .xxl\:w-auto {
    width: auto;
  }

  .xxl\:w-px {
    width: 1px;
  }

  .xxl\:w-0\.5 {
    width: .125rem;
  }

  .xxl\:w-1\.5 {
    width: .375rem;
  }

  .xxl\:w-2\.5 {
    width: .625rem;
  }

  .xxl\:w-3\.5 {
    width: .875rem;
  }

  .xxl\:w-1\/2 {
    width: 50%;
  }

  .xxl\:w-1\/3 {
    width: 33.3333%;
  }

  .xxl\:w-2\/3 {
    width: 66.6667%;
  }

  .xxl\:w-1\/4 {
    width: 25%;
  }

  .xxl\:w-2\/4 {
    width: 50%;
  }

  .xxl\:w-3\/4 {
    width: 75%;
  }

  .xxl\:w-1\/5 {
    width: 20%;
  }

  .xxl\:w-2\/5 {
    width: 40%;
  }

  .xxl\:w-3\/5 {
    width: 60%;
  }

  .xxl\:w-4\/5 {
    width: 80%;
  }

  .xxl\:w-1\/6 {
    width: 16.6667%;
  }

  .xxl\:w-2\/6 {
    width: 33.3333%;
  }

  .xxl\:w-3\/6 {
    width: 50%;
  }

  .xxl\:w-4\/6 {
    width: 66.6667%;
  }

  .xxl\:w-5\/6 {
    width: 83.3333%;
  }

  .xxl\:w-1\/12 {
    width: 8.33333%;
  }

  .xxl\:w-2\/12 {
    width: 16.6667%;
  }

  .xxl\:w-3\/12 {
    width: 25%;
  }

  .xxl\:w-4\/12 {
    width: 33.3333%;
  }

  .xxl\:w-5\/12 {
    width: 41.6667%;
  }

  .xxl\:w-6\/12 {
    width: 50%;
  }

  .xxl\:w-7\/12 {
    width: 58.3333%;
  }

  .xxl\:w-8\/12 {
    width: 66.6667%;
  }

  .xxl\:w-9\/12 {
    width: 75%;
  }

  .xxl\:w-10\/12 {
    width: 83.3333%;
  }

  .xxl\:w-11\/12 {
    width: 91.6667%;
  }

  .xxl\:w-full {
    width: 100%;
  }

  .xxl\:w-screen {
    width: 100vw;
  }

  .xxl\:w-min {
    width: min-content;
  }

  .xxl\:w-max {
    width: max-content;
  }

  .xxl\:min-w-0 {
    min-width: 0;
  }

  .xxl\:min-w-full {
    min-width: 100%;
  }

  .xxl\:min-w-min {
    min-width: min-content;
  }

  .xxl\:min-w-max {
    min-width: max-content;
  }

  .xxl\:max-w-0 {
    max-width: 0;
  }

  .xxl\:max-w-none {
    max-width: none;
  }

  .xxl\:max-w-xs {
    max-width: 20rem;
  }

  .xxl\:max-w-sm {
    max-width: 24rem;
  }

  .xxl\:max-w-md {
    max-width: 28rem;
  }

  .xxl\:max-w-lg {
    max-width: 32rem;
  }

  .xxl\:max-w-xl {
    max-width: 36rem;
  }

  .xxl\:max-w-2xl {
    max-width: 42rem;
  }

  .xxl\:max-w-3xl {
    max-width: 48rem;
  }

  .xxl\:max-w-4xl {
    max-width: 56rem;
  }

  .xxl\:max-w-5xl {
    max-width: 64rem;
  }

  .xxl\:max-w-6xl {
    max-width: 72rem;
  }

  .xxl\:max-w-7xl {
    max-width: 80rem;
  }

  .xxl\:max-w-full {
    max-width: 100%;
  }

  .xxl\:max-w-min {
    max-width: min-content;
  }

  .xxl\:max-w-max {
    max-width: max-content;
  }

  .xxl\:max-w-prose {
    max-width: 65ch;
  }

  .xxl\:max-w-screen-sm {
    max-width: 640px;
  }

  .xxl\:max-w-screen-md {
    max-width: 768px;
  }

  .xxl\:max-w-screen-lg {
    max-width: 1024px;
  }

  .xxl\:max-w-screen-xl {
    max-width: 1280px;
  }

  .xxl\:max-w-screen-xxl {
    max-width: 1560px;
  }

  .xxl\:flex-1 {
    flex: 1;
  }

  .xxl\:flex-auto {
    flex: auto;
  }

  .xxl\:flex-initial {
    flex: 0 auto;
  }

  .xxl\:flex-none {
    flex: none;
  }

  .xxl\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xxl\:flex-shrink {
    flex-shrink: 1;
  }

  .xxl\:flex-grow-0 {
    flex-grow: 0;
  }

  .xxl\:flex-grow {
    flex-grow: 1;
  }

  .xxl\:table-auto {
    table-layout: auto;
  }

  .xxl\:table-fixed {
    table-layout: fixed;
  }

  .xxl\:border-collapse {
    border-collapse: collapse;
  }

  .xxl\:border-separate {
    border-collapse: separate;
  }

  .xxl\:origin-center {
    transform-origin: center;
  }

  .xxl\:origin-top {
    transform-origin: top;
  }

  .xxl\:origin-top-right {
    transform-origin: 100% 0;
  }

  .xxl\:origin-right {
    transform-origin: 100%;
  }

  .xxl\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .xxl\:origin-bottom {
    transform-origin: bottom;
  }

  .xxl\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .xxl\:origin-left {
    transform-origin: 0;
  }

  .xxl\:origin-top-left {
    transform-origin: 0 0;
  }

  .xxl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xxl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xxl\:transform-none {
    transform: none;
  }

  .xxl\:translate-x-0 {
    --tw-translate-x: 0px;
  }

  .xxl\:translate-x-1 {
    --tw-translate-x: .25rem;
  }

  .xxl\:translate-x-2 {
    --tw-translate-x: .5rem;
  }

  .xxl\:translate-x-3 {
    --tw-translate-x: .75rem;
  }

  .xxl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .xxl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .xxl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .xxl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }

  .xxl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .xxl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }

  .xxl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .xxl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }

  .xxl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .xxl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }

  .xxl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .xxl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .xxl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .xxl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }

  .xxl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .xxl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }

  .xxl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .xxl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }

  .xxl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .xxl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }

  .xxl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .xxl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }

  .xxl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .xxl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }

  .xxl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }

  .xxl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }

  .xxl\:translate-x-px {
    --tw-translate-x: 1px;
  }

  .xxl\:translate-x-0\.5 {
    --tw-translate-x: .125rem;
  }

  .xxl\:translate-x-1\.5 {
    --tw-translate-x: .375rem;
  }

  .xxl\:translate-x-2\.5 {
    --tw-translate-x: .625rem;
  }

  .xxl\:translate-x-3\.5 {
    --tw-translate-x: .875rem;
  }

  .xxl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }

  .xxl\:-translate-x-1 {
    --tw-translate-x: -.25rem;
  }

  .xxl\:-translate-x-2 {
    --tw-translate-x: -.5rem;
  }

  .xxl\:-translate-x-3 {
    --tw-translate-x: -.75rem;
  }

  .xxl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .xxl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .xxl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .xxl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }

  .xxl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .xxl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }

  .xxl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .xxl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }

  .xxl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .xxl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }

  .xxl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .xxl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .xxl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .xxl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }

  .xxl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .xxl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }

  .xxl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .xxl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }

  .xxl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .xxl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }

  .xxl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .xxl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }

  .xxl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .xxl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }

  .xxl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }

  .xxl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }

  .xxl\:-translate-x-px {
    --tw-translate-x: -1px;
  }

  .xxl\:-translate-x-0\.5 {
    --tw-translate-x: -.125rem;
  }

  .xxl\:-translate-x-1\.5 {
    --tw-translate-x: -.375rem;
  }

  .xxl\:-translate-x-2\.5 {
    --tw-translate-x: -.625rem;
  }

  .xxl\:-translate-x-3\.5 {
    --tw-translate-x: -.875rem;
  }

  .xxl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .xxl\:translate-x-1\/3 {
    --tw-translate-x: 33.3333%;
  }

  .xxl\:translate-x-2\/3 {
    --tw-translate-x: 66.6667%;
  }

  .xxl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }

  .xxl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }

  .xxl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }

  .xxl\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .xxl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .xxl\:-translate-x-1\/3 {
    --tw-translate-x: -33.3333%;
  }

  .xxl\:-translate-x-2\/3 {
    --tw-translate-x: -66.6667%;
  }

  .xxl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }

  .xxl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }

  .xxl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }

  .xxl\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .xxl\:translate-y-0 {
    --tw-translate-y: 0px;
  }

  .xxl\:translate-y-1 {
    --tw-translate-y: .25rem;
  }

  .xxl\:translate-y-2 {
    --tw-translate-y: .5rem;
  }

  .xxl\:translate-y-3 {
    --tw-translate-y: .75rem;
  }

  .xxl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .xxl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .xxl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .xxl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }

  .xxl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .xxl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }

  .xxl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .xxl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }

  .xxl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .xxl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }

  .xxl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .xxl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .xxl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .xxl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }

  .xxl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .xxl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }

  .xxl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .xxl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }

  .xxl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .xxl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }

  .xxl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .xxl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }

  .xxl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .xxl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }

  .xxl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }

  .xxl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }

  .xxl\:translate-y-px {
    --tw-translate-y: 1px;
  }

  .xxl\:translate-y-0\.5 {
    --tw-translate-y: .125rem;
  }

  .xxl\:translate-y-1\.5 {
    --tw-translate-y: .375rem;
  }

  .xxl\:translate-y-2\.5 {
    --tw-translate-y: .625rem;
  }

  .xxl\:translate-y-3\.5 {
    --tw-translate-y: .875rem;
  }

  .xxl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }

  .xxl\:-translate-y-1 {
    --tw-translate-y: -.25rem;
  }

  .xxl\:-translate-y-2 {
    --tw-translate-y: -.5rem;
  }

  .xxl\:-translate-y-3 {
    --tw-translate-y: -.75rem;
  }

  .xxl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .xxl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .xxl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .xxl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }

  .xxl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .xxl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }

  .xxl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .xxl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }

  .xxl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .xxl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }

  .xxl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .xxl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .xxl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .xxl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }

  .xxl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .xxl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }

  .xxl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .xxl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }

  .xxl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .xxl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }

  .xxl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .xxl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }

  .xxl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .xxl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }

  .xxl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }

  .xxl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }

  .xxl\:-translate-y-px {
    --tw-translate-y: -1px;
  }

  .xxl\:-translate-y-0\.5 {
    --tw-translate-y: -.125rem;
  }

  .xxl\:-translate-y-1\.5 {
    --tw-translate-y: -.375rem;
  }

  .xxl\:-translate-y-2\.5 {
    --tw-translate-y: -.625rem;
  }

  .xxl\:-translate-y-3\.5 {
    --tw-translate-y: -.875rem;
  }

  .xxl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .xxl\:translate-y-1\/3 {
    --tw-translate-y: 33.3333%;
  }

  .xxl\:translate-y-2\/3 {
    --tw-translate-y: 66.6667%;
  }

  .xxl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }

  .xxl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }

  .xxl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }

  .xxl\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .xxl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .xxl\:-translate-y-1\/3 {
    --tw-translate-y: -33.3333%;
  }

  .xxl\:-translate-y-2\/3 {
    --tw-translate-y: -66.6667%;
  }

  .xxl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }

  .xxl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }

  .xxl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }

  .xxl\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .xxl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .xxl\:hover\:translate-x-1:hover {
    --tw-translate-x: .25rem;
  }

  .xxl\:hover\:translate-x-2:hover {
    --tw-translate-x: .5rem;
  }

  .xxl\:hover\:translate-x-3:hover {
    --tw-translate-x: .75rem;
  }

  .xxl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .xxl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .xxl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .xxl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }

  .xxl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .xxl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }

  .xxl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .xxl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }

  .xxl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .xxl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }

  .xxl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .xxl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .xxl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .xxl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }

  .xxl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .xxl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }

  .xxl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .xxl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }

  .xxl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .xxl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }

  .xxl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .xxl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }

  .xxl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .xxl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }

  .xxl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }

  .xxl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }

  .xxl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }

  .xxl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: .125rem;
  }

  .xxl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: .375rem;
  }

  .xxl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: .625rem;
  }

  .xxl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: .875rem;
  }

  .xxl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }

  .xxl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -.25rem;
  }

  .xxl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -.5rem;
  }

  .xxl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -.75rem;
  }

  .xxl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .xxl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .xxl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .xxl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }

  .xxl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .xxl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }

  .xxl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .xxl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }

  .xxl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .xxl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }

  .xxl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .xxl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .xxl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .xxl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }

  .xxl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .xxl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }

  .xxl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .xxl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }

  .xxl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .xxl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }

  .xxl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .xxl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }

  .xxl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .xxl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }

  .xxl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }

  .xxl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }

  .xxl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }

  .xxl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -.125rem;
  }

  .xxl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -.375rem;
  }

  .xxl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -.625rem;
  }

  .xxl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -.875rem;
  }

  .xxl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .xxl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.3333%;
  }

  .xxl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.6667%;
  }

  .xxl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }

  .xxl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }

  .xxl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }

  .xxl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .xxl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .xxl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.3333%;
  }

  .xxl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.6667%;
  }

  .xxl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }

  .xxl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }

  .xxl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }

  .xxl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .xxl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .xxl\:hover\:translate-y-1:hover {
    --tw-translate-y: .25rem;
  }

  .xxl\:hover\:translate-y-2:hover {
    --tw-translate-y: .5rem;
  }

  .xxl\:hover\:translate-y-3:hover {
    --tw-translate-y: .75rem;
  }

  .xxl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .xxl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .xxl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .xxl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }

  .xxl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .xxl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }

  .xxl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .xxl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }

  .xxl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .xxl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }

  .xxl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .xxl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .xxl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .xxl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }

  .xxl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .xxl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }

  .xxl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .xxl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }

  .xxl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .xxl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }

  .xxl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .xxl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }

  .xxl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .xxl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }

  .xxl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }

  .xxl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }

  .xxl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }

  .xxl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: .125rem;
  }

  .xxl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: .375rem;
  }

  .xxl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: .625rem;
  }

  .xxl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: .875rem;
  }

  .xxl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }

  .xxl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -.25rem;
  }

  .xxl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -.5rem;
  }

  .xxl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -.75rem;
  }

  .xxl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .xxl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .xxl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .xxl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }

  .xxl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .xxl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }

  .xxl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .xxl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }

  .xxl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .xxl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }

  .xxl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .xxl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .xxl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .xxl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }

  .xxl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .xxl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }

  .xxl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .xxl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }

  .xxl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .xxl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }

  .xxl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .xxl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }

  .xxl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .xxl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }

  .xxl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }

  .xxl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }

  .xxl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }

  .xxl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -.125rem;
  }

  .xxl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -.375rem;
  }

  .xxl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -.625rem;
  }

  .xxl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -.875rem;
  }

  .xxl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .xxl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.3333%;
  }

  .xxl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.6667%;
  }

  .xxl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }

  .xxl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }

  .xxl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }

  .xxl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .xxl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .xxl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.3333%;
  }

  .xxl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.6667%;
  }

  .xxl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }

  .xxl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }

  .xxl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }

  .xxl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .xxl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .xxl\:focus\:translate-x-1:focus {
    --tw-translate-x: .25rem;
  }

  .xxl\:focus\:translate-x-2:focus {
    --tw-translate-x: .5rem;
  }

  .xxl\:focus\:translate-x-3:focus {
    --tw-translate-x: .75rem;
  }

  .xxl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .xxl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .xxl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .xxl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }

  .xxl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .xxl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }

  .xxl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .xxl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }

  .xxl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .xxl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }

  .xxl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .xxl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .xxl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .xxl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }

  .xxl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .xxl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }

  .xxl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .xxl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }

  .xxl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .xxl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }

  .xxl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .xxl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }

  .xxl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .xxl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }

  .xxl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }

  .xxl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }

  .xxl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }

  .xxl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: .125rem;
  }

  .xxl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: .375rem;
  }

  .xxl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: .625rem;
  }

  .xxl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: .875rem;
  }

  .xxl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }

  .xxl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -.25rem;
  }

  .xxl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -.5rem;
  }

  .xxl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -.75rem;
  }

  .xxl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .xxl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .xxl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .xxl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }

  .xxl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .xxl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }

  .xxl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .xxl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }

  .xxl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .xxl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }

  .xxl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .xxl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .xxl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .xxl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }

  .xxl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .xxl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }

  .xxl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .xxl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }

  .xxl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .xxl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }

  .xxl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .xxl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }

  .xxl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .xxl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }

  .xxl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }

  .xxl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }

  .xxl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }

  .xxl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -.125rem;
  }

  .xxl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -.375rem;
  }

  .xxl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -.625rem;
  }

  .xxl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -.875rem;
  }

  .xxl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .xxl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.3333%;
  }

  .xxl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.6667%;
  }

  .xxl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }

  .xxl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }

  .xxl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }

  .xxl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .xxl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .xxl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.3333%;
  }

  .xxl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.6667%;
  }

  .xxl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }

  .xxl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }

  .xxl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }

  .xxl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .xxl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .xxl\:focus\:translate-y-1:focus {
    --tw-translate-y: .25rem;
  }

  .xxl\:focus\:translate-y-2:focus {
    --tw-translate-y: .5rem;
  }

  .xxl\:focus\:translate-y-3:focus {
    --tw-translate-y: .75rem;
  }

  .xxl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .xxl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .xxl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .xxl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }

  .xxl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .xxl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }

  .xxl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .xxl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }

  .xxl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .xxl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }

  .xxl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .xxl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .xxl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .xxl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }

  .xxl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .xxl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }

  .xxl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .xxl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }

  .xxl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .xxl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }

  .xxl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .xxl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }

  .xxl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .xxl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }

  .xxl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }

  .xxl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }

  .xxl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }

  .xxl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: .125rem;
  }

  .xxl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: .375rem;
  }

  .xxl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: .625rem;
  }

  .xxl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: .875rem;
  }

  .xxl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }

  .xxl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -.25rem;
  }

  .xxl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -.5rem;
  }

  .xxl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -.75rem;
  }

  .xxl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .xxl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .xxl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .xxl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }

  .xxl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .xxl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }

  .xxl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .xxl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }

  .xxl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .xxl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }

  .xxl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .xxl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .xxl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .xxl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }

  .xxl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .xxl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }

  .xxl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .xxl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }

  .xxl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .xxl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }

  .xxl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .xxl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }

  .xxl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .xxl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }

  .xxl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }

  .xxl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }

  .xxl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }

  .xxl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -.125rem;
  }

  .xxl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -.375rem;
  }

  .xxl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -.625rem;
  }

  .xxl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -.875rem;
  }

  .xxl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .xxl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.3333%;
  }

  .xxl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.6667%;
  }

  .xxl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }

  .xxl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }

  .xxl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }

  .xxl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .xxl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .xxl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.3333%;
  }

  .xxl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.6667%;
  }

  .xxl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }

  .xxl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }

  .xxl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }

  .xxl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .xxl\:rotate-0 {
    --tw-rotate: 0deg;
  }

  .xxl\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .xxl\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .xxl\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .xxl\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .xxl\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .xxl\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .xxl\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .xxl\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .xxl\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .xxl\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .xxl\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .xxl\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .xxl\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .xxl\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .xxl\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .xxl\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .xxl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }

  .xxl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .xxl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .xxl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .xxl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .xxl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .xxl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .xxl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .xxl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .xxl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .xxl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .xxl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .xxl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .xxl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .xxl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .xxl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .xxl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .xxl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }

  .xxl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .xxl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .xxl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .xxl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .xxl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .xxl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .xxl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .xxl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .xxl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .xxl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .xxl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .xxl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .xxl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .xxl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .xxl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .xxl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .xxl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }

  .xxl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .xxl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .xxl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .xxl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .xxl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .xxl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .xxl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .xxl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .xxl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .xxl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .xxl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }

  .xxl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .xxl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .xxl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .xxl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .xxl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .xxl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .xxl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .xxl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .xxl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .xxl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .xxl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }

  .xxl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .xxl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .xxl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .xxl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .xxl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .xxl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .xxl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .xxl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .xxl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .xxl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .xxl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }

  .xxl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .xxl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .xxl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .xxl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .xxl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .xxl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .xxl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .xxl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .xxl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .xxl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .xxl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }

  .xxl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .xxl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .xxl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .xxl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .xxl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .xxl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .xxl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .xxl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .xxl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .xxl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .xxl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }

  .xxl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .xxl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .xxl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .xxl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .xxl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .xxl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .xxl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .xxl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .xxl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .xxl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .xxl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xxl\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xxl\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xxl\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xxl\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xxl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xxl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xxl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xxl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xxl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xxl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xxl\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xxl\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xxl\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xxl\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xxl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xxl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xxl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xxl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xxl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xxl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xxl\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xxl\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xxl\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xxl\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xxl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xxl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xxl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xxl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xxl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xxl\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .xxl\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .xxl\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .xxl\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .xxl\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .xxl\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .xxl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .xxl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .xxl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .xxl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .xxl\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .xxl\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .xxl\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .xxl\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .xxl\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .xxl\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .xxl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .xxl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .xxl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .xxl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .xxl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .xxl\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .xxl\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .xxl\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .xxl\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .xxl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .xxl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .xxl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .xxl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .xxl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .xxl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .xxl\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .xxl\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .xxl\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .xxl\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .xxl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .xxl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .xxl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .xxl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .xxl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .xxl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .xxl\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .xxl\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .xxl\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .xxl\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .xxl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .xxl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .xxl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .xxl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .xxl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .xxl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .xxl\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .xxl\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .xxl\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .xxl\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .xxl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .xxl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .xxl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .xxl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .xxl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .xxl\:animate-none {
    animation: none;
  }

  .xxl\:animate-spin {
    animation: 1s linear infinite spin;
  }

  .xxl\:animate-ping {
    animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  }

  .xxl\:animate-pulse {
    animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
  }

  .xxl\:animate-bounce {
    animation: 1s infinite bounce;
  }

  .xxl\:cursor-auto {
    cursor: auto;
  }

  .xxl\:cursor-default {
    cursor: default;
  }

  .xxl\:cursor-pointer {
    cursor: pointer;
  }

  .xxl\:cursor-wait {
    cursor: wait;
  }

  .xxl\:cursor-text {
    cursor: text;
  }

  .xxl\:cursor-move {
    cursor: move;
  }

  .xxl\:cursor-help {
    cursor: help;
  }

  .xxl\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .xxl\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }

  .xxl\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }

  .xxl\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }

  .xxl\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }

  .xxl\:resize-none {
    resize: none;
  }

  .xxl\:resize-y {
    resize: vertical;
  }

  .xxl\:resize-x {
    resize: horizontal;
  }

  .xxl\:resize {
    resize: both;
  }

  .xxl\:list-inside {
    list-style-position: inside;
  }

  .xxl\:list-outside {
    list-style-position: outside;
  }

  .xxl\:list-none {
    list-style-type: none;
  }

  .xxl\:list-disc {
    list-style-type: disc;
  }

  .xxl\:list-decimal {
    list-style-type: decimal;
  }

  .xxl\:appearance-none {
    appearance: none;
  }

  .xxl\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xxl\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xxl\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xxl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xxl\:grid-flow-row {
    grid-auto-flow: row;
  }

  .xxl\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xxl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xxl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xxl\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xxl\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xxl\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xxl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xxl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xxl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xxl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xxl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xxl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xxl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xxl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xxl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xxl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xxl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xxl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xxl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xxl\:grid-cols-none {
    grid-template-columns: none;
  }

  .xxl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xxl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xxl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xxl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xxl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xxl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xxl\:grid-rows-none {
    grid-template-rows: none;
  }

  .xxl\:flex-row {
    flex-direction: row;
  }

  .xxl\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xxl\:flex-col {
    flex-direction: column;
  }

  .xxl\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xxl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xxl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xxl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xxl\:place-content-center {
    place-content: center;
  }

  .xxl\:place-content-start {
    place-content: start;
  }

  .xxl\:place-content-end {
    place-content: end;
  }

  .xxl\:place-content-between {
    place-content: space-between;
  }

  .xxl\:place-content-around {
    place-content: space-around;
  }

  .xxl\:place-content-evenly {
    place-content: space-evenly;
  }

  .xxl\:place-content-stretch {
    place-content: stretch;
  }

  .xxl\:place-items-start {
    place-items: start;
  }

  .xxl\:place-items-end {
    place-items: end;
  }

  .xxl\:place-items-center {
    place-items: center;
  }

  .xxl\:place-items-stretch {
    place-items: stretch stretch;
  }

  .xxl\:content-center {
    align-content: center;
  }

  .xxl\:content-start {
    align-content: flex-start;
  }

  .xxl\:content-end {
    align-content: flex-end;
  }

  .xxl\:content-between {
    align-content: space-between;
  }

  .xxl\:content-around {
    align-content: space-around;
  }

  .xxl\:content-evenly {
    align-content: space-evenly;
  }

  .xxl\:items-start {
    align-items: flex-start;
  }

  .xxl\:items-end {
    align-items: flex-end;
  }

  .xxl\:items-center {
    align-items: center;
  }

  .xxl\:items-baseline {
    align-items: baseline;
  }

  .xxl\:items-stretch {
    align-items: stretch;
  }

  .xxl\:justify-start {
    justify-content: flex-start;
  }

  .xxl\:justify-end {
    justify-content: flex-end;
  }

  .xxl\:justify-center {
    justify-content: center;
  }

  .xxl\:justify-between {
    justify-content: space-between;
  }

  .xxl\:justify-around {
    justify-content: space-around;
  }

  .xxl\:justify-evenly {
    justify-content: space-evenly;
  }

  .xxl\:justify-items-start {
    justify-items: start;
  }

  .xxl\:justify-items-end {
    justify-items: end;
  }

  .xxl\:justify-items-center {
    justify-items: center;
  }

  .xxl\:justify-items-stretch {
    justify-items: stretch;
  }

  .xxl\:gap-0 {
    gap: 0;
  }

  .xxl\:gap-1 {
    gap: .25rem;
  }

  .xxl\:gap-2 {
    gap: .5rem;
  }

  .xxl\:gap-3 {
    gap: .75rem;
  }

  .xxl\:gap-4 {
    gap: 1rem;
  }

  .xxl\:gap-5 {
    gap: 1.25rem;
  }

  .xxl\:gap-6 {
    gap: 1.5rem;
  }

  .xxl\:gap-7 {
    gap: 1.75rem;
  }

  .xxl\:gap-8 {
    gap: 2rem;
  }

  .xxl\:gap-9 {
    gap: 2.25rem;
  }

  .xxl\:gap-10 {
    gap: 2.5rem;
  }

  .xxl\:gap-11 {
    gap: 2.75rem;
  }

  .xxl\:gap-12 {
    gap: 3rem;
  }

  .xxl\:gap-14 {
    gap: 3.5rem;
  }

  .xxl\:gap-16 {
    gap: 4rem;
  }

  .xxl\:gap-20 {
    gap: 5rem;
  }

  .xxl\:gap-24 {
    gap: 6rem;
  }

  .xxl\:gap-28 {
    gap: 7rem;
  }

  .xxl\:gap-32 {
    gap: 8rem;
  }

  .xxl\:gap-36 {
    gap: 9rem;
  }

  .xxl\:gap-40 {
    gap: 10rem;
  }

  .xxl\:gap-44 {
    gap: 11rem;
  }

  .xxl\:gap-48 {
    gap: 12rem;
  }

  .xxl\:gap-52 {
    gap: 13rem;
  }

  .xxl\:gap-56 {
    gap: 14rem;
  }

  .xxl\:gap-60 {
    gap: 15rem;
  }

  .xxl\:gap-64 {
    gap: 16rem;
  }

  .xxl\:gap-72 {
    gap: 18rem;
  }

  .xxl\:gap-80 {
    gap: 20rem;
  }

  .xxl\:gap-96 {
    gap: 24rem;
  }

  .xxl\:gap-px {
    gap: 1px;
  }

  .xxl\:gap-0\.5 {
    gap: .125rem;
  }

  .xxl\:gap-1\.5 {
    gap: .375rem;
  }

  .xxl\:gap-2\.5 {
    gap: .625rem;
  }

  .xxl\:gap-3\.5 {
    gap: .875rem;
  }

  .xxl\:gap-x-0 {
    column-gap: 0;
  }

  .xxl\:gap-x-1 {
    column-gap: .25rem;
  }

  .xxl\:gap-x-2 {
    column-gap: .5rem;
  }

  .xxl\:gap-x-3 {
    column-gap: .75rem;
  }

  .xxl\:gap-x-4 {
    column-gap: 1rem;
  }

  .xxl\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xxl\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .xxl\:gap-x-7 {
    column-gap: 1.75rem;
  }

  .xxl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xxl\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .xxl\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .xxl\:gap-x-11 {
    column-gap: 2.75rem;
  }

  .xxl\:gap-x-12 {
    column-gap: 3rem;
  }

  .xxl\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .xxl\:gap-x-16 {
    column-gap: 4rem;
  }

  .xxl\:gap-x-20 {
    column-gap: 5rem;
  }

  .xxl\:gap-x-24 {
    column-gap: 6rem;
  }

  .xxl\:gap-x-28 {
    column-gap: 7rem;
  }

  .xxl\:gap-x-32 {
    column-gap: 8rem;
  }

  .xxl\:gap-x-36 {
    column-gap: 9rem;
  }

  .xxl\:gap-x-40 {
    column-gap: 10rem;
  }

  .xxl\:gap-x-44 {
    column-gap: 11rem;
  }

  .xxl\:gap-x-48 {
    column-gap: 12rem;
  }

  .xxl\:gap-x-52 {
    column-gap: 13rem;
  }

  .xxl\:gap-x-56 {
    column-gap: 14rem;
  }

  .xxl\:gap-x-60 {
    column-gap: 15rem;
  }

  .xxl\:gap-x-64 {
    column-gap: 16rem;
  }

  .xxl\:gap-x-72 {
    column-gap: 18rem;
  }

  .xxl\:gap-x-80 {
    column-gap: 20rem;
  }

  .xxl\:gap-x-96 {
    column-gap: 24rem;
  }

  .xxl\:gap-x-px {
    column-gap: 1px;
  }

  .xxl\:gap-x-0\.5 {
    column-gap: .125rem;
  }

  .xxl\:gap-x-1\.5 {
    column-gap: .375rem;
  }

  .xxl\:gap-x-2\.5 {
    column-gap: .625rem;
  }

  .xxl\:gap-x-3\.5 {
    column-gap: .875rem;
  }

  .xxl\:gap-y-0 {
    row-gap: 0;
  }

  .xxl\:gap-y-1 {
    row-gap: .25rem;
  }

  .xxl\:gap-y-2 {
    row-gap: .5rem;
  }

  .xxl\:gap-y-3 {
    row-gap: .75rem;
  }

  .xxl\:gap-y-4 {
    row-gap: 1rem;
  }

  .xxl\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .xxl\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .xxl\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .xxl\:gap-y-8 {
    row-gap: 2rem;
  }

  .xxl\:gap-y-9 {
    row-gap: 2.25rem;
  }

  .xxl\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .xxl\:gap-y-11 {
    row-gap: 2.75rem;
  }

  .xxl\:gap-y-12 {
    row-gap: 3rem;
  }

  .xxl\:gap-y-14 {
    row-gap: 3.5rem;
  }

  .xxl\:gap-y-16 {
    row-gap: 4rem;
  }

  .xxl\:gap-y-20 {
    row-gap: 5rem;
  }

  .xxl\:gap-y-24 {
    row-gap: 6rem;
  }

  .xxl\:gap-y-28 {
    row-gap: 7rem;
  }

  .xxl\:gap-y-32 {
    row-gap: 8rem;
  }

  .xxl\:gap-y-36 {
    row-gap: 9rem;
  }

  .xxl\:gap-y-40 {
    row-gap: 10rem;
  }

  .xxl\:gap-y-44 {
    row-gap: 11rem;
  }

  .xxl\:gap-y-48 {
    row-gap: 12rem;
  }

  .xxl\:gap-y-52 {
    row-gap: 13rem;
  }

  .xxl\:gap-y-56 {
    row-gap: 14rem;
  }

  .xxl\:gap-y-60 {
    row-gap: 15rem;
  }

  .xxl\:gap-y-64 {
    row-gap: 16rem;
  }

  .xxl\:gap-y-72 {
    row-gap: 18rem;
  }

  .xxl\:gap-y-80 {
    row-gap: 20rem;
  }

  .xxl\:gap-y-96 {
    row-gap: 24rem;
  }

  .xxl\:gap-y-px {
    row-gap: 1px;
  }

  .xxl\:gap-y-0\.5 {
    row-gap: .125rem;
  }

  .xxl\:gap-y-1\.5 {
    row-gap: .375rem;
  }

  .xxl\:gap-y-2\.5 {
    row-gap: .625rem;
  }

  .xxl\:gap-y-3\.5 {
    row-gap: .875rem;
  }

  .xxl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse));
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.875rem * var(--tw-space-x-reverse));
    margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-.625rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-.875rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xxl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.25rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.75rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.125rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.375rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.625rem * var(--tw-space-y-reverse));
  }

  .xxl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-.875rem * var(--tw-space-y-reverse));
  }

  .xxl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .xxl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .xxl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xxl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xxl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xxl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xxl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xxl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xxl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xxl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xxl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xxl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xxl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .xxl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .xxl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .xxl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .xxl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .xxl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .xxl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .xxl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: #0000;
  }

  .xxl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .xxl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }

  .xxl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }

  .xxl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }

  .xxl\:divide-red > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }

  .xxl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }

  .xxl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }

  .xxl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }

  .xxl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }

  .xxl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }

  .xxl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }

  .xxl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .xxl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .05;
  }

  .xxl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .1;
  }

  .xxl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .2;
  }

  .xxl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .25;
  }

  .xxl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .3;
  }

  .xxl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .4;
  }

  .xxl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .5;
  }

  .xxl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .6;
  }

  .xxl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .7;
  }

  .xxl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .75;
  }

  .xxl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .8;
  }

  .xxl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .9;
  }

  .xxl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: .95;
  }

  .xxl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .xxl\:place-self-auto {
    place-self: auto;
  }

  .xxl\:place-self-start {
    place-self: start;
  }

  .xxl\:place-self-end {
    place-self: end;
  }

  .xxl\:place-self-center {
    place-self: center;
  }

  .xxl\:place-self-stretch {
    place-self: stretch stretch;
  }

  .xxl\:self-auto {
    align-self: auto;
  }

  .xxl\:self-start {
    align-self: flex-start;
  }

  .xxl\:self-end {
    align-self: flex-end;
  }

  .xxl\:self-center {
    align-self: center;
  }

  .xxl\:self-stretch {
    align-self: stretch;
  }

  .xxl\:self-baseline {
    align-self: baseline;
  }

  .xxl\:justify-self-auto {
    justify-self: auto;
  }

  .xxl\:justify-self-start {
    justify-self: start;
  }

  .xxl\:justify-self-end {
    justify-self: end;
  }

  .xxl\:justify-self-center {
    justify-self: center;
  }

  .xxl\:justify-self-stretch {
    justify-self: stretch;
  }

  .xxl\:overflow-auto {
    overflow: auto;
  }

  .xxl\:overflow-hidden {
    overflow: hidden;
  }

  .xxl\:overflow-visible {
    overflow: visible;
  }

  .xxl\:overflow-scroll {
    overflow: scroll;
  }

  .xxl\:overflow-x-auto {
    overflow-x: auto;
  }

  .xxl\:overflow-y-auto {
    overflow-y: auto;
  }

  .xxl\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .xxl\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .xxl\:overflow-x-visible {
    overflow-x: visible;
  }

  .xxl\:overflow-y-visible {
    overflow-y: visible;
  }

  .xxl\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .xxl\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .xxl\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .xxl\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .xxl\:overscroll-none {
    overscroll-behavior: none;
  }

  .xxl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xxl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xxl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xxl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xxl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xxl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xxl\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .xxl\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .xxl\:overflow-clip {
    text-overflow: clip;
  }

  .xxl\:whitespace-normal {
    white-space: normal;
  }

  .xxl\:whitespace-nowrap {
    white-space: nowrap;
  }

  .xxl\:whitespace-pre {
    white-space: pre;
  }

  .xxl\:whitespace-pre-line {
    white-space: pre-line;
  }

  .xxl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xxl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .xxl\:break-words {
    overflow-wrap: break-word;
  }

  .xxl\:break-all {
    word-break: break-all;
  }

  .xxl\:rounded-none {
    border-radius: 0;
  }

  .xxl\:rounded-sm {
    border-radius: .125rem;
  }

  .xxl\:rounded {
    border-radius: .25rem;
  }

  .xxl\:rounded-md {
    border-radius: .375rem;
  }

  .xxl\:rounded-lg {
    border-radius: .5rem;
  }

  .xxl\:rounded-xl {
    border-radius: .75rem;
  }

  .xxl\:rounded-2xl {
    border-radius: 1rem;
  }

  .xxl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xxl\:rounded-full {
    border-radius: 9999px;
  }

  .xxl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xxl\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .xxl\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .xxl\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .xxl\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .xxl\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .xxl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xxl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xxl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xxl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xxl\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .xxl\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .xxl\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .xxl\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .xxl\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .xxl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xxl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xxl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xxl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xxl\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xxl\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xxl\:rounded-b-md {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xxl\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xxl\:rounded-b-xl {
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xxl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xxl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xxl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xxl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xxl\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xxl\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xxl\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xxl\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xxl\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xxl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xxl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xxl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xxl\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xxl\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .xxl\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .xxl\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .xxl\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .xxl\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .xxl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xxl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xxl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xxl\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xxl\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .xxl\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .xxl\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .xxl\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .xxl\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .xxl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xxl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xxl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xxl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xxl\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .xxl\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .xxl\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .xxl\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .xxl\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .xxl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xxl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xxl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xxl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xxl\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .xxl\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .xxl\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .xxl\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .xxl\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .xxl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xxl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xxl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xxl\:border-0 {
    border-width: 0;
  }

  .xxl\:border-2 {
    border-width: 2px;
  }

  .xxl\:border-4 {
    border-width: 4px;
  }

  .xxl\:border-8 {
    border-width: 8px;
  }

  .xxl\:border {
    border-width: 1px;
  }

  .xxl\:border-t-0 {
    border-top-width: 0;
  }

  .xxl\:border-t-2 {
    border-top-width: 2px;
  }

  .xxl\:border-t-4 {
    border-top-width: 4px;
  }

  .xxl\:border-t-8 {
    border-top-width: 8px;
  }

  .xxl\:border-t {
    border-top-width: 1px;
  }

  .xxl\:border-r-0 {
    border-right-width: 0;
  }

  .xxl\:border-r-2 {
    border-right-width: 2px;
  }

  .xxl\:border-r-4 {
    border-right-width: 4px;
  }

  .xxl\:border-r-8 {
    border-right-width: 8px;
  }

  .xxl\:border-r {
    border-right-width: 1px;
  }

  .xxl\:border-b-0 {
    border-bottom-width: 0;
  }

  .xxl\:border-b-2 {
    border-bottom-width: 2px;
  }

  .xxl\:border-b-4 {
    border-bottom-width: 4px;
  }

  .xxl\:border-b-8 {
    border-bottom-width: 8px;
  }

  .xxl\:border-b {
    border-bottom-width: 1px;
  }

  .xxl\:border-l-0 {
    border-left-width: 0;
  }

  .xxl\:border-l-2 {
    border-left-width: 2px;
  }

  .xxl\:border-l-4 {
    border-left-width: 4px;
  }

  .xxl\:border-l-8 {
    border-left-width: 8px;
  }

  .xxl\:border-l {
    border-left-width: 1px;
  }

  .xxl\:border-solid {
    border-style: solid;
  }

  .xxl\:border-dashed {
    border-style: dashed;
  }

  .xxl\:border-dotted {
    border-style: dotted;
  }

  .xxl\:border-double {
    border-style: double;
  }

  .xxl\:border-none {
    border-style: none;
  }

  .xxl\:border-transparent {
    border-color: #0000;
  }

  .xxl\:border-current {
    border-color: currentColor;
  }

  .xxl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xxl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xxl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xxl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xxl\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xxl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xxl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xxl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xxl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xxl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xxl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xxl\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xxl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xxl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xxl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xxl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xxl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xxl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xxl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xxl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xxl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xxl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xxl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xxl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xxl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xxl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xxl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xxl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xxl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xxl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xxl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xxl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xxl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xxl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xxl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xxl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xxl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xxl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xxl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xxl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xxl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xxl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xxl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xxl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xxl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xxl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xxl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xxl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xxl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xxl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xxl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xxl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xxl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xxl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .xxl\:group-hover\:border-current {
    border-color: currentColor;
  }

  .group:hover .xxl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-red {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .group:hover .xxl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-transparent:focus-within {
    border-color: #0000;
  }

  .xxl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }

  .xxl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-red:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xxl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .xxl\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .xxl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-red:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xxl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .xxl\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .xxl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-red:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 0, 0, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .xxl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .xxl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .xxl\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .xxl\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .xxl\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .xxl\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .xxl\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .xxl\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .xxl\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .xxl\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .xxl\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .xxl\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .xxl\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .xxl\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .xxl\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .xxl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .group:hover .xxl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .group:hover .xxl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: .05;
  }

  .group:hover .xxl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: .1;
  }

  .group:hover .xxl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: .2;
  }

  .group:hover .xxl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: .25;
  }

  .group:hover .xxl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: .3;
  }

  .group:hover .xxl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: .4;
  }

  .group:hover .xxl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .group:hover .xxl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: .6;
  }

  .group:hover .xxl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: .7;
  }

  .group:hover .xxl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: .75;
  }

  .group:hover .xxl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: .8;
  }

  .group:hover .xxl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: .9;
  }

  .group:hover .xxl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: .95;
  }

  .group:hover .xxl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .xxl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }

  .xxl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: .05;
  }

  .xxl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: .1;
  }

  .xxl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: .2;
  }

  .xxl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: .25;
  }

  .xxl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: .3;
  }

  .xxl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: .4;
  }

  .xxl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: .5;
  }

  .xxl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: .6;
  }

  .xxl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: .7;
  }

  .xxl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: .75;
  }

  .xxl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: .8;
  }

  .xxl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: .9;
  }

  .xxl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: .95;
  }

  .xxl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }

  .xxl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .xxl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: .05;
  }

  .xxl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: .1;
  }

  .xxl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: .2;
  }

  .xxl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: .25;
  }

  .xxl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: .3;
  }

  .xxl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: .4;
  }

  .xxl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: .5;
  }

  .xxl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: .6;
  }

  .xxl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: .7;
  }

  .xxl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: .75;
  }

  .xxl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: .8;
  }

  .xxl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: .9;
  }

  .xxl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: .95;
  }

  .xxl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .xxl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .xxl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: .05;
  }

  .xxl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: .1;
  }

  .xxl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: .2;
  }

  .xxl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: .25;
  }

  .xxl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: .3;
  }

  .xxl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: .4;
  }

  .xxl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: .5;
  }

  .xxl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: .6;
  }

  .xxl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: .7;
  }

  .xxl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: .75;
  }

  .xxl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: .8;
  }

  .xxl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: .9;
  }

  .xxl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: .95;
  }

  .xxl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .xxl\:bg-transparent {
    background-color: #0000;
  }

  .xxl\:bg-current {
    background-color: currentColor;
  }

  .xxl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xxl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xxl\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xxl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xxl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xxl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xxl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xxl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xxl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .xxl\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .group:hover .xxl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-red {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .group:hover .xxl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-transparent:focus-within {
    background-color: #0000;
  }

  .xxl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }

  .xxl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-red:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xxl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .xxl\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .xxl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-red:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xxl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .xxl\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .xxl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-red:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .xxl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .xxl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .xxl\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .xxl\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .xxl\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .xxl\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .xxl\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .xxl\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .xxl\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .xxl\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .xxl\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .xxl\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .xxl\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .xxl\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .xxl\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .xxl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: .05;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: .1;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: .2;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: .25;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: .3;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: .4;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: .5;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: .6;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: .7;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: .9;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: .95;
  }

  .group:hover .xxl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .xxl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }

  .xxl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: .05;
  }

  .xxl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: .1;
  }

  .xxl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: .2;
  }

  .xxl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: .25;
  }

  .xxl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: .3;
  }

  .xxl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: .4;
  }

  .xxl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: .5;
  }

  .xxl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: .6;
  }

  .xxl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: .7;
  }

  .xxl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: .75;
  }

  .xxl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: .8;
  }

  .xxl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: .9;
  }

  .xxl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: .95;
  }

  .xxl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }

  .xxl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .xxl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .xxl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: .1;
  }

  .xxl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: .2;
  }

  .xxl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: .25;
  }

  .xxl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: .3;
  }

  .xxl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: .4;
  }

  .xxl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: .5;
  }

  .xxl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: .6;
  }

  .xxl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: .7;
  }

  .xxl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: .75;
  }

  .xxl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: .8;
  }

  .xxl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: .9;
  }

  .xxl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: .95;
  }

  .xxl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .xxl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .xxl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: .05;
  }

  .xxl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: .1;
  }

  .xxl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: .2;
  }

  .xxl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: .25;
  }

  .xxl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: .3;
  }

  .xxl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: .4;
  }

  .xxl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: .5;
  }

  .xxl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: .6;
  }

  .xxl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: .7;
  }

  .xxl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: .75;
  }

  .xxl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: .8;
  }

  .xxl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: .9;
  }

  .xxl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: .95;
  }

  .xxl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .xxl\:bg-none {
    background-image: none;
  }

  .xxl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .xxl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }

  .xxl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xxl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xxl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xxl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xxl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .xxl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .xxl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .xxl\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .xxl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .xxl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .xxl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .xxl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .xxl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .xxl\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .xxl\:from-red {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .xxl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .xxl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .xxl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .xxl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .xxl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .xxl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .xxl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .xxl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .xxl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .xxl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .xxl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .xxl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .xxl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .xxl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .xxl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .xxl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .xxl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .xxl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .xxl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .xxl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .xxl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .xxl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .xxl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .xxl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .xxl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .xxl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .xxl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .xxl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .xxl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .xxl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .xxl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .xxl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .xxl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .xxl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .xxl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .xxl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .xxl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .xxl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .xxl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .xxl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .xxl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .xxl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .xxl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .xxl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .xxl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .xxl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .xxl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .xxl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .xxl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .xxl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .xxl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .xxl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .xxl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .xxl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .xxl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .xxl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .xxl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .xxl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .xxl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .xxl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .xxl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xxl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xxl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xxl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xxl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .xxl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .xxl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .xxl\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .xxl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .xxl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .xxl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .xxl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .xxl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .xxl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .xxl\:hover\:from-red:hover {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .xxl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .xxl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .xxl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .xxl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .xxl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .xxl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .xxl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .xxl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .xxl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .xxl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .xxl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .xxl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .xxl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .xxl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .xxl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .xxl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .xxl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .xxl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .xxl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .xxl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .xxl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .xxl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .xxl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .xxl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .xxl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .xxl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .xxl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .xxl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .xxl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .xxl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .xxl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .xxl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .xxl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .xxl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .xxl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .xxl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .xxl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .xxl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .xxl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .xxl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .xxl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .xxl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .xxl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .xxl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .xxl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .xxl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .xxl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .xxl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .xxl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .xxl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .xxl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .xxl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .xxl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .xxl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .xxl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .xxl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .xxl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .xxl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .xxl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .xxl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .xxl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xxl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xxl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #0000);
  }

  .xxl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fff0);
  }

  .xxl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9fafb00);
  }

  .xxl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f3f4f600);
  }

  .xxl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e5e7eb00);
  }

  .xxl\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1d5db00);
  }

  .xxl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9ca3af00);
  }

  .xxl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6b728000);
  }

  .xxl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4b556300);
  }

  .xxl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #37415100);
  }

  .xxl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1f293700);
  }

  .xxl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #11182700);
  }

  .xxl\:focus\:from-red:focus {
    --tw-gradient-from: red;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f000);
  }

  .xxl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fffbeb00);
  }

  .xxl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fef3c700);
  }

  .xxl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fde68a00);
  }

  .xxl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fcd34d00);
  }

  .xxl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbbf2400);
  }

  .xxl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f59e0b00);
  }

  .xxl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d9770600);
  }

  .xxl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #b4530900);
  }

  .xxl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #92400e00);
  }

  .xxl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #78350f00);
  }

  .xxl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ecfdf500);
  }

  .xxl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #d1fae500);
  }

  .xxl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a7f3d000);
  }

  .xxl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6ee7b700);
  }

  .xxl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #34d39900);
  }

  .xxl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #10b98100);
  }

  .xxl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #05966900);
  }

  .xxl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #04785700);
  }

  .xxl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #065f4600);
  }

  .xxl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #064e3b00);
  }

  .xxl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eff6ff00);
  }

  .xxl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #dbeafe00);
  }

  .xxl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #bfdbfe00);
  }

  .xxl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #93c5fd00);
  }

  .xxl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #60a5fa00);
  }

  .xxl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3b82f600);
  }

  .xxl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #2563eb00);
  }

  .xxl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1d4ed800);
  }

  .xxl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e40af00);
  }

  .xxl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #1e3a8a00);
  }

  .xxl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #eef2ff00);
  }

  .xxl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #e0e7ff00);
  }

  .xxl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c7d2fe00);
  }

  .xxl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a5b4fc00);
  }

  .xxl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #818cf800);
  }

  .xxl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6366f100);
  }

  .xxl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4f46e500);
  }

  .xxl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4338ca00);
  }

  .xxl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #3730a300);
  }

  .xxl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #312e8100);
  }

  .xxl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f5f3ff00);
  }

  .xxl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ede9fe00);
  }

  .xxl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ddd6fe00);
  }

  .xxl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #c4b5fd00);
  }

  .xxl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #a78bfa00);
  }

  .xxl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8b5cf600);
  }

  .xxl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #7c3aed00);
  }

  .xxl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #6d28d900);
  }

  .xxl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #5b21b600);
  }

  .xxl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #4c1d9500);
  }

  .xxl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fdf2f800);
  }

  .xxl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fce7f300);
  }

  .xxl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #fbcfe800);
  }

  .xxl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f9a8d400);
  }

  .xxl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #f472b600);
  }

  .xxl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #ec489900);
  }

  .xxl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #db277700);
  }

  .xxl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #be185d00);
  }

  .xxl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #9d174d00);
  }

  .xxl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #83184300);
  }

  .xxl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .xxl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .xxl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .xxl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .xxl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .xxl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .xxl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .xxl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .xxl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .xxl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .xxl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .xxl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .xxl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .xxl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .xxl\:via-red {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .xxl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .xxl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .xxl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .xxl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .xxl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .xxl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .xxl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .xxl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .xxl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .xxl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .xxl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .xxl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .xxl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .xxl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .xxl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .xxl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .xxl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .xxl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .xxl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .xxl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .xxl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .xxl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .xxl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .xxl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .xxl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .xxl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .xxl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .xxl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .xxl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .xxl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .xxl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .xxl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .xxl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .xxl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .xxl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .xxl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .xxl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .xxl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .xxl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .xxl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .xxl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .xxl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .xxl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .xxl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .xxl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .xxl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .xxl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .xxl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .xxl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .xxl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .xxl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .xxl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .xxl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .xxl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .xxl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .xxl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .xxl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .xxl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .xxl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .xxl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .xxl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .xxl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .xxl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .xxl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .xxl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .xxl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .xxl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .xxl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .xxl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .xxl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .xxl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .xxl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .xxl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .xxl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .xxl\:hover\:via-red:hover {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .xxl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .xxl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .xxl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .xxl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .xxl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .xxl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .xxl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .xxl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .xxl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .xxl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .xxl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .xxl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .xxl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .xxl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .xxl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .xxl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .xxl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .xxl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .xxl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .xxl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .xxl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .xxl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .xxl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .xxl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .xxl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .xxl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .xxl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .xxl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .xxl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .xxl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .xxl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .xxl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .xxl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .xxl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .xxl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .xxl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .xxl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .xxl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .xxl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .xxl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .xxl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .xxl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .xxl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .xxl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .xxl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .xxl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .xxl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .xxl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .xxl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .xxl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .xxl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .xxl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .xxl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .xxl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .xxl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .xxl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .xxl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .xxl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .xxl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .xxl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .xxl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, #0000);
  }

  .xxl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, #fff0);
  }

  .xxl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, #0000);
  }

  .xxl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, #fff0);
  }

  .xxl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, #f9fafb00);
  }

  .xxl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, #f3f4f600);
  }

  .xxl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, #e5e7eb00);
  }

  .xxl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, #d1d5db00);
  }

  .xxl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, #9ca3af00);
  }

  .xxl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, #6b728000);
  }

  .xxl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, #4b556300);
  }

  .xxl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, #37415100);
  }

  .xxl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, #1f293700);
  }

  .xxl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, #11182700);
  }

  .xxl\:focus\:via-red:focus {
    --tw-gradient-stops: var(--tw-gradient-from), red, var(--tw-gradient-to, #f000);
  }

  .xxl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, #fffbeb00);
  }

  .xxl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, #fef3c700);
  }

  .xxl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, #fde68a00);
  }

  .xxl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, #fcd34d00);
  }

  .xxl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, #fbbf2400);
  }

  .xxl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, #f59e0b00);
  }

  .xxl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, #d9770600);
  }

  .xxl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, #b4530900);
  }

  .xxl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, #92400e00);
  }

  .xxl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, #78350f00);
  }

  .xxl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, #ecfdf500);
  }

  .xxl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, #d1fae500);
  }

  .xxl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, #a7f3d000);
  }

  .xxl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, #6ee7b700);
  }

  .xxl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, #34d39900);
  }

  .xxl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, #10b98100);
  }

  .xxl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, #05966900);
  }

  .xxl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, #04785700);
  }

  .xxl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, #065f4600);
  }

  .xxl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, #064e3b00);
  }

  .xxl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, #eff6ff00);
  }

  .xxl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, #dbeafe00);
  }

  .xxl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, #bfdbfe00);
  }

  .xxl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, #93c5fd00);
  }

  .xxl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, #60a5fa00);
  }

  .xxl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, #3b82f600);
  }

  .xxl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, #2563eb00);
  }

  .xxl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, #1d4ed800);
  }

  .xxl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, #1e40af00);
  }

  .xxl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, #1e3a8a00);
  }

  .xxl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, #eef2ff00);
  }

  .xxl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, #e0e7ff00);
  }

  .xxl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, #c7d2fe00);
  }

  .xxl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, #a5b4fc00);
  }

  .xxl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, #818cf800);
  }

  .xxl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, #6366f100);
  }

  .xxl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, #4f46e500);
  }

  .xxl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, #4338ca00);
  }

  .xxl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, #3730a300);
  }

  .xxl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, #312e8100);
  }

  .xxl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, #f5f3ff00);
  }

  .xxl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, #ede9fe00);
  }

  .xxl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, #ddd6fe00);
  }

  .xxl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, #c4b5fd00);
  }

  .xxl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, #a78bfa00);
  }

  .xxl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, #8b5cf600);
  }

  .xxl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, #7c3aed00);
  }

  .xxl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, #6d28d900);
  }

  .xxl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, #5b21b600);
  }

  .xxl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, #4c1d9500);
  }

  .xxl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, #fdf2f800);
  }

  .xxl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, #fce7f300);
  }

  .xxl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, #fbcfe800);
  }

  .xxl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, #f9a8d400);
  }

  .xxl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, #f472b600);
  }

  .xxl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, #ec489900);
  }

  .xxl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, #db277700);
  }

  .xxl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, #be185d00);
  }

  .xxl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, #9d174d00);
  }

  .xxl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, #83184300);
  }

  .xxl\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .xxl\:to-current {
    --tw-gradient-to: currentColor;
  }

  .xxl\:to-black {
    --tw-gradient-to: #000;
  }

  .xxl\:to-white {
    --tw-gradient-to: #fff;
  }

  .xxl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }

  .xxl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }

  .xxl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }

  .xxl\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }

  .xxl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }

  .xxl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }

  .xxl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }

  .xxl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }

  .xxl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }

  .xxl\:to-gray-900 {
    --tw-gradient-to: #111827;
  }

  .xxl\:to-red {
    --tw-gradient-to: red;
  }

  .xxl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }

  .xxl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }

  .xxl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }

  .xxl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }

  .xxl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }

  .xxl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }

  .xxl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }

  .xxl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }

  .xxl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }

  .xxl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }

  .xxl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }

  .xxl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }

  .xxl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }

  .xxl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }

  .xxl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }

  .xxl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }

  .xxl\:to-green-600 {
    --tw-gradient-to: #059669;
  }

  .xxl\:to-green-700 {
    --tw-gradient-to: #047857;
  }

  .xxl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }

  .xxl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }

  .xxl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }

  .xxl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }

  .xxl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }

  .xxl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }

  .xxl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }

  .xxl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }

  .xxl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }

  .xxl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }

  .xxl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }

  .xxl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }

  .xxl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }

  .xxl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }

  .xxl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }

  .xxl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }

  .xxl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }

  .xxl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }

  .xxl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }

  .xxl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }

  .xxl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }

  .xxl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }

  .xxl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }

  .xxl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }

  .xxl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }

  .xxl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }

  .xxl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }

  .xxl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }

  .xxl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }

  .xxl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }

  .xxl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }

  .xxl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }

  .xxl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }

  .xxl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }

  .xxl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }

  .xxl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }

  .xxl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }

  .xxl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }

  .xxl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }

  .xxl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }

  .xxl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }

  .xxl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }

  .xxl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .xxl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .xxl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }

  .xxl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .xxl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }

  .xxl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }

  .xxl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }

  .xxl\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }

  .xxl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }

  .xxl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }

  .xxl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }

  .xxl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }

  .xxl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }

  .xxl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }

  .xxl\:hover\:to-red:hover {
    --tw-gradient-to: red;
  }

  .xxl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }

  .xxl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }

  .xxl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }

  .xxl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }

  .xxl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }

  .xxl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }

  .xxl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }

  .xxl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }

  .xxl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }

  .xxl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }

  .xxl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }

  .xxl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }

  .xxl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }

  .xxl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }

  .xxl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }

  .xxl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }

  .xxl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }

  .xxl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }

  .xxl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }

  .xxl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }

  .xxl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }

  .xxl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }

  .xxl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }

  .xxl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }

  .xxl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }

  .xxl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }

  .xxl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }

  .xxl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }

  .xxl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }

  .xxl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }

  .xxl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }

  .xxl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }

  .xxl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }

  .xxl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }

  .xxl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }

  .xxl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }

  .xxl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }

  .xxl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }

  .xxl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }

  .xxl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }

  .xxl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }

  .xxl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }

  .xxl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }

  .xxl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }

  .xxl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }

  .xxl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }

  .xxl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }

  .xxl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }

  .xxl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }

  .xxl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }

  .xxl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }

  .xxl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }

  .xxl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }

  .xxl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }

  .xxl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }

  .xxl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }

  .xxl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }

  .xxl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }

  .xxl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }

  .xxl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }

  .xxl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .xxl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .xxl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }

  .xxl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .xxl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }

  .xxl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }

  .xxl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }

  .xxl\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }

  .xxl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }

  .xxl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }

  .xxl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }

  .xxl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }

  .xxl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }

  .xxl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }

  .xxl\:focus\:to-red:focus {
    --tw-gradient-to: red;
  }

  .xxl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }

  .xxl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }

  .xxl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }

  .xxl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }

  .xxl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }

  .xxl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }

  .xxl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }

  .xxl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }

  .xxl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }

  .xxl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }

  .xxl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }

  .xxl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }

  .xxl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }

  .xxl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }

  .xxl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }

  .xxl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }

  .xxl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }

  .xxl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }

  .xxl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }

  .xxl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }

  .xxl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }

  .xxl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }

  .xxl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }

  .xxl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }

  .xxl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }

  .xxl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }

  .xxl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }

  .xxl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }

  .xxl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }

  .xxl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }

  .xxl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }

  .xxl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }

  .xxl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }

  .xxl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }

  .xxl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }

  .xxl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }

  .xxl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }

  .xxl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }

  .xxl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }

  .xxl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }

  .xxl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }

  .xxl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }

  .xxl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }

  .xxl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }

  .xxl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }

  .xxl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }

  .xxl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }

  .xxl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }

  .xxl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }

  .xxl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }

  .xxl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }

  .xxl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }

  .xxl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }

  .xxl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }

  .xxl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }

  .xxl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }

  .xxl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }

  .xxl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }

  .xxl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }

  .xxl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }

  .xxl\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }

  .xxl\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .xxl\:bg-auto {
    background-size: auto;
  }

  .xxl\:bg-cover {
    background-size: cover;
  }

  .xxl\:bg-contain {
    background-size: contain;
  }

  .xxl\:bg-fixed {
    background-attachment: fixed;
  }

  .xxl\:bg-local {
    background-attachment: local;
  }

  .xxl\:bg-scroll {
    background-attachment: scroll;
  }

  .xxl\:bg-clip-border {
    background-clip: border-box;
  }

  .xxl\:bg-clip-padding {
    background-clip: padding-box;
  }

  .xxl\:bg-clip-content {
    background-clip: content-box;
  }

  .xxl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }

  .xxl\:bg-bottom {
    background-position: bottom;
  }

  .xxl\:bg-center {
    background-position: center;
  }

  .xxl\:bg-left {
    background-position: 0;
  }

  .xxl\:bg-left-bottom {
    background-position: 0 100%;
  }

  .xxl\:bg-left-top {
    background-position: 0 0;
  }

  .xxl\:bg-right {
    background-position: 100%;
  }

  .xxl\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .xxl\:bg-right-top {
    background-position: 100% 0;
  }

  .xxl\:bg-top {
    background-position: top;
  }

  .xxl\:bg-repeat {
    background-repeat: repeat;
  }

  .xxl\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xxl\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xxl\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xxl\:bg-repeat-round {
    background-repeat: round;
  }

  .xxl\:bg-repeat-space {
    background-repeat: space;
  }

  .xxl\:bg-origin-border {
    background-origin: border-box;
  }

  .xxl\:bg-origin-padding {
    background-origin: padding-box;
  }

  .xxl\:bg-origin-content {
    background-origin: content-box;
  }

  .xxl\:fill-current {
    fill: currentColor;
  }

  .xxl\:stroke-current {
    stroke: currentColor;
  }

  .xxl\:stroke-0 {
    stroke-width: 0;
  }

  .xxl\:stroke-1 {
    stroke-width: 1px;
  }

  .xxl\:stroke-2 {
    stroke-width: 2px;
  }

  .xxl\:object-contain {
    object-fit: contain;
  }

  .xxl\:object-cover {
    object-fit: cover;
  }

  .xxl\:object-fill {
    object-fit: fill;
  }

  .xxl\:object-none {
    object-fit: none;
  }

  .xxl\:object-scale-down {
    object-fit: scale-down;
  }

  .xxl\:object-bottom {
    object-position: bottom;
  }

  .xxl\:object-center {
    object-position: center;
  }

  .xxl\:object-left {
    object-position: left;
  }

  .xxl\:object-left-bottom {
    object-position: left bottom;
  }

  .xxl\:object-left-top {
    object-position: left top;
  }

  .xxl\:object-right {
    object-position: right;
  }

  .xxl\:object-right-bottom {
    object-position: right bottom;
  }

  .xxl\:object-right-top {
    object-position: right top;
  }

  .xxl\:object-top {
    object-position: top;
  }

  .xxl\:p-0 {
    padding: 0;
  }

  .xxl\:p-1 {
    padding: .25rem;
  }

  .xxl\:p-2 {
    padding: .5rem;
  }

  .xxl\:p-3 {
    padding: .75rem;
  }

  .xxl\:p-4 {
    padding: 1rem;
  }

  .xxl\:p-5 {
    padding: 1.25rem;
  }

  .xxl\:p-6 {
    padding: 1.5rem;
  }

  .xxl\:p-7 {
    padding: 1.75rem;
  }

  .xxl\:p-8 {
    padding: 2rem;
  }

  .xxl\:p-9 {
    padding: 2.25rem;
  }

  .xxl\:p-10 {
    padding: 2.5rem;
  }

  .xxl\:p-11 {
    padding: 2.75rem;
  }

  .xxl\:p-12 {
    padding: 3rem;
  }

  .xxl\:p-14 {
    padding: 3.5rem;
  }

  .xxl\:p-16 {
    padding: 4rem;
  }

  .xxl\:p-20 {
    padding: 5rem;
  }

  .xxl\:p-24 {
    padding: 6rem;
  }

  .xxl\:p-28 {
    padding: 7rem;
  }

  .xxl\:p-32 {
    padding: 8rem;
  }

  .xxl\:p-36 {
    padding: 9rem;
  }

  .xxl\:p-40 {
    padding: 10rem;
  }

  .xxl\:p-44 {
    padding: 11rem;
  }

  .xxl\:p-48 {
    padding: 12rem;
  }

  .xxl\:p-52 {
    padding: 13rem;
  }

  .xxl\:p-56 {
    padding: 14rem;
  }

  .xxl\:p-60 {
    padding: 15rem;
  }

  .xxl\:p-64 {
    padding: 16rem;
  }

  .xxl\:p-72 {
    padding: 18rem;
  }

  .xxl\:p-80 {
    padding: 20rem;
  }

  .xxl\:p-96 {
    padding: 24rem;
  }

  .xxl\:p-px {
    padding: 1px;
  }

  .xxl\:p-0\.5 {
    padding: .125rem;
  }

  .xxl\:p-1\.5 {
    padding: .375rem;
  }

  .xxl\:p-2\.5 {
    padding: .625rem;
  }

  .xxl\:p-3\.5 {
    padding: .875rem;
  }

  .xxl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xxl\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .xxl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xxl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xxl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xxl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xxl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xxl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .xxl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xxl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xxl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xxl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .xxl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xxl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xxl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xxl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xxl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xxl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xxl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xxl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .xxl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xxl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .xxl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xxl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .xxl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xxl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .xxl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xxl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .xxl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .xxl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .xxl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xxl\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .xxl\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .xxl\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .xxl\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .xxl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xxl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .xxl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xxl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xxl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xxl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xxl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xxl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .xxl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xxl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .xxl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xxl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .xxl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xxl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xxl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xxl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xxl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xxl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xxl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xxl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .xxl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xxl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .xxl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xxl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .xxl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xxl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .xxl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xxl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .xxl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .xxl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .xxl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xxl\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .xxl\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .xxl\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .xxl\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .xxl\:pt-0 {
    padding-top: 0;
  }

  .xxl\:pt-1 {
    padding-top: .25rem;
  }

  .xxl\:pt-2 {
    padding-top: .5rem;
  }

  .xxl\:pt-3 {
    padding-top: .75rem;
  }

  .xxl\:pt-4 {
    padding-top: 1rem;
  }

  .xxl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xxl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xxl\:pt-7 {
    padding-top: 1.75rem;
  }

  .xxl\:pt-8 {
    padding-top: 2rem;
  }

  .xxl\:pt-9 {
    padding-top: 2.25rem;
  }

  .xxl\:pt-10 {
    padding-top: 2.5rem;
  }

  .xxl\:pt-11 {
    padding-top: 2.75rem;
  }

  .xxl\:pt-12 {
    padding-top: 3rem;
  }

  .xxl\:pt-14 {
    padding-top: 3.5rem;
  }

  .xxl\:pt-16 {
    padding-top: 4rem;
  }

  .xxl\:pt-20 {
    padding-top: 5rem;
  }

  .xxl\:pt-24 {
    padding-top: 6rem;
  }

  .xxl\:pt-28 {
    padding-top: 7rem;
  }

  .xxl\:pt-32 {
    padding-top: 8rem;
  }

  .xxl\:pt-36 {
    padding-top: 9rem;
  }

  .xxl\:pt-40 {
    padding-top: 10rem;
  }

  .xxl\:pt-44 {
    padding-top: 11rem;
  }

  .xxl\:pt-48 {
    padding-top: 12rem;
  }

  .xxl\:pt-52 {
    padding-top: 13rem;
  }

  .xxl\:pt-56 {
    padding-top: 14rem;
  }

  .xxl\:pt-60 {
    padding-top: 15rem;
  }

  .xxl\:pt-64 {
    padding-top: 16rem;
  }

  .xxl\:pt-72 {
    padding-top: 18rem;
  }

  .xxl\:pt-80 {
    padding-top: 20rem;
  }

  .xxl\:pt-96 {
    padding-top: 24rem;
  }

  .xxl\:pt-px {
    padding-top: 1px;
  }

  .xxl\:pt-0\.5 {
    padding-top: .125rem;
  }

  .xxl\:pt-1\.5 {
    padding-top: .375rem;
  }

  .xxl\:pt-2\.5 {
    padding-top: .625rem;
  }

  .xxl\:pt-3\.5 {
    padding-top: .875rem;
  }

  .xxl\:pr-0 {
    padding-right: 0;
  }

  .xxl\:pr-1 {
    padding-right: .25rem;
  }

  .xxl\:pr-2 {
    padding-right: .5rem;
  }

  .xxl\:pr-3 {
    padding-right: .75rem;
  }

  .xxl\:pr-4 {
    padding-right: 1rem;
  }

  .xxl\:pr-5 {
    padding-right: 1.25rem;
  }

  .xxl\:pr-6 {
    padding-right: 1.5rem;
  }

  .xxl\:pr-7 {
    padding-right: 1.75rem;
  }

  .xxl\:pr-8 {
    padding-right: 2rem;
  }

  .xxl\:pr-9 {
    padding-right: 2.25rem;
  }

  .xxl\:pr-10 {
    padding-right: 2.5rem;
  }

  .xxl\:pr-11 {
    padding-right: 2.75rem;
  }

  .xxl\:pr-12 {
    padding-right: 3rem;
  }

  .xxl\:pr-14 {
    padding-right: 3.5rem;
  }

  .xxl\:pr-16 {
    padding-right: 4rem;
  }

  .xxl\:pr-20 {
    padding-right: 5rem;
  }

  .xxl\:pr-24 {
    padding-right: 6rem;
  }

  .xxl\:pr-28 {
    padding-right: 7rem;
  }

  .xxl\:pr-32 {
    padding-right: 8rem;
  }

  .xxl\:pr-36 {
    padding-right: 9rem;
  }

  .xxl\:pr-40 {
    padding-right: 10rem;
  }

  .xxl\:pr-44 {
    padding-right: 11rem;
  }

  .xxl\:pr-48 {
    padding-right: 12rem;
  }

  .xxl\:pr-52 {
    padding-right: 13rem;
  }

  .xxl\:pr-56 {
    padding-right: 14rem;
  }

  .xxl\:pr-60 {
    padding-right: 15rem;
  }

  .xxl\:pr-64 {
    padding-right: 16rem;
  }

  .xxl\:pr-72 {
    padding-right: 18rem;
  }

  .xxl\:pr-80 {
    padding-right: 20rem;
  }

  .xxl\:pr-96 {
    padding-right: 24rem;
  }

  .xxl\:pr-px {
    padding-right: 1px;
  }

  .xxl\:pr-0\.5 {
    padding-right: .125rem;
  }

  .xxl\:pr-1\.5 {
    padding-right: .375rem;
  }

  .xxl\:pr-2\.5 {
    padding-right: .625rem;
  }

  .xxl\:pr-3\.5 {
    padding-right: .875rem;
  }

  .xxl\:pb-0 {
    padding-bottom: 0;
  }

  .xxl\:pb-1 {
    padding-bottom: .25rem;
  }

  .xxl\:pb-2 {
    padding-bottom: .5rem;
  }

  .xxl\:pb-3 {
    padding-bottom: .75rem;
  }

  .xxl\:pb-4 {
    padding-bottom: 1rem;
  }

  .xxl\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xxl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xxl\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .xxl\:pb-8 {
    padding-bottom: 2rem;
  }

  .xxl\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .xxl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xxl\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .xxl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xxl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xxl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xxl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xxl\:pb-24 {
    padding-bottom: 6rem;
  }

  .xxl\:pb-28 {
    padding-bottom: 7rem;
  }

  .xxl\:pb-32 {
    padding-bottom: 8rem;
  }

  .xxl\:pb-36 {
    padding-bottom: 9rem;
  }

  .xxl\:pb-40 {
    padding-bottom: 10rem;
  }

  .xxl\:pb-44 {
    padding-bottom: 11rem;
  }

  .xxl\:pb-48 {
    padding-bottom: 12rem;
  }

  .xxl\:pb-52 {
    padding-bottom: 13rem;
  }

  .xxl\:pb-56 {
    padding-bottom: 14rem;
  }

  .xxl\:pb-60 {
    padding-bottom: 15rem;
  }

  .xxl\:pb-64 {
    padding-bottom: 16rem;
  }

  .xxl\:pb-72 {
    padding-bottom: 18rem;
  }

  .xxl\:pb-80 {
    padding-bottom: 20rem;
  }

  .xxl\:pb-96 {
    padding-bottom: 24rem;
  }

  .xxl\:pb-px {
    padding-bottom: 1px;
  }

  .xxl\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .xxl\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .xxl\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .xxl\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .xxl\:pl-0 {
    padding-left: 0;
  }

  .xxl\:pl-1 {
    padding-left: .25rem;
  }

  .xxl\:pl-2 {
    padding-left: .5rem;
  }

  .xxl\:pl-3 {
    padding-left: .75rem;
  }

  .xxl\:pl-4 {
    padding-left: 1rem;
  }

  .xxl\:pl-5 {
    padding-left: 1.25rem;
  }

  .xxl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xxl\:pl-7 {
    padding-left: 1.75rem;
  }

  .xxl\:pl-8 {
    padding-left: 2rem;
  }

  .xxl\:pl-9 {
    padding-left: 2.25rem;
  }

  .xxl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xxl\:pl-11 {
    padding-left: 2.75rem;
  }

  .xxl\:pl-12 {
    padding-left: 3rem;
  }

  .xxl\:pl-14 {
    padding-left: 3.5rem;
  }

  .xxl\:pl-16 {
    padding-left: 4rem;
  }

  .xxl\:pl-20 {
    padding-left: 5rem;
  }

  .xxl\:pl-24 {
    padding-left: 6rem;
  }

  .xxl\:pl-28 {
    padding-left: 7rem;
  }

  .xxl\:pl-32 {
    padding-left: 8rem;
  }

  .xxl\:pl-36 {
    padding-left: 9rem;
  }

  .xxl\:pl-40 {
    padding-left: 10rem;
  }

  .xxl\:pl-44 {
    padding-left: 11rem;
  }

  .xxl\:pl-48 {
    padding-left: 12rem;
  }

  .xxl\:pl-52 {
    padding-left: 13rem;
  }

  .xxl\:pl-56 {
    padding-left: 14rem;
  }

  .xxl\:pl-60 {
    padding-left: 15rem;
  }

  .xxl\:pl-64 {
    padding-left: 16rem;
  }

  .xxl\:pl-72 {
    padding-left: 18rem;
  }

  .xxl\:pl-80 {
    padding-left: 20rem;
  }

  .xxl\:pl-96 {
    padding-left: 24rem;
  }

  .xxl\:pl-px {
    padding-left: 1px;
  }

  .xxl\:pl-0\.5 {
    padding-left: .125rem;
  }

  .xxl\:pl-1\.5 {
    padding-left: .375rem;
  }

  .xxl\:pl-2\.5 {
    padding-left: .625rem;
  }

  .xxl\:pl-3\.5 {
    padding-left: .875rem;
  }

  .xxl\:text-left {
    text-align: left;
  }

  .xxl\:text-center {
    text-align: center;
  }

  .xxl\:text-right {
    text-align: right;
  }

  .xxl\:text-justify {
    text-align: justify;
  }

  .xxl\:align-baseline {
    vertical-align: baseline;
  }

  .xxl\:align-top {
    vertical-align: top;
  }

  .xxl\:align-middle {
    vertical-align: middle;
  }

  .xxl\:align-bottom {
    vertical-align: bottom;
  }

  .xxl\:align-text-top {
    vertical-align: text-top;
  }

  .xxl\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .xxl\:font-body {
    font-family: Montserrat;
  }

  .xxl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .xxl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xxl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xxl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xxl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xxl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xxl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xxl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xxl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xxl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xxl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .xxl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .xxl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .xxl\:font-thin {
    font-weight: 100;
  }

  .xxl\:font-extralight {
    font-weight: 200;
  }

  .xxl\:font-light {
    font-weight: 300;
  }

  .xxl\:font-normal {
    font-weight: 400;
  }

  .xxl\:font-medium {
    font-weight: 500;
  }

  .xxl\:font-semibold {
    font-weight: 600;
  }

  .xxl\:font-bold {
    font-weight: 700;
  }

  .xxl\:font-extrabold {
    font-weight: 800;
  }

  .xxl\:font-black {
    font-weight: 900;
  }

  .xxl\:uppercase {
    text-transform: uppercase;
  }

  .xxl\:lowercase {
    text-transform: lowercase;
  }

  .xxl\:capitalize {
    text-transform: capitalize;
  }

  .xxl\:normal-case {
    text-transform: none;
  }

  .xxl\:italic {
    font-style: italic;
  }

  .xxl\:not-italic {
    font-style: normal;
  }

  .xxl\:ordinal, .xxl\:slashed-zero, .xxl\:lining-nums, .xxl\:oldstyle-nums, .xxl\:proportional-nums, .xxl\:tabular-nums, .xxl\:diagonal-fractions, .xxl\:stacked-fractions {
    --tw-ordinal: var(--tw-empty, );
    --tw-slashed-zero: var(--tw-empty, );
    --tw-numeric-figure: var(--tw-empty, );
    --tw-numeric-spacing: var(--tw-empty, );
    --tw-numeric-fraction: var(--tw-empty, );
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xxl\:normal-nums {
    font-variant-numeric: normal;
  }

  .xxl\:ordinal {
    --tw-ordinal: ordinal;
  }

  .xxl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .xxl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .xxl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .xxl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .xxl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .xxl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .xxl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .xxl\:leading-3 {
    line-height: .75rem;
  }

  .xxl\:leading-4 {
    line-height: 1rem;
  }

  .xxl\:leading-5 {
    line-height: 1.25rem;
  }

  .xxl\:leading-6 {
    line-height: 1.5rem;
  }

  .xxl\:leading-7 {
    line-height: 1.75rem;
  }

  .xxl\:leading-8 {
    line-height: 2rem;
  }

  .xxl\:leading-9 {
    line-height: 2.25rem;
  }

  .xxl\:leading-10 {
    line-height: 2.5rem;
  }

  .xxl\:leading-none {
    line-height: 1;
  }

  .xxl\:leading-tight {
    line-height: 1.25;
  }

  .xxl\:leading-snug {
    line-height: 1.375;
  }

  .xxl\:leading-normal {
    line-height: 1.5;
  }

  .xxl\:leading-relaxed {
    line-height: 1.625;
  }

  .xxl\:leading-loose {
    line-height: 2;
  }

  .xxl\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .xxl\:tracking-tight {
    letter-spacing: -.025em;
  }

  .xxl\:tracking-normal {
    letter-spacing: 0;
  }

  .xxl\:tracking-wide {
    letter-spacing: .025em;
  }

  .xxl\:tracking-wider {
    letter-spacing: .05em;
  }

  .xxl\:tracking-widest {
    letter-spacing: .1em;
  }

  .xxl\:text-transparent {
    color: #0000;
  }

  .xxl\:text-current {
    color: currentColor;
  }

  .xxl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xxl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xxl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xxl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xxl\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xxl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xxl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xxl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xxl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xxl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xxl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xxl\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xxl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xxl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xxl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xxl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xxl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xxl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xxl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xxl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xxl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xxl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xxl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xxl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xxl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xxl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xxl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xxl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xxl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xxl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xxl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xxl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xxl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xxl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xxl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xxl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xxl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xxl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xxl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xxl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xxl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xxl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xxl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xxl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xxl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xxl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xxl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xxl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xxl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xxl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xxl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xxl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xxl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xxl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .xxl\:group-hover\:text-current {
    color: currentColor;
  }

  .group:hover .xxl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-red {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .group:hover .xxl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .xxl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }

  .xxl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-red:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xxl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-transparent:hover {
    color: #0000;
  }

  .xxl\:hover\:text-current:hover {
    color: currentColor;
  }

  .xxl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-red:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xxl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-transparent:focus {
    color: #0000;
  }

  .xxl\:focus\:text-current:focus {
    color: currentColor;
  }

  .xxl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-red:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .xxl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .xxl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .xxl\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .xxl\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .xxl\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .xxl\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .xxl\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .xxl\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .xxl\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .xxl\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .xxl\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .xxl\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .xxl\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .xxl\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .xxl\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .xxl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .group:hover .xxl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .group:hover .xxl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: .05;
  }

  .group:hover .xxl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: .1;
  }

  .group:hover .xxl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: .2;
  }

  .group:hover .xxl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: .25;
  }

  .group:hover .xxl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: .3;
  }

  .group:hover .xxl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: .4;
  }

  .group:hover .xxl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: .5;
  }

  .group:hover .xxl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: .6;
  }

  .group:hover .xxl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: .7;
  }

  .group:hover .xxl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: .75;
  }

  .group:hover .xxl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: .8;
  }

  .group:hover .xxl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: .9;
  }

  .group:hover .xxl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: .95;
  }

  .group:hover .xxl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .xxl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }

  .xxl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: .05;
  }

  .xxl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: .1;
  }

  .xxl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: .2;
  }

  .xxl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: .25;
  }

  .xxl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: .3;
  }

  .xxl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: .4;
  }

  .xxl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: .5;
  }

  .xxl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: .6;
  }

  .xxl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: .7;
  }

  .xxl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: .75;
  }

  .xxl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: .8;
  }

  .xxl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: .9;
  }

  .xxl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: .95;
  }

  .xxl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }

  .xxl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .xxl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: .05;
  }

  .xxl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: .1;
  }

  .xxl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: .2;
  }

  .xxl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: .25;
  }

  .xxl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: .3;
  }

  .xxl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: .4;
  }

  .xxl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: .5;
  }

  .xxl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: .6;
  }

  .xxl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: .7;
  }

  .xxl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: .75;
  }

  .xxl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: .8;
  }

  .xxl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: .9;
  }

  .xxl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: .95;
  }

  .xxl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .xxl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .xxl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: .05;
  }

  .xxl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: .1;
  }

  .xxl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: .2;
  }

  .xxl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: .25;
  }

  .xxl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: .3;
  }

  .xxl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: .4;
  }

  .xxl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: .5;
  }

  .xxl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: .6;
  }

  .xxl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: .7;
  }

  .xxl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: .75;
  }

  .xxl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: .8;
  }

  .xxl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: .9;
  }

  .xxl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: .95;
  }

  .xxl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .xxl\:underline {
    text-decoration: underline;
  }

  .xxl\:line-through {
    text-decoration: line-through;
  }

  .xxl\:no-underline {
    text-decoration: none;
  }

  .group:hover .xxl\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .xxl\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .xxl\:group-hover\:no-underline {
    text-decoration: none;
  }

  .xxl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }

  .xxl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }

  .xxl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }

  .xxl\:hover\:underline:hover {
    text-decoration: underline;
  }

  .xxl\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .xxl\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .xxl\:focus\:underline:focus {
    text-decoration: underline;
  }

  .xxl\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .xxl\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .xxl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xxl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xxl\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .xxl\:placeholder-current::placeholder {
    color: currentColor;
  }

  .xxl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-red::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .xxl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .xxl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-red:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }

  .xxl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }

  .xxl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xxl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .xxl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .xxl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .xxl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .xxl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .xxl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .xxl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .xxl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .xxl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .xxl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .xxl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .xxl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .xxl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .xxl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xxl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xxl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: .05;
  }

  .xxl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: .1;
  }

  .xxl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: .2;
  }

  .xxl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: .25;
  }

  .xxl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: .3;
  }

  .xxl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: .4;
  }

  .xxl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: .5;
  }

  .xxl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: .6;
  }

  .xxl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: .7;
  }

  .xxl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: .75;
  }

  .xxl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: .8;
  }

  .xxl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: .9;
  }

  .xxl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: .95;
  }

  .xxl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xxl\:opacity-0 {
    opacity: 0;
  }

  .xxl\:opacity-5 {
    opacity: .05;
  }

  .xxl\:opacity-10 {
    opacity: .1;
  }

  .xxl\:opacity-20 {
    opacity: .2;
  }

  .xxl\:opacity-25 {
    opacity: .25;
  }

  .xxl\:opacity-30 {
    opacity: .3;
  }

  .xxl\:opacity-40 {
    opacity: .4;
  }

  .xxl\:opacity-50 {
    opacity: .5;
  }

  .xxl\:opacity-60 {
    opacity: .6;
  }

  .xxl\:opacity-70 {
    opacity: .7;
  }

  .xxl\:opacity-75 {
    opacity: .75;
  }

  .xxl\:opacity-80 {
    opacity: .8;
  }

  .xxl\:opacity-90 {
    opacity: .9;
  }

  .xxl\:opacity-95 {
    opacity: .95;
  }

  .xxl\:opacity-100 {
    opacity: 1;
  }

  .group:hover .xxl\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .group:hover .xxl\:group-hover\:opacity-5 {
    opacity: .05;
  }

  .group:hover .xxl\:group-hover\:opacity-10 {
    opacity: .1;
  }

  .group:hover .xxl\:group-hover\:opacity-20 {
    opacity: .2;
  }

  .group:hover .xxl\:group-hover\:opacity-25 {
    opacity: .25;
  }

  .group:hover .xxl\:group-hover\:opacity-30 {
    opacity: .3;
  }

  .group:hover .xxl\:group-hover\:opacity-40 {
    opacity: .4;
  }

  .group:hover .xxl\:group-hover\:opacity-50 {
    opacity: .5;
  }

  .group:hover .xxl\:group-hover\:opacity-60 {
    opacity: .6;
  }

  .group:hover .xxl\:group-hover\:opacity-70 {
    opacity: .7;
  }

  .group:hover .xxl\:group-hover\:opacity-75 {
    opacity: .75;
  }

  .group:hover .xxl\:group-hover\:opacity-80 {
    opacity: .8;
  }

  .group:hover .xxl\:group-hover\:opacity-90 {
    opacity: .9;
  }

  .group:hover .xxl\:group-hover\:opacity-95 {
    opacity: .95;
  }

  .group:hover .xxl\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .xxl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }

  .xxl\:focus-within\:opacity-5:focus-within {
    opacity: .05;
  }

  .xxl\:focus-within\:opacity-10:focus-within {
    opacity: .1;
  }

  .xxl\:focus-within\:opacity-20:focus-within {
    opacity: .2;
  }

  .xxl\:focus-within\:opacity-25:focus-within {
    opacity: .25;
  }

  .xxl\:focus-within\:opacity-30:focus-within {
    opacity: .3;
  }

  .xxl\:focus-within\:opacity-40:focus-within {
    opacity: .4;
  }

  .xxl\:focus-within\:opacity-50:focus-within {
    opacity: .5;
  }

  .xxl\:focus-within\:opacity-60:focus-within {
    opacity: .6;
  }

  .xxl\:focus-within\:opacity-70:focus-within {
    opacity: .7;
  }

  .xxl\:focus-within\:opacity-75:focus-within {
    opacity: .75;
  }

  .xxl\:focus-within\:opacity-80:focus-within {
    opacity: .8;
  }

  .xxl\:focus-within\:opacity-90:focus-within {
    opacity: .9;
  }

  .xxl\:focus-within\:opacity-95:focus-within {
    opacity: .95;
  }

  .xxl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }

  .xxl\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .xxl\:hover\:opacity-5:hover {
    opacity: .05;
  }

  .xxl\:hover\:opacity-10:hover {
    opacity: .1;
  }

  .xxl\:hover\:opacity-20:hover {
    opacity: .2;
  }

  .xxl\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .xxl\:hover\:opacity-30:hover {
    opacity: .3;
  }

  .xxl\:hover\:opacity-40:hover {
    opacity: .4;
  }

  .xxl\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .xxl\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .xxl\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .xxl\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .xxl\:hover\:opacity-80:hover {
    opacity: .8;
  }

  .xxl\:hover\:opacity-90:hover {
    opacity: .9;
  }

  .xxl\:hover\:opacity-95:hover {
    opacity: .95;
  }

  .xxl\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .xxl\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .xxl\:focus\:opacity-5:focus {
    opacity: .05;
  }

  .xxl\:focus\:opacity-10:focus {
    opacity: .1;
  }

  .xxl\:focus\:opacity-20:focus {
    opacity: .2;
  }

  .xxl\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .xxl\:focus\:opacity-30:focus {
    opacity: .3;
  }

  .xxl\:focus\:opacity-40:focus {
    opacity: .4;
  }

  .xxl\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .xxl\:focus\:opacity-60:focus {
    opacity: .6;
  }

  .xxl\:focus\:opacity-70:focus {
    opacity: .7;
  }

  .xxl\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .xxl\:focus\:opacity-80:focus {
    opacity: .8;
  }

  .xxl\:focus\:opacity-90:focus {
    opacity: .9;
  }

  .xxl\:focus\:opacity-95:focus {
    opacity: .95;
  }

  .xxl\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .xxl\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .xxl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .xxl\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .xxl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .xxl\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .xxl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .xxl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .xxl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .xxl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .xxl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .xxl\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .xxl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .xxl\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .xxl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .xxl\:bg-blend-color {
    background-blend-mode: color;
  }

  .xxl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .xxl\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .xxl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .xxl\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .xxl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .xxl\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .xxl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .xxl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .xxl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .xxl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .xxl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .xxl\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .xxl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .xxl\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .xxl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .xxl\:mix-blend-color {
    mix-blend-mode: color;
  }

  .xxl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .xxl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .xxl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 #0000000f;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xxl\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xxl\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xxl\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xxl\:focus-within\:outline-none:focus-within {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xxl\:focus-within\:outline-white:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xxl\:focus-within\:outline-black:focus-within {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xxl\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xxl\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xxl\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xxl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xxl\:ring-inset, .xxl\:focus-within\:ring-inset:focus-within, .xxl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .xxl\:ring-transparent {
    --tw-ring-color: transparent;
  }

  .xxl\:ring-current {
    --tw-ring-color: currentColor;
  }

  .xxl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xxl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xxl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xxl\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xxl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xxl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xxl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xxl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xxl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xxl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }

  .xxl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }

  .xxl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xxl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }

  .xxl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }

  .xxl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 0, 0, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }

  .xxl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }

  .xxl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .xxl\:ring-opacity-5 {
    --tw-ring-opacity: .05;
  }

  .xxl\:ring-opacity-10 {
    --tw-ring-opacity: .1;
  }

  .xxl\:ring-opacity-20 {
    --tw-ring-opacity: .2;
  }

  .xxl\:ring-opacity-25 {
    --tw-ring-opacity: .25;
  }

  .xxl\:ring-opacity-30 {
    --tw-ring-opacity: .3;
  }

  .xxl\:ring-opacity-40 {
    --tw-ring-opacity: .4;
  }

  .xxl\:ring-opacity-50 {
    --tw-ring-opacity: .5;
  }

  .xxl\:ring-opacity-60 {
    --tw-ring-opacity: .6;
  }

  .xxl\:ring-opacity-70 {
    --tw-ring-opacity: .7;
  }

  .xxl\:ring-opacity-75 {
    --tw-ring-opacity: .75;
  }

  .xxl\:ring-opacity-80 {
    --tw-ring-opacity: .8;
  }

  .xxl\:ring-opacity-90 {
    --tw-ring-opacity: .9;
  }

  .xxl\:ring-opacity-95 {
    --tw-ring-opacity: .95;
  }

  .xxl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .xxl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .xxl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: .05;
  }

  .xxl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: .1;
  }

  .xxl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: .2;
  }

  .xxl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: .25;
  }

  .xxl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: .3;
  }

  .xxl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: .4;
  }

  .xxl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: .5;
  }

  .xxl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: .6;
  }

  .xxl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: .7;
  }

  .xxl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: .75;
  }

  .xxl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: .8;
  }

  .xxl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: .9;
  }

  .xxl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: .95;
  }

  .xxl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .xxl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .xxl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: .05;
  }

  .xxl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: .1;
  }

  .xxl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: .2;
  }

  .xxl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: .25;
  }

  .xxl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: .3;
  }

  .xxl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: .4;
  }

  .xxl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: .5;
  }

  .xxl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: .6;
  }

  .xxl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: .7;
  }

  .xxl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: .75;
  }

  .xxl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: .8;
  }

  .xxl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: .9;
  }

  .xxl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: .95;
  }

  .xxl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .xxl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .xxl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .xxl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .xxl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .xxl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .xxl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .xxl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .xxl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .xxl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .xxl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .xxl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .xxl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .xxl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .xxl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .xxl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .xxl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }

  .xxl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }

  .xxl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }

  .xxl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }

  .xxl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }

  .xxl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xxl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xxl\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }

  .xxl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }

  .xxl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }

  .xxl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }

  .xxl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }

  .xxl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .xxl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }

  .xxl\:ring-offset-red {
    --tw-ring-offset-color: red;
  }

  .xxl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }

  .xxl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }

  .xxl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }

  .xxl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }

  .xxl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }

  .xxl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }

  .xxl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }

  .xxl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }

  .xxl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }

  .xxl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }

  .xxl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xxl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }

  .xxl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xxl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xxl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }

  .xxl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }

  .xxl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }

  .xxl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }

  .xxl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }

  .xxl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }

  .xxl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }

  .xxl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }

  .xxl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xxl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }

  .xxl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }

  .xxl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }

  .xxl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }

  .xxl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xxl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }

  .xxl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xxl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }

  .xxl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xxl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xxl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xxl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }

  .xxl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }

  .xxl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }

  .xxl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }

  .xxl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }

  .xxl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }

  .xxl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xxl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }

  .xxl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xxl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xxl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }

  .xxl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xxl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }

  .xxl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }

  .xxl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }

  .xxl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }

  .xxl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xxl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }

  .xxl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xxl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xxl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }

  .xxl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }

  .xxl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }

  .xxl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }

  .xxl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }

  .xxl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }

  .xxl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .xxl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }

  .xxl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }

  .xxl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }

  .xxl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }

  .xxl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xxl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xxl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }

  .xxl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }

  .xxl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }

  .xxl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }

  .xxl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }

  .xxl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }

  .xxl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }

  .xxl\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: red;
  }

  .xxl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }

  .xxl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }

  .xxl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }

  .xxl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }

  .xxl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }

  .xxl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }

  .xxl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }

  .xxl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }

  .xxl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }

  .xxl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }

  .xxl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xxl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }

  .xxl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xxl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xxl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }

  .xxl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }

  .xxl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }

  .xxl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }

  .xxl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }

  .xxl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }

  .xxl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }

  .xxl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }

  .xxl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xxl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }

  .xxl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }

  .xxl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }

  .xxl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }

  .xxl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xxl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }

  .xxl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xxl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }

  .xxl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xxl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xxl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xxl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }

  .xxl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }

  .xxl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }

  .xxl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }

  .xxl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }

  .xxl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }

  .xxl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xxl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }

  .xxl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xxl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xxl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }

  .xxl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xxl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }

  .xxl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }

  .xxl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }

  .xxl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }

  .xxl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xxl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }

  .xxl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xxl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xxl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }

  .xxl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }

  .xxl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }

  .xxl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }

  .xxl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }

  .xxl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }

  .xxl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }

  .xxl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }

  .xxl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }

  .xxl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }

  .xxl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }

  .xxl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }

  .xxl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }

  .xxl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }

  .xxl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }

  .xxl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }

  .xxl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }

  .xxl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }

  .xxl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }

  .xxl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }

  .xxl\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: red;
  }

  .xxl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }

  .xxl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }

  .xxl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }

  .xxl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }

  .xxl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }

  .xxl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }

  .xxl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }

  .xxl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }

  .xxl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }

  .xxl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }

  .xxl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }

  .xxl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }

  .xxl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }

  .xxl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }

  .xxl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }

  .xxl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }

  .xxl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }

  .xxl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }

  .xxl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }

  .xxl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }

  .xxl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }

  .xxl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }

  .xxl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }

  .xxl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }

  .xxl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }

  .xxl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }

  .xxl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }

  .xxl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }

  .xxl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }

  .xxl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }

  .xxl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }

  .xxl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }

  .xxl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }

  .xxl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }

  .xxl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }

  .xxl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }

  .xxl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }

  .xxl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }

  .xxl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }

  .xxl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }

  .xxl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }

  .xxl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }

  .xxl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }

  .xxl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }

  .xxl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }

  .xxl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }

  .xxl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }

  .xxl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }

  .xxl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }

  .xxl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }

  .xxl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }

  .xxl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }

  .xxl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }

  .xxl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }

  .xxl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }

  .xxl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }

  .xxl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }

  .xxl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }

  .xxl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }

  .xxl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }

  .xxl\:filter {
    --tw-blur: var(--tw-empty, );
    --tw-brightness: var(--tw-empty, );
    --tw-contrast: var(--tw-empty, );
    --tw-grayscale: var(--tw-empty, );
    --tw-hue-rotate: var(--tw-empty, );
    --tw-invert: var(--tw-empty, );
    --tw-saturate: var(--tw-empty, );
    --tw-sepia: var(--tw-empty, );
    --tw-drop-shadow: var(--tw-empty, );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xxl\:filter-none {
    filter: none;
  }

  .xxl\:blur-0, .xxl\:blur-none {
    --tw-blur: blur(0);
  }

  .xxl\:blur-sm {
    --tw-blur: blur(4px);
  }

  .xxl\:blur {
    --tw-blur: blur(8px);
  }

  .xxl\:blur-md {
    --tw-blur: blur(12px);
  }

  .xxl\:blur-lg {
    --tw-blur: blur(16px);
  }

  .xxl\:blur-xl {
    --tw-blur: blur(24px);
  }

  .xxl\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .xxl\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .xxl\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .xxl\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .xxl\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .xxl\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .xxl\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .xxl\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .xxl\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .xxl\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .xxl\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .xxl\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .xxl\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .xxl\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .xxl\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .xxl\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .xxl\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .xxl\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .xxl\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .xxl\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .xxl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  }

  .xxl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  }

  .xxl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  }

  .xxl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  }

  .xxl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  }

  .xxl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  }

  .xxl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .xxl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .xxl\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .xxl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .xxl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .xxl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .xxl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .xxl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .xxl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .xxl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .xxl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .xxl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .xxl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .xxl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .xxl\:invert-0 {
    --tw-invert: invert(0);
  }

  .xxl\:invert {
    --tw-invert: invert(100%);
  }

  .xxl\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .xxl\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .xxl\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .xxl\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .xxl\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .xxl\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .xxl\:sepia {
    --tw-sepia: sepia(100%);
  }

  .xxl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty, );
    --tw-backdrop-brightness: var(--tw-empty, );
    --tw-backdrop-contrast: var(--tw-empty, );
    --tw-backdrop-grayscale: var(--tw-empty, );
    --tw-backdrop-hue-rotate: var(--tw-empty, );
    --tw-backdrop-invert: var(--tw-empty, );
    --tw-backdrop-opacity: var(--tw-empty, );
    --tw-backdrop-saturate: var(--tw-empty, );
    --tw-backdrop-sepia: var(--tw-empty, );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xxl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }

  .xxl\:backdrop-blur-0, .xxl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .xxl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .xxl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .xxl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .xxl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .xxl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .xxl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .xxl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .xxl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .xxl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .xxl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .xxl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .xxl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .xxl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .xxl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .xxl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .xxl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .xxl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .xxl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .xxl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .xxl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .xxl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .xxl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .xxl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .xxl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .xxl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .xxl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .xxl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .xxl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .xxl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .xxl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .xxl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .xxl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .xxl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .xxl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .xxl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .xxl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .xxl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .xxl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .xxl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .xxl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .xxl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .xxl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(.05);
  }

  .xxl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(.1);
  }

  .xxl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(.2);
  }

  .xxl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(.25);
  }

  .xxl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(.3);
  }

  .xxl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(.4);
  }

  .xxl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(.5);
  }

  .xxl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(.6);
  }

  .xxl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(.7);
  }

  .xxl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(.75);
  }

  .xxl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(.8);
  }

  .xxl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(.9);
  }

  .xxl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(.95);
  }

  .xxl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .xxl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .xxl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .xxl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .xxl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .xxl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .xxl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .xxl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }

  .xxl\:transition-none {
    transition-property: none;
  }

  .xxl\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xxl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xxl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xxl\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xxl\:transition-shadow {
    transition-property: box-shadow;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xxl\:transition-transform {
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xxl\:delay-75 {
    transition-delay: 75ms;
  }

  .xxl\:delay-100 {
    transition-delay: .1s;
  }

  .xxl\:delay-150 {
    transition-delay: .15s;
  }

  .xxl\:delay-200 {
    transition-delay: .2s;
  }

  .xxl\:delay-300 {
    transition-delay: .3s;
  }

  .xxl\:delay-500 {
    transition-delay: .5s;
  }

  .xxl\:delay-700 {
    transition-delay: .7s;
  }

  .xxl\:delay-1000 {
    transition-delay: 1s;
  }

  .xxl\:duration-75 {
    transition-duration: 75ms;
  }

  .xxl\:duration-100 {
    transition-duration: .1s;
  }

  .xxl\:duration-150 {
    transition-duration: .15s;
  }

  .xxl\:duration-200 {
    transition-duration: .2s;
  }

  .xxl\:duration-300 {
    transition-duration: .3s;
  }

  .xxl\:duration-500 {
    transition-duration: .5s;
  }

  .xxl\:duration-700 {
    transition-duration: .7s;
  }

  .xxl\:duration-1000 {
    transition-duration: 1s;
  }

  .xxl\:ease-linear {
    transition-timing-function: linear;
  }

  .xxl\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .xxl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .xxl\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

/*# sourceMappingURL=index.css.map */
