.headline {
  font-weight: $font-light;

  strong {
    font-weight: $font-bold;
    color: $color-yellow;
  }
}

h1,
.headline--h1 {
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-weight: $font-light;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 2rem;
}

h2,
.headline--h2 {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  font-weight: $font-light;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

h3,
.headline--h3 {
  font-size: clamp(1.1rem, 2vw, 1.8rem);
  font-weight: $font-bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 1rem;
}
