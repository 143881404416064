$color-yellow: #fcc924;
$color-black: #242424;
$color-gray: #7e8184;
$color-white: #ffffff;

$xs: 380px;
$sm: 480px;
$md: 769px;
$lg: 1024px;
$xl: 1280px;

$font-light: 200;
$font-normal: 400;
$font-bold: 700;
