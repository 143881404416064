.nav__toggle {
  width: 50px;
  height: 20px;

  @media (min-width: $sm) {
    width: 70px;
  }

  @media (min-width: $md) {
    width: 90px;
  }

  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    width: 100%;
    height: 2px;
    background: $color-yellow;
    display: block;
    transition: all 0.3s ease-in-out;
    pointer-events: none;

    &:nth-child(2) {
      margin: 5px 0;
      width: 40%;
    }
    &:nth-child(3) {
      width: 60%;
    }
  }

  &.active {
    span {
      position: absolute;
      top: 50%;
      width: 30px;

      @media (min-width: $md) {
        width: 60px;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}

.main-navigation {
  // @apply hidden;
  @apply flex;
  @apply items-center;
  @apply absolute;
  left: 0;
  top: 100%;
  background: $color-black;
  @apply text-white;
  @apply w-full;
  transform: translate3d(0, -200%, 0);
  @apply opacity-0;
  transition: all 0.3s ease-in-out;
  z-index: -1;

  .nav__toggle.active + & {
    transform: translate3d(0, 0%, 0);
    @apply opacity-100;
  }
}

.main-navigation__inner {
  height: calc(100vh - 68px);
  @apply overflow-auto;
  padding: 3rem 1rem;
  position: relative;

  @screen md {
    padding: 5rem 1rem;
    height: calc(100vh - 83px);
    @apply py-20;
  }

  @media (min-width: $lg) {
    display: flex;
    align-items: center;
  }
}

#primary-menu {
  @apply flex;
  @apply flex-col;

  @screen md {
    // @apply items-center;
  }

  @screen lg {
    @apply justify-center;
    @apply flex-row;
    align-items: center;
    flex-wrap: wrap;
  }

  .menu-item {
    @apply block;

    &.current_page_item,
    &:hover:after {
      right: 0;
    }

    a {
      @apply p-4;
      @apply text-2xl;
      @apply block;
      @apply cursor-pointer;
      @apply text-center;

      @screen md {
        @apply text-3xl;
        @apply px-8;
        @apply py-4;
        // @apply inline-block;
      }
    }
  }

  a.active + .sub-menu {
    @apply block;
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0;

    // background: $color-black;
    padding: 3rem 1rem;
    overflow: auto;

    @media (min-width: $md) {
      padding: 5rem 1rem;
    }

    @media (min-width: $lg) {
      left: 50%;
      top: 50%;
      right: auto;
      transform: translate3d(-50%, -50%, 0);
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .sub-menu {
    @apply hidden;

    a {
      @apply text-base;
    }
  }
}

.nav-btns {
  @apply mt-12;
  @apply px-4;

  @screen lg {
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply mt-20;
  }

  .btn {
    @apply inline-block;
    @apply mb-2;
    @apply mr-2;
  }
}

.nav_back {
  text-align: center;
  font-weight: bold;
  padding: 1rem;
  cursor: pointer;
  visibility: visible;

  @media (min-width: $lg) {
    flex: 1 0 100%;
    width: 100%;
  }
}

.sub-nav-open {
  .menu-item {
    visibility: hidden;
  }

  & .active + .sub-menu > .menu-item {
    visibility: visible;
  }
}
