.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  padding: 1rem 0;

  &.nav-open {
    background: $color-black !important;
  }

  .scroll & {
    background: rgba($color-black, 0.5);
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 90px;

  @media (min-width: $xs) {
    width: 120px;
  }

  @media (min-width: $sm) {
    width: 150px;
  }

  @media (min-width: $md) {
    width: 200px;
  }

  img {
    width: 100%;
  }
}

.header__interact {
  display: flex;
  align-items: center;
}

//Social

.header__social {
  display: flex;
  align-items: center;
}

.header-social__item {
  margin-right: 0.5rem;
  display: inline-block;

  @media (min-width: $sm) {
    margin-right: 1rem;
  }

  &--tel {
    color: $color-yellow;
    font-size: 1.2rem;
    display: none;

    @media (min-width: $md) {
      display: block;
    }
  }
}

.header-social__item-icon {
  width: 1rem;
  height: 1rem;
  color: $color-yellow;

  svg {
    path,
    g {
      fill: currentColor;
    }
  }

  @media (min-width: $xs) {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (min-width: $sm) {
    width: 1.75rem;
    height: 1.75rem;
  }

  @media (min-width: $md) {
    width: 2rem;
    height: 2rem;
  }
}
