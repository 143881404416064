.accordion {
    @apply mb-4;
  }

  .accordion__header {
    @apply bg-black;
    @apply text-white;
    @apply p-4;
    @apply cursor-pointer;
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;

    h4 {
      @apply mb-0;
      flex: 1 auto;
    }
  }

  .accordion__icon {
    @apply w-6;

    div {
      transform: rotate(90deg);
      transform-origin: center;
      transition: all 0.3s ease-in-out;
      @apply w-4;
      @apply h-4;

      &.accordion__icon--active {
        transform: rotate(-90deg);
      }
    }
  }

  .accordion__body {
    max-height: 0;
    @apply overflow-hidden;
    transition: all 0.3s ease-in-out;

    &--open {
      max-height: 1000px;
      @apply overflow-auto;
    }
  }

  .accordion__body-inner {
    @apply p-4;
  }