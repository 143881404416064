.footer {
  background: $color-yellow;
  color: $color-black;
}

.footer__col {
  @media (min-width: $md) {
    width: 33.3333%;
  }
}

.footer__info-content {
  margin-bottom: 1rem;
}

.footer-link {
  display: block;
  margin-bottom: 0.3rem;
  font-weight: $font-bold;
}
