.big-teaser {
  position: relative;
  background: $color-black;
  min-height: 50vh;
}

.big-teaser__inner {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.big-teaser__image {
  position: absolute;
  inset: 0;

  picture {
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // mix-blend-mode: multiply;
    opacity: 0.3;
  }
}

.big-teaser__content {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: $lg;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
  border: 2px solid $color-yellow;
}

.big-teaser__headline {
  color: $color-white;

  strong {
    color: $color-yellow;
    font-weight: $font-bold;
  }
}
