.category-header {
  background: $color-black;
  color: $color-white;
  padding: 9rem 0 7rem 0;
  position: relative;
}

.category__hero {
  position: absolute;
  inset: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3;
  }
}

.category-header__inner {
  border-bottom: 2px solid $color-yellow;
  border-right: 2px solid $color-yellow;
  padding: 0 1rem 2rem 0;
  position: relative;
  z-index: 1;

  @media (min-width: $md) {
    padding: 0 3rem 3rem 0;
    display: flex;
    gap: 2rem;

    &--small {
      width: 80%;
    }
  }

  @media (min-width: $lg) {
    padding: 0 5rem 5rem 0;
  }

  .btn {
    margin-top: 1rem;
  }
}

.category-header__content {
  @media (min-width: $md) {
    width: 50%;
    position: relative;
    // position: fixed;
    // top: 0;
    // left: 0;

    &--small {
      width: 100%;
    }
  }
}

.category-header__content-inner {
  position: sticky;
  top: 150px;
  left: 0;
  width: 100%;
}

.category-header__media {
  @media (min-width: $md) {
    width: 50%;
  }
}

.category-header__back {
  cursor: pointer;
  color: $color-yellow;
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: $font-light;
}

.category-list {
  background: $color-yellow;
}

.machines-overview {
  background: $color-gray;
}

.machine-overview__item {
  margin-bottom: 2rem;

  @media (min-width: $md) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 4rem;
  }
}

.machine-overview__image {
  @media (min-width: $md) {
    width: 50%;
    position: relative;
    z-index: 0;
  }
}

.machine-overview__content {
  background: $color-black;
  padding: 1rem;
  color: $color-white;
  font-weight: $font-light;

  @media (min-width: $md) {
    width: 50%;
    padding: 3rem;
    padding-left: 7rem;
    margin-left: -3rem;
    margin-top: 3rem;
  }
}

.machine-overview__price {
  color: $color-yellow;
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 1rem;
  display: block;
}

.machine-overview__text {
  margin-bottom: 1rem;
}

.category-list__inner {
  display: flex;
  overflow: auto;
  align-items: center;
}

.categories-headline {
  margin-right: 1rem;
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  font-weight: $font-bold;
  text-transform: uppercase;
  line-height: 1.2;
}

.categories-items {
  display: flex;
  align-items: center;
}

.category-link {
  min-width: 70px;
  aspect-ratio: 1 / 1;
  padding: 0.2rem;
  display: block;
  //   margin-right: 1rem;

  img {
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    height: 100%;
  }

  .intro__categories-items & {
    width: 50%;
  }

  @media (min-width: $md) {
    width: 160px;
    padding: 1rem;

    // .intro__categories-items & {
    //   width: 50%;
    // }
  }

  &--active,
  &:hover {
    background: darken($color-yellow, 10%);
  }
}

.category-link__name {
  width: 100%;
  font-size: 1rem;
  text-align: center;
  display: block;
  padding: 0.2rem 0;
  color: $color-black;

  @media (min-width: $md) {
  }
}
