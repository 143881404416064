.textarea {
  background: $color-black;
  color: $color-white;
  border-bottom: 2px solid $color-yellow;
}

.textarea__inner {
  @media (min-width: $md) {
    display: flex;
  }
}

.textarea__headline {
  @media (min-width: $md) {
    width: 50%;
  }
}

.textarea__content {
  font-weight: $font-light;

  @media (min-width: $md) {
    width: 50%;
  }
}
