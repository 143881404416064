input,
textarea {
  background: transparent;
  padding: 0.5rem 0 0.5rem 0 !important;
  border-bottom: 2px solid $color-black;
  margin-bottom: 1rem !important;
  width: 100%;
  display: block;
}

input[type="checkbox"] {
  display: inline-block;
  width: 2rem;
  margin-right: 0.5rem;
  margin-bottom: 0 !important;
}

.input-wrapper {
  &--checkbox {
    padding: 1rem 0;
  }
}

form {
  label {
    font-weight: $font-light;
    display: flex;
    align-items: center;

    a {
      font-weight: $font-bold;
    }
  }
}

.wpcf7-list-item {
  margin-left: 0 !important;
}

@mixin placeholder {
  color: $color-black !important;
  font-weight: $font-light;
}

::-webkit-input-placeholder {
  /* Edge */
  @include placeholder();
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @include placeholder();
}

::placeholder {
  @include placeholder();
}
